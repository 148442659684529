import {ProcessDetailsTableModel} from './process-details-table.model';

export class ProcessDetailsModel {
  constructor(
    public programName: string = null,
    public channelName: string = null,
    public fileSize: string = null,
    public fileName: string = null,
    public fileNameUrl: string = null,
    public fileId: string = null,
    public processTransactionId: string = null,
    public fileLogUrl: string = null,
    public tableData: ProcessDetailsTableModel[] = [],
  ) {}
}
