import {
  Component,
  EventEmitter,
  HostListener,
  Input,
  OnInit,
  Output,
  OnDestroy,
} from '@angular/core';
import { ReplaceValuePipe } from '../../../../shared/pipe/replace-value.pipe';
import { ReviewProgram } from '../../../../shared/models/program-review.model';
import { NzUploadChangeParam, NzUploadFile } from 'ng-zorro-antd/upload';
import { Subscription } from 'rxjs';
import { NzModalRef, NzModalService } from 'ng-zorro-antd/modal';
import { HttpService } from '../../../../shared/services/http.service';
import { AppState } from '../../../../shared/services/app-state';

@Component({
  selector: 'app-program-approval-modal',
  templateUrl: './program-approval-modal.component.html',
  styleUrls: ['./program-approval-modal.component.scss'],
})
export class ProgramApprovalModalComponent implements OnInit, OnDestroy {
  @Input() showReviewModal;
  @Output() hideReviewModal: EventEmitter<boolean> = new EventEmitter<boolean>();
  @Input() customProgramId;
  @Output() reloadPage: EventEmitter<boolean> = new EventEmitter<boolean>();
  @Input() programStatus;
  public replaceValue = new ReplaceValuePipe();

  public searchValue = /[?\/\\:[\]*]/gm;
  public validProgramChar = true;

  public showDropdown = false;

  public formData = new FormData();
  public isInprogress = false;
  public uploadPercent = 0;
  public customError = '';
  public showUploadError = false;

  public errorMessage = '';

  public programReview: ReviewProgram = new ReviewProgram();

  public loading = false;
  public reason = '';
  public isValidReason = true;

  public showRejectScreen = false;
  public programValue = '';
  public isValidProgram = true;
  public programValueCpy = '';
  public editProgramClicked = false;
  public editChannelClicked = false;

  public nzFileList: NzUploadFile[] = [];
  public fileList: File[] = [];

  public productOptions = [
    { label: 'AffinityMonitor', value: 'AffinityMonitor', checked: false },
    { label: 'AccessMonitor', value: 'AccessMonitor', checked: false },
  ];
  public selectedProducts = '';

  public channelData = [
    'Personal Face-to-Face',
    'Personal Remote',
    'Peer Interaction',
    'Digital Push',
    'Digital Pull',
    'Direct',
    'Customer Master',
    'Offer',
  ];

  public selectedChannel = '';
  public selectedChannelCpy = '';

  public uploadFileClicked = false;
  public showModifyScreen = false;
  public programEditted = false;
  public channelEditted = false;
  private appStateSubscription: Subscription;

  public duplicate = false;

  constructor(
    private modal: NzModalService,
    private httpService: HttpService,
    private appState: AppState,
  ) {
    this.appStateSubscription = AppState.appState.subscribe((val) => {
      if (val && val.showDropdown) {
        this.showDropdown = true;
      }
    });
  }

  public confirmModal?: NzModalRef;

  ngOnInit(): void {
    this.loading = true;
    this.loadData();
  }

  public loadData(): void {
    const payloadId = {
      custom_program_id: this.customProgramId,
    };
    this.httpService.postRequest('get_program_approval_details', payloadId).subscribe((result) => {
    // this.httpService.getRequest('get_program_approval_details').subscribe((result) => {
      if (result['status'] === 'SUCCESS') {
        this.programReview = result.data;
        this.programValue = result.data.program;
        this.programValueCpy = this.programValue;
        this.selectedChannel = result.data.channel;
        this.selectedChannelCpy = this.selectedChannel;
        this.loading = false;
        this.duplicate = result.data.duplicate;
        this.errorMessage = result.data.duplicate
          ? 'This program already exists under this channel.'
          : '';
        this.checkedProduct(this.selectedProducts);
      } else if (result['status'] === 'ERROR') {
        console.log('error', result['message']);
      }
    });
  }
  public checkedProduct(product): void {
    if (product === 'AFM') {
      this.productOptions = [
        { label: 'AffinityMonitor', value: 'AffinityMonitor', checked: true },
        { label: 'AccessMonitor', value: 'AccessMonitor', checked: false },
      ];
    } else if (product === 'ACM') {
      this.productOptions = [
        { label: 'AffinityMonitor', value: 'AffinityMonitor', checked: false },
        { label: 'AccessMonitor', value: 'AccessMonitor', checked: true },
      ];
    } else if (product === 'AFM, ACM' || product === 'ACM, AFM') {
      this.productOptions = [
        { label: 'AffinityMonitor', value: 'AffinityMonitor', checked: true },
        { label: 'AccessMonitor', value: 'AccessMonitor', checked: true },
      ];
    } else {
      this.productOptions = [
        { label: 'AffinityMonitor', value: 'AffinityMonitor', checked: false },
        { label: 'AccessMonitor', value: 'AccessMonitor', checked: false },
      ];
    }
  }

  public channelSelected(event): void {
    this.selectedChannel = event;
  }

  public downloadFile(url, target): void {
    window.open(url, target);
  }

  public fileChanged(files: NzUploadChangeParam): void {
    this.formData = new FormData();
    this.isInprogress = true;
    this.uploadPercent = 5;
    const fileNames = [];
    this.customError = '';
    this.showUploadError = false;
    if (files.type === 'start') {
      files.fileList.forEach((file) => {
        this.fileList.push(file.originFileObj);
      });
    }

    this.showUploadError = this.checkFileType();

    files = undefined;
    this.nzFileList = [];
    this.isInprogress = false;
    this.uploadFileClicked = true;
  }
  public checkFileType(): boolean {
    let isValid = false;
    let validFileType = true;
    this.showUploadError = false;
    this.fileList.forEach((file) => {
      const type = file.name.substring(file.name.length - 4, file.name.length);
      if (type === '.csv') {
        isValid = true;
        file['isValid'] = isValid;
        this.customError = '';
      } else {
        isValid = false;
        validFileType = false;
        file['isValid'] = isValid;
        this.showUploadError = true;
        this.isInprogress = false;
      }
    });
    return !validFileType;
  }

  public deleteFile(isValid = false): void {
    if (isValid) {
      this.confirmModal = this.modal.confirm({
        nzTitle: 'Delete Uploaded File?',
        nzContent: 'Are you sure you want to delete this file?',
        nzOkText: 'Yes, Delete',
        nzIconType: 'exclamation-circle',
        nzOnOk: () => {
          this.fileList = [];
          this.showUploadError = false;
          this.customError = '';
          this.uploadFileClicked = false;
        },
        nzOnCancel: () => {},
      });
    } else {
      this.fileList = [];
      this.uploadFileClicked = false;
      this.showUploadError = false;
      this.customError = '';
    }
  }

  public onCancel(): void {
    this.showReviewModal = false;
    this.confirmModal = this.modal.confirm({
      nzTitle: 'Leave Program Review',
      nzContent: 'Are you sure you want to leave the review process?',
      nzOkText: 'Yes, Leave',
      nzIconType: 'exclamation-circle',
      nzOnOk: () => {
        this.hideReviewModal.emit(false);
        this.showReviewModal = false;
      },
      nzOnCancel: () => {
        this.showReviewModal = true;
      },
    });
  }
  public onReject(): void {
    this.showRejectScreen = true;
  }
  public onBack(): void {
    this.showRejectScreen = false;
    this.showModifyScreen = false;
    this.reason = '';
    this.isValidReason = true;
  }
  public isdisabled(): boolean {
    if (this.isValidReason && this.reason && this.selectedProducts) {
      return false;
    } else {
      return true;
    }
  }

  public buttonDisabled(): boolean {
    if (
      this.editChannelClicked ||
      this.editProgramClicked ||
      this.showUploadError ||
      this.loading ||
      !this.selectedProducts
    ) {
      return true;
    } else {
      if (this.duplicate) {
        return !(this.channelEditted || this.programEditted);
      }
    }
  }

  public checkReason(): void {
    this.reason = this.replaceValue.transform(this.reason, true);
    this.isValidReason = this.reason ? this.reason.length <= 2000 : true;
  }

  public onApprove(): void {
    if (this.programEditted || this.channelEditted) {
      this.showModifyScreen = true;
    } else {
      this.showModifyScreen = false;
      this.formData = new FormData();
      this.formData.append('id', this.customProgramId);
      this.formData.append('reason', this.reason);
      this.formData.append('state', 'Approved');
      this.formData.append('program', this.programValueCpy);
      this.formData.append('channel', this.selectedChannelCpy);
      this.formData.append('product', this.selectedProducts);
      if (this.uploadFileClicked) {
        this.formData.append('template_file', this.fileList[0]);
      }
      console.log(this.formData);
      this.showReviewModal = false;
      this.confirmModal = this.modal.confirm({
        nzTitle: 'Approve Program',
        nzContent:
          'The change is permanent and you will not be able to modify this. Are you sure you want to approve this program?',
        nzOkText: 'Yes, Approve',
        nzIconType: 'exclamation-circle',
        nzOnOk: () => {
          this.httpService
              .postRequest('submit_custom_program_review', this.formData, true)
            // .getRequest('submit_custom_program_review')
            .subscribe((data) => {
              if (data['status'] === 'SUCCESS') {
                this.reloadPage.emit(true);
                this.hideReviewModal.emit(false);
                this.showReviewModal = false;
              } else if (data['status'] === 'ERROR') {
                this.errorMessage = data['message'];
                this.programEditted = false;
                this.channelEditted = false;
                this.programValueCpy = this.programValue;
                this.selectedChannelCpy = this.selectedChannel;
                this.reason = '';
                this.errorMessage === 'This program already exists under this channel.'
                  ? (this.duplicate = true)
                  : (this.duplicate = false);
                this.showReviewModal = true;
                this.showModifyScreen = false;
              }
            });
        },
        nzOnCancel: () => {
          this.showReviewModal = true;
        },
      });
    }
  }

  public saveProgram(): void {
    if (this.checkProgram()) {
      this.editProgramClicked = false;
    }
    if (this.programValue.toLowerCase() !== this.programValueCpy.toLowerCase()) {
      this.programEditted = true;
    } else {
      this.programEditted = false;
    }
  }
  public saveChannel(): void {
    if (this.selectedChannel) {
      this.editChannelClicked = false;
    }
    if (this.selectedChannel.toLowerCase() !== this.selectedChannelCpy.toLowerCase()) {
      this.channelEditted = true;
    } else {
      this.channelEditted = false;
    }
  }
  public checkProgram(): boolean {
    this.programValue = this.replaceValue.transform(this.programValue, true);
    this.isValidProgram = this.programValue ? this.programValue.length <= 50 : true;
    this.programValue
      ? this.programValue.match(this.searchValue)
        ? (this.validProgramChar = false)
        : (this.validProgramChar = true)
      : (this.validProgramChar = true);
    if (this.isValidProgram && this.validProgramChar && this.programValue) {
      return true;
    } else {
      return false;
    }
  }
  public submitClicked(isRejected): void {
    this.formData = new FormData();
    this.formData.append('id', this.customProgramId);
    this.formData.append('reason', this.reason);
    this.formData.append('product', this.selectedProducts);
    if (isRejected) {
      this.formData.append('state', 'Rejected');
    } else {
      this.formData.append('state', 'Modified & Approved');
    }
    if (isRejected) {
      this.formData.append('program', this.programValueCpy);
      this.formData.append('channel', this.selectedChannelCpy);
    } else {
      this.formData.append('program', this.programValue);
      this.formData.append('channel', this.selectedChannel);
    }
    if (this.uploadFileClicked) {
      this.formData.append('template_file', this.fileList[0]);
    }
    if (isRejected) {
      this.showReviewModal = false;
      this.confirmModal = this.modal.confirm({
        nzTitle: 'Reject Program',
        nzContent:
          'The change is permanent and you will not be able to modify this. Are you sure you want to reject this program?',
        nzOkText: 'Yes, Reject',
        nzIconType: 'exclamation-circle',
        nzOnOk: () => {
          this.httpService
            .postRequest('submit_custom_program_review', this.formData, true)
            // .getRequest('submit_custom_program_review')
            .subscribe((data) => {
              if (data['status'] === 'SUCCESS') {
                this.reloadPage.emit(true);
                this.hideReviewModal.emit(false);
                this.showReviewModal = false;
              } else if (data['status'] === 'ERROR') {
                this.errorMessage = data['message'];
                this.programEditted = false;
                this.channelEditted = false;
                this.reason = '';
                this.showReviewModal = true;
                this.showRejectScreen = false;
              }
            });
        },
        nzOnCancel: () => {
          this.showReviewModal = true;
        },
      });
    } else {
      this.showReviewModal = false;
      this.confirmModal = this.modal.confirm({
        nzTitle: 'Approve Program',
        nzContent:
          'The change is permanent and you will not be able to modify this. Are you sure you want to approve this program?',
        nzOkText: 'Yes, Approve',
        nzIconType: 'exclamation-circle',
        nzOnOk: () => {
          this.httpService
            .postRequest('submit_custom_program_review', this.formData, true)
            // .getRequest('submit_custom_program_review')
            .subscribe((data) => {
              if (data['status'] === 'SUCCESS') {
                this.reloadPage.emit(true);
                this.hideReviewModal.emit(false);
                this.showReviewModal = false;
              } else if (data['status'] === 'ERROR') {
                this.errorMessage = data['message'];
                this.programValueCpy = this.programValue;
                this.selectedChannelCpy = this.selectedChannel;
                this.programEditted = false;
                this.channelEditted = false;
                this.reason = '';
                this.errorMessage === 'This program already exists under this channel.'
                  ? (this.duplicate = true)
                  : (this.duplicate = false);
                this.showReviewModal = true;
                this.showModifyScreen = false;
              }
            });
        },
        nzOnCancel: () => {
          this.showReviewModal = true;
        },
      });
    }
  }
  @HostListener('window:scroll', ['$event'])
  public scrollHandler(event): void {
    if (this.showDropdown) {
      this.appState.setGlobalState('showDropdown', false);
    }
  }

  public disableEnter(event): void {
    if (!this.reason && event.key == 'Enter') {
      event.preventDefault();
    }
  }
  public productSelected(products): void {
    let checkedFlag = 0;
    this.selectedProducts = '';
    products.forEach((product) => {
      if (product.checked) {
        this.selectedProducts = product.value === 'AffinityMonitor' ? 'AFM' : 'ACM';
        checkedFlag += 1;
      }
    });
    if (checkedFlag === 2) {
      this.selectedProducts = 'AFM,ACM';
    }
  }
  ngOnDestroy(): void {
    this.appStateSubscription.unsubscribe();
  }
}
