import {Component, EventEmitter, Input, OnInit, Output, ViewChild} from '@angular/core';
import {NzCarouselComponent} from 'ng-zorro-antd/carousel';

@Component({
  selector: 'app-onboarding',
  templateUrl: './onboarding.component.html',
  styleUrls: ['./onboarding.component.css']
})
export class OnboardingComponent implements OnInit {

  @Input() showTour;
  @Output() hideOnboarding: EventEmitter<boolean> = new EventEmitter<boolean>();

  @ViewChild(NzCarouselComponent, { static: false }) onboardCarousel: NzCarouselComponent;

  // 4th Affex tab - 'assets/onboarding/affex.png' (Also update html condition)
  public array = ['assets/onboarding/gettingStarted.png', 'assets/onboarding/downloadReports.png', 'assets/onboarding/uploadData.png', 'assets/onboarding/dashboard.png',
  'assets/onboarding/subscription.png', 'assets/onboarding/faq.png'];
  public title = 0;

  // 4th Affex tab - 'Allows generation of HCP report for a given list of HCPs (subject to pre-decided business rules)'
  public desc = [
    'Welcome to the ZS AffinityMonitor & AccessMonitor. Let’s start with an overview of the application to help you maneuver and find relevant elements with ease. Please use ‘Skip’ to begin using the portal.',
    'Allows downloading of AffinityMonitor & AccessMonitor reports based upon subscription',
    'Allows uploading of channel / sub-channel wise data for AffinityMonitor & AccessMonitor',
    'Shows benchmark views available for Industry and Client/Company data',
    'Allows users to view their subscription status and connect with support team for any further requests or questions',
    'Contains the FAQs, User Guides, and Suggestion Form'
  ];

  // 4th Affex tab - 'AFFEX'
  public title_desc = [
    'Getting Started',
    'Download Reports',
    'Upload Data',
    'Benchmark Dashboard',
    'Subscriptions',
    'FAQ'
  ];


  constructor() { }

  ngOnInit(): void {
  }

  public onCancel(): void {
    this.hideOnboarding.emit(false);
    this.title = 0;
  }

  public onNext(): void {
    this.title = this.title + 1;
    this.onboardCarousel.next();
  }
  public onBack(): void {
    this.title = this.title - 1;
    this.onboardCarousel.pre();
  }

  public goTo(event): void {
    this.onboardCarousel.goTo(event.to);
    this.title = event.to;
  }
}
