<div class = "preview-modal-wrapper">
<nz-modal
  [nzStyle]="{ top: '20px' }"
  [(nzVisible)]="previewReport"
  [nzTitle]="modalTitle"
  (nzOnCancel)="handleCancel()"
  [nzWidth]="1200"
  [nzFooter]="null"
  [nzMaskClosable]="false"
  class="preview-modal"
>
  <ng-container *nzModalContent>
    <div class="modal-body-wrapper">
      <nz-table
        class="app-modal-table"
        #previewTable
        [nzData]="listOfData"
        nzTableLayout="fixed"
        [nzShowPagination]="true"
      >
        <thead>
          <tr>
            <th
              *ngFor="let column of listOfHeaders"
              [nzSortOrder]="'ascend'"
              (click)="sortData(column.key)"
              [nzSortDirections]="['ascend', 'descend']"
            >
              {{ column.value }}
            </th>
          </tr>
        </thead>
        <tbody>
          <tr *ngFor="let data of previewTable.data">
            <td>{{ data.field }}</td>
            <td>{{ data.fieldName }}</td>
            <td>{{ data.fieldFullName }}</td>
            <td>{{ data.description }}</td>
            <td>{{ data.type }}</td>
            <td>{{ data.values }}</td>
          </tr>
        </tbody>
      </nz-table>
    </div>
  </ng-container>
</nz-modal>
</div>
