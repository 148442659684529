import { Component, OnInit, Output, EventEmitter, Input, ViewChild, HostListener, OnDestroy } from '@angular/core';
import { NzAutocompleteComponent } from 'ng-zorro-antd/auto-complete';
import {ReplaceValuePipe} from '../../pipe/replace-value.pipe';
import { AppState } from '../../services/app-state';
import { Subscription } from 'rxjs';
@Component({
  selector: 'app-search-bar',
  templateUrl: './search-bar.component.html',
  styleUrls: ['./search-bar.component.scss'],
})
export class SearchBarComponent implements OnInit, OnDestroy {
  @Output() searchValue = new EventEmitter<string>();
  @Output() resetSearch = new EventEmitter<boolean>();
  @Input() searchParameter;
  @Output() noResultFound = new EventEmitter<boolean>();
  @Output() noInput = new EventEmitter<boolean>();
  @Output() inputKey = new EventEmitter<any>();
  private searchInput;
  @Input() inputValue;
  @Output() inputValueChange = new EventEmitter<string>();
  searchSuggestions: string[] = [];
  @ViewChild('auto') autoComplete: NzAutocompleteComponent;

  public replaceValue = new ReplaceValuePipe();
  private appStateSubscription: Subscription;

  constructor(private appState: AppState) {
    this.appStateSubscription = AppState.appState.subscribe((val) => {
      if (val && !val.showDropdown && this.autoComplete) {
        this.autoComplete.showPanel = false;
      }
    });
  }

  ngOnInit(): void {}

  public searchCheck(): void {
    this.inputValue = this.replaceValue.transform(this.inputValue, true);
  }

  onInput(event: Event): void {
    this.searchInput = (event.target as HTMLInputElement).value;
    this.searchSuggestions = [];
    if (this.searchInput.length === 0) {
      this.inputValue = '';
      this.noInput.emit(true);
    } else {
      if (this.searchInput.length >= 3) {
        this.searchParameter.map((suggestion) => {
          if (suggestion.toLocaleLowerCase().includes(this.searchInput.toLocaleLowerCase())) {
            this.searchSuggestions.push(suggestion);
          }
        });
        if (this.searchSuggestions.length === 0) {
          this.noResultFound.emit(true);
        }
        this.appState.setGlobalState('showDropdown', true);
      }
      if (this.searchInput.length >= 4) {
        this.inputKey.emit(this.searchInput);
      }
      this.inputValueChange.emit(this.inputValue);
    }
  }

  public filterSearch(event): void {
    if (this.searchInput.length !== 0){
      this.searchValue.emit(event.nzValue);
    }
  }

  onSearchClose(): void {
    this.inputValue = '';
    this.searchSuggestions = [];
    this.resetSearch.emit(true);
  }

  ngOnDestroy(): void {
    this.appStateSubscription.unsubscribe();
  }
  public openChange(event): void {
    this.appState.setGlobalState('showDropdown', event);
  }

}
