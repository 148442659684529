<nz-layout>
  <nz-content>
    <div class="space-align-container">
      <span nz-typography class="tab-header">File Tracking Log</span>
      <nz-space [nzSize]="'middle'">
        <app-search-bar *nzSpaceItem
          [searchParameter]="searchParamData"
          (searchValue)="filterOnSearch($event)"
          (inputKey)="filterOnSearch($event)"
          (noResultFound)="formatOnNoResult($event)"
          (noInput)="resetSearch($event)"
          (resetSearch)="resetSearch($event)"></app-search-bar>
      </nz-space>
    </div>
    <nz-table
      class="file-tracking-table"
      #previewTable
      [nzData]="logData"
      [nzLoading]="loading"
      nzTableLayout="fixed"
      [nzShowPagination]="true"
      [nzNoResult]="noResultFound"
      [nzPageSize]="8"
      [(nzPageIndex)]="pageIndex"
      [nzShowQuickJumper]="true"
    >
      <thead>
        <tr>
          <th rowSpan="2" nzCustomFilter class="filter-client">
            Client
            <nz-filter-trigger
              [nzDropdownMenu]="customFilterClient"
              [(nzVisible)]="showFilterClient"
              (nzVisibleChange)="openChange($event)"
            >
              <i
                nz-icon
                nzType="filter"
                nzTheme="fill"
                [ngClass]="highlightFilterClient() ? 'highlight' : ''"
              ></i>
            </nz-filter-trigger>
            <nz-dropdown-menu #customFilterClient="nzDropdownMenu">
              <div class="dropdown-container">
                <div class="ant-table-filter-dropdown">
                  <ul nz-menu *ngFor="let item of client">
                    <li nz-menu-item>
                      <label nz-checkbox [(ngModel)]="item.value"> {{ item.text }} </label>
                    </li>
                  </ul>
                </div>
                <div class="button-wrapper">
                  <a (click)="resetClient()">Reset</a>&nbsp;
                  <button
                    nz-button
                    nzType="primary"
                    class="app-primary-button button-class"
                    (click)="filter(false)"
                  >
                    OK
                  </button>
                </div>
              </div>
            </nz-dropdown-menu>
          </th>
          <th rowSpan="2">User</th>
          <th rowSpan="2">File Name</th>
          <th colSpan="3">File Status</th>
          <th rowSpan="2" nzCustomFilter class="current-step">
            Current Step
            <nz-filter-trigger
              [nzDropdownMenu]="customFilterCurrent"
              [(nzVisible)]="showFilterCurrent"
              (nzVisibleChange)="openChange($event)"
            >
              <i
                nz-icon
                nzType="filter"
                nzTheme="fill"
                [ngClass]="highlightFilterCurrentStep() ? 'highlight' : ''"
              ></i>
            </nz-filter-trigger>
            <nz-dropdown-menu #customFilterCurrent="nzDropdownMenu">
              <div class="dropdown-container-1">
                <div class="ant-table-filter-dropdown">
                  <ul nz-menu *ngFor="let item of currentStep">
                    <li nz-menu-item>
                      <label nz-checkbox [(ngModel)]="item.value"> {{ item.text }} </label>
                    </li>
                  </ul>
                </div>
                <div class="button-wrapper">
                  <a (click)="resetCurrent()">Reset</a>&nbsp;
                  <button
                    nz-button
                    nzType="primary"
                    class="app-primary-button button-class"
                    (click)="filter(false)"
                  >
                    OK
                  </button>
                </div>
              </div>
            </nz-dropdown-menu>
          </th>
          <th rowSpan="2" [nzSortOrder]="sortOrder" (click)='filter(true)' [nzSortDirections]="sortDirection">Upload Date</th>
          <th rowSpan="2">View Info</th>
        </tr>
        <tr>
          <th nzCustomFilter class="filter-upload">
            Upload Status
            <nz-filter-trigger
              [nzDropdownMenu]="customFilterUpload"
              [(nzVisible)]="showFilterUpload"
              (nzVisibleChange)="openChange($event)"
            >
              <i
                nz-icon
                nzType="filter"
                nzTheme="fill"
                [ngClass]="highlightFilterUpload() ? 'highlight' : ''"
              ></i>
            </nz-filter-trigger>
            <nz-dropdown-menu #customFilterUpload="nzDropdownMenu">
              <div class="dropdown-container-1">
                <div class="ant-table-filter-dropdown">
                  <ul nz-menu *ngFor="let item of uploadStatus">
                    <li nz-menu-item>
                      <label nz-checkbox [(ngModel)]="item.value"> {{ item.text }} </label>
                    </li>
                  </ul>
                </div>
                <div class="button-wrapper">
                  <a (click)="resetUpload()">Reset</a>&nbsp;
                  <button nz-button nzType="primary" class="app-primary-button button-class" (click)="filter(false)">
                    OK
                  </button>
                </div>
              </div>
            </nz-dropdown-menu>
          </th>
          <th nzCustomFilter class="filter-profiler">
            Data Profiler
            <nz-filter-trigger
              [nzDropdownMenu]="customFilterProfiler"
              [(nzVisible)]="showFilterProfiler"
              (nzVisibleChange)="openChange($event)"
            >
              <i
                nz-icon
                nzType="filter"
                nzTheme="fill"
                [ngClass]="highlightFilterProfiler() ? 'highlight' : ''"
              ></i>
            </nz-filter-trigger>
            <nz-dropdown-menu #customFilterProfiler="nzDropdownMenu">
              <div class="dropdown-container-1">
                <div class="ant-table-filter-dropdown">
                  <ul nz-menu *ngFor="let item of profilerStatus">
                    <li nz-menu-item>
                      <label nz-checkbox [(ngModel)]="item.value"> {{ item.text }} </label>
                    </li>
                  </ul>
                </div>
                <div class="button-wrapper">
                  <a (click)="resetProfiler()">Reset</a>&nbsp;
                  <button
                    nz-button
                    nzType="primary"
                    class="app-primary-button button-class"
                    (click)="filter(false)"
                  >
                    OK
                  </button>
                </div>
              </div>
            </nz-dropdown-menu>
          </th>
          <th nzCustomFilter class="filter-revo">
            REVO Status
            <nz-filter-trigger
              [nzDropdownMenu]="customFilterRevo"
              [(nzVisible)]="showFilterRevo"
              (nzVisibleChange)="openChange($event)"
            >
              <i
                nz-icon
                nzType="filter"
                nzTheme="fill"
                [ngClass]="highlightFilterRevo() ? 'highlight' : ''"
              ></i>
            </nz-filter-trigger>
            <nz-dropdown-menu #customFilterRevo="nzDropdownMenu">
              <div class="dropdown-container-1">
                <div class="ant-table-filter-dropdown">
                  <ul nz-menu *ngFor="let item of revoStatus">
                    <li nz-menu-item>
                      <label nz-checkbox [(ngModel)]="item.value"> {{ item.text }} </label>
                    </li>
                  </ul>
                </div>
                <div class="button-wrapper">
                  <a (click)="resetRevo()">Reset</a>&nbsp;
                  <button
                    nz-button
                    nzType="primary"
                    class="app-primary-button button-class"
                    (click)="filter(false)"
                  >
                    OK
                  </button>
                </div>
              </div>
            </nz-dropdown-menu>
          </th>
        </tr>
      </thead>
      <tbody>
        <tr *ngFor="let data of previewTable.data">
          <td class="row-data">{{ data.client }}</td>
          <td><a>{{ data.user }}</a></td>
          <td class="row-data">{{ data.filename }}</td>
          <td [ngClass]="defineClass(data.upload_status)">{{ data.upload_status }}</td>
          <td [ngClass]="defineClass(data.data_profiler)">{{ data.data_profiler }}</td>
          <td [ngClass]="defineClass(data.revo_status)">{{ data.revo_status }}</td>
          <td class="row-data">
            {{ data.current_step }}
          </td>
          <td class="row-data">{{ getDate(data.upload_date) }}</td>
          <td><i class="app-style-mid" nz-icon nzType="eye" nzTheme="outline" (click)="openProcessModal(data.id)"></i></td>
        </tr>
      </tbody>
    </nz-table>
  </nz-content>
</nz-layout>
<ng-template #noResultFound>
  <nz-empty nzNotFoundImage="simple" [nzNotFoundContent]="noResultTpl">
    <ng-template #noResultTpl>
      <p class="negate-margin">{{noDataMessage}}</p>
    </ng-template>
  </nz-empty>
</ng-template>
<app-process-details-modal
  *ngIf="openModal"
  [showModal]="openModal"
  (hideModal)="onReviewClose($event)"
  [id]="id"
>

</app-process-details-modal>
