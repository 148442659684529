<span class="simple-table-wrapper">
  <span class="dropdown-menu" nz-dropdown nzTrigger="click" [nzDropdownMenu]="dropdownMenu" nzPlacement="bottomRight" [title]="title"
    (nzVisibleChange)="openChange($event)" [(nzVisible)]="showFilter">
    <span *ngIf="!showEditIcon" class="dots">...</span>
    <i *ngIf="showEditIcon" nz-icon nzType="edit" nzTheme="outline"></i>
  </span>
  <nz-dropdown-menu #dropdownMenu="nzDropdownMenu">
    <ul nz-menu>
      <li nz-menu-item (click)="import()">Import to Portal</li>
      <li nz-menu-item (click)="export()">Export to Excel</li>
    </ul>
  </nz-dropdown-menu>
</span>

<app-import-modal [page]="page" [(showModal)]="showImport"></app-import-modal>
