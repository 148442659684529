import { Component, EventEmitter, Input, OnInit, Output } from '@angular/core';
import { Router } from '@angular/router';
import { NzUploadChangeParam, NzUploadFile } from 'ng-zorro-antd/upload';
import { NzModalRef, NzModalService } from 'ng-zorro-antd/modal';
import { HttpService } from '../../services/http.service';
import { NzNotificationService } from 'ng-zorro-antd/notification';

@Component({
  selector: 'app-import-modal',
  templateUrl: './import-modal.component.html',
  styleUrls: ['./import-modal.component.scss'],
})
export class ImportModalComponent implements OnInit {
  @Input() showModal = false;
  @Input() title = '';
  @Input() description = '';
  @Input() page = '';
  @Output() showModalChange: EventEmitter<boolean> = new EventEmitter<boolean>();

  public formData = new FormData();
  public customError = '';
  public showUploadError = false;
  public fileList: File[] = [];
  public downloadUrl = '';
  public errorMessage = '';
  public isLoading = false;

  public nzFileList: NzUploadFile[] = [];
  public confirmModal?: NzModalRef;

  constructor(
    private router: Router,
    private modal: NzModalService,
    private httpService: HttpService,
    private notification: NzNotificationService,
  ) {}

  ngOnInit(): void {
    this.getPageDetails();
  }

  public getPageDetails(): void {
    switch (this.page) {
      case 'faq':
        this.title = 'Import FAQs';
        this.description =
          'Please upload a .xlsx file with the following format (single file upload): <br> - Sheet 1 – FAQs – with following columns <br>' +
          '<ul><li> Sr. No.</li>' +
          '<li> Product </li>' +
          '<li> Question </li>' +
          '<li> Answer </li></ul><br>';
        break;
      case 'subscriptions':
        this.title = 'Import Subscriptions Data';
        this.description =
          'Please upload a .xlsx file with the following format (single file upload): <br> - Sheet 1 – Subscriptions – with columns <br>' +
          '<ul><li>Client ID</li>' +
          '<li>Client Name</li><li>Product (ACM/AFM)</li><li>Semester</li>' +
          '<li>Status</li><li>Start Date</li><li>End Date</li><li>Specialties</li>' +
          '</ul>-Sheet 2 – Current Status – with columns' +
          '<ul><li>Client ID</li>' +
          '<li>Client Name</li><li>Product (ACM/AFM)</li>' +
          '<li>Status</li><li>End Date</li><li>Specialties included</li>' +
          '</ul>';
        break;
      case 'upload-data':
        this.title = 'Import Upload Window Details';
        this.description =
          'Please upload a .xlsx file with the following format (single file upload): <br> - Sheet 1 – Upload Window – with following columns <br>' +
          '<ul><li>Cycle</li><li>Cycle Start Date</li><li>Cycle End Date</li><li>Client Code</li>' +
          '</ul>';
        break;
      case 'admin':
        this.title = 'Import Mandatory Column List Data';
        this.description =
          'Please upload a .xlsx file with the following format (single file upload): <br> Sheet – with columns <br>' +
          '<ul><li> Channel </li>' +
          '<li> Program </li>' +
          '<li> Standard field name </li></ul><br>';
        break;
      case 'dashboard':
        this.title = 'Filter-data field mapping';
        this.description =
        'Please upload a .xlsx file with the following format (single file upload): <br> Sheet 1: Filter-ID Mapping – with columns <br>' +
        '<ul><li> Dashboard Section </li>' +
        '<li> Screen </li>' +
        '<li> Report ID </li>' +
        '<li> Client List </li></ul><br>';
      break;
      case 'client':
        this.title = 'Update Client Mapping';
        this.description =
          'Please upload a .xlsx file with the following format (single file upload): <br> Sheet – with columns <br>' +
          '<ul><li> Client </li>' +
          '<li> Bucket </li>' +
          '<li> Sisense Group </li></ul><br>';
        break;
      default:
        this.title = '';
        this.description = '';
    }
  }

  public fileChanged(files: NzUploadChangeParam): void {
    const fileNames = [];
    this.customError = '';
    this.showUploadError = false;
    if (files.type === 'start') {
      files.fileList.forEach((file) => {
        this.fileList.push(file.originFileObj);
      });
    }

    this.showUploadError = this.checkFileType();

    files = undefined;
    this.nzFileList = [];
  }

  public checkFileType(): boolean {
    let isValid = false;
    let validFileType = true;
    this.showUploadError = false;
    this.fileList.forEach((file) => {
      const type = file.name.substring(file.name.length - 4, file.name.length);
      if (type === 'xlsx') {
        isValid = true;
        this.customError = '';
      } else {
        isValid = false;
        validFileType = false;
        this.showUploadError = true;
      }
    });
    return !validFileType;
  }

  public deleteFile(isValid = false): void {
    if (isValid) {
      this.confirmModal = this.modal.confirm({
        nzTitle: 'Delete Uploaded File?',
        nzContent: 'Are you sure you want to delete this file?',
        nzOkText: 'Yes, Delete',
        nzIconType: 'exclamation-circle',
        nzOnOk: () => {
          this.fileList = [];
          this.errorMessage = '';
          this.showUploadError = false;
        },
        nzOnCancel: () => {},
      });
    } else {
      this.fileList = [];
      this.errorMessage = '';
      this.showUploadError = false;
    }
  }

  public handleCancel(): void {
    this.showModal = false;
    let title = '';
    let desciption = '';

    switch (this.page) {
      case 'faq':
        title = 'Leave Upload FAQs?';
        desciption = 'Are you sure you want to leave FAQs import?';
        break;
      case 'subscriptions':
        title = 'Leave Upload Subscriptions?';
        desciption = 'Are you sure you want to leave Subscriptions import?';
        break;
      case 'upload-data':
        title = 'Leave Upload Window?';
        desciption = 'Are you sure you want to leave Upload Window import?';
        break;
      case 'admin':
        title = 'Leave Upload Mandatory Column List?';
        desciption = 'Are you sure you want to leave Mandatory Column List import?';
        break;
      case 'dashboard':
        title = 'Leave Upload Filter IDs mapping?';
        desciption = 'Are you sure you want to leave Filter IDs mapping import?';
        break;
      case 'client':
        title = 'Leave Upload Update Client Mapping?';
        desciption = 'Are you sure you want to leave Update Client Mapping import?';
        break;
      default:
        title = '';
    }

    this.confirmModal = this.modal.confirm({
      nzTitle: title,
      nzContent: desciption,
      nzOkText: 'Yes, Leave',
      nzIconType: 'exclamation-circle',
      nzOnOk: () => {
        this.showModal = false;
        this.fileList = [];
        this.showUploadError = false;
        this.downloadUrl = '';
        this.errorMessage = '';
        this.showModalChange.emit(this.showModal);
      },
      nzOnCancel: () => {
        this.showModal = true;
      },
    });
  }

  public handleOk(): void {
    this.isLoading = true;
    this.formData = new FormData();
    let type = '';
    switch (this.page) {
      case 'faq':
        type = 'faq';
        break;
      case 'subscriptions':
        type = 'subscriptions';
        break;
      case 'upload-data':
        type = 'upload';
        break;
      case 'admin':
        type = 'steward_mc';
        break;
      case 'dashboard':
        type = 'sisense_filter';
        break;
      case 'client':
        type = 'client';
        break;
    }

    const payload = {
      type: type,
      input_file: this.fileList[0],
    };

    this.formData.append('type', type);
    this.formData.append('input_file', this.fileList[0]);

    this.httpService.postRequest('import_portal_data', this.formData, true).subscribe(
      // this.httpService.getRequest('import_portal_data').subscribe(
      (data) => {
        if (data.status === 'SUCCESS') {
          this.showModal = false;
          this.fileList = [];
          this.showUploadError = false;
          this.downloadUrl = '';
          this.showModalChange.emit(this.showModal);
          this.isLoading = false;
          this.createBasicNotification('Success');
        } else {
          this.errorMessage = data.message;
          this.downloadUrl = data.error_url;
          this.isLoading = false;
          this.showUploadError = true;
        }
      },
      (error) => {
        this.errorMessage = 'something went wrong';
        this.showUploadError = true;
        this.isLoading = false;
        this.createBasicNotification('Error');
        console.log('something went wrong: ', error);
      },
    );
  }

  public createBasicNotification(status): void {
    let msg = '';
    let bgColor = '';
    if (status === 'Success' || status === 'Under Verification') {
      msg = 'File successfully uploaded';
      bgColor = '#00AA67';
    } else if (status === 'Incomplete') {
      msg = 'File was created without Upload and other attributes';
      bgColor = '#E3A900';
    } else if (status === 'Custom Success') {
      msg = 'Request sent successfully';
      bgColor = '#00AA67';
    } else {
      msg = 'Something went wrong';
      bgColor = 'red';
    }

    this.notification.blank('', msg, {
      nzPlacement: 'bottomLeft',
      nzStyle: { background: bgColor, color: '#FFFFFF' },
    });
  }

  public downloadFile(url, target): void {
    window.open(url, target);
  }
}
