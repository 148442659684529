import {Component, EventEmitter, Input, OnInit, Output} from '@angular/core';
import {HttpService} from '../../shared/services/http.service';

@Component({
  selector: 'app-template-modal',
  templateUrl: './template-modal.component.html',
  styleUrls: ['./template-modal.component.scss']
})
export class TemplateModalComponent implements OnInit {
  @Input() showTemplates = false;
  @Output() hideTemplates: EventEmitter<boolean> = new EventEmitter<boolean>();

  public templates = [];
  public isLoading = true;

  constructor(
    private httpService: HttpService
  ) { }

  ngOnInit(): void {
    this.httpService.getRequest('get_template_data').subscribe(templates => {
      this.templates = templates.data;
      this.isLoading = false;
    });
  }

  public onCancel(): void {
    this.hideTemplates.emit(false);
  }

  public downloadFile(url, target): void {
    window.open(url, target);
  }

}
