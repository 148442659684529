import { Component, Input, OnInit } from '@angular/core';
import { HttpService } from '../../shared/services/http.service';
import { DatePipe } from '@angular/common';
import { AppState } from '../../shared/services/app-state';
import { Subscription } from 'rxjs';

@Component({
  selector: 'app-file-tracking',
  templateUrl: './file-tracking.component.html',
  styleUrls: ['./file-tracking.component.scss'],
})
export class FileTrackingComponent implements OnInit {
  private appStateSubscription: Subscription;

  constructor(
    private httpService: HttpService,
    private datePipe: DatePipe,
    private appState: AppState,
  ) {
    this.appStateSubscription = AppState.appState.subscribe((val) => {
      if (val && !val.showDropdown) {
        this.showFilter = false;
      }
    });
  }

  public logData = [];
  public searchParamData = [];
  public loading = false;
  public showFilter = false;
  public showFilterClient = false;
  public showFilterUpload = false;
  public showFilterRevo = false;
  public showFilterProfiler = false;
  public showFilterCurrent = false;
  public client = [];
  public uploadStatus = [];
  public profilerStatus = [];
  public revoStatus = [];
  public currentStep = [];
  public sortOrder = 'descend';
  public sortDirection = ['ascend', 'descend'];
  public pageIndex = 1;
  public openModal = false;
  public payload = {
    filters: {
      status_revo: [],
      status_profiler: [],
      status_upload: [],
      client: [],
      date: 'descend',
      status_current_step: []
    },
    search: '',
  };
  public noDataMessage = 'There are no logs to display';
  public id = 0;

  ngOnInit(): void {
    this.loading = true;
    this.loadData();
  }

  async loadData(): Promise<void> {
    await this.httpService.postRequest('get_file_tracking_log', this.payload).toPromise().then((data) => {
    // await this.httpService.getRequest('get_file_tracking_log').toPromise().then((data) => {
      this.logData = data.data;
      this.client = data.client;
      this.uploadStatus = data.status_upload;
      this.profilerStatus = data.status_profiler;
      this.revoStatus = data.status_revo;
      this.currentStep = data.status_current_step;
      this.searchParamData = data.search_keywords;
      this.loading = false;
    });
  }
  public getDate(date): string {
    try {
      return this.datePipe.transform(new Date(date), 'MM/d/yy h:mm a');
    } catch (e) {
      return 'invalid date';
    }
  }
  public openChange(event): void {
    this.appState.setGlobalState('showDropdown', event);
  }
  public highlightFilterClient(): boolean {
    return this.client.some((client) => client.value === true);
  }
  public highlightFilterUpload(): boolean {
    return this.uploadStatus.some((status) => status.value === true);
  }
  public highlightFilterRevo(): boolean {
    return this.revoStatus.some((status) => status.value === true);
  }
  public highlightFilterProfiler(): boolean {
    return this.profilerStatus.some((status) => status.value === true);
  }
  public highlightFilterCurrentStep(): boolean {
    return this.currentStep.some((status) => status.value === true);
  }

  public filter(dateModified): void {
    this.pageIndex = 1;
    this.showFilterUpload = false;
    this.showFilterProfiler = false;
    this.showFilterCurrent = false;
    this.showFilterClient = false;
    this.showFilterRevo = false;
    this.loading = true;
    const uploadTemp = [];
    const profilerTemp = [];
    const currentTemp = [];
    const clientTemp = [];
    const revoTemp = [];
    this.client.forEach((c) => {
      if (c.value) {
        clientTemp.push(c.text);
      }
    });
    this.uploadStatus.forEach((u) => {
      if (u.value) {
        uploadTemp.push(u.text);
      }
    });
    this.profilerStatus.forEach((p) => {
      if (p.value) {
        profilerTemp.push(p.text);
      }
    });
    this.currentStep.forEach((s) => {
      if (s.value) {
        currentTemp.push(s.text);
      }
    });
    this.revoStatus.forEach((r) => {
      if (r.value) {
        revoTemp.push(r.text);
      }
    });
    if (dateModified) {
      if (this.sortOrder === 'ascend') {
        this.sortOrder = 'descend';
      } else {
        this.sortOrder = 'ascend';
      }
    }
    this.payload = {
      filters: {
        status_revo: revoTemp,
        status_profiler: profilerTemp,
        status_upload: uploadTemp,
        client: clientTemp,
        date: this.sortOrder,
        status_current_step: currentTemp,
      },
      search: '',
    };
    this.loadData();
  }
  public resetClient(): void {
    this.client.forEach((client) => {
      client.value = false;
    });
    this.filter(false);
  }
  public resetUpload(): void {
    this.uploadStatus.forEach((upload) => {
      upload.value = false;
    });
    this.filter(false);
  }
  public resetProfiler(): void {
    this.profilerStatus.forEach((profiler) => {
      profiler.value = false;
    });
    this.filter(false);
  }
  public resetCurrent(): void {
    this.currentStep.forEach((step) => {
      step.value = false;
    });
    this.filter(false);
  }
  public resetRevo(): void {
    this.revoStatus.forEach((revo) => {
      revo.value = false;
    });
    this.filter(false);
  }
  public filterOnSearch(key): void {
    this.pageIndex = 1;
    this.payload.search = key.trim();

    this.payload.search = this.payload.search.replace('zs\\', '');
    this.loading = true;
    this.loadData();
  }
  public resetSearch(event): void {
    if (event) {
      this.pageIndex = 1;
      this.payload.search = '';
      this.noDataMessage = 'There are no logs to display';
      this.loading = true;
      this.loadData();
    }
  }
  public formatOnNoResult(event): void {
    if (event) {
      this.pageIndex = 1;
      this.noDataMessage = 'Your search returned no results.';
    }
  }

  public openProcessModal(id): void {
    this.openModal = true;
    this.id = id;
  }

    public onReviewClose(event): void {
    this.openModal = event;
  }

  public defineClass(status): string {
    if (status === 'Success'){
      return 'success';
    } else if (status === 'Issues'){
      return 'issues';
    } else {
      return 'row-data';
    }
  }

}
