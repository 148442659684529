import { Component, OnInit } from '@angular/core';
import { Subscription } from 'rxjs';
import { AppState } from '../shared/services/app-state';

@Component({
  selector: 'app-admin',
  templateUrl: './admin.component.html',
  styleUrls: ['./admin.component.scss'],
})
export class AdminComponent implements OnInit {
  public showDrawer = true;
  public drawerContent = [
    {
      title: 'Activity Log',
      selected: true,
    },
    {
      title: 'Data Changes',
      selected: false,
    },
     {
      title: 'Steward Feedback',
      selected: false,
    }, {
      title: 'File Tracking Log',
      selected: false,
    }, {
      title: 'Program Management',
      selected: false
    }, {
      title: 'Client Management',
      selected: false
    }, 
    {
      title: 'Customer Master Mapping',
      selected: false
    }, {
      title: 'Activity Files Mapping',
      selected: false
    }
  ];
  private appStateSubscription: Subscription;

  constructor() {
    this.appStateSubscription = AppState.appState.subscribe((val) => {
      if (typeof val === 'string') {
        val = JSON.parse(val);
      }
      if (val && val.showDrawer) {
        this.showDrawer = val.showDrawer;
      }
    });
  }

  ngOnInit(): void {}

  public switchTab(selected): void {
    // TODO: update below condition and code when other tabs are added
    switch (selected) {
      case 'Activity Log':
        {
          this.drawerContent[0].selected = true;
          this.drawerContent[1].selected = false;
          this.drawerContent[2].selected = false;
          this.drawerContent[3].selected = false;
          this.drawerContent[4].selected = false;
          this.drawerContent[5].selected = false;
          this.drawerContent[6].selected = false;
          this.drawerContent[7].selected = false;
        }
        break;
      case 'Data Changes':
        {
          this.drawerContent[0].selected = false;
          this.drawerContent[1].selected = true;
          this.drawerContent[2].selected = false;
          this.drawerContent[3].selected = false;
          this.drawerContent[4].selected = false;
          this.drawerContent[5].selected = false;
          this.drawerContent[6].selected = false;
          this.drawerContent[7].selected = false;
        }
        break;
      case 'Steward Feedback':
        {
          this.drawerContent[0].selected = false;
          this.drawerContent[1].selected = false;
          this.drawerContent[2].selected = true;
          this.drawerContent[3].selected = false;
          this.drawerContent[4].selected = false;
          this.drawerContent[5].selected = false;
          this.drawerContent[6].selected = false;
          this.drawerContent[7].selected = false;
        }
        break;
      case 'File Tracking Log':
        {
          this.drawerContent[0].selected = false;
          this.drawerContent[1].selected = false;
          this.drawerContent[2].selected = false;
          this.drawerContent[3].selected = true;
          this.drawerContent[4].selected = false;
          this.drawerContent[5].selected = false;
          this.drawerContent[6].selected = false;
          this.drawerContent[7].selected = false;
        }
        break;
      case 'Program Management':
        {
          this.drawerContent[0].selected = false;
          this.drawerContent[1].selected = false;
          this.drawerContent[2].selected = false;
          this.drawerContent[3].selected = false;
          this.drawerContent[4].selected = true;
          this.drawerContent[5].selected = false;
          this.drawerContent[6].selected = false;
          this.drawerContent[7].selected = false;
        }
        break;
      case 'Client Management':
        {
          this.drawerContent[0].selected = false;
          this.drawerContent[1].selected = false;
          this.drawerContent[2].selected = false;
          this.drawerContent[3].selected = false;
          this.drawerContent[4].selected = false;
          this.drawerContent[5].selected = true;
          this.drawerContent[6].selected = false;
          this.drawerContent[7].selected = false;
        }
        break;
      case 'Customer Master Mapping':
        {
          this.drawerContent[0].selected = false;
          this.drawerContent[1].selected = false;
          this.drawerContent[2].selected = false;
          this.drawerContent[3].selected = false;
          this.drawerContent[4].selected = false;
          this.drawerContent[5].selected = false;
          this.drawerContent[6].selected = true;
          this.drawerContent[7].selected = false;
        }
        break;
      case 'Activity Files Mapping':
        {
          this.drawerContent[0].selected = false;
          this.drawerContent[1].selected = false;
          this.drawerContent[2].selected = false;
          this.drawerContent[3].selected = false;
          this.drawerContent[4].selected = false;
          this.drawerContent[5].selected = false;
          this.drawerContent[6].selected = false;
          this.drawerContent[7].selected = true;
        }
    }
  }
}
