<div class="search-wrapper">
  <nz-input-group [nzSuffix]="suffixIconSearch">
    <input
      placeholder="Search"
      #searchText
      nz-input
      [(ngModel)]="inputValue"
      (input)="onInput($event)"
      (keyup)="searchCheck()"
      [nzAutocomplete]="auto"
    />
  </nz-input-group>

  <ng-template #suffixIconSearch>
    <i *ngIf="!inputValue" nz-icon nzType="search" nzTheme="outline"></i>
    <i
      *ngIf="inputValue"
      nz-icon
      nzType="close-circle"
      nzTheme="outline"
      (click)="onSearchClose()"
    ></i>
  </ng-template>

  <nz-autocomplete (selectionChange)="filterSearch($event)" nzBackfill #auto>
    <div class="autocomplete-wrapper">
      <nz-auto-option *ngFor="let data of searchSuggestions" [nzValue]="data" [title]="data">
        {{data}}
      </nz-auto-option>
    </div>
  </nz-autocomplete>
</div>
