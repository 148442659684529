import { Component, EventEmitter, Input, OnInit, Output } from '@angular/core';
import { HttpService } from '../../shared/services/http.service';
import { Globals } from '../../shared/constants/globals';

@Component({
  selector: 'app-file-preview-modal',
  templateUrl: './file-preview-modal.component.html',
  styleUrls: ['./file-preview-modal.component.scss'],
})
export class FilePreviewModalComponent implements OnInit {
  @Input() previewFiles = false;
  @Input() fileList = [];
  @Output() previewFilesChange = new EventEmitter<boolean>();

  public previewHeaders = [];
  public previewData = [];
  public previewTitle = '';
  public previewLoading = true;
  public showPreview = false;
  public multipleFiles = false;
  public message = '';

  constructor(private httpService: HttpService, public globals: Globals) {}

  ngOnInit(): void {
    this.multipleFiles = this.fileList.length > 1;
    if (!this.multipleFiles) {
      let fileId = 0;
      this.fileList.forEach((file) => {
        fileId = file.file_id;
      });
      this.openFile(fileId);
    }
  }
  public handleCancel(): void {
    this.previewFilesChange.emit(false);
    this.showPreview = false;
  }
  public handleCancelPreview(): void {
    this.previewFilesChange.emit(false);
  }
  public openFile(fileId): void {
    const payload = {
      file_id: fileId,
      client: this.globals.userDetail.client,
    };
    this.httpService.postRequest('get_file_data', payload).subscribe((data) => {
    // this.httpService.getRequest('get_file_data').subscribe((data) => {
      if (data['status'] === 'SUCCESS') {
        this.previewData = data.tbody;
        this.previewHeaders = data.thead;
        this.previewTitle = data.title;
        this.previewLoading = false;
      } else {
        this.previewLoading = false;
        this.previewTitle = data.title;
        this.message = data['message'];
      }
    });
    if (this.multipleFiles) {
      this.showPreview = true;

    }
  }
}
