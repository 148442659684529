import {Component, OnInit, OnDestroy} from '@angular/core';
import {environment} from 'src/environments/environment';
import {AppState} from '../shared/services/app-state';
import {Subscription} from 'rxjs';
import { HttpService } from '../shared/services/http.service';
import { HttpClient, HttpHeaders } from '@angular/common/http';
import { catchError } from 'rxjs/operators';
import { throwError } from 'rxjs';


 


@Component({
  selector: 'app-client-selection',
  templateUrl: './client-selection.component.html',
  styleUrls: ['./client-selection.component.scss']
})
export class ClientSelectionComponent implements OnInit, OnDestroy {

  loginUrl = environment.authLoginUrl;

  public selectedClient = '';
  clientsList = [];
  invalidClient = false;
  loading = false;
  private appStateSubscription: Subscription;

  public selectedEmailId = '';
  invalidEmailId = false;
  formData: FormData;
  errorMessage = false;
  
  constructor(private httpService: HttpService, private httpClient: HttpClient) {
    this.appStateSubscription = AppState.appState.subscribe((val) => {
      if (val) {
        this.loading = val?.clientLoading;
      }
    });
  }
  
  ngOnInit(): void {
    this.loadClients();
  }

  loadClients(): void {
    environment.client.forEach((i: any) => {
        this.clientsList.push(i.name);
      }
    );
  }

  navigateToIDM(): void {
    let found = false;
    
    const payload = {
      client: this.selectedClient.trim(),
      email_id: this.selectedEmailId.trim(),
      headers: new HttpHeaders({
        'Content-Type': 'application/json'
      })
    };
  

    this.httpService.postRequest('validate_client_mail_id',payload )
    .pipe(
      catchError(error => {
        let errorMessage;
        if (error.status === 400 || error.status === 500) {
          this.errorMessage = true;
        }   
        return throwError(errorMessage);
      })
    ).subscribe((data) => {
        if (data['status'] === 'SUCCESS') {
          environment.client.forEach((i: any) => {
            if (i.name.toLowerCase() === this.selectedClient.toLowerCase().trim()) {
              window.location.href = i.authLoginUrl;
              found = true;
            }
          });
        } 
        else {
          this.invalidClient = true;  
             }
      });
  }

  ngOnDestroy(): void {
    
  }

}
