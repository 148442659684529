import {Component, Input, OnInit, Output, EventEmitter} from '@angular/core';

@Component({
  selector: 'app-upload-options',
  templateUrl: './upload-options.component.html',
  styleUrls: ['./upload-options.component.scss']
})
export class UploadOptionsComponent implements OnInit {

  @Input() selectedOption;
  @Output() selectedOptionChange = new EventEmitter<number>();

  public options = [
    {
      optionId: 1,
      isSelected: true,
      title: 'Single File',
      description: 'Upload a single file that maps to appropriate channels and their respective programs.',
      example: 'Example: “call_activity_s221.csv” which can be mapped to Personal Face-to-Face: Live Rep Visits and Personal Remote: Live electronic rep interactions“'
    },
    {
      optionId: 2,
      isSelected: false,
      title: 'Multiple Files with Single Mapping',
      description: 'Upload multiple files all of which map to the same channels and their respective programs. To be used when all files follow a single template but are split as separate according to categories like time, geography, etc.',
      example: 'Example: “digital_push_email_jul21.csv”, “digital_push_email_aug21.csv” and “digital_push_email_sep21.csv” all of which map to Digital Push: Email (Rep-triggered, Home office or Third-party)'
    },
    {
      optionId: 3,
      isSelected: false,
      title: 'Multiple Files with Multiple Mappings',
      description: 'Upload multiple files each of which maps to different channels and their respective programs, and so, use different templates, but contain data for the same date range. In case the data in the files are for different date ranges, they will need to be added as separate uploads.',
      example: 'Example: “digital_pull_online_ads_q121.csv”, “digital_push_email_q121.csv” and “peer_interaction_speaker_q121.csv” which map to Digital Pull: Online Ads, Digital Push: Email (Rep-triggered, Home office or Third-party) and Peer Interactions: Speaker Programs respectively, and contain data for Q1’21'
    }
  ];

  constructor() { }

  ngOnInit(): void {
  }

  public optionChanged(): void {
    this.selectedOptionChange.emit(this.selectedOption);
  }
}
