import { Component, OnInit } from '@angular/core';
import { HttpService } from '../../../shared/services/http.service';

@Component({
  selector: 'app-customer-master',
  templateUrl: './customer-master.component.html',
  styleUrls: ['./customer-master.component.scss'],
})
export class CustomerMasterComponent implements OnInit {
  public clientCount = 0;
  public totalCount = 0;
  public uniqueCount = 0;
  public AFMCount = 0;
  public downloadUrl = '';
  public headers;
  public fileData;
  public feedbackHeaders;
  public feedbackFileData;
  public uploadHeaders;
  public uploadFileData;
  public uploadStats = {
    countValues: {
      uploadTotalCount: 0,
      uploadUniqueCount: 0,
      uploadClientCount: 0,
      uploadAFMCount: 0
    },
    tableData: {
      tbody: [],
      thead: []
    },
    feedbackTableData: {
      tbody: [],
      thead: {}
    }
  };

  constructor(private httpService: HttpService) {}

  ngOnInit(): void {
    this.loadData();
  }

  public loadData(): void {
    const payload = {
      type: 'Speciality'
    };

    // this.httpService.getRequest('get_automation_mapping_response').subscribe((data) => {
    this.httpService.postRequest('get_automation_mapping_response', payload).subscribe((data) => {
      console.log('data: ', data);
      this.clientCount = data.customer_mapping_data.count_values.client_count;
      this.totalCount = data.customer_mapping_data.count_values.total_count;
      this.uniqueCount = data.customer_mapping_data.count_values.unique_count;
      this.AFMCount = data.customer_mapping_data.count_values.unique_afm_ss;
      this.downloadUrl = data.download_url;
      this.headers = data.customer_mapping_data.table_data.thead;
      this.fileData = data.customer_mapping_data.table_data.tbody;
      this.feedbackHeaders = data.customer_mapping_data.feedback_table_data.thead;
      this.feedbackFileData = data.customer_mapping_data.feedback_table_data.tbody;
      this.uploadStats.countValues.uploadTotalCount = data.manual_review_data.count_values.upload_total_count;
      this.uploadStats.countValues.uploadUniqueCount = data.manual_review_data.count_values.upload_unique_count;
      this.uploadStats.countValues.uploadClientCount = data.manual_review_data.count_values.upload_client_count;
      this.uploadStats.countValues.uploadAFMCount = data.manual_review_data.count_values.upload_unique_afm_ss;
      this.uploadStats.tableData = data.manual_review_data.table_data;
      this.uploadStats.feedbackTableData = data.manual_review_data.feedback_table_data;
    });
  }

  public getUploadStats(data): void {
    this.uploadStats = data;
    this.loadData();
  }

}
