<nz-layout>
  <nz-content class="activity-log-nz-content">
    <div class="space-align-container">
      <span nz-typography class="tab-header">Activity Log</span>
      <nz-space [nzSize]="'middle'">
        <app-search-bar
          [searchParameter]="searchParamData"
          (searchValue)="filterOnSearch($event)"
          (noResultFound)="formatOnNoResult($event)"
          (noInput)="resetSearch($event)"
          (resetSearch)="resetSearch($event)"
          (inputKey)="filterOnSearch($event)"
          *nzSpaceItem
        ></app-search-bar>
      </nz-space>
    </div>
    <nz-table
      class="activity-log-table"
      #previewTable
      [nzData]="logData"
      nzTableLayout="fixed"
      [nzShowPagination]="true"
      [nzLoading]="loading"
      [nzNoResult]="noResultFound"
      [nzPageSize]="8"
      [(nzPageIndex)]="pageIndex"
      [nzShowQuickJumper]="true"
    >
      <thead>
        <tr>
          <ng-container *ngFor="let column of logHeaders">
             <th
              *ngIf="column.key === 'status'"
              [nzWidth]="getWidth(column.key)"
              nzCustomFilter
            >
              {{ column.value }}
               <nz-filter-trigger [nzDropdownMenu]="customFilter" [(nzVisible)]="showFilter" (nzVisibleChange)="openChange($event)">
                 <i nz-icon nzType="filter" nzTheme="fill" [ngClass]="highlightFilterStatus() ? 'highlight' : ''"></i>
              </nz-filter-trigger>
               <nz-dropdown-menu #customFilter = "nzDropdownMenu">
               <div class="dropdown-container">
              <div class="ant-table-filter-dropdown">
                <ul nz-menu *ngFor="let item of status">
                  <li nz-menu-item><label nz-checkbox [(ngModel)]= "item.value"> {{ item.text }} </label></li>
                </ul>
              </div>
              <div class="button-wrapper">
                <a (click)="resetStatus()">Reset</a>&nbsp;
                <button  nz-button
                  nzType="primary"
                  class="app-primary-button button-class"
                 (click)="filterStatus()"
                >OK</button>
              </div>
            </div>
          </nz-dropdown-menu>
            </th>
            <th
              *ngIf="column.key === 'tab'"
              [nzWidth]="getWidth(column.key)"
              nzCustomFilter
              class="tab-filter"
            >
              {{ column.value }}
               <nz-filter-trigger [nzDropdownMenu]="customFilterTab" [(nzVisible)]="showFilterTab" (nzVisibleChange)="openChange($event)">
                 <i nz-icon nzType="filter" nzTheme="fill" [ngClass]="highlightFilterTab() ? 'highlight' : ''"></i>
              </nz-filter-trigger>
               <nz-dropdown-menu #customFilterTab = "nzDropdownMenu">
               <div class="dropdown-container">
              <div class="ant-table-filter-dropdown">
                <ul nz-menu *ngFor="let item of tab">
                  <li nz-menu-item><label nz-checkbox [(ngModel)]= "item.value"> {{ item.text }} </label></li>
                </ul>
              </div>
              <div class="button-wrapper">
                <a (click)="resetTab()">Reset</a>&nbsp;
                <button  nz-button
                  nzType="primary"
                  class="app-primary-button button-class"
                 (click)="filterTab()"
                >OK</button>
              </div>
            </div>
          </nz-dropdown-menu>
            </th>
            <th
              *ngIf="column.key === 'date'; else nonSortable"
              [nzWidth]="getWidth(column.key)"
              nzCustomFilter
              class="date-filter"
            >
              {{ column.value }}
              <nz-filter-trigger [nzDropdownMenu]="customFilterDate" [(nzVisible)]="showFilterDate" (nzVisibleChange)="openChange($event)">
                 <i nz-icon nzType="filter" nzTheme="fill" [ngClass]="highlightFilterDate() ? 'highlight' : ''"></i>
              </nz-filter-trigger>
               <nz-dropdown-menu #customFilterDate = "nzDropdownMenu">
               <div class="dropdown-container">
              <div class="ant-table-filter-dropdown">
                <ul nz-menu *ngFor="let item of date">
                  <li nz-menu-item><label nz-radio [(ngModel)]= "item.value" (click)="updateDateFilter(item, date)"> {{ item.text }} </label></li>
                </ul>
              </div>
              <div class="button-wrapper">
                <a (click)="resetDate()">Reset</a>&nbsp;
                <button  nz-button
                  nzType="primary"
                  class="app-primary-button button-class"
                  (click)="filterDate()"
                >OK</button>
              </div>
            </div>
          </nz-dropdown-menu>
            </th>
            <ng-template #nonSortable>
              <th [nzWidth]="getWidth(column.key)" *ngIf="column.key !== 'date' && column.key !== 'status' && column.key !== 'tab'">
                {{ column.value === 'Actions' ? 'View Info' : column.value }}
              </th>
            </ng-template>
          </ng-container>
        </tr>
      </thead>
      <tbody>
        <tr *ngFor="let data of previewTable.data">
          <ng-container *ngFor="let column of logHeaders">
            <td *ngIf="column.key === 'date'">{{ getDate(data[column.key]) }}</td>
            <td class="email" *ngIf="column.key === 'user'">
              <a (click)="generateMailData(data['email'], data['user'], data['description'])">{{
                data[column.key]
              }}</a>
            </td>
            <td
              *ngIf="column.key === 'status'; else showColumns"
              [ngClass]="{
                'status-failed': data[column.key] === 'Failed'
              }"
            >
              <i
                *ngIf="data[column.key] === 'Failed'"
                class="fail-icon"
                nz-icon
                nzType="exclamation-circle"
                nzTheme="fill"
              ></i>
              {{ data[column.key] }}
            </td>
            <td *ngIf="column.key === 'action'"
                (click)="openInfo(data['tab'], data['action'], data['status'])"
                class = "view-icon"
            ><i class="app-style-mid" nz-icon nzType="eye" nzTheme="outline"></i>
<!--              <span class="view-info" >View info</span>-->
            </td>
            <ng-template #showColumns>
              <td *ngIf="column.key !== 'date' && column.key !== 'user' && column.key !== 'action'">
                {{ data[column.key] ? data[column.key] : '-' }}
              </td>
            </ng-template>
          </ng-container>
        </tr>
      </tbody>
    </nz-table>
  </nz-content>
</nz-layout>

<ng-template #noResultFound>
  <nz-empty nzNotFoundImage="simple" [nzNotFoundContent]="noResultTpl">
    <ng-template #noResultTpl>
      <p class="negate-margin">{{ noDataMessage }}</p>
    </ng-template>
  </nz-empty>
</ng-template>

<app-drawer
  [(showDrawer)]="showDrawer"
  [title]="'More Information'"
  [placement]="'right'"
  [offsetX]="0"
  [content]="drawerContent"
  [closableIcon]="true"
  [maskClosable]="false"
></app-drawer>

