import {Component, OnInit} from '@angular/core';
import {environment} from 'src/environments/environment';
import {AppState} from '../shared/services/app-state';
import {Router} from '@angular/router';
import {Globals} from '../shared/constants/globals';
import {Subscription} from 'rxjs';

@Component({
  selector: 'app-landing-page',
  templateUrl: './landing-page.component.html',
  styleUrls: ['./landing-page.component.scss']
})
export class LandingPageComponent implements OnInit {
  private appStateSubscription: Subscription;
  public userDetails;

  public showDrawer = false;
  public drawerContent = [];
  public IDMDetails;

  constructor(private appState: AppState, private router: Router, private global: Globals) {
    this.appStateSubscription = AppState.appState.subscribe((val) => {

      if (!this.userDetails) {
        if (val && val.userDetails) {
          this.userDetails = val.userDetails;
          this.checkAccess();
        }
      }

    });
  }

  ngOnInit(): void {
    if (this.userDetails) {
      this.checkAccess();
    }
    this.drawerContent.push({
      title: 'Sign Out',
      selected: false,
      url: environment.authLogOutUrl
    });
  }

  public checkAccess(): void {
    const pathArray = window.location.href.split('/');
    if (this.userDetails.client !== pathArray[pathArray.length - 2] || pathArray.length !== 2) {
      if (this.userDetails.client === pathArray[pathArray.length - 2] && pathArray[pathArray.length - 1] === 'profile') {
        this.showDrawer = true;
      } else {
        if (this.userDetails.role === 'admin' || this.userDetails.role === 'manager') {
          this.appState.setGlobalState('activeRoute', '/admin');
          this.router.navigateByUrl(this.userDetails.client + '/admin');
        } else {
          this.appState.setGlobalState('activeRoute', '/upload-data');
          this.router.navigateByUrl(this.userDetails.client + '/upload-data');
          // this.appState.setGlobalState('activeRoute', '/subscriptions');
          // this.router.navigateByUrl(this.userDetails.client + '/subscriptions');
        }
      }
    }
  }

  public switchTab(tab): void {
    const selectedTab = this.drawerContent.find(app => app.title === tab);
    this.appState.clearGlobalState();
    window.location.href = selectedTab.url;
  }
}
