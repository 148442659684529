import {Component, OnDestroy, OnInit, ViewChild} from '@angular/core';
import { HttpService } from '../shared/services/http.service';
import { SubscriptionTableComponent } from './shared/subscription-table/subscription-table.component';
import {AppState} from '../shared/services/app-state';
import {Subscription} from 'rxjs';
import { Globals } from '../shared/constants/globals';

@Component({
  selector: 'app-subscriptions',
  templateUrl: './subscriptions.component.html',
  styleUrls: ['./subscriptions.component.scss'],
})
export class SubscriptionsComponent implements OnInit, OnDestroy {
  @ViewChild(SubscriptionTableComponent) table: SubscriptionTableComponent;

  public menuItems = [
    {
      id: 1,
      name: 'AffinityMonitor®',
      isSelected: true,
      route: '/afm',
      isVisible: true,
    },
    {
      id: 2,
      name: 'AccessMonitor™',
      isSelected: false,
      route: '/acs',
      isVisible: true,
    },
  ];

  public selectedTab = 'AffinityMonitor®';

  public listOfData = [];
  public headerData = {
    current_status: '',
  };

  public acmData = [];
  public afmData = [];

  public acmHeader = {
    current_status: '',
  };
  public afmHeader = {
    current_status: '',
  };
  public specialities = [];
  public isDataLoaded = false;
  public showDrawer = true;
  public drawerContent = [
    {
      title: 'AffinityMonitor®',
      selected: true,
    },
    {
      title: 'AccessMonitor™',
      selected: false,
    },
  ];
  private appStateSubscription: Subscription;
  public emailContent =
    'mailto:support@zs.com?subject=Subscription%20Query%20for%20Affinity%20Monitor&body=Hi%20ZS%20Affinity%20team,%0D%0A%0D%0AWe%20would%20like%20to%20inquire%20about%20subscribing%20to%20AffinityMonitor%20for%20the%20scope%20below:%0D%0A%0D%0ACompany:%0D%0A%0D%0ASalesforce%20size%20(rep%20count):%0D%0A%0D%0AHCP%20specialties%20targeted:%0D%0A%0D%0AEstimated%20target%20universe%20size%20(HCP%20count):%0D%0A%0D%0ATime%20period%20of%20interest%20(if%20not%20the%20latest):%0D%0A%0D%0AThank%20you,';
  public isAdmin = false;
  public clientList = [];
  public selectedClient = '';
  public userDetails;

  constructor(
    private httpService: HttpService,
    private appState: AppState,
    private globals: Globals,
  ) {
    if (this.globals.userDetail) {
      this.isAdmin = this.globals.userDetail.role === 'admin' || this.globals.userDetail.role === 'manager';
    }

    this.appStateSubscription = AppState.appState.subscribe((val) => {
      if (typeof val === 'string') {
        val = JSON.parse(val);
      }
      if (val && val.showDrawer) {
        this.showDrawer = val.showDrawer;
      }

      if (!this.userDetails) {
        if (val && val.userDetails) {
          this.userDetails = val.userDetails;
          this.isAdmin = this.userDetails.role === 'admin' || this.userDetails.role === 'manager';
        }
      }
    });

    if (this.isAdmin) {
      this.clientList = this.userDetails.clientList;
    }
  }

  ngOnInit(): void {
    if (!this.isAdmin || this.selectedClient) {
      this.loadData();
    }
  }

  loadData(): void {
    let payload;
    if (this.isAdmin) {
      payload = {
        client: this.selectedClient,
      };
    } else {
      payload = {
        client: this.userDetails.client,
      };
    }
    this.httpService.postRequest('get_subscription_data', payload).subscribe((result) => {
    // this.httpService.getRequest('get_subscription_data').subscribe((result) => {
      this.afmData = result.data.affinity_monitor.body_content;
      this.acmData = result.data.access_monitor.body_content;
      this.afmHeader = result.data.affinity_monitor.header;
      this.acmHeader = result.data.access_monitor.header;
      const tmp = [];
      result.data.specialties.map((specialty) => {
        tmp.push({ text: specialty, value: false });
      });
      this.specialities = tmp;

      // TODO: Check if below condition is still required and update accordingly.
      // if (result.data.affinity_monitor.header.current_status === 'Not Subscribed') {
      //   this.menuItems = [
      //     {
      //       id: 1,
      //       name: 'AccessMonitor™',
      //       isSelected: true,
      //       route: '/acs',
      //       isVisible: true,
      //     },
      //   ];
      //   this.listOfData = [...this.acmData];
      //   this.headerData = this.acmHeader;
      // }
      // if (result.data.access_monitor.header.current_status === 'Not Subscribed') {
      //   this.menuItems = [
      //     {
      //       id: 1,
      //       name: 'AffinityMonitor®',
      //       isSelected: true,
      //       route: '/acs',
      //       isVisible: true,
      //     },
      //   ];
      //   this.listOfData = [...this.afmData];
      //   this.headerData = this.afmHeader;
      // }
      if (this.menuItems.length > 1) {
        if (this.selectedTab === 'AffinityMonitor®') {
          this.listOfData = [...this.afmData];
          this.headerData = this.afmHeader;
          this.drawerContent[0].selected = true;
          this.drawerContent[1].selected = false;
          this.emailContent =
            'mailto:support@zs.com?subject=Subscription%20Query%20for%20AffinityMonitor&body=Hi%20ZS%20Affinity%20team,%0D%0A%0D%0AWe%20would%20like%20to%20inquire%20about%20subscribing%20to%20AffinityMonitor%20for%20the%20scope%20below:%0D%0A%0D%0ACompany:%0D%0A%0D%0ASalesforce%20size%20(rep%20count):%0D%0A%0D%0AHCP%20specialties%20targeted:%0D%0A%0D%0AEstimated%20target%20universe%20size%20(HCP%20count):%0D%0A%0D%0ATime%20period%20of%20interest%20(if%20not%20the%20latest):%0D%0A%0D%0AThank%20you,';
        } else {
          this.listOfData = [...this.acmData];
          this.headerData = this.acmHeader;
          this.drawerContent[0].selected = false;
          this.drawerContent[1].selected = true;
          this.emailContent =
            'mailto:support@zs.com?subject=Subscription%20Query%20for%20AccessMonitor&body=Hi%20ZS%20Access%20team,%0D%0A%0D%0AWe%20would%20like%20to%20inquire%20about%20subscribing%20to%20AccessMonitor%20for%20the%20scope%20below:%0D%0A%0D%0ACompany:%0D%0A%0D%0ASalesforce%20size%20(rep%20count):%0D%0A%0D%0AHCP%20specialties%20targeted:%0D%0A%0D%0AEstimated%20target%20universe%20size%20(HCP%20count):%0D%0A%0D%0ATime%20period%20of%20interest%20(if%20not%20the%20latest):%0D%0A%0D%0AThank%20you,';
        }
      }
      this.isDataLoaded = true;
    });
  }

  switchTab(selected): void {
    this.isDataLoaded = false;
    this.selectedTab = selected;
    if (!this.isAdmin) {
      this.table.resetTable();
      this.loadData();
    } else {
      this.selectedClient = '';
      if (selected === 'AccessMonitor™') {
        this.drawerContent[0].selected = false;
        this.drawerContent[1].selected = true;
      } else {
        this.drawerContent[0].selected = true;
        this.drawerContent[1].selected = false;
      }
    }
  }

  ngOnDestroy(): void {
    this.appStateSubscription.unsubscribe();
  }

  public clientSelected(event): void {
    this.selectedClient = event;
    if (this.selectedClient) {
      this.isDataLoaded = false;
      this.loadData();
    }
  }
}
