import { Component, OnInit } from '@angular/core';

@Component({
  selector: 'app-affex',
  templateUrl: './affex.component.html',
  styleUrls: ['./affex.component.scss'],
})
export class AffexComponent implements OnInit {
  constructor() {}

  ngOnInit(): void {}
}
