<div class="template-wrapper">
  <nz-modal
    nzTitle="View Template"
    [(nzVisible)]="showTemplates"
    [nzFooter]="null"
    (nzOnCancel)="onCancel()"
    [nzWidth]="'820px'"
    [nzWrapClassName]="'modal-wrapper'"
    [nzMaskClosable]="false"
  >
    <ng-container *nzModalContent>
      <div class="spinner">
        <nz-spin nzSimple *ngIf="isLoading" nzSize="large"></nz-spin>
      </div>
      <div class="modal-body-wrapper" *ngIf="!isLoading" style="padding: 24px">
        <p class="view-template-info">
          Please use the below templates to upload channel specific files for further processing.
          Contact AffinityMonitor team at support@zs.com for any queries.
        </p>
        <ng-container *ngFor="let template of templates">
          <div nz-row [nzGutter]="[8, 0]">
            <div nz-col nzSpan="8" class="template-channel">
              <span *ngIf="template.template_id == 1" class="template-icons">
                <i nz-icon nzType="user" nzTheme="outline" class="temp-1"></i>
              </span>
              <span *ngIf="template.template_id == 2" class="template-icons">
                <i nz-icon nzType="shopping" nzTheme="fill" class="temp-2"></i>
              </span>
              <span *ngIf="template.template_id == 3" class="template-icons">
                <i nz-icon nzType="team" nzTheme="outline" class="temp-3"></i>
              </span>
              <span *ngIf="template.template_id == 4" class="template-icons">
                <i nz-icon nzType="import" nzTheme="outline" class="temp-4"></i>
              </span>
              <span *ngIf="template.template_id == 5" class="template-icons">
                <i nz-icon nzType="cloud-download" nzTheme="outline" class="temp-5"></i>
              </span>
              <span *ngIf="template.template_id == 6" class="template-icons">
                <i nz-icon nzType="mail" nzTheme="outline" class="temp-6"></i>
              </span>
              <span *ngIf="template.template_id == 7" class="template-icons">
                <i nz-icon nzType="solution" nzTheme="outline" class="temp-7"></i>
              </span>
              <span *ngIf="template.template_id == 8" class="template-icons">
                <i nz-icon nzType="dollar" nzTheme="outline" class="temp-8"></i>
              </span>
              {{ template.channel }}
              <button
                nz-button
                nz-popover
                nzPopoverTitle=" {{ template.channel }}"
                nzPopoverContent="{{ template.info }}"
                nzPopoverPlacement="rightTop"
                class="info-button"
              >
                <i nz-icon nzType="info-circle" nzTheme="fill" class="info-icon"></i>
              </button>
            </div>
            <div nz-col nzSpan="8" class="template-programs">{{ template.programs }}</div>
            <div
              nz-col
              nzSpan="8"
              class="download-link"
              (click)="downloadFile(template.template_url, '')"
            >
              <i class="app-style-mid" nz-icon nzType="download" nzTheme="outline"></i> Data Layout/Templates
            </div>
            <nz-divider class="template-divider"></nz-divider>
          </div>
        </ng-container>
      </div>
    </ng-container>
  </nz-modal>
</div>
