import { NgModule } from '@angular/core';
import { CommonModule } from '@angular/common';
import { PowerBiReportComponent } from './power-bi-report.component';
import { PowerBIEmbedModule } from 'powerbi-client-angular';
import { SharedModule } from "../shared/shared.module";
import { NzTabsModule } from 'ng-zorro-antd/tabs';
import { NzSpinModule } from 'ng-zorro-antd/spin';
import { NzIconModule } from 'ng-zorro-antd/icon';
import { NzSpaceModule } from 'ng-zorro-antd/space';
import { NzModalModule } from 'ng-zorro-antd/modal';
import { NzRadioModule } from 'ng-zorro-antd/radio';
import { NzNotificationModule } from 'ng-zorro-antd/notification';
import { FormsModule } from '@angular/forms';


@NgModule({
    declarations: [PowerBiReportComponent],
    imports: [
        CommonModule,
        PowerBIEmbedModule,
        SharedModule,
        NzTabsModule,
        NzSpinModule,
        NzIconModule,
        NzSpaceModule,
        NzModalModule,
        NzRadioModule,
        FormsModule,
        NzNotificationModule
    ]
})
export class PowerBiReportModule { }
