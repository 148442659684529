import { Component, OnInit } from '@angular/core';
import { HttpService } from '../../shared/services/http.service';
// import { tryCatch } from 'rxjs/internal-compatibility';
import { Subscription } from 'rxjs';
import { AppState } from '../../shared/services/app-state';
import { NzNotificationService } from 'ng-zorro-antd/notification';

@Component({
  selector: 'app-steward-feedback',
  templateUrl: './steward-feedback.component.html',
  styleUrls: ['./steward-feedback.component.scss'],
})
export class StewardFeedbackComponent implements OnInit {

  public isAdmin = false;
  private appStateSubscription: Subscription;
  public userDetails;
  constructor(private httpService: HttpService,
    private notification: NzNotificationService,
    ) {

    this.appStateSubscription = AppState.appState.subscribe((val) => {

      if (!this.userDetails) {
        if (val && val.userDetails) {
          this.userDetails = val.userDetails;
          this.isAdmin = this.userDetails.role === 'admin' || this.userDetails.role === 'manager';
        }
      }

    });
  }

  public afmPending = 0;
  public acmPending = 0;
  public customError = [];
  public payload = {
    key: 'AFM',
    filters: {
      status: [],
      client: [],
      channel: [],
      program: [],
      date: 'descend',
    },
    search: '',
  };
  public searchParamData = [];

  public stewardData = [];
  public status = [];
  public client = [];
  public channel = [];
  public program = [];
  public tab = 'AFM';
  public sortOrder = 'descend';
  public sortDirection = ['ascend', 'descend'];
  public loading = false;
  public noDataMessage = 'There are no feedbacks to display.';
  public id = 0;
  public pageIndex = 1;
  public searchInput = '';
  public errorMessage = '';


  ngOnInit(): void {
    this.loading = true;
    this.loadData(this.payload);
  }

  async loadData(payload): Promise<void> {
    this.loading = true;
    this.errorMessage = '';
    try {
      this.payload = payload;
      this.payload.key = this.tab;
      await this.httpService.postRequest('get_steward_data', this.payload).toPromise().then((data) => {
      // await this.httpService.getRequest('get_steward_data').toPromise().then((data) => {
        if (data.api_status === 'SUCCESS') {
          this.afmPending = data.pending_afm;
          this.acmPending = data.pending_acm;
          this.searchParamData = data.search_keywords;
          this.stewardData = data.data;
          this.client = data.client;
          this.status = data.status;
          this.channel = data.channel;
          this.program = data.program;
          this.sortOrder = data.date;
          this.loading = false;
        } else {
          console.log(data['message']);
          this.createBasicNotification('Something went wrong. Please try again..');
          this.loading = false;
        }
      }, (error) => {
        this.errorMessage = "Something went wrong";
      });
    } catch (e) {
      console.log(e);
    }
  }
  public filterOnSearch(key): void {
    this.payload.search = key.trim();
    this.payload.search = this.payload.search.replace('zs\\', '');
    this.loading = true;
    this.pageIndex = 1;
    this.loadData(this.payload);
  }
  public formatOnNoResult(event): void {
    if (event) {
      this.noDataMessage = 'Your search returned no results.';
    }
  }
  public resetSearch(event): void {
    if (event) {
      this.loading = true;
      this.payload.search = '';
      this.loadData(this.payload);
      this.pageIndex = 1;
      this.noDataMessage = 'There are no feedbacks to display.';
    }
  }
  public tabChanged(tab): void {
    this.searchInput = '';
    this.tab = tab;
    this.pageIndex = 1;
    this.payload = {
      key: tab,
      filters: {
        status: [],
        client: [],
        channel: [],
        program: [],
        date: 'descend',
      },
      search: '',
    };
    this.loadData(this.payload);
  }

  public createBasicNotification(msg): void {
    let bgColor = '';
    bgColor = 'red';
    const test = this.notification.blank('',  msg, {
      nzPlacement: 'bottomLeft',
      nzDuration: 0,
      nzStyle: { background: bgColor, color: '#FFFFFF', padding: '20px'}
    });

  }
}
