export class ReviewProgram {
  constructor(
    public program = '',
    public channel = '',
    public description = '',
    public layoutFileName = '',
    public layoutFileUrl = '',
    public duplicate = false,
    public reason = '',
  ) {}
}
