<div class="type-wrapper" *ngIf="selectedTab === 'AccessMonitor™'">
  <app-dropdown
    [data]="typeOptions"
    [label]="'Type'"
    [defaultSelected]="'Semesterly'"
    [size]="'large'"
    (selectedValue)="updateData($event)"
    [allowClear]="false"
    ngDefaultControl
  ></app-dropdown>
</div>
<div nz-row class="table-container">
  <div nz-col nzSpan="3">
    <div class="header-1"><strong>Current Status</strong></div>
    <div *ngIf="headerData.current_status == 'Subscribed'">
      <span class="subscribed">{{ headerData.current_status }}</span>
    </div>
    <div *ngIf="headerData.current_status == 'Data Only'">
      <span class="data-only">{{ headerData.current_status }}</span>
    </div>
    <div *ngIf="headerData.current_status == 'Not Subscribed'">
      <span class="not-subscribed">{{ headerData.current_status }}</span>
    </div>
  </div>
  <div nz-col nzSpan="3">
    <div class="header-1"><strong>End Date</strong></div>
    <div class="header-1-data">{{ getDate(headerData.end_date) }}</div>
  </div>
  <div nz-col nzSpan="18">
    <div class="header-1"><strong>Specialties Included</strong></div>
    <div class="specialties header-1-data">
      {{ headerData.specialties_included ? headerData.specialties_included.join(', ') : '' }}
    </div>
  </div>
</div>
<div class="table">
  <nz-table #basicTable [nzData]="listOfData" [nzFrontPagination]="'false'" class="table-inner">
    <thead class="table-header">
      <tr>
        <th
          class="header header-3"
          [nzWidth]="'10%'"
          [nzSortOrder]="sortColumns.cycle.sortOrder"
          (click)="sortData('cycle')"
          [nzSortDirections]="sortColumns.cycle.sortDirection"
        >Cycle</th>
        <th
          class="header header-2"
          [nzWidth]="'10%'"
          [nzSortOrder]="sortColumns.status.sortOrder"
          (click)="sortData('status')"
          [nzSortDirections]="sortColumns.status.sortDirection"
        >
          Status
          <span
            class="info-icon"
            nz-popover
            [nzPopoverContent]="status"
            nzPopoverPlacement="rightTop"
          >
            <i nz-icon nzType="info-circle" nzTheme="fill"></i>
          </span>
        </th>
        <th
          class="header header-2"
          [nzWidth]="'80%'"
          nzCustomFilter>Specialties
          <nz-filter-trigger [nzDropdownMenu]="customFilter" [(nzVisible)]="showFilter" (nzVisibleChange)="openChange($event)">
            <i nz-icon nzType="filter" nzTheme="fill" [ngClass]="highlightFilter() ? 'highlight' : ''"></i>
          </nz-filter-trigger>
          <nz-dropdown-menu #customFilter="nzDropdownMenu">
            <div class="dropdown-container">
              <div class="ant-table-filter-dropdown">
                <ul nz-menu *ngFor="let speciality of specialities">
                  <li nz-menu-item><label nz-checkbox [(ngModel)]=" speciality.value"> {{ speciality.text }} </label></li>
                </ul>
              </div>
              <div class="button-wrapper">
                <a (click)="resetTable()">Reset</a>&nbsp;
                <button  nz-button
                  nzType="primary"
                  class="app-primary-button"
                  (click)="filterSpecialities()"
                >OK</button>
              </div>
            </div>
          </nz-dropdown-menu>
        </th>
      </tr>
    </thead>
    <tbody>
      <tr *ngFor="let data of basicTable.data">
        <td class="semester-data">
          <strong>{{ data.cycle }}</strong>
        </td>
        <td *ngIf="data.status == 'Subscribed'" class="status-data">
          <span class="subscribed">{{ data.status }}</span>
        </td>
        <td *ngIf="data.status == 'Data Only'" class="status-data">
          <span class="data-only">Data Contributor</span>
        </td>
        <td *ngIf="data.status == 'Not Subscribed'" class="status-data">
          <span class="not-subscribed">{{ data.status }}</span>
        </td>
        <td class="specialties-data" *ngIf="data.status !== 'Not Subscribed'">
          {{ data.specialties.join(', ') }}
        </td>
        <td class="specialties-none" *ngIf="data.status == 'Not Subscribed'">
          <i>No Specialties Available</i>
        </td>
      </tr>
    </tbody>
  </nz-table>
</div>

<ng-template #status>
  <p>
    <strong>Subscribed:</strong> {{client}} can access the HCP data report and summary report on the download page.
  </p>
  <p>
    <strong>Data Contributor:</strong> Although {{client}} is not subscribed, {{client}} activity data was submitted as an input for the cycle's data processing. Only the summary report will be available on the download page, while the HCP data is only accessible to ZS teams for {{client}} project work.
  </p>
</ng-template>
