<nz-layout>
  <nz-content>
    <div nz-row>
      <div class="space-align-container">
        <div nz-col nzSpan="19.5">
          <span nz-typography class="tab-header">AFFEX</span>
        </div>
      </div>
    </div>
  </nz-content>
  <div class="affex"><app-affex-content> </app-affex-content></div>
</nz-layout>
