import { InMemoryDbService } from 'angular-in-memory-web-api';
import { Injectable } from '@angular/core';

@Injectable({
  providedIn: 'root',
})
export class DataService implements InMemoryDbService {
  constructor() {}
  createDb(): object {
    const get_download_reports = {
      accessMonitorMonthlyDataReport: {
        tbody: [
          {
            description: 'Unique client identifier as provided by ZS',
            field: 1,
            fieldFullName: 'Client ID',
            fieldName: 'client_id',
            type: 'Character',
            values: 'N/A',
          },
          {
            description: "Unique identifier for an HCP in the subscriber's internal systems",
            field: 2,
            fieldFullName: 'Client Physician ID',
            fieldName: 'client_physician_id',
            type: 'Character',
            values: 'N/A',
          },
          {
            description: 'HCP NPI',
            field: 3,
            fieldFullName: 'National Provider Identifier',
            fieldName: 'NPI',
            type: 'Character',
            values: 'N/A',
          },
          {
            description: 'A binary flag indicating if the provided HCP ME number is valid ',
            field: 4,
            fieldFullName: 'Valid ME Number Flag',
            fieldName: 'valid_menum_flag',
            type: 'Numeric',
            values: '1:valid, 0:invalid',
          },
          {
            description: 'A binary flag indicating if the provided HCP NPI is valid',
            field: 5,
            fieldFullName: 'Valid NPI Flag',
            fieldName: 'valid_npi_flag',
            type: 'Numeric',
            values: '1:valid, 0:invalid',
          },
          {
            description:
              'Binary Reach Flag: An indication of whether an HCP is being reached by a representative during the respective time period',
            field: 6,
            fieldFullName: 'Binary Reach Flag',
            fieldName: 'reach_flag',
            type: 'Numeric',
            values: '0: Not Reached, 1: Reached, NR: Not Rated',
          },
          {
            description: 'A field indicating which channel an HCP is reached by',
            field: 7,
            fieldFullName: 'Reach Type Description',
            fieldName: 'reach_type',
            type: 'Character',
            values: 'Remote, F2F, Both, NR, NA',
          },
          {
            description: 'A measure of how much an HCP is accessible to reps',
            field: 8,
            fieldFullName: 'HCP Accessibility Bucket',
            fieldName: 'accessibility',
            type: 'Character',
            values:
              'Not Rated, Inaccessible, Severely Access Restricted, Access Restricted, Accessible',
          },
          {
            description:
              'Monthly actual calls being made by representatives ranked as the 25th percentile',
            field: 9,
            fieldFullName: 'Call Frequency Percentile 25th',
            fieldName: 'call_freq_perc_25',
            type: 'Numeric',
            values: 'low',
          },
          {
            description:
              'Monthly actual calls being made by representatives ranked as the 50th percentile',
            field: 10,
            fieldFullName: 'Call Frequency Percentile 50th',
            fieldName: 'call_freq_perc_50',
            type: 'Numeric',
            values: 'median',
          },
          {
            description:
              'Monthly actual calls being made by representatives ranked as the 75th percentile',
            field: 11,
            fieldFullName: 'Call Frequency Percentile 75th',
            fieldName: 'call_freq_perc_75',
            type: 'Numeric',
            values: 'best in class',
          },
          {
            description: 'Teams counting towards Call Frequency calculation',
            field: 12,
            fieldFullName: 'Team Count Range for Call Frequencies',
            fieldName: 'teams_call_freq',
            type: 'Character',
            values: '0 to 1, 2 to 4, 5 to 9, 10 or more',
          },
          {
            description:
              'Monthly actual F2F calls being made by representatives ranked as the 25th percentile',
            field: 13,
            fieldFullName: 'F2F Call Frequency Percentile 25th',
            fieldName: 'f2f_call_freq_perc_25',
            type: 'Numeric',
            values: 'low',
          },
          {
            description:
              'Monthly actual F2F calls being made by representatives ranked as the 50th percentile',
            field: 14,
            fieldFullName: 'F2F Call Frequency Percentile 25th',
            fieldName: 'f2f_call_freq_perc_50',
            type: 'Numeric',
            values: 'median',
          },
          {
            description:
              'Monthly actual F2F calls being made by representatives ranked as the 75th percentile',
            field: 15,
            fieldFullName: 'F2F Call Frequency Percentile 75th',
            fieldName: 'f2f_call_freq_perc_75',
            type: 'Numeric',
            values: 'best in class',
          },
          {
            description: 'Teams counting towards F2F Call Frequency calculation',
            field: 16,
            fieldFullName: 'Team Count Range for F2F Call Frequencies',
            fieldName: 'f2f_teams_call_freq',
            type: 'Character',
            values: 'N/A',
          },
          {
            description:
              'Monthly actual remote calls being made by representatives ranked as the 25th percentile',
            field: 17,
            fieldFullName: 'Remote Call Frequency Percentile 25th',
            fieldName: 'remote_call_freq_perc_25',
            type: 'Numeric',
            values: 'low',
          },
          {
            description:
              'Monthly actual remote calls being made by representatives ranked as the 50th percentile',
            field: 18,
            fieldFullName: 'Remote Call Frequency Percentile 50th',
            fieldName: 'remote_call_freq_perc_50',
            type: 'Numeric',
            values: 'median',
          },
          {
            description:
              'Monthly actual remote calls being made by representatives ranked as the 75th percentile',
            field: 19,
            fieldFullName: 'Remote Call Frequency Percentile 75th',
            fieldName: 'remote_call_freq_perc_75',
            type: 'Numeric',
            values: 'best in class',
          },
          {
            description: 'Teams counting towards remote Call Frequency calculation',
            field: 20,
            fieldFullName: 'Team Count Range for remote Call Frequencies',
            fieldName: 'remote_teams_call_freq',
            type: 'Character',
            values: 'N/A',
          },
        ],
        thead: [
          { key: 'field', value: 'Field #' },
          { key: 'fieldName', value: 'Field Name' },
          { key: 'fieldFullName', value: 'Field Full Name' },
          { key: 'description', value: 'Description' },
          { key: 'type', value: 'Type' },
          { key: 'values', value: 'Values' },
        ],
      },
      accessMonitorSemesterlyDataReport: {
        tbody: [
          {
            description: 'Unique client identifier as provided by ZS',
            field: 1,
            fieldFullName: 'Client ID',
            fieldName: 'client_id',
            type: 'Character',
            values: 'N/A',
          },
          {
            description: "Unique identifier for an HCP in the subscriber's internal systems",
            field: 2,
            fieldFullName: 'Client Physician ID',
            fieldName: 'client_physician_id',
            type: 'Character',
            values: 'N/A',
          },
          {
            description: 'HCP ME number',
            field: 3,
            fieldFullName: 'ME number',
            fieldName: 'ME_number',
            type: 'Character',
            values: 'N/A',
          },
          {
            description: 'HCP NPI',
            field: 4,
            fieldFullName: 'National Provider Identifier',
            fieldName: 'NPI',
            type: 'Character',
            values: 'N/A',
          },
          {
            description: 'A binary flag indicating if the provided HCP ME number is valid ',
            field: 5,
            fieldFullName: 'Valid ME Number Flag',
            fieldName: 'valid_menum_flag',
            type: 'Numeric',
            values: '1:valid, 0:invalid',
          },
          {
            description: 'A binary flag indicating if the provided HCP NPI is valid',
            field: 6,
            fieldFullName: 'Valid NPI Flag',
            fieldName: 'valid_npi_flag',
            type: 'Numeric',
            values: '1:valid, 0:invalid',
          },
          {
            description: 'A measure of how often an HCP will see a representative during the cycle',
            field: 7,
            fieldFullName: 'No See Rating',
            fieldName: 'am_no_see_rating',
            type: 'Numeric',
            values: '1-10',
          },
          {
            description: 'Teams counting towards No See Rating calculation',
            field: 8,
            fieldFullName: 'Team Count Range for No See Rating',
            fieldName: 'teams_no_see_rating',
            type: 'Character',
            values: '0 to 2, 3 to 4, 5 to 9, 10 or more',
          },
          {
            description:
              ' Annualized actual calls being made by representatives ranked as the 25th percentile',
            field: 9,
            fieldFullName: 'Call Frequency percentile 25th',
            fieldName: 'annual_call_freq_perc_25',
            type: 'Numeric',
            values: 'low',
          },
          {
            description:
              'Annualized actual calls being made by representatives ranked as the 50th percentile',
            field: 10,
            fieldFullName: 'Call Frequency Percentile 50th',
            fieldName: 'annual_call_freq_perc_50',
            type: 'Numeric',
            values: 'median',
          },
          {
            description:
              'Annualized actual calls being made by representatives ranked as the 75th percentile',
            field: 11,
            fieldFullName: 'Call Frequency Percentile 75th',
            fieldName: 'annual_call_freq_perc_75',
            type: 'Numeric',
            values: 'best in class',
          },
          {
            description: 'Teams counting towards Annual Call Frequency calculation',
            field: 12,
            fieldFullName: 'Team Count Range for Annual Call Frequencies',
            fieldName: 'teams_call_freq',
            type: 'Character',
            values: '0 to 2, 3 to 4, 5 to 9, 10 or more',
          },
          {
            description:
              '% of calls planned by all subscriber companies that are actually implemented',
            field: 13,
            fieldFullName: 'Industry Attainment ',
            fieldName: 'industry_attainment*',
            type: 'Numeric',
            values: 'N/A',
          },
          {
            description: 'Teams counting towards Industry Attainment calculation',
            field: 14,
            fieldFullName: 'Team Count Range for Industry Attainment',
            fieldName: 'teams_attainment*',
            type: 'Character',
            values: '0 to 2, 3 to 4, 5 to 9, 10 or more',
          },
          {
            description: 'Industry Attainment ranked as the 25th percentile',
            field: 15,
            fieldFullName: 'Attainment Percentile 25th',
            fieldName: 'attn_perc_25*',
            type: 'Numeric',
            values: 'low',
          },
          {
            description: 'Industry Attainment ranked as the 50th percentile',
            field: 16,
            fieldFullName: 'Attainment Percentile 50th',
            fieldName: 'attn_perc_50*',
            type: 'Numeric',
            values: 'median',
          },
          {
            description: 'Industry Attainment ranked as the 75th percentile',
            field: 17,
            fieldFullName: 'Attainment Percentile 75th',
            fieldName: 'attn_perc_75*',
            type: 'Numeric',
            values: 'high',
          },
          {
            description: 'Teams counting towards Attainment Percentiles calculation',
            field: 18,
            fieldFullName: 'Team Count Range for Attainment Percentiles',
            fieldName: 'teams_attn_perc*',
            type: 'Character',
            values: '0 to 2, 3 to 4, 5 to 9, 10 or more',
          },
          {
            description:
              'Specialty grouping attributed to the HCP as reported by majority of the subscriber companies',
            field: 19,
            fieldFullName: 'Speciality',
            fieldName: 'am_specialty_grouping*',
            type: 'Character',
            values: 'N/A',
          },
          {
            description: 'Teams counting towards AM Specialty Grouping calculation',
            field: 20,
            fieldFullName: 'Team Count Range for AM Specialty Grouping',
            fieldName: 'teams_spec*',
            type: 'Character',
            values: '0 to 2, 3 to 4, 5 to 9, 10 or more',
          },
          {
            description:
              'Best ZIP Code attributed to the HCP as reported by majority of the subscriber companies',
            field: 21,
            fieldFullName: 'ZIP',
            fieldName: 'am_zip*',
            type: 'Character',
            values: 'N/A',
          },
          {
            description: 'Teams counting towards AM ZIP calculation',
            field: 22,
            fieldFullName: 'Team Count Range for AM ZIP',
            fieldName: 'teams_zip*',
            type: 'Character',
            values: '0 to 2, 3 to 4, 5 to 9, 10 or more',
          },
        ],
        thead: [
          { key: 'field', value: 'Field #' },
          { key: 'fieldName', value: 'Field Name' },
          { key: 'fieldFullName', value: 'Field Full Name' },
          { key: 'description', value: 'Description' },
          { key: 'type', value: 'Type' },
          { key: 'values', value: 'Values' },
        ],
      },
      affinityMonitorDataReport: {
        tbody: [
          {
            description: 'National Provider Identifier',
            field: 1,
            fieldFullName: 'National Provider Identifier',
            fieldName: 'NPI',
            type: 'String',
            values: '10 digit Numeric ID',
          },
          {
            description:
              'Internal contributor identifier for integration into contributor data systems',
            field: 2,
            fieldFullName: 'Contributor ID',
            fieldName: 'Contributor ID',
            type: 'String',
            values: "Contributor's Unique HCP ID",
          },
          {
            description:
              'Quintile based on number of representatives targeting this HCP across all contributors',
            field: 3,
            fieldFullName: 'Personal Face-to- Face Contact Volume',
            fieldName: 'PFF_CTT_VOL',
            type: 'String',
            values: 'Quintile Ranges from VL-VH ',
          },
          {
            description:
              'Percent of targeting representatives with reach (i.e., >3 delivered calls / year delivered)',
            field: 4,
            fieldFullName: 'Personal Face-to- Face Engagement %',
            fieldName: 'PFF_EN',
            type: 'String',
            values: 'Ranges between 0-100%',
          },
          {
            description: 'Quintile based on engagement for this channel',
            field: 5,
            fieldFullName: 'Personal Face-to- Face Engagement Quintile',
            fieldName: 'PFF_EN_QUINTILE',
            type: 'String',
            values: 'Quintile Ranges from VL-VH ',
          },
          {
            description:
              'Predicted affinity for this channel. Identical to engagement quintile where engagement quintile is reported. Otherwise, based on projected channel affinity.',
            field: 6,
            fieldFullName: 'Personal Face-to- Face Affinity',
            fieldName: 'PFF_AFFINITY',
            type: 'String',
            values: 'Quintile Ranges from VL-VH ',
          },
          {
            description: 'Number of representatives targeting this HCP for this contributor only',
            field: 7,
            fieldFullName: 'Personal Face-to- Face Contributor Contact Volume',
            fieldName: 'PFF_CTBR_CTT_VOL',
            type: 'String',
            values: 'Ranges between 0-100',
          },
          {
            description:
              'Percent of targeting representatives with reach (i.e., >3 delivered calls /year delivered) for this contributor only',
            field: 8,
            fieldFullName: 'Personal Face-to-Face Contributor Engagement',
            fieldName: 'PFF_CTBR_EN',
            type: 'String',
            values: 'Ranges between 0-100%',
          },
          {
            description:
              'Quintile based on number of remote personal sales interactions with this HCP across all contributors',
            field: 9,
            fieldFullName: 'Personal Remote Contact Volume',
            fieldName: 'PR_CTT_VOL',
            type: 'String',
            values: 'Quintile Ranges from VL-VH ',
          },
          {
            description:
              'Number of remote personal sales interactions for this HCP / the observed maximum (3standard deviations above mean) for this HCP\u2019s specialty',
            field: 10,
            fieldFullName: 'Personal Remote Engagement %',
            fieldName: 'PR_EN',
            type: 'String',
            values: 'Ranges between 0-100%',
          },
          {
            description: 'Quintile based on engagement for this channel',
            field: 11,
            fieldFullName: 'Personal Remote Engagement Quintile',
            fieldName: 'PR_EN_QUINTILE',
            type: 'String',
            values: 'Quintile Ranges from VL-VH ',
          },
          {
            description:
              'Predicted affinity for this channel. Identical to engagement quintile where engagement quintile is reported. Otherwise, based on\nprojected channel affinity.',
            field: 12,
            fieldFullName: 'Personal Promotion Affinity',
            fieldName: 'PR AFFINITY',
            type: 'String',
            values: 'Quintile Ranges from VL-VH ',
          },
          {
            description:
              'Number of remote personal sales interactions with this HCP for this contributor only',
            field: 13,
            fieldFullName: 'Personal Remote Contributor Contact Volume',
            fieldName: 'PR_CTBR_CTT\n_VOL',
            type: 'String',
            values: 'Ranges between 0-100',
          },
          {
            description:
              'Number of remote personal sales interactions with this HCP for this contributor only / the observed maximum (3standard deviations above mean) for this HCP\u2019s specialty for this contributor only',
            field: 14,
            fieldFullName: 'Personal Remote Contributor Engagement',
            fieldName: 'PR_CTBR_EN',
            type: 'String',
            values: 'Ranges between 0-100%',
          },
          {
            description:
              'Quintile based on number of peer program interactions with this HCP across all contributors',
            field: 15,
            fieldFullName: 'Peer Interaction Contact Volume',
            fieldName: 'PI_CTT_VOL',
            type: 'String',
            values: 'Quintile Ranges from VL-VH ',
          },
          {
            description:
              'Number of peer program interactions for this HCP / the observed maximum (3standard deviations above mean) for this HCP\u2019s specialty',
            field: 16,
            fieldFullName: 'Peer Interaction Engagement % ',
            fieldName: 'PI_EN',
            type: 'String',
            values: 'Ranges between 0-100%',
          },
          {
            description: 'Quintile based on engagement for this channel',
            field: 17,
            fieldFullName: 'Peer Interaction Engagement Quintile',
            fieldName: 'PI_EN_QUINTI LE',
            type: 'String',
            values: 'Quintile Ranges from VL-VH ',
          },
          {
            description:
              'Predicted affinity for this channel. Identical to engagement quintile where engagement quintile is reported. Otherwise, based on projected channel affinity.',
            field: 18,
            fieldFullName: 'Peer Interaction Affinity',
            fieldName: 'PI_AFFINITY',
            type: 'String',
            values: 'Quintile Ranges from VL-VH ',
          },
          {
            description:
              'Number of peer program interactions with this HCP for this contributor only',
            field: 19,
            fieldFullName: 'Peer Interaction Contributor Contact Volume',
            fieldName: 'PI_CTBR_CTT_ VOL',
            type: 'String',
            values: 'Ranges between 0-100',
          },
          {
            description:
              'Number of peer program interactions with this HCP for this contributor only/ the observed maximum (3standard deviations above mean) for this HCP\u2019s specialty for this contributor only',
            field: 20,
            fieldFullName: 'Peer Interaction Contributor Engagement',
            fieldName: 'PI_CTBR_EN',
            type: 'String',
            values: 'Ranges between 0-100%',
          },
          {
            description:
              'Quintile based on number of digital push messages sent to and received by this HCP across all contributors',
            field: 21,
            fieldFullName: 'Digital Push Contact Volume',
            fieldName: 'DPH_CTT_VOL',
            type: 'String',
            values: 'Quintile Ranges from VL-VH ',
          },
          {
            description:
              'Percent of digital push messages which are opened or viewed across all contributors',
            field: 22,
            fieldFullName: 'Digital Push Engagement ',
            fieldName: 'DPH_EN',
            type: 'String',
            values: 'Ranges between 0-100%',
          },
          {
            description: 'Quintile based on engagement for this channel',
            field: 23,
            fieldFullName: 'Digital Push Engagement Quintile',
            fieldName: 'DPH_EN_QUINTILE',
            type: 'String',
            values: 'Quintile Ranges from VL-VH ',
          },
          {
            description:
              'Predicted affinity for this channel. Identical to engagement quintile where engagement quintile is reported.Otherwise, based on projected channel affinity.',
            field: 24,
            fieldFullName: 'Digital Push Affinity',
            fieldName: 'DPH_AFFINITY',
            type: 'String',
            values: 'Quintile Ranges from VL-VH ',
          },
          {
            description:
              'Number of digital push messages sent to and received by this HCP for this contributor only',
            field: 25,
            fieldFullName: 'Digital Push Contributor Contact Volume',
            fieldName: 'DPH\n_CTBR_CTT_VOL',
            type: 'String',
            values: 'Ranges between 0-100',
          },
          {
            description:
              'Percent of digital push messages which were opened or viewed for this contributor only',
            field: 26,
            fieldFullName: 'Digital Push Contributor Engagement',
            fieldName: 'DPH _CTBR_EN',
            type: 'String',
            values: 'Ranges between 0-100%',
          },
          {
            description:
              'Quintile based on number of digital pull interactions with this HCP across all contributors',
            field: 27,
            fieldFullName: 'Digital Pull Contact Volume',
            fieldName: 'DPL_CTT_VOL',
            type: 'String',
            values: 'Quintile Ranges from VL-VH ',
          },
          {
            description:
              'Number of digital pull interactions for this HCP / the observed maximum (3standard deviations above mean) for this HCP\u2019s ',
            field: 28,
            fieldFullName: 'Digital Pull Engagement %',
            fieldName: 'DPH_EN',
            type: 'String',
            values: 'Ranges between 0-100%',
          },
          {
            description: 'Quintile based on engagement for this channel',
            field: 29,
            fieldFullName: 'Digital Pull Engagement Quintile',
            fieldName: 'DPL_EN_QUINTILE',
            type: 'String',
            values: 'Quintile Ranges from VL-VH ',
          },
          {
            description:
              'Predicted affinity for this channel. Identical to engagement quintile where engagement quintile is reported.Otherwise, based on\nprojected channel affinity.',
            field: 30,
            fieldFullName: 'Digital Pull Affinity',
            fieldName: 'DPL_AFFINITY',
            type: 'String',
            values: 'Quintile Ranges from VL-VH ',
          },
          {
            description:
              'Number of digital pull interactions with this HCP for this contributor only',
            field: 31,
            fieldFullName: 'Digital Pull Contributor Contact Volume',
            fieldName: 'DPL_CTBR_CTT_VOL',
            type: 'String',
            values: 'Ranges between 0-100',
          },
          {
            description:
              'Number of digital pull interactions with this HCP for this contributor only / the observed maximum (3standard deviations above mean) for this HCP\u2019s specialty for this\ncontributor only',
            field: 32,
            fieldFullName: 'Digital Pull Contributor Engagement',
            fieldName: 'DPL_CTBR_EN',
            type: 'String',
            values: 'Ranges between 0-100%',
          },
          {
            description:
              'Quintile based on number of direct mail/fax messages sent to this HCP across all contributors',
            field: 33,
            fieldFullName: 'Direct Contact Volume',
            fieldName: 'DRT_CTT_VOL',
            type: 'String',
            values: 'Quintile Ranges from VL-VH ',
          },
          {
            description:
              'Percent of direct mail/fax messages where a response (QR code scan, sample request, BRC card reply,etc.) could be tracked to the HCP)',
            field: 34,
            fieldFullName: 'Direct Engagement %',
            fieldName: 'DRT _EN',
            type: 'String',
            values: 'Ranges between 0-100%',
          },
          {
            description: 'Quintile based on engagement for this channel',
            field: 35,
            fieldFullName: 'Direct Engagement Quintile',
            fieldName: 'DRT\n_EN_QUINTILE',
            type: 'String',
            values: 'Quintile Ranges from VL-VH ',
          },
          {
            description:
              'Predicted affinity for this channel. Identical to engagement quintile where engagement quintile is reported. Otherwise, based on\nprojected channel affinity',
            field: 36,
            fieldFullName: 'Direct Affinity',
            fieldName: 'DRT\n_AFFINITY',
            type: 'String',
            values: 'Quintile Ranges from VL-VH ',
          },
          {
            description:
              'Number of direct mail/fax messages sent to this HCP for this contributor only',
            field: 37,
            fieldFullName: 'Direct Contributor Contact Volume',
            fieldName: 'DRT_CTBR_CTT_VOL',
            type: 'String',
            values: 'Ranges between 0-100',
          },
          {
            description:
              'Percent of direct mail/fax messages where a response (QR code scan, sample request, BRC card reply,etc.) could be tracked to the HCP for this contributor only',
            field: 38,
            fieldFullName: 'Direct Contributor Engagement',
            fieldName: 'DRT _CTBR_EN',
            type: 'String',
            values: 'Ranges between 0-100%',
          },
          {
            description:
              'Propensity to engage in interactions where the offer was information presented by a KOL or other HCP, relative to other types of offer for this HCP.Includes interactions regardless of channel (e.g., speaker programs, webinars, KOL videos, KOL branded email, etc.).',
            field: 39,
            fieldFullName: 'Peer Information Offer Engagement',
            fieldName: 'PEER_OFFER_ EN',
            type: 'String',
            values: 'Quintile Ranges from H-L ',
          },
          {
            description:
              'Propensity to engage in interactions where the offer was patient co-pay assistance, relative to other types of offer for this HCP. Includes interactions regardless of channel (e.g., online resource, direct mail, rep delivered).',
            field: 40,
            fieldFullName: 'Co-Pay Offer Engagement',
            fieldName: 'COPAY_OFFER\n_EN',
            type: 'String',
            values: 'Quintile Ranges from H-L ',
          },
          {
            description:
              'Propensity to engage in interactions where samples were offered, relative to other types of offer for this HCP. Includes interactions regardless of channel (e.g., rep delivered, online request, BRC/fax request).',
            field: 41,
            fieldFullName: 'Sample Offer Engagement',
            fieldName: 'SAMPLE_OFFE R_EN',
            type: 'String',
            values: 'Quintile Ranges from H-L ',
          },
          {
            description:
              'Propensity to engage in interactions where vouchers were offered, relative to other types of offer for this HCP.Includes interactions regardless of channel (e.g., rep delivered, online request, BRC/fax request).',
            field: 42,
            fieldFullName: 'Voucher Offer Engagement',
            fieldName: 'VOUCHER_OF FER_EN',
            type: 'String',
            values: 'Quintile Ranges from H-L ',
          },
          {
            description:
              'Up to three channels for which this HCP shows an affinity, in order. May list fewer than three channels or be NULL if this HCP does not display an affinity for three channels',
            field: 43,
            fieldFullName: 'Top 3 Channels ',
            fieldName: 'TOP_CHANNELS',
            type: 'String',
            values: 'Upto three channel abbreviation (DPH, DRT, PFF, PI,PR,DPL)',
          },
          {
            description:
              'Affinity for personal promotion on a 10\u20131 scale. Based on number of targeting reps who reach the HCP with >3 calls within a year',
            field: 44,
            fieldFullName: 'Personal Promotion Access Rating',
            fieldName: 'PP_ACCESS_RATING',
            type: 'Integer',
            values:
              '10\u20131 integer where 10 = the most accessible HCP via personal promotion (>90% of targeting reps have access)',
          },
          {
            description:
              'Affinity for NPP on a 10\u20131 scale. Based on the maximum engagement decile across NPP channels (e.g., an HCP in the top 10% of engagement for digital pull would rate a 10, even if other NPP channels showed lower affinity) NPP channels include digital push, digital pull, peer interaction, and direct mail',
            field: 45,
            fieldFullName: 'Non-personal Promotion Access Rating',
            fieldName: 'NPP_ACCESS_ RATING',
            type: 'Integer',
            values:
              '10\u20131 integer where 10 = an HCP in the top 10% of engagement for at least one NPP channel, and 1 = an HCP who is within the bottom 10% of engagement for all NPP channels',
          },
        ],
        thead: [
          { key: 'field', value: 'Field #' },
          { key: 'fieldName', value: 'Field Name' },
          { key: 'fieldFullName', value: 'Field Full Name' },
          { key: 'description', value: 'Description' },
          { key: 'type', value: 'Type' },
          { key: 'values', value: 'Values' },
        ],
      },
      report: [
        {
          id: 100001,
          reportAvailable: '2 out of 2',
          reports: [
            {
              files: [
                {
                  dateAvailable: null,
                  downloadLink:
                    'https://aws-a0011-use1-00-d-s3b-aftm-shr-data01.s3.amazonaws.com/2021%2FSemester%207%2FAccess%20Monitor%20Reports%2FData_Report%2FMonthly%2FAM_April_Client_01_data_report_v1.txt',
                  downloadable: false,
                  preview: 'accessMonitorMonthlyDataReport',
                  reportId: 7940,
                  reportName: 'AM_April_Client_01_data_report_v1.txt',
                },
                {
                  dateAvailable: 'Tue, 06 Apr 2021 00:00:00 GMT',
                  downloadLink:
                    'https://aws-a0011-use1-00-d-s3b-aftm-shr-data01.s3.amazonaws.com/2021%2FSemester%207%2FAccess%20Monitor%20Reports%2FData_Report%2FSemesterly%2FAM_SEM1_Client_01_data_report_v1.txt',
                  downloadable: true,
                  preview: 'accessMonitorSemesterlyDataReport',
                  reportId: 7941,
                  reportName: 'AM_SEM1_Client_01_data_report_v1.txt',
                },
              ],
              folderName: 'AccessMonitor Reports',
              id: 100002,
            },
          ],
          semester: 'Semester 7',
          year: 2021,
        },
        {
          id: 100003,
          reportAvailable: '2 out of 2',
          reports: [
            {
              files: [
                {
                  dateAvailable: 'Tue, 06 Apr 2021 00:00:00 GMT',
                  downloadLink:
                    'https://aws-a0011-use1-00-d-s3b-aftm-shr-data01.s3.amazonaws.com/2021%2FSemester%206%2FAccess%20Monitor%20Reports%2FData_Report%2FMonthly%2FAM_April_Client_01_data_report_v1.txt',
                  downloadable: true,
                  preview: 'accessMonitorMonthlyDataReport',
                  reportId: 7938,
                  reportName: 'AM_April_Client_01_data_report_v1.txt',
                },
                {
                  dateAvailable: 'Tue, 06 Apr 2021 00:00:00 GMT',
                  downloadLink:
                    'https://aws-a0011-use1-00-d-s3b-aftm-shr-data01.s3.amazonaws.com/2021%2FSemester%206%2FAccess%20Monitor%20Reports%2FData_Report%2FSemesterly%2FAM_SEM1_Client_01_data_report_v1.txt',
                  downloadable: true,
                  preview: 'accessMonitorSemesterlyDataReport',
                  reportId: 7939,
                  reportName: 'AM_SEM1_Client_01_data_report_v1.txt',
                },
              ],
              folderName: 'AccessMonitor Reports',
              id: 100004,
            },
          ],
          semester: 'Semester 6',
          year: 2021,
        },
        {
          id: 100005,
          reportAvailable: '2 out of 2',
          reports: [
            {
              files: [
                {
                  dateAvailable: 'Tue, 06 Apr 2021 00:00:00 GMT',
                  downloadLink:
                    'https://aws-a0011-use1-00-d-s3b-aftm-shr-data01.s3.amazonaws.com/2021%2FSemester%205%2FAccess%20Monitor%20Reports%2FData_Report%2FMonthly%2FAM_April_Client_01_data_report_v1.txt',
                  downloadable: true,
                  preview: 'accessMonitorMonthlyDataReport',
                  reportId: 7936,
                  reportName: 'AM_April_Client_01_data_report_v1.txt',
                },
                {
                  dateAvailable: 'Tue, 06 Apr 2021 00:00:00 GMT',
                  downloadLink:
                    'https://aws-a0011-use1-00-d-s3b-aftm-shr-data01.s3.amazonaws.com/2021%2FSemester%205%2FAccess%20Monitor%20Reports%2FData_Report%2FSemesterly%2FAM_SEM1_Client_01_data_report_v1.txt',
                  downloadable: true,
                  preview: 'accessMonitorSemesterlyDataReport',
                  reportId: 7937,
                  reportName: 'AM_SEM1_Client_01_data_report_v1.txt',
                },
              ],
              folderName: 'AccessMonitor Reports',
              id: 100006,
            },
          ],
          semester: 'Semester 5',
          year: 2021,
        },
        {
          id: 100007,
          reportAvailable: '3 out of 3',
          reports: [
            {
              files: [
                {
                  dateAvailable: 'Tue, 06 Apr 2021 00:00:00 GMT',
                  downloadLink:
                    'https://aws-a0011-use1-00-d-s3b-aftm-shr-data01.s3.amazonaws.com/2021%2FSemester%204%2FAccess%20Monitor%20Reports%2FData_Report%2FSemesterly%2FAM_SEM1_Client_01_data_report_v1.txt',
                  downloadable: true,
                  preview: 'accessMonitorSemesterlyDataReport',
                  reportId: 7934,
                  reportName: 'AM_SEM1_Client_01_data_report_v1.txt',
                },
                {
                  dateAvailable: 'Tue, 06 Apr 2021 00:00:00 GMT',
                  downloadLink:
                    'https://aws-a0011-use1-00-d-s3b-aftm-shr-data01.s3.amazonaws.com/2021%2FSemester%204%2FAccess%20Monitor%20Reports%2FData_Report%2FMonthly%2FAM_April_Client_01_data_report_v1.txt',
                  downloadable: true,
                  preview: 'accessMonitorMonthlyDataReport',
                  reportId: 7933,
                  reportName: 'AM_April_Client_01_data_report_v1.txt',
                },
              ],
              folderName: 'AccessMonitor Reports',
              id: 100008,
            },
            {
              files: [
                {
                  dateAvailable: 'Tue, 06 Apr 2021 00:00:00 GMT',
                  downloadLink:
                    'https://aws-a0011-use1-00-d-s3b-aftm-shr-data01.s3.amazonaws.com/2021%2FSemester%204%2FAffinity%20Monitor%20Reports%2FData_Report%2F2021-04-05_AfM_SEM1_Client_01_HCP_Report.txt',
                  downloadable: true,
                  preview: 'affinityMonitorDataReport',
                  reportId: 7935,
                  reportName: '2021-04-05_AfM_SEM1_Client_01_HCP_Report.txt',
                },
              ],
              folderName: 'AffinityMonitor Reports',
              id: 100009,
            },
          ],
          semester: 'Semester 4',
          year: 2021,
        },
        {
          id: 100010,
          reportAvailable: '3 out of 3',
          reports: [
            {
              files: [
                {
                  dateAvailable: 'Tue, 06 Apr 2021 00:00:00 GMT',
                  downloadLink:
                    'https://aws-a0011-use1-00-d-s3b-aftm-shr-data01.s3.amazonaws.com/2021%2FSemester%203%2FAccess%20Monitor%20Reports%2FData_Report%2FSemesterly%2FAM_SEM1_Client_01_data_report_v1.txt',
                  downloadable: true,
                  preview: 'accessMonitorSemesterlyDataReport',
                  reportId: 7931,
                  reportName: 'AM_SEM1_Client_01_data_report_v1.txt',
                },
                {
                  dateAvailable: 'Tue, 06 Apr 2021 00:00:00 GMT',
                  downloadLink:
                    'https://aws-a0011-use1-00-d-s3b-aftm-shr-data01.s3.amazonaws.com/2021%2FSemester%203%2FAccess%20Monitor%20Reports%2FData_Report%2FMonthly%2FAM_April_Client_01_data_report_v1.txt',
                  downloadable: true,
                  preview: 'accessMonitorMonthlyDataReport',
                  reportId: 7930,
                  reportName: 'AM_April_Client_01_data_report_v1.txt',
                },
              ],
              folderName: 'AccessMonitor Reports',
              id: 100011,
            },
            {
              files: [
                {
                  dateAvailable: 'Tue, 06 Apr 2021 00:00:00 GMT',
                  downloadLink:
                    'https://aws-a0011-use1-00-d-s3b-aftm-shr-data01.s3.amazonaws.com/2021%2FSemester%203%2FAffinity%20Monitor%20Reports%2FData_Report%2F2021-04-05_AfM_SEM1_Client_01_HCP_Report.txt',
                  downloadable: true,
                  preview: 'affinityMonitorDataReport',
                  reportId: 7932,
                  reportName: '2021-04-05_AfM_SEM1_Client_01_HCP_Report.txt',
                },
              ],
              folderName: 'AffinityMonitor Reports',
              id: 100012,
            },
          ],
          semester: 'Semester 3',
          year: 2021,
        },
        {
          id: 100013,
          reportAvailable: '3 out of 3',
          reports: [
            {
              files: [
                {
                  dateAvailable: 'Tue, 06 Apr 2021 00:00:00 GMT',
                  downloadLink:
                    'https://aws-a0011-use1-00-d-s3b-aftm-shr-data01.s3.amazonaws.com/2021%2FSemester%202%2FAccess%20Monitor%20Reports%2FData_Report%2FSemesterly%2FAM_SEM1_Client_01_data_report_v1.txt',
                  downloadable: true,
                  preview: 'accessMonitorSemesterlyDataReport',
                  reportId: 7928,
                  reportName: 'AM_SEM1_Client_01_data_report_v1.txt',
                },
                {
                  dateAvailable: 'Tue, 06 Apr 2021 00:00:00 GMT',
                  downloadLink:
                    'https://aws-a0011-use1-00-d-s3b-aftm-shr-data01.s3.amazonaws.com/2021%2FSemester%202%2FAccess%20Monitor%20Reports%2FData_Report%2FMonthly%2FAM_April_Client_01_data_report_v1.txt',
                  downloadable: true,
                  preview: 'accessMonitorMonthlyDataReport',
                  reportId: 7927,
                  reportName: 'AM_April_Client_01_data_report_v1.txt',
                },
              ],
              folderName: 'AccessMonitor Reports',
              id: 100014,
            },
            {
              files: [
                {
                  dateAvailable: 'Tue, 06 Apr 2021 00:00:00 GMT',
                  downloadLink:
                    'https://aws-a0011-use1-00-d-s3b-aftm-shr-data01.s3.amazonaws.com/2021%2FSemester%202%2FAffinity%20Monitor%20Reports%2FData_Report%2F2021-04-05_AfM_SEM1_Client_01_HCP_Report.txt',
                  downloadable: true,
                  preview: 'affinityMonitorDataReport',
                  reportId: 7929,
                  reportName: '2021-04-05_AfM_SEM1_Client_01_HCP_Report.txt',
                },
              ],
              folderName: 'AffinityMonitor Reports',
              id: 100015,
            },
          ],
          semester: 'Semester 2',
          year: 2021,
        },
        {
          id: 100016,
          reportAvailable: '2 out of 2',
          reports: [
            {
              files: [
                {
                  dateAvailable: 'Tue, 06 Apr 2021 00:00:00 GMT',
                  downloadLink:
                    'https://aws-a0011-use1-00-d-s3b-aftm-shr-data01.s3.amazonaws.com/2021%2FSemester%201%2FAffinity%20Monitor%20Reports%2FData_Report%2F2021-04-05_AfM_SEM1_Client_01_HCP_Report.txt',
                  downloadable: true,
                  preview: 'affinityMonitorDataReport',
                  reportId: 7926,
                  reportName: '2021-04-05_AfM_SEM1_Client_01_HCP_Report.txt',
                },
              ],
              folderName: 'AffinityMonitor Reports',
              id: 100017,
            },
            {
              files: [
                {
                  dateAvailable: 'Tue, 06 Apr 2021 00:00:00 GMT',
                  downloadLink:
                    'https://aws-a0011-use1-00-d-s3b-aftm-shr-data01.s3.amazonaws.com/2021%2FSemester%201%2FAccess%20Monitor%20Reports%2FData_Report%2FSemesterly%2FAM_SEM1_Client_01_data_report_v1.txt',
                  downloadable: true,
                  preview: 'accessMonitorSemesterlyDataReport',
                  reportId: 7925,
                  reportName: 'AM_SEM1_Client_01_data_report_v1.txt',
                },
              ],
              folderName: 'AccessMonitor Reports',
              id: 100018,
            },
          ],
          semester: 'Semester 1',
          year: 2021,
        },
        {
          id: 100019,
          reportAvailable: '9 out of 10',
          reports: [
            {
              files: [
                {
                  dateAvailable: 'Tue, 06 Apr 2021 00:00:00 GMT',
                  downloadLink:
                    'https://aws-a0011-use1-00-d-s3b-aftm-shr-data01.s3.amazonaws.com/2020%2FSemester%202%2FAccess%20Monitor%20Reports%2FData_Report%2FMonthly%2FAM_202009_%5BClient_Code%5D_data_report_v1.txt',
                  downloadable: true,
                  preview: 'accessMonitorMonthlyDataReport',
                  reportId: 7916,
                  reportName: 'AM_202009_[Client_Code]_data_report_v1.txt',
                },
                {
                  dateAvailable: 'Tue, 06 Apr 2021 00:00:00 GMT',
                  downloadLink:
                    'https://aws-a0011-use1-00-d-s3b-aftm-shr-data01.s3.amazonaws.com/2020%2FSemester%202%2FAccess%20Monitor%20Reports%2FData_Report%2FMonthly%2FAM_202007_%5BClient_Code%5D_data_report_v1.txt',
                  downloadable: true,
                  preview: 'accessMonitorMonthlyDataReport',
                  reportId: 7914,
                  reportName: 'AM_202007_[Client_Code]_data_report_v1.txt',
                },
                {
                  dateAvailable: 'Tue, 06 Apr 2021 00:00:00 GMT',
                  downloadLink:
                    'https://aws-a0011-use1-00-d-s3b-aftm-shr-data01.s3.amazonaws.com/2020%2FSemester%202%2FAccess%20Monitor%20Reports%2FData_Report%2FMonthly%2FAM_202008_%5BClient_Code%5D_data_report_v1.txt',
                  downloadable: true,
                  preview: 'accessMonitorMonthlyDataReport',
                  reportId: 7915,
                  reportName: 'AM_202008_[Client_Code]_data_report_v1.txt',
                },
                {
                  dateAvailable: 'Tue, 06 Apr 2021 00:00:00 GMT',
                  downloadLink:
                    'https://aws-a0011-use1-00-d-s3b-aftm-shr-data01.s3.amazonaws.com/2020%2FSemester%202%2FAccess%20Monitor%20Reports%2FSummary_Report%2F01-07-2020%20Client1%20AccessMonitor%20Analysis%20Report%20v1.pdf',
                  downloadable: true,
                  preview: null,
                  reportId: 7921,
                  reportName: '01-07-2020 Client1 AccessMonitor Analysis Report v1.pdf',
                },
                {
                  dateAvailable: 'Tue, 06 Apr 2021 00:00:00 GMT',
                  downloadLink:
                    'https://aws-a0011-use1-00-d-s3b-aftm-shr-data01.s3.amazonaws.com/2020%2FSemester%202%2FAccess%20Monitor%20Reports%2FData_Report%2FSemesterly%2FAM_%5BCycle%5D_%5BClient_ID%5D_data_report_v1.txt',
                  downloadable: true,
                  preview: 'accessMonitorSemesterlyDataReport',
                  reportId: 7920,
                  reportName: 'AM_[Cycle]_[Client_ID]_data_report_v1.txt',
                },
                {
                  dateAvailable: 'Tue, 06 Apr 2021 00:00:00 GMT',
                  downloadLink:
                    'https://aws-a0011-use1-00-d-s3b-aftm-shr-data01.s3.amazonaws.com/2020%2FSemester%202%2FAccess%20Monitor%20Reports%2FData_Report%2FMonthly%2FAM_202012_%5BClient_Code%5D_data_report_v1.txt',
                  downloadable: true,
                  preview: 'accessMonitorMonthlyDataReport',
                  reportId: 7919,
                  reportName: 'AM_202012_[Client_Code]_data_report_v1.txt',
                },
                {
                  dateAvailable: 'Tue, 06 Apr 2021 00:00:00 GMT',
                  downloadLink:
                    'https://aws-a0011-use1-00-d-s3b-aftm-shr-data01.s3.amazonaws.com/2020%2FSemester%202%2FAccess%20Monitor%20Reports%2FData_Report%2FMonthly%2FAM_202011_%5BClient_Code%5D_data_report_v1.txt',
                  downloadable: true,
                  preview: 'accessMonitorMonthlyDataReport',
                  reportId: 7918,
                  reportName: 'AM_202011_[Client_Code]_data_report_v1.txt',
                },
                {
                  dateAvailable: 'Tue, 06 Apr 2021 00:00:00 GMT',
                  downloadLink:
                    'https://aws-a0011-use1-00-d-s3b-aftm-shr-data01.s3.amazonaws.com/2020%2FSemester%202%2FAccess%20Monitor%20Reports%2FData_Report%2FMonthly%2FAM_202010_%5BClient_Code%5D_data_report_v1.txt',
                  downloadable: true,
                  preview: 'accessMonitorMonthlyDataReport',
                  reportId: 7917,
                  reportName: 'AM_202010_[Client_Code]_data_report_v1.txt',
                },
              ],
              folderName: 'AccessMonitor Reports',
              id: 100020,
            },
            {
              files: [
                {
                  dateAvailable: 'Tue, 06 Apr 2021 00:00:00 GMT',
                  downloadLink:
                    'https://aws-a0011-use1-00-d-s3b-aftm-shr-data01.s3.amazonaws.com/2020%2FSemester%202%2FAffinity%20Monitor%20Reports%2FSummary_Report%2F2020-07-01%20AffinityMonitor%C2%AE%20Summary%20Report%20-%20Client.pdf',
                  downloadable: true,
                  preview: null,
                  reportId: 7923,
                  reportName: '2020-07-01 AffinityMonitor\u00ae Summary Report - Client.pdf',
                },
                {
                  dateAvailable: 'Wed, 01 Dec 2021 00:00:00 GMT',
                  downloadLink:
                    'https://aws-a0011-use1-00-d-s3b-aftm-shr-data01.s3.amazonaws.com/2020%2FSemester%202%2FAffinity%20Monitor%20Reports%2FData_Report%2F2020-07-01_AfM_%5BCYCLE%5D_Client_HCP_Report_v01.txt',
                  downloadable: false,
                  preview: 'affinityMonitorDataReport',
                  reportId: 7922,
                  reportName: '2020-07-01_AfM_[CYCLE]_Client_HCP_Report_v01.txt',
                },
              ],
              folderName: 'AffinityMonitor Reports',
              id: 100021,
            },
          ],
          semester: 'Semester 2',
          year: 2020,
        },
        {
          id: 100022,
          reportAvailable: '9 out of 10',
          reports: [
            {
              files: [
                {
                  dateAvailable: 'Tue, 06 Apr 2021 00:00:00 GMT',
                  downloadLink:
                    'https://aws-a0011-use1-00-d-s3b-aftm-shr-data01.s3.amazonaws.com/2020%2FSemester%201%2FAccess%20Monitor%20Reports%2FData_Report%2FMonthly%2FAM_202006_%5BClient_Code%5D_data_report_v1.txt',
                  downloadable: true,
                  preview: 'accessMonitorMonthlyDataReport',
                  reportId: 7909,
                  reportName: 'AM_202006_[Client_Code]_data_report_v1.txt',
                },
                {
                  dateAvailable: 'Tue, 06 Apr 2021 00:00:00 GMT',
                  downloadLink:
                    'https://aws-a0011-use1-00-d-s3b-aftm-shr-data01.s3.amazonaws.com/2020%2FSemester%201%2FAccess%20Monitor%20Reports%2FData_Report%2FMonthly%2FAM_202005_%5BClient_Code%5D_data_report_v1.txt',
                  downloadable: true,
                  preview: 'accessMonitorMonthlyDataReport',
                  reportId: 7908,
                  reportName: 'AM_202005_[Client_Code]_data_report_v1.txt',
                },
                {
                  dateAvailable: 'Tue, 06 Apr 2021 00:00:00 GMT',
                  downloadLink:
                    'https://aws-a0011-use1-00-d-s3b-aftm-shr-data01.s3.amazonaws.com/2020%2FSemester%201%2FAccess%20Monitor%20Reports%2FData_Report%2FMonthly%2FAM_202004_%5BClient_Code%5D_data_report_v1.txt',
                  downloadable: true,
                  preview: 'accessMonitorMonthlyDataReport',
                  reportId: 7907,
                  reportName: 'AM_202004_[Client_Code]_data_report_v1.txt',
                },
                {
                  dateAvailable: 'Tue, 06 Apr 2021 00:00:00 GMT',
                  downloadLink:
                    'https://aws-a0011-use1-00-d-s3b-aftm-shr-data01.s3.amazonaws.com/2020%2FSemester%201%2FAccess%20Monitor%20Reports%2FData_Report%2FMonthly%2FAM_202003_%5BClient_Code%5D_data_report_v1.txt',
                  downloadable: true,
                  preview: 'accessMonitorMonthlyDataReport',
                  reportId: 7906,
                  reportName: 'AM_202003_[Client_Code]_data_report_v1.txt',
                },
                {
                  dateAvailable: 'Tue, 06 Apr 2021 00:00:00 GMT',
                  downloadLink:
                    'https://aws-a0011-use1-00-d-s3b-aftm-shr-data01.s3.amazonaws.com/2020%2FSemester%201%2FAccess%20Monitor%20Reports%2FData_Report%2FMonthly%2FAM_202002_%5BClient_Code%5D_data_report_v1.txt',
                  downloadable: true,
                  preview: 'accessMonitorMonthlyDataReport',
                  reportId: 7905,
                  reportName: 'AM_202002_[Client_Code]_data_report_v1.txt',
                },
                {
                  dateAvailable: 'Tue, 06 Apr 2021 00:00:00 GMT',
                  downloadLink:
                    'https://aws-a0011-use1-00-d-s3b-aftm-shr-data01.s3.amazonaws.com/2020%2FSemester%201%2FAccess%20Monitor%20Reports%2FData_Report%2FMonthly%2FAM_202001_%5BClient_Code%5D_data_report_v1.txt',
                  downloadable: true,
                  preview: 'accessMonitorMonthlyDataReport',
                  reportId: 7904,
                  reportName: 'AM_202001_[Client_Code]_data_report_v1.txt',
                },
                {
                  dateAvailable: 'Tue, 06 Apr 2021 00:00:00 GMT',
                  downloadLink:
                    'https://aws-a0011-use1-00-d-s3b-aftm-shr-data01.s3.amazonaws.com/2020%2FSemester%201%2FAccess%20Monitor%20Reports%2FSummary_Report%2F01-01-2020%20Client1%20AccessMonitor%20Analysis%20Report%20v1.pdf',
                  downloadable: true,
                  preview: null,
                  reportId: 7911,
                  reportName: '01-01-2020 Client1 AccessMonitor Analysis Report v1.pdf',
                },
                {
                  dateAvailable: 'Tue, 06 Apr 2021 00:00:00 GMT',
                  downloadLink:
                    'https://aws-a0011-use1-00-d-s3b-aftm-shr-data01.s3.amazonaws.com/2020%2FSemester%201%2FAccess%20Monitor%20Reports%2FData_Report%2FSemesterly%2FAM_%5BCycle%5D_%5BClient_ID%5D_data_report_v1.txt',
                  downloadable: true,
                  preview: 'accessMonitorSemesterlyDataReport',
                  reportId: 7910,
                  reportName: 'AM_[Cycle]_[Client_ID]_data_report_v1.txt',
                },
              ],
              folderName: 'AccessMonitor Reports',
              id: 100023,
            },
            {
              files: [
                {
                  dateAvailable: 'Tue, 06 Apr 2021 00:00:00 GMT',
                  downloadLink:
                    'https://aws-a0011-use1-00-d-s3b-aftm-shr-data01.s3.amazonaws.com/2020%2FSemester%201%2FAffinity%20Monitor%20Reports%2FSummary_Report%2F2020-01-01%20AffinityMonitor%C2%AE%20Summary%20Report%20-%20Client.pdf',
                  downloadable: true,
                  preview: null,
                  reportId: 7913,
                  reportName: '2020-01-01 AffinityMonitor\u00ae Summary Report - Client.pdf',
                },
                {
                  dateAvailable: 'Tue, 01 Jun 2021 00:00:00 GMT',
                  downloadLink:
                    'https://aws-a0011-use1-00-d-s3b-aftm-shr-data01.s3.amazonaws.com/2020%2FSemester%201%2FAffinity%20Monitor%20Reports%2FData_Report%2F2020-01-01_AfM_%5BCYCLE%5D_Client_HCP_Report_v01.txt',
                  downloadable: false,
                  preview: 'affinityMonitorDataReport',
                  reportId: 7912,
                  reportName: '2020-01-01_AfM_[CYCLE]_Client_HCP_Report_v01.txt',
                },
              ],
              folderName: 'AffinityMonitor Reports',
              id: 100024,
            },
          ],
          semester: 'Semester 1',
          year: 2020,
        },
        {
          id: 100025,
          reportAvailable: '10 out of 10',
          reports: [
            {
              files: [
                {
                  dateAvailable: 'Tue, 06 Apr 2021 00:00:00 GMT',
                  downloadLink:
                    'https://aws-a0011-use1-00-d-s3b-aftm-shr-data01.s3.amazonaws.com/2019%2FSemester%202%2FAffinity%20Monitor%20Reports%2FData_Report%2F2019-07-01_AfM_%5BCYCLE%5D_Client_HCP_Report_v01.txt',
                  downloadable: true,
                  preview: 'affinityMonitorDataReport',
                  reportId: 7902,
                  reportName: '2019-07-01_AfM_[CYCLE]_Client_HCP_Report_v01.txt',
                },
                {
                  dateAvailable: 'Tue, 06 Apr 2021 00:00:00 GMT',
                  downloadLink:
                    'https://aws-a0011-use1-00-d-s3b-aftm-shr-data01.s3.amazonaws.com/2019%2FSemester%202%2FAffinity%20Monitor%20Reports%2FSummary_Report%2F2018-07-01%20AffinityMonitor%C2%AE%20Summary%20Report%20-%20Client.pdf',
                  downloadable: true,
                  preview: null,
                  reportId: 7903,
                  reportName: '2018-07-01 AffinityMonitor\u00ae Summary Report - Client.pdf',
                },
              ],
              folderName: 'AffinityMonitor Reports',
              id: 100026,
            },
            {
              files: [
                {
                  dateAvailable: 'Tue, 06 Apr 2021 00:00:00 GMT',
                  downloadLink:
                    'https://aws-a0011-use1-00-d-s3b-aftm-shr-data01.s3.amazonaws.com/2019%2FSemester%202%2FAccess%20Monitor%20Reports%2FData_Report%2FMonthly%2FAM_201907_%5BClient_Code%5D_data_report_v1.txt',
                  downloadable: true,
                  preview: 'accessMonitorMonthlyDataReport',
                  reportId: 7894,
                  reportName: 'AM_201907_[Client_Code]_data_report_v1.txt',
                },
                {
                  dateAvailable: 'Tue, 06 Apr 2021 00:00:00 GMT',
                  downloadLink:
                    'https://aws-a0011-use1-00-d-s3b-aftm-shr-data01.s3.amazonaws.com/2019%2FSemester%202%2FAccess%20Monitor%20Reports%2FData_Report%2FMonthly%2FAM_201908_%5BClient_Code%5D_data_report_v1.txt',
                  downloadable: true,
                  preview: 'accessMonitorMonthlyDataReport',
                  reportId: 7895,
                  reportName: 'AM_201908_[Client_Code]_data_report_v1.txt',
                },
                {
                  dateAvailable: 'Tue, 06 Apr 2021 00:00:00 GMT',
                  downloadLink:
                    'https://aws-a0011-use1-00-d-s3b-aftm-shr-data01.s3.amazonaws.com/2019%2FSemester%202%2FAccess%20Monitor%20Reports%2FData_Report%2FMonthly%2FAM_201909_%5BClient_Code%5D_data_report_v1.txt',
                  downloadable: true,
                  preview: 'accessMonitorMonthlyDataReport',
                  reportId: 7896,
                  reportName: 'AM_201909_[Client_Code]_data_report_v1.txt',
                },
                {
                  dateAvailable: 'Tue, 06 Apr 2021 00:00:00 GMT',
                  downloadLink:
                    'https://aws-a0011-use1-00-d-s3b-aftm-shr-data01.s3.amazonaws.com/2019%2FSemester%202%2FAccess%20Monitor%20Reports%2FData_Report%2FMonthly%2FAM_201910_%5BClient_Code%5D_data_report_v1.txt',
                  downloadable: true,
                  preview: 'accessMonitorMonthlyDataReport',
                  reportId: 7897,
                  reportName: 'AM_201910_[Client_Code]_data_report_v1.txt',
                },
                {
                  dateAvailable: 'Tue, 06 Apr 2021 00:00:00 GMT',
                  downloadLink:
                    'https://aws-a0011-use1-00-d-s3b-aftm-shr-data01.s3.amazonaws.com/2019%2FSemester%202%2FAccess%20Monitor%20Reports%2FData_Report%2FMonthly%2FAM_201911_%5BClient_Code%5D_data_report_v1.txt',
                  downloadable: true,
                  preview: 'accessMonitorMonthlyDataReport',
                  reportId: 7898,
                  reportName: 'AM_201911_[Client_Code]_data_report_v1.txt',
                },
                {
                  dateAvailable: 'Tue, 06 Apr 2021 00:00:00 GMT',
                  downloadLink:
                    'https://aws-a0011-use1-00-d-s3b-aftm-shr-data01.s3.amazonaws.com/2019%2FSemester%202%2FAccess%20Monitor%20Reports%2FData_Report%2FMonthly%2FAM_201912_%5BClient_Code%5D_data_report_v1.txt',
                  downloadable: true,
                  preview: 'accessMonitorMonthlyDataReport',
                  reportId: 7899,
                  reportName: 'AM_201912_[Client_Code]_data_report_v1.txt',
                },
                {
                  dateAvailable: 'Tue, 06 Apr 2021 00:00:00 GMT',
                  downloadLink:
                    'https://aws-a0011-use1-00-d-s3b-aftm-shr-data01.s3.amazonaws.com/2019%2FSemester%202%2FAccess%20Monitor%20Reports%2FData_Report%2FSemesterly%2FAM_%5BCycle%5D_%5BClient_ID%5D_data_report_v1.txt',
                  downloadable: true,
                  preview: 'accessMonitorSemesterlyDataReport',
                  reportId: 7900,
                  reportName: 'AM_[Cycle]_[Client_ID]_data_report_v1.txt',
                },
                {
                  dateAvailable: 'Tue, 06 Apr 2021 00:00:00 GMT',
                  downloadLink:
                    'https://aws-a0011-use1-00-d-s3b-aftm-shr-data01.s3.amazonaws.com/2019%2FSemester%202%2FAccess%20Monitor%20Reports%2FSummary_Report%2F01-07-2019%20Client1%20AccessMonitor%20Analysis%20Report%20v1.pdf',
                  downloadable: true,
                  preview: null,
                  reportId: 7901,
                  reportName: '01-07-2019 Client1 AccessMonitor Analysis Report v1.pdf',
                },
              ],
              folderName: 'AccessMonitor Reports',
              id: 100027,
            },
          ],
          semester: 'Semester 2',
          year: 2019,
        },
        {
          id: 100028,
          reportAvailable: '10 out of 10',
          reports: [
            {
              files: [
                {
                  dateAvailable: 'Tue, 06 Apr 2021 00:00:00 GMT',
                  downloadLink:
                    'https://aws-a0011-use1-00-d-s3b-aftm-shr-data01.s3.amazonaws.com/2019%2FSemester%201%2FAccess%20Monitor%20Reports%2FData_Report%2FMonthly%2FAM_201901_%5BClient_Code%5D_data_report_v1.txt',
                  downloadable: true,
                  preview: 'accessMonitorMonthlyDataReport',
                  reportId: 7884,
                  reportName: 'AM_201901_[Client_Code]_data_report_v1.txt',
                },
                {
                  dateAvailable: 'Tue, 06 Apr 2021 00:00:00 GMT',
                  downloadLink:
                    'https://aws-a0011-use1-00-d-s3b-aftm-shr-data01.s3.amazonaws.com/2019%2FSemester%201%2FAccess%20Monitor%20Reports%2FSummary_Report%2F01-01-2019%20Client1%20AccessMonitor%20Analysis%20Report%20v1.pdf',
                  downloadable: true,
                  preview: null,
                  reportId: 7891,
                  reportName: '01-01-2019 Client1 AccessMonitor Analysis Report v1.pdf',
                },
                {
                  dateAvailable: 'Tue, 06 Apr 2021 00:00:00 GMT',
                  downloadLink:
                    'https://aws-a0011-use1-00-d-s3b-aftm-shr-data01.s3.amazonaws.com/2019%2FSemester%201%2FAccess%20Monitor%20Reports%2FData_Report%2FSemesterly%2FAM_%5BCycle%5D_%5BClient_ID%5D_data_report_v1.txt',
                  downloadable: true,
                  preview: 'accessMonitorSemesterlyDataReport',
                  reportId: 7890,
                  reportName: 'AM_[Cycle]_[Client_ID]_data_report_v1.txt',
                },
                {
                  dateAvailable: 'Tue, 06 Apr 2021 00:00:00 GMT',
                  downloadLink:
                    'https://aws-a0011-use1-00-d-s3b-aftm-shr-data01.s3.amazonaws.com/2019%2FSemester%201%2FAccess%20Monitor%20Reports%2FData_Report%2FMonthly%2FAM_201906_%5BClient_Code%5D_data_report_v1.txt',
                  downloadable: true,
                  preview: 'accessMonitorMonthlyDataReport',
                  reportId: 7889,
                  reportName: 'AM_201906_[Client_Code]_data_report_v1.txt',
                },
                {
                  dateAvailable: 'Tue, 06 Apr 2021 00:00:00 GMT',
                  downloadLink:
                    'https://aws-a0011-use1-00-d-s3b-aftm-shr-data01.s3.amazonaws.com/2019%2FSemester%201%2FAccess%20Monitor%20Reports%2FData_Report%2FMonthly%2FAM_201905_%5BClient_Code%5D_data_report_v1.txt',
                  downloadable: true,
                  preview: 'accessMonitorMonthlyDataReport',
                  reportId: 7888,
                  reportName: 'AM_201905_[Client_Code]_data_report_v1.txt',
                },
                {
                  dateAvailable: 'Tue, 06 Apr 2021 00:00:00 GMT',
                  downloadLink:
                    'https://aws-a0011-use1-00-d-s3b-aftm-shr-data01.s3.amazonaws.com/2019%2FSemester%201%2FAccess%20Monitor%20Reports%2FData_Report%2FMonthly%2FAM_201904_%5BClient_Code%5D_data_report_v1.txt',
                  downloadable: true,
                  preview: 'accessMonitorMonthlyDataReport',
                  reportId: 7887,
                  reportName: 'AM_201904_[Client_Code]_data_report_v1.txt',
                },
                {
                  dateAvailable: 'Tue, 06 Apr 2021 00:00:00 GMT',
                  downloadLink:
                    'https://aws-a0011-use1-00-d-s3b-aftm-shr-data01.s3.amazonaws.com/2019%2FSemester%201%2FAccess%20Monitor%20Reports%2FData_Report%2FMonthly%2FAM_201903_%5BClient_Code%5D_data_report_v1.txt',
                  downloadable: true,
                  preview: 'accessMonitorMonthlyDataReport',
                  reportId: 7886,
                  reportName: 'AM_201903_[Client_Code]_data_report_v1.txt',
                },
                {
                  dateAvailable: 'Tue, 06 Apr 2021 00:00:00 GMT',
                  downloadLink:
                    'https://aws-a0011-use1-00-d-s3b-aftm-shr-data01.s3.amazonaws.com/2019%2FSemester%201%2FAccess%20Monitor%20Reports%2FData_Report%2FMonthly%2FAM_201902_%5BClient_Code%5D_data_report_v1.txt',
                  downloadable: true,
                  preview: 'accessMonitorMonthlyDataReport',
                  reportId: 7885,
                  reportName: 'AM_201902_[Client_Code]_data_report_v1.txt',
                },
              ],
              folderName: 'AccessMonitor Reports',
              id: 100029,
            },
            {
              files: [
                {
                  dateAvailable: 'Tue, 06 Apr 2021 00:00:00 GMT',
                  downloadLink:
                    'https://aws-a0011-use1-00-d-s3b-aftm-shr-data01.s3.amazonaws.com/2019%2FSemester%201%2FAffinity%20Monitor%20Reports%2FSummary_Report%2F2019-01-01%20AffinityMonitor%C2%AE%20Summary%20Report%20-%20Client.pdf',
                  downloadable: true,
                  preview: null,
                  reportId: 7893,
                  reportName: '2019-01-01 AffinityMonitor\u00ae Summary Report - Client.pdf',
                },
                {
                  dateAvailable: 'Tue, 06 Apr 2021 00:00:00 GMT',
                  downloadLink:
                    'https://aws-a0011-use1-00-d-s3b-aftm-shr-data01.s3.amazonaws.com/2019%2FSemester%201%2FAffinity%20Monitor%20Reports%2FData_Report%2F2019-01-01_AfM_%5BCYCLE%5D_Client_HCP_Report_v01.txt',
                  downloadable: true,
                  preview: 'affinityMonitorDataReport',
                  reportId: 7892,
                  reportName: '2019-01-01_AfM_[CYCLE]_Client_HCP_Report_v01.txt',
                },
              ],
              folderName: 'AffinityMonitor Reports',
              id: 100030,
            },
          ],
          semester: 'Semester 1',
          year: 2019,
        },
        {
          id: 100031,
          reportAvailable: '10 out of 10',
          reports: [
            {
              files: [
                {
                  dateAvailable: 'Tue, 06 Apr 2021 00:00:00 GMT',
                  downloadLink:
                    'https://aws-a0011-use1-00-d-s3b-aftm-shr-data01.s3.amazonaws.com/2018%2FSemester%202%2FAccess%20Monitor%20Reports%2FData_Report%2FMonthly%2FAM_201808_%5BClient_Code%5D_data_report_v1.txt',
                  downloadable: true,
                  preview: 'accessMonitorMonthlyDataReport',
                  reportId: 7875,
                  reportName: 'AM_201808_[Client_Code]_data_report_v1.txt',
                },
                {
                  dateAvailable: 'Tue, 06 Apr 2021 00:00:00 GMT',
                  downloadLink:
                    'https://aws-a0011-use1-00-d-s3b-aftm-shr-data01.s3.amazonaws.com/2018%2FSemester%202%2FAccess%20Monitor%20Reports%2FSummary_Report%2F01-07-2018%20Client1%20AccessMonitor%20Analysis%20Report%20v1.pdf',
                  downloadable: true,
                  preview: null,
                  reportId: 7881,
                  reportName: '01-07-2018 Client1 AccessMonitor Analysis Report v1.pdf',
                },
                {
                  dateAvailable: 'Tue, 06 Apr 2021 00:00:00 GMT',
                  downloadLink:
                    'https://aws-a0011-use1-00-d-s3b-aftm-shr-data01.s3.amazonaws.com/2018%2FSemester%202%2FAccess%20Monitor%20Reports%2FData_Report%2FSemesterly%2FAM_%5BCycle%5D_%5BClient_ID%5D_data_report_v1.txt',
                  downloadable: true,
                  preview: 'accessMonitorSemesterlyDataReport',
                  reportId: 7880,
                  reportName: 'AM_[Cycle]_[Client_ID]_data_report_v1.txt',
                },
                {
                  dateAvailable: 'Tue, 06 Apr 2021 00:00:00 GMT',
                  downloadLink:
                    'https://aws-a0011-use1-00-d-s3b-aftm-shr-data01.s3.amazonaws.com/2018%2FSemester%202%2FAccess%20Monitor%20Reports%2FData_Report%2FMonthly%2FAM_201812_%5BClient_Code%5D_data_report_v1.txt',
                  downloadable: true,
                  preview: 'accessMonitorMonthlyDataReport',
                  reportId: 7879,
                  reportName: 'AM_201812_[Client_Code]_data_report_v1.txt',
                },
                {
                  dateAvailable: 'Tue, 06 Apr 2021 00:00:00 GMT',
                  downloadLink:
                    'https://aws-a0011-use1-00-d-s3b-aftm-shr-data01.s3.amazonaws.com/2018%2FSemester%202%2FAccess%20Monitor%20Reports%2FData_Report%2FMonthly%2FAM_201811_%5BClient_Code%5D_data_report_v1.txt',
                  downloadable: true,
                  preview: 'accessMonitorMonthlyDataReport',
                  reportId: 7878,
                  reportName: 'AM_201811_[Client_Code]_data_report_v1.txt',
                },
                {
                  dateAvailable: 'Tue, 06 Apr 2021 00:00:00 GMT',
                  downloadLink:
                    'https://aws-a0011-use1-00-d-s3b-aftm-shr-data01.s3.amazonaws.com/2018%2FSemester%202%2FAccess%20Monitor%20Reports%2FData_Report%2FMonthly%2FAM_201810_%5BClient_Code%5D_data_report_v1.txt',
                  downloadable: true,
                  preview: 'accessMonitorMonthlyDataReport',
                  reportId: 7877,
                  reportName: 'AM_201810_[Client_Code]_data_report_v1.txt',
                },
                {
                  dateAvailable: 'Tue, 06 Apr 2021 00:00:00 GMT',
                  downloadLink:
                    'https://aws-a0011-use1-00-d-s3b-aftm-shr-data01.s3.amazonaws.com/2018%2FSemester%202%2FAccess%20Monitor%20Reports%2FData_Report%2FMonthly%2FAM_201809_%5BClient_Code%5D_data_report_v1.txt',
                  downloadable: true,
                  preview: 'accessMonitorMonthlyDataReport',
                  reportId: 7876,
                  reportName: 'AM_201809_[Client_Code]_data_report_v1.txt',
                },
                {
                  dateAvailable: 'Tue, 06 Apr 2021 00:00:00 GMT',
                  downloadLink:
                    'https://aws-a0011-use1-00-d-s3b-aftm-shr-data01.s3.amazonaws.com/2018%2FSemester%202%2FAccess%20Monitor%20Reports%2FData_Report%2FMonthly%2FAM_201807_%5BClient_Code%5D_data_report_v1.txt',
                  downloadable: true,
                  preview: 'accessMonitorMonthlyDataReport',
                  reportId: 7874,
                  reportName: 'AM_201807_[Client_Code]_data_report_v1.txt',
                },
              ],
              folderName: 'AccessMonitor Reports',
              id: 100032,
            },
            {
              files: [
                {
                  dateAvailable: 'Tue, 06 Apr 2021 00:00:00 GMT',
                  downloadLink:
                    'https://aws-a0011-use1-00-d-s3b-aftm-shr-data01.s3.amazonaws.com/2018%2FSemester%202%2FAffinity%20Monitor%20Reports%2FSummary_Report%2F2018-07-01%20AffinityMonitor%C2%AE%20Summary%20Report%20-%20Client.pdf',
                  downloadable: true,
                  preview: null,
                  reportId: 7883,
                  reportName: '2018-07-01 AffinityMonitor\u00ae Summary Report - Client.pdf',
                },
                {
                  dateAvailable: 'Tue, 06 Apr 2021 00:00:00 GMT',
                  downloadLink:
                    'https://aws-a0011-use1-00-d-s3b-aftm-shr-data01.s3.amazonaws.com/2018%2FSemester%202%2FAffinity%20Monitor%20Reports%2FData_Report%2F2018-07-01_AfM_%5BCYCLE%5D_Client_HCP_Report_v01.txt',
                  downloadable: true,
                  preview: 'affinityMonitorDataReport',
                  reportId: 7882,
                  reportName: '2018-07-01_AfM_[CYCLE]_Client_HCP_Report_v01.txt',
                },
              ],
              folderName: 'AffinityMonitor Reports',
              id: 100033,
            },
          ],
          semester: 'Semester 2',
          year: 2018,
        },
        {
          id: 100034,
          reportAvailable: '10 out of 10',
          reports: [
            {
              files: [
                {
                  dateAvailable: 'Tue, 06 Apr 2021 00:00:00 GMT',
                  downloadLink:
                    'https://aws-a0011-use1-00-d-s3b-aftm-shr-data01.s3.amazonaws.com/2018%2FSemester%201%2FAccess%20Monitor%20Reports%2FData_Report%2FMonthly%2FAM_201802_%5BClient_Code%5D_data_report_v1.txt',
                  downloadable: true,
                  preview: 'accessMonitorMonthlyDataReport',
                  reportId: 7865,
                  reportName: 'AM_201802_[Client_Code]_data_report_v1.txt',
                },
                {
                  dateAvailable: 'Tue, 06 Apr 2021 00:00:00 GMT',
                  downloadLink:
                    'https://aws-a0011-use1-00-d-s3b-aftm-shr-data01.s3.amazonaws.com/2018%2FSemester%201%2FAccess%20Monitor%20Reports%2FSummary_Report%2F01-01-2018%20Client1%20AccessMonitor%20Analysis%20Report%20v1.pdf',
                  downloadable: true,
                  preview: null,
                  reportId: 7871,
                  reportName: '01-01-2018 Client1 AccessMonitor Analysis Report v1.pdf',
                },
                {
                  dateAvailable: 'Tue, 06 Apr 2021 00:00:00 GMT',
                  downloadLink:
                    'https://aws-a0011-use1-00-d-s3b-aftm-shr-data01.s3.amazonaws.com/2018%2FSemester%201%2FAccess%20Monitor%20Reports%2FData_Report%2FSemesterly%2FAM_%5BCycle%5D_%5BClient_ID%5D_data_report_v1.txt',
                  downloadable: true,
                  preview: 'accessMonitorSemesterlyDataReport',
                  reportId: 7870,
                  reportName: 'AM_[Cycle]_[Client_ID]_data_report_v1.txt',
                },
                {
                  dateAvailable: 'Tue, 06 Apr 2021 00:00:00 GMT',
                  downloadLink:
                    'https://aws-a0011-use1-00-d-s3b-aftm-shr-data01.s3.amazonaws.com/2018%2FSemester%201%2FAccess%20Monitor%20Reports%2FData_Report%2FMonthly%2FAM_201806_%5BClient_Code%5D_data_report_v1.txt',
                  downloadable: true,
                  preview: 'accessMonitorMonthlyDataReport',
                  reportId: 7869,
                  reportName: 'AM_201806_[Client_Code]_data_report_v1.txt',
                },
                {
                  dateAvailable: 'Tue, 06 Apr 2021 00:00:00 GMT',
                  downloadLink:
                    'https://aws-a0011-use1-00-d-s3b-aftm-shr-data01.s3.amazonaws.com/2018%2FSemester%201%2FAccess%20Monitor%20Reports%2FData_Report%2FMonthly%2FAM_201805_%5BClient_Code%5D_data_report_v1.txt',
                  downloadable: true,
                  preview: 'accessMonitorMonthlyDataReport',
                  reportId: 7868,
                  reportName: 'AM_201805_[Client_Code]_data_report_v1.txt',
                },
                {
                  dateAvailable: 'Tue, 06 Apr 2021 00:00:00 GMT',
                  downloadLink:
                    'https://aws-a0011-use1-00-d-s3b-aftm-shr-data01.s3.amazonaws.com/2018%2FSemester%201%2FAccess%20Monitor%20Reports%2FData_Report%2FMonthly%2FAM_201804_%5BClient_Code%5D_data_report_v1.txt',
                  downloadable: true,
                  preview: 'accessMonitorMonthlyDataReport',
                  reportId: 7867,
                  reportName: 'AM_201804_[Client_Code]_data_report_v1.txt',
                },
                {
                  dateAvailable: 'Tue, 06 Apr 2021 00:00:00 GMT',
                  downloadLink:
                    'https://aws-a0011-use1-00-d-s3b-aftm-shr-data01.s3.amazonaws.com/2018%2FSemester%201%2FAccess%20Monitor%20Reports%2FData_Report%2FMonthly%2FAM_201803_%5BClient_Code%5D_data_report_v1.txt',
                  downloadable: true,
                  preview: 'accessMonitorMonthlyDataReport',
                  reportId: 7866,
                  reportName: 'AM_201803_[Client_Code]_data_report_v1.txt',
                },
                {
                  dateAvailable: 'Tue, 06 Apr 2021 00:00:00 GMT',
                  downloadLink:
                    'https://aws-a0011-use1-00-d-s3b-aftm-shr-data01.s3.amazonaws.com/2018%2FSemester%201%2FAccess%20Monitor%20Reports%2FData_Report%2FMonthly%2FAM_201801_%5BClient_Code%5D_data_report_v1.txt',
                  downloadable: true,
                  preview: 'accessMonitorMonthlyDataReport',
                  reportId: 7864,
                  reportName: 'AM_201801_[Client_Code]_data_report_v1.txt',
                },
              ],
              folderName: 'AccessMonitor Reports',
              id: 100035,
            },
            {
              files: [
                {
                  dateAvailable: 'Tue, 06 Apr 2021 00:00:00 GMT',
                  downloadLink:
                    'https://aws-a0011-use1-00-d-s3b-aftm-shr-data01.s3.amazonaws.com/2018%2FSemester%201%2FAffinity%20Monitor%20Reports%2FSummary_Report%2F2018-01-01%20AffinityMonitor%C2%AE%20Summary%20Report%20-%20Client.pdf',
                  downloadable: true,
                  preview: null,
                  reportId: 7873,
                  reportName: '2018-01-01 AffinityMonitor\u00ae Summary Report - Client.pdf',
                },
                {
                  dateAvailable: 'Tue, 06 Apr 2021 00:00:00 GMT',
                  downloadLink:
                    'https://aws-a0011-use1-00-d-s3b-aftm-shr-data01.s3.amazonaws.com/2018%2FSemester%201%2FAffinity%20Monitor%20Reports%2FData_Report%2F2018-01-01_AfM_%5BCYCLE%5D_Client_HCP_Report_v01.txt',
                  downloadable: true,
                  preview: 'affinityMonitorDataReport',
                  reportId: 7872,
                  reportName: '2018-01-01_AfM_[CYCLE]_Client_HCP_Report_v01.txt',
                },
              ],
              folderName: 'AffinityMonitor Reports',
              id: 100036,
            },
          ],
          semester: 'Semester 1',
          year: 2018,
        },
      ],
      reportNames: [
        'AM_201910_[Client_Code]_data_report_v1.txt',
        '2018-01-01_AfM_[CYCLE]_Client_HCP_Report_v01.txt',
        'AM_201812_[Client_Code]_data_report_v1.txt',
        '01-01-2018 Client1 AccessMonitor Analysis Report v1.pdf',
        'AM_201802_[Client_Code]_data_report_v1.txt',
        '2020-01-01 AffinityMonitor\u00ae Summary Report - Client.pdf',
        'AM_201809_[Client_Code]_data_report_v1.txt',
        'AM_201909_[Client_Code]_data_report_v1.txt',
        'AM_201905_[Client_Code]_data_report_v1.txt',
        '2019-01-01 AffinityMonitor\u00ae Summary Report - Client.pdf',
        'AM_201903_[Client_Code]_data_report_v1.txt',
        'AM_201912_[Client_Code]_data_report_v1.txt',
        'AM_202003_[Client_Code]_data_report_v1.txt',
        '01-01-2019 Client1 AccessMonitor Analysis Report v1.pdf',
        'AM_201810_[Client_Code]_data_report_v1.txt',
        '2020-01-01_AfM_[CYCLE]_Client_HCP_Report_v01.txt',
        'AffinityMonitor Reports',
        '01-01-2020 Client1 AccessMonitor Analysis Report v1.pdf',
        'AM_201807_[Client_Code]_data_report_v1.txt',
        'AM_201902_[Client_Code]_data_report_v1.txt',
        'AM_202007_[Client_Code]_data_report_v1.txt',
        'AM_202004_[Client_Code]_data_report_v1.txt',
        'AM_202002_[Client_Code]_data_report_v1.txt',
        'AM_April_Client_01_data_report_v1.txt',
        'AM_201911_[Client_Code]_data_report_v1.txt',
        '2020-07-01 AffinityMonitor\u00ae Summary Report - Client.pdf',
        '01-07-2020 Client1 AccessMonitor Analysis Report v1.pdf',
        'AM_201806_[Client_Code]_data_report_v1.txt',
        'AM_201901_[Client_Code]_data_report_v1.txt',
        '2019-01-01_AfM_[CYCLE]_Client_HCP_Report_v01.txt',
        'AM_201805_[Client_Code]_data_report_v1.txt',
        'AM_[Cycle]_[Client_ID]_data_report_v1.txt',
        '2018-01-01 AffinityMonitor\u00ae Summary Report - Client.pdf',
        'AM_202010_[Client_Code]_data_report_v1.txt',
        'AM_202008_[Client_Code]_data_report_v1.txt',
        '2018-07-01 AffinityMonitor\u00ae Summary Report - Client.pdf',
        'AM_202006_[Client_Code]_data_report_v1.txt',
        'AM_201804_[Client_Code]_data_report_v1.txt',
        'AM_201907_[Client_Code]_data_report_v1.txt',
        '01-07-2018 Client1 AccessMonitor Analysis Report v1.pdf',
        'AM_201908_[Client_Code]_data_report_v1.txt',
        '2019-07-01_AfM_[CYCLE]_Client_HCP_Report_v01.txt',
        'AM_SEM1_Client_01_data_report_v1.txt',
        'AM_201906_[Client_Code]_data_report_v1.txt',
        'AM_201801_[Client_Code]_data_report_v1.txt',
        'AM_201808_[Client_Code]_data_report_v1.txt',
        'AM_202001_[Client_Code]_data_report_v1.txt',
        '2021-04-05_AfM_SEM1_Client_01_HCP_Report.txt',
        'AccessMonitor Reports',
        'AM_202012_[Client_Code]_data_report_v1.txt',
        'AM_201803_[Client_Code]_data_report_v1.txt',
        '01-07-2019 Client1 AccessMonitor Analysis Report v1.pdf',
        'AM_202005_[Client_Code]_data_report_v1.txt',
        'AM_201904_[Client_Code]_data_report_v1.txt',
        '2020-07-01_AfM_[CYCLE]_Client_HCP_Report_v01.txt',
        'AM_201811_[Client_Code]_data_report_v1.txt',
        'AM_202009_[Client_Code]_data_report_v1.txt',
        '2018-07-01_AfM_[CYCLE]_Client_HCP_Report_v01.txt',
        'AM_202011_[Client_Code]_data_report_v1.txt',
      ],
    };
    const download_report = {
      Status: 'success',
      message: 'download link created successfully',
      downloadLink: '',
    };
    const get_activity_logs = {
      tbody: [
        {
          action: {
            Cycle: 'Cycle 1',
            Filename: [
              'AM_SEM1_Client_01_data_report_v1.txt',
              'AM_April_Client_01_data_report_v1.txt',
            ],
            Product: ['AccessMonitor Reports', 'AffinityMonitor Reports'],
            Year: '2021',
          },
          channel: null,
          client: 'Client_1',
          date: 'Thu, 9 Sep 2021 00:00:00 GMT',
          description: 'Reports Downloaded.txt',
          email: 'johnsmith@client.com',
          status: 'Success',
          tab: 'Download Reports',
          user: 'John Smith',
        },
        {
          action: {
            Cycle: 'S1 2020',
            Filename: 'xyz',
            NPI_Count: 99,
            Product: 'ACM',
            Error_Message:
              'The user is not eligible for the required data. Please contact the Affinity Monitor Team for the eligibility information.',
          },
          channel: null,
          client: 'Client_1',
          date: 'Mon, 12 Apr 2021 14:07:14 GMT',
          description: 'Reports Downloaded for 2021 Semester 6',
          email: 'johnsmith@client.com',
          status: 'Failed',
          tab: 'AFFEX',
          user: 'John Smith',
        },
        {
          action: {
            Cycle: 'Cycle 2',
            Data_Period: '2021-09-01T10:35:08.122Z-2021-09-24T10:35:08.177Z',
            Description: '',
            L1_Status: '',
            L2_Status: '',
            Upload_Status: 'Under Verification',
          },
          channel: null,
          client: 'Client_1',
          date: 'Mon, 12 Apr 2021 14:07:14 GMT',
          description: 'Reports Downloaded for 2021 Semester 7',
          email: 'johnsmith@client.com',
          status: 'Success',
          tab: 'Upload',
          user: 'John Smith',
        },
        {
          channel: null,
          client: 'Client_1',
          date: 'Mon, 12 Apr 2021 14:07:14 GMT',
          description: 'Reports Downloaded for 2021 Semester 5',
          email: 'johnsmith@client.com',
          status: 'Success',
          tab: 'Benchmark Dashboard',
          user: 'John Smith',
        },
        {
          action: {
            Cycle: 'Cycle 2',
            Filename: [
              'AM_SEM1_Client_01_data_report_v1.txt',
              'AM_April_Client_01_data_report_v1.txt',
            ],
            Product: ['AccessMonitor Reports', 'AffinityMonitor Reports'],
            Year: '2021',
          },
          channel: null,
          client: 'Client_1',
          date: 'Mon, 12 Apr 2021 14:07:14 GMT',
          description: 'Reports Downloaded for 2021 Semester 4',
          email: 'johnsmith@client.com',
          status: 'Success',
          tab: 'Download Reports',
          user: 'John Smith',
        },
        {
          action: {
            Cycle: '',
            Filename: [
              'AM_SEM1_Client_01_data_report_v1.txt',
              'AM_April_Client_01_data_report_v1.txt',
            ],
            Product: ['AccessMonitor Reports', 'AffinityMonitor Reports'],
            Year: '2021',
          },
          channel: null,
          client: 'Client_1',
          date: 'Mon, 12 Apr 2021 14:07:03 GMT',
          description: 'Reports Downloaded for 2021 Semester 5',
          email: 'johnsmith@client.com',
          status: 'Success',
          tab: 'Download Reports',
          user: 'John Smith',
        },
        {
          action: {},
          channel: null,
          client: 'Client_1',
          date: 'Mon, 12 Apr 2021 14:07:03 GMT',
          description: 'Reports Downloaded for 2021 Semester 7',
          email: 'johnsmith@client.com',
          status: 'Success',
          tab: 'Download Reports',
          user: 'John Smith',
        },
        {
          channel: null,
          client: 'Client_1',
          date: 'Mon, 12 Apr 2021 14:07:03 GMT',
          description: 'Reports Downloaded for 2021 Semester 4',
          email: 'johnsmith@client.com',
          status: 'Success',
          tab: 'Download Reports',
          user: 'John Smith',
        },
        {
          channel: null,
          client: 'Client_1',
          date: 'Mon, 12 Apr 2021 14:07:03 GMT',
          description: 'Reports Downloaded for 2021 Semester 6',
          email: 'johnsmith@client.com',
          status: 'Success',
          tab: 'Download Reports',
          user: 'John Smith',
        },
        {
          channel: null,
          client: 'Client_1',
          date: 'Mon, 12 Apr 2021 14:06:13 GMT',
          description: 'Reports Downloaded for 2021 Semester 5',
          email: 'johnsmith@client.com',
          status: 'Success',
          tab: 'Download Reports',
          user: 'John Smith',
        },
        {
          channel: null,
          client: 'Client_1',
          date: 'Mon, 12 Apr 2021 14:06:13 GMT',
          description: 'Reports Downloaded for 2021 Semester 4',
          email: 'johnsmith@client.com',
          status: 'Success',
          tab: 'Download Reports',
          user: 'John Smith',
        },
        {
          channel: null,
          client: 'Client_1',
          date: 'Mon, 12 Apr 2021 14:06:13 GMT',
          description: 'Reports Downloaded for 2021 Semester 6',
          email: 'johnsmith@client.com',
          status: 'Success',
          tab: 'Download Reports',
          user: 'John Smith',
        },
        {
          channel: null,
          client: 'Client_1',
          date: 'Mon, 12 Apr 2021 14:06:13 GMT',
          description: 'Reports Downloaded for 2021 Semester 7',
          email: 'johnsmith@client.com',
          status: 'Success',
          tab: 'Download Reports',
          user: 'John Smith',
        },
        {
          channel: null,
          client: 'Client_1',
          date: 'Mon, 12 Apr 2021 13:50:59 GMT',
          description:
            'Reports Downloaded for 2021 Semester 7 AccessMonitor Reports AM_April_Client_01_data_report_v1.txt',
          email: 'johnsmith@client.com',
          status: 'Success',
          tab: 'Download Reports',
          user: 'John Smith',
        },
        {
          channel: null,
          client: 'Client_1',
          date: 'Mon, 12 Apr 2021 13:13:44 GMT',
          description:
            'Reports Downloaded for 2019 Semester 1 AccessMonitor Reports AM_April_Client_01_data_report_v1.txt',
          email: 'johnsmith@client.com',
          status: 'Success',
          tab: 'Download Reports',
          user: 'John Smith',
        },
        {
          channel: null,
          client: 'Client_1',
          date: 'Mon, 12 Apr 2021 13:13:18 GMT',
          description:
            'Reports Downloaded for 2018 Semester 1 AccessMonitor Reports AM_201802_Client_Code_data_report_v1.txt',
          email: 'johnsmith@client.com',
          status: 'Success',
          tab: 'Download Reports',
          user: 'John Smith',
        },
        {
          channel: null,
          client: 'Client_1',
          date: 'Sat, 10 Apr 2021 00:00:00 GMT',
          description: 'Reports Downloaded.txt',
          email: 'johnsmith@client.com',
          status: 'Success',
          tab: 'Download Reports',
          user: 'John Smith',
        },
        {
          channel: null,
          client: 'Client_1',
          date: 'Fri, 12 Jun 2020 00:00:00 GMT',
          description: 'Reports Downloaded.txt',
          email: 'johnsmith@client.com',
          status: 'Success',
          user: 'John Smith',
          tab: 'Benchmark Dashboard',
        },
        {
          channel: null,
          client: 'Client_1',
          date: 'Wed, 10 Jun 2020 00:00:00 GMT',
          description: 'Reports Downloaded.txt',
          email: 'johnsmith@client.com',
          status: 'Success',
          user: 'John Smith',
          tab: 'AFFEX',
        },
        {
          channel: null,
          client: 'Client_1',
          date: 'Sun, 12 Jan 2020 00:00:00 GMT',
          description: 'Reports Downloaded.txt',
          email: 'johnsmith@client.com',
          status: 'Failed',
          user: 'John Smith',
          tab: 'Upload',
        },
        {
          channel: null,
          client: 'Client_1',
          date: 'Fri, 10 Jan 2020 00:00:00 GMT',
          description: 'Reports Downloaded.txt',
          email: 'johnsmith@client.com',
          status: 'Success',
          tab: 'Download Reports',
          user: 'John Smith',
        },
      ],
      thead: [
        { key: 'client', value: 'Client' },
        { key: 'user', value: 'User' },
        { key: 'status', value: 'Status' },
        { key: 'tab', value: 'Tab' },
        { key: 'description', value: 'Description' },
        { key: 'date', value: 'Date' },
        { key: 'action', value: 'Actions' },
      ],
    };

    const get_template_data = {
      custom_programs: [
        {
          channel: 'Personal Face-to-Face',
          folder_name: 'Personal Face-to-Face',
          info: '',
          programs: ['testddd', 'testss'],
          status: 'Pending',
          template_id: 1,
        },
        {
          channel: 'Peer Interaction',
          folder_name: 'Peer Interaction',
          info: '',
          programs: ['testss'],
          status: 'Pending',
          template_id: 3,
        },
      ],
      data: [
        {
          channel: 'Personal Face-to-Face:',
          folder_name: 'Personal Face-to-Face',
          info: 'Sales reps that call on HCPs to promote products',
          programs: [
            'Live Rep Visits \u2013 Activity (in-person, remote, etc.)',
            'Live Rep Visits - Call Plan or Target List',
            'Live Rep Visits - No See',
            'Rep Triggered Emails',
          ],
          template_id: 1,
          template_url:
            'https://api.aftm.dev.zsservices.com/download_template?folder_name=Personal Face-to-Face',
        },
        {
          channel: 'Personal Remote:',
          folder_name: 'Personal Remote',
          info: 'Calls delivered over a virtual meeting or phone call',
          programs: ['Call center', 'Telesales', 'Live electronic rep interactions'],
          template_id: 2,
          template_url:
            'https://api.aftm.dev.zsservices.com/download_template?folder_name=Personal Remote',
        },
        {
          channel: 'Peer Interaction:',
          folder_name: 'Peer Interaction',
          info: 'HCPs interacting with other HCPs in a forum organized by pharma manufacturers',
          programs: ['Speaker Programs', 'Lunch & Learns', 'HCP Webinars'],
          template_id: 3,
          template_url:
            'https://api.aftm.dev.zsservices.com/download_template?folder_name=Peer Interaction',
        },
        {
          channel: 'Digital Push:',
          folder_name: 'Digital Push',
          info: 'Digital messaging pushed to HCPs',
          programs: ['Email (Rep-triggered, Home office or Third party)', 'Mobile alerts'],
          template_id: 4,
          template_url:
            'https://api.aftm.dev.zsservices.com/download_template?folder_name=Digital Push',
        },
        {
          channel: 'Digital Pull:',
          folder_name: 'Digital Pull',
          info: 'HCP initiated engagements with digital messaging',
          programs: [
            'Online ads',
            'Online resource requests (e-samples, e-vouchers, etc)',
            'Website (Microsite)',
            'Recorded video or Self-guided e-details',
            'EMR Marketing',
          ],
          template_id: 5,
          template_url:
            'https://api.aftm.dev.zsservices.com/download_template?folder_name=Digital Pull',
        },
        {
          channel: 'Direct:',
          folder_name: 'Direct',
          info: 'Physical mail sent to an HCP',
          programs: ['Direct Mail'],
          template_id: 6,
          template_url: 'https://api.aftm.dev.zsservices.com/download_template?folder_name=Direct',
        },
        {
          channel: 'Customer Master:',
          folder_name: 'Customer Master',
          info: 'HCP profile information such as NPI, HCP Specialty, ZIP code',
          programs: ['Customer Master'],
          template_id: 7,
          template_url:
            'https://api.aftm.dev.zsservices.com/download_template?folder_name=Customer Master',
        },
        {
          channel: 'Offer:',
          folder_name: 'Offer',
          info: 'Sponsored offers',
          programs: ['Samples', 'Copay', 'Vouchers', 'Patient kit'],
          template_id: 8,
          template_url: 'https://api.aftm.dev.zsservices.com/download_template?folder_name=Offer',
        },
      ],
    };

    const save_upload_data = {
      status: 'SUCCESS',
      custom_program_id: 24,
      upload_status: 'Success'
    };

    const add_custom_program = {
      status: 'ERROR',
      message: 'This program already exists',
    };

    const get_upload_data = {
      custom_programs: [
        {
          channel_id: 8,
          custom_program_id: 23,
          custom_program_name: 'test 2d',
          custom_program_status: 'Pending',
        },
        {
          channel_id: 8,
          custom_program_id: 25,
          custom_program_name: 'test 2d',
          custom_program_status: 'Pending',
        },
        {
          channel_id: 8,
          custom_program_id: 26,
          custom_program_name: 'test 2d',
          custom_program_status: 'Pending',
        },
        {
          channel_id: 8,
          custom_program_id: 27,
          custom_program_name: 'test 2d',
          custom_program_status: 'Pending',
        },
        {
          channel_id: 8,
          custom_program_id: 28,
          custom_program_name: 'test 2d',
          custom_program_status: 'Pending',
        },
        {
          channel_id: 8,
          custom_program_id: 30,
          custom_program_name: 'test 2d',
          custom_program_status: 'Pending',
        },
        {
          channel_id: 8,
          custom_program_id: 31,
          custom_program_name: 'test 2d',
          custom_program_status: 'Pending',
        },
        {
          channel_id: 8,
          custom_program_id: 32,
          custom_program_name: 'test 2d',
          custom_program_status: 'Pending',
        },
        {
          channel_id: 8,
          custom_program_id: 33,
          custom_program_name: 'test 2d',
          custom_program_status: 'Pending',
        },
        {
          channel_id: 8,
          custom_program_id: 34,
          custom_program_name: 'test 2d',
          custom_program_status: 'Pending',
        },
        {
          channel_id: 8,
          custom_program_id: 35,
          custom_program_name: 'test 2d',
          custom_program_status: 'Pending',
        },
        {
          channel_id: 8,
          custom_program_id: 36,
          custom_program_name: 'test 2d',
          custom_program_status: 'Pending',
        },
        {
          channel_id: 8,
          custom_program_id: 39,
          custom_program_name: 'test 2d',
          custom_program_status: 'Pending',
        },
        {
          channel_id: 8,
          custom_program_id: 40,
          custom_program_name: 'test 2d',
          custom_program_status: 'Pending',
        },
        {
          channel_id: 8,
          custom_program_id: 66,
          custom_program_name: 'test 2d',
          custom_program_status: 'Pending',
        },
        {
          channel_id: 8,
          custom_program_id: 69,
          custom_program_name: 'test 2d',
          custom_program_status: 'Pending',
        },
        {
          channel_id: 8,
          custom_program_id: 70,
          custom_program_name: 'test 2d',
          custom_program_status: 'Pending',
        },
        {
          channel_id: 8,
          custom_program_id: 71,
          custom_program_name: 'test 2d',
          custom_program_status: 'Pending',
        },
        {
          channel_id: 8,
          custom_program_id: 100,
          custom_program_name: 'test 2d',
          custom_program_status: 'Pending',
        },
        {
          channel_id: 8,
          custom_program_id: 101,
          custom_program_name: 'test 2d',
          custom_program_status: 'Pending',
        },
        {
          channel_id: 8,
          custom_program_id: 102,
          custom_program_name: 'test 2d',
          custom_program_status: 'Pending',
        },
      ],
      data: [
        {
          bussiness_justification: {},
          bussiness_justification_reason: null,
          channel_step: 'Complete',
          edit_count: 0,
          file_info: {
            description: 'Hello',
            end_date: '2022-02-26T00:00:00.000Z',
            file_title: 'Test',
            start_date: '2022-02-01T00:00:00.000Z',
          },
          file_step: 'Complete',
          files: [
            {
              channelId: 4,
              channel_name: 'Digital Push',
              custom_program: null,
              custom_program_id: null,
              error_description: '',
              file_id: 447,
              file_type: 'web',
              filename: 'AM_April_Client_01_data_report_v1.txt',
              program: 'Mobile alerts',
              s3_path:
                'https://aws-a0011-use1-00-d-s3b-aftm-dqm-data01.s3.amazonaws.com/AM_April_Client_01_data_report_v1.txt',
              size: '0',
            },
            {
              channelId: 3,
              channel_name: 'Peer Interaction',
              custom_program: null,
              custom_program_id: null,
              error_description: '',
              file_id: 446,
              file_type: 'web',
              filename: 'afm.txt',
              program: 'Speaker Programs',
              s3_path: 'https://aws-a0011-use1-00-d-s3b-aftm-dqm-data01.s3.amazonaws.com/afm.txt',
              size: '0',
            },
            {
              channelId: 6,
              channel_name: 'Direct',
              custom_program: null,
              custom_program_id: null,
              error_description: '',
              file_id: 450,
              file_type: 'web',
              filename: 'blank.csv',
              program: 'Direct Mail',
              s3_path: 'https://aws-a0011-use1-00-d-s3b-aftm-dqm-data01.s3.amazonaws.com/blank.csv',
              size: '0',
            },
            {
              channelId: 1,
              channel_name: 'Personal Face-to-Face',
              custom_program: null,
              custom_program_id: null,
              error_description: '',
              file_id: 449,
              file_type: 'web',
              filename: 'comma.csv',
              program: 'Live Rep Visits \u2013 Activity (in-person, remote, etc.)',
              s3_path: 'https://aws-a0011-use1-00-d-s3b-aftm-dqm-data01.s3.amazonaws.com/comma.csv',
              size: '0',
            },
            {
              channelId: 8,
              channel_name: 'Offer',
              custom_program: null,
              custom_program_id: null,
              error_description: '',
              file_id: 448,
              file_type: 'web',
              filename: 'AM_SEM1_Client_01_data_report_v1.txt',
              program: 'Samples',
              s3_path:
                'https://aws-a0011-use1-00-d-s3b-aftm-dqm-data01.s3.amazonaws.com/AM_SEM1_Client_01_data_report_v1.txt',
              size: '0',
            },
            {
              channelId: 4,
              channel_name: 'Digital Push',
              custom_program: null,
              custom_program_id: null,
              error_description: '',
              file_id: 447,
              file_type: 'web',
              filename: 'AM_April_Client_01_data_report_v1.txt',
              program: 'Mobile alerts',
              s3_path:
                'https://aws-a0011-use1-00-d-s3b-aftm-dqm-data01.s3.amazonaws.com/AM_April_Client_01_data_report_v1.txt',
              size: '0',
            },
            {
              channelId: 4,
              channel_name: 'Digital Push',
              custom_program: null,
              custom_program_id: null,
              error_description: '',
              file_id: 447,
              file_type: 'web',
              filename: 'AM_April_Client_01_data_report_v1.txt',
              program: 'Mobile alerts',
              s3_path:
                'https://aws-a0011-use1-00-d-s3b-aftm-dqm-data01.s3.amazonaws.com/AM_April_Client_01_data_report_v1.txt',
              size: '0',
            },
            {
              channelId: 4,
              channel_name: 'Digital Push',
              custom_program: null,
              custom_program_id: null,
              error_description: '',
              file_id: 447,
              file_type: 'web',
              filename: 'AM_April_Client_01_data_report_v1.txt',
              program: 'Mobile alerts',
              s3_path:
                'https://aws-a0011-use1-00-d-s3b-aftm-dqm-data01.s3.amazonaws.com/AM_April_Client_01_data_report_v1.txt',
              size: '0',
            },
            {
              channelId: 4,
              channel_name: 'Digital Push',
              custom_program: null,
              custom_program_id: null,
              error_description: '',
              file_id: 447,
              file_type: 'web',
              filename: 'AM_April_Client_01_data_report_v1.txt',
              program: 'Mobile alerts',
              s3_path:
                'https://aws-a0011-use1-00-d-s3b-aftm-dqm-data01.s3.amazonaws.com/AM_April_Client_01_data_report_v1.txt',
              size: '0',
            },
            {
              channelId: 4,
              channel_name: 'Digital Push',
              custom_program: null,
              custom_program_id: null,
              error_description: '',
              file_id: 447,
              file_type: 'web',
              filename: 'AM_April_Client_01_data_report_v1.txt',
              program: 'Mobile alerts',
              s3_path:
                'https://aws-a0011-use1-00-d-s3b-aftm-dqm-data01.s3.amazonaws.com/AM_April_Client_01_data_report_v1.txt',
              size: '0',
            },
            {
              channelId: 4,
              channel_name: 'Digital Push',
              custom_program: null,
              custom_program_id: null,
              error_description: '',
              file_id: 447,
              file_type: 'web',
              filename: 'AM_April_Client_01_data_report_v1.txt',
              program: 'Mobile alerts',
              s3_path:
                'https://aws-a0011-use1-00-d-s3b-aftm-dqm-data01.s3.amazonaws.com/AM_April_Client_01_data_report_v1.txt',
              size: '0',
            },
            {
              channelId: 4,
              channel_name: 'Digital Push',
              custom_program: null,
              custom_program_id: null,
              error_description: '',
              file_id: 447,
              file_type: 'web',
              filename: 'AM_April_Client_01_data_report_v1.txt',
              program: 'Mobile alerts',
              s3_path:
                'https://aws-a0011-use1-00-d-s3b-aftm-dqm-data01.s3.amazonaws.com/AM_April_Client_01_data_report_v1.txt',
              size: '0',
            },
          ],
          l1_status: null,
          l2_status: null,
          optionId: 3,
          sent_on: '2022-02-03T00:00:00.000Z',
          status: 'Complete',
          upload_file_id: 556,
        },
        {
          bussiness_justification: {},
          bussiness_justification_reason: null,
          channel_step: 'Complete',
          edit_count: 0,
          file_info: {
            description: null,
            end_date: '2022-02-26T00:00:00.000Z',
            file_title: 'File',
            start_date: '2022-02-02T00:00:00.000Z',
          },
          file_step: 'Complete',
          files: [
            {
              channelId: 3,
              channel_name: 'Peer Interaction',
              custom_program: null,
              custom_program_id: null,
              error_description: '',
              file_id: 445,
              file_type: 'web',
              filename: 'AM_SEM1_Client_01_data_report_v1.txt',
              program: 'Speaker Programs',
              s3_path:
                'https://aws-a0011-use1-00-d-s3b-aftm-dqm-data01.s3.amazonaws.com/AM_SEM1_Client_01_data_report_v1.txt',
              size: '0',
            },
          ],
          l1_status: null,
          l2_status: null,
          optionId: 1,
          sent_on: '2022-02-02T00:00:00.000Z',
          status: 'Under Verification',
          upload_file_id: 555,
        },
        {
          bussiness_justification: {},
          bussiness_justification_reason: 't',
          channel_step: 'Complete',
          edit_count: 3,
          file_info: {
            description: null,
            end_date: '2022-03-05T00:00:00.000Z',
            file_title: 'test option 2 skip 2 step edit',
            start_date: '2022-02-28T00:00:00.000Z',
          },
          file_step: 'Complete',
          files: [
            {
              channelId: 1,
              channel_name: 'Personal Face-to-Face',
              custom_program: null,
              custom_program_id: null,
              error_description: '',
              file_id: 442,
              file_type: 'web',
              filename: 'AM_SEM1_Client_01_data_report_v1 (1).txt',
              program: 'Live Rep Visits \u2013 Activity (in-person, remote, etc.)',
              s3_path:
                'https://aws-a0011-use1-00-d-s3b-aftm-dqm-data01.s3.amazonaws.com/AM_SEM1_Client_01_data_report_v1%20(1).txt',
              size: '0',
            },
          ],
          l1_status: null,
          l2_status: null,
          optionId: 2,
          sent_on: '2022-02-01T00:00:00.000Z',
          status: 'Success',
          upload_file_id: 552,
        },
        {
          bussiness_justification: {},
          bussiness_justification_reason: null,
          channel_step: 'Complete',
          edit_count: 0,
          file_info: {
            description: null,
            end_date: '2022-02-11T00:00:00.000Z',
            file_title: 'test 2 option',
            start_date: '2022-02-03T00:00:00.000Z',
          },
          file_step: 'Incomplete',
          files: [
            {
              channelId: 8,
              channel_name: 'Offer',
              custom_program: null,
              custom_program_id: null,
              error_description: '',
              file_id: 439,
              file_type: 'None',
              filename: '',
              program: 'Copay',
              s3_path: 'None',
              size: '0',
            },
          ],
          l1_status: null,
          l2_status: null,
          optionId: 2,
          sent_on: '2022-02-01T00:00:00.000Z',
          status: 'Incomplete',
          upload_file_id: 549,
        },
        {
          bussiness_justification: {},
          bussiness_justification_reason: 'null',
          channel_step: 'Complete',
          edit_count: 0,
          file_info: {
            description: null,
            end_date: '2022-01-23T00:00:00.000Z',
            file_title: 'test 2 edit',
            start_date: '2021-12-28T00:00:00.000Z',
          },
          file_step: 'Complete',
          files: [
            {
              channelId: 1,
              channel_name: 'Personal Face-to-Face',
              custom_program: null,
              custom_program_id: null,
              error_description: '',
              file_id: 438,
              file_type: 'web',
              filename: 'AM_SEM1_Client_01_data_report_v1 (1).txt',
              program: 'Live Rep Visits \u2013 Activity (in-person, remote, etc.)',
              s3_path:
                'https://aws-a0011-use1-00-d-s3b-aftm-dqm-data01.s3.amazonaws.com/AM_SEM1_Client_01_data_report_v1%20(1).txt',
              size: '0',
            },
            {
              channelId: 1,
              channel_name: 'Personal Face-to-Face',
              custom_program: null,
              custom_program_id: null,
              error_description: '',
              file_id: 430,
              file_type: 'None',
              filename: '',
              program: 'Live Rep Visits \u2013 Activity (in-person, remote, etc.)',
              s3_path: 'None',
              size: '0',
            },
          ],
          l1_status: null,
          l2_status: null,
          optionId: 1,
          sent_on: '2022-01-28T00:00:00.000Z',
          status: 'Under Verification',
          upload_file_id: 546,
        },
        {
          bussiness_justification: {},
          bussiness_justification_reason: 'null',
          channel_step: 'Incomplete',
          edit_count: 0,
          file_info: {
            description: null,
            end_date: '2022-01-28T00:00:00.000Z',
            file_title: 'test 1 edit',
            start_date: '2021-12-28T00:00:00.000Z',
          },
          file_step: 'Incomplete',
          files: [
            {
              channelId: 5,
              channel_name: 'Digital Pull',
              custom_program: null,
              custom_program_id: null,
              error_description: '',
              file_id: 429,
              file_type: 'None',
              filename: '',
              program: 'Online ads',
              s3_path: 'None',
              size: '0',
            },
          ],
          l1_status: null,
          l2_status: null,
          optionId: 1,
          sent_on: '2022-01-28T00:00:00.000Z',
          status: 'Incomplete',
          upload_file_id: 545,
        },
        {
          bussiness_justification: {},
          bussiness_justification_reason: null,
          channel_step: 'Incomplete',
          edit_count: 0,
          file_info: {
            description: null,
            end_date: '2022-01-29T00:00:00.000Z',
            file_title: 'test',
            start_date: '2022-01-05T00:00:00.000Z',
          },
          file_step: 'Incomplete',
          files: [
            {
              channelId: 4,
              channel_name: 'Digital Push',
              custom_program: 'test push',
              custom_program_id: 123,
              error_description: '',
              file_id: 428,
              file_type: 'None',
              filename: '',
              program: 'test push',
              s3_path: 'None',
              size: '0',
            },
          ],
          l1_status: null,
          l2_status: null,
          optionId: 1,
          sent_on: '2022-01-28T00:00:00.000Z',
          status: 'Incomplete',
          upload_file_id: 544,
        },
        {
          bussiness_justification: {},
          bussiness_justification_reason: null,
          channel_step: 'Incomplete',
          edit_count: 0,
          file_info: {
            description: null,
            end_date: '2022-01-31T00:00:00.000Z',
            file_title: 'gg',
            start_date: '2021-12-29T00:00:00.000Z',
          },
          file_step: 'Incomplete',
          files: [
            {
              channelId: 6,
              channel_name: 'Direct',
              custom_program: null,
              custom_program_id: null,
              error_description: '',
              file_id: 427,
              file_type: 'None',
              filename: '',
              program: 'test diirect',
              s3_path: 'None',
              size: '0',
            },
          ],
          l1_status: null,
          l2_status: null,
          optionId: 1,
          sent_on: '2022-01-28T00:00:00.000Z',
          status: 'Incomplete',
          upload_file_id: 543,
        },
        {
          bussiness_justification: {},
          bussiness_justification_reason: null,
          channel_step: 'Incomplete',
          edit_count: 0,
          file_info: {
            description: null,
            end_date: '2022-01-29T00:00:00.000Z',
            file_title: 'test',
            start_date: '2022-01-05T00:00:00.000Z',
          },
          file_step: 'Incomplete',
          files: [
            {
              channelId: 5,
              channel_name: 'Digital Pull',
              custom_program: null,
              custom_program_id: null,
              error_description: '',
              file_id: 426,
              file_type: 'None',
              filename: '',
              program: 'test pull',
              s3_path: 'None',
              size: '0',
            },
          ],
          l1_status: null,
          l2_status: null,
          optionId: 1,
          sent_on: '2022-01-28T00:00:00.000Z',
          status: 'Incomplete',
          upload_file_id: 542,
        },
        {
          bussiness_justification: {},
          bussiness_justification_reason: null,
          channel_step: 'Incomplete',
          edit_count: 0,
          file_info: {
            description: null,
            end_date: '2022-01-29T00:00:00.000Z',
            file_title: 'h',
            start_date: '2021-12-28T00:00:00.000Z',
          },
          file_step: 'Incomplete',
          files: [
            {
              channelId: 2,
              channel_name: 'Personal Remote',
              custom_program: 'testing remote',
              custom_program_id: 120,
              error_description: '',
              file_id: 425,
              file_type: 'None',
              filename: '',
              program: 'testing remote',
              s3_path: 'None',
              size: '0',
            },
          ],
          l1_status: null,
          l2_status: null,
          optionId: 1,
          sent_on: '2022-01-28T00:00:00.000Z',
          status: 'Incomplete',
          upload_file_id: 541,
        },
        {
          bussiness_justification: {},
          bussiness_justification_reason: null,
          channel_step: 'Incomplete',
          edit_count: 0,
          file_info: {
            description: null,
            end_date: '2022-01-29T00:00:00.000Z',
            file_title: 'test',
            start_date: '2021-12-29T00:00:00.000Z',
          },
          file_step: 'Incomplete',
          files: [
            {
              channelId: 1,
              channel_name: 'Personal Face-to-Face',
              custom_program: null,
              custom_program_id: null,
              error_description: '',
              file_id: 424,
              file_type: 'None',
              filename: '',
              program: 'testing face',
              s3_path: 'None',
              size: '0',
            },
          ],
          l1_status: null,
          l2_status: null,
          optionId: 1,
          sent_on: '2022-01-28T00:00:00.000Z',
          status: 'Incomplete',
          upload_file_id: 540,
        },
        {
          bussiness_justification: {},
          bussiness_justification_reason: 'null',
          channel_step: 'Incomplete',
          edit_count: 0,
          file_info: {
            description: null,
            end_date: '2022-01-29T00:00:00.000Z',
            file_title: 'test 1e',
            start_date: '2022-01-26T00:00:00.000Z',
          },
          file_step: 'Incomplete',
          files: [
            {
              channelId: 8,
              channel_name: 'Offer',
              custom_program: null,
              custom_program_id: null,
              error_description: '',
              file_id: 414,
              file_type: 'None',
              filename: '',
              program: 'test 2d',
              s3_path: 'None',
              size: '0',
            },
          ],
          l1_status: null,
          l2_status: null,
          optionId: 1,
          sent_on: '2022-01-25T00:00:00.000Z',
          status: 'Incomplete',
          upload_file_id: 537,
        },
        {
          bussiness_justification: {},
          bussiness_justification_reason: null,
          channel_step: 'Incomplete',
          edit_count: 0,
          file_info: {
            description: null,
            end_date: '2022-01-01T00:00:00.000Z',
            file_title: 'test 20/1',
            start_date: '2021-12-28T00:00:00.000Z',
          },
          file_step: 'Complete',
          files: [
            {
              channelId: 8,
              channel_name: 'Offer',
              custom_program: null,
              custom_program_id: null,
              error_description: '',
              file_id: 400,
              file_type: 'web',
              filename: 'Copaytest.txt',
              program: 'Copay',
              s3_path:
                'https://aws-a0011-use1-00-d-s3b-aftm-dqm-data01.s3.amazonaws.com/Copaytest.txt',
              size: '0',
            },
          ],
          l1_status: null,
          l2_status: null,
          optionId: 1,
          sent_on: '2022-01-20T00:00:00.000Z',
          status: 'Incomplete',
          upload_file_id: 534,
        },
        {
          bussiness_justification: {},
          bussiness_justification_reason: 'null',
          channel_step: 'Incomplete',
          edit_count: 0,
          file_info: {
            description: null,
            end_date: '2022-01-24T00:00:00.000Z',
            file_title: 'test success',
            start_date: '2022-01-13T00:00:00.000Z',
          },
          file_step: 'Complete',
          files: [
            {
              channelId: 8,
              channel_name: 'Offer',
              custom_program: null,
              custom_program_id: null,
              error_description: '',
              file_id: 396,
              file_type: 'None',
              filename: '',
              program: 'test 2d',
              s3_path: 'None',
              size: '0',
            },
            {
              channelId: 8,
              channel_name: 'Offer',
              custom_program: null,
              custom_program_id: null,
              error_description: '',
              file_id: 395,
              file_type: 'web',
              filename: 'Voucherstest.csv',
              program: 'Vouchers',
              s3_path:
                'https://aws-a0011-use1-00-d-s3b-aftm-dqm-data01.s3.amazonaws.com/Voucherstest.csv',
              size: '0',
            },
          ],
          l1_status: null,
          l2_status: null,
          optionId: 1,
          sent_on: '2022-01-20T00:00:00.000Z',
          status: 'Incomplete',
          upload_file_id: 531,
        },
        {
          bussiness_justification: {},
          bussiness_justification_reason: 'null',
          channel_step: 'Incomplete',
          edit_count: 0,
          file_info: {
            description: null,
            end_date: '2022-01-29T00:00:00.000Z',
            file_title: 'a edit',
            start_date: '2021-12-30T00:00:00.000Z',
          },
          file_step: 'Complete',
          files: [
            {
              channelId: 1,
              channel_name: 'Personal Face-to-Face',
              custom_program: null,
              custom_program_id: null,
              error_description: '',
              file_id: 394,
              file_type: 'None',
              filename: '',
              program: 'Live Rep Visits \u2013 Activity (in-person, remote, etc.)',
              s3_path: 'None',
              size: '0',
            },
            {
              channelId: null,
              channel_name: null,
              custom_program: null,
              custom_program_id: null,
              error_description: '',
              file_id: 390,
              file_type: 'web',
              filename: 'blank.csv',
              program: null,
              s3_path: 'https://aws-a0011-use1-00-d-s3b-aftm-dqm-data01.s3.amazonaws.com/blank.csv',
              size: '0',
            },
          ],
          l1_status: null,
          l2_status: null,
          optionId: 1,
          sent_on: '2022-01-17T00:00:00.000Z',
          status: 'Incomplete',
          upload_file_id: 527,
        },
        {
          bussiness_justification: {},
          bussiness_justification_reason: 'null',
          channel_step: 'Incomplete',
          edit_count: 0,
          file_info: {
            description: null,
            end_date: '2022-01-27T00:00:00.000Z',
            file_title: 'testing hj',
            start_date: '2021-12-28T00:00:00.000Z',
          },
          file_step: 'Incomplete',
          files: [
            {
              channelId: 4,
              channel_name: 'Digital Push',
              custom_program: null,
              custom_program_id: null,
              error_description: '',
              file_id: 378,
              file_type: 'None',
              filename: '',
              program: 'Email (Rep-triggered, Home office or Third party)',
              s3_path: 'None',
              size: '0',
            },
          ],
          l1_status: null,
          l2_status: null,
          optionId: 1,
          sent_on: '2022-01-12T00:00:00.000Z',
          status: 'Incomplete',
          upload_file_id: 520,
        },
        {
          bussiness_justification: {},
          bussiness_justification_reason: null,
          channel_step: 'Incomplete',
          edit_count: 0,
          file_info: {
            description: null,
            end_date: '2022-01-14T00:00:00.000Z',
            file_title: 'test',
            start_date: '2021-12-29T00:00:00.000Z',
          },
          file_step: 'Incomplete',
          files: [
            {
              channelId: 4,
              channel_name: 'Digital Push',
              custom_program: null,
              custom_program_id: null,
              error_description: '',
              file_id: 377,
              file_type: 'None',
              filename: '',
              program: null,
              s3_path: 'None',
              size: '0',
            },
          ],
          l1_status: null,
          l2_status: null,
          optionId: 1,
          sent_on: '2022-01-12T00:00:00.000Z',
          status: 'Incomplete',
          upload_file_id: 519,
        },
        {
          bussiness_justification: {},
          bussiness_justification_reason: null,
          channel_step: 'Incomplete',
          edit_count: 0,
          file_info: {
            description: null,
            end_date: '2022-01-21T00:00:00.000Z',
            file_title: 'sa',
            start_date: '2022-01-01T00:00:00.000Z',
          },
          file_step: 'Complete',
          files: [
            {
              channelId: 4,
              channel_name: 'Digital Push',
              custom_program: null,
              custom_program_id: null,
              error_description: '',
              file_id: 371,
              file_type: 'web',
              filename: 'hello.txt',
              program: 'Mobile alerts',
              s3_path: 'https://aws-a0011-use1-00-d-s3b-aftm-dqm-data01.s3.amazonaws.com/hello.txt',
              size: '0',
            },
          ],
          l1_status: null,
          l2_status: null,
          optionId: 1,
          sent_on: '2022-01-12T00:00:00.000Z',
          status: 'Incomplete',
          upload_file_id: 513,
        },
        {
          bussiness_justification: {},
          bussiness_justification_reason: null,
          channel_step: 'Incomplete',
          edit_count: 0,
          file_info: {
            description: null,
            end_date: '2022-02-05T00:00:00.000Z',
            file_title: 'sa',
            start_date: '2021-12-31T00:00:00.000Z',
          },
          file_step: 'Complete',
          files: [
            {
              channelId: 8,
              channel_name: 'Offer',
              custom_program: null,
              custom_program_id: null,
              error_description: '',
              file_id: 370,
              file_type: 'web',
              filename: 'hello.txt',
              program: 'Copay',
              s3_path: 'https://aws-a0011-use1-00-d-s3b-aftm-dqm-data01.s3.amazonaws.com/hello.txt',
              size: '0',
            },
          ],
          l1_status: null,
          l2_status: null,
          optionId: 1,
          sent_on: '2022-01-12T00:00:00.000Z',
          status: 'Incomplete',
          upload_file_id: 512,
        },
        {
          bussiness_justification: 'Missing Data',
          bussiness_justification_reason: 'null',
          channel_step: 'Incomplete',
          edit_count: 2,
          file_info: {
            description: null,
            end_date: '2022-01-29T00:00:00.000Z',
            file_title: 'test',
            start_date: '2021-12-31T00:00:00.000Z',
          },
          file_step: 'Complete',
          files: [
            {
              channelId: null,
              channel_name: null,
              custom_program: null,
              custom_program_id: null,
              error_description: '',
              file_id: 362,
              file_type: 'web',
              filename: 'AM_SEM1_Client_01_data_report_v1.txt',
              program: null,
              s3_path:
                'https://aws-a0011-use1-00-d-s3b-aftm-dqm-data01.s3.amazonaws.com/AM_SEM1_Client_01_data_report_v1.txt',
              size: '0',
            },
            {
              channelId: 2,
              channel_name: 'Personal Remote',
              custom_program: null,
              custom_program_id: null,
              error_description: '',
              file_id: 363,
              file_type: 'None',
              filename: '',
              program: 'Call center',
              s3_path: 'None',
              size: '0',
            },
            {
              channelId: 1,
              channel_name: 'Personal Face-to-Face',
              custom_program: null,
              custom_program_id: null,
              error_description: '',
              file_id: 364,
              file_type: 'None',
              filename: '',
              program: 'Live Rep Visits \u2013 Activity (in-person, remote, etc.)',
              s3_path: 'None',
              size: '0',
            },
          ],
          l1_status: null,
          l2_status: null,
          optionId: 1,
          sent_on: '2022-01-12T00:00:00.000Z',
          status: 'Incomplete',
          upload_file_id: 505,
        },
        {
          bussiness_justification: 'Missing Data',
          bussiness_justification_reason: 'null',
          channel_step: 'Incomplete',
          edit_count: 1,
          file_info: {
            description: null,
            end_date: '2022-01-20T00:00:00.000Z',
            file_title: 'abd',
            start_date: '2021-12-29T00:00:00.000Z',
          },
          file_step: 'Complete',
          files: [
            {
              channelId: 2,
              channel_name: 'Personal Remote',
              custom_program: null,
              custom_program_id: null,
              error_description: '',
              file_id: 361,
              file_type: 'None',
              filename: '',
              program: 'Call center',
              s3_path: 'None',
              size: '0',
            },
            {
              channelId: null,
              channel_name: null,
              custom_program: null,
              custom_program_id: null,
              error_description: '',
              file_id: 360,
              file_type: 'web',
              filename: 'comma.csv',
              program: null,
              s3_path: 'https://aws-a0011-use1-00-d-s3b-aftm-dqm-data01.s3.amazonaws.com/comma.csv',
              size: '0',
            },
          ],
          l1_status: null,
          l2_status: null,
          optionId: 1,
          sent_on: '2022-01-12T00:00:00.000Z',
          status: 'Incomplete',
          upload_file_id: 504,
        },
        {
          bussiness_justification: {},
          bussiness_justification_reason: 'null',
          channel_step: 'Incomplete',
          edit_count: 3,
          file_info: {
            description: null,
            end_date: '2022-01-29T00:00:00.000Z',
            file_title: 'testing 4 edit 3',
            start_date: '2021-12-28T00:00:00.000Z',
          },
          file_step: 'Complete',
          files: [
            {
              channelId: null,
              channel_name: null,
              custom_program: null,
              custom_program_id: null,
              error_description: '',
              file_id: 358,
              file_type: 'web',
              filename: 'hello.txt',
              program: null,
              s3_path: 'https://aws-a0011-use1-00-d-s3b-aftm-dqm-data01.s3.amazonaws.com/hello.txt',
              size: '0',
            },
            {
              channelId: 2,
              channel_name: 'Personal Remote',
              custom_program: null,
              custom_program_id: null,
              error_description: '',
              file_id: 421,
              file_type: 'None',
              filename: '',
              program: 'Call center',
              s3_path: 'None',
              size: '0',
            },
            {
              channelId: 2,
              channel_name: 'Personal Remote',
              custom_program: null,
              custom_program_id: null,
              error_description: '',
              file_id: 422,
              file_type: 'None',
              filename: '',
              program: 'Call center',
              s3_path: 'None',
              size: '0',
            },
            {
              channelId: 2,
              channel_name: 'Personal Remote',
              custom_program: null,
              custom_program_id: null,
              error_description: '',
              file_id: 359,
              file_type: 'None',
              filename: '',
              program: 'Call center',
              s3_path: 'None',
              size: '0',
            },
          ],
          l1_status: null,
          l2_status: null,
          optionId: 1,
          sent_on: '2022-01-11T00:00:00.000Z',
          status: 'Incomplete',
          upload_file_id: 503,
        },
        {
          bussiness_justification: {},
          bussiness_justification_reason: 'null',
          channel_step: 'Incomplete',
          edit_count: 3,
          file_info: {
            description: null,
            end_date: '2022-01-27T00:00:00.000Z',
            file_title: 'testing u edit',
            start_date: '2021-12-29T00:00:00.000Z',
          },
          file_step: 'Complete',
          files: [
            {
              channelId: null,
              channel_name: null,
              custom_program: null,
              custom_program_id: null,
              error_description: '',
              file_id: 356,
              file_type: 'web',
              filename: 'hello.txt',
              program: null,
              s3_path: 'https://aws-a0011-use1-00-d-s3b-aftm-dqm-data01.s3.amazonaws.com/hello.txt',
              size: '0',
            },
            {
              channelId: 1,
              channel_name: 'Personal Face-to-Face',
              custom_program: null,
              custom_program_id: null,
              error_description: '',
              file_id: 423,
              file_type: 'None',
              filename: '',
              program: 'Live Rep Visits \u2013 Activity (in-person, remote, etc.)',
              s3_path: 'None',
              size: '0',
            },
            {
              channelId: 1,
              channel_name: 'Personal Face-to-Face',
              custom_program: null,
              custom_program_id: null,
              error_description: '',
              file_id: 357,
              file_type: 'None',
              filename: '',
              program: 'Live Rep Visits \u2013 Activity (in-person, remote, etc.)',
              s3_path: 'None',
              size: '0',
            },
          ],
          l1_status: null,
          l2_status: null,
          optionId: 1,
          sent_on: '2022-01-11T00:00:00.000Z',
          status: 'Incomplete',
          upload_file_id: 502,
        },
        {
          bussiness_justification: 'Missing Data',
          bussiness_justification_reason: 'null',
          channel_step: 'Incomplete',
          edit_count: 1,
          file_info: {
            description: null,
            end_date: '2022-01-29T00:00:00.000Z',
            file_title: 'test g',
            start_date: '2021-12-27T00:00:00.000Z',
          },
          file_step: 'Complete',
          files: [
            {
              channelId: null,
              channel_name: null,
              custom_program: null,
              custom_program_id: null,
              error_description: '',
              file_id: 347,
              file_type: 'None',
              filename: '',
              program: null,
              s3_path: 'None',
              size: '0',
            },
            {
              channelId: null,
              channel_name: null,
              custom_program: null,
              custom_program_id: null,
              error_description: '',
              file_id: 346,
              file_type: 'web',
              filename: 'afm.txt',
              program: null,
              s3_path: 'https://aws-a0011-use1-00-d-s3b-aftm-dqm-data01.s3.amazonaws.com/afm.txt',
              size: '0',
            },
          ],
          l1_status: null,
          l2_status: null,
          optionId: 1,
          sent_on: '2022-01-11T00:00:00.000Z',
          status: 'Incomplete',
          upload_file_id: 498,
        },
        {
          bussiness_justification: 'New Data is available',
          bussiness_justification_reason: 'null',
          channel_step: 'Incomplete',
          edit_count: 1,
          file_info: {
            description: null,
            end_date: '2021-12-27T00:00:00.000Z',
            file_title: 'ik',
            start_date: '2021-11-29T00:00:00.000Z',
          },
          file_step: 'Complete',
          files: [
            {
              channelId: 5,
              channel_name: 'Digital Pull',
              custom_program: null,
              custom_program_id: null,
              error_description: '',
              file_id: 337,
              file_type: 'None',
              filename: '',
              program: 'Online ads',
              s3_path: 'None',
              size: '0',
            },
            {
              channelId: null,
              channel_name: null,
              custom_program: null,
              custom_program_id: null,
              error_description: '',
              file_id: 336,
              file_type: 'web',
              filename: 'hello.txt',
              program: null,
              s3_path: 'https://aws-a0011-use1-00-d-s3b-aftm-dqm-data01.s3.amazonaws.com/hello.txt',
              size: '0',
            },
          ],
          l1_status: null,
          l2_status: null,
          optionId: 1,
          sent_on: '2022-01-11T00:00:00.000Z',
          status: 'Incomplete',
          upload_file_id: 492,
        },
        {
          bussiness_justification: {},
          bussiness_justification_reason: null,
          channel_step: 'Incomplete',
          edit_count: 0,
          file_info: {
            description: null,
            end_date: '2022-01-24T00:00:00.000Z',
            file_title: 'k',
            start_date: '2022-01-02T00:00:00.000Z',
          },
          file_step: 'Complete',
          files: [
            {
              channelId: 3,
              channel_name: 'Peer Interaction',
              custom_program: null,
              custom_program_id: null,
              error_description: '',
              file_id: 335,
              file_type: 'web',
              filename: 'hello.txt',
              program: 'Speaker Programs',
              s3_path: 'https://aws-a0011-use1-00-d-s3b-aftm-dqm-data01.s3.amazonaws.com/hello.txt',
              size: '0',
            },
          ],
          l1_status: null,
          l2_status: null,
          optionId: 1,
          sent_on: '2022-01-11T00:00:00.000Z',
          status: 'Incomplete',
          upload_file_id: 491,
        },
        {
          bussiness_justification: 'Revised/Updated Data',
          bussiness_justification_reason: 'null',
          channel_step: 'Incomplete',
          edit_count: 1,
          file_info: {
            description: null,
            end_date: '2022-01-28T00:00:00.000Z',
            file_title: 'sa',
            start_date: '2022-01-13T00:00:00.000Z',
          },
          file_step: 'Complete',
          files: [
            {
              channelId: 8,
              channel_name: 'Offer',
              custom_program: null,
              custom_program_id: null,
              error_description: '',
              file_id: 326,
              file_type: 'None',
              filename: '',
              program: 'Copay',
              s3_path: 'None',
              size: '0',
            },
            {
              channelId: 8,
              channel_name: 'Offer',
              custom_program: null,
              custom_program_id: null,
              error_description: '',
              file_id: 329,
              file_type: 'web',
              filename: 'hello.txt',
              program: 'Patient kit',
              s3_path: 'https://aws-a0011-use1-00-d-s3b-aftm-dqm-data01.s3.amazonaws.com/hello.txt',
              size: '0',
            },
          ],
          l1_status: null,
          l2_status: null,
          optionId: 1,
          sent_on: '2022-01-11T00:00:00.000Z',
          status: 'Incomplete',
          upload_file_id: 487,
        },
      ],
      filter_data: {
        channel: [
          'All',
          'Personal Face-to-Face',
          'Personal Remote',
          'Peer Interaction',
          'Digital Push',
          'Digital Pull',
          'Direct',
          'Customer Master',
          'Offer',
        ],
        file_type: ['All', '.csv', '.txt'],
        program: [
          'All',
          'Live Rep Visits \u2013 Activity (in-person, remote, etc.)',
          'Live Rep Visits - Call Plan or Target List',
          'Live Rep Visits - No See',
          'Rep Triggered Emails',
          'Call center',
          'Telesales',
          'Live electronic rep interactions',
          'Speaker Programs',
          'Lunch & Learns',
          'HCP Webinars',
          'Email (Rep-triggered, Home office or Third party)',
          'Mobile alerts',
          'Online ads',
          'Online resource requests (e-samples, e-vouchers, etc)',
          'Website (Microsite)',
          'Recorded video or Self-guided e-details',
          'EMR Marketing',
          'Direct Mail',
          'Customer Master',
          'Samples',
          'Copay',
          'Vouchers',
          'Patient kit',
          'test 2d',
          'test custom',
          'test diirect',
          'test 2d',
          'testing face',
          'test pull',
          'test 2d',
        ],
        sort_by: ['Latest to Oldest', 'Oldest to Latest', 'Data Ends On'],
        status: ['All', 'Incomplete', 'Verifying', 'Issues', 'Success'],
      },
      search_keyword: [
        'test with all details',
        'desc',
        'Incomplete',
        'hello.txt',
        'Online ads',
        'Digital Pull',
        'test skip with all details',
        'AM_April_Client_01_data_report_v1.txt',
        'Direct Mail',
        'Direct',
        'test without file',
        'Offer',
        'test without file  at last step',
        'Live Rep Visits \u2013 Activity (in-person, remote, etc.)',
        'Personal Face-to-Face',
        'test without program',
        'test wo program skip last step',
        'test wo file and program',
        'test wo file and program skip last step',
        'Multiple files w all details',
        'blank.csv',
        'Multi files w all details skip last step',
        'comma.csv',
        'Lunch & Learns',
        'Peer Interaction',
        'multi files wo prog',
        'multi files wo prog skip last step',
        'no files in multi',
        'no files in multi skip at last step',
        'Live Rep Visits - No See',
        'no data in multi',
        'no data in multi skip last',
        'multi multi all data',
        'AM_SEM1_Client_01_data_report_v1.txt',
        'Digital Push',
        'Multi multi all data skip last',
        'multi multi no prog',
        'afm.txt',
        'multi multi no prog skip at last',
        'w file skip after selecting prog',
        'w file skip after select prog and at last',
        'tesr',
        'w file skip after prog and last',
        'wo file skip after prog',
        'wo file skip after prog and last',
        'test multi multi skip one prog',
        'Telesales',
        'Personal Remote',
        'test',
        'Call center',
        'test wo file',
        'Live Rep Visits - Call Plan or Target List',
        'tewst wo file',
        'test wo f',
        'test 2',
        'test 3',
        's',
        't',
        'z',
        'Customer Master',
        'zz',
        'Mobile alerts',
        'f',
        'sa',
        'Copay',
        'xz',
        'Vouchers',
        'Patient kit',
        'd',
        'k',
        'Speaker Programs',
        'editTest',
        'testing u edit',
        'a',
        'test 2d',
        'ik',
        'yta',
        'ja',
        'kj',
        'test g',
        'gthkjhhgg',
        'New test Set',
        'Test new set ',
        'testcustom.csv',
        'abd',
        'New Test',
        'CSV TESt.csv',
        'r',
        'da',
        'Samples',
        'Rep Triggered Emails',
        'x',
        'q',
        'testing new',
        'TestLongerFileNameForAffinityMonitorFeaturesForTestingPurposeOnly......................................................................................................csv',
        'Blank - Copy.csv',
        'OutgoingACM_Output_Client_1_John_tgt_lst_1630650955.txt',
        'testingReview.csv',
        'Live electronic rep interactions',
        'Email (Rep-triggered, Home office or Third party)',
        '@@TestFile123@@',
        'Test File Upload',
        'Verifying',
        '900MB Test.txt',
        'Client_DRT_DM_F2 - Copy.csv',
        'Online resource requests (e-samples, e-vouchers, etc)',
        'adasd',
        'testing hj',
        'OutgoingACM_Output_Client_1_John_tgt_lst_1630649748.txt',
        'testing_%%New%%',
        'Testing_AF-636',
        'Blank.csv',
        'testing face',
        'gg',
        'test diirect',
        'asd',
        'test success',
        'TestReview',
        'Test',
        'Voucherstest.csv',
        'TestLongerFileNameForAffinityMonitorFeaturesForTestingPurposeOnly..................................................................................................... - Copy.csv',
        '&&Test$$file',
        'Test file.csv',
        'test 20/1',
        'Copaytest.txt',
        'testing 4 edit 3',
        'h',
        'test 1 edit',
        '&&Test_Edit_File&&',
        'Test file .txt',
        '900MB Test.csv',
        '@@New Data Testing@@',
        'New File Test',
        'test<1 GB',
        'Option2Test1',
        'New File.txt',
        'test 2 edit',
        'AM_SEM1_Client_01_data_report_v1 (1).txt',
        'a edit',
        'Option1Test1',
        'Edit_1test',
        '!!!Bug Test1!!!',
        'Bug Testing, Do not Delete',
        'Success',
        'New Text file.txt',
        'File',
        'test pull',
        'tests',
        '&&Test&&123&&',
        'Test1GB.txt',
        'test 2 option',
        'test option 2 skip 2 step edit',
        '!!!Bug Test!!!',
        'Do Not Delete',
        'Hello',
        'test 1e',
        'asdfg',
      ],
      status: 'SUCCESS',
      display_customer_msg: true,
      upload_window: {
        current_status: 'Open',
        next_cycle: 'NA',
        window_closes: '2023-08-15T00:00:00.000Z',
        current_cycle: 'Cycle 4',
        days_left: 17,
        hours_left: 14,
        minutes_left: 12
      },
      total_records: 72,
    };

    const delimiter_checks = {
      data: [
        {
          isValid: false,
          file_name: 'afm.txt',
        },
        {
          isValid: true,
          file_name: 'xyz.txt',
        },
      ],
    };

    const delete_upload_data = {
      status: 'Success',
    };

    const get_faqs_data = {
      data: {
        ACM: [
          {
            Answer:
              'ZS AccessMonitor™️ is syndicated ZS data offering that provides access information for interactions between sales representatives and HCPs. The individual HCP metrics are generated from real world promotional activity data provided by participating companies across the healthcare industry and serves as resource to help participating healthcare companies manage their salesforce.',
            Question: 'What is AccessMonitor™️?',
          },
          {
            Answer:
              'AccessMonitor™️ provides in-depth access data on the rep channel – this includes both in-person and remote details.',
            Question: 'What are the channels of data that AccessMonitor™️ deals with?',
          },
          {
            Answer:
              'The primary datasets required are:\n•\tRep call activity (transaction level)\n•\tCall plan and/or target list\n•\tCustomer Master or HCP Demographic data (with HCP ME and/or NPI details)\n•\tField indicated no-see list\n•\tRep email data\n•\tRep sampling data\nPlease refer to user guide Section _ for more details or the templates available in the “Upload Data” tab under “Download Templates” section for the data expected to be present in the files',
            Question: 'Which data feeds are taken as input by AccessMonitor™️?',
          },
          {
            Answer:
              'ZS AccessMonitor™️ deliverables consists of two reports: \n1.\tHCP data report – a text file containing retrospective HCP accessibility metrics such as the AM No-see rating, call frequency percentiles (overall and channel-wise), industry attainment, and such\n2.\tSummary report – summary views or dashboard that shows standard industry benchmarks and trends, as well as contributor specific data summaries ',
            Question: 'What is the data that will be available in the output reports?',
          },
          {
            Answer:
              'ZS AccessMonitor™️ provides a retrospective data driven measure of how healthcare manufacturers’ salesforces can access HCPs. The individual HCP access measure and aggregated benchmarks can be utilized to adjust and optimize management and deployment of the salesforce by providing a measure of feasible reach and frequency and thus avoid otherwise wasted rep efforts.',
            Question: 'How is AccessMonitor™️ going to add value to a Client/Company?',
          },
          {
            Answer:
              'The time period varies according to the process that the input dataset is meant for – the semesterly datasets should be for the prior 6 months (H1 or H2 of the year), while the monthly datasets are expected to be for the prior month.',
            Question: 'How much data is needed to run AccessMonitor™️ algorithms?',
          },
          {
            Answer:
              'The standard ZS AccessMonitor™️ semesterly HCP reports will be available approximately three months after input data collection and the semesterly summary report will be available an additional month after that. For AccessMonitor™️ monthly reports, the HCP report and the monthly summary report for a specific month is available at the end of the next month. Please refer to the “Download Reports” tab to see the exact availability dates for each report.',
            Question:
              'What is the expected date of availability for AccessMonitor™️ output reports?',
          },
          {
            Answer:
              'ZS AccessMonitor™️ subscription has two components – 1) the subscription cost and 2) the data that participating companies are required to contribute as an input for metric calculation.\nTo know more about new subscriptions, renewal of existing subscriptions, or in case of any subscription related queries, please drop an email to -Team AccessMonitor India <accessmonitor_india@zs.com>',
            Question: 'How can a Client/Company subscribe?',
          },
          {
            Answer:
              'ZS AccessMonitor™️ subscription costs vary by the size of the salesforce, relevant specialty groups as well as the number of cycles being subscribed to (the default standard is two cycles) in case of semesterly reports and/or the number of months being subscribed to in case of monthly reports.  For specific pricing, and other related queries, please drop an email to -Team AccessMonitor India <accessmonitor_india@zs.com>',
            Question: 'What is the cost of a Subscription?',
          },
          {
            Answer:
              'AccessMonitor™️ provides in-depth insights into the accessibility of the rep channel, while AffinityMonitor® provides information on HCP engagement across a breadth of both personal and non-personal promotion channels.\nAccessMonitor™️ semesterly reports have a 6-month look back and monthly reports have a 1-month look back, while AffinityMonitor® semesterly reports have a 12-month look back. ',
            Question: 'How is AccessMonitor™️ different from AffinityMonitor®?',
          },
        ],
        AFM: [
          {
            Answer:
              'ZS AffinityMonitor®️ is a syndicated data offering that provides information on HCP engagement across marketing and sales channels. The individual HCP metrics are generated from real world promotional activity data provided by participating companies across the healthcare industry.  AffinityMonitor® serves as a resource to optimize customer experience by showing which channels are feasible for individual HCPs and thus their engagement.',
            Question: 'What is ZS AffinityMonitor®️?',
          },
          {
            Answer:
              'ZS Affinity Monitor®️ covers both marketing and sales channels: \n•\tPersonal Face-to-face (sales reps)\n•\tPersonal Remote (remote details, tele-sales, call center)\n•\tPeer interaction (Speaker Programs/Lunch & Learns -Live and HCP Teleconference/Webinars -Remote).\n•\tDigital Push (email and mobile alerts)\n•\tDigital Pull (web or mobile microsite, recorded video, self-guided e-details, Online Advertisements (where HCP trackable engagement is available), Online resource requests (e-samples, e-vouchers, etc.)\n•\tDirect (Mail and Fax)',
            Question: 'What are the channels of data the AffinityMonitor®️ deals with?',
          },
          {
            Answer:
              'As input, ZS AffinityMonitor®️ requires transactional (time stamp, HCP and activity level data) data across various HCP engagement channels. Please refer to user guide Section _ for more details or the templates available in the “Upload Data” tab within “Download Templates” section for the data expected to be present in the files.',
            Question: 'Which data feeds are taken as input by AffinityMonitor®️?',
          },
          {
            Answer:
              'The ZS AffinityMonitor®️ output data will have two files: First, an HCP-level report which contains aggregated, cross-industry metrics on an individual HCP’s channel affinity and related metrics like channel-wise engagement volume and engagement quintile. Second, a Summary report consisting of cross industry, cross channel customer views.',
            Question: 'What is the data that will be available in the output reports?',
          },
          {
            Answer:
              'ZS AffinityMonitor® provides a unique view of how HCPs engage across sales & marketing channels. It helps in understanding physicians’ channel preferences in evolving pharma landscape environment that can multiply the commercial impact and provides the ability to determine promotional mix at an individual HCP level, enabling proper allocation of channel investment across brands or therapeutic areas with granular, real world engagement data.',
            Question: 'How is ZS AffinityMonitor®️ going to add value to a Client/Company?',
          },
          {
            Answer:
              'Data required would be the latest activity for recent 12/14 months for all channels/programs, and the HCP demographics data (ME number, NPI, specialty, zip, and such) covering the corresponding period.',
            Question: 'How much data is needed to run AffinityMonitor®️ algorithms?',
          },
          {
            Answer:
              'The ZS AffinityMonitor®️ HCP report deliverable will be available about four to five months after data delivery and for ZS AffinityMonitor®️ summary report will be available about five to six months after data delivery. Please refer to the “Download Reports” tab to see the exact availability dates for each report.',
            Question:
              'What is the expected date of availability for AffinityMonitor®️ output reports?',
          },
          {
            Answer:
              'ZS AffinityMonitor® subscription is a two-way process where client/company needs to provide latest activity/customer master data as an input and can expect standard AffinityMonitor® deliverables as an output. To know more about new subscriptions, renewal of existing subscriptions, or in case of any subscription related queries, please drop an email to -Team AffinityMonitor India <AffinityMonitorIndia@zs.com>',
            Question: 'How can a Client/Company subscribe?',
          },
          {
            Answer:
              'ZS AffinityMonitor® subscription cost varies based on customer groups/specialties the client/company subscribes is interested in, as well as the data periods that the subscription is to be valid for. For specific pricing, and other related queries, please drop an email to -Team AffinityMonitor India <AffinityMonitorIndia@zs.com>',
            Question: 'What is the cost of a Subscription?',
          },
          {
            Answer:
              'AffinityMonitor® provides breadth in HCP engagement of promotional channels, while AccessMonitor™ provides depth in salesforce representative access metrics.',
            Question: 'How is AffinityMonitor®️ different from other AccessMonitor™?',
          },
        ],
      },
    };

    const get_subscription_data = {
      data: {
        affinity_monitor: {
          header: {
            current_status: 'Subscribed',
            end_date: 'Fri, 31 Dec 2021 00:00:00 GMT',
            specialties_included: [
              'A',
              'CRD',
              'DRM',
              'END',
              'GE',
              'NEP',
              'NEU',
              'OBG',
              'ONC',
              'OPT',
              'PCP',
              'PED',
              'PUL',
              'PSY',
              'RHU',
              'URO',
              'VIR',
              'Other',
              'A',
              'CRD',
              'DRM',
              'END',
              'GE',
              'NEP',
              'NEU',
              'OBG',
              'ONC',
              'OPT',
              'PCP',
              'PED',
              'PUL',
              'PSY',
              'RHU',
              'URO',
              'VIR',
              'Other',
            ],
          },
          body_content: [
            {
              cycle: 'S2 2021',
              specialties: ['A', ' CRD', ' OBG', ' PED', ' Others'],
              status: 'Subscribed',
              type: 'Monthly',
            },
            {
              cycle: 'S1 2021',
              specialties: ['A', ' CRD', ' OBG', ' Others'],
              status: 'Subscribed',
              type: 'Monthly',
            },
            {
              cycle: 'S2 2020',
              specialties: ['A', ' CRD', ' OBG', ' PED', ' Others'],
              status: 'Subscribed',
              type: 'Quarterly',
            },
            {
              cycle: 'S1 2020',
              specialties: ['A', ' CRD', ' PED', ' Others'],
              status: 'Subscribed',
              type: 'Quarterly',
            },
            { cycle: 'S1 2019', specialties: [], status: 'Not Subscribed' },
            { cycle: 'S2 2018', specialties: [], status: 'Not Subscribed' },
            {
              cycle: 'S1 2018',
              specialties: ['A', ' CRD', ' PED', ' Others'],
              status: 'Data Only',
              type: 'Quarterly',
            },
          ],
        },
        access_monitor: {
          header: {
            current_status: 'Data Only',
            end_date: 'Fri, 31 Dec 2021 00:00:00 GMT',
            specialties_included: [
              'A',
              'CRD',
              'DRM',
              'END',
              'GE',
              'NEP',
              'NEU',
              'OBG',
              'ONC',
              'OPT',
              'PCP',
              'PED',
              'PUL',
              'PSY',
              'RHU',
              'URO',
              'VIR',
              'Other',
              'A',
              'CRD',
              'DRM',
              'END',
              'GE',
              'NEP',
              'NEU',
              'OBG',
              'ONC',
              'OPT',
              'PCP',
              'PED',
              'PUL',
              'PSY',
              'RHU',
              'URO',
              'VIR',
              'Other',
            ],
          },
          body_content: [
            {
              cycle: 'S2 2021',
              specialties: ['A', ' CRD', ' OBG', ' PED', ' Others'],
              status: 'Subscribed',
              type: 'Monthly',
            },
            {
              cycle: 'S1 2021',
              specialties: ['A', ' CRD', ' OBG', ' Others'],
              status: 'Subscribed',
              type: 'Monthly',
            },
            {
              cycle: 'S2 2020',
              specialties: ['A', ' CRD', ' OBG', ' PED', ' Others'],
              status: 'Subscribed',
              type: 'Quarterly',
            },
            {
              cycle: 'S1 2020',
              specialties: ['A', ' CRD', ' PED', ' Others'],
              status: 'Subscribed',
              type: 'Quarterly',
            },
            {
              cycle: 'S2 2019',
              specialties: ['A', ' CRD', ' PED', ' Others'],
              status: 'Data Only',
              type: 'Quarterly',
            },
            { cycle: 'S1 2019', specialties: [], status: 'Not Subscribed', type: 'Quarterly' },
            { cycle: 'S2 2018', specialties: [], status: 'Not Subscribed', type: 'Quarterly' },
            {
              cycle: 'S1 2018',
              specialties: ['A', ' CRD', ' PED', ' Others'],
              status: 'Data Only',
              type: 'Quarterly',
            },
          ],
        },
        specialties: [
          'A',
          'CRD',
          'PED',
          'Others',
          'Allergy',
          'Anesthesiology',
          'Cardiology',
          'Dermatology',
          'Emergency Medicine',
          'Endocrinology',
          'Gastroenterology',
          'Geriatrics',
          'Infectious Diseases',
          'Nephrology',
          'Neurology',
          'OBG/GYN',
          'Oncology',
          'Ophthalmology/Optometry',
          'Orthopedics',
          'Other',
          'Otolaryngology',
          'Pathology',
          'Pediatrics',
          'Physical Medicine & Rehab',
          'Preventive Medicine',
          'Primary Care',
          'Psychiatry',
          'Pulmonary',
          'Radiology',
          'Rheumatology',
          'Surgery',
          'Urology',
          'Virology',
          'NP_PA',
        ],
      },
      status: 'SUCCESS',
    };

    const submit_affex_data = {
      affex_id: 1,
      message: 'AFFEX data submitted successfully',
      status: 'SUCCESS',
      specialties: ['abc', 'gfh', 'qcd'],
    };

    const affex_status = {
      data: {
        summary_stats: { activity_per: '87%', hcp_count: 87, total_npi: 99 },
        table_data: {
          tbody: [
            { count_with_no_see_rating: 87, rated_hcps: '87%' },
            { count_with_annual_call_freq_perc_75: 76, rated_hcps: '76%' },
            { count_with_industry_attainment: 64, rated_hcps: '64%' },
          ],
          thead: ['hcp_with_metrics', 'rated_hcps'],
        },
      },
      status: 'SUCCESS',
      url:
        'https://api.aftm.dev.zsservices.com/download_outgoing_file?file_name=Default/Outgoing/ACM_Output_Client_1_dm27498_tgt_lst_1671450422.txt',
      zs_user: true,
    };

    const parameter_checks = {
      data: [
        {
          filename: 'rename_file_People_data - Copy.csv',
          exists: true,
          size: 12727.229904174805,
          path:
            's3://aws-a0011-use1-00-d-s3b-aftm-dqm-data01/approved/AM_SEM1_Client_01_data_report_v1.txt',
        },
        {
          filename: 'rename_file_People_data - Copy.txt',
          exists: true,
          size: 12727.229904174805,
          path:
            's3://aws-a0011-use1-00-d-s3b-aftm-dqm-data01/approved/AM_SEM1_Client_01_data_report_v1.txt',
          error:
            'The file type cannot be accepted. Please ensure the file is in .csv or .txt extension format and in line with template available for reference.',
        },
      ],
    };

    const get_custom_programs = {
      data: [
        {
          id: 1,
          email: 'johnsmith@email.com',
          user: 'John Smith',
          client: 'one',
          program_name: 'Flyers',
          channel: 'Personal Face-To-Face',
          date: '2022-03-05T00:00:00.000Z',
          status: 'Pending',
        },
        {
          id: 2,
          email: 'johnsmith@email.com',
          user: 'John Smith',
          client: 'two',
          program_name: 'Flyers',
          channel: 'Personal Remote',
          date: '2022-03-05T00:00:00.000Z',
          status: 'Approved',
        },
        {
          id: 3,
          email: 'johnsmith@email.com',
          user: 'John Smith',
          client: 'Client Name',
          program_name: 'Flyers',
          channel: 'Personal Face-To-Face',
          date: '2022-03-05T00:00:00.000Z',
          status: 'Rejected',
        },
        {
          id: 4,
          user: 'John Smith',
          email: 'johnsmith@email.com',
          client: 'Client Name',
          program_name: 'Flyers',
          channel: 'Personal Face-To-Face',
          date: '2022-03-05T00:00:00.000Z',
          status: 'Modified & Approved',
        },
        {
          id: 5,
          email: 'johnsmith@email.com',
          user: 'John Smith',
          client: 'Client Name',
          program_name: 'Flyers',
          channel: 'Personal Face-To-Face',
          date: '2022-03-05T00:00:00.000Z',
          status: 'Pending',
        },
        {
          id: 5,
          email: 'johnsmith@email.com',
          user: 'John Smith',
          client: 'Client Name',
          program_name: 'Flyers',
          channel: 'Personal Face-To-Face',
          date: '2022-03-05T00:00:00.000Z',
          status: 'Pending',
        },
        {
          id: 5,
          email: 'johnsmith@email.com',
          user: 'John Smith',
          client: 'Client Name',
          program_name: 'Flyers',
          channel: 'Personal Face-To-Face',
          date: '2022-03-05T00:00:00.000Z',
          status: 'Pending',
        },
        {
          id: 5,
          email: 'johnsmith@email.com',
          user: 'John Smith',
          client: 'Client Name',
          program_name: 'Flyers',
          channel: 'Personal Face-To-Face',
          date: '2022-03-05T00:00:00.000Z',
          status: 'Pending',
        },
        {
          id: 5,
          email: 'johnsmith@email.com',
          user: 'John Smith',
          client: 'Client Name',
          program_name: 'Flyers',
          channel: 'Personal Face-To-Face',
          date: 'Thu, 9 Sep 2021 00:00:00 GMT',
          status: 'Pending',
        },
        {
          id: 5,
          email: 'johnsmith@email.com',
          user: 'John Smith',
          client: 'Client Name',
          program_name: 'Flyers',
          channel: 'Personal Face-To-Face',
          date: 'Thu, 9 Sep 2021 00:00:00 GMT',
          status: 'Pending',
        },
        {
          id: 5,
          email: 'johnsmith@email.com',
          user: 'John Smith',
          client: 'Client Name',
          program_name: 'Flyers',
          channel: 'Personal Face-To-Face',
          date: 'Thu, 9 Sep 2021 00:00:00 GMT',
          status: 'Pending',
        },
        {
          id: 5,
          email: 'johnsmith@email.com',
          user: 'John Smith',
          client: 'Client Name',
          program_name: 'Flyers',
          channel: 'Personal Face-To-Face',
          date: 'Thu, 9 Sep 2021 00:00:00 GMT',
          status: 'Pending',
        },
        {
          id: 5,
          email: 'johnsmith@email.com',
          user: 'John Smith',
          client: 'Client Name',
          program_name: 'Flyers',
          channel: 'Personal Face-To-Face',
          date: 'Thu, 9 Sep 2021 00:00:00 GMT',
          status: 'Pending',
        },
        {
          id: 5,
          email: 'johnsmith@email.com',
          user: 'John Smith',
          client: 'Client Name',
          program_name: 'Flyers',
          channel: 'Personal Face-To-Face',
          date: 'Thu, 9 Sep 2021 00:00:00 GMT',
          status: 'Pending',
        },
        {
          id: 5,
          email: 'johnsmith@email.com',
          user: 'John Smith',
          client: 'Client Name',
          program_name: 'Flyers',
          channel: 'Personal Face-To-Face',
          date: 'Thu, 9 Sep 2021 00:00:00 GMT',
          status: 'Pending',
        },
        {
          id: 5,
          email: 'johnsmith@email.com',
          user: 'John Smith',
          client: 'Client Name',
          program_name: 'Flyers',
          channel: 'Personal Face-To-Face',
          date: 'Thu, 6 Sep 2021 00:00:00 GMT',
          status: 'Pending',
        },
        {
          id: 5,
          email: 'johnsmith@email.com',
          user: 'John Smith',
          client: 'Client Name',
          program_name: 'Flyers',
          channel: 'Personal Face-To-Face',
          date: 'Thu, 5 Sep 2021 00:00:00 GMT',
          status: 'Pending',
        },
        {
          id: 5,
          email: 'johnsmith@email.com',
          user: 'John Smith',
          client: 'Client Name',
          program_name: 'Flyers',
          channel: 'Personal Face-To-Face',
          date: 'Thu, 5 Sep 2021 00:00:00 GMT',
          status: 'Pending',
        },
        {
          id: 5,
          email: 'johnsmith@email.com',
          user: 'John Smith',
          client: 'Client Name',
          program_name: 'Flyers',
          channel: 'Personal Face-To-Face',
          date: 'Thu, 5 Sep 2021 00:00:00 GMT',
          status: 'Pending',
        },
        {
          id: 5,
          email: 'johnsmith@email.com',
          user: 'John Smith',
          client: 'Client Name',
          program_name: 'Flyers',
          channel: 'Personal Face-To-Face',
          date: 'Thu, 5 Sep 2021 00:00:00 GMT',
          status: 'Pending',
        },
        {
          id: 5,
          email: 'johnsmith@email.com',
          user: 'John Smith',
          client: 'Client Name',
          program_name: 'Flyers',
          channel: 'Personal Face-To-Face',
          date: 'Thu, 5 Sep 2021 00:00:00 GMT',
          status: 'Pending',
        },
        {
          id: 5,
          email: 'johnsmith@email.com',
          user: 'John Smith',
          client: 'Client Name',
          program_name: 'Flyers',
          channel: 'Personal Face-To-Face',
          date: 'Thu, 5 Sep 2021 00:00:00 GMT',
          status: 'Pending',
        },
        {
          id: 5,
          email: 'johnsmith@email.com',
          user: 'John Smith',
          client: 'Client Name',
          program_name: 'Flyers',
          channel: 'Personal Face-To-Face',
          date: 'Thu, 5 Sep 2021 00:00:00 GMT',
          status: 'Pending',
        },
        {
          id: 5,
          email: 'johnsmith@email.com',
          user: 'John Smith',
          client: 'Client Name',
          program_name: 'Flyers',
          channel: 'Personal Face-To-Face',
          date: 'Thu, 5 Sep 2021 00:00:00 GMT',
          status: 'Pending',
        },
        {
          id: 5,
          email: 'johnsmith@email.com',
          user: 'John Smith',
          client: 'Client Name',
          program_name: 'Flyers',
          channel: 'Personal Face-To-Face',
          date: 'Thu, 5 Sep 2021 00:00:00 GMT',
          status: 'Pending',
        },
        {
          id: 5,
          email: 'johnsmith@email.com',
          user: 'John Smith',
          client: 'Client Name',
          program_name: 'Flyers',
          channel: 'Personal Face-To-Face',
          date: 'Thu, 5 Sep 2021 00:00:00 GMT',
          status: 'Pending',
        },
        {
          id: 5,
          email: 'johnsmith@email.com',
          user: 'John Smith',
          client: 'Client Name',
          program_name: 'Flyers',
          channel: 'Personal Face-To-Face',
          date: 'Thu, 5 Sep 2021 00:00:00 GMT',
          status: 'Pending',
        },
        {
          id: 5,
          email: 'johnsmith@email.com',
          user: 'John Smith',
          client: 'Client Name',
          program_name: 'Flyers',
          channel: 'Personal Face-To-Face',
          date: 'Thu, 5 Sep 2021 00:00:00 GMT',
          status: 'Pending',
        },
        {
          id: 5,
          email: 'johnsmith@email.com',
          user: 'John Smith',
          client: 'Client Name',
          program_name: 'Flyers',
          channel: 'Personal Face-To-Face',
          date: 'Thu, 5 Sep 2021 00:00:00 GMT',
          status: 'Pending',
        },
        {
          id: 5,
          email: 'johnsmith@email.com',
          user: 'John Smith',
          client: 'Client Name',
          program_name: 'Flyers',
          channel: 'Personal Face-To-Face',
          date: 'Thu, 5 Sep 2021 00:00:00 GMT',
          status: 'Pending',
        },
        {
          id: 5,
          email: 'johnsmith@email.com',
          user: 'John Smith',
          client: 'Client Name',
          program_name: 'Flyers',
          channel: 'Personal Face-To-Face',
          date: 'Thu, 5 Sep 2021 00:00:00 GMT',
          status: 'Pending',
        },
        {
          id: 5,
          email: 'johnsmith@email.com',
          user: 'John Smith',
          client: 'Client Name',
          program_name: 'Flyers',
          channel: 'Personal Face-To-Face',
          date: 'Thu, 5 Sep 2021 00:00:00 GMT',
          status: 'Pending',
        },
        {
          id: 5,
          email: 'johnsmith@email.com',
          user: 'John Smith',
          client: 'Client Name',
          program_name: 'Flyers',
          channel: 'Personal Face-To-Face',
          date: 'Thu, 5 Sep 2021 00:00:00 GMT',
          status: 'Pending',
        },
        {
          id: 5,
          email: 'johnsmith@email.com',
          user: 'John Smith',
          client: 'Client Name',
          program_name: 'Flyers',
          channel: 'Personal Face-To-Face',
          date: 'Thu, 5 Sep 2021 00:00:00 GMT',
          status: 'Pending',
        },
        {
          id: 5,
          email: 'johnsmith@email.com',
          user: 'John Smith',
          client: 'Client Name',
          program_name: 'Flyers',
          channel: 'Personal Face-To-Face',
          date: 'Thu, 5 Sep 2021 00:00:00 GMT',
          status: 'Pending',
        },
        {
          id: 5,
          email: 'johnsmith@email.com',
          user: 'John Smith',
          client: 'Client Name',
          program_name: 'Flyers',
          channel: 'Personal Face-To-Face',
          date: 'Thu, 5 Sep 2021 00:00:00 GMT',
          status: 'Pending',
        },
        {
          id: 5,
          email: 'johnsmith@email.com',
          user: 'John Smith',
          client: 'Client Name',
          program_name: 'Flyers',
          channel: 'Personal Face-To-Face',
          date: 'Thu, 5 Sep 2021 00:00:00 GMT',
          status: 'Pending',
        },
        {
          id: 5,
          email: 'johnsmith@email.com',
          user: 'John Smith',
          client: 'Client Name',
          program_name: 'Flyers',
          channel: 'Personal Face-To-Face',
          date: 'Thu, 5 Sep 2021 00:00:00 GMT',
          status: 'Pending',
        },
        {
          id: 5,
          email: 'johnsmith@email.com',
          user: 'John Smith',
          client: 'Client Name',
          program_name: 'Flyers',
          channel: 'Personal Face-To-Face',
          date: 'Thu, 5 Sep 2021 00:00:00 GMT',
          status: 'Pending',
        },
        {
          id: 5,
          email: 'johnsmith@email.com',
          user: 'John Smith',
          client: 'Client Name',
          program_name: 'Flyers',
          channel: 'Personal Face-To-Face',
          date: 'Thu, 5 Sep 2021 00:00:00 GMT',
          status: 'Pending',
        },
        {
          id: 5,
          email: 'johnsmith@email.com',
          user: 'John Smith',
          client: 'Client Name',
          program_name: 'Flyers',
          channel: 'Personal Face-To-Face',
          date: 'Thu, 5 Sep 2021 00:00:00 GMT',
          status: 'Pending',
        },
        {
          id: 5,
          email: 'johnsmith@email.com',
          user: 'John Smith',
          client: 'Client Name',
          program_name: 'Flyers',
          channel: 'Personal Face-To-Face',
          date: 'Thu, 5 Sep 2021 00:00:00 GMT',
          status: 'Pending',
        },
        {
          id: 5,
          email: 'johnsmith@email.com',
          user: 'John Smith',
          client: 'Client Name',
          program_name: 'Flyers',
          channel: 'Personal Face-To-Face',
          date: 'Thu, 5 Sep 2021 00:00:00 GMT',
          status: 'Pending',
        },
        {
          id: 5,
          email: 'johnsmith@email.com',
          user: 'John Smith',
          client: 'Client Name',
          program_name: 'Flyers',
          channel: 'Personal Face-To-Face',
          date: 'Thu, 5 Sep 2021 00:00:00 GMT',
          status: 'Pending',
        },
        {
          id: 5,
          email: 'johnsmith@email.com',
          user: 'John Smith',
          client: 'Client Name',
          program_name: 'Flyers',
          channel: 'Personal Face-To-Face',
          date: 'Thu, 5 Sep 2021 00:00:00 GMT',
          status: 'Pending',
        },
        {
          id: 5,
          email: 'johnsmith@email.com',
          user: 'John Smith',
          client: 'Client Name',
          program_name: 'Flyers',
          channel: 'Personal Face-To-Face',
          date: 'Thu, 5 Sep 2021 00:00:00 GMT',
          status: 'Pending',
        },
        {
          id: 5,
          email: 'johnsmith@email.com',
          user: 'John Smith',
          client: 'Client Name',
          program_name: 'Flyers',
          channel: 'Personal Face-To-Face',
          date: 'Thu, 5 Sep 2021 00:00:00 GMT',
          status: 'Pending',
        },
        {
          id: 5,
          email: 'johnsmith@email.com',
          user: 'John Smith',
          client: 'Client Name',
          program_name: 'Flyers',
          channel: 'Personal Face-To-Face',
          date: 'Thu, 5 Sep 2021 00:00:00 GMT',
          status: 'Pending',
        },
        {
          id: 5,
          email: 'johnsmith@email.com',
          user: 'John Smith',
          client: 'Client Name',
          program_name: 'Flyers',
          channel: 'Personal Face-To-Face',
          date: 'Thu, 5 Sep 2021 00:00:00 GMT',
          status: 'Pending',
        },
        {
          id: 5,
          email: 'johnsmith@email.com',
          user: 'John Smith',
          client: 'Client Name',
          program_name: 'Flyers',
          channel: 'Personal Face-To-Face',
          date: 'Thu, 5 Sep 2021 00:00:00 GMT',
          status: 'Pending',
        },
        {
          id: 5,
          email: 'johnsmith@email.com',
          user: 'John Smith',
          client: 'Client Name',
          program_name: 'Flyers',
          channel: 'Personal Face-To-Face',
          date: 'Thu, 5 Sep 2021 00:00:00 GMT',
          status: 'Pending',
        },
        {
          id: 5,
          email: 'johnsmith@email.com',
          user: 'John Smith',
          client: 'Client Name',
          program_name: 'Flyers',
          channel: 'Personal Face-To-Face',
          date: 'Thu, 5 Sep 2021 00:00:00 GMT',
          status: 'Pending',
        },
        {
          id: 5,
          email: 'johnsmith@email.com',
          user: 'John Smith',
          client: 'Client Name',
          program_name: 'Flyers',
          channel: 'Personal Face-To-Face',
          date: 'Thu, 5 Sep 2021 00:00:00 GMT',
          status: 'Pending',
        },
        {
          id: 5,
          email: 'johnsmith@email.com',
          user: 'John Smith',
          client: 'Client Name',
          program_name: 'Flyers',
          channel: 'Personal Face-To-Face',
          date: 'Thu, 5 Sep 2021 00:00:00 GMT',
          status: 'Pending',
        },
        {
          id: 5,
          email: 'johnsmith@email.com',
          user: 'John Smith',
          client: 'Client Name',
          program_name: 'Flyers',
          channel: 'Personal Face-To-Face',
          date: 'Thu, 5 Sep 2021 00:00:00 GMT',
          status: 'Pending',
        },
        {
          id: 5,
          email: 'johnsmith@email.com',
          user: 'John Smith',
          client: 'Client Name',
          program_name: 'Flyers',
          channel: 'Personal Face-To-Face',
          date: 'Thu, 5 Sep 2021 00:00:00 GMT',
          status: 'Pending',
        },
        {
          id: 5,
          email: 'johnsmith@email.com',
          user: 'John Smith',
          client: 'Client Name',
          program_name: 'Flyers',
          channel: 'Personal Face-To-Face',
          date: 'Thu, 5 Sep 2021 00:00:00 GMT',
          status: 'Pending',
        },
        {
          id: 5,
          email: 'johnsmith@email.com',
          user: 'John Smith',
          client: 'Client Name',
          program_name: 'Flyers',
          channel: 'Personal Face-To-Face',
          date: 'Thu, 5 Sep 2021 00:00:00 GMT',
          status: 'Pending',
        },
        {
          id: 5,
          email: 'johnsmith@email.com',
          user: 'John Smith',
          client: 'Client Name',
          program_name: 'Flyers',
          channel: 'Personal Face-To-Face',
          date: 'Thu, 5 Sep 2021 00:00:00 GMT',
          status: 'Pending',
        },
        {
          id: 5,
          email: 'johnsmith@email.com',
          user: 'John Smith',
          client: 'Client Name',
          program_name: 'Flyers',
          channel: 'Personal Face-To-Face',
          date: 'Thu, 5 Sep 2021 00:00:00 GMT',
          status: 'Pending',
        },
        {
          id: 5,
          email: 'johnsmith@email.com',
          user: 'John Smith',
          client: 'Client Name',
          program_name: 'Flyers',
          channel: 'Personal Face-To-Face',
          date: 'Thu, 5 Sep 2021 00:00:00 GMT',
          status: 'Pending',
        },
        {
          id: 5,
          email: 'johnsmith@email.com',
          user: 'John Smith',
          client: 'Client Name',
          program_name: 'Flyers',
          channel: 'Personal Face-To-Face',
          date: 'Thu, 5 Sep 2021 00:00:00 GMT',
          status: 'Pending',
        },
        {
          id: 5,
          email: 'johnsmith@email.com',
          user: 'John Smith',
          client: 'Client Name',
          program_name: 'Flyers',
          channel: 'Personal Face-To-Face',
          date: 'Thu, 5 Sep 2021 00:00:00 GMT',
          status: 'Pending',
        },
        {
          id: 5,
          email: 'johnsmith@email.com',
          user: 'John Smith',
          client: 'Client Name',
          program_name: 'Flyers',
          channel: 'Personal Face-To-Face',
          date: 'Thu, 5 Sep 2021 00:00:00 GMT',
          status: 'Pending',
        },
        {
          id: 5,
          email: 'johnsmith@email.com',
          user: 'John Smith',
          client: 'Client Name',
          program_name: 'Flyers',
          channel: 'Personal Face-To-Face',
          date: 'Thu, 5 Sep 2021 00:00:00 GMT',
          status: 'Pending',
        },
        {
          id: 5,
          email: 'johnsmith@email.com',
          user: 'John Smith',
          client: 'Client Name',
          program_name: 'Flyers',
          channel: 'Personal Face-To-Face',
          date: 'Thu, 5 Sep 2021 00:00:00 GMT',
          status: 'Pending',
        },
        {
          id: 5,
          email: 'johnsmith@email.com',
          user: 'John Smith',
          client: 'Client Name',
          program_name: 'Flyers',
          channel: 'Personal Face-To-Face',
          date: 'Thu, 5 Sep 2021 00:00:00 GMT',
          status: 'Pending',
        },
        {
          id: 5,
          email: 'johnsmith@email.com',
          user: 'John Smith',
          client: 'Client Name',
          program_name: 'Flyers',
          channel: 'Personal Face-To-Face',
          date: 'Thu, 5 Sep 2021 00:00:00 GMT',
          status: 'Pending',
        },
        {
          id: 5,
          email: 'johnsmith@email.com',
          user: 'John Smith',
          client: 'Client Name',
          program_name: 'Flyers',
          channel: 'Personal Face-To-Face',
          date: 'Thu, 5 Sep 2021 00:00:00 GMT',
          status: 'Pending',
        },
        {
          id: 5,
          email: 'johnsmith@email.com',
          user: 'John Smith',
          client: 'Client Name',
          program_name: 'Flyers',
          channel: 'Personal Face-To-Face',
          date: 'Thu, 5 Sep 2021 00:00:00 GMT',
          status: 'Pending',
        },
        {
          id: 5,
          email: 'johnsmith@email.com',
          user: 'John Smith',
          client: 'Client Name',
          program_name: 'Flyers',
          channel: 'Personal Face-To-Face',
          date: 'Thu, 5 Sep 2021 00:00:00 GMT',
          status: 'Pending',
        },
        {
          id: 5,
          email: 'johnsmith@email.com',
          user: 'John Smith',
          client: 'Client Name',
          program_name: 'Flyers',
          channel: 'Personal Face-To-Face',
          date: 'Thu, 5 Sep 2021 00:00:00 GMT',
          status: 'Pending',
        },
        {
          id: 5,
          email: 'johnsmith@email.com',
          user: 'John Smith',
          client: 'Client Name',
          program_name: 'Flyers',
          channel: 'Personal Face-To-Face',
          date: 'Thu, 5 Sep 2021 00:00:00 GMT',
          status: 'Pending',
        },
        {
          id: 5,
          email: 'johnsmith@email.com',
          user: 'John Smith',
          client: 'Client Name',
          program_name: 'Flyers',
          channel: 'Personal Face-To-Face',
          date: 'Thu, 5 Sep 2021 00:00:00 GMT',
          status: 'Pending',
        },
        {
          id: 5,
          email: 'johnsmith@email.com',
          user: 'John Smith',
          client: 'Client Name',
          program_name: 'Flyers',
          channel: 'Personal Face-To-Face',
          date: 'Thu, 5 Sep 2021 00:00:00 GMT',
          status: 'Pending',
        },
        {
          id: 5,
          email: 'johnsmith@email.com',
          user: 'John Smith',
          client: 'Client Name',
          program_name: 'Flyers',
          channel: 'Personal Face-To-Face',
          date: 'Thu, 5 Sep 2021 00:00:00 GMT',
          status: 'Pending',
        },
        {
          id: 5,
          email: 'johnsmith@email.com',
          user: 'John Smith',
          client: 'Client Name',
          program_name: 'Flyers',
          channel: 'Personal Face-To-Face',
          date: 'Thu, 5 Sep 2021 00:00:00 GMT',
          status: 'Pending',
        },
        {
          id: 5,
          email: 'johnsmith@email.com',
          user: 'John Smith',
          client: 'Client Name',
          program_name: 'Flyers',
          channel: 'Personal Face-To-Face',
          date: 'Thu, 5 Sep 2021 00:00:00 GMT',
          status: 'Pending',
        },
        {
          id: 5,
          email: 'johnsmith@email.com',
          user: 'John Smith',
          client: 'Client Name',
          program_name: 'Flyers',
          channel: 'Personal Face-To-Face',
          date: 'Thu, 5 Sep 2021 00:00:00 GMT',
          status: 'Pending',
        },
        {
          id: 5,
          email: 'johnsmith@email.com',
          user: 'John Smith',
          client: 'Client Name',
          program_name: 'Flyers',
          channel: 'Personal Face-To-Face',
          date: 'Thu, 5 Sep 2021 00:00:00 GMT',
          status: 'Pending',
        },
      ],
      status: [
        { text: 'Pending', value: false },
        { text: 'Rejected', value: false },
        { text: 'Approved', value: false },
        { text: 'Modified & Approved', value: false },
      ],
      client: [
        { text: 'one', value: false },
        { text: 'two', value: false },
        { text: 'Client Name', value: false },
      ],
      channel: [
        { text: 'Personal Face-to-Face', value: false },
        { text: 'Personal Remote', value: false },
        { text: 'Peer Interaction', value: false },
        { text: 'Digital Push', value: false },
        { text: 'Digital Pull', value: false },
        { text: 'Direct', value: false },
        { text: 'Customer Master', value: false },
        { text: 'Offer', value: false },
      ],
      search_keywords: ['abc', 'John Smith'],
      export_url: 'cc',
      pendingCount: 20,
    };

    const get_steward_data = {
      api_status: 'SUCCESS',
      data: [
        {
          id: 1,
          email: 'johnsmith@email.com',
          user: 'John Smith',
          client: 'one',
          process_id: 1,
          file_name: 'Hello.txt',
          channel: 'Personal Face-To-Face',
          upload_date: '2022-03-05T00:00:00.000Z',
          status: 'Pending',
          program: 'abc',
        },
        {
          id: 1,
          email: 'johnsmith@email.com',
          user: 'John Smith',
          client: 'one',
          process_id: 1,
          file_name: 'Hello.txt',
          channel: 'Personal Face-To-Face',
          upload_date: '2022-03-05T00:00:00.000Z',
          status: 'Approved',
          program: 'abc',
        },
        {
          id: 1,
          email: 'johnsmith@email.com',
          user: 'John Smith',
          client: 'one',
          process_id: 1,
          file_name: 'Hello.txt',
          channel: 'Personal Face-To-Face',
          upload_date: '2022-03-05T00:00:00.000Z',
          status: 'Approved',
          program: 'abc',
        },
        {
          id: 1,
          email: 'johnsmith@email.com',
          user: 'John Smith',
          client: 'one',
          process_id: 1,
          file_name: 'Hello.txt',
          channel: 'Personal Face-To-Face',
          upload_date: '2022-03-05T00:00:00.000Z',
          status: 'Approved',
          program: 'abc',
        },
        {
          id: 1,
          email: 'johnsmith@email.com',
          user: 'John Smith',
          client: 'one',
          process_id: 1,
          file_name: 'Hello.txt',
          channel: 'Personal Face-To-Face',
          upload_date: '2022-03-05T00:00:00.000Z',
          status: 'Approved',
          program: 'abc',
        },
        {
          id: 1,
          email: 'johnsmith@email.com',
          user: 'John Smith',
          client: 'one',
          process_id: 1,
          file_name: 'Hello.txt',
          channel: 'Personal Face-To-Face',
          upload_date: '2022-03-05T00:00:00.000Z',
          status: 'Modified & Approved',
          program: 'abc',
        },
        {
          id: 1,
          email: 'johnsmith@email.com',
          user: 'John Smith',
          client: 'one',
          process_id: 1,
          file_name: 'Hello.txt',
          channel: 'Personal Face-To-Face',
          upload_date: '2022-03-05T00:00:00.000Z',
          status: 'Rejected',
          program: 'abc',
        },
        {
          id: 1,
          email: 'johnsmith@email.com',
          user: 'John Smith',
          client: 'one',
          process_id: 1,
          file_name: 'Hello.txt',
          channel: 'Personal Face-To-Face',
          upload_date: '2022-03-05T00:00:00.000Z',
          status: 'Pending',
          program: 'abc',
        },
        {
          id: 1,
          email: 'johnsmith@email.com',
          user: 'John Smith',
          client: 'one',
          process_id: 1,
          file_name: 'Hello.txt',
          channel: 'Personal Face-To-Face',
          upload_date: '2022-03-05T00:00:00.000Z',
          status: 'Pending',
          program: 'abc',
        },
        {
          id: 1,
          email: 'johnsmith@email.com',
          user: 'John Smith',
          client: 'one',
          process_id: 1,
          file_name: 'Hello.txt',
          channel: 'Personal Face-To-Face',
          upload_date: '2022-03-05T00:00:00.000Z',
          status: 'Pending',
          program: 'abc',
        },
        {
          id: 1,
          email: 'johnsmith@email.com',
          user: 'John Smith',
          client: 'one',
          process_id: 1,
          file_name: 'Hello.txt',
          channel: 'Personal Face-To-Face',
          upload_date: '2022-03-05T00:00:00.000Z',
          status: 'Pending',
          program: 'abc',
        },
      ],
      status: [
        { text: 'Pending', value: false },
        { text: 'Rejected', value: false },
        { text: 'Approved', value: false },
        { text: 'Modified & Approved', value: false },
      ],
      client: [
        { text: 'one', value: false },
        { text: 'two', value: false },
        { text: 'Client Name', value: false },
      ],
      channel: [
        { text: 'Personal Face-to-Face', value: false },
        { text: 'Personal Remote', value: false },
        { text: 'Peer Interaction', value: false },
        { text: 'Digital Push', value: false },
        { text: 'Digital Pull', value: false },
        { text: 'Direct', value: false },
        { text: 'Customer Master', value: false },
        { text: 'Offer', value: false },
      ],
      program: [
        { text: 'prog 1', value: false },
        { text: 'prog 2', value: false },
        { text: 'prog 3', value: false },
        { text: 'prog 4', value: false },
        { text: 'prog 5', value: false },
        { text: 'prog 6', value: false },
        { text: 'prog 7', value: false },
        { text: 'prog 8', value: false },
      ],
      date: 'ascend',
      search_keywords: ['abc', 'def', 'defi', 'jkl', 'john smith', 'zs\\mj27136'],
      pending_afm: 3,
      pending_acm: 4,
    };

    const get_data_changes = {
      data: [
        {
          id: 1,
          email: 'johnsmith@email.com',
          user: 'John Smith',
          client: 'client_1',
          file_title:
            'Emails from Jun to Jul Emails from Jun to Jul Emails from Jun to Jul Emails from Jun to Jul Emails from Jun to Jul Emails from Jun to Jul Emails from Jun to Jul',
          uploaded_date: 'Thu, 4 Sep 2021 00:00:00 GMT',
          modified_date: 'Thu, 10 Sep 2021 00:00:00 GMT',
          status: 'Approved',
        },
        {
          id: 1,
          email: 'johnsmith@email.com',
          user: 'John Smith',
          client: 'client_2',
          file_title: 'Hello from Jun to Jul',
          uploaded_date: 'Thu, 7 Sep 2021 00:00:00 GMT',
          modified_date: 'Thu, 8 Sep 2021 00:00:00 GMT',
          status: 'Rejected',
        },
        {
          id: 1,
          email: 'johnsmith@email.com',
          user: 'John Smith',
          client: 'Client Name',
          file_title: 'Emails from Jun to Jul',
          uploaded_date: 'Thu, 6 Sep 2021 00:00:00 GMT',
          modified_date: 'Thu, 7 Sep 2021 00:00:00 GMT',
          status: 'Pending',
        },
        {
          id: 1,
          email: 'johnsmith@email.com',
          user: 'John Smith',
          client: 'Client Name',
          file_title: 'Emails from Jun to Jul',
          uploaded_date: 'Thu, 5 Sep 2021 00:00:00 GMT',
          modified_date: 'Thu, 6 Sep 2021 00:00:00 GMT',
          status: 'Pending',
        },
        {
          id: 1,
          email: 'johnsmith@email.com',
          user: 'John Smith',
          client: 'Client Name',
          file_title: 'Emails from Jun to Jul',
          uploaded_date: 'Thu, 5 Sep 2021 00:00:00 GMT',
          modified_date: 'Thu, 6 Sep 2021 00:00:00 GMT',
          status: 'Pending',
        },
        {
          id: 1,
          email: 'johnsmith@email.com',
          user: 'John Smith',
          client: 'Client Name',
          file_title: 'Emails from Jun to Jul',
          uploaded_date: 'Thu, 5 Sep 2021 00:00:00 GMT',
          modified_date: 'Thu, 6 Sep 2021 00:00:00 GMT',
          status: 'Pending',
        },
        {
          id: 1,
          email: 'johnsmith@email.com',
          user: 'John Smith',
          client: 'Client Name',
          file_title: 'Emails from Jun to Jul',
          uploaded_date: 'Thu, 5 Sep 2021 00:00:00 GMT',
          modified_date: 'Thu, 6 Sep 2021 00:00:00 GMT',
          status: 'Pending',
        },
        {
          id: 1,
          email: 'johnsmith@email.com',
          user: 'John Smith',
          client: 'Client Name',
          file_title: 'Emails from Jun to Jul',
          uploaded_date: 'Thu, 5 Sep 2021 00:00:00 GMT',
          modified_date: 'Thu, 6 Sep 2021 00:00:00 GMT',
          status: 'Pending',
        },
        {
          id: 1,
          email: 'johnsmith@email.com',
          user: 'John Smith',
          client: 'Client Name',
          file_title: 'Emails from Jun to Jul',
          uploaded_date: 'Thu, 5 Sep 2021 00:00:00 GMT',
          modified_date: 'Thu, 6 Sep 2021 00:00:00 GMT',
          status: 'Pending',
        },
        {
          id: 1,
          email: 'johnsmith@email.com',
          user: 'John Smith',
          client: 'Client Name',
          file_title: 'Emails from Jun to Jul',
          uploaded_date: 'Thu, 5 Sep 2021 00:00:00 GMT',
          modified_date: 'Thu, 6 Sep 2021 00:00:00 GMT',
          status: 'Pending',
        },
        {
          id: 1,
          email: 'johnsmith@email.com',
          user: 'John Smith',
          client: 'Client Name',
          file_title: 'Emails from Jun to Jul',
          uploaded_date: 'Thu, 5 Sep 2021 00:00:00 GMT',
          modified_date: 'Thu, 6 Sep 2021 00:00:00 GMT',
          status: 'Pending',
        },
      ],
      client: [
        { text: 'client_1', value: false },
        { text: 'client_2', value: false },
      ],
      status: [
        { text: 'Pending', value: false },
        { text: 'Rejected', value: false },
        { text: 'Approved', value: false },
      ],
    };

    const get_user_details = {
      access: ['dashboard', 'subscriptions', 'upload', 'download_reports', 'affex'],
      bucket: 'aws-a0011-use1-00-d-s3b-aftm-dqm-data01',
      client: 'Client_1',
      clientList: ['bms', 'Client_1'],
      cycle: ['S2 2021', 'S1 2021', 'S2 2020', 'S1 2020', 'S2 2019', 'S1 2018'],
      email: 'divya.mummasani@zs.com',
      newUser: false,
      role: 'admin',
      username: 'zs\\dm27498',
      zsUser: true,
    };

    const get_review_data_changes = {
      custom_programs: '',
      data: {
        businessJustification: ['Incorrect Data/Uploaded By mistake'],
        businessJustificationDescription: '',
      },
      isDeleted: true,
      status: 'SUCCESS',
    };

    const submit_data_changes_review = {
      status: 'SUCCESS',
    };

    const submit_custom_program_review = {
      status: 'ERROR',
      message: 'This program already exists under this channel.',
    };

    const import_portal_data = {
      error_url: 'https://www.google.com/',
      message: 'FAQs data is uploaded Successfully',
      status: 'SUCCESS',
    };

    const get_program_approval_details = {
      data: {
        channel: 'Digital Push',
        description: '',
        duplicate: false,
        layoutFileName: 'testingnew.csv',
        layoutFileUrl:
          'https://api.aftm.dev.zsservices.com/download_custom_program_file?file_path=custom_programs/bms/Digital Push/testingnew.csv',
        product: null,
        program: 'testingnew',
        reason: 'uploaded in error',
      },
      status: 'SUCCESS',
    };

    const get_program_review_details = {
      data: {
        channel: 'Digital Push',
        client: 'client1',
        description: '',
        duplicate: false,
        layoutFileName: 'testingnew.csv',
        layoutFileUrl:
          'https://api.aftm.dev.zsservices.com/download_custom_program_file?file_path=custom_programs/bms/Digital Push/testingnew.csv',
        program: 'testingnew',
        reason: null,
        product: 'AFM',
        programEditted: true,
        channelEditted: false,
        layoutFileEditted: true,
        previous_state: 'Active',
      },
      status: 'SUCCESS',
    };

    const get_add_program_details = {
      status: 'SUCCESS',
      channelData: [
        'Personal Face-to-Face',
        'Personal Remote',
        'Peer Interaction',
        'Digital Push',
        'Digital Pull',
        'Direct',
        'Customer Master',
        'Offer',
      ],
      clientData: ['client1', 'client2', 'client3', 'client4', 'client5', 'client6'],
      programTypeData: ['Custom', 'Standard'],
    };

    const export_custom_program_data = {
      status: 'SUCCESS',
      url: 'abcd',
    };

    const get_presigned_url = {
      fields: {
        AWSAccessKeyId: 'ASIA2U4AW7EIUAJ6ZEGY',
        key: 'test1put.csv',
        policy: '',
        signature: 'khhRYWLsdkjt/GconxJpMMSy5Hc=',
        'x-amz-security-token': '',
      },
      status: 'SUCCESS',
      duplicate: false,
      url: 'https://aws-a0011-use1-00-d-s3b-aftm-shr-data01.s3.amazonaws.com/',
      put_url:
        'https://aws-a0011-use1-00-d-s3b-aftm-dqm-data01.s3.amazonaws.com/rename_file_People_data%20-%20Copy.csv?AWSAccessKeyId=ASIA2U4AW7EI4S4BKS7G&Signature=mEa3P4YxOzbc8Fk%2BCCgKpu02lx8%3D&x-amz-security-token=IQoJb3JpZ2luX2VjEDoaCXVzLWVhc3QtMSJHMEUCIBS4ucgYcSPJVn6tvUnX758CM4ovJzVrAqXIRbvM%2FssIAiEAo1vazDK0qOkOWSRiCr7T20m7aO132yu%2Fby1fFeDsrO8qrgQIo%2F%2F%2F%2F%2F%2F%2F%2F%2F%2F%2FARAEGgw3MzIwMjQ5OTQwNjUiDEE6r58QzgY5pd6eWyqCBH18kDB0JX1cSoL0wdkddie5M3iIPObZzzME%2BvERBWEArTMKAYOxJIc8z3amvG8BgIUjt3dIWvzmowuMrDiz1bnaUSGJYmWsa8dEMivbbJhs%2FDtCsf7NyUE4mjm8qkBP0JgSj9Uztx%2B9yIQ5BlzrNScNZ7DGmJrWnhQVM1iIkb%2BSdS%2FFzPqwsI1RDMLRE%2B9Jpehu9s%2FsWxrTYcYXBWirC8DqpycYPCs8LAq8ktcGdE%2FFOVvjXf0VRT39zCLyvuzNKok%2BtS5wMUX66cVQGfO7iHh3VvkQAb%2BJUP6171DmlWzg3ECHUgiUak7LHC5aAkyy61ZA9oavdgc5wL0CYJSWuxq5RoGduH7K8ZhOwXr3wn2k4Eyz1GYEPSOGEt3tErM2D9LHP6DYQvFU1bveJh6a0hAKeuygfSIg9uwM5RrT%2FJ3Qn4e19hj77VRx%2BQ7rZIcQAo%2FhZw4FQYpWHQOKtvlm17ctjkt6BHGtm%2BVd6Ddka7WF%2BZyANoIdNJ5PKAM0EcFfWyH%2BX%2FYVbE7dE7JggZ%2BMNCX3VnyzokivypZXTG1by6hjOsBmdukb8cOQ3fO7QSPLOX7P2cGWDsBnIxWrM3U7Lo4Ei8DtLUYmXL%2B3OMhhZxukT5tz%2BmJOm0Q6y82OGbvVKsD9xppS7M4eTupJeAeeWF0MSTCk%2FLETKBOYPDHBw12ILbgw%2BJ2XkQY6pgEAW4TZF5hEWXo7RqOlbXi%2B6fx9oEOf4JLmxGz3LabhCp2RrHTIfvePZbAF9mSNVfUhaX6iXup4M3ID6i6kbvYRVoP4ACp0%2BoY2Hquc8xkwZZjJF27nR0HKCDqmREHiabnlJVptslTjum8%2FGHa0CXv1XtrbejbhkyqkY8uW8zptGwnAA88WnoF1zxlCMV3%2B3OPJxwbBHE0mztnCheh5YIEjAN8WxhNX&Expires=1646654439',
    };

    const get_file_data = {
      thead: ['field', 'fieldName', 'fieldFullName', 'description', 'type', 'values'],
      tbody: [
        {
          description: 'Unique client identifier as provided by ZS',
          field: 1,
          fieldFullName: 'Client ID',
          fieldName: 'client_id',
          type: 'Character',
          values: 'N/A',
        },
        {
          description: "Unique identifier for an HCP in the subscriber's internal systems",
          field: 2,
          fieldFullName: 'Client Physician ID',
          fieldName: 'client_physician_id',
          type: 'Character',
          values: 'N/A',
        },
        {
          description: 'HCP ME number',
          field: 3,
          fieldFullName: 'ME number',
          fieldName: 'ME_number',
          type: 'Character',
          values: 'N/A',
        },
        {
          description: 'HCP NPI',
          field: 4,
          fieldFullName: 'National Provider Identifier',
          fieldName: 'NPI',
          type: 'Character',
          values: 'N/A',
        },
        {
          description: 'A binary flag indicating if the provided HCP ME number is valid ',
          field: 5,
          fieldFullName: 'Valid ME Number Flag',
          fieldName: 'valid_menum_flag',
          type: 'Numeric',
          values: '1:valid, 0:invalid',
        },
        {
          description: 'A binary flag indicating if the provided HCP NPI is valid',
          field: 6,
          fieldFullName: 'Valid NPI Flag',
          fieldName: 'valid_npi_flag',
          type: 'Numeric',
          values: '1:valid, 0:invalid',
        },
        {
          description: 'A measure of how often an HCP will see a representative during the cycle',
          field: 7,
          fieldFullName: 'No See Rating',
          fieldName: 'am_no_see_rating',
          type: 'Numeric',
          values: '1-10',
        },
        {
          description: 'Teams counting towards No See Rating calculation',
          field: 8,
          fieldFullName: 'Team Count Range for No See Rating',
          fieldName: 'teams_no_see_rating',
          type: 'Character',
          values: '0 to 2, 3 to 4, 5 to 9, 10 or more',
        },
        {
          description:
            ' Annualized actual calls being made by representatives ranked as the 25th percentile',
          field: 9,
          fieldFullName: 'Call Frequency percentile 25th',
          fieldName: 'annual_call_freq_perc_25',
          type: 'Numeric',
          values: 'low',
        },
        {
          description:
            'Annualized actual calls being made by representatives ranked as the 50th percentile',
          field: 10,
          fieldFullName: 'Call Frequency Percentile 50th',
          fieldName: 'annual_call_freq_perc_50',
          type: 'Numeric',
          values: 'median',
        },
        {
          description:
            'Annualized actual calls being made by representatives ranked as the 75th percentile',
          field: 11,
          fieldFullName: 'Call Frequency Percentile 75th',
          fieldName: 'annual_call_freq_perc_75',
          type: 'Numeric',
          values: 'best in class',
        },
        {
          description: 'Teams counting towards Annual Call Frequency calculation',
          field: 12,
          fieldFullName: 'Team Count Range for Annual Call Frequencies',
          fieldName: 'teams_call_freq',
          type: 'Character',
          values: '0 to 2, 3 to 4, 5 to 9, 10 or more',
        },
        {
          description:
            '% of calls planned by all subscriber companies that are actually implemented',
          field: 13,
          fieldFullName: 'Industry Attainment ',
          fieldName: 'industry_attainment*',
          type: 'Numeric',
          values: 'N/A',
        },
        {
          description: 'Teams counting towards Industry Attainment calculation',
          field: 14,
          fieldFullName: 'Team Count Range for Industry Attainment',
          fieldName: 'teams_attainment*',
          type: 'Character',
          values: '0 to 2, 3 to 4, 5 to 9, 10 or more',
        },
        {
          description: 'Industry Attainment ranked as the 25th percentile',
          field: 15,
          fieldFullName: 'Attainment Percentile 25th',
          fieldName: 'attn_perc_25*',
          type: 'Numeric',
          values: 'low',
        },
        {
          description: 'Industry Attainment ranked as the 50th percentile',
          field: 16,
          fieldFullName: 'Attainment Percentile 50th',
          fieldName: 'attn_perc_50*',
          type: 'Numeric',
          values: 'median',
        },
        {
          description: 'Industry Attainment ranked as the 75th percentile',
          field: 17,
          fieldFullName: 'Attainment Percentile 75th',
          fieldName: 'attn_perc_75*',
          type: 'Numeric',
          values: 'high',
        },
        {
          description: 'Teams counting towards Attainment Percentiles calculation',
          field: 18,
          fieldFullName: 'Team Count Range for Attainment Percentiles',
          fieldName: 'teams_attn_perc*',
          type: 'Character',
          values: '0 to 2, 3 to 4, 5 to 9, 10 or more',
        },
        {
          description:
            'Specialty grouping attributed to the HCP as reported by majority of the subscriber companies',
          field: 19,
          fieldFullName: 'Speciality',
          fieldName: 'am_specialty_grouping*',
          type: 'Character',
          values: 'N/A',
        },
        {
          description: 'Teams counting towards AM Specialty Grouping calculation',
          field: 20,
          fieldFullName: 'Team Count Range for AM Specialty Grouping',
          fieldName: 'teams_spec*',
          type: 'Character',
          values: '0 to 2, 3 to 4, 5 to 9, 10 or more',
        },
        {
          description:
            'Best ZIP Code attributed to the HCP as reported by majority of the subscriber companies',
          field: 21,
          fieldFullName: 'ZIP',
          fieldName: 'am_zip*',
          type: 'Character',
          values: 'N/A',
        },
        {
          description: 'Teams counting towards AM ZIP calculation',
          field: 22,
          fieldFullName: 'Team Count Range for AM ZIP',
          fieldName: 'teams_zip*',
          type: 'Character',
          values: '0 to 2, 3 to 4, 5 to 9, 10 or more',
        },
      ],
      status: 'SUCCESS',
      message: "This file doesn't have enough data to preview",
      title:
        'Filesaaaaaaaaaaaaaaaaaaaaaaaaaaaaaaaaaaaaaaaaaaaaaaaaaaaaaaaaaaaaaaaaaaaaaaaaaaaaaaaaaaaaaaaaaaaaaaaaaaaaaaaaaaa',
    };

    const get_dashboard_url = {
      status: 'SUCCESS',
      url:
        'https://sisense.qa.zsservices.com/jwt?jwt=eyJ0eXAiOiJKV1QiLCJhbGciOiJIUzI1NiJ9.eyJzdWIiOiJtaXNoYS5qYXJzYW5peWFAenMuY29tIiwianRpIjoiQWZmaW5pdHlfMTY2MDcyMTMxOS4zNjI4NTExIiwiZXhwIjoxNjYwNzIyNTE5LCJpYXQiOjE2NjA3MjEzMTksIm5iZiI6MTY2MDcyMTMxOX0.4BHgJUCJwbEvX1mf5yBFsGkyNh_qyL7XAjwuZYo0IXQ',
      access_token: '',
      auth_token: '',
    };

    const get_dashboard_ids = {
      ids: [],
    };

    const get_pending_count = {
      customProgram: 10,
      standardTab: '11',
      customTab: '12',
      dataChanges: 15,
      stewardAfm: 22,
      stewardAcm: 24,
    };

    const get_steward_review_details = {
      program_name: 'Emails',
      channel_name: 'Digital Push',
      file_name: 'fileWork.txt',
      data_layout_url: '',
      rolled_down: false,
      process_id: '3',
      reject_reason: 'abc',
      mandatory_columns_present: false,
      classification_table: {
        thead: ['column_name', 'feedback', 'recommended_std_name', 'other_recommendation'],
        tbody: [
          {
            id: 1,
            selected: true,
            column_name: 'Transaction id',
            recommended_std_name: 'Transaction_id',
            feedback: null,
            other_recommendation: '',
          },
          {
            id: 2,
            selected: true,
            column_name: 'offer_information',
            feedback: 'abc',
            recommended_std_name: 'Program_id',
            other_recommendation: '',
          },
          {
            id: 3,
            selected: true,
            column_name: 'Event',
            feedback: 'abc',
            recommended_std_name: '',
            other_recommendation: '',
          },
          {
            id: 4,
            selected: false,
            column_name: 'Date',
            feedback: 'abc',
            recommended_std_name: 'Date',
            other_recommendation: '',
          },
          {
            id: 5,
            selected: true,
            column_name: 'Program_desc',
            feedback: 'abc',
            recommended_std_name: 'Program_description',
            other_recommendation: '',
          },
          {
            id: 6,
            selected: true,
            column_name: 'Vendor',
            feedback: 'abc',
            recommended_std_name: 'Vendor',
            other_recommendation: '',
          },
        ],
      },
    };

    const sample_data_preview = {
      thead: ['field', 'fieldName', 'fieldFullName', 'description', 'type', 'values'],
      tbody: [
        {
          description: 'Unique client identifier as provided by ZS',
          field: 1,
          fieldFullName: 'Client ID',
          fieldName: 'client_id',
          type: 'Character',
          values: 'N/A',
        },
        {
          description: "Unique identifier for an HCP in the subscriber's internal systems",
          field: 2,
          fieldFullName: 'Client Physician ID',
          fieldName: 'client_physician_id',
          type: 'Character',
          values: 'N/A',
        },
        {
          description: 'HCP ME number',
          field: 3,
          fieldFullName: 'ME number',
          fieldName: 'ME_number',
          type: 'Character',
          values: 'N/A',
        },
        {
          description: 'HCP NPI',
          field: 4,
          fieldFullName: 'National Provider Identifier',
          fieldName: 'NPI',
          type: 'Character',
          values: 'N/A',
        },
        {
          description: 'A binary flag indicating if the provided HCP ME number is valid ',
          field: 5,
          fieldFullName: 'Valid ME Number Flag',
          fieldName: 'valid_menum_flag',
          type: 'Numeric',
          values: '1:valid, 0:invalid',
        },
        {
          description: 'A binary flag indicating if the provided HCP NPI is valid',
          field: 6,
          fieldFullName: 'Valid NPI Flag',
          fieldName: 'valid_npi_flag',
          type: 'Numeric',
          values: '1:valid, 0:invalid',
        },
        {
          description: 'A measure of how often an HCP will see a representative during the cycle',
          field: 7,
          fieldFullName: 'No See Rating',
          fieldName: 'am_no_see_rating',
          type: 'Numeric',
          values: '1-10',
        },
        {
          description: 'Teams counting towards No See Rating calculation',
          field: 8,
          fieldFullName: 'Team Count Range for No See Rating',
          fieldName: 'teams_no_see_rating',
          type: 'Character',
          values: '0 to 2, 3 to 4, 5 to 9, 10 or more',
        },
        {
          description:
            ' Annualized actual calls being made by representatives ranked as the 25th percentile',
          field: 9,
          fieldFullName: 'Call Frequency percentile 25th',
          fieldName: 'annual_call_freq_perc_25',
          type: 'Numeric',
          values: 'low',
        },
        {
          description:
            'Annualized actual calls being made by representatives ranked as the 50th percentile',
          field: 10,
          fieldFullName: 'Call Frequency Percentile 50th',
          fieldName: 'annual_call_freq_perc_50',
          type: 'Numeric',
          values: 'median',
        },
        {
          description:
            'Annualized actual calls being made by representatives ranked as the 75th percentile',
          field: 11,
          fieldFullName: 'Call Frequency Percentile 75th',
          fieldName: 'annual_call_freq_perc_75',
          type: 'Numeric',
          values: 'best in class',
        },
        {
          description: 'Teams counting towards Annual Call Frequency calculation',
          field: 12,
          fieldFullName: 'Team Count Range for Annual Call Frequencies',
          fieldName: 'teams_call_freq',
          type: 'Character',
          values: '0 to 2, 3 to 4, 5 to 9, 10 or more',
        },
        {
          description:
            '% of calls planned by all subscriber companies that are actually implemented',
          field: 13,
          fieldFullName: 'Industry Attainment ',
          fieldName: 'industry_attainment*',
          type: 'Numeric',
          values: 'N/A',
        },
        {
          description: 'Teams counting towards Industry Attainment calculation',
          field: 14,
          fieldFullName: 'Team Count Range for Industry Attainment',
          fieldName: 'teams_attainment*',
          type: 'Character',
          values: '0 to 2, 3 to 4, 5 to 9, 10 or more',
        },
        {
          description: 'Industry Attainment ranked as the 25th percentile',
          field: 15,
          fieldFullName: 'Attainment Percentile 25th',
          fieldName: 'attn_perc_25*',
          type: 'Numeric',
          values: 'low',
        },
        {
          description: 'Industry Attainment ranked as the 50th percentile',
          field: 16,
          fieldFullName: 'Attainment Percentile 50th',
          fieldName: 'attn_perc_50*',
          type: 'Numeric',
          values: 'median',
        },
        {
          description: 'Industry Attainment ranked as the 75th percentile',
          field: 17,
          fieldFullName: 'Attainment Percentile 75th',
          fieldName: 'attn_perc_75*',
          type: 'Numeric',
          values: 'high',
        },
        {
          description: 'Teams counting towards Attainment Percentiles calculation',
          field: 18,
          fieldFullName: 'Team Count Range for Attainment Percentiles',
          fieldName: 'teams_attn_perc*',
          type: 'Character',
          values: '0 to 2, 3 to 4, 5 to 9, 10 or more',
        },
        {
          description:
            'Specialty grouping attributed to the HCP as reported by majority of the subscriber companies',
          field: 19,
          fieldFullName: 'Speciality',
          fieldName: 'am_specialty_grouping*',
          type: 'Character',
          values: 'N/A',
        },
        {
          description: 'Teams counting towards AM Specialty Grouping calculation',
          field: 20,
          fieldFullName: 'Team Count Range for AM Specialty Grouping',
          fieldName: 'teams_spec*',
          type: 'Character',
          values: '0 to 2, 3 to 4, 5 to 9, 10 or more',
        },
        {
          description:
            'Best ZIP Code attributed to the HCP as reported by majority of the subscriber companies',
          field: 21,
          fieldFullName: 'ZIP',
          fieldName: 'am_zip*',
          type: 'Character',
          values: 'N/A',
        },
        {
          description: 'Teams counting towards AM ZIP calculation',
          field: 22,
          fieldFullName: 'Team Count Range for AM ZIP',
          fieldName: 'teams_zip*',
          type: 'Character',
          values: '0 to 2, 3 to 4, 5 to 9, 10 or more',
        },
      ],
      url: '',
      status: 'SUCCESS',
      message: '',
      title:
        'Filesaaaaaaaaaaaaaaaaaaaaaaaaaaaaaaaaaaaaaaaaaaaaaaaaaaaaaaaaaaaaaaaaaaaaaaaaaaaaaaaaaaaaaaaaaaaaaaaaaaaaaaaaaaa',
    };
    const profiling_statistics_preview = {
      thead: ['field', 'fieldName', 'fieldFullName', 'description', 'type', 'values'],
      tbody: [
        {
          description: 'Unique client identifier as provided by ZS',
          field: 1,
          fieldFullName: 'Client ID',
          fieldName: 'client_id',
          type: 'Character',
          values: 'N/A',
        },
        {
          description: "Unique identifier for an HCP in the subscriber's internal systems",
          field: 2,
          fieldFullName: 'Client Physician ID',
          fieldName: 'client_physician_id',
          type: 'Character',
          values: 'N/A',
        },
        {
          description: 'HCP ME number',
          field: 3,
          fieldFullName: 'ME number',
          fieldName: 'ME_number',
          type: 'Character',
          values: 'N/A',
        },
        {
          description: 'HCP NPI',
          field: 4,
          fieldFullName: 'National Provider Identifier',
          fieldName: 'NPI',
          type: 'Character',
          values: 'N/A',
        },
        {
          description: 'A binary flag indicating if the provided HCP ME number is valid ',
          field: 5,
          fieldFullName: 'Valid ME Number Flag',
          fieldName: 'valid_menum_flag',
          type: 'Numeric',
          values: '1:valid, 0:invalid',
        },
        {
          description: 'A binary flag indicating if the provided HCP NPI is valid',
          field: 6,
          fieldFullName: 'Valid NPI Flag',
          fieldName: 'valid_npi_flag',
          type: 'Numeric',
          values: '1:valid, 0:invalid',
        },
        {
          description: 'A measure of how often an HCP will see a representative during the cycle',
          field: 7,
          fieldFullName: 'No See Rating',
          fieldName: 'am_no_see_rating',
          type: 'Numeric',
          values: '1-10',
        },
        {
          description: 'Teams counting towards No See Rating calculation',
          field: 8,
          fieldFullName: 'Team Count Range for No See Rating',
          fieldName: 'teams_no_see_rating',
          type: 'Character',
          values: '0 to 2, 3 to 4, 5 to 9, 10 or more',
        },
        {
          description:
            ' Annualized actual calls being made by representatives ranked as the 25th percentile',
          field: 9,
          fieldFullName: 'Call Frequency percentile 25th',
          fieldName: 'annual_call_freq_perc_25',
          type: 'Numeric',
          values: 'low',
        },
        {
          description:
            'Annualized actual calls being made by representatives ranked as the 50th percentile',
          field: 10,
          fieldFullName: 'Call Frequency Percentile 50th',
          fieldName: 'annual_call_freq_perc_50',
          type: 'Numeric',
          values: 'median',
        },
        {
          description:
            'Annualized actual calls being made by representatives ranked as the 75th percentile',
          field: 11,
          fieldFullName: 'Call Frequency Percentile 75th',
          fieldName: 'annual_call_freq_perc_75',
          type: 'Numeric',
          values: 'best in class',
        },
        {
          description: 'Teams counting towards Annual Call Frequency calculation',
          field: 12,
          fieldFullName: 'Team Count Range for Annual Call Frequencies',
          fieldName: 'teams_call_freq',
          type: 'Character',
          values: '0 to 2, 3 to 4, 5 to 9, 10 or more',
        },
        {
          description:
            '% of calls planned by all subscriber companies that are actually implemented',
          field: 13,
          fieldFullName: 'Industry Attainment ',
          fieldName: 'industry_attainment*',
          type: 'Numeric',
          values: 'N/A',
        },
        {
          description: 'Teams counting towards Industry Attainment calculation',
          field: 14,
          fieldFullName: 'Team Count Range for Industry Attainment',
          fieldName: 'teams_attainment*',
          type: 'Character',
          values: '0 to 2, 3 to 4, 5 to 9, 10 or more',
        },
        {
          description: 'Industry Attainment ranked as the 25th percentile',
          field: 15,
          fieldFullName: 'Attainment Percentile 25th',
          fieldName: 'attn_perc_25*',
          type: 'Numeric',
          values: 'low',
        },
        {
          description: 'Industry Attainment ranked as the 50th percentile',
          field: 16,
          fieldFullName: 'Attainment Percentile 50th',
          fieldName: 'attn_perc_50*',
          type: 'Numeric',
          values: 'median',
        },
        {
          description: 'Industry Attainment ranked as the 75th percentile',
          field: 17,
          fieldFullName: 'Attainment Percentile 75th',
          fieldName: 'attn_perc_75*',
          type: 'Numeric',
          values: 'high',
        },
        {
          description: 'Teams counting towards Attainment Percentiles calculation',
          field: 18,
          fieldFullName: 'Team Count Range for Attainment Percentiles',
          fieldName: 'teams_attn_perc*',
          type: 'Character',
          values: '0 to 2, 3 to 4, 5 to 9, 10 or more',
        },
        {
          description:
            'Specialty grouping attributed to the HCP as reported by majority of the subscriber companies',
          field: 19,
          fieldFullName: 'Speciality',
          fieldName: 'am_specialty_grouping*',
          type: 'Character',
          values: 'N/A',
        },
        {
          description: 'Teams counting towards AM Specialty Grouping calculation',
          field: 20,
          fieldFullName: 'Team Count Range for AM Specialty Grouping',
          fieldName: 'teams_spec*',
          type: 'Character',
          values: '0 to 2, 3 to 4, 5 to 9, 10 or more',
        },
        {
          description:
            'Best ZIP Code attributed to the HCP as reported by majority of the subscriber companies',
          field: 21,
          fieldFullName: 'ZIP',
          fieldName: 'am_zip*',
          type: 'Character',
          values: 'N/A',
        },
        {
          description: 'Teams counting towards AM ZIP calculation',
          field: 22,
          fieldFullName: 'Team Count Range for AM ZIP',
          fieldName: 'teams_zip*',
          type: 'Character',
          values: '0 to 2, 3 to 4, 5 to 9, 10 or more',
        },
      ],
      url: '',
      status: 'SUCCESS',
      message: '',
      title:
        'Filesaaaaaaaaaaaaaaaaaaaaaaaaaaaaaaaaaaaaaaaaaaaaaaaaaaaaaaaaaaaaaaaaaaaaaaaaaaaaaaaaaaaaaaaaaaaaaaaaaaaaaaaaaaa',
    };

    const submit_steward_review = {
      status: 'SUCCESS',
      message: 'some error.',
    };

    const get_file_tracking_log = {
      data: [
        {
          id: 1,
          client: 'client_1',
          user: 'user',
          filename: 'xyz.txt',
          upload_status: 'Success',
          data_profiler: 'Success',
          revo_status: 'In Progress',
          current_step: 'Data Profiler',
          upload_date: '2022-03-05T00:00:00.000Z',
        },
        {
          id: 1,
          client: 'client_1',
          user: 'user',
          filename: 'xyz.txt',
          upload_status: 'Success',
          data_profiler: 'Issues',
          revo_status: 'In Progress',
          current_step: 'Data Profiler',
          upload_date: '2022-03-05T00:00:00.000Z',
        },
        {
          id: 1,
          client: 'client_1',
          user: 'user',
          filename: 'xyz.txt',
          upload_status: 'Success',
          data_profiler: 'Success',
          revo_status: 'In Progress',
          current_step: 'Data Profiler',
          upload_date: '2022-03-05T00:00:00.000Z',
        },
        {
          id: 1,
          client: 'client_1',
          user: 'user',
          filename: 'xyz.txt',
          upload_status: 'Success',
          data_profiler: 'Success',
          revo_status: 'In Progress',
          current_step: 'Data Profiler',
          upload_date: '2022-03-05T00:00:00.000Z',
        },
        {
          id: 1,
          client: 'client_1',
          user: 'user',
          filename: 'xyz.txt',
          upload_status: 'Success',
          data_profiler: 'Success',
          revo_status: 'In Progress',
          current_step: 'Data Profiler',
          upload_date: '2022-03-05T00:00:00.000Z',
        },
      ],
      search_keywords: ['abc', 'def', 'defi', 'jkl', 'john smith'],
      date: 'ascend/descend',
      status_revo: [
        { text: 'Not Started', value: false },
        { text: 'In Progress', value: false },
        { text: 'Success', value: false },
      ],
      status_upload: [
        { text: 'Not Started', value: false },
        { text: 'In Progress', value: false },
        { text: 'Success', value: false },
      ],
      status_profiler: [
        { text: 'Not Started', value: false },
        { text: 'In Progress', value: false },
        { text: 'Success', value: false },
      ],
      status_current_step: [
        { text: 'Not Started', value: false },
        { text: 'In Progress', value: false },
        { text: 'Success', value: false },
      ],
      client: [
        { text: 'client_1', value: false },
        { text: 'client_2', value: false },
        { text: 'client_3', value: false },
      ],
    };

    const get_process_details = {
      programName: 'Emails abcdefmcccccccccccccccccccc',
      channelName: 'Digital Push',
      fileSize: '8MB',
      fileName: 'ZYX.txt',
      fileNameUrl: '',
      fileId: '8',
      processTransactionId: '8',
      fileLogUrl: '',
      tableData: {
        thead: ['step', 'result', 'status', 'details', 'start_time', 'duration', 'end_time'],
        tbody: [
          {
            step: 'Loading',
            result: 'question',
            status: 'Successfully Completed',
            details: 'Feedback timeout',
            startTime: '2022-03-05T00:00:00.000Z',
            duration: '1hr 15 mins.',
            endTime: '2022-03-05T00:00:00.000Z',
          },
          {
            step: 'L2 Check',
            result: 'question',
            status: 'Successfully Completed',
            details: 'Feedback timeout',
            startTime: '2022-03-05T00:00:00.000Z',
            duration: '1hr 15 mins.',
            endTime: '2022-03-05T00:00:00.000Z',
          },
        ],
      },
    };

    const get_programs_data = {
      data: [
        {
          id: '1',
          program_name: 'Flyers',
          channel: 'Personal Face-To-Face',
          client: 'abc',
          date: 'Thu, 11 Sep 2021 00:00:00 GMT',
          status: 'Active',
          program_layout_url: '',
        },
        {
          id: '1',
          program_name: 'Flyers',
          channel: 'Personal Face-To-Face',
          client: 'abc',
          date: 'Thu, 11 Sep 2021 00:00:00 GMT',
          status: 'Retired',
          program_layout_url: '',
        },
        {
          id: '1',
          program_name: 'Flyers',
          channel: 'Personal Face-To-Face',
          client: 'abc',
          date: 'Thu, 11 Sep 2021 00:00:00 GMT',
          status: 'Active-Pending',
          program_layout_url: '',
        },
        {
          id: '1',
          program_name: 'Flyers',
          channel: 'Personal Face-To-Face',
          client: 'abc',
          date: 'Thu, 11 Sep 2021 00:00:00 GMT',
          status: 'Retired-Pending',
          program_layout_url: '',
        },
      ],
      status: [
        { text: 'Active', value: false },
        { text: 'Retired', value: false },
        { text: 'Active-Pending', value: false },
        { text: 'Retired-Pending', value: false },
      ],
      channel: [
        { text: 'Personal Face-to-Face', value: false },
        { text: 'Personal Remote', value: false },
      ],
      client: [
        { text: 'Personal Face-to-Face', value: false },
        { text: 'Personal Remote', value: false },
      ],
      date: 'descend',
      search_keywords: ['xyzdf'],
      export_url: '',
    };

    const submit_program_review = {
      status: 'ERROR',
      message: 'err',
    };
    const add_standard_program = {
      status: 'ERROR',
      message: 'err',
    };

    const get_filter_data = {
      executive_summary: [
        {
          id: 'HCP_TAX_CODE',
          title: 'Timeframe',
          selected: 'Month',
          values: ['Month', 'Quarter', 'Semester'],
        },
        {
          id: 'REACH',
          title: 'Benchmark Period',
          selected: 'Jan 2020',
          period: 'Month',
          values: [
            'Jan 2020',
            'Feb 2020',
            'March 2020',
            'Apr 2020',
            'May 2020',
            'Jun 2020',
            'Jul 2020',
            'Aug 2020',
            'Sep 2020',
            'Oct 2020',
            'Nov 2020',
            'Dec 2020',
            'Jan 2021',
            'Feb 2021',
            'Mar 2021',
            'Apr 2021',
            'May 2021',
            'Jun 2021',
            'Jul 2021',
            'Aug 2021',
            'Sep 2021',
            'Oct 2021',
            'Nov 2021',
            'Dec 2021',
            'Jan 2022',
            'Feb 2022',
            'Mar 2022',
            'Apr 2022',
            'May 2022',
          ],
        },
        {
          id: 'REACH',
          title: 'Benchmark Period',
          selected: "Q1'20",
          period: 'Quarter',
          values: [
            "Q1'20",
            "Q2'20",
            "Q3'20",
            "Q4'20",
            "Q1'21",
            "Q2'21",
            "Q3'21",
            "Q4'21",
            "Q1'22",
            "Q2'22",
          ],
        },
        {
          id: 'REACH',
          title: 'Benchmark Period',
          selected: "H1'20",
          period: 'Semester',
          values: ["H1'20", "H2'20", "H1'21", "H2'21", "H1'22", "H2'22"],
        },
        {
          id: 'SPEC',
          title: 'Specialty',
          selected: 'All Specialties',
          values: [
            'All Specialties',
            'Allergy/Immunology',
            'Anesthesiology',
            'Cardiology',
            'Dermatology',
            'Emergency Medicine',
            'Endocrinology',
            'Gastroenterology',
            'Geriatrics',
            'Hematology',
            'Infectious Diseases',
            'Nephrology',
            'Neurology',
            'Not Determined',
            'Obstetrics',
            'Gynecology',
            'Oncology',
            'Opthalmology',
            'Optometry',
            'Orthopedics',
            'Other Specialties',
            'Otalaryngology',
            'Pathology',
            'Pediatrics',
            'Physical Medicine and Rehab',
            'Preventive Medicine',
            'Prmary Care',
            'Psychiatry',
            'Pulmonary',
            'Radiology',
            'Rheumatology',
            'Surgery',
            'Urology',
          ],
        },
      ],
      detailed_benchmarks_promotion: [
        {
          id: 'HCP_TAX_CODE',
          title: 'Timeframe',
          selected: 'Month',
          values: ['Month', 'Quarter', 'Semester'],
        },
        {
          id: 'REACH',
          title: 'Benchmark Period',
          selected: 'Jan 2020',
          period: 'Month',
          values: [
            'Jan 2020',
            'Feb 2020',
            'March 2020',
            'Apr 2020',
            'May 2020',
            'Jun 2020',
            'Jul 2020',
            'Aug 2020',
            'Sep 2020',
            'Oct 2020',
            'Nov 2020',
            'Dec 2020',
            'Jan 2021',
            'Feb 2021',
            'Mar 2021',
            'Apr 2021',
            'May 2021',
            'Jun 2021',
            'Jul 2021',
            'Aug 2021',
            'Sep 2021',
            'Oct 2021',
            'Nov 2021',
            'Dec 2021',
            'Jan 2022',
            'Feb 2022',
            'Mar 2022',
            'Apr 2022',
            'May 2022',
          ]
        },
        {
          id: 'REACH',
          title: 'Benchmark Period',
          selected: "Q1'20",
          period: 'Quarter',
          values: [
            "Q1'20",
            "Q2'20",
            "Q3'20",
            "Q4'20",
            "Q1'21",
            "Q2'21",
            "Q3'21",
            "Q4'21",
            "Q1'22",
            "Q2'22",
          ]
        },
        {
          id: 'REACH',
          title: 'Benchmark Period',
          selected: "H1'20",
          period: 'Semester',
          values: ["H1'20", "H2'20", "H1'21", "H2'21", "H1'22", "H2'22"],
        },
        {
          id: 'SPEC',
          title: 'Specialty',
          selected: 'All Specialties',
          values: [
            'All Specialties',
            'Allergy/Immunology',
            'Anesthesiology',
            'Cardiology',
            'Dermatology',
            'Emergency Medicine',
            'Endocrinology',
            'Gastroenterology',
            'Geriatrics',
            'Hematology',
            'Infectious Diseases',
            'Nephrology',
            'Neurology',
            'Not Determined',
            'Obstetrics',
            'Gynecology',
            'Oncology',
            'Opthalmology',
            'Optometry',
            'Orthopedics',
            'Other Specialties',
            'Otalaryngology',
            'Pathology',
            'Pediatrics',
            'Physical Medicine and Rehab',
            'Preventive Medicine',
            'Prmary Care',
            'Psychiatry',
            'Pulmonary',
            'Radiology',
            'Rheumatology',
            'Surgery',
            'Urology',
          ]
        },
      ],
      detailed_benchmarks_personal_face_to_face: [
        {
          id: 'REACH',
          title: 'Timeframe',
          selected: 'Month',
          values: ['Month', 'Quarter', 'Semester'],
        },
        {
          id: 'TEAMS_ATTAINMENT',
          title: 'Benchmark Period',
          selected: 'Jan 2020',
          period: 'Month',
          values: [
            'Jan 2020',
            'Feb 2020',
            'March 2020',
            'Apr 2020',
            'May 2020',
            'Jun 2020',
            'Jul 2020',
            'Aug 2020',
            'Sep 2020',
            'Oct 2020',
            'Nov 2020',
            'Dec 2020',
            'Jan 2021',
            'Feb 2021',
            'Mar 2021',
            'Apr 2021',
            'May 2021',
            'Jun 2021',
            'Jul 2021',
            'Aug 2021',
            'Sep 2021',
            'Oct 2021',
            'Nov 2021',
            'Dec 2021',
            'Jan 2022',
            'Feb 2022',
            'Mar 2022',
            'Apr 2022',
            'May 2022',
          ],
        },
        {
          id: 'TEAMS_ATTAINMENT',
          title: 'Benchmark Period',
          selected: "Q1'20",
          period: 'Quarter',
          values: [
            "Q1'20",
            "Q2'20",
            "Q3'20",
            "Q4'20",
            "Q1'21",
            "Q2'21",
            "Q3'21",
            "Q4'21",
            "Q1'22",
            "Q2'22",
          ],
        },
        {
          id: 'TEAMS_ATTAINMENT',
          title: 'Benchmark Period',
          selected: "H1'20",
          period: 'Semester',
          values: ["H1'20", "H2'20", "H1'21", "H2'21", "H1'22", "H2'22"],
        },
        {
          id: 'SPEC',
          title: 'Type/HCP Type',
          selected: 'All',
          values: ['All', 'Physician', 'Non-Physician'],
        },
      ],
      detailed_benchmarks_digital_push: [
        {
          id: 'REACH_TYPE',
          title: 'Timeframe',
          selected: 'Month',
          values: ['Month', 'Quarter', 'Semester'],
        },
        {
          id: 'REACH',
          title: 'Benchmark Period',
          selected: 'Jan 2020',
          period: 'Month',
          values: [
            'Jan 2020',
            'Feb 2020',
            'March 2020',
            'Apr 2020',
            'May 2020',
            'Jun 2020',
            'Jul 2020',
            'Aug 2020',
            'Sep 2020',
            'Oct 2020',
            'Nov 2020',
            'Dec 2020',
            'Jan 2021',
            'Feb 2021',
            'Mar 2021',
            'Apr 2021',
            'May 2021',
            'Jun 2021',
            'Jul 2021',
            'Aug 2021',
            'Sep 2021',
            'Oct 2021',
            'Nov 2021',
            'Dec 2021',
            'Jan 2022',
            'Feb 2022',
            'Mar 2022',
            'Apr 2022',
            'May 2022',
          ],
        },
        {
          id: 'REACH',
          title: 'Benchmark Period',
          selected: "Q1'20",
          period: 'Quarter',
          values: [
            "Q1'20",
            "Q2'20",
            "Q3'20",
            "Q4'20",
            "Q1'21",
            "Q2'21",
            "Q3'21",
            "Q4'21",
            "Q1'22",
            "Q2'22",
          ],
        },
        {
          id: 'REACH',
          title: 'Benchmark Period',
          selected: "H1'20",
          period: 'Semester',
          values: ["H1'20", "H2'20", "H1'21", "H2'21", "H1'22", "H2'22"],
        },
        {
          id: 'REACH_TYPE',
          title: 'Specialty',
          selected: 'All Specialties',
          values: [
            'All Specialties',
            'Allergy/Immunology',
            'Anesthesiology',
            'Cardiology',
            'Dermatology',
            'Emergency Medicine',
            'Endocrinology',
            'Gastroenterology',
            'Geriatrics',
            'Hematology',
            'Infectious Diseases',
            'Nephrology',
            'Neurology',
            'Not Determined',
            'Obstetrics',
            'Gynecology',
            'Oncology',
            'Opthalmology',
            'Optometry',
            'Orthopedics',
            'Other Specialties',
            'Otalaryngology',
            'Pathology',
            'Pediatrics',
            'Physical Medicine and Rehab',
            'Preventive Medicine',
            'Prmary Care',
            'Psychiatry',
            'Pulmonary',
            'Radiology',
            'Rheumatology',
            'Surgery',
            'Urology',
          ],
        },
        {
          id: 'REACH_TYPE',
          title: 'Content',
          selected: 'All',
          values: [
            'All',
            'Brand Approval or new indication ',
            'Branded Product Mention',
            'Clinical case study/ Patient Profiles',
            'Clinical Practice',
            'Clinical Trials',
            'Copay, Coupons and PAPs',
            'Covid-19 related',
            'Disease Knowledge',
            'Efiicacy and Safety',
            'KOL/Peer opinions and Insights',
            'MHC Access and Coverage',
            'patient Education (Direct)',
            'Patient Education (Indirect)',
            'Product Alerts',
            'Quiz/interactive Activity',
            'Sample',
            'Treatment information',
          ],
        },
        {
          id: 'REACH_TYPE',
          title: 'Lookback Period',
          selected: '6 Months',
          values: ['6 Months', '12 Months', '24 Months'],
        },
      ],
      detailed_benchmarks_digital_pull: [
        {
          id: 'REACH_TYPE',
          title: 'Timeframe',
          selected: 'Month',
          values: ['Month', 'Quarter', 'Semester'],
        },
        {
          id: 'REACH',
          title: 'Benchmark Period',
          selected: 'Jan 2020',
          period: 'Month',
          values: [
            'Jan 2020',
            'Feb 2020',
            'March 2020',
            'Apr 2020',
            'May 2020',
            'Jun 2020',
            'Jul 2020',
            'Aug 2020',
            'Sep 2020',
            'Oct 2020',
            'Nov 2020',
            'Dec 2020',
            'Jan 2021',
            'Feb 2021',
            'Mar 2021',
            'Apr 2021',
            'May 2021',
            'Jun 2021',
            'Jul 2021',
            'Aug 2021',
            'Sep 2021',
            'Oct 2021',
            'Nov 2021',
            'Dec 2021',
            'Jan 2022',
            'Feb 2022',
            'Mar 2022',
            'Apr 2022',
            'May 2022',
          ],
        },
        {
          id: 'REACH',
          title: 'Benchmark Period',
          selected: "Q1'20",
          period: 'Quarter',
          values: [
            "Q1'20",
            "Q2'20",
            "Q3'20",
            "Q4'20",
            "Q1'21",
            "Q2'21",
            "Q3'21",
            "Q4'21",
            "Q1'22",
            "Q2'22",
          ],
        },
        {
          id: 'REACH',
          title: 'Benchmark Period',
          selected: "H1'20",
          period: 'Semester',
          values: ["H1'20", "H2'20", "H1'21", "H2'21", "H1'22", "H2'22"],
        },
        {
          id: 'REACH_TYPE',
          title: 'Specialty',
          selected: 'All SPecialties',
          values: [
            'All Specialties',
            'Allergy/Immunology',
            'Anesthesiology',
            'Cardiology',
            'Dermatology',
            'Emergency Medicine',
            'Endocrinology',
            'Gastroenterology',
            'Geriatrics',
            'Hematology',
            'Infectious Diseases',
            'Nephrology',
            'Neurology',
            'Not Determined',
            'Obstetrics',
            'Gynecology',
            'Oncology',
            'Opthalmology',
            'Optometry',
            'Orthopedics',
            'Other Specialties',
            'Otalaryngology',
            'Pathology',
            'Pediatrics',
            'Physical Medicine and Rehab',
            'Preventive Medicine',
            'Prmary Care',
            'Psychiatry',
            'Pulmonary',
            'Radiology',
            'Rheumatology',
            'Surgery',
            'Urology',
          ],
        },
      ],
      detailed_benchmarks_personal_remote: [
        {
          id: 'REACH_TYPE',
          title: 'Timeframe',
          selected: 'Month',
          values: ['Month', 'Quarter', 'Semester'],
        },
        {
          id: 'REACH',
          title: 'Benchmark Period',
          selected: 'Jan 2020',
          period: 'Month',
          values: [
            'Jan 2020',
            'Feb 2020',
            'March 2020',
            'Apr 2020',
            'May 2020',
            'Jun 2020',
            'Jul 2020',
            'Aug 2020',
            'Sep 2020',
            'Oct 2020',
            'Nov 2020',
            'Dec 2020',
            'Jan 2021',
            'Feb 2021',
            'Mar 2021',
            'Apr 2021',
            'May 2021',
            'Jun 2021',
            'Jul 2021',
            'Aug 2021',
            'Sep 2021',
            'Oct 2021',
            'Nov 2021',
            'Dec 2021',
            'Jan 2022',
            'Feb 2022',
            'Mar 2022',
            'Apr 2022',
            'May 2022',
          ],
        },
        {
          id: 'REACH',
          title: 'Benchmark Period',
          selected: "Q1'20",
          period: 'Quarter',
          values: [
            "Q1'20",
            "Q2'20",
            "Q3'20",
            "Q4'20",
            "Q1'21",
            "Q2'21",
            "Q3'21",
            "Q4'21",
            "Q1'22",
            "Q2'22",
          ],
        },
        {
          id: 'REACH',
          title: 'Benchmark Period',
          selected: "H1'20",
          period: 'Semester',
          values: ["H1'20", "H2'20", "H1'21", "H2'21", "H1'22", "H2'22"],
        },
        {
          id: 'REACH_TYPE',
          title: 'Specialty',
          selected: 'All Specialties',
          values: [
            'All Specialties',
            'Allergy/Immunology',
            'Anesthesiology',
            'Cardiology',
            'Dermatology',
            'Emergency Medicine',
            'Endocrinology',
            'Gastroenterology',
            'Geriatrics',
            'Hematology',
            'Infectious Diseases',
            'Nephrology',
            'Neurology',
            'Not Determined',
            'Obstetrics',
            'Gynecology',
            'Oncology',
            'Opthalmology',
            'Optometry',
            'Orthopedics',
            'Other Specialties',
            'Otalaryngology',
            'Pathology',
            'Pediatrics',
            'Physical Medicine and Rehab',
            'Preventive Medicine',
            'Prmary Care',
            'Psychiatry',
            'Pulmonary',
            'Radiology',
            'Rheumatology',
            'Surgery',
            'Urology',
          ],
        },
      ],
      detailed_benchmarks_direct: [
        {
          id: 'REACH_TYPE',
          title: 'Timeframe',
          selected: 'Month',
          values: ['Month', 'Quarter', 'Semester'],
        },
        {
          id: 'REACH',
          title: 'Benchmark Period',
          selected: 'Jan 2020',
          period: 'Month',
          values: [
            'Jan 2020',
            'Feb 2020',
            'March 2020',
            'Apr 2020',
            'May 2020',
            'Jun 2020',
            'Jul 2020',
            'Aug 2020',
            'Sep 2020',
            'Oct 2020',
            'Nov 2020',
            'Dec 2020',
            'Jan 2021',
            'Feb 2021',
            'Mar 2021',
            'Apr 2021',
            'May 2021',
            'Jun 2021',
            'Jul 2021',
            'Aug 2021',
            'Sep 2021',
            'Oct 2021',
            'Nov 2021',
            'Dec 2021',
            'Jan 2022',
            'Feb 2022',
            'Mar 2022',
            'Apr 2022',
            'May 2022',
          ],
        },
        {
          id: 'REACH',
          title: 'Benchmark Period',
          selected: "Q1'20",
          period: 'Quarter',
          values: [
            "Q1'20",
            "Q2'20",
            "Q3'20",
            "Q4'20",
            "Q1'21",
            "Q2'21",
            "Q3'21",
            "Q4'21",
            "Q1'22",
            "Q2'22",
          ],
        },
        {
          id: 'REACH',
          title: 'Benchmark Period',
          selected: "H1'20",
          period: 'Semester',
          values: ["H1'20", "H2'20", "H1'21", "H2'21", "H1'22", "H2'22"],
        },
        {
          id: 'REACH_TYPE',
          title: 'Specialty',
          selected: 'All Specialties',
          values: [
            'All Specialties',
            'Allergy/Immunology',
            'Anesthesiology',
            'Cardiology',
            'Dermatology',
            'Emergency Medicine',
            'Endocrinology',
            'Gastroenterology',
            'Geriatrics',
            'Hematology',
            'Infectious Diseases',
            'Nephrology',
            'Neurology',
            'Not Determined',
            'Obstetrics',
            'Gynecology',
            'Oncology',
            'Opthalmology',
            'Optometry',
            'Orthopedics',
            'Other Specialties',
            'Otalaryngology',
            'Pathology',
            'Pediatrics',
            'Physical Medicine and Rehab',
            'Preventive Medicine',
            'Prmary Care',
            'Psychiatry',
            'Pulmonary',
            'Radiology',
            'Rheumatology',
            'Surgery',
            'Urology',
          ],
        },
      ],
      detailed_benchmarks_peer_interaction: [
        {
          id: 'REACH_TYPE',
          title: 'Timeframe',
          selected: 'Month',
          values: ['Month', 'Quarter', 'Semester'],
        },
        {
          id: 'REACH',
          title: 'Benchmark Period',
          selected: 'Jan 2020',
          period: 'Month',
          values: [
            'Jan 2020',
            'Feb 2020',
            'March 2020',
            'Apr 2020',
            'May 2020',
            'Jun 2020',
            'Jul 2020',
            'Aug 2020',
            'Sep 2020',
            'Oct 2020',
            'Nov 2020',
            'Dec 2020',
            'Jan 2021',
            'Feb 2021',
            'Mar 2021',
            'Apr 2021',
            'May 2021',
            'Jun 2021',
            'Jul 2021',
            'Aug 2021',
            'Sep 2021',
            'Oct 2021',
            'Nov 2021',
            'Dec 2021',
            'Jan 2022',
            'Feb 2022',
            'Mar 2022',
            'Apr 2022',
            'May 2022',
          ],
        },
        {
          id: 'REACH',
          title: 'Benchmark Period',
          selected: "Q1'20",
          period: 'Quarter',
          values: [
            "Q1'20",
            "Q2'20",
            "Q3'20",
            "Q4'20",
            "Q1'21",
            "Q2'21",
            "Q3'21",
            "Q4'21",
            "Q1'22",
            "Q2'22",
          ],
        },
        {
          id: 'REACH',
          title: 'Benchmark Period',
          selected: "H1'20",
          period: 'Semester',
          values: ["H1'20", "H2'20", "H1'21", "H2'21", "H1'22", "H2'22"],
        },
        {
          id: 'REACH_TYPE',
          title: 'Specialty',
          selected: 'All Specialties',
          values: [
            'All Specialties',
            'Allergy/Immunology',
            'Anesthesiology',
            'Cardiology',
            'Dermatology',
            'Emergency Medicine',
            'Endocrinology',
            'Gastroenterology',
            'Geriatrics',
            'Hematology',
            'Infectious Diseases',
            'Nephrology',
            'Neurology',
            'Not Determined',
            'Obstetrics',
            'Gynecology',
            'Oncology',
            'Opthalmology',
            'Optometry',
            'Orthopedics',
            'Other Specialties',
            'Otalaryngology',
            'Pathology',
            'Pediatrics',
            'Physical Medicine and Rehab',
            'Preventive Medicine',
            'Prmary Care',
            'Psychiatry',
            'Pulmonary',
            'Radiology',
            'Rheumatology',
            'Surgery',
            'Urology',
          ],
        },
      ],
      coverage_stats: [
        {
          id: 'REACH_TYPE',
          title: 'Semester',
          selected: "H1'20",
          values: ["H1'20", "H2'20", "H1'21", "H2'21", "H1'22", "H2'22"],
        },
      ],
    };

    const get_drawer_content = {
      data: [
        {
          client_dashboard: { filters: [], oid: '' },
          dashboard: {
            filters: [
              {
                dataset: 'industry_union_hcp_engagement_rate_revo_v0.1.csv',
                filter_id: 'timegrain',
                filter_name: 'Time Grain',
                period: '',
                selected: 'Semester',
                values: ['Semester'],
              },
              {
                dataset: 'Period_id',
                filter_id: 'period_id',
                filter_name: 'Benchmark Period',
                period: 'Month',
                selected: "May'22",
                values: [
                  "Jan'20",
                  "Feb'20",
                  "Mar'20",
                  "Apr'20",
                  "May'20",
                  "Jun'20",
                  "Jul'20",
                  "Aug'20",
                  "Sep'20",
                  "Oct'20",
                  "Nov'20",
                  "Dec'20",
                  "Jan'21",
                  "Feb'21",
                  "Mar'21",
                  "Apr'21",
                  "May'21",
                  "Jun'21",
                  "Jul'21",
                  "Aug'21",
                  "Sep'21",
                  "Oct'21",
                  "Nov'21",
                  "Dec'21",
                  "Jan'22",
                  "Feb'22",
                  "Mar'22",
                  "Apr'22",
                  "May'22",
                ],
              },
              {
                dataset: 'Period_id',
                filter_id: 'period_id',
                filter_name: 'Benchmark Period',
                period: 'Quarter',
                selected: "Q2'22",
                values: [
                  "Q1'20",
                  "Q2'20",
                  "Q3'20",
                  "Q4'20",
                  "Q1'21",
                  "Q2'21",
                  "Q3'21",
                  "Q4'21",
                  "Q1'22",
                  "Q2'22",
                ],
              },
              {
                dataset: 'Period_id',
                filter_id: 'period_id',
                filter_name: 'Benchmark Period',
                period: 'Semester',
                selected: '2022 S2',
                values: [
                  '2019 S2',
                  '2020 S1',
                  '2020 S2',
                  '2021 S1',
                  '2021 S2',
                  '2022 S1',
                  '2022 S2',
                ],
              },
              {
                dataset: 'Specialty',
                filter_id: 'Specialty',
                filter_name: 'Specialty',
                period: '',
                selected: 'All Specialties',
                values: [
                  'All Specialties',
                  'Allergy/Immunology',
                  'Anesthesiology',
                  'Cardiology',
                  'Dermatology',
                  'Emergency Medicine',
                  'Endocrinology',
                  'Gastroenterology',
                  'Geriatrics',
                  'Hematology',
                  'Hepatology',
                  'Infectious Diseases',
                  'Nephrology',
                  'Neurology',
                  'Obstetrics/Gynecology',
                  'Oncology',
                  'Ophthalmology/Optometry',
                  'Orthopedics',
                  'Otolaryngology',
                  'Pathology',
                  'Pediatrics',
                  'Physical Medicine & Rehab',
                  'Preventive Medicine',
                  'Primary Care',
                  'Psychiatry',
                  'Pulmonary',
                  'Radiology',
                  'Rheumatology',
                  'Surgery',
                  'Urology',
                ],
              },
            ],
            oid: '6399a03e880e120037920516',
          },
          selected: true,
          title: 'Executive Summary',
        },
        {
          selected: false,
          tabs: [
            {
              client_dashboard: {
                filters: [
                  {
                    dataset: 'timegrain_filter',
                    filter_id: 'timegrain',
                    filter_name: 'Time Grain',
                    period: '',
                    selected: 'Semester',
                    values: ['Semester'],
                  },
                  {
                    dataset: 'period_filter',
                    filter_id: 'period_id',
                    filter_name: 'Show Data Through',
                    period: 'Month',
                    selected: "May'22",
                    values: [
                      "Jan'20",
                      "Feb'20",
                      "Mar'20",
                      "Apr'20",
                      "May'20",
                      "Jun'20",
                      "Jul'20",
                      "Aug'20",
                      "Sep'20",
                      "Oct'20",
                      "Nov'20",
                      "Dec'20",
                      "Jan'21",
                      "Feb'21",
                      "Mar'21",
                      "Apr'21",
                      "May'21",
                      "Jun'21",
                      "Jul'21",
                      "Aug'21",
                      "Sep'21",
                      "Oct'21",
                      "Nov'21",
                      "Dec'21",
                      "Jan'22",
                      "Feb'22",
                      "Mar'22",
                      "Apr'22",
                      "May'22",
                    ],
                  },
                  {
                    dataset: 'period_filter',
                    filter_id: 'period_id',
                    filter_name: 'Show Data Through',
                    period: 'Quarter',
                    selected: "Q2'22",
                    values: [
                      "Q1'20",
                      "Q2'20",
                      "Q3'20",
                      "Q4'20",
                      "Q1'21",
                      "Q2'21",
                      "Q3'21",
                      "Q4'21",
                      "Q1'22",
                      "Q2'22",
                    ],
                  },
                  {
                    dataset: 'period_filter',
                    filter_id: 'period_id',
                    filter_name: 'Show Data Through',
                    period: 'Semester',
                    selected: '2022 S2',
                    values: [
                      '2019 S2',
                      '2020 S1',
                      '2020 S2',
                      '2021 S1',
                      '2021 S2',
                      '2022 S1',
                      '2022 S2',
                    ],
                  },
                  {
                    dataset: 'specialty_filter',
                    filter_id: 'specialty',
                    filter_name: 'Specialty',
                    period: '',
                    selected: 'All Specialties',
                    values: [
                      'All Specialties',
                      'Allergy/Immunology',
                      'Anesthesiology',
                      'Cardiology',
                      'Dermatology',
                      'Emergency Medicine',
                      'Endocrinology',
                      'Gastroenterology',
                      'Geriatrics',
                      'Hematology',
                      'Hepatology',
                      'Infectious Diseases',
                      'Nephrology',
                      'Neurology',
                      'Obstetrics/Gynecology',
                      'Oncology',
                      'Ophthalmology/Optometry',
                      'Orthopedics',
                      'Otolaryngology',
                      'Pathology',
                      'Pediatrics',
                      'Physical Medicine & Rehab',
                      'Preventive Medicine',
                      'Primary Care',
                      'Psychiatry',
                      'Pulmonary',
                      'Radiology',
                      'Rheumatology',
                      'Surgery',
                      'Urology',
                    ],
                  },
                ],
                oid: '635903ffb0c4db0037b2b2ee',
              },
              dashboard: {
                filters: [
                  {
                    dataset: 'timegrain_filter',
                    filter_id: 'timegrain',
                    filter_name: 'Time Grain',
                    period: '',
                    selected: 'Semester',
                    values: ['Semester'],
                  },
                  {
                    dataset: 'period_filter',
                    filter_id: 'period_id',
                    filter_name: 'Show Data Through',
                    period: 'Month',
                    selected: "May'22",
                    values: [
                      "Jan'20",
                      "Feb'20",
                      "Mar'20",
                      "Apr'20",
                      "May'20",
                      "Jun'20",
                      "Jul'20",
                      "Aug'20",
                      "Sep'20",
                      "Oct'20",
                      "Nov'20",
                      "Dec'20",
                      "Jan'21",
                      "Feb'21",
                      "Mar'21",
                      "Apr'21",
                      "May'21",
                      "Jun'21",
                      "Jul'21",
                      "Aug'21",
                      "Sep'21",
                      "Oct'21",
                      "Nov'21",
                      "Dec'21",
                      "Jan'22",
                      "Feb'22",
                      "Mar'22",
                      "Apr'22",
                      "May'22",
                    ],
                  },
                  {
                    dataset: 'period_filter',
                    filter_id: 'period_id',
                    filter_name: 'Show Data Through',
                    period: 'Quarter',
                    selected: "Q2'22",
                    values: [
                      "Q1'20",
                      "Q2'20",
                      "Q3'20",
                      "Q4'20",
                      "Q1'21",
                      "Q2'21",
                      "Q3'21",
                      "Q4'21",
                      "Q1'22",
                      "Q2'22",
                    ],
                  },
                  {
                    dataset: 'period_filter',
                    filter_id: 'period_id',
                    filter_name: 'Show Data Through',
                    period: 'Semester',
                    selected: '2022 S2',
                    values: [
                      '2019 S2',
                      '2020 S1',
                      '2020 S2',
                      '2021 S1',
                      '2021 S2',
                      '2022 S1',
                      '2022 S2',
                    ],
                  },
                  {
                    dataset: 'specialty_filter',
                    filter_id: 'specialty',
                    filter_name: 'Specialty',
                    period: '',
                    selected: 'All Specialties',
                    values: [
                      'All Specialties',
                      'Allergy/Immunology',
                      'Anesthesiology',
                      'Cardiology',
                      'Dermatology',
                      'Emergency Medicine',
                      'Endocrinology',
                      'Gastroenterology',
                      'Geriatrics',
                      'Hematology',
                      'Hepatology',
                      'Infectious Diseases',
                      'Nephrology',
                      'Neurology',
                      'Obstetrics/Gynecology',
                      'Oncology',
                      'Ophthalmology/Optometry',
                      'Orthopedics',
                      'Otolaryngology',
                      'Pathology',
                      'Pediatrics',
                      'Physical Medicine & Rehab',
                      'Preventive Medicine',
                      'Primary Care',
                      'Psychiatry',
                      'Pulmonary',
                      'Radiology',
                      'Rheumatology',
                      'Surgery',
                      'Urology',
                    ],
                  },
                ],
                oid: '63aa092c774c1e0037d970e4',
              },
              selected: false,
              title: 'Personal vs Non Personal Promotion',
            },
            {
              client_dashboard: {
                filters: [
                  {
                    dataset: 'timegrain_filter',
                    filter_id: 'timegrain',
                    filter_name: 'Time Grain',
                    period: '',
                    selected: 'Semester',
                    values: ['Semester'],
                  },
                  {
                    dataset: 'period_filter',
                    filter_id: 'period_id',
                    filter_name: 'Show Data Through',
                    period: 'Month',
                    selected: "May'22",
                    values: [
                      "Jan'20",
                      "Feb'20",
                      "Mar'20",
                      "Apr'20",
                      "May'20",
                      "Jun'20",
                      "Jul'20",
                      "Aug'20",
                      "Sep'20",
                      "Oct'20",
                      "Nov'20",
                      "Dec'20",
                      "Jan'21",
                      "Feb'21",
                      "Mar'21",
                      "Apr'21",
                      "May'21",
                      "Jun'21",
                      "Jul'21",
                      "Aug'21",
                      "Sep'21",
                      "Oct'21",
                      "Nov'21",
                      "Dec'21",
                      "Jan'22",
                      "Feb'22",
                      "Mar'22",
                      "Apr'22",
                      "May'22",
                    ],
                  },
                  {
                    dataset: 'period_filter',
                    filter_id: 'period_id',
                    filter_name: 'Show Data Through',
                    period: 'Quarter',
                    selected: "Q2'22",
                    values: [
                      "Q1'20",
                      "Q2'20",
                      "Q3'20",
                      "Q4'20",
                      "Q1'21",
                      "Q2'21",
                      "Q3'21",
                      "Q4'21",
                      "Q1'22",
                      "Q2'22",
                    ],
                  },
                  {
                    dataset: 'period_filter',
                    filter_id: 'period_id',
                    filter_name: 'Show Data Through',
                    period: 'Semester',
                    selected: '2022 S2',
                    values: [
                      '2019 S2',
                      '2020 S1',
                      '2020 S2',
                      '2021 S1',
                      '2021 S2',
                      '2022 S1',
                      '2022 S2',
                    ],
                  },
                  {
                    dataset: 'hcp_type_filter',
                    filter_id: 'hcp_type',
                    filter_name: 'Type/HCP Type',
                    period: '',
                    selected: 'All HCPs',
                    values: ['All HCPs', 'Physician', 'NPPA', 'Others'],
                  },
                  {
                    dataset: 'specialty_filter',
                    filter_id: 'specialty',
                    filter_name: 'Specialty',
                    period: '',
                    selected: 'All Specialties',
                    values: [
                      'All Specialties',
                      'Allergy/Immunology',
                      'Anesthesiology',
                      'Cardiology',
                      'Dermatology',
                      'Emergency Medicine',
                      'Endocrinology',
                      'Gastroenterology',
                      'Geriatrics',
                      'Hematology',
                      'Hepatology',
                      'Infectious Diseases',
                      'Nephrology',
                      'Neurology',
                      'Obstetrics/Gynecology',
                      'Oncology',
                      'Ophthalmology/Optometry',
                      'Orthopedics',
                      'Otolaryngology',
                      'Pathology',
                      'Pediatrics',
                      'Physical Medicine & Rehab',
                      'Preventive Medicine',
                      'Primary Care',
                      'Psychiatry',
                      'Pulmonary',
                      'Radiology',
                      'Rheumatology',
                      'Surgery',
                      'Urology',
                    ],
                  },
                ],
                oid: '635903cbb0c4db0037b2b28f',
              },
              dashboard: {
                filters: [
                  {
                    dataset: 'timegrain_filter',
                    filter_id: 'timegrain',
                    filter_name: 'Time Grain',
                    period: '',
                    selected: 'Semester',
                    values: ['Semester'],
                  },
                  {
                    dataset: 'period_filter',
                    filter_id: 'period_id',
                    filter_name: 'Show Data Through',
                    period: 'Month',
                    selected: "May'22",
                    values: [
                      "Jan'20",
                      "Feb'20",
                      "Mar'20",
                      "Apr'20",
                      "May'20",
                      "Jun'20",
                      "Jul'20",
                      "Aug'20",
                      "Sep'20",
                      "Oct'20",
                      "Nov'20",
                      "Dec'20",
                      "Jan'21",
                      "Feb'21",
                      "Mar'21",
                      "Apr'21",
                      "May'21",
                      "Jun'21",
                      "Jul'21",
                      "Aug'21",
                      "Sep'21",
                      "Oct'21",
                      "Nov'21",
                      "Dec'21",
                      "Jan'22",
                      "Feb'22",
                      "Mar'22",
                      "Apr'22",
                      "May'22",
                    ],
                  },
                  {
                    dataset: 'period_filter',
                    filter_id: 'period_id',
                    filter_name: 'Show Data Through',
                    period: 'Quarter',
                    selected: "Q2'22",
                    values: [
                      "Q1'20",
                      "Q2'20",
                      "Q3'20",
                      "Q4'20",
                      "Q1'21",
                      "Q2'21",
                      "Q3'21",
                      "Q4'21",
                      "Q1'22",
                      "Q2'22",
                    ],
                  },
                  {
                    dataset: 'period_filter',
                    filter_id: 'period_id',
                    filter_name: 'Show Data Through',
                    period: 'Semester',
                    selected: '2022 S2',
                    values: [
                      '2019 S2',
                      '2020 S1',
                      '2020 S2',
                      '2021 S1',
                      '2021 S2',
                      '2022 S1',
                      '2022 S2',
                    ],
                  },
                  {
                    dataset: 'hcp_type_filter',
                    filter_id: 'hcp_type',
                    filter_name: 'Type/HCP Type',
                    period: '',
                    selected: 'All HCPs',
                    values: ['All HCPs', 'Physician', 'NPPA', 'Others'],
                  },
                  {
                    dataset: 'specialty_filter',
                    filter_id: 'specialty',
                    filter_name: 'Specialty',
                    period: '',
                    selected: 'All Specialties',
                    values: [
                      'All Specialties',
                      'Allergy/Immunology',
                      'Anesthesiology',
                      'Cardiology',
                      'Dermatology',
                      'Emergency Medicine',
                      'Endocrinology',
                      'Gastroenterology',
                      'Geriatrics',
                      'Hematology',
                      'Hepatology',
                      'Infectious Diseases',
                      'Nephrology',
                      'Neurology',
                      'Obstetrics/Gynecology',
                      'Oncology',
                      'Ophthalmology/Optometry',
                      'Orthopedics',
                      'Otolaryngology',
                      'Pathology',
                      'Pediatrics',
                      'Physical Medicine & Rehab',
                      'Preventive Medicine',
                      'Primary Care',
                      'Psychiatry',
                      'Pulmonary',
                      'Radiology',
                      'Rheumatology',
                      'Surgery',
                      'Urology',
                    ],
                  },
                ],
                oid: '63bd6101774c1e0037d992a3',
              },
              selected: false,
              title: 'Personal Face to Face',
            },
            {
              client_dashboard: {
                filters: [
                  {
                    dataset: 'timegrain_filter',
                    filter_id: 'timegrain',
                    filter_name: 'Time Grain',
                    period: '',
                    selected: 'Semester',
                    values: ['Semester'],
                  },
                  {
                    dataset: 'period_filter',
                    filter_id: 'period_id',
                    filter_name: 'Show Data Through',
                    period: 'Month',
                    selected: "May'22",
                    values: [
                      "Jan'20",
                      "Feb'20",
                      "Mar'20",
                      "Apr'20",
                      "May'20",
                      "Jun'20",
                      "Jul'20",
                      "Aug'20",
                      "Sep'20",
                      "Oct'20",
                      "Nov'20",
                      "Dec'20",
                      "Jan'21",
                      "Feb'21",
                      "Mar'21",
                      "Apr'21",
                      "May'21",
                      "Jun'21",
                      "Jul'21",
                      "Aug'21",
                      "Sep'21",
                      "Oct'21",
                      "Nov'21",
                      "Dec'21",
                      "Jan'22",
                      "Feb'22",
                      "Mar'22",
                      "Apr'22",
                      "May'22",
                    ],
                  },
                  {
                    dataset: 'period_filter',
                    filter_id: 'period_id',
                    filter_name: 'Show Data Through',
                    period: 'Quarter',
                    selected: "Q2'22",
                    values: [
                      "Q1'20",
                      "Q2'20",
                      "Q3'20",
                      "Q4'20",
                      "Q1'21",
                      "Q2'21",
                      "Q3'21",
                      "Q4'21",
                      "Q1'22",
                      "Q2'22",
                    ],
                  },
                  {
                    dataset: 'period_filter',
                    filter_id: 'period_id',
                    filter_name: 'Show Data Through',
                    period: 'Semester',
                    selected: '2022 S2',
                    values: [
                      '2019 S2',
                      '2020 S1',
                      '2020 S2',
                      '2021 S1',
                      '2021 S2',
                      '2022 S1',
                      '2022 S2',
                    ],
                  },
                  {
                    dataset: 'specialty_filter',
                    filter_id: 'specialty',
                    filter_name: 'Specialty',
                    period: '',
                    selected: 'All Specialties',
                    values: [
                      'All Specialties',
                      'Allergy/Immunology',
                      'Anesthesiology',
                      'Cardiology',
                      'Dermatology',
                      'Emergency Medicine',
                      'Endocrinology',
                      'Gastroenterology',
                      'Geriatrics',
                      'Hematology',
                      'Hepatology',
                      'Infectious Diseases',
                      'Nephrology',
                      'Neurology',
                      'Obstetrics/Gynecology',
                      'Oncology',
                      'Ophthalmology/Optometry',
                      'Orthopedics',
                      'Otolaryngology',
                      'Pathology',
                      'Pediatrics',
                      'Physical Medicine & Rehab',
                      'Preventive Medicine',
                      'Primary Care',
                      'Psychiatry',
                      'Pulmonary',
                      'Radiology',
                      'Rheumatology',
                      'Surgery',
                      'Urology',
                    ],
                  },
                ],
                oid: '635903d7b0c4db0037b2b2b5',
              },
              dashboard: {
                filters: [
                  {
                    dataset: 'dph_open_rate.csv',
                    filter_id: 'timegrain',
                    filter_name: 'Time Grain',
                    period: '',
                    selected: 'Semester',
                    values: ['Semester'],
                  },
                  {
                    dataset: 'dph_open_rate.csv',
                    filter_id: 'period_id',
                    filter_name: 'Show Data Through',
                    period: 'Month',
                    selected: "May'22",
                    values: [
                      "Jan'20",
                      "Feb'20",
                      "Mar'20",
                      "Apr'20",
                      "May'20",
                      "Jun'20",
                      "Jul'20",
                      "Aug'20",
                      "Sep'20",
                      "Oct'20",
                      "Nov'20",
                      "Dec'20",
                      "Jan'21",
                      "Feb'21",
                      "Mar'21",
                      "Apr'21",
                      "May'21",
                      "Jun'21",
                      "Jul'21",
                      "Aug'21",
                      "Sep'21",
                      "Oct'21",
                      "Nov'21",
                      "Dec'21",
                      "Jan'22",
                      "Feb'22",
                      "Mar'22",
                      "Apr'22",
                      "May'22",
                    ],
                  },
                  {
                    dataset: 'dph_open_rate.csv',
                    filter_id: 'period_id',
                    filter_name: 'Show Data Through',
                    period: 'Quarter',
                    selected: "Q2'22",
                    values: [
                      "Q1'20",
                      "Q2'20",
                      "Q3'20",
                      "Q4'20",
                      "Q1'21",
                      "Q2'21",
                      "Q3'21",
                      "Q4'21",
                      "Q1'22",
                      "Q2'22",
                    ],
                  },
                  {
                    dataset: 'dph_open_rate.csv',
                    filter_id: 'period_id',
                    filter_name: 'Show Data Through',
                    period: 'Semester',
                    selected: '2022 S2',
                    values: [
                      '2019 S2',
                      '2020 S1',
                      '2020 S2',
                      '2021 S1',
                      '2021 S2',
                      '2022 S1',
                      '2022 S2',
                    ],
                  },
                  {
                    dataset: 'dph_open_rate.csv',
                    filter_id: 'specialty',
                    filter_name: 'Specialty',
                    period: '',
                    selected: 'All Specialties',
                    values: [
                      'All Specialties',
                      'Allergy/Immunology',
                      'Anesthesiology',
                      'Cardiology',
                      'Dermatology',
                      'Emergency Medicine',
                      'Endocrinology',
                      'Gastroenterology',
                      'Geriatrics',
                      'Hematology',
                      'Hepatology',
                      'Infectious Diseases',
                      'Nephrology',
                      'Neurology',
                      'Obstetrics/Gynecology',
                      'Oncology',
                      'Ophthalmology/Optometry',
                      'Orthopedics',
                      'Otolaryngology',
                      'Pathology',
                      'Pediatrics',
                      'Physical Medicine & Rehab',
                      'Preventive Medicine',
                      'Primary Care',
                      'Psychiatry',
                      'Pulmonary',
                      'Radiology',
                      'Rheumatology',
                      'Surgery',
                      'Urology',
                    ],
                  },
                ],
                oid: '63bd60e6880e12003792411c',
              },
              selected: false,
              title: 'Digital Push',
            },
            {
              client_dashboard: {
                filters: [
                  {
                    dataset: 'timegrain_filter',
                    filter_id: 'timegrain',
                    filter_name: 'Time Grain',
                    period: '',
                    selected: 'Semester',
                    values: ['Semester'],
                  },
                  {
                    dataset: 'period_filter',
                    filter_id: 'period_id',
                    filter_name: 'Show Data Through',
                    period: 'Month',
                    selected: "May'22",
                    values: [
                      "Jan'20",
                      "Feb'20",
                      "Mar'20",
                      "Apr'20",
                      "May'20",
                      "Jun'20",
                      "Jul'20",
                      "Aug'20",
                      "Sep'20",
                      "Oct'20",
                      "Nov'20",
                      "Dec'20",
                      "Jan'21",
                      "Feb'21",
                      "Mar'21",
                      "Apr'21",
                      "May'21",
                      "Jun'21",
                      "Jul'21",
                      "Aug'21",
                      "Sep'21",
                      "Oct'21",
                      "Nov'21",
                      "Dec'21",
                      "Jan'22",
                      "Feb'22",
                      "Mar'22",
                      "Apr'22",
                      "May'22",
                    ],
                  },
                  {
                    dataset: 'period_filter',
                    filter_id: 'period_id',
                    filter_name: 'Show Data Through',
                    period: 'Quarter',
                    selected: "Q2'22",
                    values: [
                      "Q1'20",
                      "Q2'20",
                      "Q3'20",
                      "Q4'20",
                      "Q1'21",
                      "Q2'21",
                      "Q3'21",
                      "Q4'21",
                      "Q1'22",
                      "Q2'22",
                    ],
                  },
                  {
                    dataset: 'period_filter',
                    filter_id: 'period_id',
                    filter_name: 'Show Data Through',
                    period: 'Semester',
                    selected: '2022 S2',
                    values: [
                      '2019 S2',
                      '2020 S1',
                      '2020 S2',
                      '2021 S1',
                      '2021 S2',
                      '2022 S1',
                      '2022 S2',
                    ],
                  },
                  {
                    dataset: 'specialty_filter',
                    filter_id: 'specialty',
                    filter_name: 'Specialty',
                    period: '',
                    selected: 'All Specialties',
                    values: [
                      'All Specialties',
                      'Allergy/Immunology',
                      'Anesthesiology',
                      'Cardiology',
                      'Dermatology',
                      'Emergency Medicine',
                      'Endocrinology',
                      'Gastroenterology',
                      'Geriatrics',
                      'Hematology',
                      'Hepatology',
                      'Infectious Diseases',
                      'Nephrology',
                      'Neurology',
                      'Obstetrics/Gynecology',
                      'Oncology',
                      'Ophthalmology/Optometry',
                      'Orthopedics',
                      'Otolaryngology',
                      'Pathology',
                      'Pediatrics',
                      'Physical Medicine & Rehab',
                      'Preventive Medicine',
                      'Primary Care',
                      'Psychiatry',
                      'Pulmonary',
                      'Radiology',
                      'Rheumatology',
                      'Surgery',
                      'Urology',
                    ],
                  },
                ],
                oid: '6374dfc7880e1200379132f7',
              },
              dashboard: {
                filters: [
                  {
                    dataset: 'afm_sisense_txn_volume_sub_chnl',
                    filter_id: 'timegrain',
                    filter_name: 'Time Grain',
                    period: '',
                    selected: 'Semester',
                    values: ['Semester'],
                  },
                  {
                    dataset: 'afm_sisense_txn_volume_sub_chnl',
                    filter_id: 'period_id',
                    filter_name: 'Show Data Through',
                    period: 'Month',
                    selected: "May'22",
                    values: [
                      "Jan'20",
                      "Feb'20",
                      "Mar'20",
                      "Apr'20",
                      "May'20",
                      "Jun'20",
                      "Jul'20",
                      "Aug'20",
                      "Sep'20",
                      "Oct'20",
                      "Nov'20",
                      "Dec'20",
                      "Jan'21",
                      "Feb'21",
                      "Mar'21",
                      "Apr'21",
                      "May'21",
                      "Jun'21",
                      "Jul'21",
                      "Aug'21",
                      "Sep'21",
                      "Oct'21",
                      "Nov'21",
                      "Dec'21",
                      "Jan'22",
                      "Feb'22",
                      "Mar'22",
                      "Apr'22",
                      "May'22",
                    ],
                  },
                  {
                    dataset: 'afm_sisense_txn_volume_sub_chnl',
                    filter_id: 'period_id',
                    filter_name: 'Show Data Through',
                    period: 'Quarter',
                    selected: "Q2'22",
                    values: [
                      "Q1'20",
                      "Q2'20",
                      "Q3'20",
                      "Q4'20",
                      "Q1'21",
                      "Q2'21",
                      "Q3'21",
                      "Q4'21",
                      "Q1'22",
                      "Q2'22",
                    ],
                  },
                  {
                    dataset: 'afm_sisense_txn_volume_sub_chnl',
                    filter_id: 'period_id',
                    filter_name: 'Show Data Through',
                    period: 'Semester',
                    selected: '2022 S2',
                    values: [
                      '2019 S2',
                      '2020 S1',
                      '2020 S2',
                      '2021 S1',
                      '2021 S2',
                      '2022 S1',
                      '2022 S2',
                    ],
                  },
                  {
                    dataset: 'afm_sisense_txn_volume_sub_chnl',
                    filter_id: 'specialty',
                    filter_name: 'Specialty',
                    period: '',
                    selected: 'All Specialties',
                    values: [
                      'All Specialties',
                      'Allergy/Immunology',
                      'Anesthesiology',
                      'Cardiology',
                      'Dermatology',
                      'Emergency Medicine',
                      'Endocrinology',
                      'Gastroenterology',
                      'Geriatrics',
                      'Hematology',
                      'Hepatology',
                      'Infectious Diseases',
                      'Nephrology',
                      'Neurology',
                      'Obstetrics/Gynecology',
                      'Oncology',
                      'Ophthalmology/Optometry',
                      'Orthopedics',
                      'Otolaryngology',
                      'Pathology',
                      'Pediatrics',
                      'Physical Medicine & Rehab',
                      'Preventive Medicine',
                      'Primary Care',
                      'Psychiatry',
                      'Pulmonary',
                      'Radiology',
                      'Rheumatology',
                      'Surgery',
                      'Urology',
                    ],
                  },
                ],
                oid: '63bd60d5774c1e0037d99284',
              },
              selected: false,
              title: 'Digital Pull',
            },
            {
              client_dashboard: {
                filters: [
                  {
                    dataset: 'timegrain_filter',
                    filter_id: 'timegrain',
                    filter_name: 'Time Grain',
                    period: '',
                    selected: 'Semester',
                    values: ['Semester'],
                  },
                  {
                    dataset: 'period_filter',
                    filter_id: 'period_id',
                    filter_name: 'Show Data Through',
                    period: 'Month',
                    selected: "May'22",
                    values: [
                      "Jan'20",
                      "Feb'20",
                      "Mar'20",
                      "Apr'20",
                      "May'20",
                      "Jun'20",
                      "Jul'20",
                      "Aug'20",
                      "Sep'20",
                      "Oct'20",
                      "Nov'20",
                      "Dec'20",
                      "Jan'21",
                      "Feb'21",
                      "Mar'21",
                      "Apr'21",
                      "May'21",
                      "Jun'21",
                      "Jul'21",
                      "Aug'21",
                      "Sep'21",
                      "Oct'21",
                      "Nov'21",
                      "Dec'21",
                      "Jan'22",
                      "Feb'22",
                      "Mar'22",
                      "Apr'22",
                      "May'22",
                    ],
                  },
                  {
                    dataset: 'period_filter',
                    filter_id: 'period_id',
                    filter_name: 'Show Data Through',
                    period: 'Quarter',
                    selected: "Q2'22",
                    values: [
                      "Q1'20",
                      "Q2'20",
                      "Q3'20",
                      "Q4'20",
                      "Q1'21",
                      "Q2'21",
                      "Q3'21",
                      "Q4'21",
                      "Q1'22",
                      "Q2'22",
                    ],
                  },
                  {
                    dataset: 'period_filter',
                    filter_id: 'period_id',
                    filter_name: 'Show Data Through',
                    period: 'Semester',
                    selected: '2022 S2',
                    values: [
                      '2019 S2',
                      '2020 S1',
                      '2020 S2',
                      '2021 S1',
                      '2021 S2',
                      '2022 S1',
                      '2022 S2',
                    ],
                  },
                  {
                    dataset: 'specialty_filter',
                    filter_id: 'specialty',
                    filter_name: 'Specialty',
                    period: '',
                    selected: 'All Specialties',
                    values: [
                      'All Specialties',
                      'Allergy/Immunology',
                      'Anesthesiology',
                      'Cardiology',
                      'Dermatology',
                      'Emergency Medicine',
                      'Endocrinology',
                      'Gastroenterology',
                      'Geriatrics',
                      'Hematology',
                      'Hepatology',
                      'Infectious Diseases',
                      'Nephrology',
                      'Neurology',
                      'Obstetrics/Gynecology',
                      'Oncology',
                      'Ophthalmology/Optometry',
                      'Orthopedics',
                      'Otolaryngology',
                      'Pathology',
                      'Pediatrics',
                      'Physical Medicine & Rehab',
                      'Preventive Medicine',
                      'Primary Care',
                      'Psychiatry',
                      'Pulmonary',
                      'Radiology',
                      'Rheumatology',
                      'Surgery',
                      'Urology',
                    ],
                  },
                ],
                oid: '6374dfdb880e12003791331b',
              },
              dashboard: {
                filters: [
                  {
                    dataset: 'personal_remote.csv',
                    filter_id: 'timegrain',
                    filter_name: 'Time Grain',
                    period: '',
                    selected: 'Semester',
                    values: ['Semester'],
                  },
                  {
                    dataset: 'personal_remote.csv',
                    filter_id: 'period_id',
                    filter_name: 'Show Data Through',
                    period: 'Month',
                    selected: "May'22",
                    values: [
                      "Jan'20",
                      "Feb'20",
                      "Mar'20",
                      "Apr'20",
                      "May'20",
                      "Jun'20",
                      "Jul'20",
                      "Aug'20",
                      "Sep'20",
                      "Oct'20",
                      "Nov'20",
                      "Dec'20",
                      "Jan'21",
                      "Feb'21",
                      "Mar'21",
                      "Apr'21",
                      "May'21",
                      "Jun'21",
                      "Jul'21",
                      "Aug'21",
                      "Sep'21",
                      "Oct'21",
                      "Nov'21",
                      "Dec'21",
                      "Jan'22",
                      "Feb'22",
                      "Mar'22",
                      "Apr'22",
                      "May'22",
                    ],
                  },
                  {
                    dataset: 'personal_remote.csv',
                    filter_id: 'period_id',
                    filter_name: 'Show Data Through',
                    period: 'Quarter',
                    selected: "Q2'22",
                    values: [
                      "Q1'20",
                      "Q2'20",
                      "Q3'20",
                      "Q4'20",
                      "Q1'21",
                      "Q2'21",
                      "Q3'21",
                      "Q4'21",
                      "Q1'22",
                      "Q2'22",
                    ],
                  },
                  {
                    dataset: 'personal_remote.csv',
                    filter_id: 'period_id',
                    filter_name: 'Show Data Through',
                    period: 'Semester',
                    selected: '2022 S2',
                    values: [
                      '2019 S2',
                      '2020 S1',
                      '2020 S2',
                      '2021 S1',
                      '2021 S2',
                      '2022 S1',
                      '2022 S2',
                    ],
                  },
                  {
                    dataset: 'personal_remote.csv',
                    filter_id: 'specialty',
                    filter_name: 'Specialty',
                    period: '',
                    selected: 'All Specialties',
                    values: [
                      'All Specialties',
                      'Allergy/Immunology',
                      'Anesthesiology',
                      'Cardiology',
                      'Dermatology',
                      'Emergency Medicine',
                      'Endocrinology',
                      'Gastroenterology',
                      'Geriatrics',
                      'Hematology',
                      'Hepatology',
                      'Infectious Diseases',
                      'Nephrology',
                      'Neurology',
                      'Obstetrics/Gynecology',
                      'Oncology',
                      'Ophthalmology/Optometry',
                      'Orthopedics',
                      'Otolaryngology',
                      'Pathology',
                      'Pediatrics',
                      'Physical Medicine & Rehab',
                      'Preventive Medicine',
                      'Primary Care',
                      'Psychiatry',
                      'Pulmonary',
                      'Radiology',
                      'Rheumatology',
                      'Surgery',
                      'Urology',
                    ],
                  },
                ],
                oid: '63bd610a774c1e0037d992ca',
              },
              selected: false,
              title: 'Personal Remote',
            },
            {
              client_dashboard: {
                filters: [
                  {
                    dataset: 'timegrain_filter',
                    filter_id: 'timegrain',
                    filter_name: 'Time Grain',
                    period: '',
                    selected: 'Semester',
                    values: ['Semester'],
                  },
                  {
                    dataset: 'period_filter',
                    filter_id: 'period_id',
                    filter_name: 'Show Data Through',
                    period: 'Month',
                    selected: "May'22",
                    values: [
                      "Jan'20",
                      "Feb'20",
                      "Mar'20",
                      "Apr'20",
                      "May'20",
                      "Jun'20",
                      "Jul'20",
                      "Aug'20",
                      "Sep'20",
                      "Oct'20",
                      "Nov'20",
                      "Dec'20",
                      "Jan'21",
                      "Feb'21",
                      "Mar'21",
                      "Apr'21",
                      "May'21",
                      "Jun'21",
                      "Jul'21",
                      "Aug'21",
                      "Sep'21",
                      "Oct'21",
                      "Nov'21",
                      "Dec'21",
                      "Jan'22",
                      "Feb'22",
                      "Mar'22",
                      "Apr'22",
                      "May'22",
                    ],
                  },
                  {
                    dataset: 'period_filter',
                    filter_id: 'period_id',
                    filter_name: 'Show Data Through',
                    period: 'Quarter',
                    selected: "Q2'22",
                    values: [
                      "Q1'20",
                      "Q2'20",
                      "Q3'20",
                      "Q4'20",
                      "Q1'21",
                      "Q2'21",
                      "Q3'21",
                      "Q4'21",
                      "Q1'22",
                      "Q2'22",
                    ],
                  },
                  {
                    dataset: 'period_filter',
                    filter_id: 'period_id',
                    filter_name: 'Show Data Through',
                    period: 'Semester',
                    selected: '2022 S2',
                    values: [
                      '2019 S2',
                      '2020 S1',
                      '2020 S2',
                      '2021 S1',
                      '2021 S2',
                      '2022 S1',
                      '2022 S2',
                    ],
                  },
                  {
                    dataset: 'specialty_filter',
                    filter_id: 'specialty',
                    filter_name: 'Specialty',
                    period: '',
                    selected: 'All Specialties',
                    values: [
                      'All Specialties',
                      'Allergy/Immunology',
                      'Anesthesiology',
                      'Cardiology',
                      'Dermatology',
                      'Emergency Medicine',
                      'Endocrinology',
                      'Gastroenterology',
                      'Geriatrics',
                      'Hematology',
                      'Hepatology',
                      'Infectious Diseases',
                      'Nephrology',
                      'Neurology',
                      'Obstetrics/Gynecology',
                      'Oncology',
                      'Ophthalmology/Optometry',
                      'Orthopedics',
                      'Otolaryngology',
                      'Pathology',
                      'Pediatrics',
                      'Physical Medicine & Rehab',
                      'Preventive Medicine',
                      'Primary Care',
                      'Psychiatry',
                      'Pulmonary',
                      'Radiology',
                      'Rheumatology',
                      'Surgery',
                      'Urology',
                    ],
                  },
                ],
                oid: '6374dfcf880e12003791330c',
              },
              dashboard: {
                filters: [
                  {
                    dataset: 'afm_sisense_txn_volume_sub_chnl',
                    filter_id: 'timegrain',
                    filter_name: 'Time Grain',
                    period: '',
                    selected: 'Semester',
                    values: ['Semester'],
                  },
                  {
                    dataset: 'afm_sisense_txn_volume_sub_chnl',
                    filter_id: 'period_id',
                    filter_name: 'Show Data Through',
                    period: 'Month',
                    selected: "May'22",
                    values: [
                      "Jan'20",
                      "Feb'20",
                      "Mar'20",
                      "Apr'20",
                      "May'20",
                      "Jun'20",
                      "Jul'20",
                      "Aug'20",
                      "Sep'20",
                      "Oct'20",
                      "Nov'20",
                      "Dec'20",
                      "Jan'21",
                      "Feb'21",
                      "Mar'21",
                      "Apr'21",
                      "May'21",
                      "Jun'21",
                      "Jul'21",
                      "Aug'21",
                      "Sep'21",
                      "Oct'21",
                      "Nov'21",
                      "Dec'21",
                      "Jan'22",
                      "Feb'22",
                      "Mar'22",
                      "Apr'22",
                      "May'22",
                    ],
                  },
                  {
                    dataset: 'afm_sisense_txn_volume_sub_chnl',
                    filter_id: 'period_id',
                    filter_name: 'Show Data Through',
                    period: 'Quarter',
                    selected: "Q2'22",
                    values: [
                      "Q1'20",
                      "Q2'20",
                      "Q3'20",
                      "Q4'20",
                      "Q1'21",
                      "Q2'21",
                      "Q3'21",
                      "Q4'21",
                      "Q1'22",
                      "Q2'22",
                    ],
                  },
                  {
                    dataset: 'afm_sisense_txn_volume_sub_chnl',
                    filter_id: 'period_id',
                    filter_name: 'Show Data Through',
                    period: 'Semester',
                    selected: '2022 S2',
                    values: [
                      '2019 S2',
                      '2020 S1',
                      '2020 S2',
                      '2021 S1',
                      '2021 S2',
                      '2022 S1',
                      '2022 S2',
                    ],
                  },
                  {
                    dataset: 'afm_sisense_txn_volume_sub_chnl',
                    filter_id: 'specialty',
                    filter_name: 'Specialty',
                    period: '',
                    selected: 'All Specialties',
                    values: [
                      'All Specialties',
                      'Allergy/Immunology',
                      'Anesthesiology',
                      'Cardiology',
                      'Dermatology',
                      'Emergency Medicine',
                      'Endocrinology',
                      'Gastroenterology',
                      'Geriatrics',
                      'Hematology',
                      'Hepatology',
                      'Infectious Diseases',
                      'Nephrology',
                      'Neurology',
                      'Obstetrics/Gynecology',
                      'Oncology',
                      'Ophthalmology/Optometry',
                      'Orthopedics',
                      'Otolaryngology',
                      'Pathology',
                      'Pediatrics',
                      'Physical Medicine & Rehab',
                      'Preventive Medicine',
                      'Primary Care',
                      'Psychiatry',
                      'Pulmonary',
                      'Radiology',
                      'Rheumatology',
                      'Surgery',
                      'Urology',
                    ],
                  },
                ],
                oid: '6374dfcf880e12003791330c',
              },
              selected: false,
              title: 'Direct',
            },
            {
              client_dashboard: {
                filters: [
                  {
                    dataset: 'timegrain_filter',
                    filter_id: 'timegrain',
                    filter_name: 'Time Grain',
                    period: '',
                    selected: 'Semester',
                    values: ['Semester'],
                  },
                  {
                    dataset: 'period_filter',
                    filter_id: 'period_id',
                    filter_name: 'Show Data Through',
                    period: 'Month',
                    selected: "May'22",
                    values: [
                      "Jan'20",
                      "Feb'20",
                      "Mar'20",
                      "Apr'20",
                      "May'20",
                      "Jun'20",
                      "Jul'20",
                      "Aug'20",
                      "Sep'20",
                      "Oct'20",
                      "Nov'20",
                      "Dec'20",
                      "Jan'21",
                      "Feb'21",
                      "Mar'21",
                      "Apr'21",
                      "May'21",
                      "Jun'21",
                      "Jul'21",
                      "Aug'21",
                      "Sep'21",
                      "Oct'21",
                      "Nov'21",
                      "Dec'21",
                      "Jan'22",
                      "Feb'22",
                      "Mar'22",
                      "Apr'22",
                      "May'22",
                    ],
                  },
                  {
                    dataset: 'period_filter',
                    filter_id: 'period_id',
                    filter_name: 'Show Data Through',
                    period: 'Quarter',
                    selected: "Q2'22",
                    values: [
                      "Q1'20",
                      "Q2'20",
                      "Q3'20",
                      "Q4'20",
                      "Q1'21",
                      "Q2'21",
                      "Q3'21",
                      "Q4'21",
                      "Q1'22",
                      "Q2'22",
                    ],
                  },
                  {
                    dataset: 'period_filter',
                    filter_id: 'period_id',
                    filter_name: 'Show Data Through',
                    period: 'Semester',
                    selected: '2022 S2',
                    values: [
                      '2019 S2',
                      '2020 S1',
                      '2020 S2',
                      '2021 S1',
                      '2021 S2',
                      '2022 S1',
                      '2022 S2',
                    ],
                  },
                  {
                    dataset: 'specialty_filter',
                    filter_id: 'specialty',
                    filter_name: 'Specialty',
                    period: '',
                    selected: 'All Specialties',
                    values: [
                      'All Specialties',
                      'Allergy/Immunology',
                      'Anesthesiology',
                      'Cardiology',
                      'Dermatology',
                      'Emergency Medicine',
                      'Endocrinology',
                      'Gastroenterology',
                      'Geriatrics',
                      'Hematology',
                      'Hepatology',
                      'Infectious Diseases',
                      'Nephrology',
                      'Neurology',
                      'Obstetrics/Gynecology',
                      'Oncology',
                      'Ophthalmology/Optometry',
                      'Orthopedics',
                      'Otolaryngology',
                      'Pathology',
                      'Pediatrics',
                      'Physical Medicine & Rehab',
                      'Preventive Medicine',
                      'Primary Care',
                      'Psychiatry',
                      'Pulmonary',
                      'Radiology',
                      'Rheumatology',
                      'Surgery',
                      'Urology',
                    ],
                  },
                ],
                oid: '6374dfd5774c1e0037d8b2d0',
              },
              dashboard: {
                filters: [
                  {
                    dataset: 'afm_sisense_txn_volume_sub_chnl',
                    filter_id: 'timegrain',
                    filter_name: 'Time Grain',
                    period: '',
                    selected: 'Semester',
                    values: ['Semester'],
                  },
                  {
                    dataset: 'afm_sisense_txn_volume_sub_chnl',
                    filter_id: 'period_id',
                    filter_name: 'Show Data Through',
                    period: 'Month',
                    selected: "May'22",
                    values: [
                      "Jan'20",
                      "Feb'20",
                      "Mar'20",
                      "Apr'20",
                      "May'20",
                      "Jun'20",
                      "Jul'20",
                      "Aug'20",
                      "Sep'20",
                      "Oct'20",
                      "Nov'20",
                      "Dec'20",
                      "Jan'21",
                      "Feb'21",
                      "Mar'21",
                      "Apr'21",
                      "May'21",
                      "Jun'21",
                      "Jul'21",
                      "Aug'21",
                      "Sep'21",
                      "Oct'21",
                      "Nov'21",
                      "Dec'21",
                      "Jan'22",
                      "Feb'22",
                      "Mar'22",
                      "Apr'22",
                      "May'22",
                    ],
                  },
                  {
                    dataset: 'afm_sisense_txn_volume_sub_chnl',
                    filter_id: 'period_id',
                    filter_name: 'Show Data Through',
                    period: 'Quarter',
                    selected: "Q2'22",
                    values: [
                      "Q1'20",
                      "Q2'20",
                      "Q3'20",
                      "Q4'20",
                      "Q1'21",
                      "Q2'21",
                      "Q3'21",
                      "Q4'21",
                      "Q1'22",
                      "Q2'22",
                    ],
                  },
                  {
                    dataset: 'afm_sisense_txn_volume_sub_chnl',
                    filter_id: 'period_id',
                    filter_name: 'Show Data Through',
                    period: 'Semester',
                    selected: '2022 S2',
                    values: [
                      '2019 S2',
                      '2020 S1',
                      '2020 S2',
                      '2021 S1',
                      '2021 S2',
                      '2022 S1',
                      '2022 S2',
                    ],
                  },
                  {
                    dataset: 'afm_sisense_txn_volume_sub_chnl',
                    filter_id: 'specialty',
                    filter_name: 'Specialty',
                    period: '',
                    selected: 'All Specialties',
                    values: [
                      'All Specialties',
                      'Allergy/Immunology',
                      'Anesthesiology',
                      'Cardiology',
                      'Dermatology',
                      'Emergency Medicine',
                      'Endocrinology',
                      'Gastroenterology',
                      'Geriatrics',
                      'Hematology',
                      'Hepatology',
                      'Infectious Diseases',
                      'Nephrology',
                      'Neurology',
                      'Obstetrics/Gynecology',
                      'Oncology',
                      'Ophthalmology/Optometry',
                      'Orthopedics',
                      'Otolaryngology',
                      'Pathology',
                      'Pediatrics',
                      'Physical Medicine & Rehab',
                      'Preventive Medicine',
                      'Primary Care',
                      'Psychiatry',
                      'Pulmonary',
                      'Radiology',
                      'Rheumatology',
                      'Surgery',
                      'Urology',
                    ],
                  },
                ],
                oid: '63bd60f9880e120037924132',
              },
              selected: false,
              title: 'Peer Interaction',
            },
          ],
          title: 'Detailed Benchmarks',
        },
        {
          client_dashboard: { filters: [], oid: '' },
          dashboard: {
            filters: [
              {
                dataset: 'cov_stats_highlights',
                filter_id: 'period_id',
                filter_name: 'Semester',
                period: '',
                selected: '2022 S2',
                values: ['2020 S1', '2020 S2', '2021 S1', '2021 S2', '2022 S1', '2022 S2'],
              },
            ],
            oid: '635903f0b0c4db0037b2b2d5',
          },
          selected: false,
          title: 'Coverage Statistics',
        },
      ],
      status: 'SUCCESS',
    };


    const get_download_use_cases = {
      url: 'https:'
    };

    const launch_bar = {
      applications: [
        { name: 'Affinity Monitor', title: '', url: 'https://aftm.stage.zsservices.com/' },
      ],
      expiry: {},
      myAccount:
        'https://idm.staging.zsservices.com/Affinitymonitor/IdentityManager/app/Web/MyAccount.aspx',
      signOut: 'https://aftm.stage.zsservices.com/api/logout',
    };

    const get_automation_mapping_response = {
      customer_mapping_data: {
        count_values: { client_count: 4719, total_count: 4719, unique_count: 4719 },
        table_data: {
          tbody: [
            {
              CLIENT: '1074',
              CLIENT_SPEC: 'FM',
              CLIENT_SPEC_DESC: 'PCP',
              'Unnamed: 0': 0,
              ZS_SPEC: 'UNK',
              ZS_SPEC_DESC: 'UKNOWN',
              match_flag: 2,
            },
            {
              CLIENT: '0149',
              CLIENT_SPEC: 'OTH',
              CLIENT_SPEC_DESC: 'ORTHOPEDICSURGERYORS',
              'Unnamed: 0': 1,
              ZS_SPEC: 'SUR',
              ZS_SPEC_DESC: 'SURGERY',
              match_flag: 2,
            },
            {
              CLIENT: '1074',
              CLIENT_SPEC: 'DR',
              CLIENT_SPEC_DESC: 'ALLOTHER',
              'Unnamed: 0': 2,
              ZS_SPEC: 'UNK',
              ZS_SPEC_DESC: 'UKNOWN',
              match_flag: 2,
            },
            {
              CLIENT: '0149',
              CLIENT_SPEC: 'CARD',
              CLIENT_SPEC_DESC: 'CARDIOVASCULARDISEASECD',
              'Unnamed: 0': 3,
              ZS_SPEC: 'CRD',
              ZS_SPEC_DESC: 'CARDIOLOGY',
              match_flag: 2,
            },
            {
              CLIENT: '1074',
              CLIENT_SPEC: 'PMD',
              CLIENT_SPEC_DESC: 'ALLOTHER',
              'Unnamed: 0': 4,
              ZS_SPEC: 'UNK',
              ZS_SPEC_DESC: 'UKNOWN',
              match_flag: 2,
            },
            {
              CLIENT: '1074',
              CLIENT_SPEC: 'VET',
              CLIENT_SPEC_DESC: 'ALLOTHER',
              'Unnamed: 0': 5,
              ZS_SPEC: 'UNK',
              ZS_SPEC_DESC: 'UKNOWN',
              match_flag: 2,
            },
            {
              CLIENT: '0153',
              CLIENT_SPEC: 'ACA',
              CLIENT_SPEC_DESC: 'ADULTCARIOTHORACICANESTHESIOLOGY',
              'Unnamed: 0': 6,
              ZS_SPEC: 'ANE',
              ZS_SPEC_DESC: 'ANESTHESIOLOGY',
              match_flag: 2,
            },
            {
              CLIENT: '0186',
              CLIENT_SPEC: 'ONM',
              CLIENT_SPEC_DESC: 'OSTEOPATHICNEUROMUSCULARMEDICINE',
              'Unnamed: 0': 7,
              ZS_SPEC: 'PCP',
              ZS_SPEC_DESC: 'PRIMARYCARE',
              match_flag: 2,
            },
            {
              CLIENT: '0149',
              CLIENT_SPEC: 'OTH',
              CLIENT_SPEC_DESC: 'UROLOGYU',
              'Unnamed: 0': 8,
              ZS_SPEC: 'URO',
              ZS_SPEC_DESC: 'UROLOGY',
              match_flag: 2,
            },
            {
              CLIENT: '1074',
              CLIENT_SPEC: 'OTO',
              CLIENT_SPEC_DESC: 'ALLOTHER',
              'Unnamed: 0': 9,
              ZS_SPEC: 'UNK',
              ZS_SPEC_DESC: 'UKNOWN',
              match_flag: 2,
            },
          ],
          thead: [
            'Unnamed: 0',
            'CLIENT',
            'CLIENT_SPEC',
            'CLIENT_SPEC_DESC',
            'ZS_SPEC_DESC',
            'ZS_SPEC',
            'match_flag',
          ],
        },
      },
      download_url:
        'https://api.aftm.dev.zsservices.com/download_automation_mapping_file?file_path=AFM_Mapping_Automation/Specialty_Mapping/Input/AfM_Specialty_Mapping_Output_2023-04-03_080925.csv',
      manual_review_data: {
        count_values: {
          upload_client_count: '',
          upload_total_count: '',
          upload_unique_afm_ss: '',
          upload_unique_count: '',
        },
        table_data: {
          tbody: [
            {
              CLIENT: '1074',
              CLIENT_SPEC: 'FM',
              CLIENT_SPEC_DESC: 'PCP',
              Unnamed: 0,
              ZS_SPEC: 'UNK',
              ZS_SPEC_DESC: 'UKNOWN',
              match_flag: 2,
            },
          ],
          thead: [
            'Unnamed',
            'CLIENT',
            'CLIENT_SPEC',
            'CLIENT_SPEC_DESC',
            'ZS_SPEC_DESC',
            'ZS_SPEC',
            'match_flag',
          ],
        },
      },
      status: 'SUCCESS',
    };

    const user_upload_automation_mapping = {
      status: "SUCCESS",
      Message: "Count Doesn't match",
      count_values: {
        upload_total_count: 12,
        upload_unique_count: 20,
        upload_unique_afm_ss: 3,
        upload_client_count: 11
      },
      table_data: {
        tbody: [
          {
            test_a: "test_value",
            test_b: "test_value"
          },
          {
            test_a: "test_value1",
            test_b: "test_value1"
          },
          {
            test_a: "test_value2",
            test_b: "test_value2"
          },
          {
            test_a: "test_value3",
            test_b: "test_value3"
          }
        ],
        thead: [
          "test_a",
          "test_b"
        ]
      }
    }
    const validate_client_mail_id = {
      
    }

    const submit_automation_mapping_response = {
      status: "SUCCESS",
      Message: "Count Doesn't match",
    }

    return {
      get_download_reports,
      download_report,
      get_activity_logs,
      get_template_data,
      save_upload_data,
      get_upload_data,
      delete_upload_data,
      get_faqs_data,
      get_subscription_data,
      submit_affex_data,
      affex_status,
      parameter_checks,
      add_custom_program,
      get_custom_programs,
      get_data_changes,
      get_user_details,
      get_review_data_changes,
      import_portal_data,
      get_program_approval_details,
      export_custom_program_data,
      submit_data_changes_review,
      submit_custom_program_review,
      get_presigned_url,
      get_file_data,
      get_dashboard_url,
      get_dashboard_ids,
      delimiter_checks,
      get_pending_count,
      get_steward_data,
      get_steward_review_details,
      sample_data_preview,
      profiling_statistics_preview,
      submit_steward_review,
      get_file_tracking_log,
      get_process_details,
      get_programs_data,
      get_program_review_details,
      submit_program_review,
      get_add_program_details,
      add_standard_program,
      get_filter_data,
      get_drawer_content,
      get_download_use_cases,
      launch_bar,
      get_automation_mapping_response,
      user_upload_automation_mapping,
      validate_client_mail_id,
      submit_automation_mapping_response
    };
  }
}
