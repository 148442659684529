<nz-layout>
  <nz-content>
    <div class="space-align-container">
      <span nz-typography class="tab-header">Activity Files Mapping</span>
    </div>
    <div>
        <ng-container>
          <ul nz-menu nzMode="horizontal">
            <ng-container *ngFor="let item of menuItems">
              <li class="affinity-menu-item" nz-menu-item [nzSelected]="item.isSelected" (click)="switchTab(item.name)">
                {{ item.name }}
              </li>
            </ng-container>
          </ul>
        </ng-container>
    </div>
    <app-header-content
        [totalCount]="totalCount"
        [uniqueCount]="uniqueCount"
        [clientCount]="clientCount"
        [AFMCount]="AFMCount"
        [standardDispositions]="standardDispositions"
        [downloadUrl]="downloadUrl"
        (uploadData)="getUploadStats($event)"
        [type]="payload.type"
    ></app-header-content>
    <app-mapping-table
        [headers]="headers"
        [fileData]="fileData"
    ></app-mapping-table>
    <app-mapping-table
        [headers]="feedbackHeaders"
        [fileData]="feedbackFileData"
        customClass ="automation-mapping"
    ></app-mapping-table>
    <div class="manual-review" *ngIf="uploadStats.tableData.tbody.length > 0">
        <hr>
        <div class="manual-title">
            <strong>MANUAL REVIEW - FEEDBACK</strong>
        </div>
        <app-header-content
            [totalCount]="uploadStats.countValues.uploadTotalCount"
            [uniqueCount]="uploadStats.countValues.uploadUniqueCount"
            [clientCount]="uploadStats.countValues.uploadClientCount"
            [AFMCount]="uploadStats.countValues.uploadAFMCount"
            [standardDispositions]="standardDispositions"
            [isSubmit]="true"
            [type]="payload.type"
        ></app-header-content>
        <app-mapping-table
            [headers]="uploadStats.tableData.thead"
            [fileData]="uploadStats.tableData.tbody"
        ></app-mapping-table>
        <app-mapping-table
        [headers]="uploadStats.feedbackTableData?.thead"
        [fileData]="uploadStats.feedbackTableData?.tbody"
        customClass ="automation-mapping"
    ></app-mapping-table>
    </div>
  </nz-content>
</nz-layout>
