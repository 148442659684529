<div class="review-wrapper">
  <nz-modal
    nzTitle="Review Program"
    [(nzVisible)]="showReviewModal"
    [nzWidth]="'624px'"
    [nzWrapClassName]="'modal-wrapper'"
    [nzMaskClosable]="false"
    [nzFooter]="footer"
    (nzOnCancel)="onCancel()"
  >
    <ng-container *nzModalContent>
      <!--      Reason screen not displayed-->
      <div class="review-content" *ngIf="!showReasonScreen">
        <!--        Header-->
        <div class="data-heading">
          Please review the program name, channel name, and data layout file provided and make
          modifications if necessary.
        </div>
        <!--        Loader-->
        <div class="spin" *ngIf="loading"><nz-spin nzSimple></nz-spin></div>
        <!--        Content when not Loading-->
        <div *ngIf="!loading" class="main-data-wrapper">
          <!--          Error message-->
          <div class="error-message" *ngIf="showErrorMessage()">
            {{ errorMessage }}
          </div>
          <!--          Main Data-->
          <div *ngIf="!loading">
            <div class="inner-data">
              <div class="data-header">Program</div>
              <div *ngIf="!editProgramClicked">
                <div *ngIf="!loading">
                  <span [ngClass]="originalProgramEditted || programEditted ? 'italic-typo' : ''">{{
                    program
                  }}</span>
                  <span class="edit-icon"
                    ><i
                      nz-icon
                      nzType="edit"
                      nzTheme="outline"
                      (click)="editProgramClicked = true"
                    ></i
                  ></span>
                </div>
              </div>
              <div *ngIf="editProgramClicked">
                <input
                  nz-input
                  [ngClass]="isValidProgram && validProgramChar ? 'input' : 'error-input'"
                  [(ngModel)]="program"
                  (keyup)="checkProgram()"
                />
                <span *ngIf="checkProgram()" (click)="saveProgram()" class="save-button">Save</span>
                <span *ngIf="!checkProgram()" class="save-button-disabled">Save</span>
                <div class="error" *ngIf="!isValidProgram">
                  Program Title length must be 50 characters or less. Please enter a valid input.
                </div>
                <div class="error" *ngIf="!validProgramChar">
                  \ , / , * , ? , : , [ , ] are not allowed in the program name.
                </div>
              </div>
            </div>
            <div class="inner-data">
              <div class="data-header">Channel</div>
              <div *ngIf="!editChannelClicked">
                <div *ngIf="!loading">
                  <span [ngClass]="originalChannelEditted || channelEditted ? 'italic-typo' : ''">{{
                    channel
                  }}</span>
                  <span class="edit-icon"
                    ><i
                      nz-icon
                      nzType="edit"
                      nzTheme="outline"
                      (click)="editChannelClicked = true"
                    ></i
                  ></span>
                </div>
              </div>
              <div *ngIf="editChannelClicked">
                <app-dropdown
                  [isMultiple]="false"
                  [isSearchable]="true"
                  [data]="channelData"
                  [defaultSelected]="channel"
                  (selectedValue)="channelSelected($event)"
                ></app-dropdown>
                <span *ngIf="channel" (click)="saveChannel()" class="save-button"> Save </span>
                <span *ngIf="!channel" class="save-button-disabled"> Save </span>
              </div>
            </div>
            <div class="inner-data">
              <div class="data-header">Program Type</div>
              <div>{{ programType }}</div>
            </div>
            <div class="inner-data" *ngIf="programType === 'Custom'">
              <div class="data-header">Client</div>
              <div>{{ client }}</div>
            </div>
            <div class="inner-data">
              <div class="data-header">Select Product</div>
              <div *ngIf="!loading">
                <div>
                  <nz-checkbox-group
                    [(ngModel)]="productOptions"
                    (ngModelChange)="productSelected(productOptions)"
                  ></nz-checkbox-group>
                </div>
              </div>
            </div>
            <div class="inner-data">
              <div class="data-header">Data Layout File Uploaded</div>
              <div *ngIf="!loading">
                <div class="layout-filename" (click)="downloadFile(layoutFileUrl, '')">
                  <i nz-icon nzType="file" nzTheme="fill" class="file-icon"></i>
                  <span [ngClass]="layoutFileEditted ? 'italic-typo' : ''">{{
                    layoutFileName
                  }}</span>
                </div>
              </div>
            </div>
            <div class="inner-data">
              <div class="data-header">Upload updated data layout file <span>(optional)</span></div>
              <div class="upload-description">
                Please upload a data layout/template in .csv format.
              </div>
              <div>
                <nz-upload
                  nzType="drag"
                  *ngIf="fileList.length <= 0"
                  [nzFileListRender]="fileListRef"
                  (nzChange)="fileChanged($event)"
                  [(nzFileList)]="nzFileList"
                  [nzMultiple]="false"
                >
                  <p class="ant-upload-text">
                    Drag a file here to upload or
                    <button nz-button nzType="primary" class="app-primary-button">
                      <span class="choose-file">Choose File</span>
                    </button>
                  </p>
                  <ng-template #fileListRef> </ng-template>
                </nz-upload>

                <div *ngIf="fileList.length > 0">
                  <div class="progress-wrapper" *ngIf="isInprogress">
                    <div class="file-name">
                      <i nz-icon nzType="cloud-upload" nzTheme="outline"></i>&nbsp;
                      <span class="file-name"
                        ><strong>{{ fileList[0].name }}</strong></span
                      >
                    </div>
                    <div class="progress-bar">
                      <nz-progress
                        nzStrokeLinecap="square"
                        nzStrokeColor="#2B44C7"
                        [nzPercent]="uploadPercent"
                      ></nz-progress>
                    </div>
                  </div>
                  <div *ngIf="!isInprogress && !showUploadError; else inValidFile">
                    <div nz-row class="title-row file-list">
                      <div nz-col nzSpan="22" class="title">
                        <i nz-icon nzType="check-circle" nzTheme="fill" class="check-icon"></i>
                        <span class="file-name">{{ fileList[0].name }}</span>
                      </div>
                      <div nz-col nzSpan="1" class="link delete-icon" (click)="deleteFile(true)">
                        <i nz-icon nzType="delete" nzTheme="outline"></i>
                      </div>
                    </div>
                  </div>
                  <ng-template #inValidFile>
                    <div *ngIf="!isInprogress" nz-row class="title-row file-error">
                      <div nz-col nzSpan="22" class="title">
                        <span class="file-name">{{ fileList[0].name }}</span>
                      </div>
                      <div nz-col nzSpan="1" class="link delete-icon" (click)="deleteFile(false)">
                        <i nz-icon nzType="delete" nzTheme="outline"></i>
                      </div>
                    </div>
                  </ng-template>
                  <div class="error-message" *ngIf="showUploadError">
                    <div>The file type cannot be accepted. Please ensure the file is in .csv</div>
                  </div>
                </div>
              </div>
            </div>
          </div>
        </div>
      </div>
      <!--       Reason screen displayed-->
      <div *ngIf="showReasonScreen">
        <div class="reason-wrapper">
          <div class="data-header">{{ reasonHeader }}</div>
          <div>
            <textarea
              nz-input
              [(ngModel)]="reason"
              (keyup)="checkReason()"
              [placeholder]="placeHolder"
              [nzAutosize]="{ minRows: 8, maxRows: 8 }"
              [ngClass]="isValidReason ? 'text' : 'error-textarea'"
              (keydown)="disableEnter($event)"
            ></textarea>
          </div>
          <div class="error" *ngIf="!isValidReason">
            The input value for this field requires 2000 characters. Please enter valid input.
          </div>
        </div>
      </div>
    </ng-container>
  </nz-modal>
</div>

<ng-template #footer>
  <!--  buttons for original screen-->
  <nz-space *ngIf="!showReasonScreen">
    <!--    cancel button-->
    <div *nzSpaceItem class="cancel-button" (click)="onCancel()">Cancel</div>
    <!--    main button-->
    <div *nzSpaceItem>
      <button
        nz-button
        [nzType]="'default'"
        [disabled]="disableButton('main')"
        (click)="buttonClicked(buttonText)"
      >
        <i
          nz-icon
          nzType="minus-circle"
          nzTheme="fill"
          *ngIf="buttonText === 'Retire Program' || buttonText === 'Cancel Request' || buttonText === 'Reject Request'"
        ></i>
        <i
          nz-icon
          nzType="rollback"
          nzTheme="outline"
          *ngIf="buttonText === 'Reinstate Program'"
        ></i>
        {{ buttonText }}
      </button>
    </div>
    <!--    save button-->
    <div *nzSpaceItem>
      <button
        nz-button
        *ngIf="role === 'admin' || programStatus === 'Active'  || programStatus === 'Retired'"
        [nzType]="'primary'"
        [ngClass]="disableButton('save') ? 'app-primary-button-disabled' : 'app-primary-button'"
        [disabled]="disableButton('save')"
        (click)="saveChanges()"
      >
        <i nz-icon nzType="check" nzTheme="outline"></i> Save Changes
      </button>
      <button
        nz-button
        *ngIf="(programStatus === 'Active-Pending'  || programStatus === 'Retired-Pending') && role === 'manager'"
        [nzType]="'primary'"
        [ngClass]="disableButton('main') ? 'app-primary-button-disabled' : 'app-primary-button'"
        [disabled]="disableButton('main')"
        (click)="approveRequest()"
      >
        <i nz-icon nzType="check" nzTheme="outline"></i> Approve Request
      </button>
    </div>
  </nz-space>
  <!--  buttons for reason screen-->
  <nz-space *ngIf="showReasonScreen">
    <!--    back button-->
    <div *nzSpaceItem>
      <button nz-button [nzType]="'default'" (click)="backClicked()">Back</button>
    </div>
    <!--    submit button-->
    <div *nzSpaceItem>
      <button
        nz-button
        [nzType]="'primary'"
        [ngClass]="!reason || !isValidReason ? 'app-primary-button-disabled' : 'app-primary-button'"
        [disabled]="!reason || !isValidReason"
        (click)="submitRequest()"
      >
        {{reasonButton}}
      </button>
    </div>
  </nz-space>
</ng-template>
