<nz-layout>
  <nz-content>
    <div nz-row>
      <div class="space-align-container">
        <div nz-row>
          <div nz-col nzFlex="160px" class="upload-text">
            <span nz-typography class="tab-header">Upload Data</span>
          </div>
          <div nz-col nzFlex="auto" class="status-wrapper" *ngIf="!isLoading">
            <span class="status-label"
              ><strong>Status: </strong>
              <span *ngIf="status === 'open'; else close">
                <i nz-icon nzType="check-circle" nzTheme="fill" class="check-icon"></i> Open
              </span>
              <ng-template #close>
                <i nz-icon nzType="close-circle" nzTheme="fill" class="close-icon"></i> Closed
              </ng-template>
            </span>
            <span class="status-label">
              <span *ngIf="status === 'open'">
                <strong>Cycle:</strong> {{ currentCycle }}
              </span>
            </span>
            <span class="status-label">
              <span *ngIf="status === 'open'; else nextSem">
                <strong>Window Closes:</strong> {{ windowCloses }} EST
              </span>
              <ng-template #nextSem>
                <strong>Next Cycle:</strong> {{ nextCycle === 'N/A' ? 'N/A' : nextCycle + ' EST'}}
              </ng-template>
            </span>
            <span class="status-label">
              <span *ngIf="status === 'open'; else connect">
                <strong>Time Left:</strong> {{ daysLeft }} Days {{ hoursLeft }} Hours {{ minutesLeft }} Minutes
              </span>
              <ng-template #connect>
                <span class="reachout"
                  >&nbsp; Reach out to AffinityMonitor Team before uploading any data &nbsp;</span
                >
                <a
                  href="mailto:support@zs.com?subject = Feedback&body = Message"
                  class="mail-icon"
                  ><i nz-icon nzType="mail" nzTheme="outline"></i
                ></a>
              </ng-template>
              <app-simple-dropdown
                *ngIf="isAdmin"
                [showEditIcon]="true"
                [title]="'Edit Upload Window'"
                [page]="'upload-data'"
              ></app-simple-dropdown>
            </span>
          </div>
          <div nz-col nzFlex="auto" class="button-container" *ngIf="!isAdmin">
            <a (click)="showTemplates = true">View Templates</a>
            <button
              nz-button
              nzType="primary"
              class="app-primary-button upload-button"
              (click)="uploadFile = true; mode = 'save'"
            >
            <!-- TODO: add this to above button if button is to be enabled/disabled based on cycle
               [ngClass]="status === 'open' ? '' : 'disable'" -->
              <i nz-icon nzType="plus" nzTheme="outline" class="plus-icon"></i>
              Upload File
            </button>
          </div>
          <!--          TODO: update the legend once confirmed with client-->
          <!--                    <div>-->
          <!--                      <p>-->
          <!--                       <span-->
          <!--                        class="card under-verification"-->
          <!--                      >-->
          <!--                        <i nz-icon nzType="reload" nzTheme="outline"></i> Under verification-->
          <!--                      </span>-->
          <!--                        <span class="legend-details">&nbsp;Verifying data against expected format and template layout</span>-->
          <!--                      <span class="card success">-->
          <!--                        <i nz-icon nzType="check-circle" nzTheme="fill"></i> Success-->
          <!--                      </span>-->
          <!--                        <span class="legend-details">&nbsp;Data uploaded successfully, we will contact you in case of further processing issues</span>-->
          <!--                      </p>-->
          <!--                      <p>-->
          <!--                        <span class="card incomplete">-->
          <!--                        <i nz-icon nzType="question-circle" nzTheme="fill"></i> Incomplete-->
          <!--                      </span>-->
          <!--                        <span class="legend-details">&nbsp;Incomplete data feed pending further information</span>-->
          <!--                      <span class="card issues">-->
          <!--                        <i nz-icon nzType="close-circle" nzTheme="fill"></i> Issues-->
          <!--                      </span>-->
          <!--                        <span class="legend-details">&nbsp;Uploaded data does not match expected format or template layout and/or has other issues</span>-->
          <!--                      </p>-->
          <!--                    </div>-->
        </div>
      </div>
    </div>
    <div nz-row *ngIf="!isAdmin">
      <div class="dropdown-container">
        <div nz-row [nzGutter]="[14, 0]">
          <div nz-col nzFlex="160px">
            <app-dropdown
              [data]="sortBy"
              [label]="'Sort By'"
              [size]="'large'"
              [(ngModel)]="payload.filter_data.sort_by"
              (selectedValue)="updatePayload($event, 'sort_by')"
              [placeHolder]="'Latest to Oldest'"
              ngDefaultControl
            ></app-dropdown>
          </div>
          <div nz-col nzFlex="160px">
            <app-dropdown
              [data]="channel"
              [label]="'Channel'"
              [size]="'large'"
              (selectedValue)="updatePayload($event, 'channel')"
              [isMultiple]="true"
              [isSearchable]="true"
              [placeHolder]="'All'"
              nz-popover=""
              [nzPopoverContent]="
                payload.filter_data.channel.length > 0 ? payload.filter_data.channel : ['All']
              "
            ></app-dropdown>
          </div>
          <div nz-col nzFlex="160px">
            <app-dropdown
              [data]="program"
              [label]="'Program'"
              [size]="'large'"
              (selectedValue)="updatePayload($event, 'program')"
              [isMultiple]="true"
              [isSearchable]="true"
              [placeHolder]="'All'"
              nz-popover=""
              [nzPopoverContent]="
                payload.filter_data.program.length > 0 ? payload.filter_data.program : ['All']
              "
            ></app-dropdown>
          </div>
          <div nz-col nzFlex="160px">
            <app-dropdown
              [data]="fileType"
              [label]="'File Type'"
              [size]="'large'"
              (selectedValue)="updatePayload($event, 'file_type')"
              [isMultiple]="true"
              [placeHolder]="'All'"
              nz-popover=""
              [nzPopoverContent]="
                payload.filter_data.file_type.length > 0 ? payload.filter_data.file_type : ['All']
              "
            ></app-dropdown>
          </div>
          <div nz-col nzFlex="160px">
            <app-dropdown
              [data]="statusOption"
              [label]="'Status'"
              [size]="'large'"
              (selectedValue)="updatePayload($event, 'status')"
              [isMultiple]="true"
              [placeHolder]="'All'"
              nz-popover=""
              [nzPopoverContent]="
                payload.filter_data.status.length > 0 ? payload.filter_data.status : ['All']
              "
            ></app-dropdown>
          </div>
          <div nz-col nzFlex="160px">
            <br />
            <app-search-bar
              [searchParameter]="searchData"
              (searchValue)="filterOnSearch($event)"
              (noResultFound)="formatOnNoresult($event)"
              (noInput)="resetSearch($event)"
              (resetSearch)="resetSearch($event)"
              (inputKey)="filterOnSearch($event)"
            >
            </app-search-bar>
          </div>
        </div>
      </div>
    </div>
  </nz-content>

  <div class="spinner" *ngIf="isLoading && !isAdmin">
    <nz-spin nzSimple></nz-spin>
  </div>

  <div *ngIf="!isLoading && !isAdmin">
    <div class="no-data" *ngIf="(!uploadedFiles || uploadedFiles.length <= 0) && !noResultsFound">
      <p>No files uploaded yet.</p>
    </div>
    <div class="no-data" *ngIf="noResultsFound">
      <p>Your search returned no results</p>
    </div>
    <div *ngIf="uploadedFiles.length >= 1">
      <ng-container *ngFor="let uploadedFile of uploadedFiles">
        <div nz-row class="upload-row" *ngIf="uploadedFile">
          <div nz-col nzSpan="8" class="upload-column col-1">
            <p class="card-titles" *ngIf="uploadedFile.files && uploadedFile.files.length <= 1">
              File Title
            </p>
            <p class="card-titles" *ngIf="uploadedFile.files && uploadedFile.files.length > 1">
              Data Set Title
            </p>
            <p
              class="card-content-1"
              nz-popover
              nzPopoverContent="{{ uploadedFile.file_info.file_title }}"
              *ngIf="uploadedFile.file_info.file_title != ''"
            >
              {{ uploadedFile.file_info.file_title }}
            </p>
            <p
              class="card-content-1"
              nz-popover
              nzPopoverContent="{{ uploadedFile.file_info.file_title }}"
              *ngIf="uploadedFile.file_info.file_title == ''"
            >
              -
            </p>
            <p class="card-titles">File Upload</p>
            <div
              *ngIf="
                uploadedFile.files &&
                uploadedFile.files.length > 0 &&
                getFilenames(uploadedFile.files)
              "
            >
              <div class="uploaded-file-display">
                <p
                  class="card-content-1 uploaded-file"
                  nz-popover
                  nzPopoverContent="{{ getFilenames(uploadedFile.files) }}"
                >
                  <span
                    class="uploaded-file-num"
                    *ngIf="uploadedFile.files && uploadedFile.files.length > 1"
                  >
                    {{ uploadedFile.files.length }}
                  </span>
                  <span
                    *ngIf="uploadedFile.files && uploadedFile.files.length === 1"
                    class="uploaded-file-success"
                  >
                    <i nz-icon nzType="check-circle" nzTheme="fill"></i>
                  </span>
                  <span class="uploaded-file-data">{{ getFilenames(uploadedFile.files) }}</span>
                </p>
                <i nz-icon nzType="eye" nzTheme="fill" class="uploaded-file-icon" (click)="showPreviewPopup(uploadedFile.files)"></i>
              </div>
            </div>
            <div *ngIf="!uploadedFile.files || !getFilenames(uploadedFile.files)">
              No File Uploaded
            </div>
          </div>
          <div nz-col nzSpan="8" class="upload-column col-2">
            <div class="card-body">
              <p class="card-titles">Channels & Programs</p>
              <span class="program-list-wrapper">
                <span
                  class="program-data"
                  *ngFor="let programData of uploadedFile.files; let i = index"
                >
                  <span
                    *ngIf="
                      !getCustomProgram(programData.custom_program_id) &&
                      programData.program &&
                      (uploadedFile.optionId !== 2 && i < 4) ||
                      (uploadedFile.optionId === 2 && i < 1)
                    "
                    [ngClass]="getClass(programData.channelId)"
                    [title]="programData.program"
                  >
                    <span *ngIf="programData.channelId === 1" class="template-icons">
                      <i nz-icon nzType="user" nzTheme="outline" class="temp-1"></i>
                    </span>
                    <span *ngIf="programData.channelId === 2" class="template-icons">
                      <i nz-icon nzType="shopping" nzTheme="fill" class="temp-2"></i>
                    </span>
                    <span *ngIf="programData.channelId === 3" class="template-icons">
                      <i nz-icon nzType="team" nzTheme="outline" class="temp-3"></i>
                    </span>
                    <span *ngIf="programData.channelId === 4" class="template-icons">
                      <i nz-icon nzType="import" nzTheme="outline" class="temp-4"></i>
                    </span>
                    <span *ngIf="programData.channelId === 5" class="template-icons">
                      <i nz-icon nzType="cloud-download" nzTheme="outline" class="temp-5"></i>
                    </span>
                    <span *ngIf="programData.channelId === 6" class="template-icons">
                      <i nz-icon nzType="mail" nzTheme="outline" class="temp-6"></i>
                    </span>
                    <span *ngIf="programData.channelId === 7" class="template-icons">
                      <i nz-icon nzType="solution" nzTheme="outline" class="temp-7"></i>
                    </span>
                    <span *ngIf="programData.channelId === 8" class="template-icons">
                      <i nz-icon nzType="dollar" nzTheme="outline" class="temp-8"></i>
                    </span>
                    {{ programData.program }}
                  </span>
                  <span
                    *ngIf="getCustomProgram(programData.custom_program_id)"
                    class="none"
                    [title]="programData.program"
                  >
                    <span class="template-icons">
                      <i nz-icon nzType="clock-circle" nzTheme="fill"></i>
                    </span>

                    {{ programData.program }}
                  </span>
                </span>
                <span *ngIf="uploadedFile.files.length > 4">
                  <span
                    class="custom-circle"
                    nz-popover
                    nzPopoverTitle="Channels / Programs"
                    [nzPopoverContent]="programList"
                    nzPopoverPlacement="rightTop"
                  >
                    +
                    {{ uploadedFile.files.length - 4 }}
                  </span>

                  <ng-template #programList>
                    <div class="popover-content-wrapper">
                      <span *ngFor="let channel of uploadedFile.files; let i = index">
                        <span *ngIf="i > 3">
                          <div [ngClass]="getClass(channel.channelId)">
                            <span
                              *ngIf="
                                channel.channelId === 1 &&
                                !getCustomProgram(channel.custom_program_id)
                              "
                              class="template-icons"
                            >
                              <i nz-icon nzType="user" nzTheme="outline" class="temp-1"></i>
                            </span>
                            <span
                              *ngIf="
                                channel.channelId === 2 &&
                                !getCustomProgram(channel.custom_program_id)
                              "
                              class="template-icons"
                            >
                              <i nz-icon nzType="shopping" nzTheme="fill" class="temp-2"></i>
                            </span>
                            <span
                              *ngIf="
                                channel.channelId === 3 &&
                                !getCustomProgram(channel.custom_program_id)
                              "
                              class="template-icons"
                            >
                              <i nz-icon nzType="team" nzTheme="outline" class="temp-3"></i>
                            </span>
                            <span
                              *ngIf="
                                channel.channelId === 4 &&
                                !getCustomProgram(channel.custom_program_id)
                              "
                              class="template-icons"
                            >
                              <i nz-icon nzType="import" nzTheme="outline" class="temp-4"></i>
                            </span>
                            <span
                              *ngIf="
                                channel.channelId === 5 &&
                                !getCustomProgram(channel.custom_program_id)
                              "
                              class="template-icons"
                            >
                              <i
                                nz-icon
                                nzType="cloud-download"
                                nzTheme="outline"
                                class="temp-5"
                              ></i>
                            </span>
                            <span
                              *ngIf="
                                channel.channelId === 6 &&
                                !getCustomProgram(channel.custom_program_id)
                              "
                              class="template-icons"
                            >
                              <i nz-icon nzType="mail" nzTheme="outline" class="temp-6"></i>
                            </span>
                            <span
                              *ngIf="
                                channel.channelId === 7 &&
                                !getCustomProgram(channel.custom_program_id)
                              "
                              class="template-icons"
                            >
                              <i nz-icon nzType="solution" nzTheme="outline" class="temp-7"></i>
                            </span>
                            <span
                              *ngIf="
                                channel.channelId === 8 &&
                                !getCustomProgram(channel.custom_program_id)
                              "
                              class="template-icons"
                            >
                              <i nz-icon nzType="dollar" nzTheme="outline" class="temp-8"></i>
                            </span>
                            <span
                              *ngIf="getCustomProgram(channel.custom_program_id)"
                              class="template-icons"
                            >
                              <i nz-icon nzType="clock-circle" nzTheme="fill"></i>
                            </span>
                            {{ channel.program }} <br />
                          </div>
                        </span>
                      </span>
                    </div>
                  </ng-template>
                </span>
              </span>
              <span *ngIf="programsSelected(uploadedFile.files)">
                No Channels & Programs selected
              </span>
            </div>

            <div class="card-footer">
              <div nz-row class="card-footer-inner">
                <div nz-col nzLg="24" nzSm="24" nzMd="24" nzXl="12">
                  <span class="footer-titles">Uploaded On</span>&nbsp;
                  <span class="sent-on">{{ getDate(uploadedFile.sent_on, true) }}</span>
                </div>
                <div nz-col nzLg="24" nzSm="24" nzMd="24" nzXl="12">
                  <span class="footer-status"> Status </span>
                  <span class="status-data">
                    <span
                      class="card under-verification"
                      *ngIf="uploadedFile.status == 'Under Verification'"
                    >
                      <i nz-icon nzType="reload" nzTheme="outline"></i
                      ><span class="status-uploaded"> VERIFYING </span>
                      <i
                        nz-icon
                        nzType="info-circle"
                        nzTheme="outline"
                        nz-popover
                        nzPopoverContent="Verifying data against expected format and template layout"
                      ></i>
                    </span>
                    <span class="card success" *ngIf="uploadedFile.status == 'Success'">
                      <i nz-icon nzType="check-circle" nzTheme="fill"></i>
                      {{ uploadedFile.status.toUpperCase() }}
                      <i
                        nz-icon
                        nzType="info-circle"
                        nzTheme="outline"
                        nz-popover
                        nzPopoverContent="Data uploaded successfully, we will contact you in case of further processing issues"
                      ></i>
                    </span>
                    <span class="card incomplete" *ngIf="uploadedFile.status == 'Incomplete'">
                      <i nz-icon nzType="question-circle" nzTheme="fill"></i>
                      {{ uploadedFile.status.toUpperCase() }}
                      <i
                        nz-icon
                        nzType="info-circle"
                        nzTheme="outline"
                        nz-popover
                        nzPopoverContent="Incomplete data feed pending further information"
                      ></i>
                    </span>
                    <span class="card issues" *ngIf="uploadedFile.status == 'Issues'">
                      <i nz-icon nzType="close-circle" nzTheme="fill"></i>
                      {{ uploadedFile.status.toUpperCase() }}
                      <i
                        nz-icon
                        nzType="info-circle"
                        nzTheme="outline"
                        nz-popover
                        nzPopoverContent="Uploaded data does not match expected format or template layout and/or has other issues"
                      ></i>
                    </span>
                  </span>
                </div>
              </div>
            </div>
          </div>
          <div nz-col nzSpan="8" class="upload-column col-2">
            <div class="card-body">
              <div class="start-date">
                <p class="card-titles">Data Period</p>
                <p class="card-titles-inner">
                  <span *ngIf="uploadedFile.file_info.start_date != ''">{{
                    getDate(uploadedFile.file_info.start_date, false)
                  }}</span>
                  <span *ngIf="uploadedFile.file_info.start_date == ''" class="empty-data">-</span>
                  <strong class="card-titles" class="to-data">&nbsp;To &nbsp;</strong>
                  <span *ngIf="uploadedFile.file_info.end_date != ''">{{
                    getDate(uploadedFile.file_info.end_date, false)
                  }}</span>
                  <span *ngIf="uploadedFile.file_info.end_date == ''" class="empty-data">-</span>
                </p>
              </div>
              <div>
                <p class="card-titles">Description</p>
                <p
                  *ngIf="
                    uploadedFile.file_info.description &&
                    uploadedFile.file_info.description.trim().length != 0
                  "
                  class="description"
                  nz-popover
                  nzPopoverContent="
               {{ uploadedFile.file_info.description }}
              "
                  nzPopoverPlacement="bottom"
                >
                  {{ uploadedFile.file_info.description }}
                </p>
                <p *ngIf="!uploadedFile.file_info.description">-</p>
              </div>
            </div>
            <div class="card-footer">
              <div
                class="card-footer-1"
                [ngClass]="uploadedFile.status === 'Under Verification' ? 'disable' : ''"
              >
                <p class="card-edit-icon">
                  <span (click)="editClicked(uploadedFile)"
                    ><i nz-icon nzType="edit" nzTheme="outline"></i> Edit</span
                  >
                </p>
                <p class="card-delete-icon">
                  <span (click)="deleteClicked(uploadedFile)"
                    ><i nz-icon nzType="delete" nzTheme="outline"></i> Delete</span
                  >
                </p>
              </div>
            </div>
          </div>
        </div>
      </ng-container>
    </div>
    <div class="loading-message" *ngIf="lazyLoading">
      Loading remaining records..
    </div>
    <div class="loading-message" *ngIf="uploadedFiles.length > 0 && uploadedFiles.length >= totalRecords">
      All records are displayed.
    </div>
  </div>
</nz-layout>

<div *ngIf="isAdmin" class="admin-disabled">Previews disabled in admin mode</div>

<app-template-modal
  *ngIf="showTemplates"
  [showTemplates]="showTemplates"
  (hideTemplates)="onTemplateClose($event)"
></app-template-modal>

<app-upload-file-modal
  *ngIf="uploadFile"
  [showModal]="uploadFile"
  (hideModal)="onUploadFileClose($event)"
  (reloadPage)="reloadPage($event)"
  [mode]="mode"
  [fileData]="fileData"
  [uploadedFiles]="uploadedFiles"
  [businessJustificaton]="businessJustification"
  [reason]="reasonForModification"
></app-upload-file-modal>

<app-business-justification-modal
  *ngIf="businessJust"
  [showModal]="businessJust"
  (reloadPage)="reloadPage($event)"
  [editAction]="editAction"
  (nextMoveEdit)="businessJustClicked($event)"
  (hideModal)="businessJustClose($event)"
  [fileData]="fileData"
  [uploadWindow]="status"
>
</app-business-justification-modal>

<app-file-preview-modal
  *ngIf="showPreview"
  [(previewFiles)]= "showPreview"
  [fileList]= "previewFileList"
>
</app-file-preview-modal>
