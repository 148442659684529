<div class="landing-container" style="background-image: url('assets/6229209.jpg'); background-size: cover; background-position: center;">
    <div class="client-container" [ngClass]="{'error-active': errorMessage}">
        <ng-container *ngIf="!loading">
            <div class="item-group">
                <div>
                    <img src="assets\cobrand-logo.png"  width="251" height="63" style="margin: 0 auto; display: block;">
                </div>  
                <div class="input-wrapper" style="padding-bottom: 15px; padding-top: 20px; color: #53565a;">
                    <label style="font-family: Roboto, Helvetica;font-size: 14px;color: #53565a;"><strong>Organization</strong></label>
                    <input nz-input [placeholder]="'Organization Name'" style="border: solid;border-width: 0.666667px; border-color: rgb(152, 164, 174); border-radius: 0px;" [(ngModel)]="selectedClient"
                           (keyup)="invalidClient=false"/>
                </div>
                <div class="input-wrapper" style="padding-bottom: 30px; color: #53565a;">
                    <label style="font-family: Roboto, Helvetica;font-size: 14px;color: #53565a;"><strong>Email</strong></label>
                    <input nz-input [placeholder]="'Email'" style="border: solid;border-width: 0.666667px; border-color: rgb(152, 164, 174); border-radius: 0px;"[(ngModel)]="selectedEmailId"
                           (keyup)="invalidEmailId=false"/>
                </div>
                <button nzType="primary" class="custom-button app-primary-button button-class"
                        style="margin: 0 auto; display: block; background-color:#5b93aa;"
                        (click)="navigateToIDM()">Continue
                </button>
                <div style="color:white;margin: auto;padding-left: 106px; padding-top: 17px; font-family: Roboto, Helvetica, Helvetica Neue, Arial, sans-serif;font-size: 14px;" >
                    <a [href]="loginUrl" style="color:#327f97;">ZS Login<br></a>
                </div>
            </div>
                <div [ngClass]="invalidClient?'error':'hide-error'" style="padding-top: 0.20px;">
                    Organisation/Email entered is not valid.
                </div>
                <div [ngClass]="errorMessage?'error':'hide-error'">
                    Reach out to affinity/access monitor team for further assistance.
                </div>
        </ng-container>
        <ng-container *ngIf="loading">
            <div class="spin">
                <nz-spin nzSimple></nz-spin>
            </div>
        </ng-container>    

    </div>
    <div style="color:white;margin: auto; padding-top: 60px; font-family: Roboto, Helvetica, Helvetica Neue, Arial, sans-serif;font-size: 14px;" >
        <p>&nbsp;&nbsp;&nbsp;&nbsp;&nbsp;&nbsp;&nbsp;&nbsp;&nbsp;&nbsp;Created by
        <a style="color:white;" href="https://www.zs.com/"><u> ZS Associates</u><br></a>
        <a style="padding-top: 20px; color:white;" href="https://www.zs.com/zs-online-privacy-policy-for-hosted-applications" id="ctl00_PrivacyPolicyLink">
            <u>Privacy Policy</u>
        </a> | 
        <a style="color:white;" href="https://www.zs.com/zs-terms-of-use-for-hosted-applications" id="ctl00_TermsOfUseLink">
                <u>Terms of Use</u></a> |
        <a style="color:white;"  href="mailto:afm_acm_offshore@zs.com">
                    <u>Contact</u></a>
    </p>
    </div>
</div>
