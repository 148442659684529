<div nz-row class="title-row">
  <div nz-col nzSpan="12" class="title">Add Custom Program</div>
</div>
<div class="multi-text">
  In case existing programs are not applicable, a custom program can be added. Post approval by the
  ZS team, the new program can be used to upload data.
</div>
<div class="channel-wrapper">
  <div class="title-in">Channel</div>
  <div>{{ channelName }}</div>
</div>
<div class="program-wrapper">
  <div class="title-in">Custom Program Name</div>
  <div>
    <textarea
      nz-input
      [nzAutosize]="{ minRows: 1, maxRows: 1 }"
      [(ngModel)]="program"
      (keyup)="checkProgram()"
      [ngClass]="isValidProgram && validProgramChar && !isProgramDuplicate ? '' : 'error'"
    ></textarea>
    <div class="error-3" *ngIf="!isValidProgram">
      Program Title length must be 50 characters or less. Please enter a valid input.
    </div>
    <div class="error-3" *ngIf="!validProgramChar">
      \ , / , * , ? , : , [ , ] are not allowed in the program name.
    </div>
    <div class="error-3" *ngIf="isProgramDuplicate">
      This program already exists under this channel.
    </div>
  </div>
</div>

<div class="data-layout-wrapper">
  <div class="title-in">Data Layout File</div>
  <div class="multi-text">Please upload a data layout/template in .csv format.</div>
  <nz-upload
    nzType="drag"
    *ngIf="fileList.length <= 0"
    [nzFileListRender]="fileListRef"
    (nzChange)="fileChanged($event)"
    [(nzFileList)]="nzFileList"
    [nzMultiple]="false"
  >
    <p class="ant-upload-text">
      Drag a file here to upload or
      <button nz-button nzType="primary" class="app-primary-button">Choose File</button>
    </p>
    <ng-template #fileListRef> </ng-template>
  </nz-upload>
  <div *ngIf="fileList.length > 0">
    <div class="progress-wrapper" *ngIf="isInprogress">
      <div class="file-name">
        <i nz-icon nzType="cloud-upload" nzTheme="outline"></i>&nbsp;
        <span class="file-name"
          ><strong>{{ fileList[0].name }}</strong></span
        >
      </div>
      <div class="progress-bar">
        <nz-progress
          nzStrokeLinecap="square"
          nzStrokeColor="#2B44C7"
          [nzPercent]="uploadPercent"
        ></nz-progress>
      </div>
    </div>
    <div
      nz-row
      class="title-row file-list"
      *ngIf="!isInprogress && !showUploadError && commaDelimCheck; else inValidFile"
    >
      <div nz-col nzSpan="22" class="title">
        <i nz-icon nzType="check-circle" nzTheme="fill" class="check-icon"></i>
        <span class="file-name">{{ fileList[0].name }}</span>
      </div>
      <div nz-col nzSpan="1" class="link delete-icon" (click)="deleteFile(true)">
        <i nz-icon nzType="delete" nzTheme="outline"></i>
      </div>
    </div>
    <ng-template #inValidFile>
      <div *ngIf="!isInprogress" nz-row class="title-row file-error">
        <div nz-col nzSpan="22" class="title">
          <span class="file-name">{{ fileList[0].name }}</span>
        </div>
        <div nz-col nzSpan="1" class="link delete-icon" (click)="deleteFile(false)">
          <i nz-icon nzType="delete" nzTheme="outline"></i>
        </div>
      </div>
    </ng-template>
  </div>
  <div class="error-message" *ngIf="showUploadError">
    <div>The file type cannot be accepted. Please ensure the file is in .csv format.</div>
  </div>
  <div class="error-message" *ngIf="!commaDelimCheck || programExistsCheck">
    <div *ngIf="!commaDelimCheck">Please ensure your template has ‘comma’ delimiter.</div>
    <div *ngIf="programExistsCheck">This program already exists.</div>
  </div>
</div>
<div class="description-wrapper">
  <div class="title-in">Description</div>
  <div>
    <textarea
      nz-input
      [nzAutosize]="{ minRows: 4, maxRows: 4 }"
      [(ngModel)]="description"
      (keyup)="checkDescription()"
      [ngClass]="isValidDescription ? '' : 'error'"
    ></textarea>
    <div class="error-3" *ngIf="!isValidDescription">
      Description length must be 1000 characters or less. Please enter a valid input.
    </div>
  </div>
</div>
