import { Component, EventEmitter, Input, OnInit, Output } from '@angular/core';
import { NzModalRef, NzModalService } from 'ng-zorro-antd/modal';
import { HttpService } from '../../shared/services/http.service';
import { NzUploadChangeParam, NzUploadFile } from 'ng-zorro-antd/upload';
import { NzNotificationService } from 'ng-zorro-antd/notification';
import { ReplaceValuePipe } from '../../shared/pipe/replace-value.pipe';
import { UploadFile, territoryAlignmentUploadFile } from '../../shared/models/upload-file.model';
import { AppState } from '../../shared/services/app-state';
import { Subscription } from 'rxjs';

@Component({
  selector: 'app-upload-file-modal',
  templateUrl: './upload-file-modal.component.html',
  styleUrls: ['./upload-file-modal.component.scss'],
})
export class UploadFileModalComponent implements OnInit {
  @Input() showModal = false;
  @Input() mode = 'save';
  @Input() fileData;
  @Input() uploadedFiles;
  @Input() businessJustificaton = [];
  @Input() reason = '';
  @Output() hideModal: EventEmitter<boolean> = new EventEmitter<boolean>();
  @Output() reloadPage: EventEmitter<boolean> = new EventEmitter<boolean>();
  public replaceValue = new ReplaceValuePipe();
  // TODO: replace this with fileList variable
  public uploadFileList: UploadFile[] = [];
  public territoryAlignmentUploadFileList: territoryAlignmentUploadFile[] = [];

  public confirmModal?: NzModalRef;

  public customProgramName = '';
  public customStatusSuccess = false;
  public commaDelimiterCheck = true;
  public programExistsCheck = false;
  public showCheckListStep = false;
  public checkListMode = 'new';

  public customProgramMapping = [];
  public originalMode;
  public activeStep = 1;
  public templates = [];
  public customTemplates = [];
  public templatePrograms = [];
  public customTemplatePrograms = [];
  public title = '';
  public description = '';
  public startDate;
  public endDate;
  public showTemplates = false;
  public isInprogress = false;
  public territoryAlignmentIsInprogress = false;
  public uploadPercent = 0;
  public allowMultiple = false;
  public fileList: File[] = [];
  public territoryAlignmentFileList: File[] = [];
  public s3FileList = [];
  public filenames = [];
  public territoryAlignmentFilenames = [];
  public isValidFile = true;
  public territoryAlignmentisValidFile = true;
  public showUploadError = false;
  public territoryAlignmentUploadError = false;
  public payload = {};
  public selectedChannels = [];
  public isValidTitle = true;
  public dateFormat = 'MM/dd/yyyy';
  public isValidDesc = true;
  public customError = [];
  public territoryAlignmentCustomError = [];
  public customProgram = '';
  public isValidProg = true;
  public formData = new FormData();
  public territoryAlignmentFormData = new FormData();
  public customformData = new FormData();
  isDuplicate: boolean;
  public showPrevious = false;
  public showCustomPage = false;
  public deletedFiles = [];
  public existingFiles: UploadFile[] = [];
  public uploadFileId;
  public duplicateFiles = [];
  public duplicateError = false;
  public nzFileList: NzUploadFile[] = [];
  public incompleteSteps = [];
  public modalWidth = '825px';
  public isEditMode = false;
  public isChanged = false;

  public territoryDetails: string;
  public AccountHCOCalls: string;
  public TerritoryAlignmentChange: string;

  public positionIdPresent = null;
  public accountHCOCallsDesc = null;

  public fileTypeValid = false;
  public fileSizeInvalid = false;

  public fileSizeMapping = {};

  public fileSizeEdited = 0;
  public TerritoryAlignmentFileSizeEdited = 0;
  public isSave = false;

  public showS3 = false;
  public s3Path = '';
  public s3Files = '';
  public validS3Location = true;
  public isValidS3LocationLoading = false;
  public isS3LocationVerified = false;

  public titleNotEmpty = false;
  public descNotEmpty = true;
  public s3PathNotEmpty = false;

  public uploadOption = null;
  public showSteps = false;
  public disableSubmitCustom = true;

  public customChannelName = '';
  public customChannelId;
  public customProgramNames = [];

  public isDuplicateFile = false;
  public territoryAlignmentisDuplicateFile = false;
  public hasDelimiter = true;
  public territoryAlignmentasDelimiter = true;
  // flag to show/hide channels on step2
  public showPrograms = true;
  // file selected for step2 multiMapping
  public selectedFile;
  public fileChannel = [];
  // used to retain the original sort order of programs on step 2.
  public originalOrder = (a, b): number => {
    return 0;
  };
  private appStateSubscription: Subscription;
  public userDetails;
  public isSubmitted = false;

  public options = ['Home_office', 'Rep_email', '3rd_party'];
  public selectedOptionIndex: number | null = null;

  constructor(
    private modal: NzModalService,
    private httpService: HttpService,
    private notification: NzNotificationService,
    private appState: AppState,
  ) { }

  ngOnInit(): void {
    this.appStateSubscription = AppState.appState.subscribe((val) => {
      if (!this.userDetails) {
        if (val && val.userDetails) {
          this.userDetails = val.userDetails;
        }
      }
    });

    this.originalMode = this.mode;
    if (this.mode === 'edit') {
      this.isEditMode = true;
      this.uploadOption = this.fileData.optionId;
      if (this.uploadOption === 1) {
        this.allowMultiple = false;
      } else {
        if (this.uploadOption === 3) {
          this.showPrograms = false;
        }
        this.allowMultiple = true;
      }
      this.showSteps = true;
      this.title = this.fileData.file_info.file_title;
      this.description = this.fileData.file_info.description;
      this.startDate = this.fileData.file_info.start_date;
      this.endDate = this.fileData.file_info.end_date;

      this.fileData.files.forEach((file, i) => {
        if (file.file_type === 's3') {
          this.s3Path = file.s3_path + ',';
          this.s3FileList.push(file);
          this.fileChannel.push({
            channelId: file.channelId,
            filename: file.filename,
            program: file.custom_program,
            channel_name: file.channel_name,
          });
          if (file.filename) {
            this.filenames.push(file);
          }
        } else {
          if (file.filename) {
            this.filenames.push(file);
          }

          if (file.program) {
            const channelId = file.custom_program_id ? file.custom_program_id : file.channelId;
            if (this.uploadOption !== 2 || (this.uploadOption === 2 && i === 0)) {
              this.selectedChannels.push({
                channel_id: channelId,
                channel_name: file.channel_name,
                programs: [file.program],
              });
            }
          }
          this.fileSizeEdited = this.fileSizeEdited + this.mb_to_gb(file.size);
          this.fileSizeMapping[file.filename] = file.size;
          if (file.program) {
            this.fileChannel.push({
              channelId: file.channelId,
              filename: file.filename,
              program: file.program,
              channel_name: file.channel_name,
            });
          }
        }
      });
      this.uploadFileList = this.fileData.files;
      this.existingFiles = this.fileData.files;
      if (this.s3Path.length > 0) {
        this.s3Path = this.s3Path.substring(0, this.s3Path.length - 1);
      }
      this.uploadFileId = this.fileData.upload_file_id;
      if (this.filenames.length < 1) {
        this.mode = 'save';
      }
    }
    this.loadChannelData();
    if (this.uploadFileList.length > 0) {
      if (this.fileData?.checklist?.activity?.files){
        this.territoryAlignmentUploadFileList = this.fileData.checklist.activity.files;
        this.territoryAlignmentFilenames.push(this.fileData.checklist.activity.files[0]);
      }

      this.loadChecklist();
    }
  }

  public getTitle(): string {
    let title = 'Upload File';
    switch (this.uploadOption) {
      case 1:
        title = title + ' - Single File';
        break;
      case 2:
        title = title + ' - Multiple Files with Single Mapping';
        break;
      case 3:
        title = title + ' - Multiple Files with Multiple Mapping';
        break;
    }
    return title;
  }

  public loadChannelData(): void {
    this.httpService.getRequest('get_template_data').subscribe((templates) => {
      this.templates = templates.data;
      this.templates.forEach((t) => {
        templates.custom_programs.forEach((c) => {
          if (t.channel.slice(0, -1) === c.channel) {
            c.programs.forEach((p) => {
              t.programs.push(p);
              this.customTemplatePrograms.push(p);
            });
          }
        });
      });
      this.templates.forEach((temp) => {
        temp['active'] = false;
        temp['disabled'] = false;
        temp['progObj'] = {};
        let progStr = '';

        temp.programs.forEach((prog) => {
          progStr = progStr + prog + ', ';

          temp.progObj[prog] = false;

          if (this.isEditMode) {
            this.uploadFileList.map((channel) => {
              if (channel.program === prog) {
                temp.progObj[prog] = true;
              }
            });
          }
        });
        progStr = progStr.substring(0, progStr.length - 2);
        temp['programStr'] = progStr;
      });
    });
  }

  public loadChecklist() {
    this.uploadedFiles.forEach((item: any) => {
      if (this.uploadFileId == item.upload_file_id) {
        if (item?.checklist?.activity && Object.keys(item?.checklist?.activity).length) {
          this.territoryDetails = item.checklist.activity.territoryDetails;
          this.positionIdPresent = item.checklist.activity.positionIdPresent;
          this.AccountHCOCalls = item.checklist.activity.accountHCOCalls;
          this.accountHCOCallsDesc = item.checklist.activity.accountHCOCallsDesc;
          this.TerritoryAlignmentChange = item.checklist.activity.territoryAlignmentChange;

          this.showCheckListStep = true;
        }
      }
    });

    if (this.showCheckListStep) {
      this.checkTitle();
      this.checkListMode = 'edit';
    }
  }

  public onCancel(): void {
    if (!this.isSave) {
      this.showModal = false;
      this.showConfirm();
    }
  }

  public onCancelCustom(): void {
    if (!this.isSave) {
      this.showModal = false;
      this.showConfirmCustom();
    }
  }

  public showConfirm(): void {
    this.confirmModal = this.modal.confirm({
      nzTitle: 'Leave Upload File?',
      nzContent:
        'You will lose any uploaded files or information you have inputted. Are you sure you want to leave?',
      nzOkText: 'Yes, Leave',
      nzIconType: 'exclamation-circle',
      nzOnOk: () => {
        this.hideModal.emit(false);
        this.showModal = false;
      },
      nzOnCancel: () => {
        this.showModal = true;
      },
    });
  }

  public showConfirmCustom(): void {
    this.confirmModal = this.modal.confirm({
      nzTitle: 'Leave Custom Program?',
      nzContent: 'Are you sure you want to leave custom program submission?',
      nzOkText: 'Yes, Leave',
      nzIconType: 'exclamation-circle',
      nzOnOk: () => {
        this.showCustomPage = false;
        this.hideModal.emit(true);
        this.showModal = true;
        this.commaDelimiterCheck = true;
        this.programExistsCheck = false;
      },
      nzOnCancel: () => {
        this.showModal = true;
      },
    });
  }

  public fileChanged(files: NzUploadChangeParam): void {
    this.formData = new FormData();
    this.isInprogress = true;
    this.uploadPercent = 5;
    const fileNames = [];
    // this.customError = [];
    this.showUploadError = false;
    if (files.type === 'start') {
      files.fileList.forEach((file, index) => {
        if (!this.fileList.find((f) => f === file.originFileObj)) {
          if (this.fileList.length > 0) {
            const result = this.fileList.find((list) => list.name === file.originFileObj.name);
            if (!result) {
              this.fileList.push(file.originFileObj);
              this.fileList[this.fileList.length - 1]['isValid'] = false;
              this.fileList[this.fileList.length - 1]['isUploaded'] = false;
              this.fileList[this.fileList.length - 1]['presigned'] = false;
              this.fileList[this.fileList.length - 1]['inProgress'] = true;
            } else {
              this.showUploadError = true;
              this.customError.push({
                filename: file.filename,
                error: 'This file is already uploaded, please upload a different file.'
              });
            }
          } else {
            this.fileList.push(file.originFileObj);
            this.fileList[this.fileList.length - 1]['isValid'] = false;
            this.fileList[this.fileList.length - 1]['isUploaded'] = false;
            this.fileList[this.fileList.length - 1]['presigned'] = false;
            this.fileList[this.fileList.length - 1]['inProgress'] = true;
          }
          const uploadFile = this.uploadFileList.find((f) => f.filename === file.name);
          if (uploadFile) {
            this.uploadFileList.splice(this.uploadFileList.indexOf(uploadFile), 1);
          }
          if (this.uploadOption === 1 && this.uploadFileList.length > 0) {
            this.uploadFileList[0].filename = file.name;
            this.uploadFileList[0].file_type = 'web';
            this.uploadFileList[0].size = this.bytes_to_mb(file.size);
          } else if (this.uploadOption === 2 && this.uploadFileList.length === 1) {
            this.uploadFileList[0].filename = file.name;
            this.uploadFileList[0].file_type = 'web';
            this.uploadFileList[0].size = this.bytes_to_mb(file.size);
          } else {
            this.uploadFileList.push({
              filename: file.name,
              file_type: 'web',
              s3_path: '',
              size: this.bytes_to_mb(file.size),
              program: '',
              sub_program_code: this.tempVariable,
              custom_program_id: null,
              channelId: null,
              channel_name: null,
              file_id: '',
            });
          }
        }
      });
    }

    this.fileTypeValid = this.checkFileType();
    if (this.fileTypeValid) {
      this.fileSizeInvalid = this.checkSize();
    }
    // if (!this.fileTypeValid) {
    //   this.fileSizeInvalid = this.checkSize();
    //   if (!this.fileSizeInvalid) {
    //     this.fileTypeValid = this.checkFileType();
    //   }
    // }
    if (this.fileTypeValid && !this.fileSizeInvalid) {
      this.s3Upload();
    }

    files = undefined;
    this.nzFileList = [];
    this.isChanged = true;
  }

  public territoryAlignmentFilechanged(files: NzUploadChangeParam): void {
    this.territoryAlignmentUploadError = false;
    this.territoryAlignmentIsInprogress = true;
    this.territoryAlignmentFormData = new FormData();
    this.uploadPercent = 5;
    if (files.type === 'start') {
      files.fileList.forEach((file, index) => {
        if (!this.territoryAlignmentFileList.find((f) => f === file.originFileObj)) {
          if (this.territoryAlignmentFileList.length > 0) {
            const result = this.territoryAlignmentFileList.find((list) => list.name === file.originFileObj.name);
            if (!result) {
              this.territoryAlignmentFileList.push(file.originFileObj);
              this.territoryAlignmentFileList[this.territoryAlignmentFileList.length - 1]['isValid'] = false;
              this.territoryAlignmentFileList[this.territoryAlignmentFileList.length - 1]['isUploaded'] = false;
              this.territoryAlignmentFileList[this.territoryAlignmentFileList.length - 1]['presigned'] = false;
              this.territoryAlignmentFileList[this.territoryAlignmentFileList.length - 1]['inProgress'] = true;
            } else {
              this.territoryAlignmentUploadError = true;
              this.territoryAlignmentCustomError.push({
                filename: file.filename,
                error: 'This file is already uploaded, please upload a different file.'
              });
            }
          } else {
            this.territoryAlignmentFileList.push(file.originFileObj);
            this.territoryAlignmentFileList[this.territoryAlignmentFileList.length - 1]['isValid'] = false;
            this.territoryAlignmentFileList[this.territoryAlignmentFileList.length - 1]['isUploaded'] = false;
            this.territoryAlignmentFileList[this.territoryAlignmentFileList.length - 1]['presigned'] = false;
            this.territoryAlignmentFileList[this.territoryAlignmentFileList.length - 1]['inProgress'] = true;
          }

          this.territoryAlignmentUploadFileList = [{
            filename: file.name,
            s3_path: '',
            size: this.bytes_to_mb(file.size),
            file_id: ''
          }];

        }
      });
    }

    const fileTypeValid = this.checkTAFileType();
    let fileSizeInvalid = false;

    if (fileTypeValid) {
      fileSizeInvalid = this.checkTASize();
    }

    if (fileTypeValid && !fileSizeInvalid) {
      this.s3Upload('territoryAlignemt');
    }

    // this.isChanged = true;

  }

  public bytes_to_gb(bytes) {
    return bytes / Math.pow(1024, 3);
  }

  public bytes_to_mb(bytes) {
    return bytes / Math.pow(1024, 2);
  }

  public mb_to_gb(mb) {
    return mb / Math.pow(1024, 1);
  }

  /**
   * Parameter check for files directly uploaded on S3.
   */
  public validateS3(currentStep, skip): void {
    this.validS3Location = false;
    this.isValidS3LocationLoading = true;
    this.isS3LocationVerified = false;
    const payload = {
      file_locations: [],
    };
    if (this.isEditMode) {
      payload['upload_file_id'] = this.uploadFileId;
    }

    if (!this.s3Path && skip) {
      this.activeStep = currentStep + 1;
    } else {
      payload.file_locations = this.s3Path.split(',');
      this.httpService.postRequest('parameter_checks', payload).subscribe(
        // this.httpService.getRequest('parameter_checks').subscribe(
        (data) => {
          if (data.data.some((file) => !file.exists)) {
            const errorFile = data.data.find((file) => !file.exists);
            this.showUploadError = true;
            if (errorFile.error) {
              this.customError.push({
                filename: errorFile.filename,
                error: errorFile.error
              });
            }
          } else {
            this.validS3Location = true;
            this.isS3LocationVerified = true;
            this.s3FileList = [];
            data.data.forEach((file) => {
              this.s3FileList.push({
                filename: file.filename,
                file_type: 's3',
                s3_path: file.path,
                size: file.size,
              });

              if (this.uploadOption === 3) {
                const existingFile = this.uploadFileList.find((f) => file.filename === f.filename);
                if (!existingFile) {
                  this.uploadFileList.push({
                    filename: file.filename,
                    file_type: 's3',
                    s3_path: file.path,
                    size: this.bytes_to_mb(file.size),
                    program: '',
                    sub_program_code: this.tempVariable,
                    custom_program_id: null,
                    channelId: null,
                    channel_name: null,
                    file_id: '',
                  });
                }
              }
            });
            this.delimiterCheck(currentStep, skip);
          }
          this.isValidS3LocationLoading = false;
        },
        () => {
          this.isValidS3LocationLoading = false;
        }
      );
    }
    this.isChanged = true;
  }

  public changeStep(currentStep, button, skip): void {
    if (!this.showSteps) {
      if (this.uploadOption) {
        if (this.uploadOption === 1) {
          this.allowMultiple = false;
        } else {
          this.allowMultiple = true;
        }
        if (this.uploadOption === 3) {
          this.showPrograms = false;
        }
        this.showSteps = true;
      }
    } else {
      let incompleteCount = 0;
      if (button === 'next') {
        if (this.activeStep === 4 || (this.activeStep === 3 && !this.showCheckListStep)) {
          this.saveData();
        } else {
          if (this.activeStep === 1 && this.showS3) {
            this.validateS3(currentStep, skip);
          } else {
            if (!skip) {
              const index = this.incompleteSteps.indexOf(this.activeStep);
              if (index !== -1) {
                this.incompleteSteps.splice(index, 1);
              }
            }
            this.activeStep = currentStep + 1;
          }
        }
      }
      if (button === 'previous') {
        if (this.activeStep === 1) {
          this.showModal = false;
          this.showConfirm();
        } else if (this.uploadOption === 3 && this.activeStep === 2) {
          if (this.showPrograms) {
            this.showPrograms = false;
          } else {
            this.duplicateError = false;
            this.activeStep = currentStep - 1;
          }
        } else {
          this.duplicateError = false;
          this.activeStep = currentStep - 1;
        }
      }

      if (button === 'skip') {
        this.showModal = false;
        let title = this.activeStep === 1 ? 'Skip Upload Step?' : 'Skip Channels & Programs Step?';
        let content =
          'You will have to complete this process before the semester ends. If you want this file mapping to be used by ZS. Do you wish to continue?';
        let okText = 'Yes, Skip';

        if (this.activeStep === 3) {
          title = 'Submit Incomplete Mapping?';
          content = '';
          if (
            (this.mode !== 'edit' && this.fileList.length < 1) ||
            (this.mode === 'edit' && this.filenames.length < 0)
          ) {
            content = content + '<br> - Upload File(s)';
            incompleteCount = incompleteCount + 1;
          }
          if (
            (this.uploadOption !== 3 && this.selectedChannels.length < 1) ||
            (this.uploadOption === 3 && this.fileChannel.length < 1)
          ) {
            content = content + '<br> - Select Channel(s)';
            incompleteCount = incompleteCount + 1;
          }
          if (!this.title || !this.startDate || !this.endDate) {
            content = content + '<br> - Info';
            incompleteCount = incompleteCount + 1;
          }
          if (incompleteCount === 3) {
            content =
              "You haven't entered data for any of the steps" +
              content +
              '<br> Are you sure you want to leave the upload process?';
            okText = 'Yes, Close';
          } else {
            if (incompleteCount > 0) {
              content =
                'You have yet to complete:' +
                content +
                '<br> You will have to complete this process before the semester ends. If you want this file mapping to be used by ZS. Do you wish to continue?';
              okText = 'Yes, Skip & Create';
            } else {
              this.incompleteSteps.push(0);
              content =
                '<br> You will have to complete this process before the semester ends. If you want this file mapping to be used by ZS. Do you wish to continue?';
              okText = 'Yes, Skip & Create';
            }
          }
        }

        this.confirmModal = this.modal.confirm({
          nzTitle: title,
          nzContent: content,
          nzOkText: okText,
          nzIconType: 'exclamation-circle',
          nzOnOk: () => {
            if (this.activeStep !== 3) {
              if (this.activeStep === 1 && this.showS3) {
                this.validateS3(currentStep, skip);
              } else {
                if (!this.incompleteSteps.includes(this.activeStep)) {
                  this.incompleteSteps.push(this.activeStep);
                }
                this.changeStep(this.activeStep, 'next', true);
              }
              this.showModal = true;
            } else {
              if (incompleteCount === 3) {
                this.showModal = false;
                this.hideModal.emit(false);
              } else {
                this.saveData();
              }
            }
            incompleteCount = 0;
          },
          nzOnCancel: () => {
            this.showModal = true;
            incompleteCount = 0;
            this.incompleteSteps = [];
          },
        });
      }
      if (this.activeStep === 2 && this.showPrevious) {
        this.modalWidth = '820px';
      } else {
        this.modalWidth = '825px';
      }
    }
  }

  public onDateChange(date, type): void {
    this.duplicateError = false;
    if (type === 'start' && this.endDate && this.startDate) {
      if (this.startDate.getTime() > this.endDate.getTime()) {
        this.endDate = undefined;
      }
    }
    if (type === 'end' && this.endDate && this.startDate) {
      if (this.startDate.getTime() > this.endDate.getTime()) {
        this.startDate = undefined;
      }
    }
    this.isChanged = true;
  }

  public disableUpload(): boolean {
    return !this.allowMultiple && (this.fileList.length > 0 || this.filenames.length > 0);
  }

  public disableTAUpload(): boolean {
    return (this.territoryAlignmentFileList.length > 0 || this.territoryAlignmentFilenames.length > 0);
  }

  /**
   * displays a confirmation popup and deletes the channel-file mapping if confirmed
   */
  public deleteMapping(channel): void {
    this.confirmModal = this.modal.confirm({
      nzTitle: 'Remove program?',
      nzContent: 'Are you sure you want to remove the program from ' + channel.filename + '?',
      nzOkText: 'Yes, Remove',
      nzIconType: 'exclamation-circle',
      nzOnOk: () => {
        this.fileChannel.splice(this.fileChannel.indexOf(channel), 1);

        if (!this.fileChannel.some(i => i.program === 'Live Rep Visits - Activity (in-person, remote, etc.)')) {
          this.showCheckListStep = false;
        }
      },
      nzOnCancel: () => { },
    });
  }

  public deleteFile(mode, isValid = false, isExisting, file?, territoryAlignemt?): void {
    if (isValid) {
      this.confirmModal = this.modal.confirm({
        nzTitle: 'Delete Uploaded File?',
        nzContent: 'Are you sure you want to delete this file?',
        nzOkText: 'Yes, Delete',
        nzIconType: 'exclamation-circle',
        nzOnOk: () => {
          if (mode === 'single') {
            if (isExisting) {
              this.deletedFiles.push(this.filenames[0].file_id);
              this.filenames = [];
              this.fileSizeMapping = [];
              this.mode = 'save';
            } else {
              if (territoryAlignemt) {
                this.territoryAlignmentFileList = [];
                this.territoryAlignmentFilenames = [];
              } else {
                this.fileList = [];
              }
            }

            if (territoryAlignemt) {
              this.territoryAlignmentUploadFileList = [];
              this.territoryAlignmentisValidFile = true;
              this.territoryAlignmentUploadError = false;
              this.territoryAlignmentCustomError = [];
            } else {
              this.uploadFileList = [];
              this.s3FileList = [];
              this.isValidFile = true;
              this.showUploadError = false;
              this.customError = [];
            }

          } else {

            const i = this.filenames.indexOf(file);
            const index = this.fileList.indexOf(file);
            const key = Object.keys(this.fileSizeMapping);
            const j = key.indexOf(file);
            if (i !== -1) {
              this.deletedFiles.push(this.filenames[i].file_id);
              this.filenames.splice(i, 1);
            }
            if (index !== -1) {
              this.fileList.splice(index, 1);
            }
            if (j !== -1) {
              this.fileSizeEdited =
                this.fileSizeEdited - this.mb_to_gb(this.fileSizeMapping[key[j]]);
              delete this.fileSizeMapping[key[j]];
            }
            if (this.mode === 'edit' && this.filenames.length < 1) {
              this.mode = 'save';
            }

            let uploadFileIndex = this.uploadFileList.find((f) => f.filename === file.filename);
            if (!uploadFileIndex) {
              uploadFileIndex = this.uploadFileList.find((f) => f.filename === file.name);
            }
            if (uploadFileIndex) {
              this.uploadFileList.splice(this.uploadFileList.indexOf(uploadFileIndex), 1);
            }

            const cErrorIndex = this.customError.find((f) => f.filename === file.name);
            if (cErrorIndex) {
              this.customError.splice(this.customError.indexOf(cErrorIndex), 1);
            }

            if (this.uploadOption === 3) {
              const fileIndex = this.fileChannel.find((f) => f.filename === file.filename);
              if (fileIndex) {
                this.fileChannel.splice(this.fileChannel.indexOf(fileIndex), 1);
              }
            }
          }
          this.fileSizeInvalid = this.checkSize();
        },
        nzOnCancel: () => { },
      });
    } else {
      if (mode === 'single') {
        if (isExisting) {
          this.deletedFiles.push(this.filenames[0].file_id);
          this.fileSizeMapping = [];
          this.filenames = [];
        } else {
          if (territoryAlignemt) {
            this.territoryAlignmentFileList = [];
          } else {
            this.fileList = [];
          }
        }

        if (territoryAlignemt) {
          this.territoryAlignmentUploadFileList = [];
          this.territoryAlignmentisValidFile = true;
          this.territoryAlignmentUploadError = false;
          this.territoryAlignmentCustomError = [];
        } else {
          this.uploadFileList = [];
          this.s3FileList = [];
          this.isValidFile = true;
          this.showUploadError = false;
          this.customError = [];
        }


      } else {
        const i = this.filenames.indexOf(file);
        const index = this.fileList.indexOf(file);
        if (i !== -1) {
          this.deletedFiles.push(this.filenames[i].file_id);
          this.filenames.splice(i, 1);
        }
        if (index !== -1) {
          this.fileList.splice(index, 1);
        }
        this.fileSizeInvalid = this.checkSize();
        const uploadFileIndex = this.uploadFileList.find((f) => f.filename === file.name);
        if (uploadFileIndex) {
          this.uploadFileList.splice(this.uploadFileList.indexOf(uploadFileIndex), 1);
        }

        const cErrorIndex = this.customError.find((f) => f.filename === file.name);
        if (cErrorIndex) {
          this.customError.splice(this.customError.indexOf(cErrorIndex), 1);
        }
      }
    }
    this.isChanged = true;
    console.log(
      'file size',
      this.fileSizeInvalid,
      'file type',
      this.fileTypeValid,
      'shows3',
      this.showS3,
      'showUpload error',
      this.showUploadError,
    );
  }

  public checkFileType(): boolean {
    let isValid = false;
    let fileTypeFalse = false;
    // this.showUploadError = false;
    this.fileList.forEach((file) => {
      const type = file.name.substring(file.name.length - 4, file.name.length);
      const hasError = this.customError.find(err => err.filename === file.name);
      if (!hasError) {
        if (!file['isUploaded']) {
          if (type === '.csv' || type === '.txt') {
            isValid = true;
            // file['inProgress'] = false;
            file['isValid'] = isValid;
            // this.customError = [];
          } else {
            isValid = false;
            fileTypeFalse = true;
            file['isValid'] = isValid;
            this.showUploadError = true;
            file['inProgress'] = false;
            this.customError.push({
              filename: file.name,
              error: 'The file type cannot be accepted. Please ensure the file is in .csv or .txt extension format and in line with template available for reference'
            });
            this.isInprogress = false;
          }
        }
      }
    });
    // if (this.fileList.length === this.customError.length) {
    //   this.isInprogress = false;
    // }
    const file = this.fileList.find((f) => !f['isValid']);
    if (!file) {
      this.showUploadError = false;
      this.customError = [];
    }
    return !fileTypeFalse;
  }

  public checkTAFileType(): boolean {
    let isValid = false;
    let fileTypeFalse = false;
    // this.showUploadError = false;
    this.territoryAlignmentFileList.forEach((file) => {
      const type = file.name.substring(file.name.length - 4, file.name.length);
      const hasError = this.territoryAlignmentCustomError.find(err => err.filename === file.name);
      if (!hasError) {
        if (!file['isUploaded']) {
          if (type === '.csv' || type === '.txt') {
            isValid = true;
            // file['inProgress'] = false;
            file['isValid'] = isValid;
            // this.territoryAlignmentCustomError = [];
          } else {
            isValid = false;
            fileTypeFalse = true;
            file['isValid'] = isValid;
            this.territoryAlignmentUploadError = true;
            file['inProgress'] = false;
            this.territoryAlignmentCustomError.push({
              filename: file.name,
              error: 'The file type cannot be accepted. Please ensure the file is in .csv or .txt extension format and in line with template available for reference'
            });
            this.territoryAlignmentIsInprogress = false;
          }
        }
      }
    });
    // if (this.territoryAlignmentFileList.length === this.territoryAlignmentCustomError.length) {
    //   this.territoryAlignmentIsInprogress = false;
    // }
    const file = this.territoryAlignmentFileList.find((f) => !f['isValid']);
    if (!file) {
      this.territoryAlignmentUploadError = false;
      this.territoryAlignmentCustomError = [];
    }
    return !fileTypeFalse;
  }

  public checkSize(): boolean {
    let isValid = false;
    let fileSetInvalid = false;
    // this.showUploadError = false;
    // this.customError = [];
    let size = 0;
    if (this.fileList.length === 1) {
      size = this.fileList[0]['size'];
      if (this.bytes_to_gb(size) >= this.bytes_to_gb(1073741824)) {
        fileSetInvalid = true;
        this.showUploadError = true;
        this.fileList[0]['inProgress'] = false;
        this.customError.push({
          filename: this.fileList[0]['filename'],
          error:
            'This file is larger than 1GB, please upload the file directly to S3 and enter the details using the “Upload Files through S3” option. To know more about S3 details, please refer to the FAQs.'
        });
        isValid = false;
        this.fileList[0]['isValid'] = isValid;
      }
    } else if (this.fileList.length > 1) {
      this.fileList.forEach((file) => {
        size = size + file['size'];
      });
      if (this.bytes_to_gb(size) >= this.bytes_to_gb(1073741824)) {
        fileSetInvalid = true;
        this.showUploadError = true;
        this.customError.push({
          filename: 'all',
          error: 'The combined upload size is larger than 1GB, please upload the files directly to S3 and enter the details using the “Upload Files through S3” option. To know more about S3 details, please refer to the FAQs.'
        });
      } else {
        const cErrorIndex = this.customError.indexOf({
          filename: 'all',
          error: 'The combined upload size is larger than 1GB, please upload the files directly to S3 and enter the details using the “Upload Files through S3” option. To know more about S3 details, please refer to the FAQs.'
        });
        if (cErrorIndex !== -1) { this.customError.splice(cErrorIndex, 1); }
      }
      if (fileSetInvalid) {
        this.fileList.forEach((file) => {
          isValid = false;
          file['isValid'] = isValid;
          file['inProgress'] = false;
        });
      }
    }

    if (this.mode === 'edit') {
      // size = this.fileList[0]['size'];
      if (this.fileList.length === 1 && !this.filenames) {
        if (this.bytes_to_gb(size) >= this.bytes_to_gb(1073741824)) {
          fileSetInvalid = true;
          this.showUploadError = true;
          this.customError.push({
            filename: this.fileList[0]['filename'],
            error:
              'This file is larger than 1GB, please upload the file directly to S3 and enter the details using the “Upload Files through S3” option. To know more about S3 details, please refer to the FAQs.'
          });
          isValid = false;
          this.fileList[0]['isValid'] = isValid;
          this.fileList[0]['inProgress'] = false;
        }
      } else if (this.fileList.length === 1 && this.filenames) {
        const intsize = this.bytes_to_gb(size) + this.fileSizeEdited;
        if (intsize >= this.bytes_to_gb(1073741824)) {
          fileSetInvalid = true;
          this.showUploadError = true;
          this.customError.push({
            filename: this.fileList[0]['filename'],
            error:
              'This file is larger than 1GB, please upload the file directly to S3 and enter the details using the “Upload Files through S3” option. To know more about S3 details, please refer to the FAQs.'
          });
          isValid = false;
          this.fileList[0]['isValid'] = isValid;
          this.fileList[0]['inProgress'] = false;
        }
      } else if (this.fileList.length > 1 && this.filenames) {
        const intsize = this.bytes_to_gb(size) + this.fileSizeEdited;
        if (intsize >= this.bytes_to_gb(1073741824)) {
          fileSetInvalid = true;
          this.showUploadError = true;
          this.customError.push({
            filename: 'all',
            error: 'The combined upload size is larger than 1GB, please upload the file directly to S3 and enter the details using the “Upload Files through S3” option. To know more about S3 details, please refer to the FAQs.'
          });
          isValid = false;
          this.fileList[0]['isValid'] = isValid;
          this.fileList[0]['inProgress'] = false;
        } else {
          const cErrorIndex = this.customError.indexOf({
            filename: 'all',
            error: 'The combined upload size is larger than 1GB, please upload the files directly to S3 and enter the details using the “Upload Files through S3” option. To know more about S3 details, please refer to the FAQs.'
          });
          if (cErrorIndex !== -1) { this.customError.splice(cErrorIndex, 1); }
        }
      }
    }

    const file = this.fileList.find((f) => !f['isValid']);
    if (!file) {
      this.showUploadError = false;
      this.customError = [];
    }

    return fileSetInvalid;
  }

  public checkTASize(): boolean {
    let isValid = false;
    let fileSetInvalid = false;

    let size = 0;
    if (this.territoryAlignmentFileList.length === 1) {
      size = this.territoryAlignmentFileList[0]['size'];
      if (this.bytes_to_gb(size) >= this.bytes_to_gb(1073741824)) {
        fileSetInvalid = true;
        this.territoryAlignmentUploadError = true;
        this.territoryAlignmentFileList[0]['inProgress'] = false;
        this.territoryAlignmentCustomError.push({
          filename: this.territoryAlignmentFileList[0]['filename'],
          error:
            'This file is larger than 1GB, please upload the file directly to S3 and enter the details using the “Upload Files through S3” option. To know more about S3 details, please refer to the FAQs.'
        });
        isValid = false;
        this.territoryAlignmentFileList[0]['isValid'] = isValid;
      }
    } else if (this.territoryAlignmentFileList.length > 1) {
      this.territoryAlignmentFileList.forEach((file) => {
        size = size + file['size'];
      });
      if (this.bytes_to_gb(size) >= this.bytes_to_gb(1073741824)) {
        fileSetInvalid = true;
        this.territoryAlignmentUploadError = true;
        this.territoryAlignmentCustomError.push({
          filename: 'all',
          error: 'The combined upload size is larger than 1GB, please upload the files directly to S3 and enter the details using the “Upload Files through S3” option. To know more about S3 details, please refer to the FAQs.'
        });
      } else {
        const cErrorIndex = this.territoryAlignmentCustomError.indexOf({
          filename: 'all',
          error: 'The combined upload size is larger than 1GB, please upload the files directly to S3 and enter the details using the “Upload Files through S3” option. To know more about S3 details, please refer to the FAQs.'
        });
        if (cErrorIndex !== -1) { this.territoryAlignmentCustomError.splice(cErrorIndex, 1); }
      }
      if (fileSetInvalid) {
        this.territoryAlignmentFileList.forEach((file) => {
          isValid = false;
          file['isValid'] = isValid;
          file['inProgress'] = false;
        });
      }
    }

    if (this.mode === 'edit') {
      // size = this.territoryAlignmentFileList[0]['size'];
      if (this.territoryAlignmentFileList.length === 1 && !this.territoryAlignmentFilenames) {
        if (this.bytes_to_gb(size) >= this.bytes_to_gb(1073741824)) {
          fileSetInvalid = true;
          this.territoryAlignmentUploadError = true;
          this.territoryAlignmentCustomError.push({
            filename: this.territoryAlignmentFileList[0]['filename'],
            error:
              'This file is larger than 1GB, please upload the file directly to S3 and enter the details using the “Upload Files through S3” option. To know more about S3 details, please refer to the FAQs.'
          });
          isValid = false;
          this.territoryAlignmentFileList[0]['isValid'] = isValid;
          this.territoryAlignmentFileList[0]['inProgress'] = false;
        }
      } else if (this.territoryAlignmentFileList.length === 1 && this.territoryAlignmentFilenames) {
        const intsize = this.bytes_to_gb(size) + this.TerritoryAlignmentFileSizeEdited;
        if (intsize >= this.bytes_to_gb(1073741824)) {
          fileSetInvalid = true;
          this.territoryAlignmentUploadError = true;
          this.territoryAlignmentCustomError.push({
            filename: this.territoryAlignmentFileList[0]['filename'],
            error:
              'This file is larger than 1GB, please upload the file directly to S3 and enter the details using the “Upload Files through S3” option. To know more about S3 details, please refer to the FAQs.'
          });
          isValid = false;
          this.territoryAlignmentFileList[0]['isValid'] = isValid;
          this.territoryAlignmentFileList[0]['inProgress'] = false;
        }
      } else if (this.territoryAlignmentFileList.length > 1 && this.territoryAlignmentFilenames) {
        const intsize = this.bytes_to_gb(size) + this.fileSizeEdited;
        if (intsize >= this.bytes_to_gb(1073741824)) {
          fileSetInvalid = true;
          this.territoryAlignmentUploadError = true;
          this.territoryAlignmentCustomError.push({
            filename: 'all',
            error: 'The combined upload size is larger than 1GB, please upload the file directly to S3 and enter the details using the “Upload Files through S3” option. To know more about S3 details, please refer to the FAQs.'
          });
          isValid = false;
          this.territoryAlignmentFileList[0]['isValid'] = isValid;
          this.territoryAlignmentFileList[0]['inProgress'] = false;
        } else {
          const cErrorIndex = this.territoryAlignmentCustomError.indexOf({
            filename: 'all',
            error: 'The combined upload size is larger than 1GB, please upload the files directly to S3 and enter the details using the “Upload Files through S3” option. To know more about S3 details, please refer to the FAQs.'
          });
          if (cErrorIndex !== -1) { this.territoryAlignmentCustomError.splice(cErrorIndex, 1); }
        }
      }
    }

    const file = this.territoryAlignmentFileList.find((f) => !f['isValid']);
    if (!file) {
      this.territoryAlignmentUploadError = false;
      this.territoryAlignmentCustomError = [];
    }

    return fileSetInvalid;
  }

  public disableSkip(): boolean {
    const disableSkipBut =
      this.activeStep === 3 && (this.showCheckListStep)
        ? true
        : this.activeStep === 3
          ? !this.title ||
          !this.startDate ||
          !this.endDate ||
          !this.isValidTitle ||
          !this.isValidDesc ||
          this.isSave
          : this.activeStep === 1
            ? this.fileList.length > 0 ||
            this.filenames.length > 0 ||
            this.showUploadError ||
            this.checkUploadStatus()
            : this.activeStep === 4
              ? true
              : false;
    return disableSkipBut;
  }
  
  tempVariable : string = null;
  public onsub_program_codeChange(subValue: string) {
    console.log("Selected sub_program_code: " + subValue);
    this.tempVariable = subValue;
    return subValue;
  }
  public onsub_program_codeChangetoNull() {
    console.log("Selected sub_program_code: ");
    this.tempVariable = null
    return null;
  }
  
  onRadioClick(index: number): void {
    if (this.selectedOptionIndex === index) {
      this.selectedOptionIndex = null;  // Uncheck the radio button
    } else {
      this.selectedOptionIndex = index; // Check the clicked radio button
    }
  }

  public disableButton(): boolean {
    const disableButton = this.activeStep === 1 ? this.isInprogress : false;
    return disableButton;
  }

  public disableSubmit(event): void {
    if (!event) {
      this.disableSubmitCustom = true;
    } else {
      this.disableSubmitCustom = false;
    }
  }

  public disableNext(): boolean {
    if (!this.showSteps) {
      return !this.uploadOption;
    } else if (this.uploadOption === 3 && this.activeStep === 2) {
      return this.showCustomPage
        ? this.disableSubmitCustom || !this.commaDelimiterCheck || this.programExistsCheck
        : !this.showPrograms && this.uploadFileList.length !== this.fileChannel.length;
    } else if (this.activeStep === 1) {
      return this.isEditMode && !this.showS3
        ? !this.hasFile() || this.showUploadError || this.checkUploadStatus()
        : this.showS3
          ? !this.s3Path || this.showUploadError || !this.s3PathNotEmpty || this.checkUploadStatus()
          : this.showUploadError || this.fileList.length < 1 || this.checkUploadStatus();
    } else if (this.activeStep === 2 && !this.showCustomPage) {
      return this.selectedChannels.length < 1;
    } else if (this.activeStep === 2 && this.showCustomPage) {
      return this.disableSubmitCustom || !this.commaDelimiterCheck || this.programExistsCheck;
    } else if (this.activeStep === 4 && this.showCheckListStep) {
      return this.isActivityStepValid() || this.isSave;
    }
    else {
      return (
        !this.title ||
        !this.startDate ||
        !this.endDate ||
        !this.isValidTitle ||
        !this.isValidDesc ||
        !this.titleNotEmpty ||
        this.isSave
      );
    }
  }

  isActivityStepValid() {
    return ((this.territoryDetails === 'Multiple rep' && !this.positionIdPresent && !this.showS3) ? this.fileList.length < 1 ? true : this.checkUploadStatus() : false)
        || (this.territoryDetails === 'Multiple rep' && !this.positionIdPresent && this.showS3 ? (!this.s3Path || this.showUploadError || !this.s3PathNotEmpty) || !this.validS3Location : false)
        || (this.AccountHCOCalls === 'calls included' && !this.accountHCOCallsDesc ? true : false)
        || (this.TerritoryAlignmentChange === 'change happened' && this.checkListMode === 'new' && this.territoryAlignmentFileList.length < 1 ? true : this.checkUploadStatus(this.territoryAlignmentFileList))
        || (this.TerritoryAlignmentChange === 'change happened' && this.checkListMode === 'edit' && this.territoryAlignmentFilenames.length < 1 ? this.territoryAlignmentFileList.length < 1 ? true : this.checkUploadStatus(this.territoryAlignmentFileList) : false)
        || this.territoryAlignmentUploadError || this.showUploadError
        || [this.territoryDetails, this.AccountHCOCalls, this.TerritoryAlignmentChange].some(item => item === undefined)
  }

  public hasFile(): boolean {
    let isFile = false;
    if (this.uploadFileList.length > 0) {
      if (this.uploadOption === 1) {
        this.uploadFileList[0].filename ? (isFile = true) : (isFile = false);
      } else {
        const file = this.uploadFileList.find((f) => !f.filename);
        file ? (isFile = false) : (isFile = true);
      }
    }
    return isFile;
  }

  public onTemplateClose(event): void {
    this.showTemplates = event;
  }

  public programUpdated(event = null, program, channel, channelId): void {
    const template = this.templates.find((t) => t.template_id === channelId);
    if (!this.customStatusSuccess) {
      template.progObj[program.key] = event.currentTarget.checked;
    }
    const selectedChannel = this.selectedChannels.find((c) => c.channel_id === channelId);
    if (!this.customStatusSuccess) {
      if (event.currentTarget.checked) {
        if (selectedChannel) {
          selectedChannel.programs.push(program.key);
        } else {
          this.selectedChannels.push({
            channel_id: channelId,
            channel_name: channel,
            programs: [program.key],
          });
        }
        if (program.key === 'Live Rep Visits - Activity (in-person, remote, etc.)') {
          this.showCheckListStep = true;
        }

      } else {
        if (this.uploadOption === 3) {
          const file = this.fileChannel.find((f) => f.filename === this.selectedFile.filename);
          if (file && file.program === program.key) {
            this.fileChannel.splice(this.fileChannel.indexOf(file), 1);
          }
        } // else {
        if (selectedChannel) {
          const index = selectedChannel.programs.indexOf(program.key);
          const channelIndex = this.selectedChannels.indexOf(selectedChannel);
          if (index > -1) {
            selectedChannel.programs.splice(index, 1);
          }
          if (selectedChannel.programs.length < 1) {
            this.selectedChannels.splice(channelIndex, 1);
          }

          if (program.key === 'Live Rep Visits - Activity (in-person, remote, etc.)') {
            this.showCheckListStep = false;
          }
        }
        // }
      }
    } else {
      if (selectedChannel) {
        selectedChannel.programs.push(program.key);
      } else {
        this.selectedChannels.push({
          channel_id: channelId,
          channel_name: channel,
          programs: [this.customProgramName],
        });
      }
    }

    if (this.uploadOption === 3) {
      this.selectedChannels.map((c) => {
        this.fileChannel.push({
          channelId: c.channel_id,
          filename: this.selectedFile.filename,
          program: c.programs[0],
          channel_name: c.channel_name,
        });
      });
    }
    this.customStatusSuccess = false;
    this.isChanged = true;
  }

  public checkTitle(): void {
    this.duplicateError = false;
    this.isValidTitle = this.title.length < 100;
    this.title = this.replaceValue.transform(this.title, true);
    this.title
      ? this.title.length > 0
        ? (this.titleNotEmpty = true)
        : (this.titleNotEmpty = false)
      : (this.titleNotEmpty = false);
    this.isChanged = true;
  }

  public checkAccountHCOCalls(): void {
    this.accountHCOCallsDesc = this.replaceValue.transform(this.accountHCOCallsDesc, true);
  }

  public checkCustomProgram(): void {
    this.isValidProg = this.customProgram.length < 100;
  }

  public checkDescription(): void {
    this.duplicateError = false;
    this.isValidDesc = this.description.length < 2000;
    this.description = this.replaceValue.transform(this.description, true);
    this.description
      ? this.description.length > 0
        ? (this.descNotEmpty = true)
        : (this.descNotEmpty = false)
      : (this.descNotEmpty = false);
    this.isChanged = true;
  }

  public checkS3Path(): void {
    this.isS3LocationVerified = false;
    this.s3Path = this.replaceValue.transform(this.s3Path, true);
    this.s3Path
      ? this.s3Path.length > 0
        ? (this.s3PathNotEmpty = true)
        : (this.s3PathNotEmpty = false)
      : (this.s3PathNotEmpty = false);
    this.validS3Location = false;
  }

  public saveData(): void {
    this.isSave = true;
    this.isSubmitted = true;
    this.formData = new FormData();
    this.payload = {};
    this.duplicateError = false;
    const customProgramMap = [
      {
        custom_program_id: null,
        filename: '',
      },
    ];
    if (this.customProgramMapping.length > 0) {
      this.customProgramMapping.forEach((map) => {
        if (this.selectedChannels.length > 0 && this.selectedChannels[0].programs[0] === map.name) {
          customProgramMap.push({
            filename: map.name,
            custom_program_id: map.custom_program_id,
          });
        }
      });
    }

    this.updateUploadFiles();

    this.payload = {
      type: this.originalMode,
      step: 3,
      files: this.uploadFileList,
      // channels: this.selectedChannels,
      incomplete_steps: this.incompleteSteps,
      file_info: {
        file_title: this.title,
        start_date: this.startDate,
        end_date: this.endDate,
        description: this.description,
      },
      optionId: this.uploadOption,
      custom_programs: customProgramMap,
      bussiness_justification: this.businessJustificaton,
      bussiness_justification_reason: this.reason,
      checklist: {
        activity: {}
      }
    };

    if (this.showCheckListStep) {
      this.payload['checklist']['activity'] = {
        territoryDetails: this.territoryDetails,
        positionIdPresent: this.positionIdPresent,
        accountHCOCalls: this.AccountHCOCalls,
        accountHCOCallsDesc: this.accountHCOCallsDesc,
        territoryAlignmentChange: this.TerritoryAlignmentChange,
        files: this.territoryAlignmentUploadFileList
      }
    }

    if (this.isEditMode) {
      this.deletedFiles.forEach((file) => {
        const hasFile = this.existingFiles.find((efile) => efile.file_id === file);
        if (hasFile) {
          this.existingFiles.splice(this.existingFiles.indexOf(hasFile), 1);
        }
      });
      this.uploadFileList.forEach((file) => {
        const hasFile = this.existingFiles.find((efile) => efile.file_id === file.file_id);
        if (hasFile) {
          this.existingFiles.splice(this.existingFiles.indexOf(hasFile), 1);
        }
      });
      this.payload['deleted_files'] = this.deletedFiles;
      this.payload['existing_files'] = this.existingFiles;
      this.payload['upload_file_id'] = this.uploadFileId;
    }
    this.formData.append('data', JSON.stringify(this.payload));
    console.log('payload: ', this.payload);

    this.httpService.postRequest('save_upload_data', this.formData, true).subscribe(
      // this.httpService.getRequest('save_upload_data').subscribe(
      (data) => {
        if (data['status'] === 'SUCCESS') {
          this.showUploadError = true;
          this.isValidFile = true;
          this.reloadPage.emit(true);
          this.isValidFile = false;
          this.isInprogress = false;
          this.createBasicNotification(data.upload_status);
          this.showModal = false;
          this.hideModal.emit(false);
        } else if (data['status'] === 'Duplicate') {
          // TODO: Add code to handle duplicate here
          console.log('duplicate: ', data);
          this.showModal = true;
          this.duplicateError = true;
        } else {
          this.showUploadError = true;
          this.isValidFile = false;
          this.reloadPage.emit(false);
          this.isValidFile = false;
          this.isInprogress = false;
          this.showModal = false;
          this.hideModal.emit(false);
        }
        this.isSave = false;
        this.isSubmitted = false;
        this.incompleteSteps = [];
      },
      (error) => {
        if (error['status'] === 'Duplicate') {
          // TODO: Add code to handle duplicate here
          console.log('duplicate: ', error);
          this.duplicateError = true;
        } else {
          this.showUploadError = true;
          this.isInprogress = false;
          this.isValidFile = false;
          this.createBasicNotification('Issues');
          this.showModal = false;
          this.hideModal.emit(false);
          this.customError.push({
            filename: 'save',
            error: 'There was an error while uploading. Please try again.'
          });
          console.log('something went wrong: ', error);
        }
        this.isSubmitted = false;
        this.isSave = false;
        this.incompleteSteps = [];
      },
    );
  }

  public getCount(template): number {
    let count = 0;
    if (this.uploadOption === 3) {
      if (this.fileChannel.length > 0) {
        const file = this.fileChannel.find((f) => f.filename === this.selectedFile.filename);
        if (file && file.channelId === template.template_id && file.program) {
          count = 1;
        }
      }
    } else if (this.selectedChannels) {
      this.selectedChannels.forEach((channel) => {
        if (channel.channel_id === template.template_id) {
          count = channel.programs.length;
        }
      });
    }
    return count;
  }

  public isChecked(program): boolean {
    let checked = -1;
    this.selectedChannels.forEach((channel) => {
      checked = channel.programs.indexOf(program);
    });
    return checked !== -1;
  }

  public toggleMultipleUpload(): boolean {
    this.showUploadError = false;
    if (this.allowMultiple) {
      this.confirmModal = this.modal.confirm({
        nzTitle: 'Leave Upload Multiple Files?',
        nzContent:
          'You will lose any uploaded files or information you have inputted. Are you sure you want to leave?',
        nzOkText: 'Yes, Leave',
        nzIconType: 'exclamation-circle',
        nzOnOk: () => {
          this.fileList = [];
          if (this.filenames.length !== 1) {
            this.filenames = [];
          }
          this.allowMultiple = !this.allowMultiple;
        },
        nzOnCancel: () => { },
      });
    } else {
      this.fileList = [];
      this.allowMultiple = !this.allowMultiple;
    }
    return this.allowMultiple;
  }

  // TODO: Uncomment below code if copy previous mapping feature is to be implemented again
  // public selectedChannel(channelId): void {
  //   const selected = this.uploadedFiles.find((data) => data.upload_file_id === channelId);
  //   this.uploadedFiles.forEach((data) => {
  //     if (data.upload_file_id === channelId) {
  //       data.isSelected = true;
  //     } else {
  //       data.isSelected = false;
  //     }
  //   });
  //   selected.channel_data.forEach((channel) => {
  //     const template = this.templates.find((t) => t.template_id === channel.channel_id);
  //     channel.programs.forEach((program) => {
  //       template.progObj[program] = true;
  //     });
  //   });
  //   this.selectedChannels = selected.channel_data;
  // }

  public createBasicNotification(status): void {
    let msg = '';
    let bgColor = '';
    if (status === 'Success' || status === 'Under Verification') {
      msg = 'File successfully uploaded';
      bgColor = '#00AA67';
    } else if (status === 'Incomplete') {
      msg = 'File was created without Upload and other attributes';
      bgColor = '#E3A900';
    } else if (status === 'Custom Success') {
      msg = 'Request sent successfully';
      bgColor = '#00AA67';
    } else {
      msg = 'Something went wrong';
      bgColor = 'red';
    }

    this.notification.blank('', msg, {
      nzPlacement: 'bottomLeft',
      nzStyle: { background: bgColor, color: '#FFFFFF' },
    });
  }

  public getChannelCount(channels): number {
    let count = 0;
    channels.forEach((channel) => {
      count = count + channel.programs.length;
    });
    return count;
  }

  public getChannelList(channels): string {
    let list = '';
    channels.forEach((channel) => {
      channel.programs.forEach((program) => {
        list = list + program + '\n';
      });
    });
    return list;
  }

  public getClass(id): string {
    let divClass = '';
    switch (id) {
      case 1:
        divClass = 'personal-f';
        break;
      case 2:
        divClass = 'personal-r';
        break;
      case 3:
        divClass = 'peer-i';
        break;
      case 4:
        divClass = 'digital-push';
        break;
      case 5:
        divClass = 'digital-pull';
        break;
      case 6:
        divClass = 'direct';
        break;
      case 7:
        divClass = 'customer-m';
        break;
      case 8:
        divClass = 'offer';
        break;
    }
    return divClass;
  }

  public showProgram(program, displayProgram): boolean {
    return !displayProgram.find((data) => data.name === program);
  }

  public onBack(): void {
    this.showModal = false;
    this.showS3 = false;
    this.s3Path = '';
    this.showPrograms = true;
    let title = 'Leave single file upload?';
    if (this.uploadOption === 2) {
      title = 'Leave multiple file upload?';
    } else if (this.uploadOption === 3) {
      title = 'Leave multiple files upload?';
    }
    this.confirmModal = this.modal.confirm({
      nzTitle: title,
      nzContent:
        'You will lose any uploaded files or information you have entered. Are you sure you want to leave?',
      nzOkText: 'Yes, Back',
      nzIconType: 'exclamation-circle',
      nzOnOk: () => {
        this.uploadOption = null;
        this.showSteps = false;
        this.fileList = [];
        this.territoryAlignmentFileList = [];
        this.showModal = true;
        this.showUploadError = false;
        this.territoryAlignmentUploadError = false;
        this.selectedChannels = [];
        this.fileChannel = [];
        this.title = '';
        this.startDate = '';
        this.endDate = '';
        this.description = '';
        this.uploadFileList = [];
        this.territoryAlignmentUploadFileList = [];
        this.isChanged = false;
        this.filenames = [];
        this.territoryAlignmentFilenames = [];
        this.isEditMode = false;
        this.mode = 'save';
        this.customError = [];
        this.territoryAlignmentCustomError = [];
        this.loadChannelData();

        this.showCheckListStep = false;
        this.territoryDetails;
        this.positionIdPresent = null;
        this.AccountHCOCalls;
        this.accountHCOCallsDesc = null;
        this.TerritoryAlignmentChange;

      },
      nzOnCancel: () => {
        this.showModal = true;
      },
    });
  }

  public onS3(showS3, back): void {
    this.showModal = false;
    this.showS3 = false;
    let title = 'Leave single file upload?';
    let content =
      'You will lose any uploaded files or information you have entered. Are you sure you want to leave?';
    if (this.uploadOption === 2) {
      title = 'Leave multiple file upload?';
    } else if (this.uploadOption === 3) {
      title = 'Leave multiple files upload?';
    }
    if (back) {
      title = 'Leave upload to S3 files?';
      content =
        'You will lose any uploaded files or information you have entered. Are you sure you want to leave?';
    }
    this.confirmModal = this.modal.confirm({
      nzTitle: title,
      nzContent: content,
      nzOkText: 'Yes, Leave',
      nzIconType: 'exclamation-circle',
      nzOnOk: () => {
        this.showS3 = showS3;
        this.showModal = true;
        this.s3Path = '';
        this.validS3Location = !showS3;
      },
      nzOnCancel: () => {
        this.showS3 = !showS3;
        this.showModal = true;
      },
    });
  }

  public disableChannel(channelId, programKey): boolean {
    if (this.uploadOption === 3) {
      let disable = false;
      if (this.fileChannel.length > 0) {
        const file = this.fileChannel.find((f) => f.filename === this.selectedFile.filename);
        if (file) {
          if (file.program && file.program !== programKey) {
            disable = true;
          }
        }
      }
      return disable;
    } else if (this.selectedChannels.length > 0) {
      return !this.selectedChannels.find((p) => p.programs.find((x) => x === programKey));
    } else {
      return false;
    }
  }

  public addCustomProgramClicked(channelName, programs, channelId): void {
    this.showCustomPage = true;
    this.programExistsCheck = false;
    this.customChannelName = channelName;
    this.customChannelId = channelId;
    this.customProgramNames = [];
    Object.keys(programs).forEach((p) => {
      programs[p] = false;
      this.customProgramNames.push(p);
    });
    this.selectedChannels = [];
  }

  public submitRequest(): void {
    this.httpService
      .postRequest('add_custom_program', this.customformData, true)
      //.getRequest('add_custom_program')
      .subscribe(
        (data) => {
          if (data['status'] === 'SUCCESS') {
            this.customStatusSuccess = true;
            this.templates.forEach((temp) => {
              if (temp.template_id === this.customChannelId) {
                temp.programs.push(this.customProgramName);
                this.customProgramMapping.push({
                  name: this.customProgramName,
                  custom_program_id: data['custom_program_id'],
                  status: '',
                });
                temp.programs.forEach((prog) => {
                  if (prog === this.customProgramName) {
                    temp.progObj[prog] = true;
                    this.programUpdated(null, prog, temp.channel, this.customChannelId);
                    this.customStatusSuccess = false;
                  } else {
                    temp.progObj[prog] = false;
                  }
                });
              }
            });
            this.showCustomPage = false;
            this.commaDelimiterCheck = true;
            this.programExistsCheck = false;
            this.createBasicNotification('Custom Success');
          } else if (data['status'] === 'ERROR') {
            if (data['message'] === 'This program already exists') {
              this.programExistsCheck = true;
            } else {
              this.commaDelimiterCheck = false;
            }
          }
        },
        (error) => {
          console.log('something went wrong:' + error);
          this.createBasicNotification('Issues');
        },
      );
  }

  public addCustomFormData(event): void {
    this.customformData = event;
  }
  public addCustomProgramName(event): void {
    this.customProgramName = event;
  }

  public findCustomProgram(program): boolean {
    let found = false;
    if (this.customProgramMapping) {
      if (this.customProgramMapping.find((p) => p.name === program)) {
        found = true;
      }
    }
    if (!found) {
      if (this.customTemplatePrograms.find((cp) => cp === program)) {
        found = true;
      }
    }
    return found;
  }

  /**
   * Returns the percent value to be displayed on file progress bar
   * @param getPercent: flag used to identify return type
   */
  public getFileSize(getPercent): any {
    let returnValue: any;
    if (this.uploadFileList.length > 0) {
      returnValue = 0;
      this.uploadFileList.forEach((file) => {
        returnValue = returnValue + Math.floor(file.size);
      });
      // returnValue = this.bytes_to_mb(returnValue);
      // returnValue = Math.floor(returnValue);
    }
    if (getPercent) {
      returnValue = (100 * returnValue) / 1024;
    } else {
      returnValue ? (returnValue = returnValue + ' MB') : (returnValue = '0 MB');
    }
    return returnValue;
  }

  /**
   * updates the upload file list to be saved.
   */
  public updateUploadFiles(): void {
    this.uploadFileList = [];
    let prog = '';
    let channelName = '';
    let channelId = null;
    if (this.uploadOption !== 3 && this.selectedChannels.length > 0) {
      prog = this.selectedChannels[0].programs[0];
      channelName = this.selectedChannels[0].channel_name;
      channelId = this.selectedChannels[0].channel_id;
    }

    if (this.s3FileList.length > 0) {
      this.s3FileList.map((file) => {
        const channel = this.fileChannel.find((chan) => chan.filename === file.filename);
        if (this.uploadOption === 3) {
          if (channel) {
            prog = channel.program;
            channelId = channel.channelId;
            channelName = channel.channel_name;
          } else {
            prog = '';
            channelName = '';
            channelId = null;
          }
          // add channel name and id
        } else {
          if (this.selectedChannels.length > 0) {
            prog = this.selectedChannels[0].programs[0];
            channelId = this.selectedChannels[0].channel_id;
            channelName = this.selectedChannels[0].channel_name;
          }
        }       
        this.formData.append(file.filename, file);
        if (prog != 'Email'){
          this.tempVariable = null
        }
        this.uploadFileList.push({
          filename: file.filename,
          file_type: 's3',
          s3_path: file.s3_path,
          size: file.size,
          program: prog,
          sub_program_code : this.tempVariable,
          custom_program_id: null,
          channelId: channelId,
          channel_name: channelName,
          file_id: '',
        });
      });
    } else if (this.fileList.length > 0) {
      this.fileList.map((file) => {
        const channel = this.fileChannel.find((chan) => chan.filename === file.name);
        if (this.uploadOption === 3) {
          if (channel) {
            prog = channel.program ? channel.program : '';
            channelId = channel.channelId;
            channelName = channel.channel_name;
          } else {
            prog = '';
            channelName = '';
            channelId = null;
          }
        } else {
          if (this.selectedChannels.length > 0) {
            prog = this.selectedChannels[0].programs[0];
            channelId = this.selectedChannels[0].channel_id;
            channelName = this.selectedChannels[0].channel_name;
          }
        }

        this.formData.append(file.name, file);
        if (prog != 'Email'){
          this.tempVariable = null
        }
        this.uploadFileList.push({
          filename: file.name,
          file_type: 'web',
          s3_path: '',
          size: this.bytes_to_mb(file.size),
          program: prog,
          sub_program_code: this.tempVariable,
          custom_program_id: null,
          channelId: channelId,
          channel_name: channelName,
          file_id: '',
        });
      });
    } else if (this.uploadOption !== 3) {
      const program = this.existingFiles.length > 0 ? this.existingFiles[0].program : '';
      if (this.isEditMode && prog !== program) {
        this.existingFiles.map((file) => {
          file.program = prog;
          file.channelId = channelId;
          file.channel_name = channelName;
        });
      } else if (channelId && prog !== program) {
          if (prog != 'Email'){
            this.tempVariable = null
          }
        console.log(this.tempVariable)
        this.uploadFileList.push({
          filename: '',
          file_type: 'web',
          s3_path: '',
          size: 0,
          program: prog,
          sub_program_code: this.tempVariable,
          custom_program_id: null,
          channelId: channelId,
          channel_name: channelName,
          file_id: '',
        });
      }
    }
  }

  public isProgramChecked(prog): boolean {
    if (this.uploadOption !== 3) {
      return prog.value;
    } else {
      let returnVal = false;
      if (this.fileChannel.length > 0) {
        const file = this.fileChannel.find((f) => f.filename === this.selectedFile.filename);
        if (file) {
          if (file.program === prog.key) {
            returnVal = true;
          }
        }
      }
      return returnVal;
    }
  }

  public hideChannels(): void {
    this.showPrograms = false;
    this.selectedChannels = [];
  }

  public hideSkipButton(): boolean {
    if (this.isEditMode) {
      return this.fileData.status !== 'Success';
    } else {
      return this.showSteps && !this.showCustomPage;
    }
  }

  async s3Upload(type?) {
    try {
      let isUploaded = false;

      let fileList = type === 'territoryAlignemt' ? this.territoryAlignmentFileList : this.fileList;

      fileList.map((file) => {
        if (!file['presigned'] && file['isValid']) {
          file['presigned'] = true;
          const payload = {
            file_name: file.name,
          };
          this.httpService.postRequest('get_presigned_url', payload).subscribe((data) => {
            // this.httpService.getRequest('get_presigned_url').subscribe((data) => {
            const s3Data = new FormData();
            s3Data.append('AWSAccessKeyId', data.fields.AWSAccessKeyId);
            s3Data.append('key', data.fields.key);
            s3Data.append('policy', data.fields.policy);
            s3Data.append('signature', data.fields.signature);
            s3Data.append('x-amz-security-token', data.fields['x-amz-security-token']);
            s3Data.append('file', file);

            if (data.status !== 'ERROR') {
              this.httpService.postToUrl(data.url, s3Data).subscribe(
                (response) => {
                  file['isUploaded'] = true;
                  isUploaded = true;
                  console.log('post response: ', response, this.checkUploadStatus(fileList));
                  // if (!this.checkUploadStatus()) {
                  this.delimiterCheck(null, null, type);
                  // }
                },
                (error) => {
                  file['isUploaded'] = false;
                  file['isValid'] = false;
                  file['inProgress'] = false;

                  if (type === 'territoryAlignemt') {
                    this.territoryAlignmentIsInprogress = false;
                    this.territoryAlignmentasDelimiter = false;
                    this.territoryAlignmentUploadError = true;
                    this.territoryAlignmentCustomError.push({
                      filename: file.name,
                      error:
                        'File failed to upload. Please check you have sufficient permissions to upload the file. Please reach out to the AffinityMonitor team for any questions.'
                    });
                  } else {
                    this.isInprogress = false;
                    this.hasDelimiter = false;
                    this.showUploadError = true;
                    this.customError.push({
                      filename: file.name,
                      error:
                        'File failed to upload. Please check you have sufficient permissions to upload the file. Please reach out to the AffinityMonitor team for any questions.'
                    });
                  }


                  console.log('post s# Error: ', error);
                },
              );
            } else {
              file['isUploaded'] = true;
              file['isValid'] = false;
              file['inProgress'] = false;

              if (type === 'territoryAlignemt') {
                this.territoryAlignmentIsInprogress = false;
                if (data.duplicate) {
                  this.territoryAlignmentisDuplicateFile = true;
                  this.territoryAlignmentUploadError = true;
                  this.territoryAlignmentCustomError.push({
                    filename: file.name,
                    error: data.message
                  });
                }

              } else {
                this.isInprogress = false;
                if (data.duplicate) {
                  this.isDuplicateFile = true;
                  this.showUploadError = true;
                  this.customError.push({
                    filename: file.name,
                    error: data.message
                  });
                }
              }



            }
          });
          // if (!this.checkUploadStatus()){
          //         this.delimiterCheck();
          // }
        }
      });
    } catch (err) {
      console.log('err: ', err);
    }
  }

  /*
  Method used to check if all the files are uploaded.
  Will return true if file upload is pending.
   */
  public checkUploadStatus(fileList = this.fileList): boolean {
    if (fileList.length < 1) {
      return false;
    } else {
      return !!fileList.find((file) => file['isUploaded'] === false);
    }
  }

  public delimiterCheck(currentStep?, skip?, territoryAlignemt?): boolean {
    let isValid = true;
    const payload = {
      files: [],
    };

    let fileList = territoryAlignemt === 'territoryAlignemt' ? this.territoryAlignmentFileList : this.fileList;
    let customError = territoryAlignemt === 'territoryAlignemt' ? this.territoryAlignmentCustomError : this.customError;

    if (fileList.length < 1) {
      this.s3FileList.map((f) => {
        payload.files.push({
          file_name: f.filename,
          s3_path: f.s3_path,
          file_type: 's3'
        });
      });
    } else {
      fileList.map((f) => {
        if (f['isValid'] && f['isUploaded']) {
          payload.files.push({
            file_name: f.name,
            file_type: 'web'
          });
        }
      });
    }

    this.httpService.postRequest('delimiter_checks', payload).subscribe((data) => {
      // this.httpService.getRequest('delimiter_checks').subscribe(data => {
      data.data.map((file) => {
        let temp;
        let isS3Upload = false;
        if (fileList.length < 1) {
          temp = this.s3FileList.find((d) => 'in/' + d.filename === file.file_name);
          isS3Upload = true;
        } else {
          temp = fileList.find((d) => d.name === file.file_name);
        }
        if (temp) {
          temp['isValid'] = file.isValid;
          // temp['isUploaded'] = file.isValid;
          if (!file.isValid) {

            if (territoryAlignemt === 'territoryAlignemt') {
              this.territoryAlignmentasDelimiter = false;
              this.territoryAlignmentUploadError = true;
            } else {
              this.hasDelimiter = false;
              this.showUploadError = true;
            }

            // Filename can be added to error message if required.
            customError.push({
              filename: file['file_name'],
              error: file['message'] ? file['message'] : "Delimiter checks failed. Please ensure that all uploaded files must have the ‘pipe’ delimiter."
            });
          }
          if (isValid) {
            isValid = file.isValid;
          }
        }
        if (!isS3Upload) {
          temp['inProgress'] = false;
        }
      });
      if (isValid && currentStep) {
        if (!skip) {
          const index = this.incompleteSteps.indexOf(this.activeStep);
          if (index !== -1) {
            this.incompleteSteps.splice(index, 1);
          }
        }
        this.activeStep = currentStep + 1;
      }

      if (territoryAlignemt === 'territoryAlignemt') {
        this.territoryAlignmentIsInprogress = false;
      } else {
        this.isInprogress = false;
      }

    },
      (error: any) => {
        console.log('something went wrong');
        customError.push({
          filename: 'Error',
          error: 'Something went wrong. Please try again..'
        });
      });

    return isValid;
  }
}
