<nz-table
  #programApprovalTable
  [nzData]="programData"
  class="program-approval-table"
  nzTableLayout="fixed"
  [nzShowPagination]="true"
  [nzPageSize]="8"
  [nzShowQuickJumper]="true"
  [nzNoResult]="noRecords"
  [(nzPageIndex)]="pageIndex"
  [nzLoading]="loading"
>
  <thead>
    <tr>
      <th>User</th>
      <th nzCustomFilter class="client-filter">
        <nz-filter-trigger [nzDropdownMenu]="customFilterClient" [(nzVisible)]="showFilterClient" (nzVisibleChange)="openChange($event)">
          <i
            nz-icon
            nzType="filter"
            nzTheme="fill"
            [ngClass]="highlightFilterClient() ? 'highlight' : ''"
          ></i>
        </nz-filter-trigger>
        <nz-dropdown-menu #customFilterClient="nzDropdownMenu">
          <div class="dropdown-container">
            <div class="ant-table-filter-dropdown">
              <ul nz-menu *ngFor="let item of client">
                <li nz-menu-item>
                  <label nz-checkbox [(ngModel)]="item.value"> {{ item.text }} </label>
                </li>
              </ul>
            </div>
            <div class="button-wrapper">
              <a (click)="resetClient()">Reset</a>&nbsp;
              <button
                nz-button
                nzType="primary"
                class="app-primary-button button-class"
                (click)="filter(false)"
              >
                OK
              </button>
            </div>
          </div>
        </nz-dropdown-menu>
        Client
      </th>
      <th>Program Name</th>
      <th nzCustomFilter class="channel-filter">
        <nz-filter-trigger [nzDropdownMenu]="customFilterChannel" [(nzVisible)]="showFilterChannel" (nzVisibleChange)="openChange($event)">
          <i
            nz-icon
            nzType="filter"
            nzTheme="fill"
            [ngClass]="highlightFilterChannel() ? 'highlight' : ''"
          ></i>
        </nz-filter-trigger>
        <nz-dropdown-menu #customFilterChannel="nzDropdownMenu">
          <div class="dropdown-container">
            <div class="ant-table-filter-dropdown">
              <ul nz-menu *ngFor="let item of channel">
                <li nz-menu-item>
                  <label nz-checkbox [(ngModel)]="item.value"> {{ item.text }} </label>
                </li>
              </ul>
            </div>
            <div class="button-wrapper">
              <a (click)="resetChannel()">Reset</a>&nbsp;
              <button
                nz-button
                nzType="primary"
                class="app-primary-button button-class"
                (click)="filter(false)"
              >
                OK
              </button>
            </div>
          </div>
        </nz-dropdown-menu>
        Channel
      </th>
      <th [nzSortOrder]="sortOrder" (click)="filter(true)" [nzSortDirections]="sortDirection">
        Date
      </th>
      <th nzCustomFilter class="status-filter">
        <nz-filter-trigger [nzDropdownMenu]="customFilterStatus" [(nzVisible)]="showFilterStatus" (nzVisibleChange)="openChange($event)">
          <i
            nz-icon
            nzType="filter"
            nzTheme="fill"
            [ngClass]="highlightFilterStatus() ? 'highlight' : ''"
          ></i>
        </nz-filter-trigger>
        <nz-dropdown-menu #customFilterStatus="nzDropdownMenu">
          <div class="dropdown-container">
            <div class="ant-table-filter-dropdown">
              <ul nz-menu *ngFor="let item of status">
                <li nz-menu-item>
                  <label nz-checkbox [(ngModel)]="item.value"> {{ item.text }} </label>
                </li>
              </ul>
            </div>
            <div class="button-wrapper">
              <a (click)="resetStatus()">Reset</a>&nbsp;
              <button
                nz-button
                nzType="primary"
                class="app-primary-button button-class"
                (click)="filter(false)"
              >
                OK
              </button>
            </div>
          </div>
        </nz-dropdown-menu>
        Status
      </th>
      <th>Actions</th>
    </tr>
  </thead>
  <tbody>
    <tr *ngFor="let data of programApprovalTable.data">
      <td class="row-data">
        <a
          (click)="
            generateMailData(data['email'], data['user'], data['program_name'], data['channel'])
          "
          >{{ data.user }}</a
        >
      </td>
      <td class="row-data">{{ data.client }}</td>
      <td class="row-data">{{ data.program_name }}</td>
      <td class="row-data">{{ data.channel }}</td>
      <td class="row-data">{{ getDate(data.date) }}</td>

      <td class="row-data">
        <span *ngIf="data.status === 'Pending'" class="pending"
          ><i nz-icon nzType="clock-circle" nzTheme="outline"></i
        ></span>
        <span *ngIf="data.status === 'Approved'" class="approved"
          ><i nz-icon nzType="check-circle" nzTheme="fill"></i
        ></span>
        <span *ngIf="data.status === 'Modified & Approved'" class="modified"
          ><i nz-icon nzType="check-circle" nzTheme="outline"></i
        ></span>
        <span *ngIf="data.status === 'Rejected'" class="rejected"
          ><i nz-icon nzType="minus-circle" nzTheme="fill"></i
        ></span>
        &nbsp;{{ data.status }}
      </td>
      <td class="review" (click)="reviewClicked(data)">
        <i nz-icon nzType="file-search" nzTheme="outline"></i>&nbsp;&nbsp;Review
      </td>
    </tr>
  </tbody>
  <ng-template #noRecords>
    <div class="no-requests">{{noDataMessage}}</div>
  </ng-template>
</nz-table>

<app-program-approval-modal
  *ngIf="showReviewModal"
  [showReviewModal]="showReviewModal"
  (hideReviewModal)="onReviewClose($event)"
  [customProgramId]="customProgramId"
  [programStatus]="programStatus"
  (reloadPage)="loadData()"
>
</app-program-approval-modal>
