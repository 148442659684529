<div class="review-wrapper">
  <nz-modal
    nzTitle="Review Program Request"
    [(nzVisible)]="showReviewModal"
    [nzWidth]="'624px'"
    [nzWrapClassName]="'modal-wrapper'"
    [nzMaskClosable]="false"
    [nzFooter]="footer"
    (nzOnCancel)="onCancel()"
  >
    <ng-container *nzModalContent>
      <div
        *ngIf="!showRejectScreen && !showModifyScreen"
        class="review-content"
        (scroll)="scrollHandler($event)"
      >
        <div class="data-1">
          Please review the program name, channel, and the data layout file provided by the
          client-user and make modifications if necessary.
        </div>
        <div class="data-1">
          Rejecting this custom program will automatically reject any linked data changes. Kindly
          make sure to review the data changes tab as well.
        </div>
        <div class="spin" *ngIf="loading"><nz-spin nzSimple></nz-spin></div>
        <div class="data-2-wrapper" *ngIf="!loading">
          <div
            class="error-message"
            *ngIf="
              errorMessage && !channelEditted && !programEditted && programStatus === 'Pending'
            "
          >
            {{ errorMessage }}
          </div>
          <div *ngIf="!loading">
            <div class="data-2">
              <div class="data-header">Program</div>
              <div *ngIf="!editProgramClicked">
                <div *ngIf="!loading">
                  {{ programValue }}
                  <span class="edit-icon"
                    ><i
                      nz-icon
                      nzType="edit"
                      nzTheme="outline"
                      (click)="editProgramClicked = true"
                      [ngClass]="programStatus !== 'Pending' ? 'disabled' : ''"
                    ></i
                  ></span>
                </div>
              </div>
              <div *ngIf="editProgramClicked">
                <input
                  nz-input
                  [ngClass]="isValidProgram && validProgramChar ? 'input' : 'error-input'"
                  [(ngModel)]="programValue"
                  (keyup)="checkProgram()"
                />
                <span *ngIf="this.isValidProgram && this.validProgramChar && this.programValue" (click)="saveProgram()" class="save-button">Save</span>
                <span *ngIf="!(this.isValidProgram && this.validProgramChar && this.programValue)" class="save-button-disabled">Save</span>
                <div class="error-3" *ngIf="!isValidProgram">
                  Program Title length must be 50 characters or less. Please enter a valid input.
                </div>
                <div class="error-3" *ngIf="!validProgramChar">
                  \ , / , * , ? , : , [ , ] are not allowed in the program name.
                </div>
              </div>
            </div>
            <div class="data-2">
              <div class="data-header">Channel</div>
              <div *ngIf="!editChannelClicked">
                <div *ngIf="!loading">
                  {{ selectedChannel }}
                  <span class="edit-icon"
                    ><i
                      nz-icon
                      nzType="edit"
                      nzTheme="outline"
                      (click)="editChannelClicked = true"
                      [ngClass]="programStatus !== 'Pending' ? 'disabled' : ''"
                    ></i
                  ></span>
                </div>
              </div>
              <div *ngIf="editChannelClicked">
                <app-dropdown
                  [isMultiple]="false"
                  [isSearchable]="true"
                  [data]="channelData"
                  [defaultSelected]="selectedChannel"
                  (selectedValue)="channelSelected($event)"
                ></app-dropdown>
                <span *ngIf="selectedChannel" (click)="saveChannel()" class="save-button">
                  Save
                </span>
                <span *ngIf="!selectedChannel" class="save-button-disabled"> Save </span>
              </div>
            </div>
            <div class="data-2">
              <div class="data-header">Description</div>
              <div *ngIf="!loading">
                <div class="description" *ngIf="programReview.description.length > 0">
                  {{ programReview.description }}
                </div>
                <div class="description" *ngIf="programReview.description.length <= 0">-</div>
              </div>
            </div>
            <div class="data-2">
              <div class="data-header">Select Product</div>
              <div *ngIf="!loading">
                <div>
                  <nz-checkbox-group
                    [(ngModel)]="productOptions"
                    (ngModelChange)="productSelected(productOptions)"
                    [disabled]="programStatus !== 'Pending'"
                  ></nz-checkbox-group>
                </div>
              </div>
            </div>
            <div class="data-2">
              <div class="data-header">Data layout file uploaded</div>
              <div *ngIf="!loading">
                <div class="file-name-1" (click)="downloadFile(programReview.layoutFileUrl, '')">
                  <i nz-icon nzType="file" nzTheme="fill" class="file-icon"></i>
                  {{ programReview.layoutFileName }}
                </div>
              </div>
            </div>
          </div>
          <div class="data-2">
            <div class="data-header">Upload updated data layout file <span>(optional)</span></div>
            <div>
              <div class="upload-description">
                Please upload a data layout/template in .csv format.
              </div>
              <div>
                <nz-upload
                  nzType="drag"
                  *ngIf="fileList.length <= 0"
                  [nzFileListRender]="fileListRef"
                  (nzChange)="fileChanged($event)"
                  [(nzFileList)]="nzFileList"
                  [nzMultiple]="false"
                  [nzDisabled]="programStatus !== 'Pending'"
                >
                  <p class="ant-upload-text">
                    Drag a file here to upload or
                    <button
                      nz-button
                      nzType="primary"
                      [ngClass]="
                        programStatus === 'Pending'
                          ? 'app-primary-button'
                          : 'app-primary-button-disabled'
                      "
                      [disabled]="programStatus !== 'Pending'"
                    >
                      <span class="choose-file">Choose File</span>
                    </button>
                  </p>
                  <ng-template #fileListRef> </ng-template>
                </nz-upload>

                <div *ngIf="fileList.length > 0">
                  <div class="progress-wrapper" *ngIf="isInprogress">
                    <div class="file-name">
                      <i nz-icon nzType="cloud-upload" nzTheme="outline"></i>&nbsp;
                      <span class="file-name"
                        ><strong>{{ fileList[0].name }}</strong></span
                      >
                    </div>
                    <div class="progress-bar">
                      <nz-progress
                        nzStrokeLinecap="square"
                        nzStrokeColor="#2B44C7"
                        [nzPercent]="uploadPercent"
                      ></nz-progress>
                    </div>
                  </div>
                  <div *ngIf="!isInprogress && !showUploadError; else inValidFile">
                    <div nz-row class="title-row file-list">
                      <div nz-col nzSpan="22" class="title">
                        <i nz-icon nzType="check-circle" nzTheme="fill" class="check-icon"></i>
                        <span class="file-name">{{ fileList[0].name }}</span>
                      </div>
                      <div nz-col nzSpan="1" class="link delete-icon" (click)="deleteFile(true)">
                        <i nz-icon nzType="delete" nzTheme="outline"></i>
                      </div>
                    </div>
                  </div>
                  <ng-template #inValidFile>
                    <div *ngIf="!isInprogress" nz-row class="title-row file-error">
                      <div nz-col nzSpan="22" class="title">
                        <span class="file-name">{{ fileList[0].name }}</span>
                      </div>
                      <div nz-col nzSpan="1" class="link delete-icon" (click)="deleteFile(false)">
                        <i nz-icon nzType="delete" nzTheme="outline"></i>
                      </div>
                    </div>
                  </ng-template>
                  <div class="error-message" *ngIf="showUploadError">
                    <div>The file type cannot be accepted. Please ensure the file is in .csv</div>
                  </div>
                </div>
              </div>
            </div>
          </div>
          <div class="data-2" *ngIf="programStatus !== 'Pending'">
            <div class="data-header" *ngIf="programStatus === 'Rejected'">Reason for Rejection</div>
            <div class="data-header" *ngIf="programStatus === 'Modified & Approved'">
              Notes for Modification
            </div>
            <div *ngIf="!loading">
              {{ programReview.reason }}
            </div>
          </div>
        </div>
      </div>
      <div *ngIf="showRejectScreen && !showModifyScreen">
        <div class="reject-wrapper">
          <div class="data-header">Reason for Rejection</div>
          <div>
            <textarea
              nz-input
              [(ngModel)]="reason"
              (keyup)="checkReason()"
              placeholder="Enter Reason for Rejection"
              [nzAutosize]="{ minRows: 8, maxRows: 8 }"
              [ngClass]="isValidReason ? 'text' : 'error'"
              (keydown)="disableEnter($event)"
            ></textarea>
            <div class="error-3" *ngIf="!isValidReason">
              The input value for this field requires 2000 characters. Please enter valid input.
            </div>
          </div>
        </div>
      </div>
      <div *ngIf="showModifyScreen && !showRejectScreen">
        <div class="reject-wrapper">
          <div class="data-header">Program Modification Notes</div>
          <div>
            <textarea
              nz-input
              [(ngModel)]="reason"
              (keyup)="checkReason()"
              placeholder="Enter Notes for Modifications"
              [nzAutosize]="{ minRows: 8, maxRows: 8 }"
              [ngClass]="isValidReason ? 'text' : 'error'"
              (keydown)="disableEnter($event)"
            ></textarea>
            <div class="error-3" *ngIf="!isValidReason">
              The input value for this field requires 2000 characters. Please enter valid input.
            </div>
          </div>
        </div>
      </div>
    </ng-container>
  </nz-modal>
</div>

<ng-template #footer>
  <nz-space *ngIf="!showRejectScreen && !showModifyScreen">
    <div *nzSpaceItem class="cancel-button" (click)="onCancel()">Cancel</div>
    <div *nzSpaceItem>
      <button
        nz-button
        [nzType]="'default'"
        (click)="onReject()"
        [disabled]="
          editChannelClicked ||
          editProgramClicked ||
          showUploadError ||
          !selectedProducts ||
          loading
        "
        [ngClass]="
          editChannelClicked ||
          editProgramClicked ||
          showUploadError ||
          loading ||
          !selectedProducts
            ? 'default-button-disabled'
            : 'default-button'
        "
        *ngIf="programStatus === 'Pending'"
      >
        <i nz-icon nzType="minus-circle" nzTheme="fill"></i> Reject Request
      </button>
    </div>
    <div *nzSpaceItem>
      <button
        nz-button
        [nzType]="'primary'"
        [ngClass]="buttonDisabled() ? 'app-primary-button-disabled' : 'app-primary-button'"
        (click)="onApprove()"
        [disabled]="buttonDisabled()"
        *ngIf="programStatus === 'Pending'"
      >
        <i nz-icon nzType="check" nzTheme="outline"></i> Approve Request
      </button>
    </div>
  </nz-space>
  <nz-space *ngIf="showRejectScreen && !showModifyScreen">
    <div *nzSpaceItem>
      <button nz-button [nzType]="'default'" (click)="onBack()">Back</button>
    </div>
    <div *nzSpaceItem>
      <button
        nz-button
        [nzType]="'primary'"
        [ngClass]="isdisabled() ? 'app-primary-button-disabled' : 'app-primary-button'"
        [disabled]="isdisabled()"
        (click)="submitClicked(true)"
      >
        Reject
      </button>
    </div>
  </nz-space>
  <nz-space *ngIf="!showRejectScreen && showModifyScreen">
    <div *nzSpaceItem>
      <button nz-button [nzType]="'default'" (click)="onBack()">Back</button>
    </div>
    <div *nzSpaceItem>
      <button
        nz-button
        [nzType]="'primary'"
        [disabled]="isdisabled()"
        [ngClass]="isdisabled() ? 'app-primary-button-disabled' : 'app-primary-button'"
        (click)="submitClicked(false)"
      >
        Approve
      </button>
    </div>
  </nz-space>
</ng-template>
