<div>
  <strong>{{ label }}</strong>
</div>
<nz-select
  [(ngModel)]="value[0]"
  [nzSize]="size"
  (ngModelChange)="onChange($event)"
  *ngIf="!isMultiple && !isSearchable"
  [nzPlaceHolder]="placeHolder"
  [nzSuffixIcon]="customIcon"
  [nzAllowClear]="allowClear"
  [nzDisabled]="isDisabled"
  [(nzOpen)]="isOpenSelect"
  (nzOpenChange)="openChange($event)"
  #inputRef
>
  <nz-option *ngFor="let d of data" [nzLabel]="d" [nzValue]="d"></nz-option>
</nz-select>
<nz-select
  [nzAllowClear]="allowClear"
  nzShowSearch
  [(ngModel)]="value[0]"
  [nzSize]="size"
  (ngModelChange)="onChange($event)"
  *ngIf="!isMultiple && isSearchable"
  [nzPlaceHolder]="placeHolder"
  [nzSuffixIcon]="customIcon"
  [nzDisabled]="isDisabled"
  [(nzOpen)]="isOpenSelect"
  (nzOpenChange)="openChange($event)"
  #inputRef
>
  <nz-option *ngFor="let d of data" [nzLabel]="d" [nzValue]="d"></nz-option>
</nz-select>
<nz-select
  nzShowSearch
  nzAllowClear
  [nzPlaceHolder]="placeHolder"
  [(ngModel)]="value"
  [nzSize]="size"
  [nzMaxTagCount]="1"
  [nzMaxTagPlaceholder]="tagPlaceHolder"
  (ngModelChange)="onChange($event)"
  *ngIf="isMultiple && isSearchable"
  nzMode="multiple"
  [nzShowArrow]="true"
  [nzDropdownClassName]="'multiple-dropdown'"
  [nzSuffixIcon]="customIcon"
  [nzDisabled]="isDisabled"
  [(nzOpen)]="isOpenSelect"
  (nzOpenChange)="openChange($event)"
  #inputRef
>
  <nz-option *ngFor="let d of data" [nzLabel]="d" [nzValue]="d"></nz-option>
</nz-select>
<nz-select
  nzAllowClear
  [nzPlaceHolder]="placeHolder"
  [(ngModel)]="value"
  [nzSize]="size"
  (ngModelChange)="onChange($event)"
  *ngIf="isMultiple && !isSearchable"
  nzMode="multiple"
  [nzMaxTagCount]="1"
  [nzMaxTagPlaceholder]="tagPlaceHolder"
  [nzShowArrow]="true"
  [nzDropdownClassName]="'multiple-dropdown'"
  [nzSuffixIcon]="customIcon"
  [nzDisabled]="isDisabled"
  [(nzOpen)]="isOpenSelect"
  (nzOpenChange)="openChange($event)"
  #inputRef
>
  <nz-option *ngFor="let d of data" [nzLabel]="d" [nzValue]="d"></nz-option>
</nz-select>
<ng-template #customIcon>
  <i nz-icon nzType="caret-down" nzTheme="fill"></i>
</ng-template>
<ng-template #tagPlaceHolder let-selectedList>+ {{ selectedList.length }}</ng-template>
