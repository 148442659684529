import { Component, EventEmitter, Input, OnDestroy, OnInit, Output } from '@angular/core';
import { HttpService } from '../shared/services/http.service';
import { NzModalModule } from 'ng-zorro-antd/modal';
import { NzModalRef, NzModalService } from 'ng-zorro-antd/modal';
import { DatePipe } from '@angular/common';
import { AppState } from '../shared/services/app-state';
import { Subscription } from 'rxjs';
import { NzNotificationService } from 'ng-zorro-antd/notification';

@Component({
  selector: 'app-upload-data',
  templateUrl: './upload-data.component.html',
  styleUrls: ['./upload-data.component.scss'],
})
export class UploadDataComponent implements OnInit, OnDestroy {
  public confirmModal?: NzModalRef;

  @Input() showModal = false;
  @Output() hideModal: EventEmitter<boolean> = new EventEmitter<boolean>();

  public defaultValue = 'Latest to Oldest';
  public searchData = [];
  public noResultsFound = false;

  public businessJustification = [];
  public reasonForModification = '';

  public status = 'close';
  public daysLeft = 0;
  public hoursLeft = 0;
  public minutesLeft = 0;
  public nextCycle;
  public windowCloses;
  public currentCycle;

  public showPreview = false;
  public previewFileList = [];

  public deleteFileId = '';
  public statusFlex = '730px';
  public statusFlexClosed = '750px';
  public showTemplates = false;
  public uploadFile = false;
  public businessJust = false;
  public uploadedFiles = [];
  public customPrograms = [];
  public sortBy = [];
  public channel = [];
  public program = [];
  public programChannel = [];
  public fileType = [];
  public statusOption = [];
  public isVisible = false;
  public isLoading = true;
  public payload = {
    filter_data: {
      sort_by: 'Latest to Oldest',
      channel: [],
      program: [],
      file_type: [],
      status: [],
    },
    search_keyword: '',
    offset: 0,
    count: 20,
  };
  fileData: any;
  public mode = '';
  public editAction = false;
  private appStateSubscription: Subscription;
  public userDetails;
  public isAdmin = false;
  public previewHeaders = [];
  public previewData = [];
  public previewTitle = '';
  public previewLoading = true;
  public showCustomerMessage = true;
  public isMsgDisplayed = false;
  public msgStateChanged = false;
  public scrollHeight = 0;
  public scrollToLocation = false;
  public lazyLoading = false;
  public totalRecords = 0;

  constructor(
    private modal: NzModalService,
    private httpService: HttpService,
    public datePipe: DatePipe,
    private notification: NzNotificationService,
    private appState: AppState
  ) {
    this.appState.setGlobalState('offset', 0);
    this.appStateSubscription = AppState.appState.subscribe((val) => {
      if (!this.userDetails) {
        if (val && val.userDetails) {
          this.userDetails = val.userDetails;
          this.isAdmin = this.userDetails.role === 'admin' || this.userDetails.role === 'manager';
        }
      }
      if (!this.msgStateChanged){
       if (val && typeof val.showCustomerMessage !== 'undefined' && !val.showCustomerMessage) {
          this.showCustomerMessage = val.showCustomerMessage;
          this.msgStateChanged = true;
       }
      }

      if (val && val.offset !== this.payload.offset) {
        if (this.uploadedFiles.length < this.totalRecords) {
          this.payload.offset = val.offset;
          if (this.payload.offset === 0) {
            this.uploadedFiles = [];
          }
          this.loadData(false, true);
        }
      }
    });
  }

  ngOnInit(): void {
    this.loadData(true);
  }

  public onTemplateClose(event): void {
    this.showTemplates = event;
  }

  public businessJustClose(event): void {
    this.businessJust = event;
  }

  public onUploadFileClose(event): void {
    this.uploadFile = event;
    this.isLoading = true;
    this.uploadedFiles = [];
    this.payload.offset = 0;
    this.appState.setGlobalState('offset', 0);
    this.loadData(true);
  }

  public reloadPage(event): void {
    if (event) {
      this.isLoading = true;
      this.uploadedFiles = [];
      this.payload.offset = 0;
      this.appState.setGlobalState('offset', 0);
      this.loadData(true);
    }
  }

  public businessJustClicked(event): void {
    if (event.nextMove) {
      if (!event.isNewData) {
        this.editData(this.fileData);
        this.businessJustification = event.businessJustification;
        this.reasonForModification = event.reason;
      } else {
        this.mode = 'save';
        this.uploadFile = true;
      }
    } else {
      this.deleteConfirm(this.deleteFileId);
      this.businessJustification = event.businessJustification;
      this.reasonForModification = event.reason;
    }
  }

  async loadData(isInitial, setScroll = false): Promise<void> {
    if (!setScroll) {
      this.isLoading = true;
    } else {
      this.lazyLoading = true;
    }
    await this.httpService.postRequest('get_upload_data', this.payload).toPromise().then( (result) => {
    // await this.httpService.getRequest('get_upload_data').toPromise().then(
    //   (result) => {
        if (isInitial) {
          this.sortBy = result.filter_data.sort_by;
          this.channel = result.filter_data.channel;
          this.program = result.filter_data.program;
          this.fileType = result.filter_data.file_type;
          this.statusOption = result.filter_data.status;
          this.checkCustomerMaster(result.display_customer_msg);
        }

        this.status = result.upload_window.current_status === 'Open' ? 'open' : 'close';
        this.nextCycle = result.upload_window.next_cycle;
        this.currentCycle = result.upload_window.current_cycle;
        this.windowCloses = result.upload_window.window_closes;
        this.totalRecords = result.total_records;

        this.daysLeft = result.upload_window.days_left;
        this.hoursLeft = result.upload_window.hours_left;
        this.minutesLeft = result.upload_window.minutes_left;

        if (this.status === 'close') {
          this.statusFlex = '800px';
        }
        this.uploadedFiles = result.data;
        this.customPrograms = result.custom_programs;
        this.searchData = result.search_keyword;
        if (this.payload.search_keyword) {
          this.uploadedFiles.length < 1
            ? (this.noResultsFound = true)
            : (this.noResultsFound = false);
        }
        this.isLoading = false;
        this.lazyLoading = false;
        if (this.payload.offset >= 20) {
          this.scrollToLocation = true;
        }
      },
      (error) => {
        console.log('something went wrong', error);
        this.uploadedFiles = [];
        this.isLoading = false;
      },
    );
  }

  public updatePayload(event, field): void {
    this.isLoading = true;
    if (field === 'sort_by') {
      if (event) {
        this.payload.filter_data[field] = event;
      } else {
        this.payload.filter_data[field] = 'Latest to Oldest';
      }
    } else {
      this.payload.filter_data[field] = event;
    }
    this.uploadedFiles = [];
    this.payload.offset = 0;
    this.appState.setGlobalState('offset', 0);
    this.loadData(false);
  }

  public deleteFiles(deleteId): void {
    const payload = {
      upload_file_id: deleteId,
      bussiness_justification: this.businessJustification,
      bussiness_justification_reason: this.reasonForModification,
    };
    this.httpService.postRequest('delete_upload_data', payload).subscribe((result) => {});
  }

  deleteConfirm(deleteId): void {
    if (deleteId) {
      this.deleteFileId = deleteId;
    }
    this.confirmModal = this.modal.confirm({
      nzContent: 'Are you sure you want to delete this record?',
      nzOkText: 'Yes, Delete',
      nzOnOk: () => {
        this.deleteFiles(this.deleteFileId);
        this.hideModal.emit(false);
        this.showModal = false;
        this.loadData(false);
      },
      nzOnCancel: () => {
        this.showModal = true;
      },
    });
  }

  deleteClicked(file): void {
    this.deleteFileId = file.upload_file_id;
    this.editAction = false;
    if (file.status === 'Incomplete') {
      this.deleteConfirm(this.deleteFileId);
    } else {
      this.businessJust = true;
    }
  }

  public filterOnSearch(key): void {
    this.payload.search_keyword = key.trim();
    this.noResultsFound = false;
    this.uploadedFiles = [];
    this.payload.offset = 0;
    this.appState.setGlobalState('offset', 0);
    this.isLoading = true;
    this.loadData(false);
  }

  public formatOnNoresult(flag): void {
    if (flag) {
      this.uploadedFiles = [];
      this.isLoading = false;
      this.noResultsFound = true;
    }
  }

  public resetSearch(key): void {
    this.isLoading = true;
    this.noResultsFound = false;
    this.payload.search_keyword = '';
    this.uploadedFiles = [];
    this.payload.offset = 0;
    this.appState.setGlobalState('offset', 0);
    this.loadData(false);
  }

  public editData(file): void {
    this.mode = 'edit';
    this.fileData = file;
    this.uploadFile = true;
  }

  public editClicked(file): void {
    if (this.status !== 'open' && file.status === 'Incomplete') {
      const msg =
        'This data cannot be considered since the upload window is closed for this cycle. Please reach out to the AffinityMonitor team for any questions.';
      this.notification.blank('', msg, {
        nzPlacement: 'bottomLeft',
        nzStyle: { background: 'red', color: '#FFFFFF'},
      });
    } else {
      this.mode = 'edit';
      this.fileData = file;
      this.editAction = true;
      if (file.status === 'Incomplete' || file.status === 'Issues') {
        this.editData(this.fileData);
      } else {
        this.businessJust = true;
      }
    }
  }

  public getChannelCount(channels): number {
    let count = 0;
    channels.forEach((channel) => {
      count = count + channel.programs.length;
    });
    return count;
  }

  public getClass(id): string {
    let divClass = '';
    switch (id) {
      case 1:
        divClass = 'personal-f';
        break;
      case 2:
        divClass = 'personal-r';
        break;
      case 3:
        divClass = 'peer-i';
        break;
      case 4:
        divClass = 'digital-push';
        break;
      case 5:
        divClass = 'digital-pull';
        break;
      case 6:
        divClass = 'direct';
        break;
      case 7:
        divClass = 'customer-m';
        break;
      case 8:
        divClass = 'offer';
        break;
      default:
        divClass = 'none';
        break;
    }
    return divClass;
  }

  public showProgram(program, displayProgram): boolean {
    return !displayProgram.find((data) => data.name === program);
  }

  public getFilenames(files): string {
    let filenames = '';
    let editedFilenames = '';
    files.forEach((file) => {
      if (file.filename) {
        filenames = filenames + file.filename + ', ';
      }
    });
    editedFilenames = filenames.slice(0, -2);
    return editedFilenames;
  }

  public getCustomProgram(programId): boolean {
    let found = false;
    const customProgram = this.customPrograms.find(
      (program) => program.custom_program_id === programId,
    );
    if (customProgram) {
      found = true;
    } else {
      found = false;
    }
    return found;
  }

  public getDate(date, monthFormat): string {
    // TODO: Uncomment code from this block if date format is to be changed
    // if (monthFormat) {
      if (date === 'N/A') {
        return date;
      } else {
        try {
          return date ? this.datePipe.transform(new Date(date), 'dd MMM y') : 'N/A';
        } catch (e) {
          return 'invalid date';
        }
      }
    // }
    // if (!monthFormat) {
    //   if (date === 'NA') {
    //     return date;
    //   } else {
    //     try {
    //       return date ? this.datePipe.transform(new Date(date), 'yyyy-MM-dd') : 'NA';
    //     } catch (e) {
    //       return 'invalid date';
    //     }
    //   }
    // }
  }

  ngOnDestroy(): void {
    this.appState.setGlobalState('offset', 0);
    this.appStateSubscription.unsubscribe();
  }

  public programsSelected(files): boolean {
    return !files.some((file) => file.program);
  }

  public showPreviewPopup(files): void {
    this.showPreview = true;
    this.previewFileList = files;
  }

  public createBasicNotification(): void {
    if (!this.isMsgDisplayed) {
      let msg = '';
      let bgColor = '';
      msg = 'Customer master file missing or under verification. Kindly ensure a valid customer master is uploaded by the closing date.';
      bgColor = 'red';
      const test = this.notification.blank('',  msg, {
        nzPlacement: 'bottomLeft',
        nzDuration: 0,
        nzStyle: { background: bgColor, color: '#FFFFFF', padding: '20px'}
      }).onClose;
      test.subscribe(isClosed => {
        if (isClosed){
          this.appState.setGlobalState('showCustomerMessage', false);
        }
      });
      this.isMsgDisplayed = true;
    }
  }

  public checkCustomerMaster(display): void {
    if (!this.isAdmin && display) {
      if (this.showCustomerMessage){
       this.createBasicNotification();
      }
    } else {
      this.appState.setGlobalState('showCustomerMessage', true);
    }
  }
}
