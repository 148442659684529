import { Component, OnInit } from '@angular/core';
import { HttpService } from '../shared/services/http.service';
import { DownloadReports } from '../shared/models/download-reports.model';
import { DatePipe } from '@angular/common';
import { NzNotificationService } from 'ng-zorro-antd/notification';
import { AppState } from '../shared/services/app-state';
import { Subscription } from 'rxjs';

@Component({
  selector: 'app-download-reports',
  templateUrl: './download-reports.component.html',
  styleUrls: ['./download-reports.component.scss'],
})
export class DownloadReportsComponent implements OnInit {
  public expandSet = new Set<number>();
  public expandInnerSet = new Set<number>();
  public allChecked = false;
  public indeterminate = false;
  public horizontalSpace = 'middle';
  public loading = false;
  public previewModalTitle: string;
  public checkboxAllFlag = true;
  public previewData;
  public dashboardData;
  public filteredData: DownloadReports[] = [];
  public previewTableData;
  public checkDisabled = false;
  public checkAllEnabled = false;
  public reportsEmpty = false;
  public sortColumns = {
    year: {
      sortOrder: 'ascend',
      sortDirection: ['ascend', 'descend'],
    },
    semester: {
      sortOrder: 'ascend',
      sortDirection: ['ascend', 'descend'],
    },
    date: {
      sortOrder: 'ascend',
      sortDirection: ['ascend', 'descend'],
    },
  };
  public client = '';
  private appStateSubscription: Subscription;

  public reportData: DownloadReports[] = [];
  public previewReportFlag: boolean;
  public disabledReportMap = {};
  public searchParamData = [];
  public showNotification = false;
  public notifyType = 'success';
  public pageIndex = 1;

  constructor(
    private httpService: HttpService,
    public datePipe: DatePipe,
    private notification: NzNotificationService,
    private appState: AppState
  ) {
    this.appStateSubscription = AppState.appState.subscribe((val) => {
      if (!this.client) {
        if (val && val.userDetails) {
          this.client = val.userDetails.client;
        }
      }
    });
  }

  ngOnInit(): void {
    this.loading = true;
    this.httpService.getRequest('get_download_reports').subscribe((data) => {
      this.dashboardData = data;
      this.reportData = data.report;
      this.filteredData = data.report;
      this.loading = false;
      this.searchParamData = data.reportNames;
      if (this.reportData.length === 0) {
        this.reportsEmpty = true;
      } else {
        this.reportsEmpty = false;
      }
    });
  }
  public showPreviewPopup(previewTitle): void {
    this.previewData = this.dashboardData[previewTitle];
    this.previewTableData = this.dashboardData[previewTitle];
    switch (previewTitle) {
      case 'accessMonitorMonthlyDataReport':
        this.previewModalTitle = 'AccessMonitor Monthly HCP Data Report';
        break;
      case 'affinityMonitorDataReport':
        this.previewModalTitle = 'AffinityMonitor HCP Data Report';
        break;
      case 'accessMonitorSemesterlyDataReport':
        this.previewModalTitle = 'AccessMonitor HCP Data Report';
        break;
      default:
        this.previewModalTitle = 'Title not assigned';
        break;
    }
    this.previewReportFlag = true;
  }

  public hidePreview(event): void {
    this.previewReportFlag = event;
  }

  onExpandChange(id: number, checked: boolean): void {
    if (checked) {
      this.expandSet.add(id);
    } else {
      this.expandSet.delete(id);
    }
  }

  onExpandInnerChange(id: number, checked: boolean): void {
    if (checked) {
      this.expandInnerSet.add(id);
    } else {
      this.expandInnerSet.delete(id);
    }
  }

  /**
   * method called to update selected/unselected checkbox
   * @param level: the table level in which the change is made.
   */
  refreshStatus(event?, semId?: number): void {
    const validData = this.reportData;
    const allChecked = validData.length > 0 && validData.some((value) => value.checked === true);
    const allUnChecked = validData.some((value) => !value.checked);
    this.allChecked = allChecked && !allUnChecked;
    this.indeterminate = allChecked && allUnChecked;
  }

  /**
   * method used to select/unselect all checkbox
   * @param value: boolean flag to identify if value is to be checked/unchecked
   */
  checkAll(value: boolean, level?, semId?, folderId?): void {
    this.checkboxAllFlag = !value;
    this.allChecked = value;
    this.checkAllEnabled = value;
    this.reportData.map((data) => {
      data.reports.map((report) => {
        report.files.map((file) => {
          if (file.downloadable) {
            file.checked = value;
            report.checked = value;
            data.checked = value;
          }
        });
      });
    });
    this.refreshStatus();
  }

  /**
   * update parent/child checkboxes based on selected checkbox.
   * @param value: boolean flag to identify selected/unselected
   * @param level: the level of selected checkbox (semester/folder/file)
   * @param semId: semester id for selected checkbox
   * @param folderId: folder id for selected checkbox
   * @param fileId: file id for selected checkbox
   */
  public updateCheckbox(value: boolean, level, semId, folderId?, fileId?): void {
    this.checkboxAllFlag = !value;
    const folderData = this.reportData.find((semesters) => semesters.id === semId);
    const reports = folderData.reports.find((report) => report.id === folderId);
    let unChecked = false;

    if (level === 'semester') {
      folderData.reports.map((report) => {
        report.files.map((file) => {
          if (file.downloadable) {
            file.checked = value;
            report.checked = value;
          }
        });
      });
      unChecked = this.reportData.some((report) => !report.checked);
      this.allChecked = !unChecked;
    }

    if (level === 'folder') {
      reports.files.map((files) => {
        if (files.downloadable) {
          files.checked = value;
        }
      });
      unChecked = folderData.reports.some((report) => !report.checked);
      folderData.checked = !unChecked;
      unChecked = this.reportData.some((report) => !report.checked);
      this.allChecked = !unChecked;
    }

    if (level === 'file') {
      unChecked = reports.files.some((file) => !file.checked);
      reports.checked = !unChecked;
      unChecked = folderData.reports.some((report) => !report.checked);
      folderData.checked = !unChecked;
      unChecked = this.reportData.some((report) => !report.checked);
      this.allChecked = !unChecked;
    }
  }

  /**
   * Method called to sort the data available
   * @param column: the column on which sorting is applied
   */
  public sortData(column: string, reportIndex?: number): void {
    this.loading = true;
    if (column === 'year' || column === 'semester') {
      if (this.sortColumns[column].sortOrder === 'ascend') {
        this.reportData.sort((a, b) => (a[column] < b[column] ? 1 : -1));
      } else {
        this.reportData.sort((a, b) => (a[column] > b[column] ? 1 : -1));
      }
      this.expandSet.clear();
      this.expandInnerSet.clear();
      this.loading = false;
    }

    if (column === 'date') {
      this.reportData[reportIndex].reports.map((folder) => {
        const files = [...folder.files];
        folder.files = [];
        if (this.sortColumns[column].sortOrder === 'ascend') {
          files.sort((a, b) =>
            this.getTime(a.dateAvailable) === this.getTime(b.dateAvailable)
              ? 1
              : this.getTime(a.dateAvailable) < this.getTime(b.dateAvailable)
              ? 1
              : -1,
          );
        } else {
          files.sort((a, b) =>
            this.getTime(a.dateAvailable) === this.getTime(b.dateAvailable)
              ? 1
              : this.getTime(a.dateAvailable) > this.getTime(b.dateAvailable)
              ? 1
              : -1,
          );
        }
        folder.files = files;
      });
      this.loading = false;
    }

    this.sortColumns[column].sortOrder =
      this.sortColumns[column].sortOrder === 'ascend' ? 'descend' : 'ascend';
  }

  public getTime(date): number {
    return new Date(date).getTime();
  }

  /**
   * returns formatted date.
   * @param date: the date to be formated
   */
  public getDate(date): string {
    return date ? this.datePipe.transform(new Date(date), 'dd MMM y') : '-';
  }
  public resetSearch(key): void {
    this.checkDisabled = false;
    this.checkAll(false);
    if (key) {
      this.reportData = JSON.parse(JSON.stringify(this.filteredData));
    }
  }

  public filterOnSearch(key): void {
    this.checkDisabled = false;
    const filteredDataSet = [];
    this.loading = true;
    if (key) {
      this.filteredData.filter((semester) => {
        semester.reports.filter((report) => {
          if (report.folderName.toLocaleLowerCase().includes(key.toLocaleLowerCase())) {
            filteredDataSet.push(semester);
          } else {
            report.files.filter((file) => {
              if (file.reportName.toLocaleLowerCase().includes(key.toLocaleLowerCase())) {
                filteredDataSet.push(semester);
              } else {
                this.checkDisabled = true;
              }
            });
          }
        });
      });
    }
    this.loading = false;
    this.expandSet.clear();
    this.expandInnerSet.clear();
    this.pageIndex = 1;
    this.reportData = [...new Set(filteredDataSet)];
  }

  public formatOnNoresult(flag): void {
    if (flag) {
      this.allChecked = false;
      this.reportData = [];
      this.checkDisabled = true;
    } else {
      this.checkDisabled = false;
    }
  }

  public downloadFile(url, target): void {
    window.open(url, target);
    this.notifyType = 'success';
    this.showNotification = true;
    this.createBasicNotification(this.notifyType);
  }

  public downloadZip(level, semId?, folderId?, reportId?): void {
    let payload = {};
    payload = this.generatePayload();
    // TODO: Uncomment below code if file, folder level download is to be enabled.
    // if (level === 'all') {
    //   payload = this.getPayloadForAll();
    // } else {
    //   const folderData = this.reportData.find((semesters) => semesters.id === semId);
    //   const folders = folderData.reports.find((report) => report.id === folderId);
    //   const yr = folderData.year;
    //   const sem = folderData.semester;
    //   const affinityMonitor = [];
    //   const accessMonitor = [];

    //   if (level === 'semester') {
    //     folderData.reports.map((folder) =>
    //       folder.files.map((file) => {
    //         if (file.downloadable) {
    //           if (folder.folderName === 'AccessMonitor Reports') {
    //             if (file.checked) {
    //               accessMonitor.push(file.reportId);
    //             }
    //           } else {
    //             if (file.checked) {
    //               affinityMonitor.push(file.reportId);
    //             }
    //           }
    //         }
    //       }),
    //     );
    //   } else if (level === 'folder') {
    //     folders.files.map((file) => {
    //       if (file.downloadable) {
    //         if (folders.folderName === 'AccessMonitor Reports') {
    //           if (file.checked) {
    //             accessMonitor.push(file.reportId);
    //           }
    //         } else {
    //           if (file.checked) {
    //             affinityMonitor.push(file.reportId);
    //           }
    //         }
    //       }
    //     });
    //   } else {
    //     if (folders.folderName === 'AccessMonitor Reports') {
    //       accessMonitor.push(reportId);
    //     } else {
    //       affinityMonitor.push(reportId);
    //     }
    //   }

    //   payload = {
    //     download_level: level,
    //     client: this.client,
    //     data: [
    //       {
    //         year: yr,
    //         semester: sem,
    //         reportName: {
    //           Affinity_monitor: affinityMonitor,
    //           access_monitor: accessMonitor,
    //         },
    //       },
    //     ],
    //   };
    // }

    this.httpService.postRequest('download_report', payload).subscribe(
      (result) => {
        if (result.status === 'success') {
          this.downloadFile(result.downloadLink, '');
        } else {
          this.notifyType = 'error';
          this.showNotification = true;
          this.createBasicNotification(this.notifyType);
        }
      },
      (error) => {
        this.notifyType = 'error';
        this.showNotification = true;
        this.createBasicNotification(this.notifyType);
      },
    );
  }

  public generatePayload(): object {
    let payload = {};
    let affinityMonitor = [];
    let accessMonitor = [];
    const dataArray = [];
    this.reportData.map((report) => {
      report.reports.map((folder) => {
        folder.files.map((file) => {
          if (file.downloadable && file.checked) {
            if (folder.folderName === 'AccessMonitor Reports') {
              accessMonitor.push(file.reportId);
            } else {
              affinityMonitor.push(file.reportId);
            }
          }
        });
      });
      if (affinityMonitor.length > 0 || accessMonitor.length > 0) {
        dataArray.push({
          year: report.year,
          semester: report.semester,
          reportName: { affinity_monitor: affinityMonitor, access_monitor: accessMonitor },
        });
      }
      affinityMonitor = [];
      accessMonitor = [];
    });
    payload = {
      download_level: 'multiSemester',
      data: dataArray,
      client: this.client,
    };

    return payload;
  }

  public getPayloadForAll(): object {
    let affinityMonitor = [];
    let accessMonitor = [];
    let payload = {};
    if (this.allChecked) {
      payload = {
        download_level: 'all',
        data: [],
        client: this.client,
      };
    } else {
      const dataArray = [];
      this.reportData.map((report) => {
        if (report.checked) {
          report.reports.map((folder) => {
            folder.files.map((file) => {
              if (file.downloadable) {
                if (folder.folderName === '	AccessMonitor Reports') {
                  accessMonitor.push(file.reportId);
                } else {
                  affinityMonitor.push(file.reportId);
                }
              }
            });
            dataArray.push({
              year: report.year,
              semester: report.semester,
              reportName: { Affinity_monitor: affinityMonitor, access_monitor: accessMonitor },
            });
            affinityMonitor = [];
            accessMonitor = [];
          });
        }
      });
      payload = {
        download_level: 'multiSemester',
        data: dataArray,
      };
    }
    return payload;
  }

  public hideFolderDownloadIcon(semId, folderId): boolean {
    const folderData = this.reportData.find((semesters) => semesters.id === semId);
    const reports = folderData.reports.find((report) => report.id === folderId);
    return !reports.files.some((file) => file.checked);
  }

  public hideSemDownloadIcon(semId): boolean {
    const folderData = this.reportData.find((semesters) => semesters.id === semId);
    return !folderData.reports.some((report) => report.checked);
  }

  public disableDownloadButton(): boolean {
    // return !this.reportData.some((reports) => reports.checked);
    let returnValue = true;
    if (this.reportData.some((reports) => reports.checked)) {
      returnValue = false;
    }
    if (returnValue) {
      this.reportData.map(report => {
        if (returnValue) {
          returnValue = !report.reports.some(r => r.checked);
        }
      });
    }
    if (returnValue) {
      this.reportData.map(report => {
        report.reports.map(r => {
          if (returnValue) {
            returnValue = !r.files.some(file => file.checked);
          }
        });
      });
    }
    return returnValue;
  }

  public disableCheckbox(level, semId, folderId?): boolean {
    const folderData = this.reportData.find((semesters) => semesters.id === semId);
    const reports = folderData.reports.find((report) => report.id === folderId);
    let isDisabled = false;

    if (level === 'semester') {
      isDisabled = !folderData.reports.some((report) =>
        report.files.some((file) => file.downloadable),
      );
    } else {
      isDisabled = !reports.files.some((file) => file.downloadable);
    }

    return isDisabled;
  }
  public hideNotification(flag): void {
    this.showNotification = flag;
  }
  public createBasicNotification(status): void {
    let msg = '';
    let bgColor = '';
    if (status === 'success') {
      msg = 'File successfully downloaded.';
      bgColor = '#00AA67';
    } else {
      msg = 'There was an error in downloading the file. Please try again.';
      bgColor = 'red';
    }

    this.notification.blank('', msg, {
      nzPlacement: 'bottomLeft',
      nzStyle: { background: bgColor, color: '#FFFFFF' },
    });
  }
}
