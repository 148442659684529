<nz-modal
  [(nzVisible)]="showTour"
  [nzFooter]="null"
  [nzWidth]="'820px'"
  class="template-wrapper"
  [nzMaskClosable]="false"
  [nzClosable]="false"
  xmlns="http://www.w3.org/1999/html"
>
  <ng-container *nzModalContent>
    <div nz-row class="onboarding-carousel">
      <div nz-col nzSpan="14" class="onboarding-carousel-1">
        <nz-carousel
          [nzEnableSwipe] = "false"
          (nzBeforeChange) = "goTo($event)"
          [nzEffect]="'fade'"
        >
          <div nz-carousel-content *ngFor="let index of array">
            <img src="{{ index }}" class="onboarding-img" />
          </div>
        </nz-carousel>
      </div>
      <div nz-col nzSpan="10" class="onboarding-carousel-2">
        <div class="onboarding-description">
          <div class="onboarding-description-body">
            <div class="onboarding-description-title">{{ title_desc[title] }}</div>
            <div>
              {{ desc[title] }}
            </div>
          </div>
        </div>

        <div class="modal-footer">
          <div class="button-wrapper">
            <nz-space>
            <button class="skip-button-outer"><a (click)="onCancel()" class="skip-button">Skip</a></button>
            <button nz-button nzType="secondary" (click)="onBack()" *ngIf="title > 0" class="back-button">Back</button>
            <button
              nz-button
              nzType="primary"
              class="app-primary-button next-button"
              (click)="onNext()"
              *ngIf="title < 5"
            >
              Next
            </button>
            <button
              nz-button
              nzType="primary"
              class="app-primary-button next-button"
              (click)="onCancel()"
              *ngIf="title === 5"
            >
              Close
            </button>
            </nz-space>
          </div>
        </div>
      </div>
    </div>
  </ng-container>
</nz-modal>
