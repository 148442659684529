<div class="table-content">
    <nz-table
      class = 'app-modal-table'
      [ngClass]="customClass"
      #mappingTable
      [nzData]="fileData"
      nzTableLayout="fixed"
      [nzShowPagination]="false"
      [nzLoading]="false"
      [nzScroll]="{ y: '150px' }"
    >
      <thead>
        <tr>
          <th [nzWidth]="widthval" *ngFor="let column of headers">
            {{ column }}
          </th>
        </tr>
      </thead>
      <tbody>
        <tr *ngFor="let data of mappingTable.data">
          <ng-container *ngFor="let column of headers">
            <td>
              {{ data[column] }}
            </td>
          </ng-container>
        </tr>
      </tbody>
    </nz-table>
  </div>