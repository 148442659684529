import { Component, EventEmitter, Input, OnInit, Output } from '@angular/core';
import { HttpService } from '../../../../shared/services/http.service';

@Component({
  selector: 'app-preview-profiling-statistics',
  templateUrl: './preview-profiling-statistics.component.html',
  styleUrls: ['./preview-profiling-statistics.component.scss'],
})
export class PreviewProfilingStatisticsComponent implements OnInit {
  constructor(private httpService: HttpService) {}

  @Input() previewFile = false;
  @Input() processId = '';
  @Output() hidePreview: EventEmitter<boolean> = new EventEmitter<boolean>();

  public previewData = [];
  public previewHeaders = [];
  public previewTitle = '';
  public message = '';
  public previewUrl = '';
  public loading = false;

  ngOnInit(): void {
    this.loading = true;
    this.loadData();
  }

  public handleCancel(): void {
    this.previewFile = false;
    this.hidePreview.emit(false);
  }
  public loadData(): void {
    const payload = {
      process_id: this.processId,
    };
    this.httpService.postRequest('profiling_statistics_preview', payload).subscribe((data) => {
    // this.httpService.getRequest('profiling_statistics_preview').subscribe((data) => {
      if (data['status'] === 'SUCCESS') {
        this.previewData = data.tbody;
        this.previewHeaders = data.thead;
        this.previewTitle = data.title;
        this.previewUrl = data.url;
        this.message = data['message'];
        this.loading = false;
      } else {
        this.previewTitle = data.title;
        this.message = data['message'];
      }
    });
  }
  public downloadFile(url, target): void {
    window.open(url, target);
  }
}
