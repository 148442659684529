// API will point to localhost if production is set to false.

export const environment = {
  production: true,
  url: 'https://api.aftm.dev.zsservices.com/',
  withCredentials: true,
  authLoginUrl:
    'https://idm.dev.zsservices.com/AffinityMonitor/IdentityManager/app/Web/login.aspx',
  authLogOutUrl:
    'https://idm.dev.zsservices.com/AffinityMonitor/IdentityManager/app/Web/Logout.aspx',
  client: [
    {
      name: 'Client_1',
      url: 'https://api.aftm.dev.zsservices.com/',
      authLoginUrl:
        'https://idm.dev.zsservices.com/AffinityMonitor/IdentityManager/app/Web/login.aspx',
      authLogOutUrl:
        'https://idm.dev.zsservices.com/AffinityMonitor/IdentityManager/app/Web/Logout.aspx',
    },
    {
      name: 'Amgen',
      url: 'https://api.aftm.dev.zsservices.com/',
      authLoginUrl:
        'https://idm.dev.zsservices.com/AffinityMonitor/IdentityManager/app/Web/login.aspx',
      authLogOutUrl:
        'https://idm.dev.zsservices.com/AffinityMonitor/IdentityManager/app/Web/Logout.aspx',
    },
    {
      name: 'Pfizer',
      url: 'https://api.aftm.dev.zsservices.com/',
      authLoginUrl:
        'https://idm.dev.zsservices.com/AffinityMonitor/IdentityManager/app/Web/login.aspx',
      authLogOutUrl:
        'https://idm.dev.zsservices.com/AffinityMonitor/IdentityManager/app/Web/Logout.aspx',
    },
    {
      name: 'Takeda',
      url: 'https://api.aftm.dev.zsservices.com/',
      authLoginUrl:
        'https://idm.dev.zsservices.com/AffinityMonitor/IdentityManager/app/Web/login.aspx',
      authLogOutUrl:
        'https://idm.dev.zsservices.com/AffinityMonitor/IdentityManager/app/Web/Logout.aspx',
    },
    {
      name: 'Abbvie',
      url: 'https://api.aftm.dev.zsservices.com/Abbvie',
      authLoginUrl:
        'https://idm.dev.zsservices.com/Abbvie/IdentityManager/app/Web/login.aspx',
      authLogOutUrl:
        'https://idm.dev.zsservices.com/Abbvie/IdentityManager/app/Web/Logout.aspx',
    },
    {
      name: 'Argenx',
      url: 'https://idm.dev.zsservices.com/Argenx/IdentityManager/app/Web/login.aspx',
      authLoginUrl:
        'https://idm.dev.zsservices.com/Argenx/IdentityManager/app/Web/login.aspx',
      authLogOutUrl:
        'https://idm.dev.zsservices.com/Argenx/IdentityManager/app/Web/Logout.aspx',
    },
    {
      name: 'Avadel',
      url: 'https://idm.dev.zsservices.com/Avadel/IdentityManager/app/Web/login.aspx',
      authLoginUrl:
        'https://idm.dev.zsservices.com/Avadel/IdentityManager/app/Web/login.aspx',
      authLogOutUrl:
        'https://idm.dev.zsservices.com/Avadel/IdentityManager/app/Web/Logout.aspx',
    },
    {
      name: 'Beigene',
      url: 'https://javelin.staging.zsservices.com/Beigene/IdentityManager/app/Web/login.aspx',
      authLoginURL: "https://javelin.staging.zsservices.com/Beigene/IdentityManager/app/Web/login.aspx",
      authLogoutURL: "https://javelin.staging.zsservices.com/Beigene/IdentityManager/app/Web/Logout.aspx"
  },
    {
      name: 'BMS',
      url: 'https://idm.dev.zsservices.com/BMS/IdentityManager/app/Web/login.aspx',
      authLoginUrl:
        'https://idm.dev.zsservices.com/BMS/IdentityManager/app/Web/login.aspx',
      authLogOutUrl:
        'https://idm.dev.zsservices.com/BMS/IdentityManager/app/Web/Logout.aspx',
    },
    {
      name: 'Janssen',
      url: 'https://idm.dev.zsservices.com/Janssen/IdentityManager/app/Web/login.aspx',
      authLoginUrl:
        'https://idm.dev.zsservices.com/Janssen/IdentityManager/app/Web/login.aspx',
      authLogOutUrl:
        'https://idm.dev.zsservices.com/Janssen/IdentityManager/app/Web/Logout.aspx',
    },
    {
      name: 'Kite',
      url: 'https://idm.dev.zsservices.com/Kite/IdentityManager/app/Web/login.aspx',
      authLoginUrl:
        'https://idm.dev.zsservices.com/Kite/IdentityManager/app/Web/login.aspx',
      authLogOutUrl:
        'https://idm.dev.zsservices.com/Kite/IdentityManager/app/Web/Logout.aspx',
    },
    {
      name: 'Phathom',
      url: 'https://idm.dev.zsservices.com/Phathom/IdentityManager/app/Web/login.aspx',
      authLoginUrl:
        'https://idm.dev.zsservices.com/Phathom/IdentityManager/app/Web/login.aspx',
      authLogOutUrl:
        'https://idm.dev.zsservices.com/Phathom/IdentityManager/app/Web/Logout.aspx',
    },
    {
      name: 'Sanofi',
      url: 'https://idm.dev.zsservices.com/Sanofi/IdentityManager/app/Web/login.aspx',
      authLoginUrl:
        'https://idm.dev.zsservices.com/Sanofi/IdentityManager/app/Web/login.aspx',
      authLogOutUrl:
        'https://idm.dev.zsservices.com/Sanofi/IdentityManager/app/Web/Logout.aspx',
    },
    {
      name: 'Takeda',
      url: 'https://idm.dev.zsservices.com/Takeda/IdentityManager/app/Web/login.aspx',
      authLoginUrl:
        'https://idm.dev.zsservices.com/Takeda/IdentityManager/app/Web/login.aspx',
      authLogOutUrl:
        'https://idm.dev.zsservices.com/Takeda/IdentityManager/app/Web/Logout.aspx',
    },
    {
      name: 'zs',
      url: 'https://api.aftm.dev.zsservices.com/',
      authLoginUrl:
        'https://idm.dev.zsservices.com/AffinityMonitor/IdentityManager/app/Web/login.aspx',
      authLogOutUrl:
        'https://idm.dev.zsservices.com/AffinityMonitor/IdentityManager/app/Web/Logout.aspx',
    },
  

    


  ],
  sisenseUrl: 'https://sisense.qa.zsservices.com/'
};
