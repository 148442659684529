<div class = "file-modal-wrapper">
<nz-modal
  [nzStyle]="{ top: '20px'}"
  [(nzVisible)]="previewFiles"
  [nzWidth]="'820px'"
  [nzFooter]="null"
  [nzMaskClosable]="false"
  [nzTitle]="'Preview Uploaded Files'"
  (nzOnCancel)="handleCancel()"
  class ="file-modal"
  [nzWrapClassName]="'modal-wrapper'"
>
  <ng-container *nzModalContent>
    <div class="modal-body-wrapper" *ngIf="multipleFiles && !showPreview">
      <p class="message">The files you uploaded should use proper formatting so it can be easily processed by ZS. Contact the Affinity Monitor team if you have any questions.</p>
      <ng-container *ngFor="let file of fileList">
        <div nz-row>
          <div nz-col nzSpan="21" class="file-name" [title]="file.filename">{{file.filename}}</div>
          <div nz-col nzSpan="3" class="eye" (click)="openFile(file.file_id)">
            <i nz-icon nzType="eye" nzTheme="fill"></i> <span> View Details</span>
          </div>
        </div>
        <nz-divider class="file-divider"></nz-divider>
      </ng-container>
    </div>
    <div *ngIf="showPreview || !multipleFiles">
      <div *ngIf="showPreview" (click)="showPreview = false" class="back-button">Back to Files</div>
      <div [ngClass]="showPreview? 'file-title': 'file-title-one'" [title]="previewTitle">{{previewTitle}}</div>
      <nz-divider class="file-divider"></nz-divider>
    <app-file-preview
      [fileData]="previewData"
      [headers]="previewHeaders"
      [previewFile]="showPreview || !multipleFiles"
      [modalTitle]="previewTitle"
      [loading]="previewLoading"
      [multipleFiles] = "multipleFiles"
      [message] = 'message'
    >
    </app-file-preview>
    </div>

  </ng-container>
</nz-modal>
</div>
