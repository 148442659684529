import { Component, Input, OnInit, Output, EventEmitter, HostListener, ViewChild, OnDestroy } from '@angular/core';
import {HttpService} from '../../services/http.service';
import {NzAutocompleteComponent} from 'ng-zorro-antd/auto-complete';
import { AppState } from '../../services/app-state';
import { Subscription } from 'rxjs';
@Component({
  selector: 'app-dropdown',
  templateUrl: './dropdown.component.html',
  styleUrls: ['./dropdown.component.scss']
})
export class DropdownComponent implements OnInit, OnDestroy {
  @Input() label;
  @Input() size = 'default';
  @Input() data = [];
  @Output() selectedValue: EventEmitter<any> = new EventEmitter<any>();
  @Input() isMultiple = false;
  @Input() isSearchable = false;
  @Input() defaultSelected = '';
  @Input() placeHolder = '';
  @Input() isDisabled = false;
  @Input() allowClear = true;
  @Input() value = [];
  @ViewChild('inputRef') inputRef: NzAutocompleteComponent;

  private appStateSubscription: Subscription;
  public isOpenSelect = false;

  constructor(
     private httpService: HttpService,
     private appState: AppState,
  ) {
    this.appStateSubscription = AppState.appState.subscribe((val) => {
      if (val && !val.showDropdown) {
        this.isOpenSelect = false;
      }
    });
  }

  ngOnInit(): void {
    if (this.defaultSelected) {
      this.value.push(this.defaultSelected);
    }
  }

  public onChange(event): void {
    this.selectedValue.emit(event);
  }

  public openChange(event): void {
    this.appState.setGlobalState('showDropdown', event);
  }

  ngOnDestroy(): void {
    this.appStateSubscription.unsubscribe();
  }
}
