import { NgModule } from '@angular/core';
import { CommonModule } from '@angular/common';
import { AdminComponent } from './admin.component';
import { ActivityLogComponent } from './activity-log/activity-log.component';

import { SharedModule } from 'src/app/shared/shared.module';
import { NzSpaceModule } from 'ng-zorro-antd/space';
import { NzLayoutModule } from 'ng-zorro-antd/layout';
import { NzTableModule } from 'ng-zorro-antd/table';
import { NzEmptyModule } from 'ng-zorro-antd/empty';
import { NzIconModule } from 'ng-zorro-antd/icon';
import { NzDropDownModule } from 'ng-zorro-antd/dropdown';
import { FormsModule } from '@angular/forms';
import { NzCheckboxModule } from 'ng-zorro-antd/checkbox';
import { NzRadioModule } from 'ng-zorro-antd/radio';
import { NzTabsModule } from 'ng-zorro-antd/tabs';
import { ApprovalsListComponent } from './approvals-list/approvals-list.component';
import { CustomProgramsComponent } from './approvals-list/custom-programs/custom-programs.component';
import { DataChangesComponent } from './approvals-list/data-changes/data-changes.component';
import { DataReviewModalComponent } from './approvals-list/data-changes/data-review-modal/data-review-modal.component';
import { NzModalModule } from 'ng-zorro-antd/modal';
import { NzButtonModule } from 'ng-zorro-antd/button';
import { NzInputModule } from 'ng-zorro-antd/input';
import { ProgramReviewModalComponent } from './approvals-list/custom-programs/program-review-modal/program-review-modal.component';
import { NzUploadModule } from 'ng-zorro-antd/upload';
import { NzProgressModule } from 'ng-zorro-antd/progress';
import { NzGridModule } from 'ng-zorro-antd/grid';
import { NzSpinModule } from 'ng-zorro-antd/spin';
import { StewardFeedbackComponent } from './steward-feedback/steward-feedback.component';
import { StewardAfmComponent } from './steward-feedback/steward-afm/steward-afm.component';
import { StewardAcmComponent } from './steward-feedback/steward-acm/steward-acm.component';
import { StewardReviewComponent } from './steward-feedback/steward-review/steward-review.component';
import { PreviewSampleDataComponent } from './steward-feedback/steward-review/preview-sample-data/preview-sample-data.component';
import { PreviewProfilingStatisticsComponent } from './steward-feedback/steward-review/preview-profiling-statistics/preview-profiling-statistics.component';
import { FileTrackingComponent } from './file-tracking/file-tracking.component';
import { ProcessDetailsModalComponent } from './file-tracking/process-details-modal/process-details-modal.component';
import { NzPopoverModule } from 'ng-zorro-antd/popover';
import { ProgramManagementComponent } from './program-management/program-management.component';
import { ProgramApprovalComponent } from './program-management/program-approval/program-approval.component';
import { ProgramApprovalModalComponent } from './program-management/program-approval/program-approval-modal/program-approval-modal.component';
import { StandardProgramsComponent } from './program-management/standard-programs/standard-programs.component';
import { CustomProgramComponent } from './program-management/custom-program/custom-program.component';
import { ProgramReviewComponent } from './program-management/program-review/program-review.component';
import { AddProgramModalComponent } from './program-management/add-program-modal/add-program-modal.component';
import { NzDividerModule } from 'ng-zorro-antd/divider';
import { NzDatePickerModule } from 'ng-zorro-antd/date-picker';
import { ClientManagementComponent } from './client-management/client-management.component';
import { AutomationMappingModule } from './automation-mapping/automation-mapping.module';

@NgModule({
  declarations: [
    AdminComponent,
    ActivityLogComponent,
    ApprovalsListComponent,
    CustomProgramsComponent,
    DataChangesComponent,
    DataReviewModalComponent,
    ProgramReviewModalComponent,
    StewardFeedbackComponent,
    StewardAfmComponent,
    StewardAcmComponent,
    StewardReviewComponent,
    PreviewSampleDataComponent,
    PreviewProfilingStatisticsComponent,
    FileTrackingComponent,
    ProcessDetailsModalComponent,
    ProgramManagementComponent,
    ProgramApprovalComponent,
    ProgramApprovalModalComponent,
    StandardProgramsComponent,
    CustomProgramComponent,
    ProgramReviewComponent,
    AddProgramModalComponent,
    ClientManagementComponent,
  ],
  imports: [
    CommonModule,
    SharedModule,
    NzSpaceModule,
    NzLayoutModule,
    NzTableModule,
    NzEmptyModule,
    NzIconModule,
    NzDropDownModule,
    FormsModule,
    NzCheckboxModule,
    NzRadioModule,
    NzTabsModule,
    NzModalModule,
    NzButtonModule,
    NzInputModule,
    NzUploadModule,
    NzProgressModule,
    NzGridModule,
    NzSpinModule,
    NzPopoverModule,
    NzDividerModule,
    NzDatePickerModule,
    AutomationMappingModule
  ],
  exports: [ActivityLogComponent, AdminComponent],
})
export class AdminModule {}
