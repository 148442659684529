import { NgModule } from '@angular/core';
import { CommonModule } from '@angular/common';
import { DownloadReportsComponent } from './download-reports.component';
import { NzTableModule } from 'ng-zorro-antd/table';
import { NzCheckboxModule } from 'ng-zorro-antd/checkbox';
import { FormsModule } from '@angular/forms';
import { NzIconModule } from 'ng-zorro-antd/icon';
import { NzGridModule } from 'ng-zorro-antd/grid';
import { SharedModule } from 'src/app/shared/shared.module';
import { NzSpaceModule } from 'ng-zorro-antd/space';
import { NzLayoutModule } from 'ng-zorro-antd/layout';
import { NzInputModule } from 'ng-zorro-antd/input';
import { NzButtonModule } from 'ng-zorro-antd/button';
import { DatePipe } from '@angular/common';
import { NzEmptyModule } from 'ng-zorro-antd/empty';

@NgModule({
  declarations: [DownloadReportsComponent],
  imports: [
    CommonModule,
    NzTableModule,
    NzCheckboxModule,
    FormsModule,
    NzIconModule,
    NzGridModule,
    SharedModule,
    NzSpaceModule,
    NzLayoutModule,
    NzInputModule,
    NzButtonModule,
    NzEmptyModule,
  ],
  exports: [DownloadReportsComponent],
  providers: [DatePipe],
})
export class DownloadReportsModule {}
