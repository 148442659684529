<nz-layout>
  <nz-content>
    <div class="space-align-container">
      <span nz-typography class="tab-header">Download Reports</span>
      <nz-space [nzSize]="horizontalSpace">
        <app-search-bar
          [searchParameter]="searchParamData"
          (searchValue)="filterOnSearch($event)"
          (noResultFound)="formatOnNoresult($event)"
          (noInput)="resetSearch($event)"
          (resetSearch)="resetSearch($event)"
          (inputKey)="filterOnSearch($event)"
          *nzSpaceItem
        ></app-search-bar>
        <app-download
          *nzSpaceItem
          [isDisabled]="disableDownloadButton()"
          (click)="downloadZip('all')"
        ></app-download>
      </nz-space>
    </div>
    <!--    TODO: After API response show popup and set type-->
    <nz-table
      #panelData
      class="app-modal-table"
      [nzData]="reportData"
      nzTableLayout="auto"
      [nzShowPagination]="true"
      [nzPageSize]="6"
      [(nzPageIndex)]="pageIndex"
      [nzLoading]="loading"
      [nzNoResult]="noResultFound"
    >
      <ng-template #noResultFound>
        <nz-empty nzNotFoundImage="simple" [nzNotFoundContent]="noResultTpl">
          <ng-template #noResultTpl>
            <p class="negate-margin" *ngIf="!reportsEmpty">Your search returned no results</p>
            <p class="negate-margin" *ngIf="reportsEmpty">There are no subscribed reports available</p>
          </ng-template>
        </nz-empty>
      </ng-template>
      <thead class="outer-table-head">
        <tr>
          <th nzWidth="1%"></th>
          <th nzWidth="1%" (nzCheckedChange)="checkAll($event)" [(nzChecked)]="allChecked"
            [nzDisabled]="checkDisabled"
          ></th>
          <th
            nzWidth="1%"
            [nzSortOrder]="sortColumns.year.sortOrder"
            (click)="sortData('year')"
            [nzSortDirections]="sortColumns.year.sortDirection"
          >
            <span class="row-headers">Year</span>
          </th>
          <th
            nzWidth="40%"
            [nzSortOrder]="sortColumns.semester.sortOrder"
            (click)="sortData('semester')"
            [nzSortDirections]="sortColumns.semester.sortDirection"
          >
            <span class="row-headers">Cycle</span>
          </th>
          <th nzWidth="16%"><span class="row-headers">Reports Available</span></th>
          <th nzWidth="10%"><span class="row-headers">Actions</span></th>
        </tr>
      </thead>
      <tbody class="table-color">
        <ng-container *ngFor="let data of panelData.data; let reportIndex = index">
          <tr>
            <td
              [nzExpand]="expandSet.has(data.id)"
              (nzExpandChange)="onExpandChange(data.id, $event)"
            ></td>
            <td style="text-align: center">
              <label
                nz-checkbox
                [disabled]="disableCheckbox('semester', data.id)"
                [(ngModel)]="data.checked"
                (nzCheckedChange)="updateCheckbox($event, 'semester', data.id)"
              ></label>
            </td>
            <td>{{ data.year }}</td>
            <td>{{ data.semester }}</td>
            <td>{{ data.reportAvailable }}</td>
            <td>
              <!-- TODO: Uncomment when download is to be enabled -->
              <!-- <a
                href="javascript:void(0)"
                (click)="downloadZip('semester', data.id)"
                [ngClass]="{
                  'link-disable': hideSemDownloadIcon(data.id)
                }"
                ><i class="app-style-mid" nz-icon nzType="download" nzTheme="outline"></i
              ></a> -->
            </td>
          </tr>
          <tr [nzExpand]="expandSet.has(data.id)">
            <nz-table
              #folders
              [nzData]="data.reports"
              [nzShowPagination]="false"
              nzTableLayout="fixed"
              class="report-name-table"
            >
              <thead class="table-color">
                <tr>
                  <th nzWidth="2.7%" class="inner-table-header"></th>
                  <th nzWidth="5.3%" class="inner-table-header" style="text-align: center"></th>
                  <th nzWidth="37%" class="report-name-header"><span class="row-headers">Report Name</span></th>
                  <th
                    class="inner-table-header"
                    nzWidth="15%"
                    [nzSortOrder]="sortColumns.date.sortOrder"
                    (click)="sortData('date', reportIndex)"
                    [nzSortDirections]="sortColumns.date.sortDirection"
                  >
                    <span class="row-headers">Date Available</span>
                  </th>
                  <th nzWidth="9.5%" class="actions-header inner-table-header"><span class="row-headers">Actions</span></th>
                </tr>
              </thead>
              <tbody class="table-color">
                <ng-container *ngFor="let folder of folders.data; let folderIndex = index">
                  <tr>
                    <td
                      [nzExpand]="expandInnerSet.has(folder.id)"
                      (nzExpandChange)="onExpandInnerChange(folder.id, $event)"
                    ></td>
                    <td>
                      <label
                        nz-checkbox
                        [disabled]="disableCheckbox('folder', data.id, folder.id)"
                        [(ngModel)]="folder.checked"
                        [nzChecked]="folder.checked"
                        (nzCheckedChange)="updateCheckbox($event, 'folder', data.id, folder.id)"
                      ></label>
                    </td>
                    <td class="inner-table-folder">
                      <span *ngIf="folder.folderName === ('AffinityMonitor Reports' || 'AffinityMonitor')">
                        AffinityMonitor® Reports</span>
                      <span *ngIf="folder.folderName === ('AccessMonitor Reports' || 'AccessMonitor')">
                        AccessMonitor™ Reports</span>
                    </td>
                    <td></td>
                    <td>
                      <!-- TODO: Uncomment when download is to be enabled -->
                      <!-- <a
                        href="javascript:void(0)"
                        (click)="downloadZip('folder', data.id, folder.id)"
                        [ngClass]="{
                          'link-disable': hideFolderDownloadIcon(data.id, folder.id)
                        }"
                        ><i class="app-style-mid" nz-icon nzType="download" nzTheme="outline"></i
                      ></a> -->
                    </td>
                  </tr>
                  <tr [nzExpand]="expandInnerSet.has(folder.id)">
                    <nz-table
                      #files
                      [nzData]="folder.files"
                      [nzShowPagination]="false"
                      nzTableLayout="auto"
                    >
                      <tbody>
                        <ng-container *ngFor="let report of files.data">
                          <tr>
                            <td width="6.2%" style="text-align: left">
                              <label
                                nz-checkbox
                                [disabled]="!report.downloadable"
                                [(ngModel)]="report.checked"
                                (nzCheckedChange)="
                                  updateCheckbox($event, 'file', data.id, folder.id, report.id)
                                "
                              ></label>
                            </td>
                            <td width="43%" class="td-report-name">{{report.reportName}}</td>
                            <td width="17.6%">{{ getDate(report.dateAvailable) }}</td>
                            <td width="11.1%" class="td-actions">
                              <!-- TODO: Uncomment when download is to be enabled -->
                              <!-- <a
                                href="javascript:void(0)"
                                [ngClass]="{
                                  'link-disable': !report.downloadable
                                }"
                                (click)="downloadZip('file', data.id, folder.id, report.reportId)"
                              >
                                <i
                                  class="app-style-mid"
                                  nz-icon
                                  nzType="download"
                                  nzTheme="outline"
                                ></i>
                              </a> -->
                              <span
                                *ngIf="report.preview"
                                [ngClass]="{
                                  'link-disable': !report.downloadable
                                }"
                                class="cursor-pointer"
                              >
                                <i
                                  class="app-style-mid"
                                  nz-icon
                                  nzType="eye"
                                  nzTheme="outline"
                                  (click)="showPreviewPopup(report.preview)"
                                ></i>
                                &nbsp;
                              </span>
                            </td>
                          </tr>
                        </ng-container>
                      </tbody>
                    </nz-table>
                  </tr>
                </ng-container>
              </tbody>
            </nz-table>
          </tr>
        </ng-container>
      </tbody>
    </nz-table>
  </nz-content>
</nz-layout>
<app-preview-modal
  *ngIf="previewReportFlag"
  [previewReport]="previewReportFlag"
  (hidePreview)="hidePreview($event)"
  [modalTitle]="previewModalTitle"
  [tableData]="previewTableData"
></app-preview-modal>
