import { Component, Input, OnDestroy, OnInit } from '@angular/core';
import { environment } from 'src/environments/environment';
import { Subscription } from 'rxjs';
import { AppState } from '../../services/app-state';

@Component({
  selector: 'app-simple-dropdown',
  templateUrl: './simple-dropdown.component.html',
  styleUrls: ['./simple-dropdown.component.scss']
})
export class SimpleDropdownComponent implements OnInit, OnDestroy {

  @Input() showEditIcon = false;
  @Input() title = '';
  @Input() description = '';
  @Input() page = '';
  public showImport = false;
  public showFilter = false;
  private appStateSubscription: Subscription;

  constructor(private appState: AppState) {
   this.appStateSubscription = AppState.appState.subscribe((val) => {
      if (val && !val.showDropdown) {
        this.showFilter = false;
      }
    });
  }

  ngOnInit(): void {}

  public import(): void {
    this.showImport = true;
  }

  public export(): void {
    let url = environment.url + 'export_data?type=';
    switch (this.page) {
      case 'faq':
        url = url + 'faq';
        break;
      case 'subscriptions':
        url = url + 'subscriptions';
        break;
      case 'upload-data':
        url = url + 'upload';
        break;
      case 'admin':
        url = url + 'steward_mc';
        break;
      case 'dashboard':
        url = url + 'sisense_filter';
        break;
      case 'client':
        url = url + 'client';
        break;
    }
    window.open(url, '');
  }

  public openChange(event): void {
    this.appState.setGlobalState('showDropdown', event);
  }

  ngOnDestroy(): void {
    this.appStateSubscription.unsubscribe();
  }
}
