<nz-layout>
  <nz-content>
    <div class="space-align-container">
      <span nz-typography class="tab-header">Customer Master Mapping</span>
    </div>
    <app-header-content
        [totalCount]="totalCount"
        [uniqueCount]="uniqueCount"
        [clientCount]="clientCount"
        [AFMCount]="AFMCount"
        [downloadUrl]="downloadUrl"
        [type]="'Speciality'"
        (uploadData)="getUploadStats($event)"
    ></app-header-content>
    <app-mapping-table
        [headers]="headers"
        [fileData]="fileData"
    ></app-mapping-table>
    <app-mapping-table
        [headers]="feedbackHeaders"
        [fileData]="feedbackFileData"
        customClass ="automation-mapping"
    ></app-mapping-table>
    <div class="manual-review" *ngIf="uploadStats.tableData.tbody.length > 0">
        <hr>
        <div class="manual-title">
            <strong>MANUAL REVIEW - FEEDBACK</strong>
        </div>
        <app-header-content
            [totalCount]="uploadStats.countValues.uploadTotalCount"
            [uniqueCount]="uploadStats.countValues.uploadUniqueCount"
            [clientCount]="uploadStats.countValues.uploadClientCount"
            [AFMCount]="uploadStats.countValues.uploadAFMCount"
            [isSubmit]="true"
            [type]="'Speciality'"
        ></app-header-content>
        <app-mapping-table
            [headers]="uploadStats.tableData.thead"
            [fileData]="uploadStats.tableData.tbody"
        ></app-mapping-table>
        <app-mapping-table
        [headers]="uploadStats.feedbackTableData.thead"
        [fileData]="uploadStats.feedbackTableData.tbody"
        customClass ="automation-mapping"
    ></app-mapping-table>
    </div>
  </nz-content>
</nz-layout>

