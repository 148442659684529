<nz-layout>
  <nz-content>
    <div nz-row>
      <div class="space-align-container">
        <div nz-col nzSpan="17.5">
          <span nz-typography class="tab-header">{{
            drawerContent[0].selected ? drawerContent[0].title : drawerContent[1].title
          }}</span>
        </div>
        <div nz-col nzSpan="6.5" class="button-container">
          <app-simple-dropdown [page]="'subscriptions'" *ngIf="isAdmin" class="dropdown"></app-simple-dropdown>
            <app-dropdown *ngIf="isAdmin" class="dropdown" [data]="clientList" (selectedValue)="clientSelected($event)"
                          [placeHolder]="'Select Client'"
                          [isSearchable]="true"
                          [value]="[selectedClient]"
            ></app-dropdown>
          <a [href]="emailContent" class="dropdown">
            <button nz-button nzType="default" class="app-default-button">
              <i nz-icon nzType="mail" nzTheme="fill"></i>
              <span class="contact-us">Contact Us</span>
            </button>
          </a>
        </div>
      </div>
    </div>
    <div class="spinner" *ngIf="(!isDataLoaded && !isAdmin) || (!isDataLoaded && selectedClient)">
      <nz-spin nzSimple></nz-spin>
    </div>
    <app-subscription-table
      *ngIf="(isDataLoaded && !isAdmin) || (isDataLoaded && selectedClient)"
      [listOfData]="listOfData"
      [headerData]="headerData"
      [specialities]="specialities"
      [selectedTab]="selectedTab"
    ></app-subscription-table>
  </nz-content>
</nz-layout>

<div *ngIf="isAdmin && !selectedClient" class="admin-disabled">Please select a client from the dropdown.</div>

<app-drawer
  [(showDrawer)]="showDrawer"
  [content]="drawerContent"
  [title]="'SUBSCRIPTIONS'"
  (tabChanged)="switchTab($event)"
></app-drawer>
