<nz-drawer
  [nzClosable]="false"
  [nzVisible]="showDrawer"
  [nzPlacement]="placement"
  (nzOnClose)="onClose($event)"
  [nzOffsetX]="offsetX"
  [nzWidth]="'400px'"
  [nzClosable] ="closableIcon"
  [nzMaskClosable] = "maskClosable"
>
  <ng-container *nzDrawerContent>
    <div *ngIf="showLoader" class="progress-wrapper">
      <nz-spin nzTip="Dashboard is loading..." nzSimple [nzSize]="'large'"></nz-spin>
    </div>
    <span *ngIf="!showLoader">
      <div
        [ngClass]="placement == 'right' ? 'title-r' : 'title'"
        >
        {{ title }}
        <div class="sub-title" *ngIf="subTitle">
          {{ subTitle }}
        </div>
      </div>
        <div *ngIf="placement == 'right'"><hr></div>
      <div *ngIf="placement == 'left'">
      <div *ngFor="let data of content">
        <div class="item" [ngClass]="data.selected ? 'highlight' : ''" (click)="onClose(data.title)">
          {{ data.title }}
        </div>
      </div>
      </div>
      <div *ngIf="placement == 'right'" class="in-data">
        <div *ngFor="let data of content">
          <div class="data-label">{{data.label}}</div>
          <div class="data-value">
            <div>
              {{data.value}}
            </div>
          </div>
        </div>
      </div>
      <div *ngIf="placement == 'right'">
          <button nz-button
                  nzType="default"
                  class="close-button"
                  (click)="onClose()"
          >Close</button>
      </div>
    </span>
    <span *ngIf="showFooter">
      Contact Support
    </span>
  </ng-container>
</nz-drawer>

