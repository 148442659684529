import {Component, OnDestroy, OnInit} from '@angular/core';
import { HttpService } from '../shared/services/http.service';
import { Globals } from '../shared/constants/globals';
import {AppState} from '../shared/services/app-state';
import {Subscription} from 'rxjs';

@Component({
  selector: 'app-faq',
  templateUrl: './faq.component.html',
  styleUrls: ['./faq.component.scss'],
})
export class FaqComponent implements OnInit, OnDestroy {
  public showOnboarding = false;
  public searchParamData = [];
  public faqData = [];
  public loadedData = {};
  public searchInput = '';
  public faqDataTemp = [];
  public showDrawer = true;
  public drawerContent = [
    {
      title: 'FAQ',
      selected: true,
    },
    {
      title: 'Contact support',
      selected: false
    }
  ];

    public menuItems = [
    {
      id: 1,
      name: 'AffinityMonitor®',
      isSelected: true,
      route: '/afm',
      isVisible: true
    },
    {
      id: 2,
      name: 'AccessMonitor™',
      isSelected: false,
      route: '/acs',
      isVisible: true
    },
  ];

  public noResult = false;
  public isAdmin = false;
  private appStateSubscription: Subscription;
  public userDetails;

  public isLoading = true;
  template_url_1: any;
  template_url_2: any;

  constructor(private httpService: HttpService, private globals: Globals) {
    this.appStateSubscription = AppState.appState.subscribe((val) => {
      if (typeof val === 'string') {
        val = JSON.parse(val);
      }

      if (!this.userDetails) {
        if (val && val.userDetails) {
          this.userDetails = val.userDetails;
          this.isAdmin = this.userDetails.role === 'admin' || this.userDetails.role === 'manager';
        }
      }

      if (val && val.showDrawer) {
        this.showDrawer = val.showDrawer;
      }

    });
  }

  ngOnInit(): void {
    this.loadData();
  }

  public loadData(): void {
    this.httpService.postRequest('get_faqs_data', '').subscribe((faq) => {
    //this.httpService.getRequest('get_faqs_data').subscribe((faq) => {
      this.loadedData = faq.data;
      this.faqData = this.loadedData['AFM'];
      this.faqDataTemp = this.loadedData['AFM'];
      this.template_url_1 = this.loadedData['template_url_1']
      this.template_url_2 = this.loadedData['template_url_2']
      this.faqData.forEach((record) => {
        this.searchParamData.push(record.Answer);
        this.searchParamData.push(record.Question);
      });
      this.isLoading = false;
    });
  }

  public onOnboardingClose(event): void {
    this.showOnboarding = event;
  }

  public filterOnSearch(event): void {
    this.noResult = false;
    let newData = [];
    this.faqDataTemp.map((record) => {
      if (
        record.Answer.toLocaleLowerCase().includes(event.toLocaleLowerCase()) ||
        record.Question.toLocaleLowerCase().includes(event.toLocaleLowerCase())
      ) {
        newData.push(record);
      }
    });
    this.faqData = newData;
    if(this.faqData.length < 1) {
      this.noResult = true;
    }
  }

  public formatOnNoresult(event): void {
    if (event) {
      this.noResult = true;
      console.log(this.noResult);
    }
  }

  public resetSearch(event): void {
    if (event) {
      this.noResult = false;
      if(this.menuItems[1].isSelected){
        this.faqData = this.loadedData['ACM'];
        this.faqDataTemp = this.loadedData['ACM'];
      } else {
        this.faqData = this.loadedData['AFM'];
        this.faqDataTemp = this.loadedData['AFM'];
      }
    }
  }

  public switchTab(selected): void{
    this.noResult = false;
    this.searchInput = '';
    if(selected === 'AccessMonitor™'){
      this.faqData = this.loadedData['ACM'];
      this.faqDataTemp = this.loadedData['ACM'];
      this.menuItems[1].isSelected = true;
      this.menuItems[0].isSelected = false;
    } else {
      this.faqData = this.loadedData['AFM'];
      this.faqDataTemp = this.loadedData['AFM'];
      this.menuItems[1].isSelected = false;
      this.menuItems[0].isSelected = true;
    }
    this.searchParamData = [];
    this.faqData.forEach((record) => {
        this.searchParamData.push(record.Answer);
        this.searchParamData.push(record.Question);
      });
  }

  public downloadUsecase(): void {
    this.httpService.getRequest('get_download_use_cases_url').subscribe(data => {
      console.log('File download started..');
      window.open(data.url, '');
    }, (error) => {
      console.log('Something went wrong: ', error);
    });
  }

  ngOnDestroy() {
    this.appStateSubscription.unsubscribe();
  }

  switchDrawerTab(selected): void {
    // TODO: Add tab selected logic if multiple tabs are added.
  }

  public downloadFile(url, target): void {
    window.open(url, target);
  }
}
