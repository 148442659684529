import { Component, OnInit, OnDestroy, ViewChild } from '@angular/core';
import { IReportEmbedConfiguration, models, Page, Report, service, Embed } from 'powerbi-client';
import { PowerBIReportEmbedComponent } from 'powerbi-client-angular';
import { HttpService } from '../shared/services/http.service';
import { AppState } from '../shared/services/app-state';
import { Subscription } from 'rxjs';
import { Globals } from '../shared/constants/globals';
import * as FileSaver from 'file-saver';
import { NzNotificationService } from 'ng-zorro-antd/notification';
import { UserDetailsService } from '../shared/services/user-details.service';


@Component({
  selector: 'app-power-bi-report',
  templateUrl: './power-bi-report.component.html',
  styleUrls: ['./power-bi-report.component.scss']
})
export class PowerBiReportComponent implements OnInit, OnDestroy {

  @ViewChild(PowerBIReportEmbedComponent) reportObj!: PowerBIReportEmbedComponent;

  eventHandlersMap = new Map([
    ['rendered', () => this.isRendered = true]
  ]) as Map<string, (event?: service.ICustomEvent<any>, embeddedEntity?: Embed) => void | null>;


  isAdmin = false;
  loading = false;
  isRendered = false;
  isEmbedded = false;
  dashboardUnavailable = false;
  reportClass = 'power-bi-report-container';
  phasedEmbeddingFlag = false;

  showDrawer = false;
  selectedscreen = 'Executive Summary';
  selectedTab = 'Executive Summary';
  drawerContent = [
    {
      selected: true,
      title: "Executive Summary"
    },
    {
      selected: false,
      title: "Detailed Benchmarks"
    },
    {
      selected: false,
      title: "Coverage Statistics"
    }
  ];

  detailed_benchmark_tabs = [
    {
      selected: false,
      title: "Personal vs Non Personal Promotion"
    },
    {
      selected: false,
      title: "Personal Face to Face"
    },
    {
      selected: false,
      title: "Digital Push"
    },
    {
      selected: false,
      title: "Digital Pull"
    },
    {
      selected: false,
      title: "Personal Remote"
    },
    {
      selected: false,
      title: "Direct"
    },
    {
      selected: false,
      title: "Peer Interaction"
    }
  ];

  showTabs = false;
  showNotification = false;

  pageName: string;
  bookmarkState: any;

  isDownloadModalVisible = false;
  downloadType = 'currentReport';

  reportConfig: IReportEmbedConfiguration = {
    type: 'report',
    embedUrl: undefined,
    tokenType: models.TokenType.Embed,
    accessToken: undefined,
    settings: {
      panes: {
        bookmarks: {
          visible: false
        },
        fields: {
          expanded: false
        },
        filters: {
          expanded: false,
          visible: false
        },
        pageNavigation: {
          visible: false
        },
        selection: {
          visible: false
        },
        syncSlicers: {
          visible: false
        },
        visualizations: {
          expanded: false
        }
      }
    },
  };
  appStateSubscription: Subscription;



  constructor(private httpService: HttpService, public globals: Globals, private notification: NzNotificationService,
    private UserService: UserDetailsService) {
    this.isAdmin = this.globals.userDetail.role !== 'user';
    this.appStateSubscription = AppState.appState.subscribe((val) => {
      if (typeof val === 'string') {
        val = JSON.parse(val);
      }
      if (val && val.showDrawer) {
        this.showDrawer = val.showDrawer;
      }
    });

    this.pageName = "";
  }

  ngOnInit(): void {
    this.embedReport(this.selectedscreen);
  }

  embedReport(tab: string): void {
    try {
      this.loading = true;
      this.isEmbedded = false;
      this.dashboardUnavailable = false;
      const client = this.UserService.getClientName();
      const endpoint = `getembedinfo?screen=${tab}&client=${client}`;
      this.httpService.getRequest(endpoint).subscribe((response) => {

        if (response.dashboardAvailable) {
          this.reportConfig = {
            ...this.reportConfig,
            id: response.data.reportConfig.reportId,
            embedUrl: response.data.reportConfig.embedUrl,
            accessToken: response.data.accessToken,
          };

          this.loading = false;
          this.isEmbedded = true;
        } else {
          this.loading = false;
          this.dashboardUnavailable = true;

        }
      }, (error: any) => {
        this.loading = false;
        this.dashboardUnavailable = true;
        this.isEmbedded = false;
      });

    } catch (error: any) {
      console.error(error);
      return;
    }
  }

  switchTab(title: String): void {
    if (this.selectedscreen != title) {
      this.isRendered = false;

      this.drawerContent.forEach((item: any) => {
        if (item.selected) {
          item.selected = false;
        } else if (item.title == title) {
          item.selected = true;
          this.selectedscreen = item.title;
          this.selectedTab = item.title;
        }
      });

      if (this.selectedscreen === 'Detailed Benchmarks') {
        this.selectedTab = this.detailed_benchmark_tabs[0].title;
        this.embedReport(this.detailed_benchmark_tabs[0].title);

      } else {
        this.embedReport(this.selectedscreen);
      }

    }
  }

  switchDashboard(tab: string): void {
    this.isRendered = false;
    this.selectedTab = tab;
    this.embedReport(tab);
  }

  async triggerDownload() {
    this.isDownloadModalVisible = false;
    this.createBasicNotification('downloading');

    await this.getBookmarkState();
    await this.exportEeport();
  }

  async getBookmarkState() {
    try {
      const report: Report = this.reportObj.getReport();

      const activePage: Page = await report.getActivePage();
      this.pageName = activePage.displayName;

      const capturedBookmark = await report.bookmarksManager.capture();
      this.bookmarkState = capturedBookmark.state;
    }
    catch (error) {
      this.createBasicNotification('failed');
      console.log(error);
    }
  }

  exportEeport(): void {
    try {
      this.dashboardUnavailable = false;
      const client = this.UserService.getClientName();
      const endpoint = `export_report`;
      const payload = {
        bookmarkState: this.bookmarkState,
        pageName: this.pageName,
        screen: this.selectedTab,
        client: client,
        downloadType: this.downloadType
      }
      this.httpService.postRequest(endpoint, payload).subscribe((response) => {

        if (response.status === 'SUCCESS') {
          this.checkTriggerDownload(client, response.data.id);
        } else {
          this.createBasicNotification('failed');
        }
      }, error => {
        this.createBasicNotification('failed');
        console.error(error);
      });

    } catch (error: any) {
      this.createBasicNotification('failed');
      console.error(error);
    }
  }

  async checkTriggerDownload(clientName: string, exportId: string): Promise<void> {
    try {
      let pdfGenerated = false;

      const endpoint = `export_report_status?screen=${this.selectedTab}&client=${clientName}&id=${exportId}`;
      do {
        this.httpService.getRequest(endpoint).subscribe((response) => {
          if (response.status === 'SUCCESS') {
            if (response.data.percentComplete === 100) {
              this.downloadPDF(clientName, exportId);
              pdfGenerated = true;
            }

          } else {
            pdfGenerated = true;
            this.createBasicNotification('failed');
          }
        }, error => {
          this.createBasicNotification('failed');
          pdfGenerated = true;
          console.error(error);
        });

        await new Promise(f => setTimeout(f, 10000));
      } while (!pdfGenerated);

    } catch (error: any) {
      this.createBasicNotification('failed');
      console.error(error);
    }
  }

  downloadPDF(clientName: string, exportId: string): void {
    try {

      const endpoint = `download_exported_report?screen=${this.selectedTab}&client=${clientName}&id=${exportId}`;
      this.httpService.downloadPDF(endpoint).subscribe((response) => {
        FileSaver.saveAs(response, `${this.selectedTab}.${this.downloadType === 'currentReport' ? 'pdf' : 'zip'}`);
        this.createBasicNotification('success');
      }, error => {
        this.createBasicNotification('failed');
        console.error(error);
      });


    } catch (error: any) {
      this.createBasicNotification('failed');
      console.error(error);
    }
  }

  public createBasicNotification(status: string): void {
    this.showNotification = true;

    let msg = '';
    let bgColor = '';
    let title = '';
    let color = '#FFFFFF';
    let nzPlacement: any = 'bottomLeft';
    if (status === 'success') {
      msg = 'File successfully downloaded.';
      bgColor = '#00AA67';
    } else if (status === 'downloading') {
      title = 'Download in progress';
      msg = `Report ${this.selectedTab} is being exported to a PDF file. This might take a few minutes`;
      bgColor = '#FFFFFF';
      color = '#000000';
      nzPlacement = 'topRight';
    } else {
      msg = 'There was an error in downloading the file. Please try again.';
      bgColor = 'red';
    }

    this.notification.blank(title, msg, {
      nzPlacement: nzPlacement,
      nzStyle: { background: bgColor, color: color },
    });
  }

  showModal(): void {
    this.downloadType = 'currentReport';
    this.isDownloadModalVisible = true;
  }

  downloadModalCancel(): void {
    this.isDownloadModalVisible = false;
  }



  ngOnDestroy(): void {
    this.appStateSubscription.unsubscribe();
  }
}
