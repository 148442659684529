<div class="dashboard-container">
    <!-- Top section -->
    <div class="top-section">
        <span nz-typography class="tab-header">{{selectedscreen}}</span>
        <nz-space [nzSize]="'middle'" *ngIf="!isAdmin">
            <app-download *nzSpaceItem [isDisabled]="loading || !isRendered" (click)="showModal()"></app-download>
        </nz-space>
        <div class="dropdown" *ngIf="isAdmin"><app-simple-dropdown [page]="'dashboard'"></app-simple-dropdown></div>
    </div>

    <!-- tabs -->
    <span *ngIf="selectedscreen === 'Detailed Benchmarks'">
        <div class="tabs">
            <nz-tabset>
                <nz-tab *ngFor="let tab of detailed_benchmark_tabs" (nzClick)="switchDashboard(tab.title)"
                    [nzTitle]="tab.title"></nz-tab>
            </nz-tabset>
        </div>
    </span>

    <!-- Spinner -->
    <ng-container *ngIf="loading">
        <div class="spin">
            <nz-spin nzSimple></nz-spin>
        </div>
    </ng-container>

    <!-- Power BI Dashboard -->
    <ng-container *ngIf="isEmbedded">
        <powerbi-report [embedConfig]="reportConfig" [cssClassName]="reportClass" [eventHandlers]="eventHandlersMap"
            [phasedEmbedding]="phasedEmbeddingFlag">
        </powerbi-report>
    </ng-container>

    <!-- Show Error Message -->
    <ng-container *ngIf="dashboardUnavailable">
        <div class="spin">Dashboard is not available.</div>
    </ng-container>

    <!-- App Drawer -->
    <app-drawer [(showDrawer)]="showDrawer" [content]="drawerContent" [title]="'DASHBOARD'"
        (tabChanged)="switchTab($event)"></app-drawer>

    <!-- Modal -->
    <nz-modal [(nzVisible)]="isDownloadModalVisible" nzTitle="Download Report" (nzOnCancel)="downloadModalCancel()"
        (nzOnOk)="triggerDownload()" [nzMaskClosable]="false" [nzOkText]="'Download'"
        nzClassName="power-bi-download-modal">
        <ng-container *nzModalContent>
            <div class="data-layout-wrapper" style="padding: 24px;">
                <nz-radio-group [(ngModel)]="downloadType">
                    <label nz-radio nzValue="currentReport">Download the current dashboard with the selected filters</label>
                    <label nz-radio nzValue="all">Download the complete dashboard</label>
                </nz-radio-group>
            </div>
        </ng-container>
    </nz-modal>
</div>