import { Component, EventEmitter, Input, OnInit, Output } from '@angular/core';
import { NzModalRef, NzModalService } from 'ng-zorro-antd/modal';
import { HttpService } from '../../../shared/services/http.service';
import { ReplaceValuePipe } from '../../../shared/pipe/replace-value.pipe';
import { RollDown } from 'src/app/shared/models/roll-down.model';
// import { identifierModuleUrl } from '@angular/compiler';
import { NzNotificationService } from 'ng-zorro-antd/notification';

@Component({
  selector: 'app-steward-review',
  templateUrl: './steward-review.component.html',
  styleUrls: ['./steward-review.component.scss'],
})
export class StewardReviewComponent implements OnInit {
  @Input() showReviewModal;
  @Output() hideReviewModal: EventEmitter<boolean> = new EventEmitter<boolean>();
  @Input() id = 0;
  @Input() status = 'Pending';

  public replaceValue = new ReplaceValuePipe();

  constructor(private modal: NzModalService, private httpService: HttpService,private notification: NzNotificationService,) {}

  public confirmModal?: NzModalRef;
  public classificationData = [];
  public loading = false;
  public editCache: { [key: string]: { edit: boolean; data: string; isValid: boolean } } = {};
  public isValidFeedback = true;
  public programName = '';
  public fileName = '';
  public processId = '';
  public channelName = '';
  public rejectReason = '';
  public showRejectScreen = false;
  public dataLayoutUrl = '';
  public sampleDataPreview = false;
  public profilingStatsPreview = false;
  public isValidReason = true;
  public mandatoryColumns = true;
  public showRollDownScreen = false;
  public eventColumnName = '';
  public eventColumnValue = '';
  public dateColumnPresent = ['True', 'False'];
  public dateColumnValue = '';
  public addedRows = 1;
  public rolledDown = false;
  public errorMessage ;
  public rollDownData: RollDown[] = [
    {
      id: this.addedRows,
      eventSourceColumnName: '',
      eventTargetColumnValue: '',
      eventSourceColumnValue: '',
      dateSourceColumnName: '',
      expression: false,
    },
  ];
  public expression = ['True', 'False'];
  public expressionValue = '';
  public dateColumnPresentValue = '';

  ngOnInit(): void {
    this.loadData();
  }

  public onCancel(): void {
    this.showReviewModal = false;
    let title = '';
    let content = '';
    let okText = '';
    if (this.showRollDownScreen) {
      title = 'Leave rolling down process';
      content = 'Are you sure you want to leave the rolling down process?';
      okText = 'Yes, Leave';
    } else {
      title = 'Leave classification feedback review';
      content = 'Are you sure you want to leave the review process?';
      okText = 'Yes, Leave';
    }
    this.confirmModal = this.modal.confirm({
      nzTitle: title,
      nzContent: content,
      nzOkText: okText,
      nzIconType: 'exclamation-circle',
      nzOnOk: () => {
        this.hideReviewModal.emit(false);
        this.showReviewModal = false;
      },
      nzOnCancel: () => {
        this.showReviewModal = true;
      },
    });
  }

  public loadData(): void {
    this.errorMessage = '';
    this.loading = true;
    const payload = {
      id: this.id,
    };
    this.httpService.postRequest('get_steward_review_details', payload).subscribe((data) => {
    // this.httpService.getRequest('get_steward_review_details').subscribe((data) => {
      const classificationDataDuplicate = data.classification_table.tbody;
      this.processId = data.process_id;
      this.channelName = data.channel_name;
      this.programName = data.program_name;
      this.rolledDown = data.rolled_down;
      this.fileName = data.file_name;
      this.rejectReason = data.reject_reason;
      this.mandatoryColumns = data.mandatory_columns_present;
      this.dataLayoutUrl = data.data_layout_url;
      classificationDataDuplicate.map((d) => {
        this.classificationData.push({
          id: d.id,
          selected: d.selected,
          disabled: d.selected,
          column_name: d.column_name,
          feedback: d.feedback,
          recommended_std_name: d.recommended_std_name,
          other_recommendation: d.other_recommendation,
        });
      });
      this.updateEditCache();
      this.loading = false;
    }, (error) => {
      this.errorMessage = "Something went wrong";
      this.createBasicNotification('Something went wrong. Please try again..');
    });
  }

  public startEdit(id): void {
    this.editCache[id].edit = true;
  }

  public saveEdit(id): void {
    this.editCache[id].edit = false;
  }

  public checkFeedback(id): void {
    this.editCache[id].data = this.editCache[id].data
      ? this.replaceValue.transform(this.editCache[id].data, true)
      : '';
    this.editCache[id].isValid = this.editCache[id].data
      ? this.editCache[id].data.length <= 50
      : true;
  }

  public checkReviewed(): boolean {
    if (this.status === 'Pending' && !this.rolledDown) {
      return false;
    } else {
      return true;
    }
  }

  public updateEditCache(): void {
    this.classificationData.forEach((data) => {
      this.editCache[data.id] = {
        edit: false,
        data: '',
        isValid: true,
      };
    });
  }

  public downloadFile(url, target): void {
    window.open(url, target);
  }

  public previewSampleData(): void {
    this.sampleDataPreview = true;
  }
  public sampleDataClose(event): void {
    this.sampleDataPreview = event;
  }
  public previewProfilingStats(): void {
    this.profilingStatsPreview = true;
  }
  public profilingStatsClose(event): void {
    this.profilingStatsPreview = event;
  }

  public submitReview(review): void {
    const tableData = [];
    this.classificationData.map((data) => {
      tableData.push({
        id: data.id,
        selected: data.selected,
        recommended_std_name: data.recommended_std_name,
        other_recommendation: data.other_recommendation,
        column_name: data.column_name,
        feedback: this.editCache[data.id].edit ? '' : this.editCache[data.id].data,
      });
    });

    let payload = {};
    let title = '';
    let content = '';
    let text = '';
    if (review === 'Approved') {
      payload = {
        id: this.id,
        process_id: this.processId,
        review: 'Approved',
        reject_reason: '',
        table_data: tableData,
        roll_down_data: {}
      };
      title = 'Approve Request';
      content =
        'The change is permanent, and you will not be able to modify this. Are you sure you want to approve this classification feedback?';
      text = 'Yes, Approve';
    } else {
      payload = {
        id: this.id,
        process_id: this.processId,
        review: 'Rejected',
        reject_reason: this.rejectReason,
        table_data: tableData,
        roll_down_data: {}
      };
      title = 'Reject Request';
      content =
        'The change is permanent, and you will not be able to modify this. Are you sure you want to reject this classification feedback?';
      text = 'Yes, Reject';
    }
    this.showReviewModal = false;
    this.confirmModal = this.modal.confirm({
      nzTitle: title,
      nzContent: content,
      nzOkText: text,
      nzIconType: 'exclamation-circle',
      nzOnOk: () => {
        this.httpService.postRequest('submit_steward_review', payload).subscribe((data) => {
          // this.httpService.getRequest('submit_steward_review').subscribe((data) => {
          if (data['status'] === 'SUCCESS') {
            this.hideReviewModal.emit(false);
            this.showReviewModal = false;
          } else {
            console.log(data['message']);
            this.createBasicNotification('Something went wrong. Please try again..');

          }
        }, (error) => {
          this.errorMessage = "Something went wrong";
        });
      },
      nzOnCancel: () => {
        this.showReviewModal = true;
      },
    });
  }

  public submitRollDown(): void {
    let payload = {};
    payload = {
      id: this.id,
      process_id: this.processId,
      review: '',
      reject_reason: '',
      table_data: [],
      roll_down_data: {
        header_data: {
          event_column_name: this.eventColumnName,
          event_column_value: this.eventColumnValue,
          date_column_present: this.dateColumnPresentValue,
          date_column_value: this.dateColumnValue,
        },
        body_data: this.rollDownData,
      },
    };
    console.log(payload);
    this.httpService.postRequest('submit_steward_review', payload).subscribe((data) => {
      // this.httpService.getRequest('submit_steward_review').subscribe((data) => {
      if (data['status'] === 'SUCCESS') {
        this.hideReviewModal.emit(false);
        this.showReviewModal = false;
      } else {
        console.log(data['message']);
        this.createBasicNotification('Something went wrong. Please try again..');
      }
    }, (error) => {
      this.errorMessage = "Something went wrong";
    });
  }

  public numSequence(n: number): Array<number> {
    return Array(n);
  }

  public rejectClicked(): void {
    this.showRejectScreen = true;
  }

  public rollDownClicked(): void {
    this.showRollDownScreen = true;
    this.eventColumnName = '';
    this.eventColumnValue = '';
    this.dateColumnValue = '';
    this.addedRows = 1;
    this.rollDownData = [
    {
      id: this.addedRows,
      eventSourceColumnName: '',
      eventTargetColumnValue: '',
      eventSourceColumnValue: '',
      dateSourceColumnName: '',
      expression: false,
    },
  ];
  this.expressionValue = '';
  this.dateColumnPresentValue = '';
  }

  public checkReason(): void {
    this.rejectReason = this.replaceValue.transform(this.rejectReason, true);
    this.isValidReason = this.rejectReason ? this.rejectReason.length <= 2000 : true;
  }
  public disableEnter(event): void {
    if (!this.rejectReason && event.key == 'Enter') {
      event.preventDefault();
    }
  }
  public isdisabled(): boolean {
    if (this.isValidReason && this.rejectReason) {
      return false;
    } else {
      return true;
    }
  }
  public onBack(): void {
    this.showRejectScreen = false;
    this.rejectReason = '';
    this.isValidReason = true;
  }
  public buttonDisabled(): boolean {
    let disable = false;
    this.classificationData.map((data) => {
      if (!disable) {
        disable = this.editCache[data.id].edit;
      }
    });
    return disable || this.rolledDown;
  }

  public submitRollDownDisabled(): boolean {
    let rollDownLeft = true;
    this.rollDownData.forEach((row) => {
      if (
        !row.dateSourceColumnName ||
        !row.eventSourceColumnName ||
        !row.eventSourceColumnValue ||
        !row.eventTargetColumnValue
      ) {
        rollDownLeft = true;
      } else {
        rollDownLeft = false;
      }
    });
    if (
      !this.eventColumnName ||
      !this.eventColumnValue ||
      !this.dateColumnPresentValue ||
      !this.dateColumnValue ||
      rollDownLeft
    ) {
      return true;
    } else {
      return false;
    }
  }

  public backClicked(): void {
    this.showReviewModal = false;
    this.confirmModal = this.modal.confirm({
      nzTitle: 'Leave rolling down process',
      nzContent: 'Are you sure you want to leave the rolling down process?',
      nzOkText: 'Yes, Leave',
      nzIconType: 'exclamation-circle',
      nzOnOk: () => {
        this.showRollDownScreen = false;
        this.showReviewModal = true;
      },
      nzOnCancel: () => {
        this.showReviewModal = true;
        this.showRollDownScreen = true;
      },
    });
  }

  public addRow(): void {
    this.addedRows = this.addedRows + 1;
    this.rollDownData.push({
      id: this.addedRows,
      eventSourceColumnName: '',
      eventTargetColumnValue: '',
      eventSourceColumnValue: '',
      dateSourceColumnName: '',
      expression: false,
    });
  }
  public removeRow(): void {
    this.rollDownData.pop();
    this.addedRows = this.addedRows - 1;
  }
  public expressionSelected(event, id): void {
    this.expressionValue == event;
    this.rollDownData.forEach((i) => {
      if (i.id === id) {
        i.expression = event;
      }
    });
  }
  public dateColumnSelected(event): void {
    this.dateColumnPresentValue = event;
  }
  public createBasicNotification(msg): void {
    let bgColor = '';
    bgColor = 'red';
    const test = this.notification.blank('',  msg, {
      nzPlacement: 'bottomLeft',
      nzDuration: 0,
      nzStyle: { background: bgColor, color: '#FFFFFF', padding: '20px'}
    });
    
  }
}
