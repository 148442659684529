import { Component, Input, OnDestroy, OnInit } from '@angular/core';
import { NzTableFilterFn, NzTableFilterList, NzTableSortFn, NzTableSortOrder } from 'ng-zorro-antd/table';
import { Subscription } from 'rxjs';
import { AppState } from '../../../shared/services/app-state';
import { DatePipe } from '@angular/common';
import { Globals } from '../../../shared/constants/globals';

interface DataItem {
  semester: string;
  specialities: number;
  status: string;
}

@Component({
  selector: 'app-subscription-table',
  templateUrl: './subscription-table.component.html',
  styleUrls: ['./subscription-table.component.scss'],
})
export class SubscriptionTableComponent implements OnInit, OnDestroy {
  @Input() listOfData = [
    {
      id: '',
      status: '',
      semester: '',
      specialties: [],
    },
  ];
  @Input() headerData = {
    current_status: '',
    subscription: '',
    start_date: '',
    end_date: '',
    specialties_included: [],
  };

  @Input() specialities = [];
  @Input() selectedTab = '';
  public sortColumns = {
    status: {
      sortOrder: 'ascend',
      sortDirection: ['ascend', 'descend'],
    },
    cycle: {
      sortOrder: 'ascend',
      sortDirection: ['ascend', 'descend'],
    },
    specialties: {
      filterMultiple: true,
      listOfFilter: [
        { text: 'Allergy', value: 'Allergy' },
        { text: 'Anesthesiology', value: 'Anesthesiology' },
      ],
      filterFn: (list: string[], item: DataItem) =>
        list.some((semester) => item.semester.indexOf(semester) !== -1),
    },
  };
  public actualData = [];
  public showFilter = false;
  public client = '';
  public typeOptions = ['Monthly', 'Quarterly', 'Semesterly'];

  private appStateSubscription: Subscription;

  constructor(private appState: AppState, public datePipe: DatePipe, public globals: Globals) {
    this.client = this.globals.userDetail.client;
    this.appStateSubscription = AppState.appState.subscribe((val) => {
      if (val && !val.showDropdown) {
        this.showFilter = false;
      }
    });
  }

  ngOnInit(): void {
    this.sortColumns.specialties.listOfFilter = this.specialities;
    this.actualData = [...this.listOfData];
    if (this.selectedTab === 'AccessMonitor™') {
      this.updateData('Semesterly');
    }
  }

  public sortData(column: string, reportIndex?: number): void {
    if (this.sortColumns[column].sortOrder === 'descend') {
      if (column === 'status') {
        this.listOfData.sort((a, b) => a[column].localeCompare(b[column]));
      } else {
        this.listOfData.sort((a, b) => {
          const yearA = a[column].split(' ');
          const yearB = b[column].split(' ');
          if (yearA[1] === yearB[1]) {
            return yearB[0].localeCompare(yearA[0]);
          } else {
            return yearA[1] < yearB[1] ? 1 : -1;
          }
        });
      }
    } else {
      if (column === 'status') {
        this.listOfData.sort((a, b) => b[column].localeCompare(a[column]));
      } else {
        this.listOfData.sort((a, b) => {
          const yearA = a[column].split(' ');
          const yearB = b[column].split(' ');
          if (yearA[1] === yearB[1]) {
            return yearB[0].localeCompare(yearA[0]);
          } else {
            return yearA[1] > yearB[1] ? 1 : -1;
          }
        });
      }
    }

    this.sortColumns[column].sortOrder =
      this.sortColumns[column].sortOrder === 'ascend' ? 'descend' : 'ascend';
  }

  public filterSpecialities(): void {
    if (this.highlightFilter()) {
      this.listOfData = [];
      this.actualData.map((data) => {
        if (data.specialties.length > 0) {
          this.specialities.map((speciality) => {
            if (speciality.value) {
              if (data.specialties.indexOf(speciality.text) !== -1) {
                this.listOfData.push(data);
              }
            }
          });
        }
      });
      this.listOfData = [...new Set(this.listOfData)];
    } else {
      this.resetTable();
    }
    this.showFilter = false;
  }

  public resetTable(): void {
    this.specialities.forEach((speciality) => {
      speciality.value = false;
    });
    this.listOfData = [...this.actualData];
    this.showFilter = false;
    this.sortColumns.status.sortOrder = 'ascend';
    this.sortColumns.cycle.sortOrder = 'ascend';
  }

  public highlightFilter(): boolean {
    return this.specialities.some((speciality) => speciality.value === true);
  }

  public openChange(event): void {
    this.appState.setGlobalState('showDropdown', event);
  }

  ngOnDestroy(): void {
    this.appStateSubscription.unsubscribe();
  }
  public getDate(date): string {
    if (date === 'NA') {
      return date;
    } else {
      try {
        return date ? this.datePipe.transform(new Date(date), 'dd MMM y') : 'NA';
      } catch (e) {
        return 'invalid date';
      }
    }
  }

  public updateData(value): void {
    this.listOfData = this.actualData.filter((data) => data.type === value);
  }
}
