import { Component, EventEmitter, Input, OnInit, Output } from '@angular/core';
import { NzModalRef, NzModalService } from 'ng-zorro-antd/modal';
import { HttpService } from '../../../shared/services/http.service';
import { DatePipe } from '@angular/common';
import { ProcessDetailsModel } from '../../../shared/models/process-details.model';

@Component({
  selector: 'app-process-details-modal',
  templateUrl: './process-details-modal.component.html',
  styleUrls: ['./process-details-modal.component.scss'],
})
export class ProcessDetailsModalComponent implements OnInit {
  @Input() showModal;
  @Output() hideModal: EventEmitter<boolean> = new EventEmitter<boolean>();
  @Input() id = 0;

  public filterTable = ['All', 'Upload Status', 'Data Profiler', 'REVO Status'];

  constructor(
    private modal: NzModalService,
    private httpService: HttpService,
    private datePipe: DatePipe,
  ) {}

  public confirmModal?: NzModalRef;
  public tableData = [];
  public tableDataDuplicate = [];
  public loading = false;
  public payload = {
    id: 0
  };
  public processDetails: ProcessDetailsModel = new ProcessDetailsModel();
  public filterPayload = [];

  ngOnInit(): void {
    this.loading = true;
    this.loadData();
  }

  public onCancel(): void {
    this.showModal = false;
    this.confirmModal = this.modal.confirm({
      nzTitle: 'Leave Process Details',
      nzContent: 'Are you sure you want to leave?',
      nzOkText: 'Yes, Leave',
      nzIconType: 'exclamation-circle',
      nzOnOk: () => {
        this.hideModal.emit(false);
        this.showModal = false;
      },
      nzOnCancel: () => {
        this.showModal = true;
      },
    });
  }

  public loadData(): void {
    this.payload = {
      id: this.id
    };
    this.httpService.postRequest('get_process_details', this.payload).subscribe((data) => {
    // this.httpService.getRequest('get_process_details').subscribe((data) => {
      this.processDetails = data;
      this.tableData = data.tableData.tbody;
      this.tableDataDuplicate = this.tableData;
      this.loading = false;
    });
  }
  public getDate(date): string {
    try {
      return this.datePipe.transform(new Date(date), 'MM/d/yy h:mm a');
    } catch (e) {
      return 'N/A';
    }
  }

  public selectedValue(event): void {
    this.filterPayload = event;
    this.tableData = [];
    if (event.includes('Upload Status')){
      this.tableDataDuplicate.forEach((data) => {
        if (data.step === 'File Loaded' || data.step === 'L1 Check'){
          this.tableData.push(data);
        }
      });
    }
    if (event.includes('Data Profiler')){
      this.tableDataDuplicate.forEach((data) => {
        if (data.step === 'Feedback' || data.step === 'iData'){
          this.tableData.push(data);
        }
      });
    }
    if (event.includes('REVO Status')){
      this.tableDataDuplicate.forEach((data) => {
        if (data.step === 'L2 Check'){
          this.tableData.push(data);
        }
      });
    }
    if (event.includes('All') || event.length < 1){
      this.tableData = this.tableDataDuplicate;
    }
  }
  public downloadFile(url, target): void {
    window.open(url, target);
  }
}
