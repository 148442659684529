import { Component, Input, OnInit } from '@angular/core';

@Component({
  selector: 'app-mapping-table',
  templateUrl: './mapping-table.component.html',
  styleUrls: ['./mapping-table.component.scss']
})
export class MappingTableComponent implements OnInit {
  @Input() headers;
  @Input() fileData;
  @Input() customClass = '';
  widthval = "100px";
  constructor() { }

  ngOnInit(): void {
    
  }

}
