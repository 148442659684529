import { Component, Input, OnInit, Output, EventEmitter } from '@angular/core';
import { ReplaceValuePipe } from '../../../shared/pipe/replace-value.pipe';
import { NzUploadChangeParam, NzUploadFile } from 'ng-zorro-antd/upload';
import { NzModalRef, NzModalService } from 'ng-zorro-antd/modal';

@Component({
  selector: 'app-add-custom-program',
  templateUrl: './add-custom-program.component.html',
  styleUrls: ['./add-custom-program.component.scss'],
})
export class AddCustomProgramComponent implements OnInit {
  constructor(private modal: NzModalService) {}

  public confirmModal?: NzModalRef;

  @Input() channelName = '';
  @Input() programs = [];
  @Input() channelId = '';
  @Input() commaDelimCheck;
  @Input() programExistsCheck;

  public replaceValue = new ReplaceValuePipe();
  public formData = new FormData();
  public isInprogress = false;
  public uploadPercent = 0;
  public customError = '';
  public showUploadError = false;
  public nzFileList: NzUploadFile[] = [];
  public fileList: File[] = [];
  public deletedFiles = [];

  public isValidProgram = true;
  public validProgramChar = true;
  public program = '';
  public description = '';
  public isValidDescription = true;
  public searchValue = /[?\/\\:[\]*]/gm;
  public isProgramDuplicate = false;

  @Output() enableSubmit = new EventEmitter<boolean>();
  @Output() customFormData = new EventEmitter<object>();
  @Output() customProgramName = new EventEmitter<string>();
  @Output() commaDelimCheckChange = new EventEmitter<boolean>();
  @Output() programExistsCheckChange = new EventEmitter<boolean>();

  public programAdded = false;
  public fileAdded = false;

  ngOnInit(): void {
    this.enableSubmit.emit(false);
  }

  public checkProgram(): void {
    this.programExistsCheck = false;
    this.programExistsCheckChange.emit(this.programExistsCheck);
    this.isProgramDuplicate = false;
    this.program = this.replaceValue.transform(this.program, true);
    this.isValidProgram = this.program ? this.program.length <= 50 : true;
    this.programAdded = this.program ? this.program.length > 0 : false;
    this.program
      ? this.program.match(this.searchValue)
        ? (this.validProgramChar = false)
        : (this.validProgramChar = true)
      : (this.validProgramChar = true);
    const progtemp = this.program ? this.program.replace(/\s+/g, ' ').trim() : '';
    if (progtemp && this.programs.find((p) => p.toLowerCase() === progtemp.toLowerCase())) {
      this.isProgramDuplicate = true;
    } else {
      this.isProgramDuplicate = false;
    }
    this.submitRequestEnable();
  }
  public checkDescription(): void {
    this.description = this.replaceValue.transform(this.description, true);
    this.isValidDescription = this.description ? this.description.length <= 1000 : true;

    this.submitRequestEnable();
  }

  public fileChanged(files: NzUploadChangeParam): void {
    this.formData = new FormData();
    this.isInprogress = true;
    this.uploadPercent = 5;
    const fileNames = [];
    this.customError = '';
    this.showUploadError = false;
    if (files.type === 'start') {
      files.fileList.forEach((file) => {
        this.fileList.push(file.originFileObj);
      });
    }

    this.showUploadError = this.checkFileType();

    files = undefined;
    this.nzFileList = [];
    this.isInprogress = false;
    this.submitRequestEnable();
  }
  public checkFileType(): boolean {
    let isValid = false;
    let validFileType = true;
    this.showUploadError = false;
    this.fileList.forEach((file) => {
      const type = file.name.substring(file.name.length - 4, file.name.length);
      if (type === '.csv') {
        isValid = true;
        file['isValid'] = isValid;
        this.customError = '';
        this.fileAdded = true;
      } else {
        isValid = false;
        validFileType = false;
        file['isValid'] = isValid;
        this.showUploadError = true;
        this.isInprogress = false;
        this.fileAdded = false;
      }
    });
    return !validFileType;
  }

  public deleteFile(isValid = false): void {
    if (isValid) {
      this.confirmModal = this.modal.confirm({
        nzTitle: 'Delete Uploaded File?',
        nzContent: 'Are you sure you want to delete this file?',
        nzOkText: 'Yes, Delete',
        nzIconType: 'exclamation-circle',
        nzOnOk: () => {
          this.fileList = [];
          this.showUploadError = false;
          this.customError = '';
          this.fileAdded = false;
          this.commaDelimCheck = true;
          this.commaDelimCheckChange.emit(this.commaDelimCheck);
          this.programExistsCheck = false;
          this.programExistsCheckChange.emit(this.programExistsCheck);

          this.submitRequestEnable();
        },
        nzOnCancel: () => {
          this.fileAdded = true;
          this.submitRequestEnable();
        },
      });
    } else {
      this.fileList = [];
      this.showUploadError = false;
      this.customError = '';
      this.fileAdded = false;
      this.commaDelimCheck = true;
       this.commaDelimCheckChange.emit(this.commaDelimCheck);
      this.programExistsCheck = false;
      this.programExistsCheckChange.emit(this.programExistsCheck);
      this.submitRequestEnable();
    }
  }

  public submitRequestEnable(): void {
    if (
      this.fileAdded &&
      this.isValidProgram &&
      this.validProgramChar &&
      !this.isProgramDuplicate &&
      this.programAdded &&
      this.isValidDescription &&
      this.commaDelimCheck &&
      !this.programExistsCheck
    ) {
      this.enableSubmit.emit(true);
      this.customProgramName.emit(this.program);
      this.formData = new FormData();
      this.formData.append('custom_program', this.program);
      this.formData.append('template_file', this.fileList[0]);
      this.formData.append('channel_name', this.channelName);
      this.formData.append('channel_id', this.channelId);
      this.formData.append('description', this.description);
      this.formData.append('reason', '');
      this.formData.append('product', '');
      this.formData.append('client', '');
      this.formData.append('role', 'user');
      console.log(this.formData);
      this.customFormData.emit(this.formData);
    } else {
      this.enableSubmit.emit(false);
    }
  }
}
