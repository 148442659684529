import { CommonModule } from '@angular/common';
import { NgModule } from '@angular/core';
import { HttpClientModule } from '@angular/common/http';
import { environment } from 'src/environments/environment';
import { InMemoryWebApiModule } from 'angular-in-memory-web-api';
import { DataService } from './services/app-in-memory';
import { HttpClientInMemoryWebApiModule } from 'angular-in-memory-web-api';
import { SearchBarComponent } from './components/search-bar/search-bar.component';
import { NzIconModule } from 'ng-zorro-antd/icon';
import { NzInputModule } from 'ng-zorro-antd/input';
import { DownloadComponent } from './components/download/download.component';
import { NzButtonModule } from 'ng-zorro-antd/button';
import { PreviewModalComponent } from './components/preview-modal/preview-modal.component';
import { NzModalModule } from 'ng-zorro-antd/modal';
import { NzTableModule } from 'ng-zorro-antd/table';
import { NotifyPopupComponent } from './components/notify-popup/notify-popup.component';
import { NzSpaceModule } from 'ng-zorro-antd/space';
import { NzAutocompleteModule } from 'ng-zorro-antd/auto-complete';
import { FormsModule } from '@angular/forms';
import { AppState } from './services/app-state';
import { DropdownComponent } from './components/dropdown/dropdown.component';
import { NzSelectModule } from 'ng-zorro-antd/select';
import { NzPopoverModule } from 'ng-zorro-antd/popover';
import { OnboardingComponent } from './components/onboarding/onboarding.component';
import { NzCarouselModule } from 'ng-zorro-antd/carousel';
import { NzGridModule } from 'ng-zorro-antd/grid';
import { DrawerComponent } from './components/drawer/drawer.component';
import { NzDrawerModule } from 'ng-zorro-antd/drawer';
import { ReplaceValuePipe } from './pipe/replace-value.pipe';
import { Globals } from './constants/globals';
import { SimpleDropdownComponent } from './components/simple-dropdown/simple-dropdown.component';
import { NzDropDownModule } from 'ng-zorro-antd/dropdown';
import { ImportModalComponent } from './components/import-modal/import-modal.component';
import { NzUploadModule } from 'ng-zorro-antd/upload';
import { NzPipesModule } from 'ng-zorro-antd/pipes';
import { NzSpinModule } from 'ng-zorro-antd/spin';

@NgModule({
  declarations: [
    SearchBarComponent,
    DownloadComponent,
    PreviewModalComponent,
    NotifyPopupComponent,
    DropdownComponent,
    OnboardingComponent,
    DrawerComponent,
    ReplaceValuePipe,
    SimpleDropdownComponent,
    ImportModalComponent,
  ],
  imports: [
    CommonModule,
    NzModalModule,
    NzTableModule,
    HttpClientModule,
    NzIconModule,
    NzInputModule,
    NzButtonModule,
    NzSpaceModule,
    NzAutocompleteModule,
    FormsModule,
    NzSelectModule,
    environment.production
      ? []
      : HttpClientInMemoryWebApiModule.forRoot(DataService, { dataEncapsulation: false }),
    NzPopoverModule,
    NzCarouselModule,
    NzGridModule,
    NzDrawerModule,
    NzDropDownModule,
    NzUploadModule,
    NzPipesModule,
    NzSpinModule,
  ],
  providers: [DataService, AppState, Globals],
  exports: [
    SearchBarComponent,
    DownloadComponent,
    PreviewModalComponent,
    NotifyPopupComponent,
    DropdownComponent,
    OnboardingComponent,
    DrawerComponent,
    SimpleDropdownComponent,
  ],
})
export class SharedModule {}
