<nz-table
  #standardProgramTable
  [nzData]="programData"
  class="standard-programs-table"
  nzTableLayout="fixed"
  [nzShowPagination]="true"
  [nzPageSize]="8"
  [nzShowQuickJumper]="true"
  [nzNoResult]="noRecords"
  [(nzPageIndex)]="pageIndex"
  [nzLoading]="loading"
>
  <thead>
    <tr>
      <th nzWidth="20%">Program Name</th>
      <th nzWidth="20%" nzCustomFilter class="channel-filter">
        <nz-filter-trigger [nzDropdownMenu]="customFilterChannel" [(nzVisible)]="showFilterChannel" (nzVisibleChange)="openChange($event)">
          <i
            nz-icon
            nzType="filter"
            nzTheme="fill"
            [ngClass]="highlightFilterChannel() ? 'highlight' : ''"
          ></i>
        </nz-filter-trigger>
        <nz-dropdown-menu #customFilterChannel="nzDropdownMenu">
          <div class="dropdown-container">
            <div class="ant-table-filter-dropdown">
              <ul nz-menu *ngFor="let item of channel">
                <li nz-menu-item>
                  <label nz-checkbox [(ngModel)]="item.value"> {{ item.text }} </label>
                </li>
              </ul>
            </div>
            <div class="button-wrapper">
              <a (click)="resetChannel()">Reset</a>&nbsp;
              <button
                nz-button
                nzType="primary"
                class="app-primary-button button-class"
                (click)="filter(false)"
              >
                OK
              </button>
            </div>
          </div>
        </nz-dropdown-menu>
        Channel</th>
      <th nzWidth="15%" [nzSortOrder]="sortOrder" (click)="filter(true)" [nzSortDirections]="sortDirection">Date Added</th>
      <th nzWidth="10%" nzCustomFilter class="status-filter">
        <nz-filter-trigger [nzDropdownMenu]="customFilterStatus" [(nzVisible)]="showFilterStatus" (nzVisibleChange)="openChange($event)">
          <i
            nz-icon
            nzType="filter"
            nzTheme="fill"
            [ngClass]="highlightFilterStatus() ? 'highlight' : ''"
          ></i>
        </nz-filter-trigger>
        <nz-dropdown-menu #customFilterStatus="nzDropdownMenu">
          <div class="dropdown-container">
            <div class="ant-table-filter-dropdown">
              <ul nz-menu *ngFor="let item of status">
                <li nz-menu-item>
                  <label nz-checkbox [(ngModel)]="item.value"> {{ item.text }} </label>
                </li>
              </ul>
            </div>
            <div class="button-wrapper">
              <a (click)="resetStatus()">Reset</a>&nbsp;
              <button
                nz-button
                nzType="primary"
                class="app-primary-button button-class"
                (click)="filter(false)"
              >
                OK
              </button>
            </div>
          </div>
        </nz-dropdown-menu>
        Status</th>
      <th nzWidth="10%">Program Layout</th>
      <th nzWidth="15%">Actions</th>
    </tr>
  </thead>
  <tbody>
    <tr *ngFor="let data of standardProgramTable.data">
      <td class="row-data">{{ data.program_name }}</td>
      <td class="row-data">{{ data.channel }}</td>
      <td class="row-data">{{ getDate(data.date) }}</td>
      <td class="row-data">
        <span *ngIf="data.status === 'Active'" class="active">
          <i nz-icon nzType="check-circle" nzTheme="fill"></i>
        </span>
        <span *ngIf="data.status === 'Retired'" class="retired">
          <i nz-icon nzType="minus-circle" nzTheme="fill"></i>
        </span>
        <span *ngIf="data.status.match(activePendingRegex) || data.status.match(retiredPendingRegex)" class="pending">
          <i nz-icon nzType="clock-circle" nzTheme="outline"></i>
        </span>
        {{ data.status }}
      </td>
      <td class="row-data-i">
        <i
          nz-icon
          nzType="download"
          nzTheme="outline"
          (click)="downloadFile(data.program_layout_url, '')"
        ></i>
      </td>
      <td class="review" (click)="reviewClicked(data)">
        <i nz-icon nzType="file-search" nzTheme="outline"></i>&nbsp;&nbsp;Review
      </td>
    </tr>
  </tbody>
  <ng-template #noRecords>
    <div class="no-requests">{{ noDataMessage }}</div>
  </ng-template>
</nz-table>

<app-program-review
  *ngIf="showReviewModal"
  [showReviewModal]="showReviewModal"
  (hideReviewModal)="onReviewClose($event)"
  [programStatus]="programStatus"
  [programId]="standardProgramId"
  [programType]="'Standard'"
>

</app-program-review>
