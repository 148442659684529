<div class="affex-wrapper">
  <div class="upload-content-wrapper space-item-wrapper">
    <div class="header">Input Target List</div>
    <div class="instructions-wrapper" *ngIf="!showOutput">
      <div class="title">
        <i nz-icon nzType="info-circle" nzTheme="fill" class="check-icon"></i> &nbsp;&nbsp;Please
        follow these instructions while adding a Target List:
      </div>
      <div class="instructions">
        <i nz-icon nzType="check" nzTheme="outline" class="check-icon"></i> Target List contains
        either NPI and Target_Specialty or NPI only <br />
        <i nz-icon nzType="check" nzTheme="outline" class="check-icon"></i> Formatted in an Excel
        (.xlsx), CSV (.csv), or Text (.txt) file type
        <br />
        <i nz-icon nzType="check" nzTheme="outline" class="check-icon"></i> In case the file is a CSV
        (.csv) or TXT (.txt), it is pipe delimited
        <br />
        <i nz-icon nzType="check" nzTheme="outline" class="check-icon"></i> Number of NPI IDs in the
        file is limited to 400,000 or less
        <br />
      </div>
    </div>
    <div class="upload-wrapper">
      <nz-upload
        nzType="drag"
        [nzMultiple]="false"
        (nzChange)="fileChanged($event)"
        [nzFileListRender]="fileListRef"
        [(nzFileList)]="nzFileList"
        [nzDisabled]="showOutput"
        *ngIf="!affexFileName; else showSingleFile"
      >
        <p class="ant-upload-text upload-text">
          Drag a file here to upload or
          <button nz-button nzType="primary" class="app-primary-button upload-button">
            <span class="choose-file">Choose File</span>
          </button>
        </p>
        <ng-template #fileListRef> </ng-template>
      </nz-upload>

      <ng-template #showSingleFile>
        <div nz-row class="title-row file-list" *ngIf="!showUploadError; else inValidFile">
          <div
            nz-col
            nzSpan="12"
            class="title"
            [ngClass]="showOutput ? 'disable' : ''"
            [title]="affexFileName"
          >
            <i nz-icon nzType="check-circle" nzTheme="fill" class="check-icon"></i>
            {{ affexFileName | nzEllipsis: 24:'...' }}
          </div>
          <div nz-col nzSpan="12" class="link">
            <i
              nz-icon
              class="delete-icon"
              nzType="delete"
              nzTheme="outline"
              (click)="deleteFile()"
              *ngIf="!showOutput"
            ></i>
          </div>
        </div>
        <ng-template #inValidFile>
          <div nz-row class="title-row file-error">
            <div nz-col nzSpan="12" class="title" [title]="affexFileName">
              <i nz-icon nzType="close-circle" nzTheme="fill" class="close-icon"></i> &nbsp;
              {{ affexFileName | nzEllipsis: 24:'...' }}
            </div>
            <div nz-col nzSpan="12" class="link">
              <i
                nz-icon
                nzType="delete"
                nzTheme="outline"
                (click)="deleteFile()"
                class="delete-icon"
              ></i>
            </div>
          </div>
          <div class="error-message">
            <i nz-icon nzType="close-circle" nzTheme="fill" class="close-icon-default"></i>
            &nbsp;The file is in an unsupported format. Please refer to the instructions above for supported file formats
          </div>
        </ng-template>
      </ng-template>
    </div>
    <div class="dropdown-wrapper">
      <nz-space>
        <!--        <div nz-row [nzGutter]="[14, 0]">-->
        <!--          <div nz-col nzFlex="300px">-->
        <app-dropdown
          [data]="cycle"
          [label]="'Cycle'"
          [size]="'large'"
          (selectedValue)="updatePayload($event, 'cycle')"
          [placeHolder]="'Cycle'"
          [isDisabled]="showOutput"
          class="cycle-product"
          [value]="[payload.cycle]"
          *nzSpaceItem
        ></app-dropdown>
        <!--          </div>-->
        <!--          <div nz-col nzFlex="300px">-->
        <app-dropdown
          [data]="product"
          [label]="'Product'"
          [size]="'large'"
          (selectedValue)="updatePayload($event, 'product')"
          [placeHolder]="'Product'"
          [isDisabled]="showOutput"
          [value]="[payload.product]"
          class="cycle-product"
          *nzSpaceItem
        ></app-dropdown>
        <!--          </div>-->
        <!--        </div>-->
      </nz-space>
    </div>
    <div class="submit-wrapper">
      <button
        nz-button
        nzType="primary"
        class="app-primary-button"
        [disabled]="isSubmitDisabled()"
        [ngClass]="{ 'app-primary-button-disabled': isSubmitDisabled() }"
        (click)="generateReport()"
      >
        <span class="submit">Submit</span>
      </button>
    </div>
  </div>
  <div class="seprator">
    <i nz-icon nzType="caret-right" nzTheme="outline"></i>
  </div>
  <div
    class="space-item-wrapper output-wrapper"
    [ngClass]="!showOutput ? 'disable-output' : 'enabled-output'"
  >
    <div *ngIf="!showOutput">
      <div class="header">Output HCP Report</div>
      <div class="output-container">
        <div class="output-1">Sorry, a Target List has not been uploaded yet. <br /><br /></div>
        <div class="output-2"><i>To create a new file from your Target List:</i> <br /></div>
        <div>
          <ul>
            <li>Complete the fields on the left in the “Input Target List“ section</li>
            <li>Upload a Target List</li>
            <li>Click the “Submit” button</li>
          </ul>
        </div>
      </div>
    </div>
    <div *ngIf="showOutput">
      <div class="header">Output HCP Report</div>
      <div class="output-container-download-1" *ngIf="!inProgress && !errorMessage">
        <p>
          <span class="check-icon"
            ><i nz-icon nzType="check-circle" nzTheme="fill" class="check-icon"></i
          ></span>
          HCP Report Created.
        </p>
      </div>
      <div class="output-container-download-2" *ngIf="inProgress">
        <nz-spin nzSimple [nzSize]="'large'"></nz-spin>
        <div class="spinner-message">Your file is being updated...</div>
      </div>
      <div class="output-container-download" *ngIf="!inProgress && !errorMessage">
        <div class="button-container">
          <div class="download-button" *ngIf="isZSUser">
            <button
              nz-button
              nzType="primary"
              [ngClass]="downloadUrl ? 'app-primary-button': 'app-primary-button-disabled'"
              [disabled]="!downloadUrl"
              (click)="downloadReport()"
            >
              <i nz-icon nzType="download" nzTheme="outline"></i>
              <span class="download-button-text">Download Report</span>
            </button>
          </div>
          <div class="restart-button" (click)="restartClicked()">
            <i nz-icon nzType="reload" nzTheme="outline"></i> Reset
          </div>
        </div>
        <div class="summary-header">Summary Stats</div>
        <div class="summary-data">
          <div class="summary-data-1">
            <span *ngIf="payload.product === 'AccessMonitor'">HCPs rated by AccessMonitor</span>
            <span *ngIf="payload.product === 'AffinityMonitor'">HCPs rated by AffinityMonitor</span>
            <span
              ><b>- {{ summaryStats.hcp_count }}</b></span
            >
          </div>
          <nz-divider class="divider" nzType="vertical"></nz-divider>
          <div class="summary-data-2">
            Total NPIs submitted
            <span
              ><b>- {{ summaryStats.total_npi }}</b></span
            >
          </div>
          <nz-divider class="divider" nzType="vertical"></nz-divider>
          <div class="summary-data-3">
            % HCPs rated
            <span
              ><b>- {{ summaryStats.activity_per }}</b></span
            >
          </div>
        </div>
        <div class="specialties-filter">
          <app-dropdown
            [isMultiple]="true"
            [isSearchable]="true"
            [label]="'Specialties'"
            [placeHolder]="'All'"
            nz-popover=""
            [nzPopoverContent]="selectedSpecialty.length > 0 ? selectedSpecialty : ['All']"
            [data]="specialties"
            (selectedValue)="specialtySelected($event)"
          ></app-dropdown>
        </div>
        <div class="table-data">
          <nz-table
            #tableData
            [nzShowPagination]="false"
            nzTableLayout="fixed"
            class="table"
            [nzData]="tableBody"
          >
            <thead class="table-headers">
              <tr>
                <ng-container *ngFor="let column of tableHeaders">
                  <th
                    [ngClass]="column === 'channel' ? 'table-header-channel' : 'table-header'"
                    nzWidth="25%"
                  >
                    {{ displayHeader(column) }}
                  </th>
                </ng-container>
              </tr>
            </thead>
            <tbody class="table-body">
              <tr *ngFor="let data of tableData.data">
                <ng-container *ngFor="let column of tableHeaders">
                  <td [ngClass]="column === 'channel' ? 'table-body-channel' : 'table-body'">
                    {{ getTableData(data, column) }}
                  </td>
                </ng-container>
              </tr>
            </tbody>
          </nz-table>
        </div>
        <div>
          <b>Note:</b> Data generated using AFFEX is only to be used for internal purposes and
          cannot be shared with clients directly.
        </div>
      </div>
      <div class="output-container-download-3" *ngIf="!inProgress && errorMessage">
        <div class="report-created-text">
          <div class="error-message-file">
            <i nz-icon nzType="close-circle" nzTheme="fill" class="close-icon-default"></i> &nbsp;{{
              errorMessage
            }}
          </div>
        </div>
        <div
          class="restart-error"
          (click)="restartClicked()"
        >
          <i nz-icon nzType="reload" nzTheme="outline"></i> Reset
        </div>
      </div>
    </div>
  </div>
</div>
