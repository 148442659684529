import {Injectable} from '@angular/core';
import {
  HttpHandler,
  HttpHeaderResponse,
  HttpInterceptor,
  HttpProgressEvent,
  HttpRequest,
  HttpResponse,
  HttpSentEvent,
  HttpUserEvent,
} from '@angular/common/http';
import {environment} from 'src/environments/environment';
import {EMPTY, Observable, throwError} from 'rxjs';
import {catchError, map} from 'rxjs/operators';
import {AppState} from './app-state';
import {Router} from '@angular/router';
import {Globals} from '../constants/globals';

@Injectable({
  providedIn: 'root',
})
export class HttpInterceptorService implements HttpInterceptor {
  constructor(private appState: AppState, private router: Router, public globals: Globals) {
  }

  /**
   * Method for customize request.
   * @param request http request
   */
  addCommonHttpOptions(request): HttpRequest<any> {
    try {
      const req = request.clone({});
      req.withCredentials = environment.withCredentials;
      return req;
    } catch (error) {
      console.log(error);
    }
  }

  intercept(
    req: HttpRequest<any>,
    next: HttpHandler,
  ): Observable<
    HttpSentEvent | HttpHeaderResponse | HttpProgressEvent | HttpResponse<any> | HttpUserEvent<any>
  > {
    const customRequest = this.addCommonHttpOptions(req);
    return next.handle(customRequest).pipe(
      map((res) => res),
      catchError((err) => {
        const pathArray = window.location.href.split('/');
        const client = environment.client.find(c => pathArray.includes(c.name));
        console.log('client: ', client);
        console.log('pathArray: ', pathArray);

        const navUrl = pathArray[pathArray.length - 2] === 'testNavigation' ? 'https://idm.stage.zsservices.com/AffinityMonitor/IdentityManager/app/Web/login.aspx' : '';
        if (navUrl) {
          window.location.href = navUrl;
        } else if (err.status === 401) {
          this.globals.userDetail = undefined;
          this.appState.clearGlobalState();
          this.appState.passValue({clientLoading: false});
          this.router.navigate(['/']);
          // if (client) {
          //   window.location.href = client.authLoginUrl;
          // } else {
          //   window.location.href = environment.authLoginUrl;
          // }
          return EMPTY;
        } else {
          return throwError(err);
        }
      }),
    );
  }
}
