import { Component, EventEmitter, OnDestroy, OnInit, Output } from '@angular/core';
import { HttpService } from '../../../shared/services/http.service';
import { DatePipe } from '@angular/common';
import { SearchBarComponent } from '../../../shared/components/search-bar/search-bar.component';
import { ReplaceValuePipe } from '../../../shared/pipe/replace-value.pipe';
import { Subscription } from 'rxjs';
import { AppState } from '../../../shared/services/app-state';

@Component({
  selector: 'app-data-changes',
  templateUrl: './data-changes.component.html',
  styleUrls: ['./data-changes.component.scss'],
})
export class DataChangesComponent implements OnInit, OnDestroy {

  @Output() reviewed: EventEmitter<boolean> = new EventEmitter<boolean>();
  @Output() exportUrl: EventEmitter<string> = new EventEmitter<string>();

  public replaceValue = new ReplaceValuePipe();
  public loading = false;
  public showReviewModal = false;
  public searchValue = '';
  public searchInput = '';
  public visible = false;
  public noResult = false;
  public searchParamData = [];
  public filteredData = [];
  public businessData = [];
  public businessDataCpy = [];
  public pageIndex = 1;
  public sortColumns = {
    uploaded: {
      sortOrder: 'descend',
      sortDirection: ['ascend', 'descend'],
    },
    modified: {
      sortOrder: 'descend',
      sortDirection: ['ascend', 'descend'],
    },
  };
  public sortDataClicked = false;
  public showFilterClient = false;
  public showFilterStatus = false;
  public reviewId = 1;

  public client = [];

  public status = [];
  public dataChangeStatus;
  private appStateSubscription: Subscription;

  constructor(
    private httpService: HttpService,
    private datePipe: DatePipe,
    private appState: AppState,
  ) {
    this.appStateSubscription = AppState.appState.subscribe((val) => {
      if (val && !val.showDropdown) {
        this.showFilterClient = false;
        this.visible = false;
        this.showFilterStatus = false;
      }
    });
  }

  ngOnInit(): void {
    this.loadData();
  }

  public loadData(): void {
    this.loading = true;
    this.httpService.getRequest('get_data_changes').subscribe((data) => {
      this.businessData = data.data;
      this.businessDataCpy = data.data;
      this.filteredData = data.data;
      this.client = data.client;
      this.status = data.status;
      this.businessData.forEach((file) => {
        this.searchParamData.push(file.file_title);
      });
      this.reviewed.emit(true);
      this.exportUrl.emit(data.export_url);
      this.loading = false;
    });
  }
  public getDate(date): string {
    return this.datePipe.transform(new Date(date), 'MM/d/yy h:mm a');
  }

  public generateMailData(mailRecipient, user, file): void {
    const mailSubject = 'Your data change request with ZS AffinityPortal is being looked into';
    const mailCC = 'AffinityTeam@zs.com';
    const mailBody = `Hi ${user},%0D%0AThe ZS Affinity Team is working on your request - ${file} modifications.%0D%0APlease expect to hear from us soon.%0D%0A %0D%0AThank you,%0D%0AZS AffinityPortal Team`;

    window.location.href = `mailto:${mailRecipient}?subject=${mailSubject}&body=${mailBody}&cc=${mailCC}`;
  }

  public sortData(column: string): void {
    this.sortDataClicked = true;
    if (this.sortColumns.uploaded.sortOrder === 'descend' && column === 'uploaded_date') {
      this.businessData.sort((a, b) =>
        this.getTime(a[column]) === this.getTime(b[column])
          ? 1
          : this.getTime(a[column]) < this.getTime(b[column])
          ? 1
          : -1,
      );
      this.sortColumns.uploaded.sortOrder = 'ascend';
    } else if (this.sortColumns.modified.sortOrder === 'descend' && column === 'modified_date') {
      this.businessData.sort((a, b) =>
        this.getTime(a[column]) === this.getTime(b[column])
          ? 1
          : this.getTime(a[column]) < this.getTime(b[column])
          ? 1
          : -1,
      );
      this.sortColumns.modified.sortOrder = 'ascend';
    } else if (this.sortColumns.uploaded.sortOrder === 'ascend' && column === 'uploaded_date') {
      this.businessData.sort((a, b) =>
        this.getTime(a[column]) === this.getTime(b[column])
          ? 1
          : this.getTime(a[column]) > this.getTime(b[column])
          ? 1
          : -1,
      );
      this.sortColumns.uploaded.sortOrder = 'descend';
    } else if (this.sortColumns.modified.sortOrder === 'ascend' && column === 'modified_date') {
      this.businessData.sort((a, b) =>
        this.getTime(a[column]) === this.getTime(b[column])
          ? 1
          : this.getTime(a[column]) > this.getTime(b[column])
          ? 1
          : -1,
      );
      this.sortColumns.modified.sortOrder = 'descend';
    }
  }
  public getTime(date): number {
    return new Date(date).getTime();
  }
  public highlightFilterClient(): boolean {
    return this.client.some((client) => client.value === true);
  }
  public highlightFilterStatus(): boolean {
    return this.status.some((status) => status.value === true);
  }
  public resetClient(): void {
    this.searchInput = '';
    this.client.forEach((client) => {
      client.value = false;
    });
    this.showFilterClient = false;
    this.filterFxn();
  }
  public filterClient(): void {
    if (!this.highlightFilterClient()) {
      this.resetClient();
    } else {
      this.filterFxn();
    }
    this.showFilterClient = false;
  }

  public resetStatus(): void {
    this.searchInput = '';
    this.status.forEach((status) => {
      status.value = false;
    });
    this.showFilterStatus = false;
    this.filterFxn();
  }
  public filterStatus(): void {
    if (!this.highlightFilterStatus()) {
      this.resetStatus();
    } else {
      this.filterFxn();
    }
    this.showFilterStatus = false;
  }

  public filterFxn(): void {
    this.pageIndex = 1;
    if (this.highlightFilterStatus() && !this.highlightFilterClient()) {
      this.businessData = [];
      this.filteredData.map((data) => {
        if (data.status.length > 0) {
          this.status.map((status) => {
            if (status.value) {
              if (data.status === status.text) {
                this.businessData.push(data);
              }
            }
          });
        }
      });
      this.businessData = [...new Set(this.businessData)];
      this.businessDataCpy = this.businessData;
    } else if (!this.highlightFilterStatus() && this.highlightFilterClient()) {
      this.businessData = [];
      this.filteredData.map((data) => {
        if (data.client.length > 0) {
          this.client.map((client) => {
            if (client.value) {
              if (data.client === client.text) {
                this.businessData.push(data);
              }
            }
          });
        }
      });
      this.businessData = [...new Set(this.businessData)];
      this.businessDataCpy = this.businessData;
    } else if (this.highlightFilterStatus() && this.highlightFilterClient()) {
      this.businessData = [];
      let intData = [];
      this.filteredData.map((data) => {
        if (data.status.length > 0) {
          this.status.map((status) => {
            if (status.value) {
              if (data.status === status.text) {
                intData.push(data);
              }
            }
          });
        }
      });
      intData = [...new Set(intData)];
      intData.map((data) => {
        if (data.client.length > 0) {
          this.client.map((client) => {
            if (client.value) {
              if (data.client === client.text) {
                this.businessData.push(data);
              }
            }
          });
        }
      });
      this.businessData = [...new Set(this.businessData)];
      this.businessDataCpy = this.businessData;
    } else {
      this.businessData = [...this.filteredData];
      this.businessDataCpy = this.businessData;
    }
  }
  public filterOnSearch(event): void {
    this.noResult = false;
    this.pageIndex = 1;
    let newData = [];
    this.businessDataCpy.map((record) => {
      if (record.file_title.toLocaleLowerCase().includes(event.toLocaleLowerCase())) {
        newData.push(record);
      }
    });
    this.businessData = newData;
    event = this.replaceValue.transform(event, true);
    if (this.businessData.length < 1) {
      this.noResult = true;
    }
  }
  public resetSearch(event): void {
    if (event) {
      this.noResult = false;
      this.businessData = this.filteredData;
      this.businessDataCpy = this.filteredData;
      this.filterFxn();
    }
  }
  public formatOnNoresult(event): void {
    if (event) {
      this.noResult = true;
      console.log(this.noResult);
    }
  }
  public onReviewClose(event): void {
    this.showReviewModal = event;
  }

  public reviewClicked(data): void {
    this.showReviewModal = true;
    this.reviewId = data.id;
    this.dataChangeStatus = data.status;
  }

  ngOnDestroy(): void {
    this.appStateSubscription.unsubscribe();
  }
}
