<app-activity-log *ngIf="drawerContent[0].selected"></app-activity-log>
<app-approvals-list *ngIf="drawerContent[1].selected"></app-approvals-list>
<app-steward-feedback *ngIf="drawerContent[2].selected"></app-steward-feedback>
<app-file-tracking *ngIf="drawerContent[3].selected"></app-file-tracking>
<app-program-management *ngIf="drawerContent[4].selected"></app-program-management>
<app-client-management *ngIf="drawerContent[5].selected"></app-client-management>
<app-customer-master *ngIf="drawerContent[6].selected"></app-customer-master>
<app-activity-files *ngIf="drawerContent[7].selected"></app-activity-files>
<app-drawer
  [(showDrawer)]="showDrawer"
  [content]="drawerContent"
  [title]="'ADMIN'"
  (tabChanged)="switchTab($event)"
></app-drawer>
