import { Component, OnInit, Input, Output, EventEmitter } from '@angular/core';
import { DatePipe } from '@angular/common';
import { AppState } from '../../../shared/services/app-state';
import { Subscription } from 'rxjs';
import { HttpService } from '../../../shared/services/http.service';

@Component({
  selector: 'app-steward-afm',
  templateUrl: './steward-afm.component.html',
  styleUrls: ['./steward-afm.component.scss'],
})
export class StewardAfmComponent implements OnInit {
  private appStateSubscription: Subscription;
  constructor(
    private datePipe: DatePipe,
    private appState: AppState
  ) {
    this.appStateSubscription = AppState.appState.subscribe((val) => {
      if (val && !val.showDropdown) {
        this.showFilterClient = false;
        this.showFilterStatus = false;
        this.showFilterProgram = false;
        this.showFilterChannel = false;
      }
    });
  }

  public Headers = [
    'User',
    'Client',
    'Process Id',
    'File Name',
    'Channel',
    'Program',
    'Upload Date',
    'Status',
    'Actions',
  ];

  @Input() sortOrder = 'descend';
  @Input() sortDirection = ['ascend', 'descend'];

  @Input() stewardData = [];
  @Input() status = [];
  @Input() client = [];
  @Input() channel = [];
  @Input() program = [];
  @Input() loading = false;
  @Input() search = '';
  @Input() noDataMessage = 'There are no feedbacks to display.';

  @Output() exportPayload: EventEmitter<object> = new EventEmitter<object>();

  public showReviewModal = false;
  @Input() pageIndex = 1;

  public payload = {
    key: 'AFM',
    filters: {
      status: [],
      client: [],
      channel: [],
      program: [],
      date: 'descend',
    },
    search: '',
  };

  public showFilterClient = false;
  public showFilterChannel = false;
  public showFilterStatus = false;
  public showFilterProgram = false;

  public reviewId = 0;
  public reviewStatus = 'Pending';

  ngOnInit(): void {}

  public getDate(date): string {
    try {
      return this.datePipe.transform(new Date(date), 'MM/d/yy h:mm a');
    } catch (e) {
      return 'invalid date';
    }
  }

  public openChange(event): void {
    this.appState.setGlobalState('showDropdown', event);
  }
  ngOnDestroy(): void {
    this.appStateSubscription.unsubscribe();
  }
  public highlightFilterClient(): boolean {
    return this.client.some((client) => client.value === true);
  }
  public highlightFilterStatus(): boolean {
    return this.status.some((status) => status.value === true);
  }
  public highlightFilterChannel(): boolean {
    return this.channel.some((channel) => channel.value === true);
  }
  public highlightFilterProgram(): boolean {
    return this.program.some((program) => program.value === true);
  }

  public resetChannel(): void {
    this.channel.forEach((channel) => {
      channel.value = false;
    });
    this.filter(false);
  }
  public resetClient(): void {
    this.client.forEach((client) => {
      client.value = false;
    });
    this.filter(false);
  }
  public resetProgram(): void {
    this.program.forEach((program) => {
      program.value = false;
    });
    this.filter(false);
  }
  public resetStatus(): void {
    this.status.forEach((status) => {
      status.value = false;
    });
    this.filter(false);
  }

  public filter(dateModified): void {
    this.pageIndex = 1;
    this.showFilterClient = false;
    this.showFilterStatus = false;
    this.showFilterProgram = false;
    this.showFilterChannel = false;
    const clienttemp = [];
    const statustemp = [];
    const channeltemp = [];
    const programtemp = [];
    this.client.forEach((c) => {
      if (c.value) {
        clienttemp.push(c.text);
      }
    });
    this.status.forEach((s) => {
      if (s.value) {
        statustemp.push(s.text);
      }
    });
    this.channel.forEach((ch) => {
      if (ch.value) {
        channeltemp.push(ch.text);
      }
    });
    this.program.forEach((p) => {
      if (p.value) {
        programtemp.push(p.text);
      }
    });
    if (dateModified) {
      if (this.sortOrder === 'ascend') {
        this.sortOrder = 'descend';
      } else {
        this.sortOrder = 'ascend';
      }
    }
    this.payload = {
      key: 'AFM',
      filters: {
        status: statustemp,
        client: clienttemp,
        channel: channeltemp,
        program: programtemp,
        date: this.sortOrder,
      },
      search: this.search,
    };
    this.exportPayload.emit(this.payload);
  }
  public onReviewClose(event): void {
    this.showReviewModal = event;
    this.exportPayload.emit(this.payload);
  }
  public onReviewOpen(id, status): void {
    this.showReviewModal = true;
    this.reviewId = id;
    this.reviewStatus = status;
  }
}
