import {Component, EventEmitter, Input, OnInit, Output} from '@angular/core';
import { AppState } from '../../services/app-state';

@Component({
  selector: 'app-drawer',
  templateUrl: './drawer.component.html',
  styleUrls: ['./drawer.component.scss']
})
export class DrawerComponent implements OnInit {

  @Input() showDrawer = false;
  @Input() placement = 'left';
  @Input() title = '';
  @Input() content = [];
  @Output() tabChanged: EventEmitter<any> = new EventEmitter<any>();
  @Output() showDrawerChange: EventEmitter<any> = new EventEmitter<any>();
  @Input() offsetX = 80;
  @Input() maskClosable = true;
  @Input() closableIcon = false;
  @Input() subTitle = '';
  @Input() showLoader = false;
  @Input() showFooter = false;

  constructor(private appState: AppState) { }

  ngOnInit(): void {
  }

  public onClose(item?): void {
    if (item && item.toLowerCase() === 'contact support') {
      window.location.href = `mailto:support@zs.com`;
    } else {
      this.showDrawer = false;
      if (item){
        this.tabChanged.emit(item);
      }
      this.showDrawerChange.emit(this.showDrawer);
      this.appState.setGlobalState('showDrawer', false);
    }
  }
}
