<nz-layout>
  <nz-content>
    <div class="space-align-container">
      <div class="tab-header-wrapper">
        <div nz-typography class="tab-header">Program Management</div>
        <div class="add-button" *ngIf="!programApprovalFlag">
          <button nz-button nzType="primary" class="app-primary-button" (click)="addProgramClicked()">+ Add Program</button>
        </div>
        <div class="download-button">
          <button nz-button nzType="secondary" class="app-secondary-button" (click)="downloadFile(exportUrl, '')" [disabled]="!exportUrl">
            Export
          </button>
        </div>
        <div class="search-bar">
          <app-search-bar
            [searchParameter]="searchParams"
            (searchValue)="filterOnSearch($event)"
            (inputKey)="filterOnSearch($event)"
            (noInput)="resetSearch($event)"
            (resetSearch)="resetSearch($event)"
            (noResultFound)="formatOnNoResult($event)"
            [(inputValue)]="searchInput"
          >
          </app-search-bar>
        </div>
      </div>
      <div class="tabs">
        <nz-tabset>
          <nz-tab [nzTitle]="programApproval" (nzClick)="tabChanged('Program Approval')">
            <ng-template nz-tab><app-program-approval (searchParamData)="searchAssign($event)" (exportUrl)="urlAssign($event)"
            (reviewed)="getPendingCount($event)">
            </app-program-approval></ng-template>
          </nz-tab>
          <nz-tab [nzTitle]="standardTab" (nzClick)="tabChanged('Standard Programs')">
            <ng-template nz-tab><app-standard-programs (searchParamData)="searchAssign($event)" (exportUrl)="urlAssign($event)"></app-standard-programs></ng-template>
          </nz-tab>
          <nz-tab [nzTitle]="customTab" (nzClick)="tabChanged('Custom Programs')">
            <ng-template nz-tab><app-custom-program (searchParamData)="searchAssign($event)" (exportUrl)="urlAssign($event)"></app-custom-program></ng-template>
          </nz-tab>
        </nz-tabset>
        <ng-template #programApproval>
          <span [innerHTML]="pendingCustomProgram"></span>
        </ng-template>
        <ng-template #customTab>
          <span [innerHTML]="pendingCustomTab"></span>
        </ng-template>
        <ng-template #standardTab>
          <span [innerHTML]="pendingStandardTab"></span>
        </ng-template>
      </div>
    </div>
  </nz-content>
</nz-layout>

<app-add-program-modal
  *ngIf="addProgram"
  [showModal]="addProgram"
  (hideModal)="reloadPage()"
>
</app-add-program-modal>
