import { Component, EventEmitter, Input, OnInit, Output } from '@angular/core';
import { HttpService } from '../../../shared/services/http.service';
import { ReplaceValuePipe } from '../../../shared/pipe/replace-value.pipe';
import { NzUploadChangeParam, NzUploadFile } from 'ng-zorro-antd/upload';
import { NzModalRef, NzModalService } from 'ng-zorro-antd/modal';
import { Globals } from '../../../shared/constants/globals';
import { Subscription } from 'rxjs';
import { AppState } from '../../../shared/services/app-state';

@Component({
  selector: 'app-program-review',
  templateUrl: './program-review.component.html',
  styleUrls: ['./program-review.component.scss'],
})
export class ProgramReviewComponent implements OnInit {
  @Input() showReviewModal;
  @Output() hideReviewModal: EventEmitter<boolean> = new EventEmitter<boolean>();
  @Input() programId;
  @Input() programStatus;
  @Input() programType;
  public replaceValue = new ReplaceValuePipe();
  public searchValue = /[?\/\\:[\]*]/gm;
  public confirmModal?: NzModalRef;

  public loading = false;
  public errorMessage = '';
  public buttonText = '';
  public reasonButton = '';

  public client = '';
  public selectedProducts = '';
  public selectedProductsOrig = '';
  public productChanged = false;
  public state = '';
  public placeHolder = '';
  public prevState = '';

  // File-upload
  public formData = new FormData();
  public isInprogress = false;
  public uploadPercent = 0;
  public customError = '';
  public showUploadError = false;
  public uploadFileClicked = false;
  public nzFileList: NzUploadFile[] = [];
  public fileList: File[] = [];

  // program
  public program = '';
  public originalProgramEditted = false;
  public programCpy = '';
  public programEditted = false;
  public editProgramClicked = false;
  public isValidProgram = true;
  public validProgramChar = true;

  // channel
  public channel = '';
  public editChannelClicked = false;
  public channelEditted = false;
  public originalChannelEditted = false;
  public channelCpy = '';

  // LayoutFile
  public layoutFileName = '';
  public layoutFileUrl = '';
  public layoutFileEditted = false;
  public originalLayoutFile = '';
  public originalFileLayoutUrl = '';

  // reason
  public reasonHeader = '';
  public isValidReason = true;
  public reason = '';
  public showReasonScreen = false;

  // submit modal
  public submitModalContent = '';
  public submitModalOkText = '';
  public submitModalTitle = '';

  public productOptions = [
    { label: 'AffinityMonitor', value: 'AffinityMonitor', checked: false },
    { label: 'AccessMonitor', value: 'AccessMonitor', checked: false },
  ];

  private appStateSubscription: Subscription;
  public userDetails;
  public role;

  public channelData = [
    'Personal Face-to-Face',
    'Personal Remote',
    'Peer Interaction',
    'Digital Push',
    'Digital Pull',
    'Direct',
    'Customer Master',
    'Offer',
  ];

  constructor(
    private httpService: HttpService,
    private modal: NzModalService,
    private globals: Globals,
  ) {
    this.appStateSubscription = AppState.appState.subscribe((val) => {
      if (!this.userDetails) {
        if (val && val.userDetails) {
          this.userDetails = val.userDetails;
          this.role = this.userDetails.role;
        }
      }
    });
  }

  ngOnInit(): void {
    this.loadData();
  }

  public loadData(): void {
    this.loading = true;
    const payload = {
      program_id: this.programId,
      program_type: this.programType,
    };
    this.assignButtonText();
    this.httpService.postRequest('get_program_review_details', payload).subscribe((result) => {
    // this.httpService.getRequest('get_program_review_details').subscribe((result) => {
      if (result.status === 'SUCCESS') {
        this.program = result.data.program;
        this.programCpy = result.data.program;

        this.channel = result.data.channel;
        this.channelCpy = result.data.channel;

        this.selectProduct(result.data.product);

        this.client = this.programType === 'Custom' ? result.data.client : '';

        this.layoutFileName = result.data.layoutFileName;
        this.layoutFileUrl = result.data.layoutFileUrl;

        this.originalProgramEditted = result.data.programEditted;
        this.originalChannelEditted = result.data.channelEditted;
        this.layoutFileEditted = result.data.layoutFileEditted;

        this.prevState = result.data.previous_state;

        this.loading = false;
      } else if (result.status === 'ERROR') {
        console.log('error', result.message);
      }
    });
  }

  public showErrorMessage(): boolean {
    if (this.errorMessage) {
      if (this.errorMessage !== 'This program already exists under this channel.') {
        return (
          !this.channelEditted &&
          !this.programEditted &&
          !this.uploadFileClicked &&
          !this.showUploadError
        );
      } else {
        return !this.channelEditted && !this.programEditted;
      }
    } else {
      return false;
    }
  }

  // program
  public checkProgram(): boolean {
    this.program = this.replaceValue.transform(this.program, true);
    this.isValidProgram = this.program ? this.program.length <= 50 : true;
    this.program
      ? this.program.match(this.searchValue)
        ? (this.validProgramChar = false)
        : (this.validProgramChar = true)
      : (this.validProgramChar = true);
    if (this.isValidProgram && this.validProgramChar && this.program) {
      return true;
    } else {
      return false;
    }
  }
  public saveProgram(): void {
    if (this.checkProgram()) {
      this.editProgramClicked = false;
    }
    if (this.programCpy.toLowerCase() !== this.program.toLowerCase()) {
      this.programEditted = true;
    } else {
      this.programEditted = false;
    }
  }

  // channel
  public channelSelected(event): void {
    this.channel = event;
  }
  public saveChannel(): void {
    if (this.channel) {
      this.editChannelClicked = false;
    }
    if (this.channel.toLowerCase() !== this.channelCpy.toLowerCase()) {
      this.channelEditted = true;
    } else {
      this.channelEditted = false;
    }
  }

  // products
  public productSelected(products): void {
    let checkedFlag = 0;
    this.selectedProducts = '';
    products.forEach((product) => {
      if (product.checked) {
        this.selectedProducts = product.value === 'AffinityMonitor' ? 'AFM' : 'ACM';
        checkedFlag += 1;
      }
    });
    if (checkedFlag === 2) {
      this.selectedProducts = 'AFM,ACM';
    }
    this.productChangedCheck();
  }
  public selectProduct(product): void {
    this.selectedProducts = product;
    this.selectedProductsOrig = product;
    if (this.selectedProductsOrig === 'AFM') {
      this.productOptions = [
        { label: 'AffinityMonitor', value: 'AffinityMonitor', checked: true },
        { label: 'AccessMonitor', value: 'AccessMonitor', checked: false },
      ];
    } else if (this.selectedProductsOrig === 'ACM') {
      this.productOptions = [
        { label: 'AffinityMonitor', value: 'AffinityMonitor', checked: false },
        { label: 'AccessMonitor', value: 'AccessMonitor', checked: true },
      ];
    } else if (this.selectedProductsOrig === 'AFM,ACM'){
      this.productOptions = [
        { label: 'AffinityMonitor', value: 'AffinityMonitor', checked: true },
        { label: 'AccessMonitor', value: 'AccessMonitor', checked: true },
      ];
    } else {
      this.productOptions = [
        { label: 'AffinityMonitor', value: 'AffinityMonitor', checked: false },
        { label: 'AccessMonitor', value: 'AccessMonitor', checked: false },
      ];
    }
  }

  public productChangedCheck(): void {
    if (this.selectedProductsOrig === this.selectedProducts) {
        this.productChanged = false;
    } else {
      this.productChanged = true;
    }
  }

  // layout file
  public downloadFile(url, target): void {
    window.open(url, target);
  }
  public fileChanged(files: NzUploadChangeParam): void {
    this.formData = new FormData();
    this.isInprogress = true;
    this.uploadPercent = 5;
    const fileNames = [];
    this.customError = '';
    this.showUploadError = false;
    if (files.type === 'start') {
      files.fileList.forEach((file) => {
        this.fileList.push(file.originFileObj);
      });
    }

    this.showUploadError = this.checkFileType();

    files = undefined;
    this.nzFileList = [];
    this.isInprogress = false;
    this.uploadFileClicked = true;
  }
  public checkFileType(): boolean {
    let isValid = false;
    let validFileType = true;
    this.showUploadError = false;
    this.fileList.forEach((file) => {
      const type = file.name.substring(file.name.length - 4, file.name.length);
      if (type === '.csv') {
        isValid = true;
        file['isValid'] = isValid;
        this.customError = '';
      } else {
        isValid = false;
        validFileType = false;
        file['isValid'] = isValid;
        this.showUploadError = true;
        this.isInprogress = false;
      }
    });
    return !validFileType;
  }
  public deleteFile(isValid = false): void {
    if (isValid) {
      this.confirmModal = this.modal.confirm({
        nzTitle: 'Delete Uploaded File?',
        nzContent: 'Are you sure you want to delete this file?',
        nzOkText: 'Yes, Delete',
        nzIconType: 'exclamation-circle',
        nzOnOk: () => {
          this.fileList = [];
          this.showUploadError = false;
          this.customError = '';
          this.uploadFileClicked = false;
        },
        nzOnCancel: () => {},
      });
    } else {
      this.fileList = [];
      this.showUploadError = false;
      this.customError = '';
      this.uploadFileClicked = false;
    }
  }

  // buttons
  public assignButtonText(): void {
    if (this.programStatus === 'Active') {
      this.buttonText = 'Retire Program';
    } else if (this.programStatus === 'Retired') {
      this.buttonText = 'Reinstate Program';
    } else if (
      (this.programStatus === 'Active-Pending' || this.programStatus === 'Retired-Pending') &&
      this.role === 'admin'
    ) {
      this.buttonText = 'Cancel Request';
      this.state = 'Cancel Request';
    } else if (
      (this.programStatus === 'Active-Pending' || this.programStatus === 'Retired-Pending') &&
      this.role === 'manager'
    ) {
      this.buttonText = 'Reject Request';
      this.state = 'Reject Request';
    }
    this.submitModalTitle = this.buttonText;
  }
  public disableButton(buttonType): boolean {
    if (buttonType === 'main') {
      return (
        this.loading ||
        this.editProgramClicked ||
        this.editChannelClicked ||
        this.showUploadError ||
        !this.selectedProducts
      );
    } else if (buttonType === 'save') {
      return (
        (!this.programEditted && !this.channelEditted && !this.uploadFileClicked && !this.productChanged) ||
        this.editProgramClicked ||
        this.editChannelClicked ||
        this.showUploadError ||
        this.loading ||
        !this.selectedProducts
      );
    }
  }
  public onCancel(): void {
    this.showReviewModal = false;
    this.confirmModal = this.modal.confirm({
      nzTitle: 'Leave Program Review',
      nzContent: 'Are you sure you want to leave the review process?',
      nzOkText: 'Yes, Leave',
      nzIconType: 'exclamation-circle',
      nzOnOk: () => {
        this.hideReviewModal.emit(false);
        this.showReviewModal = false;
      },
      nzOnCancel: () => {
        this.showReviewModal = true;
      },
    });
  }
  public buttonClicked(button): void {
    if (button === 'Cancel Request') {
      this.showReviewModal = false;
      this.state = 'Cancel Request';
      this.submitModalContent = 'Are you sure you want to cancel the request?';
      this.submitModalOkText = 'Yes';
      this.submitModalTitle = 'Cancel Request';
      this.submitRequest();
      
    } else if (button === 'Reject Request') {
      this.state = 'Reject Request';
      this.submitModalContent = 'Are you sure you want to reject the request?';
      this.submitModalOkText = 'Yes';
      this.submitModalTitle = 'Reject Request';
      this.submitRequest();
    } else if (button === 'Retire Program') {
      this.reasonHeader = 'Reason for retiring program.';
      this.placeHolder = 'Enter reason for retiring program';
      this.submitModalContent = 'Are you sure you want to retire this program?';
      this.submitModalOkText = 'Retire';
      this.submitModalTitle = 'Retire Program';
      this.showReasonScreen = true;
      this.state = this.role === 'admin' ? 'Retired-Pending' : 'Retired';
      if(this.role === 'manager'){
        this.reasonButton = 'Retire Program';
      } else {
        this.reasonButton = 'Send for Approval';
      }
    } else if (button === 'Reinstate Program') {
      this.reasonHeader = 'Reason for reinstating program.';
      this.placeHolder = 'Enter reason for reinstating program';
      this.submitModalContent = 'Are you sure you want to reinstate this program?';
      this.submitModalOkText = 'Reinstate';
      this.submitModalTitle = 'Reinstate Program';
      this.state = this.role === 'admin' ? 'Active-Pending' : 'Active';
      this.showReasonScreen = true;
      if(this.role === 'manager'){
        this.reasonButton = 'Reinstate Program';
      } else {
        this.reasonButton = 'Send for Approval';
      }
    } 
  }
  public backClicked(): void {
    this.reason = '';
    this.showReasonScreen = false;
  }
  public submitRequest(): void {
    const modified = !this.disableButton('save');
    this.formData = new FormData();
    this.formData.append('id', this.programId);
    this.formData.append('reason', this.reason);
    this.formData.append('state', this.state);
    this.formData.append('program_type', this.programType);
    this.formData.append('modified_by', this.role);
    this.formData.append('modified', JSON.stringify(modified));
    this.formData.append('product', this.selectedProducts);
    this.formData.append('program', this.program);
    this.formData.append('channel', this.channel);
    this.formData.append('previous_state', this.prevState);
    if (this.uploadFileClicked){
      this.formData.append('template_file', this.fileList[0]);
    } else {
      this.formData.append('template_file', '');
    }
    this.showReviewModal = false;
    this.confirmModal = this.modal.confirm({
      nzTitle: this.submitModalTitle,
      nzContent: this.submitModalContent,
      nzOkText: this.submitModalOkText,
      nzIconType: 'exclamation-circle',
      nzOnOk: () => {
        this.httpService
          .postRequest('submit_program_review', this.formData, true)
          .subscribe((data) => {
        // this.httpService.getRequest('submit_program_review').subscribe((data) => {
          if (data['status'] === 'SUCCESS') {
            this.hideReviewModal.emit(false);
          } else if (data['status'] === 'ERROR') {
            this.errorMessage = data['message'];
            this.submitError();
          }
        });
      },
      nzOnCancel: () => {
        this.showReviewModal = true;
      },
    });
  }
  public submitError(): void {
    this.showReviewModal = true;
    this.showReasonScreen = false;
    this.programEditted = false;
    this.channelEditted = false;
    this.reason = '';
    this.programCpy = this.program;
    this.channelCpy = this.channel;
    if (this.errorMessage !== 'This program already exists under this channel.') {
      this.uploadFileClicked = false;
      this.showUploadError = false;
    }
  }
  public saveChanges(): void {
    this.state = (this.role === 'admin' ? (this.programStatus === 'Active' ? 'Active-Pending' : this.programStatus === 'Retired' ? 'Retired-Pending' : this.programStatus): this.programStatus);
    this.showReasonScreen = true;
    this.reasonHeader = 'Reason for modifying program.';
    this.placeHolder = 'Enter reason for modifying program';
    this.submitModalContent = 'Are you sure you want to make these changes?';
    this.submitModalOkText = 'Save';
    this.submitModalTitle = 'Modify Program';
    if(this.role === 'manager'){
      this.reasonButton = 'Modify Program';
    } else {
      this.reasonButton = 'Send for Approval';
    }
  }

  public approveRequest(): void {
    this.state = 'Approve Request';
    this.submitModalContent = 'Are you sure you want to approve the request?';
    this.submitModalOkText = 'Yes';
    this.submitModalTitle = 'Approve Request';
    this.submitRequest();
  }

  // reason
  public checkReason(): void {
    this.reason = this.replaceValue.transform(this.reason, true);
    this.isValidReason = this.reason ? this.reason.length <= 2000 : true;
  }
  public disableEnter(event): void {
    if (!this.reason && event.key == 'Enter') {
      event.preventDefault();
    }
  }
}
