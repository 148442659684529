<div class="review-wrapper">
  <nz-modal
    [nzTitle]="heading"
    [(nzVisible)]="showReviewModal"
    [nzWidth]="'800px'"
    [nzWrapClassName]="'modal-wrapper'"
    [nzMaskClosable]="false"
    [nzFooter]="footer"
    (nzOnCancel)="onCancel()"
  >
    <ng-container *nzModalContent>
      <div class="review-content" *ngIf="!loading && !showRejectScreen">
        <div class="data-1" *ngIf="!checkReviewed() && !showRollDownScreen">
          Please review the mappings done by the Data Profiler.
        </div>
        <div class="error-message" *ngIf="!mandatoryColumns && !checkReviewed() && !showRollDownScreen">
          The mandatory columns are not present in the file. Please reject this request.
        </div>
        <div class="error-message" *ngIf="rolledDown">
          This upload record is currently under the rolled down process
        </div>
        <div class="error-message" *ngIf="errorMessage">
          {{ errorMessage }}
        </div>
        <div class="data-1" *ngIf="showRollDownScreen">Please enter the required parameters for file roll down.</div>
        <div *ngIf="status === 'Rejected'" class="data-reject">
          <span>Reason for Rejection:</span> {{ rejectReason }}
        </div>
        <div class="data-2-wrapper">
          <div class="data-2">
            <div class="uneditable-data">
              <div><span>Program Name:</span> {{ programName ? programName : '-' }}</div>
              <div><span>File Name:</span> {{ fileName ? fileName : '-' }}</div>
            </div>
            <div class="uneditable-data-1">
              <div><span>Channel Name:</span> {{ channelName ? channelName : '-' }}</div>
              <div><span>Process Id:</span> {{ processId ? processId : '-' }}</div>
            </div>
          </div>
          <div class="preview-data-wrapper">
            <div class="preview-data">
              Sample Data
              <i
                nz-icon
                nzType="eye"
                nzTheme="outline"
                (click)="previewSampleData()"
                class="eye-button"
              ></i>
            </div>
            <div class="preview-data">
              Profiling Statistics
              <i
                nz-icon
                nzType="eye"
                nzTheme="outline"
                (click)="previewProfilingStats()"
                class="eye-button"
              ></i>
            </div>
            <div class="preview-data">
              Data Layout
              <i
                nz-icon
                nzType="download"
                nzTheme="outline"
                class="download-button"
                (click)="downloadFile(dataLayoutUrl, '')"
              ></i>
            </div>
          </div>
          <div class="classification-table" *ngIf="!showRollDownScreen">
            <nz-table #classificationTable [nzData]="classificationData" [nzLoading]="loading">
              <thead>
                <tr>
                  <th nzWidth="20%">Column Name</th>
                  <th nzWidth="39%">Feedback</th>
                  <th nzWidth="20%">Recommended Standard Name</th>
                  <th nzWidth="21%">Other Recommendation</th>
                </tr>
              </thead>
              <tbody>
                <tr *ngFor="let data of classificationTable.data">
                  <td>
                    <div class="column-name">
                      <div
                        nz-checkbox
                        [(ngModel)]="data.selected"
                        [nzDisabled]="data.disabled || checkReviewed()"
                      ></div>
                      <span>{{ data.column_name }}</span>
                    </div>
                  </td>
                  <td>
                    <div *ngIf="!editCache[data.id].edit">
                      <span>{{
                        checkReviewed()
                          ? data.feedback
                            ? data.feedback
                            : '-'
                          : editCache[data.id].data
                      }}</span>
                      <span *ngIf="!checkReviewed()">
                        <i
                          nz-icon
                          nzType="edit"
                          nzTheme="outline"
                          class="edit-icon"
                          (click)="startEdit(data.id)"
                        ></i
                      ></span>
                    </div>
                    <span *ngIf="editCache[data.id].edit"
                      ><input
                        nz-input
                        [(ngModel)]="editCache[data.id].data"
                        (keyup)="checkFeedback(data.id)"
                        [ngClass]="editCache[data.id].isValid ? 'input' : 'error-input'"
                      /><span
                        (click)="saveEdit(data.id)"
                        *ngIf="editCache[data.id].isValid"
                        class="save"
                        >Save</span
                      ><span *ngIf="!editCache[data.id].isValid" class="save-disabled">Save</span>
                      <div class="error-3" *ngIf="!editCache[data.id].isValid">
                        Feedback length must be 50 characters or less. Please enter a valid input.
                      </div>
                    </span>
                  </td>
                  <td>{{ data.recommended_std_name }}</td>
                  <td>{{ data.other_recommendation }}</td>
                </tr>
              </tbody>
            </nz-table>
          </div>
          <div *ngIf="showRollDownScreen">
            <div class="roll-down">
              <div class="divider"><nz-divider></nz-divider></div>
              <div class="fixed-fields">
                <div>
                  <div class="input-header">Event column name:</div>
                  <span class="input-name-event"><input nz-input [(ngModel)]="eventColumnName" /></span>
                </div>
                <div>
                  <div class="input-header">Event column value:</div>
                  <span class="input-value-event"><input nz-input [(ngModel)]="eventColumnValue" /></span>
                </div>
              </div>
              <div class="fixed-fields-1">
                <div>
                  <div class="input-header">Date column present:</div>
                  <span class="input-present-date"><app-dropdown
                    [isMultiple]="false"
                    [isSearchable]="false"
                    [data]="dateColumnPresent"
                    [size]="'default'"
                    (selectedValue)="dateColumnSelected($event)"
                  ></app-dropdown
                ></span>
                </div>
                <div>
                  <div class="input-header">Date column value:</div>
                  <span class="input-value-event date"><input nz-input [(ngModel)]="dateColumnValue" /></span>
                </div>
              </div>
              <nz-divider class="divider"></nz-divider>
              <ng-container *ngFor="let row of rollDownData">
                <div class="input-box">
                  <div>
                    <div class="input-header">Event source column name:</div>
                    <span class="input-value"><input nz-input [(ngModel)]="row.eventSourceColumnName" /></span>
                  </div>
                  <div>
                    <div class="input-header">Expression (True/False):</div>
                    <span class="input-expression"
                      ><app-dropdown
                        [isMultiple]="false"
                        [isSearchable]="false"
                        [data]="expression"
                        [size]="'default'"
                        (selectedValue)="expressionSelected($event, row.id)"
                      ></app-dropdown
                    ></span>
                  </div>
                  <div>
                    <div class="input-header">Event source column value:</div>
                    <span class="input-source-value"><input nz-input [(ngModel)]="row.eventSourceColumnValue"/></span>
                  </div>
                  <div>
                    <div class="input-header">Event target column value:</div>
                    <span class="input-target-value"><input nz-input [(ngModel)]="row.eventTargetColumnValue"/></span>
                  </div>
                  <div>
                    <div class="input-header">Date source column name:</div>
                    <span class="input-date-src-name"><input nz-input [(ngModel)]="row.dateSourceColumnName"/></span>
                  </div></div
              ></ng-container>
            </div>
          </div>
        </div>
        <i
          nz-icon
          nzType="plus"
          nzTheme="outline"
          (click)="addRow()"
          class="plus-icon"
          *ngIf="showRollDownScreen"
        ></i>
        <i
          nz-icon
          nzType="minus"
          nzTheme="outline"
          (click)="removeRow()"
          *ngIf="rollDownData.length > 1 && showRollDownScreen"
          class="minus-icon"
        ></i>
      </div>
      <div *ngIf="showRejectScreen && !loading">
        <div class="reject-wrapper">
          <div class="data-header">Reason for Rejection</div>
          <div>
            <textarea
              nz-input
              [(ngModel)]="rejectReason"
              (keyup)="checkReason()"
              placeholder="Enter Reason for Rejection"
              [nzAutosize]="{ minRows: 8, maxRows: 8 }"
              [ngClass]="isValidReason ? 'text' : 'error'"
              (keydown)="disableEnter($event)"
            ></textarea>
            <div class="error-4" *ngIf="!isValidReason">
              The input value for this field requires 2000 characters. Please enter valid input.
            </div>
          </div>
        </div>
      </div>
      <div class="spinner" *ngIf="loading && !showRejectScreen">
        <nz-spin nzSimple></nz-spin>
      </div>
    </ng-container>
  </nz-modal>
</div>

<ng-template #footer>
  <nz-space *ngIf="!showRejectScreen && !loading && !showRollDownScreen">
    <div *nzSpaceItem class="cancel-button" (click)="onCancel()">Cancel</div>
    <div *nzSpaceItem>
      <button
        *ngIf="!checkReviewed()"
        nz-button
        [nzType]="'default'"
        [ngClass]="buttonDisabled() ? 'app-default-button-disabled' : 'app-default-button'"
        (click)="rollDownClicked()"
        [disabled]="rolledDown"
      >
        Roll Down File
      </button>
    </div>
    <div *nzSpaceItem>
      <button
        *ngIf="!checkReviewed()"
        nz-button
        [nzType]="'default'"
        [ngClass]="buttonDisabled() ? 'app-default-button-disabled' : 'app-default-button'"
        (click)="rejectClicked()"
        [disabled]="buttonDisabled()"
      >
        <i nz-icon nzType="minus-circle" nzTheme="fill"></i> Reject Request
      </button>
    </div>
    <div *nzSpaceItem>
      <button
        *ngIf="!checkReviewed()"
        nz-button
        [nzType]="'primary'"
        [ngClass]="
          buttonDisabled() || !mandatoryColumns
            ? 'app-primary-button-disabled'
            : 'app-primary-button'
        "
        (click)="submitReview('Approved')"
        [disabled]="buttonDisabled() || !mandatoryColumns"
      >
        <i nz-icon nzType="check" nzTheme="outline"></i> Approve Request
      </button>
    </div>
  </nz-space>
  <nz-space *ngIf="showRejectScreen && !loading && !showRollDownScreen">
    <div *nzSpaceItem>
      <button nz-button [nzType]="'default'" (click)="onBack()">Back</button>
    </div>
    <div *nzSpaceItem>
      <button
        nz-button
        [nzType]="'primary'"
        [ngClass]="
          rejectReason && isValidReason ? 'app-primary-button' : 'app-primary-button-disabled'
        "
        [disabled]="isdisabled()"
        (click)="submitReview('Rejected')"
      >
        Reject
      </button>
    </div>
  </nz-space>
  <nz-space *ngIf="showRollDownScreen && !showRejectScreen && !loading">
    <div *nzSpaceItem>
      <button
        *ngIf="!checkReviewed()"
        nz-button
        [nzType]="'default'"
        [ngClass]="buttonDisabled() ? 'app-default-button-disabled' : 'app-default-button'"
        (click)="backClicked()"
        [disabled]="buttonDisabled()"
      >
        Back
      </button>
    </div>
    <div *nzSpaceItem>
      <button
        *ngIf="!checkReviewed()"
        nz-button
        [nzType]="'primary'"
        [ngClass]="submitRollDownDisabled() ? 'app-primary-button-disabled' : 'app-primary-button'"
        (click)="submitRollDown()"
        [disabled]="submitRollDownDisabled()"
      >
        Submit
      </button>
    </div>
  </nz-space>
</ng-template>
<ng-template #heading>
  <div *ngIf="showRollDownScreen">Rolling down process for pivoted data</div>
  <div *ngIf="!showRollDownScreen">Steward feedback process</div>
</ng-template>
<app-preview-sample-data
  *ngIf="sampleDataPreview"
  [previewFile]="sampleDataPreview"
  (hidePreview)="sampleDataClose($event)"
  [processId]="processId"
>
</app-preview-sample-data>
<app-preview-profiling-statistics
  *ngIf="profilingStatsPreview"
  [previewFile]="profilingStatsPreview"
  (hidePreview)="profilingStatsClose($event)"
  [processId]="processId"
>
</app-preview-profiling-statistics>
