<div class="add-program-wrapper">
  <nz-modal
    nzTitle="Add Program"
    [(nzVisible)]="showModal"
    [nzWidth]="'624px'"
    [nzWrapClassName]="'modal-wrapper'"
    [nzMaskClosable]="false"
    [nzFooter]="footer"
    (nzOnCancel)="onCancel()"
  >
    <ng-container *nzModalContent>
      <div class="add-program-content" *ngIf="!showReasonScreen">
        <div class="spin" *ngIf="loading"><nz-spin nzSimple></nz-spin></div>
        <div *ngIf="!loading" class="main-data-wrapper">
          <div class="error-message" *ngIf="errorMessage">
            {{ errorMessage }}
          </div>
          <div class="inner-data-1">
            <div class="data-header">Channel</div>
            <div>
              <app-dropdown
                [isMultiple]="false"
                [isSearchable]="true"
                [data]="channelData"
                [placeHolder]="'Select Channel'"
                (selectedValue)="channelSelected($event)"
                nz-popover=""
                [nzPopoverContent]="channel ? channel : 'Select Channel'"
              ></app-dropdown>
            </div>
          </div>
          <div class="inner-data-1">
            <div class="data-header">Program Type</div>
            <div>
              <app-dropdown
                [isMultiple]="false"
                [isSearchable]="true"
                [data]="programTypeData"
                [placeHolder]="'Select Type'"
                nz-popover=""
                [nzPopoverContent]="programType ? programType : 'Select Type'"
                (selectedValue)="programTypeSelected($event)"
              ></app-dropdown>
            </div>
          </div>
          <div class="inner-data-1">
            <div class="data-header">Client</div>
            <app-dropdown
              [isMultiple]="false"
              [isSearchable]="true"
              [data]="clientData"
              [size]="'default'"
              nz-popover=""
              [nzPopoverContent]="client ? client : 'Select Client(s)'"
              [isDisabled]="programType !== 'Custom'"
              [placeHolder]="'Select Client(s)'"
              (selectedValue)="clientsSelected($event)"
            ></app-dropdown>
          </div>
          <div class="inner-data-2">
            <div class="data-header">Select Product</div>
            <div *ngIf="!loading">
              <div>
                <nz-checkbox-group
                  [(ngModel)]="productOptions"
                  (ngModelChange)="productSelected(productOptions)"
                ></nz-checkbox-group>
              </div>
            </div></div>
          <div class="inner-data-2">
            <div class="data-header">Program Title</div>
            <input
              nz-input
              [ngClass]="isValidProgram && validProgramChar ? 'input' : 'error-input'"
              [(ngModel)]="program"
              (keyup)="checkProgram()"
            />
            <div class="error" *ngIf="!isValidProgram">
              Program Title length must be 50 characters or less. Please enter a valid input.
            </div>
            <div class="error" *ngIf="!validProgramChar">
              \ , / , * , ? , : , [ , ] are not allowed in the program name.
            </div>
          </div>
          <div class="inner-data-2">
            <div class="data-header">Data Layout File</div>
            <div class="upload-description">
              Please upload a data layout/template in .csv format.
            </div>
            <div>
              <nz-upload
                nzType="drag"
                *ngIf="fileList.length <= 0"
                [nzFileListRender]="fileListRef"
                (nzChange)="fileChanged($event)"
                [(nzFileList)]="nzFileList"
                [nzMultiple]="false"
              >
                <p class="ant-upload-text">
                  Drag a file here to upload or
                  <button nz-button nzType="primary" class="app-primary-button">
                    <span class="choose-file">Choose File</span>
                  </button>
                </p>
                <ng-template #fileListRef> </ng-template>
              </nz-upload>

              <div *ngIf="fileList.length > 0">
                <div class="progress-wrapper" *ngIf="isInprogress">
                  <div class="file-name">
                    <i nz-icon nzType="cloud-upload" nzTheme="outline"></i>&nbsp;
                    <span class="file-name"
                      ><strong>{{ fileList[0].name }}</strong></span
                    >
                  </div>
                  <div class="progress-bar">
                    <nz-progress
                      nzStrokeLinecap="square"
                      nzStrokeColor="#2B44C7"
                      [nzPercent]="uploadPercent"
                    ></nz-progress>
                  </div>
                </div>
                <div *ngIf="!isInprogress && !showUploadError; else inValidFile">
                  <div nz-row class="title-row file-list">
                    <div nz-col nzSpan="22" class="title">
                      <i nz-icon nzType="check-circle" nzTheme="fill" class="check-icon"></i>
                      <span class="file-name">{{ fileList[0].name }}</span>
                    </div>
                    <div nz-col nzSpan="1" class="link delete-icon" (click)="deleteFile(true)">
                      <i nz-icon nzType="delete" nzTheme="outline"></i>
                    </div>
                  </div>
                </div>
                <ng-template #inValidFile>
                  <div *ngIf="!isInprogress" nz-row class="title-row file-error">
                    <div nz-col nzSpan="22" class="title">
                      <span class="file-name">{{ fileList[0].name }}</span>
                    </div>
                    <div nz-col nzSpan="1" class="link delete-icon" (click)="deleteFile(false)">
                      <i nz-icon nzType="delete" nzTheme="outline"></i>
                    </div>
                  </div>
                </ng-template>
                <div class="error-message" *ngIf="showUploadError">
                  <div>The file type cannot be accepted. Please ensure the file is in .csv</div>
                </div>
              </div>
            </div>
          </div>
        </div>
      </div>
      <div *ngIf="showReasonScreen">
        <div class="reason-wrapper">
          <div class="data-header">Reason for adding program.</div>
          <div>
            <textarea
              nz-input
              [(ngModel)]="reason"
              (keyup)="checkReason()"
              placeholder="Enter Reason for adding program"
              [nzAutosize]="{ minRows: 8, maxRows: 8 }"
              [ngClass]="isValidReason ? 'text' : 'error-textarea'"
              (keydown)="disableEnter($event)"
            ></textarea>
          </div>
          <div class="error" *ngIf="!isValidReason">
            The input value for this field requires 2000 characters. Please enter valid input.
          </div>
        </div>
      </div>
    </ng-container>
  </nz-modal>
</div>
<ng-template #footer>
  <nz-space *ngIf="!showReasonScreen">
    <div *nzSpaceItem>
      <button nz-button [nzType]="'default'" (click)="onCancel()">Cancel</button>
    </div>
    <div *nzSpaceItem>
      <button nz-button [nzType]="'primary'" [ngClass]="disableSubmit() ? 'app-primary-button-disabled' : 'app-primary-button'" [disabled]="disableSubmit()" (click)="showReasonScreen = true">Submit</button>
    </div>
  </nz-space>
  <nz-space *ngIf='showReasonScreen'>
    <div *nzSpaceItem>
      <button nz-button [nzType]="'default'" (click)="backClicked()">Back</button>
    </div>
    <div *nzSpaceItem>
      <button
        nz-button
        [nzType]="'primary'"
        [ngClass]="!reason || !isValidReason ? 'app-primary-button-disabled' : 'app-primary-button'"
        [disabled]="!reason || !isValidReason"
        (click)="submitRequest()"
      >
        Add Program
      </button>
    </div>
  </nz-space>
</ng-template>
