<div class="faq-wrapper">
  <div class="page-header">Frequently Asked Questions</div>
  <div nz-row nzJustify="space-between" nzAlign="middle">
    <div nz-col nzSpan="12" class="search-wrapper">
      <app-search-bar
        [searchParameter]="searchParamData"
        (searchValue)="filterOnSearch($event)"
        (noResultFound)="formatOnNoresult($event)"
        (noInput)="resetSearch($event)"
        (resetSearch)="resetSearch($event)"
        (inputKey)="filterOnSearch($event)"
        [(inputValue)]="searchInput"
      ></app-search-bar>
    </div>
    <div nz-col nzSpan="12" class="button-wrapper">
      <span class="export-wrapper">
        <app-simple-dropdown [page]="'faq'" *ngIf="isAdmin"></app-simple-dropdown>
      </span>
      <button nz-button nzType="primary" class="app-primary-button" style="height: 38px; padding: 0px 10px; cursor: pointer;"
        (click)="downloadUsecase()">
        <i nz-icon nzType="download" nzTheme="outline"></i>
        Illustrated Applications
      </button>
      <a class="onboarding-button" (click)="showOnboarding = true"
        >View Tutorial</a
      >
    </div>
    <div>
      <ng-container>
        <ul nz-menu nzMode="horizontal">
          <ng-container *ngFor="let item of menuItems">
            <li class="affinity-menu-item" nz-menu-item [nzSelected]="item.isSelected" (click)="switchTab(item.name)">
              {{ item.name }}
            </li>
          </ng-container>
        </ul>
      </ng-container>
    </div>
  </div>
  <div class="spinner" *ngIf="isLoading">
      <nz-spin nzSimple></nz-spin>
    </div>
  <div class="content-wrapper" *ngIf="!isLoading">
    <div *ngIf="noResult" class="no-result">Your search returned no results</div>
        <nz-collapse *ngIf="!noResult">
          <nz-collapse-panel
            *ngFor="let faq of faqData"
            [nzHeader]="faq['Question']"
            class="answer-wrapper"
          >
          <p>
            {{ faq['Answer'] }}
            <button *ngIf="faq['Answer']?.includes('The ZS AffinityMonitor®️ output data will have two files: First, an HCP-level report which contains aggregated, cross-industry metrics on an individual HCP’s channel affinity and related metrics like channel-wise engagement volume and engagement quintile. Second, a Summary report consisting of cross industry, cross channel customer views.')" 
            type="button" 
            (click)="downloadFile(loadedData['template_url_1'], '')" 
                _ngcontent-ng-c4132998125="" nz-button="" nztype="primary" class="app-primary-button" style="height: 38px; padding: 0px 10px; cursor: pointer;">
                <i _ngcontent-ng-c4132998125="" nz-icon="" nztype="download" nztheme="outline">
                </i> AFM Output Report Definitions File
            </button>
            <button *ngIf="faq['Answer']?.includes('ZS AccessMonitor™️ subscriptions consist of two deliverables:\n1.HCP data report – a text file containing retrospective HCP accessibility metrics such as the AM No-see rating, call frequency percentiles (overall and channel-wise), industry attainment, etc.\n2.ZAIDYN interactive dashboard – allows viewers to compare AccessMonitor data against current/past industry and client level metrics.')"
            type="button" 
            (click)="downloadFile(loadedData['template_url_2'], '')" 
                _ngcontent-ng-c4132998125="" nz-button="" nztype="primary" class="app-primary-button" style="height: 38px; padding: 0px 10px; cursor: pointer;">
                <i _ngcontent-ng-c4132998125="" nz-icon="" nztype="download" nztheme="outline">
                </i> ACM Output Report Definitions Detailed File 
            </button>
          </p>          
          </nz-collapse-panel>
        </nz-collapse>

  </div>
</div>

<app-onboarding [showTour]="showOnboarding" (hideOnboarding)="onOnboardingClose($event)">
</app-onboarding>

<app-drawer
  [(showDrawer)]="showDrawer"
  [content]="drawerContent"
  [title]="'HELP CENTER'"
  (tabChanged)="switchDrawerTab($event)"
></app-drawer>