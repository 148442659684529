import { Component, EventEmitter, Input, OnInit, Output } from '@angular/core';
import { NzModalRef, NzModalService } from 'ng-zorro-antd/modal';
import { ReplaceValuePipe } from '../../../../shared/pipe/replace-value.pipe';
import { HttpService } from '../../../../shared/services/http.service';
import { ReviewData } from '../../../../shared/models/data-review.model';
import { DatePipe } from '@angular/common';

@Component({
  selector: 'app-data-review-modal',
  templateUrl: './data-review-modal.component.html',
  styleUrls: ['./data-review-modal.component.scss'],
})
export class DataReviewModalComponent implements OnInit {
  @Input() showReviewModal = false;
  @Input() id = 1;
  @Input() dataChangeStatus;

  @Output() hideReviewModal: EventEmitter<boolean> = new EventEmitter<boolean>();
  @Output() reloadPage: EventEmitter<boolean> = new EventEmitter<boolean>();

  public replaceValue = new ReplaceValuePipe();
  public loading = false;

  public reviewData: ReviewData = new ReviewData();

  public isDeleted = false;
  public customProg = '';

  public showRejectScreen = false;
  public reason = '';
  public isValidReason = true;
  public businessOtherSelected = false;
  public businessOptions = [
    'Incorrect Data/Uploaded By mistake',
    'Missing Data',
    'Revised/Updated Data',
    'New Data is available',
    'Usage/Compliance Issues',
  ];

  public confirmModal?: NzModalRef;

  constructor(private modal: NzModalService, private httpService: HttpService, public datePipe: DatePipe) {}

  ngOnInit(): void {
    this.loading = true;
    this.loadData();
  }

  public loadData(): void {
    const payloadId = {
      id: this.id,
    };
    this.httpService.postRequest('get_review_data_changes', payloadId).subscribe((result) => {
    // this.httpService.getRequest('get_review_data_changes').subscribe((result) => {
      if (result['status'] === 'SUCCESS') {
        this.isDeleted = result.isDeleted;
        if (!this.isDeleted){
          this.reviewData = result.data;
        } else {
          this.reviewData.businessJustification = result.data.businessJustification;
          this.reviewData.businessJustificationDescription = result.data.businessJustificationDescription;
        }
        this.customProg = result.custom_programs;
        this.businessCheck();
        this.loading = false;
      } else if (result['status'] === 'ERROR') {
        console.log('error', result['message']);
      }
    });
  }

  public onCancel(): void {
    this.showReviewModal = false;
    this.confirmModal = this.modal.confirm({
      nzTitle: 'Leave data changes review',
      nzContent: 'Are you sure you want to leave the review process?',
      nzOkText: 'Yes, Leave',
      nzIconType: 'exclamation-circle',
      nzOnOk: () => {
        this.hideReviewModal.emit(false);
        this.showReviewModal = false;
      },
      nzOnCancel: () => {
        this.showReviewModal = true;
      },
    });
  }

  public onReject(): void {
    this.showRejectScreen = true;
  }
  public onBack(): void {
    this.reason = '';
    this.showRejectScreen = false;
    this.isValidReason = true;
  }
  public isdisabled(): boolean {
    if (this.isValidReason && this.reason) {
      return false;
    } else {
      return true;
    }
  }

  public checkReason(): void {
    this.reason = this.replaceValue.transform(this.reason, true);
    this.isValidReason = this.reason ? this.reason.length <= 2000 : true;
  }

  public businessCheck(): void {
    const otherReason = 'other';
    const reason = 'Usage/Compliance Issues';
    this.reviewData.businessJustification.forEach((r) => {
      if (
        r.toLowerCase() === otherReason.toLowerCase() ||
        r.toLowerCase() === reason.toLowerCase()
      ) {
        this.businessOtherSelected = true;
      }
    });
  }

  public approveClicked(): void {
    const changeType = this.isDeleted ? 'delete' : 'edit';
    const payload = {
      id: this.id,
      state: 'Approved',
      reason: '',
      change_type: changeType,
    };
    this.httpService.postRequest('submit_data_changes_review', payload).subscribe((data) => {
    //this.httpService.getRequest('submit_data_changes_review').subscribe((data) => {
      if (data['status'] === 'SUCCESS') {
        this.reloadPage.emit(true);
        this.hideReviewModal.emit(false);
        this.showReviewModal = false;
      }
    });
  }

  public rejectClicked(): void {
    const changeType = this.isDeleted ? 'delete' : 'edit';
    const payload = {
      id: this.id,
      state: 'Rejected',
      reason: this.reason,
      change_type: changeType,
    };
    this.httpService.postRequest('submit_data_changes_review', payload).subscribe((data) => {
    //this.httpService.getRequest('submit_data_changes_review').subscribe((data) => {
      if (data['status'] === 'SUCCESS') {
        this.reloadPage.emit(true);
        this.hideReviewModal.emit(false);
        this.showReviewModal = false;
      }
    });
  }
    public disableEnter(event): void {
    if (!this.reason && event.key == 'Enter') {
      event.preventDefault();
    }
  }
    public getDate(date, monthFormat): string {
    if (monthFormat) {
      if (date === 'NA') {
        return date;
      } else {
        try {
          return date ? this.datePipe.transform(new Date(date), 'dd MMM y') : 'NA';
        } catch (e) {
          return 'invalid date';
        }
      }
    }
    if (!monthFormat) {
      if (date === 'NA') {
        return date;
      } else {
        try {
          return date ? this.datePipe.transform(new Date(date), 'yyyy-MM-dd') : 'NA';
        } catch (e) {
          return 'invalid date';
        }
      }
    }
  }
}
