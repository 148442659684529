import { Component, EventEmitter, OnInit, Output, ViewChild } from '@angular/core';
import { ProgramApprovalComponent } from './program-approval/program-approval.component';
import { CustomProgramComponent } from './custom-program/custom-program.component';
import { StandardProgramsComponent } from './standard-programs/standard-programs.component';
import { HttpService } from '../../shared/services/http.service';

@Component({
  selector: 'app-program-management',
  templateUrl: './program-management.component.html',
  styleUrls: ['./program-management.component.scss'],
})
export class ProgramManagementComponent implements OnInit {
  @ViewChild(ProgramApprovalComponent) progApprovalComp: ProgramApprovalComponent;
  @ViewChild(CustomProgramComponent) customProgComp: CustomProgramComponent;
  @ViewChild(StandardProgramsComponent) standardProgComp: StandardProgramsComponent;

  constructor(private httpService: HttpService) {}

  public programApprovalFlag = true;
  public searchParams = [];
  public exportUrl = '';
  public tabName = 'Standard Programs';
  public pendingCustomProgram = 'Program Approval';
  public pendingCustomTab = 'Custom Programs';
  public pendingStandardTab = 'Satndard Programs';
  public searchInput = '';

  public addProgram = false;

  ngOnInit(): void {
    this.getPendingCount(true);
  }

  public searchAssign(event): void {
    this.searchParams = event;
  }

  public urlAssign(event): void {
    this.exportUrl = event;
  }

  public filterOnSearch(key): void {
    if (this.tabName === 'Program Approval') {
      this.progApprovalComp.search = key;
      this.progApprovalComp.payload.search = key;
      this.progApprovalComp.loadData();
    } else if (this.tabName === 'Standard Programs') {
      this.standardProgComp.search = key;
      this.standardProgComp.payload.search = key;
      this.standardProgComp.loadData();
    } else if (this.tabName === 'Custom Programs') {
      this.customProgComp.search = key;
      this.customProgComp.payload.search = key;
      this.customProgComp.loadData();
    }
  }

  public formatOnNoResult(event): void {
    if (event) {
      if (this.tabName === 'Program Approval') {
        this.progApprovalComp.noDataMessage = 'Your search returned no results.';
      } else if (this.tabName === 'Standard Programs') {
        this.standardProgComp.noDataMessage = 'Your search returned no results.';
      } else if (this.tabName === 'Custom Programs') {
        this.customProgComp.noDataMessage = 'Your search returned no results.';
      }
    }
  }

  public tabChanged(tab): void {
    this.searchInput = '';
    switch (tab) {
      case 'Standard Programs':
        {
          this.programApprovalFlag = false;
          this.tabName = 'Standard Programs';
        }
        break;
      case 'Custom Programs':
        {
          this.programApprovalFlag = false;
          this.tabName = 'Custom Programs';
        }
        break;
      case 'Program Approval':
        {
          this.programApprovalFlag = true;
          this.tabName = 'Program Approval';
        }
        break;
    }
  }

  public resetSearch(event): void {
    if (event) {
      if (this.tabName === 'Program Approval') {
        this.progApprovalComp.pageIndex = 1;
        this.progApprovalComp.payload.search = '';
        this.progApprovalComp.search = '';
        this.progApprovalComp.noDataMessage = 'No requests available.';
        this.progApprovalComp.loadData();
      } else if (this.tabName === 'Standard Programs') {
        this.standardProgComp.pageIndex = 1;
        this.standardProgComp.payload.search = '';
        this.standardProgComp.search = '';
        this.standardProgComp.noDataMessage = 'There are no programs to display.';
        this.standardProgComp.loadData();
      } else if (this.tabName === 'Custom Programs') {
        this.customProgComp.pageIndex = 1;
        this.customProgComp.payload.search = '';
        this.customProgComp.search = '';
        this.customProgComp.noDataMessage = 'There are no programs to display.';
        this.customProgComp.loadData();
      }
    }
  }

  public getPendingCount(event): void {
    if (event) {
      this.httpService.getRequest('get_pending_count').subscribe((data) => {
        if (data.customProgram === 0) {
          this.pendingCustomProgram = 'Program Approval';
        } else {
          this.pendingCustomProgram =
            'Program Approval<sup class="highlight">' + data.customProgram + '</sup>';
        }
        if (data.customTab === 0) {
          this.pendingCustomTab = 'Custom Programs';
        } else {
          this.pendingCustomTab =
            'Custom Programs<sup class="highlight">' + data.customTab + '</sup>';
        }
        if (data.standardTab === 0) {
          this.pendingStandardTab = 'Standard Programs';
        } else {
          this.pendingStandardTab =
            'Standard Programs<sup class="highlight">' + data.standardTab + '</sup>';
        }
      });
    }
  }

  public downloadFile(url, target): void {
    window.open(url, target);
  }

  public addProgramClicked(): void {
    this.addProgram = true;
  }

  public reloadPage(): void {
    console.log('tabName: ', this.tabName);
    if (this.tabName === 'Standard Programs') {
      this.standardProgComp.loadData();
    } else if (this.tabName === 'Custom Programs') {
      this.customProgComp.loadData();
    }
    // window.location.reload();
  }
}
