<div class="b-modal-wrapper">
  <nz-modal
    [nzTitle]="editAction ? 'Modify Data' : 'Delete Upload Files'"
    [(nzVisible)]="showModal"
    [nzWidth]="'624px'"
    (nzOnCancel)="onCancel()"
    class="template-modal-header"
    [nzMaskClosable]="false"
    [nzBodyStyle]="{ height: '550px', 'overflow-y': 'auto' }">
    <div style="padding: 24px">
    <p class="info-text">
      This data has been approved to generate a report. If you wish to modify please provide a business justification to proceed. Please note that changes made at this point will be pending finalization until approved by the ZS team.
    </p>

    <div class="business-justification">
      <p class="heading-busj">Business Justification</p>
      <div class="description" [innerHTML]="getDescription()">
      </div>
      <app-dropdown
        [size]="'large'"
        class="dropdown-busj"
        [data] = "data"
        [placeHolder]="'Please select business justification '"
        (selectedValue)="displayT($event)"
      ></app-dropdown>
    </div>

    <div *ngIf="exceedsLimit()" class="exceeds-limit">
       The number of re-uploads are exhausted. For any questions please reach out to AffinityMonitor@zs.com.
    </div>

    <div *ngIf="windowClosed()" class="exceeds-limit">
       New data cannot be considered since the upload window is closed for this cycle. Please reach out to the AffinityMonitor team for any questions.
    </div>

    <div *ngIf="displayReason">
      <p class="heading-busj">Reason for Modification</p>
      <div class="input-wrapper">
            <textarea
              nz-input
              placeholder="Please briefly detail the situation. This will help the ZS Team to take further action"
              [(ngModel)]="description"
              (keyup)="checkDescription()"
              [nzAutosize]="{ minRows: 8, maxRows: 8 }"
              [ngClass]="isValidDesc ? '' : 'error'"
              (keydown)="disableEnter($event)"
            ></textarea>
            <div class="error-3" *ngIf="!isValidDesc">
              The input value for this fields requires no more than 100 characters.
            </div>
          </div>
    </div>

    <div *nzModalFooter>
      <nz-space>
        <div *nzSpaceItem>
            <button nz-button nzType="default" (click)="onCancel()">
              Cancel
            </button>
          </div>
        <div *nzSpaceItem >
          <button nz-button nzType="primary" class="app-primary-button" (click)="okClicked()"
                  [disabled]="!enableButton || exceedsLimit() || windowClosed()"
          >
            {{ isNewData ? 'Upload File' : editAction === true ? 'Yes, Edit' : 'Yes, Delete'}}
          </button>
        </div>

      </nz-space>

    </div>
</div>
  </nz-modal>
</div>
