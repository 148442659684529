import { Component, EventEmitter, Input, OnInit, Output } from '@angular/core';

@Component({
  selector: 'app-file-preview',
  templateUrl: './file-preview.component.html',
  styleUrls: ['./file-preview.component.scss']
})
export class FilePreviewComponent implements OnInit {

  @Input() previewFile = false;
  @Input() modalTitle = '';
  @Input() fileData = [];
  @Input() headers = [];
  @Input() loading = false;
  @Input() multipleFiles = false;
  @Input() message = '';
  @Output() hidePreview = new EventEmitter<boolean>();

  constructor() { }

  ngOnInit(): void {
  }
    public handleCancel(): void {
    this.hidePreview.emit(false);
  }

}
