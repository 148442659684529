class Date {
  oldDate = '';
  newDate = '';
}
class Description {
  oldDesc = '';
  newDesc = '';
}
class FileTitle {
  oldTitle = '';
  newTitle = '';
}
export class InfoChange {
  constructor(
    public startDate: Date = new Date(),
    public endDate: Date = new Date(),
    public description: Description = new Description(),
    public fileTitle: FileTitle = new FileTitle()
  ) {}
}
