import { NgModule } from '@angular/core';
import { CommonModule } from '@angular/common';
import { AffexComponent } from './affex.component';
import { SharedModule } from 'src/app/shared/shared.module';
import { NzGridModule } from 'ng-zorro-antd/grid';
import { NzDividerModule } from 'ng-zorro-antd/divider';
import { NzLayoutModule } from 'ng-zorro-antd/layout';
import { AffexContentComponent } from './affex-content/affex-content.component';
import { NzCardModule } from 'ng-zorro-antd/card';
import { NzIconModule } from 'ng-zorro-antd/icon';
import { NzUploadModule } from 'ng-zorro-antd/upload';
import { NzButtonModule } from 'ng-zorro-antd/button';
import { NzSpaceModule } from 'ng-zorro-antd/space';
import { NzProgressModule } from 'ng-zorro-antd/progress';
import { NzSpinModule } from 'ng-zorro-antd/spin';
import { NzPopconfirmModule } from 'ng-zorro-antd/popconfirm';
import { NzPopoverModule } from 'ng-zorro-antd/popover';
import { NzPipesModule } from 'ng-zorro-antd/pipes';
import { NzTableModule } from 'ng-zorro-antd/table';

@NgModule({
  declarations: [AffexComponent, AffexContentComponent],
  imports: [
    CommonModule,
    NzGridModule,
    SharedModule,
    NzDividerModule,
    NzLayoutModule,
    NzCardModule,
    NzIconModule,
    NzUploadModule,
    NzButtonModule,
    NzSpaceModule,
    NzProgressModule,
    NzSpinModule,
    NzPopconfirmModule,
    NzPipesModule,
    NzTableModule,
    NzPopoverModule
  ],
})
export class AffexModule {}
