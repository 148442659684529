<div class="header-content">
    <div>
      <p class="alignleft">
        <span class="stats-content"><strong>Total:</strong> {{ totalCount }}</span>
        <span class="stats-content"><strong>Unique Count:</strong> {{ uniqueCount }}</span>
        <span class="stats-content"><strong>Client LOVs:</strong> {{ clientCount }}</span>
        <span *ngIf="AFMCount"  class="stats-content">
            <strong>
                <ng-container *ngIf="type==='Speciality'">AFM Specialties:</ng-container>
                <ng-container *ngIf="type==='Disposition'">Channel-Sub Channels:</ng-container>
                <ng-container *ngIf="type==='Vendor'">Vendor Count:</ng-container>
                <ng-container *ngIf="type==='Offer'">Offer Count:</ng-container>
                <ng-container *ngIf="type==='Brand'">Brand Count:</ng-container>
            </strong>
            {{ AFMCount }}
        </span>
        <span *ngIf="type==='Disposition'"  class="stats-content"><strong>Standard Dispositions:</strong> {{ standardDispositions }}</span>
      </p>
      <p class="alignright">
        <span *ngIf="!isSubmit; else showSubmit">
            <button
            nz-button
            nzType="primary"
            class="app-primary-button action-button"
            (click)="downloadFile()"
            >
            &nbsp;<i nz-icon nzType="download" nzTheme="outline" class="action-icon"></i>
            Download &nbsp;
            </button>
            <button
            nz-button
            nzType="primary"
            class="app-primary-button action-button"
            (click)="isUpload = true"
            >
            &nbsp;<span nz-icon nzType="upload" nzTheme="outline" class="action-icon"></span>
            Upload &nbsp;
            </button>
        </span>
        <ng-template #showSubmit>
            <button
            nz-button
            nzType="primary"
            class="app-primary-button action-button"
            (click)="submit()"
            >
            &nbsp; Submit &nbsp;
            </button>
        </ng-template>
      </p>
    </div>
</div>

<nz-modal
  [nzStyle]="{ top: '20px' }"
  [(nzVisible)]="isUpload"
  [nzWidth]="'820px'"
  [nzFooter]="null"
  [nzMaskClosable]="false"
  [nzTitle]="'Upload Automation File'"
  (nzOnCancel)="isUpload = false"
  class="file-modal"
  [nzWrapClassName]="'modal-wrapper'"
>
  <ng-container *nzModalContent>
    <div class="modal-body-wrapper">
      <p class="message">
        The files you uploaded should use proper formatting so it can be easily processed by ZS.
        Contact the Affinity Monitor team if you have any questions.
      </p>
      <nz-upload
        nzType="drag"
        *ngIf="fileList.length <= 0"
        [nzFileListRender]="fileListRef"
        (nzChange)="fileChanged($event)"
        [(nzFileList)]="nzFileList"
        [nzMultiple]="false"
      >
        <p class="ant-upload-text">
          Drag a file here to upload or
          <button nz-button nzType="primary" class="app-primary-button">Choose File</button>
        </p>
        <ng-template #fileListRef> </ng-template>
      </nz-upload>

      <div *ngIf="fileList.length > 0">
        <div nz-row class="title-row file-list" *ngIf="!showUploadError; else inValidFile">
          <div nz-col nzSpan="22" class="title" [title]="fileList[0].name">
            <i nz-icon nzType="check-circle" nzTheme="fill" class="check-icon"></i>
            {{ fileList[0].name | nzEllipsis: 20:'...' }}
          </div>
          <div nz-col nzSpan="2" class="link">
            <i
              nz-icon
              nzType="delete"
              nzTheme="outline"
              class="delete-icon"
              (click)="deleteFile(true)"
            ></i>
          </div>
        </div>
        <ng-template #inValidFile>
          <div nz-row class="title-row file-error">
            <div nz-col nzSpan="12" class="title" [title]="fileList[0].name">
              {{ fileList[0].name | nzEllipsis: 20:'...' }}
            </div>
            <div nz-col nzSpan="12" class="link">
              <i
                nz-icon
                nzType="delete"
                nzTheme="outline"
                class="delete-icon"
                (click)="deleteFile(false)"
              ></i>
            </div>
          </div>
        </ng-template>
      </div>

      <div class="error-message" *ngIf="errorMessage">
        <strong>{{ errorMessage }}</strong>
      </div>

      <div>
        <button
          nz-button
          nzType="primary"
          class="app-primary-button submit-button"
          [ngClass]="fileList.length < 1 || errorMessage ? 'disabled' : ''"
          (click)="upload()"
          [disabled]="fileList.length < 1 || errorMessage"
        >
          &nbsp; Upload &nbsp;
        </button>
      </div>
    </div>
  </ng-container>
</nz-modal>
