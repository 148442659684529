import { InfoChange } from './info-change.model';
class Changes {
  added = [];
  removed = [];
}
class ChangesMap {
  added = [{file_name: '', program: '', isCustom: false}];
  removed = [{file_name: '', program: ''}];
}

export class ReviewData  {
  constructor(
    public businessJustification: Array<any> = [],
    public businessJustificationDescription = '',
    public uploadChanges: Changes = new Changes(),
    public mappingChanges: ChangesMap = new ChangesMap(),
    public infoChanges: InfoChange = new InfoChange(),
    public reason = '',
  ) {
  }
}
