<div
  *ngIf='!message'
  [ngClass]="multipleFiles? 'modal-body-wrapper': 'modal-body-wrapper-single'">
  <div *ngIf="loading" class="loading">File preview is being created. This might take sometime..</div>
  <nz-table
    class="app-modal-table"
    #previewTable
    [nzData]="fileData"
    nzTableLayout="fixed"
    [nzShowPagination]="true"
    [nzLoading]="loading"
  >
    <thead>
      <tr>
        <th *ngFor="let column of headers" nzWidth="120px">
          {{ column }}
        </th>
      </tr>
    </thead>
    <tbody>
      <tr *ngFor="let data of previewTable.data">
        <ng-container *ngFor="let column of headers">
          <td>
            {{ data[column] }}
          </td>
        </ng-container>
      </tr>
    </tbody>
  </nz-table>
</div>
 <div *ngIf='message' class='modal-body-wrapper-single'>
    {{message}}
  </div>

