import { Pipe, PipeTransform } from '@angular/core';

@Pipe({
  name: 'replaceValue',
})
export class ReplaceValuePipe implements PipeTransform {
  transform(value: string, trim = false, regex = null, newvalue?: string): string {
    if (value) {
      if (trim) {
        return value.replace(/^[ \t]+|$/g, '');
      }
    }
  }
}
