import {Injectable} from '@angular/core';
import {BehaviorSubject} from 'rxjs';

@Injectable()
export class AppState {
  public static appState = new BehaviorSubject(null);

  constructor() {
    let gbState = window.sessionStorage.getItem('globalState');
    if (typeof gbState === 'string') {
      try {
        gbState = JSON.parse(gbState);
      } catch (e) {
        gbState = JSON.parse('{}');
      }
    }
    AppState.appState.next(gbState);
  }

  public setGlobalState(key, value): void {
    let temp = AppState.appState.getValue();

    if (typeof temp === 'string') {
      try {
        temp = JSON.parse(temp);
      } catch (e) {
        temp = {};
      }
    }

    try {
      temp[key] = value;
    } catch (e) {
      temp = {};
      temp[key] = value;
    }

    window.sessionStorage.setItem('globalState', JSON.stringify(temp));
    AppState.appState.next(temp);
  }

  public removeGlobalState(key): void {
    const temp = AppState.appState.getValue();
    delete temp[key];

    AppState.appState.next(temp);
    window.sessionStorage.setItem('globalState', JSON.stringify(temp));
  }

  public clearGlobalState(): void {
    AppState.appState.next({});
    window.sessionStorage.clear();
  }

  public passValue(data): void {
    AppState.appState.next(data);
  }
}
