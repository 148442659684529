import { Component, OnInit } from '@angular/core';
import { HttpService } from '../../../shared/services/http.service';
import { NzUploadChangeParam, NzUploadFile } from 'ng-zorro-antd/upload';
import { NzModalRef, NzModalService } from 'ng-zorro-antd/modal';

@Component({
  selector: 'app-activity-files',
  templateUrl: './activity-files.component.html',
  styleUrls: ['./activity-files.component.scss']
})
export class ActivityFilesComponent implements OnInit {

  public menuItems = [
    {
      id: 1,
      name: 'Disposition',
      isSelected: true,
      route: '/afm',
      isVisible: true
    },
    {
      id: 2,
      name: 'Vendor',
      isSelected: false,
      route: '/acs',
      isVisible: true
    },
    {
      id: 3,
      name: 'Offer',
      isSelected: false,
      route: '/acs',
      isVisible: true
    },
    {
      id: 4,
      name: 'Brand',
      isSelected: false,
      route: '/acs',
      isVisible: true
    },
  ];
  public clientCount = 0;
  public totalCount = 0;
  public uniqueCount = 0;
  public AFMCount = 0;
  public standardDispositions = 0;
  public downloadUrl = '';
  public headers;
  public fileData;
  public uploadHeaders;
  public feedbackHeaders;
  public feedbackFileData;
  public uploadFileData;
  public uploadStats = {
    countValues: {
      uploadTotalCount: 0,
      uploadUniqueCount: 0,
      uploadClientCount: 0,
      uploadAFMCount: 0
    },
    tableData: {
      tbody: [],
      thead: []
    },
    feedbackTableData: {
      tbody: [],
      thead: {}
    }
  };
  public payload = {
    type: 'Disposition'
  };

  constructor(private httpService: HttpService, private modal: NzModalService) { }

  ngOnInit(): void {
    this.loadData();
  }

  public switchTab(selected): void{
    if (selected === 'Disposition'){
      this.menuItems[0].isSelected = true;
      this.menuItems[1].isSelected = false;
      this.menuItems[2].isSelected = false;
      this.menuItems[3].isSelected = false;
    } else if (selected === 'Vendor') {
      this.menuItems[0].isSelected = false;
      this.menuItems[1].isSelected = true;
      this.menuItems[2].isSelected = false;
      this.menuItems[3].isSelected = false;
    } else if (selected === 'Offer') {
      this.menuItems[0].isSelected = false;
      this.menuItems[1].isSelected = false;
      this.menuItems[2].isSelected = true;
      this.menuItems[3].isSelected = false;
    } else if (selected === 'Brand') {
      this.menuItems[0].isSelected = false;
      this.menuItems[1].isSelected = false;
      this.menuItems[2].isSelected = false;
      this.menuItems[3].isSelected = true;
    }
    this.payload.type = selected;
    this.loadData();
  }

  public loadData(): void {
    // this.httpService.getRequest('get_automation_mapping_response').subscribe((data) => {
    this.httpService.postRequest('get_automation_mapping_response', this.payload).subscribe((data) => {
      this.clientCount = data.customer_mapping_data.count_values.client_count;
      this.totalCount = data.customer_mapping_data.count_values.total_count;
      this.uniqueCount = data.customer_mapping_data.count_values.unique_count;
      this.AFMCount = data.customer_mapping_data.count_values.unique_afm_ss;
      this.standardDispositions = data.customer_mapping_data.count_values.standard_disposition;
      this.downloadUrl = data.download_url;
      this.headers = data.customer_mapping_data.table_data.thead;
      this.fileData = data.customer_mapping_data.table_data.tbody;
      this.feedbackHeaders = data.customer_mapping_data.feedback_table_data.thead;
      this.feedbackFileData = data.customer_mapping_data.feedback_table_data.tbody;
      this.uploadStats.countValues.uploadTotalCount = data.manual_review_data.count_values.upload_total_count;
      this.uploadStats.countValues.uploadUniqueCount = data.manual_review_data.count_values.upload_unique_count;
      this.uploadStats.countValues.uploadClientCount = data.manual_review_data.count_values.upload_client_count;
      this.uploadStats.countValues.uploadAFMCount = data.manual_review_data.count_values.upload_unique_afm_ss;
      this.uploadStats.tableData = data.manual_review_data.table_data;
      this.uploadStats.feedbackTableData = data.manual_review_data.feedback_table_data;
    });
  }

  public getUploadStats(data): void {
    this.uploadStats = data;
    this.loadData();
  }
}
