import { Component, OnInit } from '@angular/core';
import { HttpService } from '../../shared/services/http.service';

@Component({
  selector: 'app-approvals-list',
  templateUrl: './approvals-list.component.html',
  styleUrls: ['./approvals-list.component.scss'],
})
export class ApprovalsListComponent implements OnInit {
  public customProgClicked = true;
  public payload = {};
  public pendingCustomProgram = 'Custom Programs';
  public pendingDataChanges = 'Data Changes';
  public exportUrl = '';

  constructor(private httpService: HttpService) {}

  ngOnInit(): void {
    this.customProgClicked = true;
    this.getPendingCount(true);
  }

  public getExportUrl(url): void {
    this.exportUrl = url;
  }

  public exportClicked(): void {
    window.open(this.exportUrl, '');
    // this.httpService.postRequest('export_custom_program_data', this.payload).subscribe(
    //   (result) => {
    //     //this.httpService.getRequest('export_custom_program_data').subscribe((result) => {
    //     if (result['status'] === 'SUCCESS') {
    //       this.downloadFile(result.url, '');
    //     } else {
    //       console.log('something went wrong');
    //     }
    //   },
    //   (error) => {
    //     console.log('something went wrong: ', error);
    //   },
    // );
  }
  public addPayload(event): void {
    this.payload = event;
  }
  public downloadFile(url, target): void {
    window.open(url, target);
  }
  public getPendingCount(event): void {
    if (event) {
      this.httpService.getRequest('get_pending_count').subscribe((data) => {
        if (data.customProgram === 0) {
          this.pendingCustomProgram = 'Custom Programs';
        } else {
          this.pendingCustomProgram =
            'Custom Programs<sup class="highlight">' + data.customProgram + '</sup>';
        }
        if (data.dataChanges === 0) {
          this.pendingDataChanges = 'Data Changes';
        } else {
          this.pendingDataChanges =
            'Data Changes<sup class="highlight">' + data.dataChanges + '</sup>';
        }
      });
    }
  }
}
