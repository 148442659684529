<!--<div class="import-modal-wrapper"></div>-->
<nz-modal
  [(nzVisible)]="showModal"
  [nzTitle]="title"
  (nzOnCancel)="handleCancel()"
  (nzOnOk)="handleOk()"
  nzClassName="import-modal-wrapper"
>
  <ng-container *nzModalContent>
    <div class="spinner" *ngIf="isLoading">
      <nz-spin nzSimple></nz-spin>
    </div>

    <div class="data-layout-wrapper" *ngIf="!isLoading">
      <div class="description" [innerHTML]="description"></div>
      <nz-upload
        nzType="drag"
        *ngIf="fileList.length <= 0"
        [nzFileListRender]="fileListRef"
        (nzChange)="fileChanged($event)"
        [(nzFileList)]="nzFileList"
        [nzMultiple]="false"
      >
        <p class="ant-upload-text">
          Drag a file here to upload or
          <button nz-button nzType="primary" class="app-primary-button">Choose File</button>
        </p>
        <ng-template #fileListRef> </ng-template>
      </nz-upload>
      <div *ngIf="fileList.length > 0">
        <div nz-row class="title-row file-list" *ngIf="!showUploadError; else inValidFile">
          <div nz-col nzSpan="22" class="title" [title]="fileList[0].name">
            <i nz-icon nzType="check-circle" nzTheme="fill" class="check-icon"></i>
            {{ fileList[0].name | nzEllipsis: 20:'...' }}
          </div>
          <div nz-col nzSpan="2" class="link">
            <i
              nz-icon
              nzType="delete"
              nzTheme="outline"
              class="delete-icon"
              (click)="deleteFile(true)"
            ></i>
          </div>
        </div>
        <ng-template #inValidFile>
          <div nz-row class="title-row file-error">
            <div nz-col nzSpan="12" class="title" [title]="fileList[0].name">
              {{ fileList[0].name | nzEllipsis: 20:'...' }}
            </div>
            <div nz-col nzSpan="12" class="link">
              <i
                nz-icon
                nzType="delete"
                nzTheme="outline"
                class="delete-icon"
                (click)="deleteFile(false)"
              ></i>
            </div>
          </div>
        </ng-template>
      </div>
      <div class="error-message" *ngIf="showUploadError">
        <div *ngIf="!errorMessage">
          The file type cannot be accepted. Please ensure the file is in .xlsx extension format.
        </div>
        <div *ngIf="errorMessage">
          {{ errorMessage }}
          <span *ngIf="downloadUrl"
            >Please find the log <span class="link" (click)="downloadFile(downloadUrl, '')">here</span>.</span
          >
        </div>
      </div>
    </div>
  </ng-container>

  <!-- Footer -->
  <div *nzModalFooter>
    <nz-space>
      <div *nzSpaceItem>
        <button nz-button nzType="default" (click)="handleCancel()" [disabled]="isLoading">
          <span class="footer-buttons">Cancel</span>
        </button>
      </div>
    </nz-space>
    <nz-space>
      <div *nzSpaceItem>
        <button
          nz-button
          nzType="primary"
          [class]="this.fileList.length < 1 || showUploadError || isLoading ? 'app-primary-button-disabled' : 'app-primary-button'"
          (click)="handleOk()"
          [disabled]="this.fileList.length < 1 || showUploadError || isLoading"
        >
          <span class="footer-buttons">Import</span>
        </button>
      </div>
    </nz-space>
  </div>
</nz-modal>
