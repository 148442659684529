import { NgModule } from '@angular/core';
import { CommonModule } from '@angular/common';
import { FaqComponent } from './faq.component';
import { SharedModule } from '../shared/shared.module';
import { NzGridModule } from 'ng-zorro-antd/grid';
import { NzCollapseModule } from 'ng-zorro-antd/collapse';
import {NzMenuModule} from "ng-zorro-antd/menu";
import { NzSpinModule } from 'ng-zorro-antd/spin';

@NgModule({
  declarations: [FaqComponent],
  imports: [CommonModule, SharedModule, NzGridModule, NzCollapseModule, NzMenuModule, NzSpinModule],
  exports: [FaqComponent],
})
export class FaqModule {}
