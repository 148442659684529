import { Component, EventEmitter, Input, OnInit, Output } from '@angular/core';
import { ReplaceValuePipe } from '../../../shared/pipe/replace-value.pipe';
import { HttpService } from '../../../shared/services/http.service';
import { NzModalRef, NzModalService } from 'ng-zorro-antd/modal';
import { NzUploadChangeParam, NzUploadFile } from 'ng-zorro-antd/upload';
@Component({
  selector: 'app-add-program-modal',
  templateUrl: './add-program-modal.component.html',
  styleUrls: ['./add-program-modal.component.scss'],
})
export class AddProgramModalComponent implements OnInit {
  @Input() showModal;
  @Output() hideModal: EventEmitter<boolean> = new EventEmitter<boolean>();
  public confirmModal?: NzModalRef;
  public replaceValue = new ReplaceValuePipe();
  public searchValue = /[?\/\\:[\]*]/gm;

  public showReasonScreen = false;
  public loading = false;
  public selectedProducts = '';

  public isValidReason = true;
  public reason = '';

  public channelData = [];
  public programTypeData = [];
  public clientData = [];
  public channel = '';
  public channelAdded = false;
  public programType = '';
  public client = '';

  public isValidProgram = true;
  public validProgramChar = true;
  public program = '';
  public errorMessage = '';

  public formData = new FormData();
  public isInprogress = false;
  public uploadPercent = 0;
  public customError = '';
  public showUploadError = false;
  public nzFileList: NzUploadFile[] = [];
  public fileList: File[] = [];
  public fileUploaded = false;

  public productOptions = [
    { label: 'AffinityMonitor', value: 'AffinityMonitor', checked: false },
    { label: 'AccessMonitor', value: 'AccessMonitor', checked: false },
  ];

  constructor(private modal: NzModalService, private httpService: HttpService) {}

  ngOnInit(): void {
    this.loadData();
  }

  public loadData(): void {
    this.loading = true;
    this.httpService.getRequest('get_add_program_details').subscribe((result) => {
      if (result.status === 'SUCCESS') {
        this.channelData = result.channelData;
        this.programTypeData = result.programTypeData;
        this.clientData = result.clientData;
        this.loading = false;
      } else if (result.status === 'ERROR') {
        console.log('error', result.message);
      }
    });
  }

  public onCancel(): void {
    this.showModal = false;
    this.confirmModal = this.modal.confirm({
      nzTitle: 'Leave Add Program',
      nzContent: 'Are you sure you want to leave?',
      nzOkText: 'Yes, Leave',
      nzIconType: 'exclamation-circle',
      nzOnOk: () => {
        this.hideModal.emit(false);
        this.showModal = false;
      },
      nzOnCancel: () => {
        this.showModal = true;
      },
    });
  }

  public channelSelected(event): void {
    this.errorMessage = '';
    this.channel = event;
  }
  public programTypeSelected(event): void {
    this.errorMessage = '';
    this.programType = event;
  }
  public clientsSelected(event): void {
    this.errorMessage = '';
    this.client = event;
  }
  public checkProgram(): boolean {
    this.errorMessage = '';
    this.program = this.replaceValue.transform(this.program, true);
    this.isValidProgram = this.program ? this.program.length <= 50 : true;
    this.program
      ? this.program.match(this.searchValue)
        ? (this.validProgramChar = false)
        : (this.validProgramChar = true)
      : (this.validProgramChar = true);
    if (this.isValidProgram && this.validProgramChar && this.program) {
      return true;
    } else {
      return false;
    }
  }
  public fileChanged(files: NzUploadChangeParam): void {
    this.errorMessage = '';
    this.formData = new FormData();
    this.isInprogress = true;
    this.uploadPercent = 5;
    const fileNames = [];
    this.customError = '';
    this.showUploadError = false;
    if (files.type === 'start') {
      files.fileList.forEach((file) => {
        this.fileList.push(file.originFileObj);
      });
    }

    this.showUploadError = this.checkFileType();

    files = undefined;
    this.nzFileList = [];
    this.isInprogress = false;
    this.fileUploaded = !this.showUploadError;
    console.log(this.fileUploaded);
  }
  public checkFileType(): boolean {
    let isValid = false;
    let validFileType = true;
    this.showUploadError = false;
    this.fileList.forEach((file) => {
      const type = file.name.substring(file.name.length - 4, file.name.length);
      if (type === '.csv') {
        isValid = true;
        file['isValid'] = isValid;
        this.customError = '';
      } else {
        isValid = false;
        validFileType = false;
        file['isValid'] = isValid;
        this.showUploadError = true;
        this.isInprogress = false;
      }
    });
    return !validFileType;
  }
  public deleteFile(isValid = false): void {
    if (isValid) {
      this.confirmModal = this.modal.confirm({
        nzTitle: 'Delete Uploaded File?',
        nzContent: 'Are you sure you want to delete this file?',
        nzOkText: 'Yes, Delete',
        nzIconType: 'exclamation-circle',
        nzOnOk: () => {
          this.fileList = [];
          this.showUploadError = false;
          this.customError = '';
          this.fileUploaded = false;
        },
        nzOnCancel: () => {},
      });
    } else {
      this.fileList = [];
      this.showUploadError = false;
      this.customError = '';
      this.fileUploaded = false;
    }
  }

  public disableSubmit(): boolean {
    const disable =
      !(this.isValidProgram && this.validProgramChar && this.program) ||
      this.channel === null ||
      (this.programType === null
        ? true
        : this.programType === 'Custom'
        ? this.client.length < 1
        : false) ||
      !this.fileUploaded || !this.selectedProducts;
    return disable;
  }

  public checkReason(): void {
    this.reason = this.replaceValue.transform(this.reason, true);
    this.isValidReason = this.reason ? this.reason.length <= 2000 : true;
  }
  public disableEnter(event): void {
    if (!this.reason && event.key == 'Enter') {
      event.preventDefault();
    }
  }
  public backClicked(): void {
    this.reason = '';
    this.showReasonScreen = false;
  }

  public submitRequest(): void {
    this.showModal = false;
    this.confirmModal = this.modal.confirm({
      nzTitle: 'Add Program',
      nzContent: 'Are you sure you want to add this program?',
      nzOkText: 'Add',
      nzIconType: 'exclamation-circle',
      nzOnOk: () => {
        if(this.programType === 'Standard'){
          this.formData = new FormData();
          this.formData.append('reason', this.reason);
          this.formData.append('program', this.program);
          this.formData.append('channel', this.channel);
          this.formData.append('product', this.selectedProducts);
          if (this.fileUploaded) {
            this.formData.append('template_file', this.fileList[0]);
          }
          this.httpService
          .postRequest('add_standard_program', this.formData, true)
          .subscribe((data) => {
        // this.httpService.getRequest('add_standard_program').subscribe((data) => {
          if (data['status'] === 'SUCCESS') {
            this.hideModal.emit(false);
          } else if (data['status'] === 'ERROR') {
            this.errorMessage = data['message'];
            this.showModal = true;
            this.showReasonScreen = false;
            this.program = '';
            this.programType = null;
            this.channel = null;
            this.client = '';
            this.reason = '';
            this.deleteFile(false);
          }
        });
        } else {
          this.formData = new FormData();
          this.formData.append('custom_program', this.program);
          if (this.fileUploaded) {
            this.formData.append('template_file', this.fileList[0]);
          }
          this.formData.append('channel_name', this.channel);
          this.formData.append('channel_id', '');
          this.formData.append('description', '');
          this.formData.append('reason', this.reason);
          this.formData.append('product', this.selectedProducts);
          this.formData.append('client', this.client);
          this.formData.append('role', 'admin');
          this.httpService
          .postRequest('add_custom_program', this.formData, true)
          .subscribe((data) => {
        // this.httpService.getRequest('add_custom_program').subscribe((data) => {
          if (data['status'] === 'SUCCESS') {
            this.hideModal.emit(false);
          } else if (data['status'] === 'ERROR') {
            this.errorMessage = data['message'];
            this.showModal = true;
            this.showReasonScreen = false;
            this.program = '';
            this.programType = null;
            this.channel = null;
            this.client = '';
            this.reason = '';
            this.deleteFile(false);
          }
        });
        }
      },
      nzOnCancel: () => {
        this.showModal = true;
      },
    });
  }
  public productSelected(products): void {
    let checkedFlag = 0;
    this.selectedProducts = '';
    products.forEach((product) => {
      if (product.checked) {
        this.selectedProducts = product.value === 'AffinityMonitor' ? 'AFM' : 'ACM';
        checkedFlag += 1;
      }
    });
    if (checkedFlag === 2) {
      this.selectedProducts = 'AFM,ACM';
    }
  }
}
