import { Component, OnInit, Input, Output, EventEmitter } from '@angular/core';
interface TableBody {
  field: number;
  fieldName: string;
  fieldFullName: string;
  type: string;
  values: string;
  description: string;
}

interface TableHeader {
  value: string;
}

@Component({
  selector: 'app-preview-modal',
  templateUrl: './preview-modal.component.html',
  styleUrls: ['./preview-modal.component.scss'],
})
export class PreviewModalComponent implements OnInit {
  @Input() previewReport;
  @Input() modalTitle: string;
  @Input() tableData;
  @Output() hidePreview = new EventEmitter<boolean>();

  listOfHeaders: TableHeader[];
  listOfData: TableBody[];
  public sortOrder = 'ascend';

  constructor() {}
  ngOnInit(): void {
    this.listOfHeaders = this.tableData.thead;
    this.listOfData = this.tableData.tbody;
  }

  public sortData(column: string): void {
    if (this.sortOrder === 'ascend') {
      this.listOfData.sort((a, b) => (a[column] < b[column] ? 1 : -1));
      this.sortOrder = 'descend';
    } else {
      this.listOfData.sort((a, b) => (a[column] > b[column] ? 1 : -1));
      this.sortOrder = 'ascend';
    }
  }

  public handleCancel(): void {
    this.hidePreview.emit(false);
  }
}
