import { NgModule } from '@angular/core';
import { CommonModule } from '@angular/common';
import {SubscriptionsComponent} from './subscriptions.component';
import { NzTableModule } from 'ng-zorro-antd/table';
import { NzCheckboxModule } from 'ng-zorro-antd/checkbox';
import { FormsModule } from '@angular/forms';
import { NzIconModule } from 'ng-zorro-antd/icon';
import { NzGridModule } from 'ng-zorro-antd/grid';
import { SharedModule } from 'src/app/shared/shared.module';
import { NzSpaceModule } from 'ng-zorro-antd/space';
import { NzLayoutModule } from 'ng-zorro-antd/layout';
import { NzInputModule } from 'ng-zorro-antd/input';
import { NzButtonModule } from 'ng-zorro-antd/button';
import { DatePipe } from '@angular/common';
import { NzEmptyModule } from 'ng-zorro-antd/empty';
import { NzMenuModule } from 'ng-zorro-antd/menu';
import { SubscriptionTableComponent } from './shared/subscription-table/subscription-table.component';
import { NzPopoverModule } from 'ng-zorro-antd/popover';
import { NzDropDownModule } from 'ng-zorro-antd/dropdown';
import { NzSpinModule } from 'ng-zorro-antd/spin';

@NgModule({
  declarations: [SubscriptionsComponent, SubscriptionTableComponent],
  imports: [
    CommonModule,
    NzTableModule,
    NzCheckboxModule,
    FormsModule,
    NzIconModule,
    NzGridModule,
    SharedModule,
    NzSpaceModule,
    NzLayoutModule,
    NzInputModule,
    NzButtonModule,
    NzEmptyModule,
    NzMenuModule,
    NzPopoverModule,
    NzDropDownModule,
    NzSpinModule,
  ],
  exports: [SubscriptionsComponent],
  providers: [DatePipe],
})

export class SubscriptionsModule {}
