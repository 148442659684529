<nz-table
  #dataChangesTable
  [nzData]="businessData"
  class="data-changes-table"
  nzTableLayout="fixed"
  [nzShowPagination]="true"
  [nzPageSize]="8"
  [nzShowQuickJumper]="true"
  [nzNoResult]="noRecords"
  [(nzPageIndex)]="pageIndex"
  [nzLoading]="loading"
>
  <thead>
    <tr>
      <th>User</th>
      <th nzCustomFilter class="client-filter">
        <nz-filter-trigger [nzDropdownMenu]="customFilterClient" [(nzVisible)]="showFilterClient">
          <i
            nz-icon
            nzType="filter"
            nzTheme="fill"
            [ngClass]="highlightFilterClient() ? 'highlight' : ''"
          ></i>
        </nz-filter-trigger>
        <nz-dropdown-menu #customFilterClient="nzDropdownMenu">
          <div class="dropdown-container">
            <div class="ant-table-filter-dropdown">
              <ul nz-menu *ngFor="let item of client">
                <li nz-menu-item>
                  <label nz-checkbox [(ngModel)]="item.value"> {{ item.text }} </label>
                </li>
              </ul>
            </div>
            <div class="button-wrapper">
              <a (click)="resetClient()">Reset</a>&nbsp;
              <button
                nz-button
                nzType="primary"
                class="app-primary-button button-class"
                (click)="filterClient()"
              >
                OK
              </button>
            </div>
          </div>
        </nz-dropdown-menu>

        Client
      </th>
      <th nzCustomFilter class="file-filter">
        <nz-filter-trigger [(nzVisible)]="visible" [nzDropdownMenu]="menu">
          <i nz-icon nzType="search"></i>
        </nz-filter-trigger>
        <nz-dropdown-menu #menu="nzDropdownMenu">
          <div class="ant-table-filter-dropdown">
            <div class="search-box">
              <app-search-bar
                [searchParameter]="searchParamData"
                (searchValue)="filterOnSearch($event)"
                (inputKey)="filterOnSearch($event)"
                (noInput)="resetSearch($event)"
                (resetSearch)="resetSearch($event)"
                (noResultFound)="formatOnNoresult($event)"
                [(inputValue)]="searchInput"
              ></app-search-bar>
            </div>
          </div>
        </nz-dropdown-menu>
        File Title
      </th>
      <th
        [nzSortOrder]="sortColumns.uploaded.sortOrder"
        (click)="sortData('uploaded_date')"
        [nzSortDirections]="sortColumns.uploaded.sortDirection"
      >
        Uploaded On
      </th>
      <th
        [nzSortOrder]="sortColumns.modified.sortOrder"
        (click)="sortData('modified_date')"
        [nzSortDirections]="sortColumns.modified.sortDirection"
      >
        Modified On
      </th>
      <th nzCustomFilter class="status-filter">
        <nz-filter-trigger [nzDropdownMenu]="customFilterStatus" [(nzVisible)]="showFilterStatus">
          <i
            nz-icon
            nzType="filter"
            nzTheme="fill"
            [ngClass]="highlightFilterStatus() ? 'highlight' : ''"
          ></i>
        </nz-filter-trigger>
        <nz-dropdown-menu #customFilterStatus="nzDropdownMenu">
          <div class="dropdown-container">
            <div class="ant-table-filter-dropdown">
              <ul nz-menu *ngFor="let item of status">
                <li nz-menu-item>
                  <label nz-checkbox [(ngModel)]="item.value"> {{ item.text }} </label>
                </li>
              </ul>
            </div>
            <div class="button-wrapper">
              <a (click)="resetStatus()">Reset</a>&nbsp;
              <button
                nz-button
                nzType="primary"
                class="app-primary-button button-class"
                (click)="filterStatus()"
              >
                OK
              </button>
            </div>
          </div>
        </nz-dropdown-menu>
        Status
      </th>
      <th>Action</th>
    </tr>
  </thead>
  <tbody>
    <tr *ngFor="let data of dataChangesTable.data">
      <td class="row-data">
        <a (click)="generateMailData(data['email'], data['user'], data['file_title'])">{{
          data.user
        }}</a>
      </td>
      <td class="row-data">{{ data.client }}</td>
      <td class="row-data file-title">{{ data.file_title }}</td>
      <td class="row-data">{{ getDate(data.uploaded_date) }}</td>
      <td class="row-data">{{ getDate(data.modified_date) }}</td>
      <td class="row-data">
        <span *ngIf="data.status === 'Pending'" class="pending"
          ><i nz-icon nzType="clock-circle" nzTheme="outline"></i
        ></span>
        <span *ngIf="data.status === 'Approved'" class="approved"
          ><i nz-icon nzType="check-circle" nzTheme="fill"></i
        ></span>
        <span *ngIf="data.status === 'Modified & Approved'" class="modified"
          ><i nz-icon nzType="check-circle" nzTheme="outline"></i
        ></span>
        <span *ngIf="data.status === 'Rejected'" class="rejected"
          ><i nz-icon nzType="minus-circle" nzTheme="fill"></i
        ></span>
        &nbsp;{{ data.status }}
      </td>
      <td class="review" (click)="reviewClicked(data)">
        <i nz-icon nzType="file-search" nzTheme="outline"></i>&nbsp;&nbsp;Review
      </td>
<!--      <td *ngIf="data.status === 'Approved'">-->
<!--        <div class="review-disabled">-->
<!--          <i nz-icon nzType="file-search" nzTheme="outline"></i>&nbsp;&nbsp;Review-->
<!--        </div>-->
<!--      </td>-->
    </tr>
  </tbody>

  <ng-template #noRecords>
    <div class="no-requests">No requests available</div>
  </ng-template>
</nz-table>

<app-data-review-modal
  *ngIf="showReviewModal"
  [showReviewModal]="showReviewModal"
  (hideReviewModal)="onReviewClose($event)"
  [id] = "reviewId"
  (reloadPage)="loadData()"
  [dataChangeStatus]="dataChangeStatus"
>
</app-data-review-modal>
