import { Component, EventEmitter, Input, OnInit, Output } from '@angular/core';
import { DropdownComponent } from '../../shared/components/dropdown/dropdown.component';
import { ReplaceValuePipe } from '../../shared/pipe/replace-value.pipe';

@Component({
  selector: 'app-business-justification-modal',
  templateUrl: './business-justification-modal.component.html',
  styleUrls: ['./business-justification-modal.component.scss'],
})
export class BusinessJustificationModalComponent implements OnInit {
  @Input() showModal = false;
  @Input() mode = 'save';
  @Input() editAction: boolean;
  @Input() fileData;
  @Input() uploadedFiles;
  @Input() uploadWindow;

  @Output() hideModal: EventEmitter<boolean> = new EventEmitter<boolean>();
  @Output() reloadPage: EventEmitter<boolean> = new EventEmitter<boolean>();
  @Output() nextMoveEdit: EventEmitter<object> = new EventEmitter<object>();

  public description = '';
  public isValidDesc = true;
  public displayReason = false;
  public enableButton = false;
  public otherReason = false;
  public isNewData = false;

  public businessJustification = [];

  public replaceValue = new ReplaceValuePipe();

  public data = [
    'Incorrect Data/Uploaded By mistake',
    'Missing Data',
    'Revised/Updated Data',
    'New Data is available',
    'Usage/Compliance Issues',
    'Other',
  ];

  constructor() {}

  ngOnInit(): void {}

  public checkDescription(): void {
    this.isValidDesc = this.description ? this.description.length < 101 : true;
    this.description = this.replaceValue.transform(this.description, true);
    this.description
      ? this.description.length > 0
        ? this.isValidDesc
          ? (this.enableButton = true)
          : (this.enableButton = false)
        : (this.enableButton = false)
      : (this.enableButton = false);
  }

  public disableEnter(event): void {
    if (!this.description && event.key == 'Enter') {
      event.preventDefault();
    }
  }

  public okClicked(): void {
    if (this.editAction === true) {
      this.nextMoveEdit.emit({
        nextMove: true , businessJustification: this.businessJustification, reason: this.description, isNewData: this.isNewData
      });
      this.hideModal.emit(false);
    } else {
      this.nextMoveEdit.emit({nextMove: false , businessJustification: this.businessJustification, reason: this.description});
      this.hideModal.emit(false);
    }
  }

  public onCancel(): void {
    this.hideModal.emit(false);
  }

  public displayT(event): void {
    this.displayReason = false;
    this.enableButton = false;
    this.isNewData = false;
    if (event === 'Other' || event === 'Usage/Compliance Issues') {
      this.displayReason = true;
      this.otherReason = true;
      this.checkDescription();
    } else {
      this.otherReason = false;
      if (event) {
        this.enableButton = true;
      } else {
        this.enableButton = false;
      }
    }

    if (event === 'New Data is available') {
      this.isNewData = true;
    }
    event ? this.businessJustification = [event] : this.businessJustification = [];
  }

  public exceedsLimit(): boolean {
    if (!this.editAction) {
      return this.editAction;
    } else if (this.businessJustification[0] === 'Incorrect Data/Uploaded By mistake'
      || this.businessJustification[0] === 'Missing Data'
      || this.businessJustification[0] === 'Revised/Updated Data') {
      return this.fileData.edit_count > 2;
    }
    return false;
  }

  public windowClosed(): boolean {
    return this.isNewData && this.uploadWindow !== 'open';
  }

  public getDescription(): string {
    let description = '';

    switch (this.businessJustification[0]) {
      case 'Incorrect Data/Uploaded By mistake':
        description = 'Data present has issues such as incorrect business rules/filters used to extract it.';
        break;
      case 'Missing Data':
        description = 'Data present is incomplete/missing additional data points.';
        break;
      case 'Revised/Updated Data':
        description = 'A newer/revised version of the data is available.';
        break;
      case 'New Data is available':
        description = 'If new/extra data is available on top of the existing data, kindly upload it as a new data feed.' +
          '<b> Please note that on selecting new data to be uploaded, the upload file flow will open and create a new feed based on the data uploaded</b>';
        break;
      case 'Usage/Compliance Issues':
        description = 'Usage of the data by Affinity Monitor incurs compliance or other issues.';
        break;
    }
    return description;
  }
}
