<nz-layout>
  <nz-content>
    <div class="space-align-container">
      <div class="tab-header-wrapper">
      <div nz-typography class="tab-header">
        Data Changes
      </div>
      <div class="export-button"><button nz-button nzType="primary" class="app-primary-button" (click)="exportClicked()" [disabled]="!exportUrl">Export</button></div>
      </div>
        <div class="tabs">
        <nz-tabset>
          <nz-tab [nzTitle]="dataChanges" (nzClick) ="customProgClicked = false">
            <ng-template nz-tab><app-data-changes (reviewed)="getPendingCount($event)"
              (exportUrl)="getExportUrl($event)"></app-data-changes></ng-template>
          </nz-tab>
        </nz-tabset>
        <ng-template #dataChanges>
          <span [innerHTML]="pendingDataChanges"></span>
        </ng-template>
      </div>
    </div>
  </nz-content>
</nz-layout>
