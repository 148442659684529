import { Component, EventEmitter, OnDestroy, OnInit, Output } from '@angular/core';
import { HttpService } from '../../../shared/services/http.service';
import { DatePipe } from '@angular/common';
import { AppState } from '../../../shared/services/app-state';
import { Subscription } from 'rxjs';

@Component({
  selector: 'app-custom-program',
  templateUrl: './custom-program.component.html',
  styleUrls: ['./custom-program.component.scss'],
})
export class CustomProgramComponent implements OnInit, OnDestroy {

  @Output() searchParamData: EventEmitter<object> = new EventEmitter<object>();
  @Output() exportUrl: EventEmitter<string> = new EventEmitter<string>();

  public programData = [];
  public status = [];
  public channel = [];
  public client = [];
  public loading = false;
  public noDataMessage = 'There are no programs to display.';
  public pageIndex = 1;
  public activePendingRegex = /Active-Pending/;
  public retiredPendingRegex = /Retired-Pending/;
  public showFilterStatus = false;
  public showFilterChannel = false;
  public showFilterClient = false;

  public search = '';
  public sortOrder = 'descend';
  public sortDirection = ['ascend', 'descend'];

  public payload = {
    key: 'custom',
    filters: {
      channel: [],
      status: [],
      client: [],
      date: 'descend',
    },
    search: '',
  };

  public showReviewModal = false;
  public programStatus = '';
  public customProgramId = '';

  private appStateSubscription: Subscription;

  constructor(
    private httpService: HttpService,
    private datePipe: DatePipe,
    private appState: AppState,
  ) {
    this.appStateSubscription = AppState.appState.subscribe((val) => {
      if (val && !val.showDropdown) {
        this.showFilterChannel = false;
        this.showFilterStatus = false;
        this.showFilterClient = false;
      }
    });
  }

  ngOnInit(): void {
    this.loadData();
  }

  async loadData(): Promise<void> {
    this.loading = true;
    await this.httpService.postRequest('get_programs_data', this.payload).toPromise().then((data) => {
    // await this.httpService.getRequest('get_programs_data').toPromise().then((data) => {
      this.programData = data.data;
      this.status = data.status;
      this.channel = data.channel;
      this.client = data.client;
      this.exportUrl.emit(data.export_url);
      this.searchParamData.emit(data.search_keywords);
      this.loading = false;
    });
  }

  public highlightFilterStatus(): boolean {
    return this.status.some((status) => status.value === true);
  }
  public highlightFilterChannel(): boolean {
    return this.channel.some((channel) => channel.value === true);
  }
  public highlightFilterClient(): boolean {
    return this.client.some((client) => client.value === true);
  }

  public resetStatus(): void {
    this.status.forEach((status) => {
      status.value = false;
    });
    this.filter(false);
  }

  public resetChannel(): void {
    this.channel.forEach((channel) => {
      channel.value = false;
    });
    this.filter(false);
  }
  public resetClient(): void {
    this.client.forEach((client) => {
      client.value = false;
    });
    this.filter(false);
  }

  public filter(dateModified): void {
    this.pageIndex = 1;
    this.showFilterStatus = false;
    this.showFilterChannel = false;
    this.showFilterClient = false;
    const statusTemp = [];
    const channelTemp = [];
    const clientTemp = [];
    this.status.forEach((s) => {
      if (s.value) {
        statusTemp.push(s.text);
      }
    });
    this.channel.forEach((ch) => {
      if (ch.value) {
        channelTemp.push(ch.text);
      }
    });
    this.client.forEach((c) => {
      if (c.value) {
        clientTemp.push(c.text);
      }
    });
    if (dateModified) {
      if (this.sortOrder === 'ascend') {
        this.sortOrder = 'descend';
      } else {
        this.sortOrder = 'ascend';
      }
    }
    this.payload = {
      key: 'custom',
      filters: {
        status: statusTemp,
        channel: channelTemp,
        client: clientTemp,
        date: this.sortOrder,
      },
      search: this.search,
    };
    this.loadData();
  }

  public getDate(date): string {
    try {
      return this.datePipe.transform(new Date(date), 'MM/d/yy h:mm a');
    } catch (e) {
      return 'invalid date';
    }
  }

  public downloadFile(url, target): void {
    window.open(url, target);
  }
  public openChange(event): void {
    this.appState.setGlobalState('showDropdown', event);
  }
  ngOnDestroy(): void {
    this.appStateSubscription.unsubscribe();
  }
  public reviewClicked(data): void {
    this.showReviewModal = true;
    this.customProgramId = data.id;
    this.programStatus = data.status;
  }
  public onReviewClose(event): void {
    this.showReviewModal = event;
    this.loadData();
  }
}
