import {NgModule} from '@angular/core';
import {CommonModule} from '@angular/common';

import {ClientSelectionComponent} from './client-selection.component';
import {SharedModule} from '../shared/shared.module';
import {NzButtonModule} from 'ng-zorro-antd/button';
import {NzInputModule} from 'ng-zorro-antd/input';
import {FormsModule} from '@angular/forms';
import {NzSpinModule} from 'ng-zorro-antd/spin';


@NgModule({
  declarations: [ClientSelectionComponent],
  imports: [
    CommonModule,
    SharedModule,
    NzButtonModule,
    NzInputModule,
    FormsModule,
    NzSpinModule
  ]
})
export class ClientSelectionModule {
}
