import { Component, OnDestroy, OnInit } from '@angular/core';

@Component({
  selector: 'app-client-management',
  templateUrl: './client-management.component.html',
  styleUrls: ['./client-management.component.scss']
})
export class ClientManagementComponent implements OnInit, OnDestroy {

  constructor() { }

  ngOnInit(): void {
    window.localStorage.setItem('pageType', 'client');
  }

  ngOnDestroy(): void {
    window.localStorage.removeItem('pageType');
  }

}
