<nz-modal
  nzTitle="Process details"
  [(nzVisible)]="showModal"
  [nzWidth]="'800px'"
  [nzWrapClassName]="'modal-wrapper'"
  [nzMaskClosable]="false"
  (nzOnCancel)="onCancel()"
  [nzFooter]="footer"
>
  <ng-container *nzModalContent>
    <div class="process-content">
      <div class="data-wrapper" *ngIf="!loading">
        <div class="heading">Process Details</div>
        <div class="filter">
          <app-dropdown
            [data]="filterTable"
            [isMultiple]="true"
            (selectedValue)="selectedValue($event)"
            [placeHolder]="'All'"
            nz-popover=""
            [nzPopoverContent]="filterPayload.length > 0 ? filterPayload : ['All']"
          >
          </app-dropdown>
        </div>
        <div class="data">
          <div nz-row>
            <div nz-col nzSpan="8">
              <span class="header">Program Name:</span
              ><span class="inner-data" [title]="processDetails.programName" *ngIf="processDetails.programName">{{ processDetails.programName }}</span>
              <span class="inner-data-null" *ngIf="!processDetails.programName">-</span>
            </div>
            <div nz-col nzSpan="8">
              <span class="header">Channel Name:</span
              ><span class="inner-data" [title]="processDetails.channelName" *ngIf="processDetails.channelName">{{ processDetails.channelName }}</span>
              <span class="inner-data-null" *ngIf="!processDetails.channelName">-</span>

            </div>
            <div nz-col nzSpan="8">
              <span class="header">File Size:</span
              ><span class="inner-data" [title]="processDetails.fileSize" *ngIf="processDetails.fileSize">{{ processDetails.fileSize }}</span>
              <span class="inner-data-null" *ngIf="!processDetails.fileSize">-</span>

            </div>
          </div>
          <div nz-row>
            <div nz-col nzSpan="8">
              <span class="header">File Name:</span
              ><span class="inner-data" [title]="processDetails.fileName" *ngIf="processDetails.fileName">{{ processDetails.fileName }}</span>
              <span class="inner-data-null" *ngIf="!processDetails.fileName">-</span>
              <i
                *ngIf="processDetails.processTransactionId"
                nz-icon
                nzType="download"
                nzTheme="outline"
                class="download-button"
                (click)="downloadFile(processDetails.fileNameUrl, '')"
              ></i
              ><i
                *ngIf="!processDetails.processTransactionId"
                nz-icon
                nzType="download"
                nzTheme="outline"
                class="download-button-disabled"
              ></i>
            </div>
            <div nz-col nzSpan="8">
              <span class="header">File Id:</span
              ><span class="inner-data" [title]="processDetails.fileId" *ngIf="processDetails.fileId">{{ processDetails.fileId }}</span>
              <span class="inner-data-null" *ngIf="!processDetails.fileId">-</span>
            </div>
            <div nz-col nzSpan="8">
              <span class="header">Process Transaction Id:</span
              ><span class="inner-data" [title]="processDetails.processTransactionId" *ngIf="processDetails.processTransactionId">{{ processDetails.processTransactionId }}</span>
              <span class="inner-data-null" *ngIf="!processDetails.processTransactionId">-</span>
            </div>
          </div>
          <div nz-row>
            <div nz-col nzSpan="8">
              <span class="header">File Log:</span
              ><i nz-icon *ngIf="processDetails.fileLogUrl" nzType="download" nzTheme="outline" class="download-button" (click)="downloadFile(processDetails.fileLogUrl, '')"></i>
              <i
                *ngIf="!processDetails.fileLogUrl"
                nz-icon
                nzType="download"
                nzTheme="outline"
                class="download-button-disabled"
              ></i>
            </div>
          </div>
        </div>
        <div class="table-data">
          <nz-table
            #processTable
            [nzData]="tableData"
            [nzNoResult]="noResults"
            [nzScroll]="{ y: '330px' }"
          >
            <thead>
              <tr>
                <th nzWidth="14%">Step</th>
                <th nzWidth="10%">Result</th>
                <th nzWidth="16%">Status</th>
                <th nzWidth="16%">Details</th>
                <th nzWidth="14%">Start Time</th>
                <th nzWidth="14%">Duration</th>
                <th nzWidth="14%">End Time</th>
              </tr>
            </thead>
            <tbody>
              <tr *ngFor="let data of processTable.data">
                <td>{{ data.step }}</td>
                <td *ngIf="data.result === 'check'" class="result-check">
                  <i nz-icon nzType="check" nzTheme="outline"></i>
                </td>
                <td *ngIf="data.result === 'cross'" class="result-cross">
                  <i nz-icon nzType="close" nzTheme="outline"></i>
                </td>
                <td *ngIf="data.result === 'question'" class="result-question">
                  <i nz-icon nzType="question" nzTheme="outline"></i>
                </td>
                <td>{{ data.status }}</td>
                <td>{{ data.details }}</td>
                <td>{{ getDate(data.startTime) }}</td>
                <td>{{ data.duration }}</td>
                <td>{{ getDate(data.endTime) }}</td>
              </tr>
            </tbody>
          </nz-table>
          <ng-template #noResults>
            <p>No results found.</p>
          </ng-template>
        </div>
      </div>
      <div class="spinner" *ngIf="loading">
        <nz-spin nzSimple></nz-spin>
      </div>
    </div>
  </ng-container>
</nz-modal>
<ng-template #footer>
  <button nz-button nzType="default" class="app-default-button" (click)="onCancel()">Cancel</button>
</ng-template>
