import { NgModule } from '@angular/core';
import { CommonModule } from '@angular/common';
import { UploadDataComponent } from './upload-data.component';
import { NzSpaceModule } from 'ng-zorro-antd/space';
import { NzLayoutModule } from 'ng-zorro-antd/layout';
import { SharedModule } from 'src/app/shared/shared.module';
import { NzButtonModule } from 'ng-zorro-antd/button';
import { NzIconModule } from 'ng-zorro-antd/icon';
import { NzGridModule } from 'ng-zorro-antd/grid';
import { TemplateModalComponent } from './template-modal/template-modal.component';
import { NzModalModule } from 'ng-zorro-antd/modal';
import { NzDividerModule } from 'ng-zorro-antd/divider';
import { NzCardModule } from 'ng-zorro-antd/card';
import { NzPopoverModule } from 'ng-zorro-antd/popover';
import { UploadFileModalComponent } from './upload-file-modal/upload-file-modal.component';
import { NzBadgeModule } from 'ng-zorro-antd/badge';
import { NzUploadModule } from 'ng-zorro-antd/upload';
import { NzCollapseModule } from 'ng-zorro-antd/collapse';
import { NzCheckboxModule } from 'ng-zorro-antd/checkbox';
import { NzInputModule } from 'ng-zorro-antd/input';
import { FormsModule } from '@angular/forms';
import { NzDatePickerModule } from 'ng-zorro-antd/date-picker';
import { NzProgressModule } from 'ng-zorro-antd/progress';
import { NzSpinModule } from 'ng-zorro-antd/spin';
import { NzTableModule } from 'ng-zorro-antd/table';
import { NzRadioModule } from 'ng-zorro-antd/radio';
import { NzNotificationModule } from 'ng-zorro-antd/notification';
import { BusinessJustificationModalComponent } from './business-justification-modal/business-justification-modal.component';
import { UploadOptionsComponent } from './upload-file-modal/upload-options/upload-options.component';
import { NzListModule } from 'ng-zorro-antd/list';
import { AddCustomProgramComponent } from './upload-file-modal/add-custom-program/add-custom-program.component';
import { NzPipesModule } from 'ng-zorro-antd/pipes';
import { FilePreviewModalComponent } from './file-preview-modal/file-preview-modal.component';
import { FilePreviewComponent } from './file-preview-modal/file-preview/file-preview.component';
import { NzSelectModule } from 'ng-zorro-antd/select';

@NgModule({
  declarations: [
    UploadDataComponent,
    TemplateModalComponent,
    UploadFileModalComponent,
    BusinessJustificationModalComponent,
    UploadOptionsComponent,
    AddCustomProgramComponent,
    FilePreviewModalComponent,
    FilePreviewComponent,
  ],
  imports: [
    CommonModule,
    NzLayoutModule,
    NzSpaceModule,
    SharedModule,
    NzButtonModule,
    NzIconModule,
    NzGridModule,
    NzModalModule,
    NzDividerModule,
    NzPopoverModule,
    NzBadgeModule,
    NzUploadModule,
    NzCollapseModule,
    NzCheckboxModule,
    NzInputModule,
    FormsModule,
    NzDatePickerModule,
    NzCardModule,
    NzProgressModule,
    NzSpinModule,
    NzTableModule,
    NzRadioModule,
    NzNotificationModule,
    NzListModule,
    NzPipesModule,
    NzSelectModule
  ],
  exports: [UploadDataComponent],
})
export class UploadDataModule {}
