import { NgModule } from '@angular/core';
import { CommonModule } from '@angular/common';
import { NavigationComponent } from './navigation.component';
import { DownloadReportsModule } from '../download-reports/download-reports.module';
import { NzMenuModule } from 'ng-zorro-antd/menu';
// import { ActivityLogModule } from '../admin/activity-log/activity-log.module';
import { UploadDataModule } from '../upload-data/upload-data.module';
import { SubscriptionsModule } from '../subscriptions/subscriptions.module';
import { AffexModule } from '../affex/affex.module';

@NgModule({
  declarations: [NavigationComponent],
  imports: [CommonModule, DownloadReportsModule, NzMenuModule, UploadDataModule, SubscriptionsModule, AffexModule],
  exports: [NavigationComponent],
})
export class NavigationModule {}
