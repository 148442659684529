import { Injectable } from '@angular/core';

@Injectable({
  providedIn: 'root'
})
export class UserDetailsService {

  userDetails = {};

  setUserDetails(userData: any) {
    this.userDetails = userData;
  }

  getUserDetails() {
    return this.userDetails;
  }

  getClientName() {
    return this.userDetails['client'];
  }

  clearUserDetails() {
    this.userDetails = {};
    return this.userDetails;
  }
}
