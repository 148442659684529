<div class="upload-file-wrapper">
  <nz-modal [(nzVisible)]="showModal" (nzOnCancel)="onCancel()" [nzWidth]="modalWidth" class="template-modal-header"
    [nzClosable]="!disableButton()" [nzMaskClosable]="false" [nzKeyboard]="!isInprogress"
    [nzBodyStyle]="{ height: '550px', 'overflow-y': 'auto' }">
    <div *nzModalTitle>
      {{ getTitle() }}
    </div>
    <ng-container *nzModalContent>
      <div style="padding: 24px" *ngIf="!showSteps">
        <app-upload-options [(selectedOption)]="uploadOption"></app-upload-options>
      </div>
      <div style="padding: 24px" *ngIf="showSteps">
        <!-- Step Navigation -->
        <div nz-row [nzGutter]="[4, 0]">
          <div nz-col nzSpan="6">
            <div [ngClass]="
              activeStep === 1
                ? 'pointer'
                : fileList.length > 0
                ? 'pointer-completed'
                : 'pointer-inactive'
            ">
              <div class="title">
                <nz-badge nzStandalone [nzCount]="1" [nzStyle]="
                  activeStep === 1
                    ? { backgroundColor: '#FFFFFF', color: '#2D535F' }
                    : fileList.length > 0
                    ? { backgroundColor: '#2D8B93', color: '#FFFFFF' }
                    : { backgroundColor: '#716E79', color: '#FFFFFF' }
                "></nz-badge>
                &nbsp;Upload File(s)
              </div>
            </div>
          </div>
          <div nz-col nzSpan="6">
            <div [ngClass]="
              activeStep === 2
                ? 'pointer'
                : selectedChannels.length > 0
                ? 'pointer-completed'
                : 'pointer-inactive'
            ">
              <div class="title-channel">
                <nz-badge nzStandalone [nzCount]="2" [nzStyle]="
                  activeStep === 2
                    ? { backgroundColor: '#FFFFFF', color: '#2D535F' }
                    : selectedChannels.length > 0
                    ? { backgroundColor: '#2D8B93', color: '#FFFFFF' }
                    : { backgroundColor: '#716E79', color: '#FFFFFF' }
                "></nz-badge>
                &nbsp;Select Channel(s)
              </div>
            </div>
          </div>
          <div nz-col nzSpan="6">
            <div [ngClass]="
              activeStep === 3
                ? 'pointer'
                : originalMode === 'edit' || title
                ? 'pointer-completed'
                : 'pointer-inactive'
            ">
              <div class="title">
                <nz-badge nzStandalone [nzCount]="3" [nzStyle]="
                  activeStep === 3
                    ? { backgroundColor: '#FFFFFF', color: '#2D535F' }
                    : originalMode === 'edit' || title
                    ? { backgroundColor: '#2D8B93', color: '#FFFFFF' }
                    : { backgroundColor: '#716E79', color: '#FFFFFF' }
                "></nz-badge>
                &nbsp; Info
              </div>
            </div>
          </div>

          <!-- Additional details (Checklists): If Live Rep Visits- Activity -->
          <div nz-col nzSpan="6" *ngIf="showCheckListStep">
            <div [ngClass]="
              activeStep === 4
                ? 'pointer'
                : originalMode === 'edit'
                ? 'pointer-completed'
                : 'pointer-inactive'
            ">
              <div class="title">
                <nz-badge nzStandalone [nzCount]="4" [nzStyle]="
                  activeStep === 4
                    ? { backgroundColor: '#FFFFFF', color: '#2D535F' }
                    : originalMode === 'edit'
                    ? { backgroundColor: '#2D8B93', color: '#FFFFFF' }
                    : { backgroundColor: '#716E79', color: '#FFFFFF' }
                "></nz-badge>
                &nbsp; Additional details
              </div>
            </div>
          </div>
        </div>

        <!-- Popup Content -->
        <div *ngIf="!isSubmitted" class="content-wrapper">
          <div *ngIf="activeStep === 1">
            <ng-container *ngTemplateOutlet="Step1"> </ng-container>
          </div>

          <div *ngIf="activeStep === 2">
            <ng-container *ngIf="!showPrevious && !showCustomPage">
              <div nz-row class="title-row">
                <div nz-col nzSpan="12" class="title">Select Channels and Programs</div>
              </div>
              <p *ngIf="uploadOption !== 3 || uploadFileList.length > 0">
                Please select the channels/sub channels descriptive the of data in file to be
                uploaded.
              </p>
              <hr />

              <div *ngIf="!showPrograms">
                <div *ngIf="uploadFileList && uploadFileList.length > 0; else noFiles">
                  <div *ngFor="let file of uploadFileList" class="file-mapping-wrapper">
                    <span class="title file-name" [title]="file.filename">
                      <i nz-icon nzType="file" nzTheme="fill"></i>
                      {{ file.filename | nzEllipsis: 36:'...' }}
                    </span>
                    <span class="link link-wrapper"
                      (click)="showPrograms = true; selectedFile = file; selectedChannels = []">
                      &nbsp; &nbsp;
                      <i class="link" nz-icon nzType="plus-circle" nzTheme="outline" style="font-size: 20px"></i>
                      &nbsp;Add Mapping
                    </span>
                    <div>
                      <span class="program-data" *ngFor="let channel of fileChannel">
                        <span *ngIf="channel.filename === file.filename" [ngClass]="getClass(channel.channelId)"
                          class="selected-program" [title]="channel.program">
                          <span *ngIf="channel.channelId === 1" class="template-icons">
                            <i nz-icon nzType="user" nzTheme="outline" class="temp-1"></i>
                          </span>
                          <span *ngIf="channel.channelId === 2" class="template-icons">
                            <i nz-icon nzType="shopping" nzTheme="fill" class="temp-2"></i>
                          </span>
                          <span *ngIf="channel.channelId === 3" class="template-icons">
                            <i nz-icon nzType="team" nzTheme="outline" class="temp-3"></i>
                          </span>
                          <span *ngIf="channel.channelId === 4" class="template-icons">
                            <i nz-icon nzType="import" nzTheme="outline" class="temp-4"></i>
                          </span>
                          <span *ngIf="channel.channelId === 5" class="template-icons">
                            <i nz-icon nzType="cloud-download" nzTheme="outline" class="temp-5"></i>
                          </span>
                          <span *ngIf="channel.channelId === 6" class="template-icons">
                            <i nz-icon nzType="mail" nzTheme="outline" class="temp-6"></i>
                          </span>
                          <span *ngIf="channel.channelId === 7" class="template-icons">
                            <i nz-icon nzType="solution" nzTheme="outline" class="temp-7"></i>
                          </span>
                          <span *ngIf="channel.channelId === 8" class="template-icons">
                            <i nz-icon nzType="dollar" nzTheme="outline" class="temp-8"></i>
                          </span>
                          {{ channel.program }} &nbsp;
                          <i nz-icon nzType="close-circle" nzTheme="outline" [ngClass]="getClass(channel.channelId)"
                            (click)="deleteMapping(channel)"></i>
                        </span>
                      </span>
                    </div>
                  </div>
                </div>
                <ng-template #noFiles> No files were uploaded. </ng-template>
              </div>

              <div *ngIf="uploadOption === 3 && selectedFile && showPrograms">
                <strong>File Name:</strong> {{ selectedFile.filename }}
              </div>

              <nz-collapse *ngIf="showPrograms">
                <nz-collapse-panel *ngFor="let template of templates" [nzHeader]="headerRef"
                  [nzActive]="template.active" [nzDisabled]="template.disabled" [nzExtra]="extraTpl">
                  <ng-template #headerRef>
                    <span *ngIf="template.template_id == 1" class="template-icons">
                      <i nz-icon nzType="user" nzTheme="outline" class="temp-1"></i>
                    </span>
                    <span *ngIf="template.template_id == 2" class="template-icons">
                      <i nz-icon nzType="shopping" nzTheme="fill" class="temp-2"></i>
                    </span>
                    <span *ngIf="template.template_id == 3" class="template-icons">
                      <i nz-icon nzType="team" nzTheme="outline" class="temp-3"></i>
                    </span>
                    <span *ngIf="template.template_id == 4" class="template-icons">
                      <i nz-icon nzType="import" nzTheme="outline" class="temp-4"></i>
                    </span>
                    <span *ngIf="template.template_id == 5" class="template-icons">
                      <i nz-icon nzType="cloud-download" nzTheme="outline" class="temp-5"></i>
                    </span>
                    <span *ngIf="template.template_id == 6" class="template-icons">
                      <i nz-icon nzType="mail" nzTheme="outline" class="temp-6"></i>
                    </span>
                    <span *ngIf="template.template_id == 7" class="template-icons">
                      <i nz-icon nzType="solution" nzTheme="outline" class="temp-7"></i>
                    </span>
                    <span *ngIf="template.template_id == 8" class="template-icons">
                      <i nz-icon nzType="dollar" nzTheme="outline" class="temp-8"></i>
                    </span>
                    &nbsp; <strong>{{ template.channel }} </strong> &nbsp;
                    <i nz-popover nzPopoverTitle=" {{ template.channel }}" nzPopoverContent="{{ template.info }}"
                      nzPopoverPlacement="rightTop" class="info-button" nz-icon nzType="info-circle" nzTheme="fill"
                      class="info-icon"></i>
                  </ng-template>
                  <div *ngIf="template.template_id == 1" class="program-message">
                    For Live Rep Visit, please ensure that you upload a Target List/Call Plan and
                    field-indicated No-See file (if it exists) in addition to the Activity file
                  </div>
                  <div *ngFor="let program of template.progObj | keyvalue: originalOrder; let i = index"
                    class="program-list">
                    <input type="checkbox" (change)="
                      programUpdated($event, program, template.channel, template.template_id)
                    " [checked]="isProgramChecked(program)"
                      [disabled]="disableChannel(template.template_id, program.key)"
                       />
                    {{ program.key }}
                    <div *ngIf="program.key === 'Email' && isProgramChecked(program)" style="padding-left: 20px;">
                      <div *ngFor="let option of options; let i = index">
                        <label>
                          <input type="radio" [name]="'option'" [value]="option" (click)="onRadioClick(i)" (change)="onsub_program_codeChange($event.target.value)" [checked]="selectedOptionIndex === i"> {{ option }}
                        </label>
                      </div>
                  </div>
                    <div *ngIf="program.key !== 'Email' && isProgramChecked(program)">
                      <input type="hidden" name="alertType" value="null" (change)="onsub_program_codeChangetoNull()" />
                    </div>
                    <span *ngIf="findCustomProgram(program.key)" class="pending">Pending Approval <i nz-icon
                        nzType="clock-circle" nzTheme="fill"></i></span>
                  </div>

                  <div class="link" (click)="
                    addCustomProgramClicked(
                      template.channel,
                      template.progObj,
                      template.template_id
                    )
                  ">
                    <i nz-icon nzType="plus-circle" nzTheme="outline"></i>&nbsp; &nbsp;<span class="add-custom">Add
                      Custom
                      Program</span>
                  </div>
                  <ng-template #contentTemplate>
                    <input nz-input placeholder="Add Custom Program" [(ngModel)]="customProgram" (keyup)="checkTitle()"
                      [ngClass]="isValidProg ? '' : 'error'" />
                    <div class="error-3" *ngIf="!isValidProg">
                      The input value for this fields requires 100 characters. Please enter valid
                      input.
                    </div>
                    <button nz-button nzType="default">Cancel</button><button nz-button nzType="primary">Send
                      Request</button>
                  </ng-template>
                  <ng-template #extraTpl>
                    <span [title]="template.programStr">
                      {{ template.programStr | nzEllipsis: 25:'...' }}
                    </span>
                    &nbsp; &nbsp;
                    <nz-badge [nzStyle]="{ backgroundColor: '#2D8B93', color: '#FFFFFF' }" nzStandalone
                      [nzCount]="getCount(template)"></nz-badge>
                  </ng-template>
                </nz-collapse-panel>
              </nz-collapse>
            </ng-container>
            <ng-container *ngIf="!showPrevious && showCustomPage">
              <app-add-custom-program [channelName]="customChannelName" [programs]="customProgramNames"
                [channelId]="customChannelId" [(commaDelimCheck)]="commaDelimiterCheck"
                [(programExistsCheck)]="programExistsCheck" (enableSubmit)="disableSubmit($event)"
                (customFormData)="addCustomFormData($event)" (customProgramName)="addCustomProgramName($event)">
              </app-add-custom-program>
            </ng-container>
            <!-- TODO: Uncomment below code if copy previous mapping feature is to be implemented again -->
            <!--          <ng-container *ngIf="showPrevious">-->
            <!--            <div nz-row class="title-row">-->
            <!--              <div nz-col nzSpan="12" class="title">Select Previous Upload Data</div>-->
            <!--              <div-->
            <!--                nz-col-->
            <!--                nzSpan="12"-->
            <!--                class="link"-->
            <!--                (click)="showPrevious = false; modalWidth = '624px'"-->
            <!--              >-->
            <!--                <i nz-icon nzType="arrow-left" nzTheme="outline"></i> Return to Channel Selection-->
            <!--              </div>-->
            <!--            </div>-->
            <!--            <p>-->
            <!--              Please use the below templates to upload channel specific files for further-->
            <!--              processing. Contact AffinityMonitor team at AffinityMonitorIndia@zs.com for any-->
            <!--              queries.-->
            <!--            </p>-->
            <!--            <nz-table #previousMapping [nzData]="uploadedFiles" [nzFrontPagination]="false">-->
            <!--              <thead>-->
            <!--                <tr>-->
            <!--                  <th nzWidth="4%"></th>-->
            <!--                  <th nzWidth="20%">File Title</th>-->
            <!--                  <th nzWidth="15%">File Name</th>-->
            <!--                  <th nzWidth="15%">Date Uploaded (DD MON YYYY)</th>-->
            <!--                  <th>Channels / Programs</th>-->
            <!--                </tr>-->
            <!--              </thead>-->
            <!--              <tbody>-->
            <!--                <tr *ngFor="let data of previousMapping.data">-->
            <!--                  <td>-->
            <!--                    <label-->
            <!--                      nz-radio-->
            <!--                      [ngModel]="data.isSelected"-->
            <!--                      (ngModelChange)="selectedChannel(data.upload_file_id)"-->
            <!--                      [disabled]="!data.channel_data || data.channel_data.length < 1"-->
            <!--                    ></label>-->
            <!--                  </td>-->
            <!--                  <td [title]="data.file_info.file_title">-->
            <!--                    {{ (data.file_info.file_title | slice: 0:18) + '...' }}-->
            <!--                  </td>-->
            <!--                  <td [title]="data.files[0]">-->
            <!--                    {{ data.files[0] ? (data.files[0] | slice: 0:15) + '...' : '-' }}-->
            <!--                  </td>-->
            <!--                  <td [title]="data.sent_on">{{ data.sent_on }}</td>-->
            <!--                  <td>-->
            <!--                    <span-->
            <!--                      class="program-list-wrapper"-->
            <!--                      *ngIf="data.displayProgram && data.displayProgram.length > 0"-->
            <!--                    >-->
            <!--                      &lt;!&ndash; use for loop in below span &ndash;&gt;-->
            <!--                      <span class="program-data" *ngFor="let programData of data.displayProgram">-->
            <!--                        <span [ngClass]="getClass(programData.id)">-->
            <!--                          <span *ngIf="programData.id === 1" class="template-icons">-->
            <!--                            <i nz-icon nzType="user" nzTheme="outline" class="temp-1"></i>-->
            <!--                          </span>-->
            <!--                          <span *ngIf="programData.id === 2" class="template-icons">-->
            <!--                            <i nz-icon nzType="shopping" nzTheme="fill" class="temp-2"></i>-->
            <!--                          </span>-->
            <!--                          <span *ngIf="programData.id === 3" class="template-icons">-->
            <!--                            <i nz-icon nzType="team" nzTheme="outline" class="temp-3"></i>-->
            <!--                          </span>-->
            <!--                          <span *ngIf="programData.id === 4" class="template-icons">-->
            <!--                            <i nz-icon nzType="import" nzTheme="outline" class="temp-4"></i>-->
            <!--                          </span>-->
            <!--                          <span *ngIf="programData.id === 5" class="template-icons">-->
            <!--                            <i nz-icon nzType="cloud-download" nzTheme="outline" class="temp-5"></i>-->
            <!--                          </span>-->
            <!--                          <span *ngIf="programData.id === 6" class="template-icons">-->
            <!--                            <i nz-icon nzType="mail" nzTheme="outline" class="temp-6"></i>-->
            <!--                          </span>-->
            <!--                          <span *ngIf="programData.id === 7" class="template-icons">-->
            <!--                            <i nz-icon nzType="solution" nzTheme="outline" class="temp-7"></i>-->
            <!--                          </span>-->
            <!--                          <span *ngIf="programData.id === 8" class="template-icons">-->
            <!--                            <i nz-icon nzType="dollar" nzTheme="outline" class="temp-8"></i>-->
            <!--                          </span>-->
            <!--                          {{-->
            <!--                            programData.name.length > 12-->
            <!--                              ? (programData.name | slice: 0:12) + '...'-->
            <!--                              : programData.name-->
            <!--                          }}-->
            <!--                        </span>-->
            <!--                      </span>-->
            <!--                      <span *ngIf="data.programList.length > 4">-->
            <!--                        <span-->
            <!--                          class="custom-circle"-->
            <!--                          nz-popover-->
            <!--                          nzPopoverTitle="Channels / Programs"-->
            <!--                          [nzPopoverContent]="programList"-->
            <!--                          nzPopoverPlacement="rightTop"-->
            <!--                        >-->
            <!--                          + {{ getChannelCount(data.channel_data) - data.displayProgram.length }}-->
            <!--                        </span>-->

            <!--                        <ng-template #programList>-->
            <!--                          <div class="popover-content-wrapper">-->
            <!--                            <span *ngFor="let channel of data.channel_data">-->
            <!--                              <span *ngFor="let program of channel.programs; let i = index">-->
            <!--                                <div-->
            <!--                                  [ngClass]="getClass(channel.channel_id)"-->
            <!--                                  *ngIf="showProgram(program, data.displayProgram)"-->
            <!--                                >-->
            <!--                                  <span *ngIf="channel.channel_id === 1" class="template-icons">-->
            <!--                                    <i nz-icon nzType="user" nzTheme="outline" class="temp-1"></i>-->
            <!--                                  </span>-->
            <!--                                  <span *ngIf="channel.channel_id === 2" class="template-icons">-->
            <!--                                    <i nz-icon nzType="shopping" nzTheme="fill" class="temp-2"></i>-->
            <!--                                  </span>-->
            <!--                                  <span *ngIf="channel.channel_id === 3" class="template-icons">-->
            <!--                                    <i nz-icon nzType="team" nzTheme="outline" class="temp-3"></i>-->
            <!--                                  </span>-->
            <!--                                  <span *ngIf="channel.channel_id === 4" class="template-icons">-->
            <!--                                    <i nz-icon nzType="import" nzTheme="outline" class="temp-4"></i>-->
            <!--                                  </span>-->
            <!--                                  <span *ngIf="channel.channel_id === 5" class="template-icons">-->
            <!--                                    <i-->
            <!--                                      nz-icon-->
            <!--                                      nzType="cloud-download"-->
            <!--                                      nzTheme="outline"-->
            <!--                                      class="temp-5"-->
            <!--                                    ></i>-->
            <!--                                  </span>-->
            <!--                                  <span *ngIf="channel.channel_id === 6" class="template-icons">-->
            <!--                                    <i nz-icon nzType="mail" nzTheme="outline" class="temp-6"></i>-->
            <!--                                  </span>-->
            <!--                                  <span *ngIf="channel.channel_id === 7" class="template-icons">-->
            <!--                                    <i-->
            <!--                                      nz-icon-->
            <!--                                      nzType="solution"-->
            <!--                                      nzTheme="outline"-->
            <!--                                      class="temp-7"-->
            <!--                                    ></i>-->
            <!--                                  </span>-->
            <!--                                  <span *ngIf="channel.channel_id === 8" class="template-icons">-->
            <!--                                    <i nz-icon nzType="dollar" nzTheme="outline" class="temp-8"></i>-->
            <!--                                  </span>-->
            <!--                                  {{ program }} <br />-->
            <!--                                </div>-->
            <!--                              </span>-->
            <!--                            </span>-->
            <!--                          </div>-->
            <!--                        </ng-template>-->
            <!--                      </span>-->
            <!--                    </span>-->
            <!--                  </td>-->
            <!--                </tr>-->
            <!--              </tbody>-->
            <!--            </nz-table>-->
            <!--          </ng-container>-->
          </div>

          <div *ngIf="activeStep === 3">
            <div nz-row class="title-row">
              <div nz-col nzSpan="12" class="title">Add Additional Information</div>
            </div>
            <div class="input-wrapper">
              <label><strong>{{ allowMultiple ? 'Data Set Title' : 'File Title' }}</strong></label>
              <input nz-input [placeholder]="allowMultiple ? 'Data Set Title' : 'File Title'" [(ngModel)]="title"
                (keyup)="checkTitle()" [ngClass]="isValidTitle ? '' : 'error'" />
              <div class="error-3" *ngIf="!isValidTitle">
                The input value for this fields requires 100 characters. Please enter valid input.
              </div>
            </div>
            <div class="input-wrapper">
              <div nz-row>
                <div nz-col nzSpan="12">
                  <label><strong>Data Period</strong></label>
                  <div>
                    <nz-date-picker [(ngModel)]="startDate" [nzPlaceHolder]="'From'" [nzFormat]="dateFormat"
                      (ngModelChange)="onDateChange($event, 'start')" [nzAllowClear]="true"></nz-date-picker>
                  </div>
                </div>
                <div nz-col nzSpan="12">
                  <label><strong>&nbsp;</strong></label>
                  <div>
                    <nz-date-picker [(ngModel)]="endDate" [nzPlaceHolder]="'To'" [nzFormat]="dateFormat"
                      (ngModelChange)="onDateChange($event, 'end')" [nzAllowClear]="true"></nz-date-picker>
                  </div>
                </div>
              </div>
            </div>
            <div class="input-wrapper">
              <label><strong>Description (Optional)</strong></label>
              <textarea nz-input placeholder="Description" [(ngModel)]="description" (keyup)="checkDescription()"
                [nzAutosize]="{ minRows: 8, maxRows: 8 }" [ngClass]="isValidDesc ? '' : 'error'"></textarea>
              <div class="error-3" *ngIf="!isValidDesc">
                The input value for this fields requires 2000 characters. Please enter valid input.
              </div>
            </div>
            <div class="error-message" *ngIf="duplicateError">
              This file with same details is already uploaded, please upload a different file or
              modify the details.
            </div>
          </div>

          <!-- Additional details - (Earlier this was called Check List) -->
          <div *ngIf="activeStep === 4" class="active-step-4">
            <div nz-row class="title-row">
              <div nz-col nzSpan="12" class="title">Additional details</div>
            </div>

            <ng-container *ngIf="showCheckListStep">
              <div class="input-wrapper">
                <div nz-row>
                  <div nz-col nzSpan="24">
                    <label><strong>Is one representative assigned to a single territory?</strong></label>
                    <div nz-col nzSpan="24" class="row">
                      <nz-radio-group [(ngModel)]="territoryDetails" nzName="territoryDetails">
                        <label nz-radio [nzDisabled]="showUploadError" nzValue="One rep">Yes</label>
                        <label nz-radio [nzDisabled]="showUploadError" nzValue="Multiple rep">No</label>
                      </nz-radio-group>
                    </div>
                  </div>
                </div>
                <div nz-row *ngIf="territoryDetails === 'Multiple rep'" class="row">
                  <div nz-col nzSpan="24">
                    <label>Please select</label>
                  </div>
                  <nz-select [(ngModel)]="positionIdPresent" [nzDisabled]="showUploadError">
                    <nz-option [nzValue]="false" nzLabel="Territory with position id not present in data"></nz-option>
                    <nz-option [nzValue]="true" nzLabel="Territory with position id present in data"></nz-option>
                  </nz-select>
                </div>
              
                <div *ngIf="territoryDetails === 'Multiple rep' && positionIdPresent === false" class="row upload">

                  <div class="upload-info ant-alert-info">
                    <diV>Please re-upload the file with position id</diV>
                    <diV><b>Note</b>: For evaluating representative access, it is necessary to identify actions performed by each individual representative in the sales team.</diV>
                  </div>

                  <ng-container *ngTemplateOutlet="Step1"> </ng-container>
                </div>
              </div>

              <div class="input-wrapper">
                <div nz-row>
                  <div nz-col nzSpan="24">
                    <label><strong>Does this file include Account/HCO calls?</strong></label>
                    <div nz-col nzSpan="24" class="row">
                      <nz-radio-group [(ngModel)]="AccountHCOCalls" nzName="AccountHCOCalls">
                        <label nz-radio nzValue="calls not included">Account/HCO calls are not included</label>
                        <label nz-radio nzValue="calls included">Account/HCO calls included</label>
                      </nz-radio-group>
                    </div>
                  </div>
                </div>
                <div nz-row *ngIf="AccountHCOCalls === 'calls included'">
                  <div nz-col nzSpan="24" class="row">
                    <label>Please describe how to identify Account/HCO calls</label>
                    <div nz-col nzSpan="24">
                      <input nz-input [(ngModel)]="accountHCOCallsDesc" style="width: 50%;" (keyup)="checkAccountHCOCalls()" />
                    </div>
                  </div>
                </div>
              </div>

              <div class="input-wrapper">
                <div nz-row>
                  <div nz-col nzSpan="24">
                    <label><strong>Has the client's territory alignment changed for this time period?</strong></label>
                    <div nz-col nzSpan="24" class="row">
                      <nz-radio-group [(ngModel)]="TerritoryAlignmentChange" nzName="TerritoryAlignmentChange">
                        <label nz-col nzSpan="10" nz-radio [nzDisabled]="territoryAlignmentUploadError" nzValue="change not happened">No</label>
                        <label nz-col nzSpan="10" nz-radio [nzDisabled]="territoryAlignmentUploadError" nzValue="change happened">Yes</label>
                      </nz-radio-group>
                    </div>
                  </div>
                </div>
                <div nz-row class="territory-alignment-change title-row" *ngIf="TerritoryAlignmentChange === 'change happened'">
                  
                  <!-- Start -->

                  <div class="upload-info ant-alert-info">
                    Please upload territory alignment change mapping file.
                  </div>

                  <div class="multi-text">
                    Accepted File Type: .txt. All uploaded files must have the ‘pipe’ delimiter.
                  </div>
                
                
                  <div class="percent-wrapper">
                    File Size: {{ getFileSize(false) }}
                    <nz-progress [nzPercent]="getFileSize(true)" [nzStrokeWidth]="8" [nzShowInfo]="false"
                      [nzStrokeColor]="getFileSize(true) > 100 ? '#b21111' : '#00AA67'"></nz-progress>
                  </div>
                
                  <nz-upload nzType="drag" (nzChange)="territoryAlignmentFilechanged($event)"
                    [nzFileListRender]="fileListRef"
                    *ngIf="!disableTAUpload(); else showSingleFileTA">
                    <p class="ant-upload-text">
                      Drag a file here to upload or
                      <button nz-button nzType="primary" class="app-primary-button">
                        <span class="choose-file">Choose File</span>
                      </button>
                    </p>
                    <ng-template #fileListRef> </ng-template>
                  </nz-upload>
                  
                  <ng-template #showSingleFileTA>
                    <!-- mode === 'edit' &&  -->
                    <div *ngIf="territoryAlignmentFilenames.length === 1">
                      <div nz-row class="title-row file-list" *ngIf="!territoryAlignmentUploadError; else territoryAlignmentInValidTAFile">
                        <div nz-col nzSpan="12" class="title">
                          <i *ngIf="!territoryAlignmentIsInprogress" nz-icon nzType="check-circle" nzTheme="fill" class="check-icon"></i>
                          <span *ngIf="territoryAlignmentFilenames[0]['inProgress']">
                            <nz-spin nzSimple [nzSize]="'small'"></nz-spin>
                          </span>
                          {{ territoryAlignmentFilenames[0].filename }}
                        </div>
                        <div nz-col nzSpan="12" class="link" *ngIf="!territoryAlignmentIsInprogress">
                          <i nz-icon class="delete-button" nzType="delete" nzTheme="outline"
                            (click)="deleteFile('single', true, false, null, 'territoryAlignment')"></i>
                        </div>
                      </div>
                      <ng-template #territoryAlignmentInValidTAFile>
                        <div *ngIf="!territoryAlignmentIsInprogress" nz-row class="title-row file-error">
                          <div nz-col nzSpan="12" class="title">{{ territoryAlignmentFilenames[0].filename }}</div>
                          <div nz-col nzSpan="12" class="link">
                            <i nz-icon class="delete-button" nzType="delete" nzTheme="outline"
                              (click)="deleteFile('single', true, false, null, 'territoryAlignment')"></i>
                          </div>
                        </div>
                      </ng-template>
                    </div>
                
                    <div *ngIf="mode !== 'edit' && (territoryAlignmentFilenames.length < 1)">
                
                      <div nz-row class="title-row file-list" *ngIf="!territoryAlignmentUploadError; else inValidTAFile">
                        <div nz-col nzSpan="12" class="title">
                          <i *ngIf="!territoryAlignmentIsInprogress" nz-icon nzType="check-circle" nzTheme="fill" class="check-icon"></i>
                          <span *ngIf="territoryAlignmentFileList[0]['inProgress']">
                            <nz-spin nzSimple [nzSize]="'small'"></nz-spin>
                          </span>
                          {{ territoryAlignmentFileList[0].name }}
                        </div>
                        <div nz-col nzSpan="12" class="link" *ngIf="!territoryAlignmentIsInprogress">
                          <i nz-icon class="delete-button" nzType="delete" nzTheme="outline"
                            (click)="deleteFile('single', true, false, null, 'territoryAlignment')"></i>
                        </div>
                      </div>
                      <ng-template #inValidTAFile>
                        <div *ngIf="!territoryAlignmentFileList[0]['inProgress']" nz-row class="title-row file-error">
                          <div nz-col nzSpan="12" class="title">{{ territoryAlignmentFileList[0].name }}</div>
                          <div nz-col nzSpan="12" class="link">
                            <i nz-icon class="delete-button" nzType="delete" nzTheme="outline"
                              (click)="deleteFile('single', false, false, null, 'territoryAlignment')"></i>
                          </div>
                        </div>
                      </ng-template>
                    </div>
                  </ng-template>
                

                  <div class="error-message" *ngIf="territoryAlignmentCustomError && territoryAlignmentCustomError.length > 0">
                    <span *ngFor="let error of territoryAlignmentCustomError">
                      <strong>{{ error.filename }}:</strong> {{ error['error'] }}<br>
                    </span>
                  </div>

                  <!-- End -->
                </div>
              </div>
            </ng-container>

          </div>
        </div>

        <div *ngIf="isSubmitted" class="progress-wrapper">
          <nz-spin nzTip="Please wait, save in progress..." nzSimple [nzSize]="'large'"></nz-spin>
        </div>
      </div>
    </ng-container>
    <!-- Footer -->
    <div *nzModalFooter>
      <nz-space>
        <div *nzSpaceItem>
          <button *ngIf="showSteps && activeStep === 1" nz-button nzType="default" (click)="onBack()"
            [disabled]="disableButton()">
            <span class="footer-buttons">Back</span>
          </button>
        </div>
        <div *nzSpaceItem>
          <button nz-button nzType="default" (click)="
              activeStep === 1
                ? onCancel()
                : !showCustomPage
                ? changeStep(activeStep, 'previous', false)
                : onCancelCustom()
            " [disabled]="disableButton() || isSubmitted">
            <span class="footer-buttons">{{
              activeStep === 1 ? 'Cancel' : 'Back'
              }}</span>
          </button>
        </div>
        <div *nzSpaceItem>
          <button *ngIf="hideSkipButton()" nz-button nzType="default" [disabled]="disableSkip()"
            (click)="changeStep(activeStep, 'skip', true)">
            <span class="footer-buttons">Skip</span>
          </button>
        </div>
        <div *nzSpaceItem>
          <button nz-button [nzType]="!showCustomPage ? 'default' : 'primary'" (click)="
              activeStep === 2 && uploadOption === 3 && showPrograms && !showCustomPage
                ? hideChannels()
                : showCustomPage
                ? submitRequest()
                : changeStep(activeStep, 'next', false)
            " [disabled]="
              isEditMode && activeStep === 3 ? disableNext() && !isChanged : disableNext()
            " [ngClass]="
              !showCustomPage
                ? ''
                : disableSubmitCustom || !commaDelimiterCheck || programExistsCheck
                ? 'app-primary-button-disabled'
                : 'app-primary-button'
            ">
            {{
            activeStep === 2 && uploadOption === 3 && showPrograms && !showCustomPage
            ? 'Next'
            : activeStep === 4 || (activeStep === 3 && !showCheckListStep)
            ? 'Create'
            : !showCustomPage
            ? 'Save and Next'
            : 'Submit Request'
            }}
          </button>
        </div>
      </nz-space>
    </div>
  </nz-modal>
</div>

<ng-template #Step1>
  <div nz-col nzSpan="8" *ngIf="showS3" (click)="onS3(false, true)" class="link" [ngClass]="
      (!allowMultiple && filenames.length > 0) || fileList.length > 0 ? 'disabled' : ''
    ">
    <span (click)="showUploadError = false"><i nz-icon nzType="arrow-left" nzTheme="outline"></i> Return to
      Upload Files</span>
  </div>
  <div nz-row class="title-row">
    <div nz-col nzSpan="12" class="title">Upload File</div>
    <!-- <div nz-col nzSpan="8" class="link"></div> -->
    <div nz-col nzSpan="12" *ngIf="!showS3" class="link" (click)="onS3(true, false)" [ngClass]="
      (!allowMultiple && filenames.length > 0) || fileList.length > 0 ? 'disabled' : ''
    ">
      <span>Upload Files through S3 Location</span>
    </div>
  </div>

  <!-- S3 Upload Logic -->
  <div *ngIf="showS3">
    <div class="multi-text">
      For files uploaded to S3, please enter the following details (all uploaded files must
      have the ‘pipe’ delimiter):
    </div>
    <div class="s-header">S3 Location (comma-separated values)</div>
    <textarea nz-input
      placeholder="Full path to the files with file extension (e.g. s3://<bucket>/<folder>/<file1>, s3://<bucket>/<folder>/<file2>)"
      [nzAutosize]="{ minRows: 4, maxRows: 4 }" [(ngModel)]="s3Path" (keyup)="checkS3Path()"
      (ngModelChange)="showUploadError = false; customError = []"></textarea>
    <div class="multi-text">
      Please ensure files are uploaded on {{ userDetails.bucket }}
    </div>
    <button *ngIf="activeStep === 4" nz-button nzType="default" (click)="validateS3(null, false)" style="margin: 0;"
      [disabled]="validS3Location" [nzLoading]="isValidS3LocationLoading">
      <i *ngIf="isS3LocationVerified" nz-icon nzType="check-circle" nzTheme="fill" class="check-icon"></i>
      {{isValidS3LocationLoading ? 'Verifying s3 location': isS3LocationVerified ? 'Verifed': 'Verify s3 location'}}
    </button>
  </div>

  <div *ngIf="!allowMultiple && !showS3" class="multi-text">
    Accepted File Type: .txt. All uploaded files must have the ‘pipe’ delimiter.
    Please note that files that exceed 1GB in size must be uploaded using the S3 location.
  </div>

  <div *ngIf="allowMultiple && !showS3" class="multi-text">
    Multiple file upload feature can be used for only same types of files/files for one
    particular interaction type. Example : ‘Email Template Jun.csv’ and 'Email Template
    July.csv'. Please do not use the feature to upload files from different sources.
    Instead, you can utilize Single Upload feature for them. Accepted File Type: .txt. All uploaded files must have the ‘pipe’ delimiter. Please note that files that
    exceed 1GB in size must be uploaded using the S3 location.
  </div>

  <div class="percent-wrapper" *ngIf="!showS3">
    File Size: {{ getFileSize(false) }}
    <nz-progress [nzPercent]="getFileSize(true)" [nzStrokeWidth]="8" [nzShowInfo]="false"
      [nzStrokeColor]="getFileSize(true) > 100 ? '#b21111' : '#00AA67'"></nz-progress>
  </div>

  <nz-upload nzType="drag" [nzMultiple]="allowMultiple" (nzChange)="fileChanged($event)"
    [nzFileListRender]="fileListRef" [(nzFileList)]="nzFileList"
    *ngIf="!disableUpload() && !showS3; else showSingleFile">
    <p class="ant-upload-text">
      Drag a file here to upload or
      <button nz-button nzType="primary" class="app-primary-button">
        <span class="choose-file">Choose File</span>
      </button>
    </p>
    <ng-template #fileListRef> </ng-template>
  </nz-upload>

  <!-- mode = save - Adding multiple files -->
  <div *ngIf="fileList.length > 0 && allowMultiple && !showS3 && mode !== 'edit'">
    <div *ngFor="let file of fileList">
      <div nz-row class="title-row file-list" *ngIf="file['inProgress']">
        <div nz-col nzSpan="12" class="title">
          <span>
            <nz-spin nzSimple [nzSize]="'small'"></nz-spin>
          </span>
          <span class="file-name">{{ file.name}}</span>
        </div>
      </div>
      <div nz-row class="title-row file-list" *ngIf="!file['inProgress'] && file['isValid']">
        <div nz-col nzSpan="12" class="title">
          <i nz-icon nzType="check-circle" nzTheme="fill" class="check-icon"></i>
          <span class="file-name">{{ file.name}}</span>
        </div>
        <div nz-col nzSpan="12" class="link">
          <i nz-icon class="delete-button" nzType="delete" nzTheme="outline"
            (click)="deleteFile('multiple', true, false, file)"></i>
        </div>
      </div>

      <div *ngIf="!file['inProgress'] && !file['isValid']" nz-row class="title-row file-error">
        <div nz-col nzSpan="12" class="title">{{ file.name }}</div>
        <div nz-col nzSpan="12" class="link">
          <i class="delete-button" nz-icon nzType="delete" nzTheme="outline"
            (click)="deleteFile('multiple', false, false, file)"></i>
        </div>
      </div>
    </div>
  </div>

  <div *ngIf="filenames.length > 0 && allowMultiple && mode === 'edit' && !showS3">
    <div *ngFor="let file of filenames">
      <div nz-row class="title-row file-list" *ngIf="!showUploadError; else inValidFile">
        <div nz-col nzSpan="12" class="title">
          <i nz-icon nzType="check-circle" nzTheme="fill" class="check-icon" *ngIf="!isInprogress"></i>
          <span *ngIf="file['inProgress']">
            <nz-spin nzSimple [nzSize]="'small'"></nz-spin>
          </span>
          {{ file.filename }}
        </div>
        <div nz-col nzSpan="12" class="link" *ngIf="!isInprogress">
          <i class="delete-button" nz-icon nzType="delete" nzTheme="outline"
            (click)="deleteFile('multiple', true, true, file)"></i>
        </div>
      </div>

      <ng-template #inValidFile>
        <div nz-col nzSpan="12" class="title" *ngIf="!isInprogress">{{ file.filename }}</div>
        <div nz-col nzSpan="12" class="link">
          <i class="delete-button" nz-icon nzType="delete" nzTheme="outline"
            (click)="deleteFile('multiple', false, false, file)"></i>
        </div>
      </ng-template>
    </div>
  </div>

  <ng-template #showSingleFile>
    <div *ngIf="mode === 'edit' && !allowMultiple && filenames.length === 1 && !showS3">
      <div nz-row class="title-row file-list" *ngIf="!showUploadError; else inValidFile">
        <div nz-col nzSpan="12" class="title">
          <i *ngIf="!isInprogress" nz-icon nzType="check-circle" nzTheme="fill" class="check-icon"></i>
          <span *ngIf="filenames[0]['inProgress']">
            <nz-spin nzSimple [nzSize]="'small'"></nz-spin>
          </span>
          {{ filenames[0].filename }}
        </div>
        <div nz-col nzSpan="12" class="link" *ngIf="!isInprogress">
          <i nz-icon class="delete-button" nzType="delete" nzTheme="outline"
            (click)="deleteFile('single', true, true)"></i>
        </div>
      </div>
      <ng-template #inValidFile>
        <div *ngIf="!showS3">
          <div *ngIf="!isInprogress" nz-row class="title-row file-error">
            <div nz-col nzSpan="12" class="title">{{ filenames[0].filename }}</div>
            <div nz-col nzSpan="12" class="link">
              <i nz-icon class="delete-button" nzType="delete" nzTheme="outline"
                (click)="deleteFile('single', false, false)"></i>
            </div>
          </div>
        </div>
      </ng-template>
    </div>

    <div *ngIf="mode !== 'edit' || (filenames.length < 1 && !showS3)">

      <div nz-row class="title-row file-list" *ngIf="!showUploadError && !showS3; else inValidFile">
        <div nz-col nzSpan="12" class="title">
          <i *ngIf="!isInprogress" nz-icon nzType="check-circle" nzTheme="fill" class="check-icon"></i>
          <span *ngIf="fileList[0]['inProgress']">
            <nz-spin nzSimple [nzSize]="'small'"></nz-spin>
          </span>
          {{ fileList[0].name }}
        </div>
        <div nz-col nzSpan="12" class="link" *ngIf="!isInprogress">
          <i nz-icon class="delete-button" nzType="delete" nzTheme="outline"
            (click)="deleteFile('single', true, false)"></i>
        </div>
      </div>
      <ng-template #inValidFile>
        <div *ngIf="!showS3">
          <div *ngIf="!fileList[0]['inProgress']" nz-row class="title-row file-error">
            <div nz-col nzSpan="12" class="title">{{ fileList[0].name }}</div>
            <div nz-col nzSpan="12" class="link">
              <i nz-icon class="delete-button" nzType="delete" nzTheme="outline"
                (click)="deleteFile('single', false, false)"></i>
            </div>
          </div>
        </div>
      </ng-template>
    </div>
  </ng-template>

  <!-- <div class="error-message" *ngIf="showUploadError && !fileTypeValid && !showS3">
  <span *ngIf="customError && customError.length > 0">
    <span *ngFor="let error of customError">
      <strong>{{ error.filename }}:</strong>
        The file type cannot be accepted. Please ensure the file is in .csv or .txt extension
        format and in line with template available for reference
        <a (click)="showTemplates = true">here</a>.
      <br>
    </span>
  </span>
  </div> -->
  <div class="error-message" *ngIf="customError && customError.length > 0">
    <!-- <span *ngIf="customError && customError.length > 0"> -->
    <span *ngFor="let error of customError">
      <strong>{{ error.filename }}:</strong> {{ error['error'] }}<br>
    </span>
    <!-- </span> -->
  </div>
  <!-- <div class="error-message" *ngIf="showUploadError && showS3">
  <span *ngIf="customError && customError.length > 0">
    <span *ngFor="let error of customError">
      <strong>{{ error.filename }}:</strong> {{ error['error'] }}<br>
    </span>
  </span> -->
  <!-- <span *ngIf="!customError || customError.length < 1"
    >The file type cannot be accepted. Please ensure the file is in .csv or .txt extension
    format and in line with template available for reference
    <a (click)="showTemplates = true">here</a>.</span
  > -->
  <!-- </div> -->
</ng-template>

<app-template-modal *ngIf="showTemplates" [showTemplates]="showTemplates" (hideTemplates)="onTemplateClose($event)">
</app-template-modal>