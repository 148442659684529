import { Component, OnInit, Input, Output, EventEmitter } from '@angular/core';
import { NzUploadChangeParam, NzUploadFile } from 'ng-zorro-antd/upload';
import { NzModalRef, NzModalService } from 'ng-zorro-antd/modal';
import { HttpService } from '../../../../shared/services/http.service';
import { NzNotificationService } from 'ng-zorro-antd/notification';

@Component({
  selector: 'app-header-content',
  templateUrl: './header-content.component.html',
  styleUrls: ['./header-content.component.scss'],
})
export class HeaderContentComponent implements OnInit {
  @Input() totalCount;
  @Input() uniqueCount;
  @Input() clientCount;
  @Input() downloadUrl;
  @Input() AFMCount;
  @Input() standardDispositions;
  @Input() isSubmit;
  @Input() type;

  @Output() uploadData: EventEmitter<object> = new EventEmitter<object>();

  public isUpload = false;
  public isFileUploaded = false;
  public fileList = [];
  public nzFileList: NzUploadFile[] = [];
  public formData = new FormData();
  public customError = '';
  public showUploadError = false;
  public errorMessage = '';
  public confirmModal?: NzModalRef;
  public uploadStats = {
    countValues: {
      uploadClientCount: 0,
      uploadTotalCount: 0,
      uploadUniqueCount: 0,
      uploadAFMCount: 0
    },
    tableData: {
      thead: [],
      tbody: [],
    },
  };

  constructor(
    private httpService: HttpService,
    private modal: NzModalService,
    private notification: NzNotificationService,
  ) {}

  ngOnInit(): void {}

  public downloadFile(): void {
    console.log('downloadFile');
    window.open(this.downloadUrl, '');
  }

  public fileChanged(files: NzUploadChangeParam): void {
    const fileNames = [];
    this.customError = '';
    this.showUploadError = false;
    if (files.type === 'start') {
      files.fileList.forEach((file) => {
        this.fileList.push(file.originFileObj);
      });
    }

    this.showUploadError = this.checkFileType();

    files = undefined;
    this.nzFileList = [];
  }

  public checkFileType(): boolean {
    let isValid = false;
    let validFileType = true;
    this.showUploadError = false;
    this.fileList.forEach((file) => {
      const type = file.name.substring(file.name.length - 4, file.name.length);
      if (type === '.csv') {
        isValid = true;
        this.errorMessage = '';
      } else {
        isValid = false;
        validFileType = false;
        this.errorMessage =
          'The file type cannot be accepted. Please ensure the file is in .csv extension format.';
        this.showUploadError = true;
      }
    });
    return !validFileType;
  }

  public upload(): void {
    this.formData.append('input_file', this.fileList[0]);
    this.formData.append('type', this.type);

    this.httpService.postRequest('user_upload_automation_mapping', this.formData, true).subscribe(
      // this.httpService.getRequest('user_upload_automation_mapping').subscribe(
      (data) => {
        if (data.status === 'SUCCESS') {
          this.formData.delete('input_file');
          this.formData.delete('type');
          this.fileList = [];
          this.showUploadError = false;
          this.isFileUploaded = true;
          this.uploadStats.countValues.uploadClientCount = data.count_values.upload_client_count;
          this.uploadStats.countValues.uploadTotalCount = data.count_values.upload_total_count;
          this.uploadStats.countValues.uploadUniqueCount = data.count_values.upload_unique_count;
          // this.downloadUrl = data.download_url;
          this.uploadStats.tableData.thead = data.table_data.thead;
          this.uploadStats.tableData.tbody = data.table_data.tbody;
          this.uploadData.emit(this.uploadStats);
          this.isUpload = false;
        } else {
          this.errorMessage = data.Message;
          this.downloadUrl = data.error_url;
        }
      },
      (error) => {
        this.errorMessage = 'something went wrong';
      },
    );
  }

  public deleteFile(isValid = false): void {
    if (isValid) {
      this.confirmModal = this.modal.confirm({
        nzTitle: 'Delete Uploaded File?',
        nzContent: 'Are you sure you want to delete this file?',
        nzOkText: 'Yes, Delete',
        nzIconType: 'exclamation-circle',
        nzOnOk: () => {
          this.fileList = [];
          this.errorMessage = '';
          this.showUploadError = false;
        },
        nzOnCancel: () => {},
      });
    } else {
      this.fileList = [];
      this.errorMessage = '';
      this.showUploadError = false;
    }
  }

  public submit(): void {
    const payload = {
      type: this.type,
    };

    this.httpService.postRequest('submit_automation_mapping_response', payload).subscribe(
      // this.httpService.getRequest('submit_automation_mapping_response').subscribe(
      (data) => {
        if (data.status === 'SUCCESS') {
          this.createBasicNotification('Automation file has been submitted.', 'success');
        } else {
          this.createBasicNotification('Something went wrong', 'error');
          this.errorMessage = data.message;
        }
      },
      (error) => {
        this.createBasicNotification('Something went wrong', 'error');
        this.errorMessage = 'something went wrong';
      },
    );
  }

  public createBasicNotification(msg, type): void {
    let style = { background: 'green', color: '#FFFFFF', padding: '20px' };

    if (type === 'error') {
      style = { background: 'red', color: '#FFFFFF', padding: '20px' };
    }

    const test = this.notification.blank('', msg, {
      nzPlacement: 'bottomLeft',
      nzDuration: 5000,
      nzStyle: style,
    }).onClose;
  }
}
