<div class="page-header">
  Upload Options
</div>

<nz-list>
  <nz-radio-group [(ngModel)]="selectedOption" (ngModelChange)="optionChanged()">
    <div *ngFor="let option of options" class="upload-options">
      <nz-list-item>
        <nz-card [ngClass]="option.optionId === selectedOption ? 'highlight-border' : ''">
          <div>
            <label nz-radio [nzValue]="option.optionId"></label>
              <span class="title">
                {{ option.title }}
              </span>
              <div class="description">
                {{ option.description }}
              </div>
              <div class="example">
                {{ option.example }}
              </div>
          </div>
        </nz-card>
      </nz-list-item>
    </div>
  </nz-radio-group>
</nz-list>
