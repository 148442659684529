import { NgModule } from '@angular/core';
import { CommonModule } from '@angular/common';
import { CustomerMasterComponent } from './customer-master/customer-master.component';
import { ActivityFilesComponent } from './activity-files/activity-files.component';
import { NzTableModule } from 'ng-zorro-antd/table';
import { NzLayoutModule } from 'ng-zorro-antd/layout';
import { SharedModule } from 'src/app/shared/shared.module';
import { NzIconModule } from 'ng-zorro-antd/icon';
import { NzModalModule } from 'ng-zorro-antd/modal';
import { NzUploadModule } from 'ng-zorro-antd/upload';
import { NzPipesModule } from 'ng-zorro-antd/pipes';
import {NzMenuModule} from "ng-zorro-antd/menu";
import { MappingTableComponent } from './shared/mapping-table/mapping-table.component';
import { HeaderContentComponent } from './shared/header-content/header-content.component';

@NgModule({
  declarations: [CustomerMasterComponent, ActivityFilesComponent, MappingTableComponent, HeaderContentComponent],
  imports: [
    CommonModule,
    NzTableModule,
    NzLayoutModule,
    SharedModule,
    NzIconModule,
    NzModalModule,
    NzUploadModule,
    NzPipesModule,
    NzMenuModule
  ],
  exports: [
    CustomerMasterComponent,
    ActivityFilesComponent,
    MappingTableComponent,
    HeaderContentComponent
  ]
})
export class AutomationMappingModule { }
