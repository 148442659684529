<div class="file-modal-wrapper">
  <nz-modal
    [nzStyle]="{ top: '20px' }"
    [(nzVisible)]="previewFile"
    [nzWidth]="'820px'"
    [nzFooter]="footer"
    [nzMaskClosable]="false"
    [nzTitle]="'Preview Sample Data'"
    [nzWrapClassName]="'modal-wrapper'"
    (nzOnCancel)="handleCancel()"
  >
    <ng-container *nzModalContent>
      <div *ngIf="!message" class="modal-body-wrapper">
        <div class="spinner" *ngIf="loading">
          <nz-spin nzSimple></nz-spin>
        </div>
        <nz-table
          class="app-modal-table"
          #previewTable
          [nzData]="previewData"
          nzTableLayout="fixed"
          [nzShowPagination]="true"
          *ngIf="!loading"
          [nzScroll]="{ x: '830px', y: '410px'}"
        >
          <thead>
            <tr>
              <th *ngFor="let column of previewHeaders" nzWidth="120px">
                {{ column }}
              </th>
            </tr>
          </thead>
          <tbody>
            <tr *ngFor="let data of previewTable.data">
              <ng-container *ngFor="let column of previewHeaders">
                <td>
                  {{ data[column] }}
                </td>
              </ng-container>
            </tr>
          </tbody>
        </nz-table>
      </div>
      <div *ngIf="message" class='modal-body-wrapper-single'>
    {{message}}
  </div>
    </ng-container>
  </nz-modal>
</div>
<ng-template #footer>
  <nz-space>
    <div *nzSpaceItem>
      <button
        nz-button
        [nzType]="'default'"
        [ngClass]="'app-default-button'"
        (click)="handleCancel()"
      >
        Close
      </button>
    </div>
    <div *nzSpaceItem>
      <button
        *ngIf="!message && !loading"
        nz-button
        [nzType]="'primary'"
        [ngClass]="'app-primary-button'"
        (click)="downloadFile(previewUrl, '')"
      >
        <i nz-icon nzType="download" nzTheme="outline" class="download-button"></i> Download
      </button>
    </div>
  </nz-space>
</ng-template>
