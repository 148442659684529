import { Component, Input, OnDestroy, OnInit } from '@angular/core';
import { HttpService } from '../../shared/services/http.service';
import { DatePipe } from '@angular/common';
import { AppState } from '../../shared/services/app-state';
import { Subscription } from 'rxjs';

@Component({
  selector: 'app-activity-log',
  templateUrl: './activity-log.component.html',
  styleUrls: ['./activity-log.component.scss'],
})
export class ActivityLogComponent implements OnInit, OnDestroy {
  public logData = [];
  public logHeaders = [];
  public sortOrder = 'ascend';
  public loading = false;
  public searchParamData = [];
  public pageIndex = 1;
  public filteredData = [];
  public showFilter = false;
  public showFilterTab = false;
  public intData = [];
  public intmData = [];
  public showFilterDate = false;
  public showDrawer = false;
  public drawerContent = [];
  public searchFiltered = false;
  public untouchedData = [];
  public showErrorMessage = false;

  public status = [
    { text: 'Success', value: false },
    { text: 'Failed', value: false },
  ];

  public tab = [
    { text: 'Download', value: false },
    { text: 'Upload', value: false },
    { text: 'AFFEX', value: false },
    { text: 'Benchmark Dashboard', value: false },
  ];

  public date = [
    { text: 'Last 30 days', value: false },
    { text: '3 months', value: false },
    { text: '6 months', value: false },
    { text: '1 year', value: false },
  ];

  public noDataMessage = 'There are no activities to display';
  public columnWidth = {
    client: '10%',
    user: '10%',
    impersonatedBy: '10%',
    status: '15%',
    tab: '15%',
    description: '25%',
    date: '5%',
    action: '10%'
  };
  private appStateSubscription: Subscription;

  constructor(private httpService: HttpService, private datePipe: DatePipe, private appState: AppState) {
   this.appStateSubscription = AppState.appState.subscribe((val) => {
      if (val && !val.showDropdown) {
        this.showFilter = false;
        this.showFilterTab = false;
        this.showFilterDate = false;
      }
    });
  }

  ngOnInit(): void {
    this.loadData();
  }

  public loadData(): void {
    this.loading = true;
    this.httpService.getRequest('get_activity_logs').subscribe((data) => {
      this.logHeaders = data.thead;
      this.logData = data.tbody;
      this.filteredData = data.tbody;
      this.untouchedData = data.tbody;
      this.createAutofillData();
      this.loading = false;
    });
  }

  public sortData(column: string): void {
    if (this.sortOrder === 'ascend') {
      this.logData.sort((a, b) =>
        this.getTime(a[column]) === this.getTime(b[column])
          ? 1
          : this.getTime(a[column]) < this.getTime(b[column])
          ? 1
          : -1,
      );
      this.sortOrder = 'descend';
    } else {
      this.logData.sort((a, b) =>
        this.getTime(a[column]) === this.getTime(b[column])
          ? 1
          : this.getTime(a[column]) > this.getTime(b[column])
          ? 1
          : -1,
      );
      this.sortOrder = 'ascend';
    }
  }

  public getTime(date): number {
    return new Date(date).getTime();
  }

  public getDate(date): string {
    return this.datePipe.transform(new Date(date), 'MM/d/yy h:mm a');
  }

  public calculateDiff(data) {
    let date = new Date(data);
    let currentDate = new Date();

    let days = Math.floor((currentDate.getTime() - date.getTime()) / 1000 / 60 / 60 / 24);
    return days;
  }

  public getWidth(key): string {
    let width = '';
    switch (key) {
      case 'client':
        width = this.columnWidth.client;
        break;
      case 'user':
        width = this.columnWidth.user;
        break;
      case 'description':
        width = this.columnWidth.description;
        break;
      case 'action':
        width = this.columnWidth.action;
        break;
      case 'status':
        width = this.columnWidth.status;
        break;
      case 'date':
        width = this.columnWidth.date;
      case 'tab' :
        width = this.columnWidth.tab;
        break;
      case 'impersonatedBy' :
        width = this.columnWidth.impersonatedBy;
        break;
    }
    return width;
  }

  public generateMailData(mailRecipient, user, description): void {
    const mailSubject = 'Your issue with ZS AffinityPortal is being looked into';
    const mailCC = 'AffinityMonitorTeam@zs.com';
    const mailBody = `Hi ${user},%0D%0AThe ZS Affinity Team is working on your issue - ${description}.%0D%0APlease expect to hear from us soon.%0D%0A %0D%0AThank you,%0D%0AZS AffinityPortal Team`;

    window.location.href = `mailto:${mailRecipient}?subject=${mailSubject}&body=${mailBody}&cc=${mailCC}`;
  }

  public createAutofillData(): void {
    const searchData = new Set();
    this.logData.forEach((data) => {
      if (data.user) {
        searchData.add(data.user);
      }
      if (data.description) {
        searchData.add(data.description);
      }
      if (data.client) {
        searchData.add(data.client);
      }
    });
    this.searchParamData = Array.from(searchData);
  }

  public filterOnSearch(key): void {
    const filteredDataSet = [];
    this.loading = true;
    if (key) {
      this.logData.forEach((data) => {
        const values = [];
        values.push(data.user);
        values.push(data.description);
        values.push(data.client);
        values.some((value) => {
          if (value && value.toLocaleLowerCase().includes(key.toLocaleLowerCase())) {
            filteredDataSet.push(data);
            return true;
          }
        });
      });
    }
    this.loading = false;
    this.pageIndex = 1;
    this.logData = filteredDataSet;
    this.filteredData = filteredDataSet;
    this.searchFiltered = true;
  }

  public formatOnNoResult(event): void {
    if (event) {
      this.noDataMessage = 'Your search returned no results';
      this.logData = [];
    }
  }

  public resetSearch(event): void {
    if (event) {
      this.filteredData = this.untouchedData;
      this.searchFiltered = false;
      this.filterDate();
      this.filterStatus();
      this.filterTab();
    }
  }
  public filterStatus(): void {
    if (!this.highlightFilterStatus()) {
      this.resetStatus();
    } else {
      this.filterFxn();
    }
    this.showFilter = false;
  }

  public filterTab(): void {
    if (!this.highlightFilterTab()) {
      this.resetTab();
    } else {
      this.filterFxn();
    }
    this.showFilterTab = false;
  }

  public filterDate(): void {
    if (!this.highlightFilterDate()) {
      this.resetDate();
    } else {
      this.filterFxn();
    }
    this.showFilterDate = false;
  }

  public resetStatus(): void {
    this.status.forEach((status) => {
      status.value = false;
    });
    this.showFilter = false;
    this.filterFxn();
  }

  public resetTab(): void {
    this.tab.forEach((tab) => {
      tab.value = false;
    });
    this.showFilterTab = false;
    this.filterFxn();
  }

  public resetDate(): void {
    this.date.forEach((date) => {
      date.value = false;
    });
    this.showFilterDate = false;
    this.filterFxn();
  }

  public highlightFilterStatus(): boolean {
    return this.status.some((status) => status.value === true);
  }

  public highlightFilterTab(): boolean {
    return this.tab.some((tab) => tab.value === true);
  }

  public highlightFilterDate(): boolean {
    return this.date.some((date) => date.value === true);
  }

  public filterFxn(): void {
    if (this.highlightFilterStatus() && !this.highlightFilterTab() && !this.highlightFilterDate()) {
      this.logData = [];
      this.filteredData.map((data) => {
        if (data.status.length > 0) {
          this.status.map((status) => {
            if (status.value) {
              if (data.status.indexOf(status.text) !== -1) {
                this.logData.push(data);
              }
            }
          });
        }
      });
      this.logData = [...new Set(this.logData)];
    } else if (
      this.highlightFilterTab() &&
      !this.highlightFilterStatus() &&
      !this.highlightFilterDate()
    ) {
      this.logData = [];
      this.filteredData.map((data) => {
        if (data.tab.length > 0) {
          this.tab.map((tab) => {
            if (tab.value) {
              if (data.tab.indexOf(tab.text) !== -1) {
                this.logData.push(data);
              }
            }
          });
        }
      });
      this.logData = [...new Set(this.logData)];
    } else if (
      this.highlightFilterDate() &&
      !this.highlightFilterTab() &&
      !this.highlightFilterStatus()
    ) {
      this.logData = [];
      this.filteredData.map((data) => {
        if (data.date.length > 0) {
          this.date.map((date) => {
            if (date.value) {
              console.log('in');
              if (date.text === 'Last 30 days') {
                if (this.calculateDiff(data.date) < 31) {
                  this.logData.push(data);
                }
              }
              if (date.text === '3 months') {
                if (this.calculateDiff(data.date) < 91) {
                  this.logData.push(data);
                }
              }
              if (date.text === '6 months') {
                if (this.calculateDiff(data.date) < 181) {
                  this.logData.push(data);
                }
              }
              if (date.text === '1 year') {
                if (this.calculateDiff(data.date) < 366) {
                  this.logData.push(data);
                }
              }
            }
          });
        }
      });
      this.logData = [...new Set(this.logData)];
    } else if (
      this.highlightFilterDate() &&
      this.highlightFilterTab() &&
      !this.highlightFilterStatus()
    ) {
      this.logData = [];
      this.intData = [];

      this.filteredData.map((data) => {
        if (data.tab.length > 0) {
          this.tab.map((tab) => {
            if (tab.value) {
              if (data.tab.indexOf(tab.text) !== -1) {
                this.intData.push(data);
              }
            }
          });
        }
      });
      this.intData.map((data) => {
        if (data.date.length > 0) {
          this.date.map((date) => {
            if (date.value) {
              console.log('in');
              if (date.text === 'Last 30 days') {
                if (this.calculateDiff(data.date) < 31) {
                  this.logData.push(data);
                }
              }
              if (date.text === '3 months') {
                if (this.calculateDiff(data.date) < 91) {
                  this.logData.push(data);
                }
              }
              if (date.text === '6 months') {
                if (this.calculateDiff(data.date) < 181) {
                  this.logData.push(data);
                }
              }
              if (date.text === '1 year') {
                if (this.calculateDiff(data.date) < 366) {
                  this.logData.push(data);
                }
              }
            }
          });
        }
      });
      this.logData = [...new Set(this.logData)];
    } else if (
      this.highlightFilterDate() &&
      !this.highlightFilterTab() &&
      this.highlightFilterStatus()
    ) {
      this.logData = [];
      this.intData = [];
      this.filteredData.map((data) => {
        if (data.status.length > 0) {
          this.status.map((status) => {
            if (status.value) {
              if (data.status.indexOf(status.text) !== -1) {
                this.intData.push(data);
              }
            }
          });
        }
      });
      this.intData.map((data) => {
        if (data.date.length > 0) {
          this.date.map((date) => {
            if (date.value) {
              console.log('in');
              if (date.text === 'Last 30 days') {
                if (this.calculateDiff(data.date) < 31) {
                  this.logData.push(data);
                }
              }
              if (date.text === '3 months') {
                if (this.calculateDiff(data.date) < 91) {
                  this.logData.push(data);
                }
              }
              if (date.text === '6 months') {
                if (this.calculateDiff(data.date) < 181) {
                  this.logData.push(data);
                }
              }
              if (date.text === '1 year') {
                if (this.calculateDiff(data.date) < 366) {
                  this.logData.push(data);
                }
              }
            }
          });
        }
      });
      this.logData = [...new Set(this.logData)];
    } else if (
      this.highlightFilterStatus() &&
      this.highlightFilterTab() &&
      !this.highlightFilterDate()
    ) {
      this.logData = [];
      this.intData = [];
      this.filteredData.map((data) => {
        if (data.tab.length > 0) {
          this.tab.map((tab) => {
            if (tab.value) {
              if (data.tab.indexOf(tab.text) !== -1) {
                this.intData.push(data);
              }
            }
          });
        }
      });
      this.intData.map((data) => {
        if (data.status.length > 0) {
          this.status.map((status) => {
            if (status.value) {
              if (data.status.indexOf(status.text) !== -1) {
                this.logData.push(data);
              }
            }
          });
        }
      });
      this.logData = [...new Set(this.logData)];
    } else if (
      this.highlightFilterDate() &&
      this.highlightFilterTab() &&
      this.highlightFilterStatus()
    ) {
      this.logData = [];
      this.intData = [];
      this.intmData = [];
      this.filteredData.map((data) => {
        if (data.status.length > 0) {
          this.status.map((status) => {
            if (status.value) {
              if (data.status.indexOf(status.text) !== -1) {
                this.intData.push(data);
              }
            }
          });
        }
      });
      this.intData.map((data) => {
        if (data.date.length > 0) {
          this.date.map((date) => {
            if (date.value) {
              console.log('in');
              if (date.text === 'Last 30 days') {
                if (this.calculateDiff(data.date) < 31) {
                  this.intmData.push(data);
                }
              }
              if (date.text === '3 months') {
                if (this.calculateDiff(data.date) < 91) {
                  this.intmData.push(data);
                }
              }
              if (date.text === '6 months') {
                if (this.calculateDiff(data.date) < 181) {
                  this.intmData.push(data);
                }
              }
              if (date.text === '1 year') {
                if (this.calculateDiff(data.date) < 366) {
                  this.intmData.push(data);
                }
              }
            }
          });
        }
      });
      this.intmData.map((data) => {
        if (data.tab.length > 0) {
          this.tab.map((tab) => {
            if (tab.value) {
              if (data.tab.indexOf(tab.text) !== -1) {
                this.logData.push(data);
              }
            }
          });
        }
      });
      this.logData = [...new Set(this.logData)];
    } else {
      this.logData = [...this.filteredData];
    }
  }
  public updateDateFilter(item, dates) {
    dates.forEach(date => {
      if(date.text !== item.text) {
        date.value = false;
      }
    })
  }
  public openInfo(tab, value, status) {
    this.showDrawer = true;
    if (tab === 'Download Reports') {
        this.drawerContent = [{
          label: 'Year',
          value: value.Year
        }, {
          label: 'Cycle',
          value: value.Cycle
        }, {
          label: 'Product(s)',
          value: value.Product
        }, {
          label: 'Filename(s)',
          value: value.Filename
        }
        ]
      } else if (tab == 'Upload') {
        this.drawerContent = [
          {
            label: 'File Mapping',
            value: ''
          },
          {
            label: 'Cycle',
            value: value.Cycle
          },
          {
            label: 'Data Period',
            value: value.Data_Period
          },
          {
            label: 'Description',
            value: value.Description
          },
          {
            label: 'L1 Status',
            value: value.L1_Status
          },
          {
            label: 'L2 Status',
            value: value.L2_Status
          },
          {
            label: 'Upload Status',
            value: value.Upload_Status
          }
        ];
      } else if (tab == 'AFFEX') {
      if(status === 'Failed'){
        this.drawerContent = [
          {
            label: 'Cycle',
            value: value.Cycle
          },
          {
            label: 'Product',
            value: value.Product
          },
          {
            label: 'Filename',
            value: value.Filename
          },
          {
            label: 'NPI count',
            value: value.NPI_Count
          },
          {
            label: 'Error Message',
            value: value.Error_Message
          }
        ];
      } else if (status === 'Success'){
        this.drawerContent = [
          {
            label: 'Cycle',
            value: value.Cycle
          },
          {
            label: 'Product',
            value: value.Product
          },
          {
            label: 'Filename',
            value: value.Filename
          },
          {
            label: 'NPI count',
            value: value.NPI_Count
          }
        ];
      }

      }

  }


  public openChange(event): void {
    this.appState.setGlobalState('showDropdown', event);
  }

  ngOnDestroy(): void {
    this.appStateSubscription.unsubscribe();
  }
}
