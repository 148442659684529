<nz-layout>
  <nz-content>
    <div class="space-align-container">
      <span nz-typography class="tab-header">Steward Feedback</span>
      <nz-space [nzSize]="'middle'">
        <span class="export-wrapper">
        <app-simple-dropdown [page]="'admin'" *ngIf="isAdmin"></app-simple-dropdown>
      </span>
        <app-search-bar
          *nzSpaceItem
          [searchParameter]="searchParamData"
          (searchValue)="filterOnSearch($event)"
          (inputKey)="filterOnSearch($event)"
          (noResultFound)="formatOnNoResult($event)"
          (noInput)="resetSearch($event)"
          (resetSearch)="resetSearch($event)"
          [(inputValue)]="searchInput"
        ></app-search-bar>
      </nz-space>
    </div>
    <div class="tabs">
      <nz-tabset>
        <nz-tab [nzTitle]="titleTemplateafm" (nzClick)="tabChanged('AFM')">
          <ng-template nz-tab>
            <app-steward-afm
              [stewardData]="stewardData"
              [status]="status"
              [client]="client"
              [channel]="channel"
              [program]="program"
              [sortOrder]="sortOrder"
              [sortDirection]="sortDirection"
              [loading]="loading"
              [noDataMessage]="noDataMessage"
              [search]="payload.search"
              [pageIndex]="pageIndex"
              (exportPayload)="loadData($event)"
            >
            </app-steward-afm> </ng-template
        ></nz-tab>
        <nz-tab [nzTitle]="titleTemplateacm" (nzClick)="tabChanged('ACM')">
          <ng-template nz-tab>
            <app-steward-acm
              [stewardData]="stewardData"
              [status]="status"
              [client]="client"
              [channel]="channel"
              [program]="program"
              [sortOrder]="sortOrder"
              [sortDirection]="sortDirection"
              [loading]="loading"
              [noDataMessage]="noDataMessage"
              [search]="payload.search"
              [pageIndex]="pageIndex"
              (exportPayload)="loadData($event)"
            >
            </app-steward-acm
          ></ng-template>
        </nz-tab>
        <ng-template #titleTemplateafm>
          <span>AffinityMonitor</span>
          <span class="pending-count"
            *ngIf="afmPending !== 0"><sup>{{ afmPending }}</sup></span
          >
        </ng-template>
        <ng-template #titleTemplateacm>
          <span>AccessMonitor</span>
          <span class="pending-count"
            *ngIf="acmPending !== 0" ><sup>{{ acmPending }}</sup></span
          >
        </ng-template>
      </nz-tabset>
    </div>
    <div class="error-message" *ngIf="customError && customError.length > 0">
          {{ customError['error'] }}
    </div>
  </nz-content>
</nz-layout>
