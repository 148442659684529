<nz-table
  #StewardAfmTable
  [nzData]="stewardData"
  class="steward-table"
  nzTableLayout="fixed"
  [nzShowPagination]="true"
  [nzPageSize]="8"
  [(nzPageIndex)]="pageIndex"
  [nzShowQuickJumper]="true"
  [nzNoResult]="noRecords"
  [nzLoading]="loading"
>
  <thead>
    <tr>
      <th nzWidth="10%">User</th>
      <th nzWidth="8%" nzCustomFilter class="client-filter">
        <nz-filter-trigger [nzDropdownMenu]="customFilterClient" [(nzVisible)]="showFilterClient">
          <i
            nz-icon
            nzType="filter"
            nzTheme="fill"
            [ngClass]="highlightFilterClient() ? 'highlight' : ''"
          ></i>
        </nz-filter-trigger>
        <nz-dropdown-menu #customFilterClient="nzDropdownMenu">
          <div class="dropdown-container">
            <div class="ant-table-filter-dropdown">
              <ul nz-menu *ngFor="let item of client">
                <li nz-menu-item>
                  <label nz-checkbox [(ngModel)]="item.value"> {{ item.text }} </label>
                </li>
              </ul>
            </div>
            <div class="button-wrapper">
              <a (click)='resetClient()'>Reset</a>&nbsp;
              <button
                nz-button
                nzType="primary"
                class="app-primary-button button-class"
                (click)='filter(false)'
              >
                OK
              </button>
            </div>
          </div>
        </nz-dropdown-menu>
        Client
      </th>
      <th nzWidth="7%">Process Id</th>
      <th nzWidth="10%">File Name</th>
      <th nzWidth="15%" nzCustomFilter class="channel-filter">
        <nz-filter-trigger [nzDropdownMenu]="customFilterChannel" [(nzVisible)]="showFilterChannel">
          <i
            nz-icon
            nzType="filter"
            nzTheme="fill"
            [ngClass]="highlightFilterChannel() ? 'highlight' : ''"
          ></i>
        </nz-filter-trigger>
        <nz-dropdown-menu #customFilterChannel="nzDropdownMenu">
          <div class="dropdown-container">
            <div class="ant-table-filter-dropdown">
              <ul nz-menu *ngFor="let item of channel">
                <li nz-menu-item>
                  <label nz-checkbox [(ngModel)]="item.value"> {{ item.text }} </label>
                </li>
              </ul>
            </div>
            <div class="button-wrapper">
              <a (click)='resetChannel()'>Reset</a>&nbsp;
              <button
                nz-button
                nzType="primary"
                class="app-primary-button button-class"
                (click)='filter(false)'
              >
                OK
              </button>
            </div>
          </div>
        </nz-dropdown-menu>
        Channel</th>
      <th nzWidth="10%" nzCustomFilter class="program-filter">
        <nz-filter-trigger [nzDropdownMenu]="customFilterProgram" [(nzVisible)]="showFilterProgram">
          <i
            nz-icon
            nzType="filter"
            nzTheme="fill"
            [ngClass]="highlightFilterProgram() ? 'highlight' : ''"
          ></i>
        </nz-filter-trigger>
        <nz-dropdown-menu #customFilterProgram="nzDropdownMenu">
          <div class="dropdown-container">
            <div class="ant-table-filter-dropdown">
              <ul nz-menu *ngFor="let item of program">
                <li nz-menu-item>
                  <label nz-checkbox [(ngModel)]="item.value"> {{ item.text }} </label>
                </li>
              </ul>
            </div>
            <div class="button-wrapper">
              <a (click)='resetProgram()'>Reset</a>&nbsp;
              <button
                nz-button
                nzType="primary"
                class="app-primary-button button-class"
                (click)='filter(false)'
              >
                OK
              </button>
            </div>
          </div>
        </nz-dropdown-menu>
        Program</th>
      <th nzWidth="12%" [nzSortOrder]="sortOrder" (click)='filter(true)' [nzSortDirections]="sortDirection">Upload Date</th>
      <th nzWidth="12%" nzCustomFilter class="status-filter">
        <nz-filter-trigger [nzDropdownMenu]="customFilterStatus" [(nzVisible)]="showFilterStatus">
          <i
            nz-icon
            nzType="filter"
            nzTheme="fill"
            [ngClass]="highlightFilterStatus() ? 'highlight' : ''"
          ></i>
        </nz-filter-trigger>
        <nz-dropdown-menu #customFilterStatus="nzDropdownMenu">
          <div class="dropdown-container">
            <div class="ant-table-filter-dropdown">
              <ul nz-menu *ngFor="let item of status">
                <li nz-menu-item>
                  <label nz-checkbox [(ngModel)]="item.value"> {{ item.text }} </label>
                </li>
              </ul>
            </div>
            <div class="button-wrapper">
              <a (click)='resetStatus()'>Reset</a>&nbsp;
              <button
                nz-button
                nzType="primary"
                class="app-primary-button button-class"
                (click)='filter(false)'
              >
                OK
              </button>
            </div>
          </div>
        </nz-dropdown-menu>
        Status
      </th>
      <th nzWidth="10%">Actions</th>
    </tr>
  </thead>
  <tbody>
    <tr *ngFor="let data of StewardAfmTable.data">
      <td class="row-data">
        <a>{{ data.user }}</a>
      </td>
      <td class="row-data">{{ data.client }}</td>
      <td class="row-data">{{ data.process_id }}</td>
      <td class="row-data">{{ data.file_name }}</td>
      <td class="row-data">{{ data.channel }}</td>
      <td class="row-data">{{ data.program }}</td>
      <td class="row-data">{{ getDate(data.upload_date) }}</td>
      <td class="row-data">
        <span *ngIf="data.status === 'Pending'" class="pending"
          ><i nz-icon nzType="clock-circle" nzTheme="outline"></i
        ></span>
        <span *ngIf="data.status === 'Approved'" class="approved"
          ><i nz-icon nzType="check-circle" nzTheme="fill"></i
        ></span>
        <span *ngIf="data.status === 'Modified & Approved'" class="modified"
          ><i nz-icon nzType="check-circle" nzTheme="outline"></i
        ></span>
        <span *ngIf="data.status === 'Rejected'" class="rejected"
          ><i nz-icon nzType="minus-circle" nzTheme="fill"></i
        ></span>
        &nbsp;{{ data.status }}
      </td>
      <td class="review" (click)="onReviewOpen(data.id, data.status)"><i nz-icon nzType="file-search" nzTheme="outline"></i> Review</td>
    </tr>
  </tbody>

  <ng-template #noRecords>
    <div class="no-requests" *ngIf="!loading">{{noDataMessage}}</div>
  </ng-template>
</nz-table>
<app-steward-review
  *ngIf="showReviewModal"
  [showReviewModal]="showReviewModal"
  (hideReviewModal)="onReviewClose($event)"
  [id]="reviewId"
  [status]="reviewStatus"
>
</app-steward-review>
