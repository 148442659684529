import { Component, OnInit } from '@angular/core';
import { HttpService } from '../shared/services/http.service';
import { AppState } from '../shared/services/app-state';
import { Subscription } from 'rxjs';
import { Router } from '@angular/router';

@Component({
  selector: 'app-idm-apps',
  templateUrl: './idm-apps.component.html',
  styleUrls: ['./idm-apps.component.scss']
})
export class IdmAppsComponent implements OnInit {
  public IDMDetails;
  public drawerContent = [];
  public showDrawer = false;
  private appStateSubscription: Subscription;
  public userDetails;

  constructor(private httpService: HttpService, private appState: AppState, private router: Router) {
    this.appStateSubscription = AppState.appState.subscribe((val) => {

      if (!this.userDetails) {
        if (val && val.userDetails) {
          this.userDetails = val.userDetails;
          this.checkAccess();
        }
      }

    });
  }

  ngOnInit(): void {
    this.httpService.getRequest('api/launch_bar').subscribe((data) => {
      this.IDMDetails = data;
      this.IDMDetails.applications.forEach(app => {
        this.drawerContent.push({title: app.name, selected: false, url: app.url});
      });
    });
  }

  public switchTab(tab): void {
    const selectedTab = this.drawerContent.find(app => app.title === tab);
    this.appState.clearGlobalState();
    window.location.href = selectedTab.url;
  }

  public checkAccess(): void {
    const pathArray = window.location.href.split('/');
    if (this.userDetails.client !== pathArray[pathArray.length - 2] || pathArray.length !== 2) {
      if (this.userDetails.client === pathArray[pathArray.length - 2] && pathArray[pathArray.length - 1] === 'idmapps') {
        this.showDrawer = true;
      } else {
        if (this.userDetails.role === 'admin' || this.userDetails.role === 'manager') {
          this.appState.setGlobalState('activeRoute', '/admin');
          this.router.navigateByUrl(this.userDetails.client + '/admin');
        } else {
          this.appState.setGlobalState('activeRoute', '/upload-data');
          this.router.navigateByUrl(this.userDetails.client + '/upload-data');
          // this.appState.setGlobalState('activeRoute', '/subscriptions');
          // this.router.navigateByUrl(this.userDetails.client + '/subscriptions');
        }
      }
    }
  }

}
