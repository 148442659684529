import { Component, EventEmitter, OnInit, Output, OnDestroy } from '@angular/core';
import { NzUploadChangeParam, NzUploadFile } from 'ng-zorro-antd/upload';
import { NzModalRef, NzModalService } from 'ng-zorro-antd/modal';
import { HttpService } from '../../shared/services/http.service';
import { AppState } from '../../shared/services/app-state';
import { Subscription } from 'rxjs';

@Component({
  selector: 'app-affex-content',
  templateUrl: './affex-content.component.html',
  styleUrls: ['./affex-content.component.scss'],
})
export class AffexContentComponent implements OnInit, OnDestroy {
  public nzFileList: NzUploadFile[] = [];
  public restartClick = false;
  public tableBody = [];
  public tableHeaders = [];
  public isZSUser = false;
  public summaryStats = {
    hcp_count: 17340,
    total_npi: 18013,
    activity_per: '96%',
  };
  public cycle = [
    'S1 2017',
    'S2 2017',
    'S1 2018',
    'S2 2018',
    'S1 2019',
    'S2 2019',
    'S1 2020',
    'S2 2020',
    'S1 2021',
    'S2 2021',
  ];
  public product = ['AffinityMonitor', 'AccessMonitor'];
  public payload = {
    cycle: '',
    product: '',
  };
  public isDisabled = true;
  public fileList = [];
  public showUploadError = false;
  public confirmModal?: NzModalRef;

  public showOutput = false;
  public inProgress = true;
  public formData = new FormData();
  public errorMessage = '';
  public affexId;
  public downloadUrl = '';
  public affexFileName = '';
  private appStateSubscription: Subscription;
  public intervalId;

  public specialties = [];
  public selectedSpecialty = [];
  public specInProgress = false;
  public intialLoad = true;

  constructor(
    private modal: NzModalService,
    private httpService: HttpService,
    private appState: AppState,
  ) {
    this.appStateSubscription = AppState.appState.subscribe((val) => {
      if (typeof val === 'string') {
        val = JSON.parse(val);
      }
      if (val) {
        if (!this.affexFileName && val.affexId && this.intialLoad) {
          this.intialLoad = false;
          this.affexId = val.affexId;
          this.affexFileName = val.affexFileName;
          this.payload.cycle = val.affexCycle;
          this.payload.product = val.affexProduct;
          this.showOutput = true;
          this.checkStatus();
        }
      }
    });
  }

  ngOnInit(): void {
    let localData = window.sessionStorage.getItem('globalState');
    if (typeof localData === 'string') {
      localData = JSON.parse(localData);
    }
    this.cycle = localData['userDetails'].cycle;
  }

  public fileChanged(files: NzUploadChangeParam): void {
    files.fileList.forEach((file) => {
      this.fileList.push(file.originFileObj);
    });
    this.affexFileName = this.fileList[0].name;
    this.showUploadError = !this.checkFileType();
    this.nzFileList = [];
  }

  public updatePayload(event, field): void {
    if (field === 'cycle') {
      this.payload.cycle = event;
    } else {
      this.payload.product = event;
    }
  }

  public deleteFile(): void {
    if (this.showUploadError) {
      this.fileList = [];
      this.affexFileName = '';
    } else {
      this.confirmModal = this.modal.confirm({
        nzTitle: 'Delete Uploaded File?',
        nzContent: 'Are you sure you want to delete this file?',
        nzOkText: 'Yes, Delete',
        nzIconType: 'exclamation-circle',
        nzOnOk: () => {
          this.fileList = [];
          this.affexFileName = '';
        },
        nzOnCancel: () => {
          // this.showModal = true;
        },
      });
    }
  }

  public checkFileType(): boolean {
    let isValid = false;
    this.showUploadError = false;
    this.fileList.forEach((file) => {
      const type = file.name.substring(file.name.length - 4, file.name.length);
      if (type === '.csv' || type === '.txt' || type === 'xlsx') {
        isValid = true;
      } else {
        isValid = false;
        this.showUploadError = true;
      }
    });
    return isValid;
  }

  public isSubmitDisabled(): boolean {
    return (
      this.fileList.length < 1 ||
      !this.payload.cycle ||
      !this.payload.product ||
      this.showUploadError ||
      this.showOutput
    );
  }

  public generateReport(): void {
    this.errorMessage = '';
    this.showOutput = true;
    this.inProgress = true;
    this.formData = new FormData();
    this.formData.append('input_file', this.fileList[0]);
    this.formData.append('product', this.payload.product);
    this.formData.append('semester', this.payload.cycle);
    this.appState.setGlobalState('affexCycle', this.payload.cycle);
    this.appState.setGlobalState('affexProduct', this.payload.product);
    this.httpService.postRequest('submit_affex_data', this.formData, true).subscribe(data => {
    // this.httpService.getRequest('submit_affex_data').subscribe((data) => {
      if (data.status === 'SUCCESS') {
        this.affexId = data.affex_id;
        this.specialties = data.specialties;
        this.appState.setGlobalState('affexId', this.affexId);
        this.appState.setGlobalState('affexFileName', this.fileList[0].name);
        this.checkStatus();
      } else if (data.status === 'ERROR') {
        this.inProgress = false;
        this.errorMessage = data.message;
      }
    });
  }

  public checkStatus(setTimer?): void {
    if (!this.affexId) {
      let state = window.sessionStorage.getItem('globalState');
      if (typeof state === 'string') {
        state = JSON.parse(state);
      }
      this.affexId = state['affexId'];
    }
    const payload = {
      affex_id: this.affexId,
      specialties: this.selectedSpecialty  
    };
    this.httpService.postRequest('affex_status', payload).subscribe((data) => {
    // this.httpService.getRequest('affex_status').subscribe(
    //  (data) => {
        if (setTimer) {
          this.intervalId = null;
        }
        if (data.status === 'SUCCESS' && this.inProgress || this.specInProgress) {
          this.specInProgress = false;
          this.inProgress = false;
          this.downloadUrl = data.url;
          this.tableBody = data.data.table_data.tbody;
          this.tableHeaders = data.data.table_data.thead;
          if (this.payload.product === 'AccessMonitor') {
            this.tableHeaders.unshift('');
          }
          this.summaryStats = data.data.summary_stats;
          this.isZSUser = data.zs_user;
          if (this.intervalId) {
            clearTimeout(this.intervalId);
          }
        } else if (data.status === 'ERROR' && this.inProgress || this.specInProgress) {
          this.inProgress = false;
          this.specInProgress = false;
          this.errorMessage = data.message;
          if (this.intervalId) {
            clearTimeout(this.intervalId);
          }
        } else if (this.specInProgress || this.inProgress && !this.intervalId) {
          this.intervalId = setTimeout(() => {
            this.checkStatus(true);
          }, 10000);
        }
      },
      (error) => {
        console.log('something went wrong: ', error);
        this.inProgress = false;
        this.specInProgress = false;
        this.errorMessage = 'Something went wrong, please try again..';
      },
    );
  }

  public downloadReport(): void {
    window.open(this.downloadUrl);
  }

  public restartClicked(): void {
    this.confirmModal = this.modal.confirm({
      nzTitle: 'Reset?',
      nzContent:
        'Resetting the process will delete the currently uploaded files. Are you sure you would like to reset?',
      nzOkText: 'Yes, Reset',
      nzIconType: 'exclamation-circle',
      nzOnOk: () => {
        this.showOutput = false;
        this.inProgress = false;
        this.fileList = [];
        this.payload.cycle = '';
        this.payload.product = '';
        this.affexFileName = '';
        this.affexId = null;
        this.appState.removeGlobalState('affexId');
        this.appState.removeGlobalState('affexFileName');
        this.appState.removeGlobalState('affexCycle');
        this.appState.removeGlobalState('affexProduct');
      },
      nzOnCancel: () => {
        // this.showModal = true;
      },
    });
  }

  ngOnDestroy(): void {
    if (this.intervalId) {
      clearTimeout(this.intervalId);
    }
  }

  public displayHeader(column): string {
    let header = '';
    switch (column) {
      case 'channel': header = 'Channel'; break;
      case 'count_with_engagement_metrics': header = 'Count with Engagement Metrics'; break;
      case 'no_see_ratings': header = 'No see ratings'; break;
      case 'activity_per': header = '% Rated w/ Activity Data'; break;
      case 'count_affinity': header = 'Count with Affinity Metrics'; break;
      case 'count_access': header = 'Count with Access Metrics'; break;
      case 'additional_hcp_per': header = '% HCPs with metrics derived from actual activity'; break;
      case 'count_with_no_see_rating': header = '# of HCPs with metrics'; break;
      case 'rated_hcps': header = '% Rated HCPs'; break;
      case 'count_with_no_see_rating': header = 'AM No-see rating'; break;
      case 'count_with_annual_call_freq_perc_75': header = 'AM Call frequency percentiles'; break;
      case 'count_with_industry_attainment': header = 'Industry Attainment'; break;
      case 'hcp_with_metrics': header = '% HCPs with metrics'; break;
      case 'hcp_with_affinity_metrics': header = '# of HCPs with Affinity metrics'; break;
      default: header = '';
    }
    return header;
  }

  public getTableData(data, column): string {
    const columnHeaders = Object.keys(data);
    let returnValue = '';
    if (!column && columnHeaders[0] === 'count_with_no_see_rating') {
      returnValue = 'AM No-see rating';
    } else if (!column && columnHeaders[0] === 'count_with_annual_call_freq_perc_75') {
      returnValue = 'AM Call frequency percentiles';
    } else if (!column && columnHeaders[0] === 'count_with_industry_attainment') {
      returnValue = 'Industry Attainment';
    } else if (this.payload.product === 'AccessMonitor' && column === 'hcp_with_metrics') {
      returnValue = data[columnHeaders[0]];
    } else if (column === 'additional_hcp_per') {
      returnValue = data['hcp_with_metrics_derived'];
    } else {
      returnValue = data[column];
    }
    return returnValue;
  }

  public specialtySelected(event): void {
    if (event) {
      this.selectedSpecialty = event;
      this.specInProgress = true;
      this.checkStatus();
    }
  }
}
