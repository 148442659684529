<div class="review-wrapper">
  <nz-modal
    nzTitle="Review data changes and business justification"
    [(nzVisible)]="showReviewModal"
    [nzFooter]="footer"
    [nzWidth]="'624px'"
    (nzOnCancel)="onCancel()"
    [nzWrapClassName]="'modal-wrapper'"
    [nzMaskClosable]="false"
  >
    <ng-container *nzModalContent>
      <div class = "spin" *ngIf="loading"><nz-spin nzSimple></nz-spin></div>
      <div *ngIf="!showRejectScreen && !loading" class="review-content">
        <div class="data-1">
          Please review the business justification for the changes made to the data file.
        </div>
        <div class="data-1" *ngIf="customProg">
          Please review the {{customProg}} before reviewing the data changes.
        </div>
        <div class="data-2-wrapper">
          <div class="data-2">
            <div class="data-header">Business Justification</div>
            <div>{{ reviewData.businessJustification }}</div>
          </div>
          <div class="data-2" *ngIf="businessOtherSelected">
            <div class="data-header">Reason for Modification</div>
            <div>{{ reviewData.businessJustificationDescription }}</div>
          </div>
          <div class="data-2" *ngIf="dataChangeStatus === 'Rejected'">
            <div class="data-header">Reason for Rejection</div>
            <div>{{ reviewData.reason }}</div>
          </div>
        </div>
        <div class="data-3-wrapper">
          <div class="data-3">
            <div class="data-header">Upload Changes</div>
            <div>
              <span class="added" *ngIf="reviewData.uploadChanges.added.length > 0">+ Added </span
              >{{ reviewData.uploadChanges.added }}
            </div>
            <div>
              <span class="removed" *ngIf="reviewData.uploadChanges.removed.length > 0"
                >- Removed </span
              >{{ reviewData.uploadChanges.removed }}
            </div>
            <div
              *ngIf="
                reviewData.uploadChanges.added.length < 1 &&
                reviewData.uploadChanges.removed.length < 1
              "
            >
              N/A
            </div>
          </div>
          <div
            class="data-3"
          >
            <div class="data-header">Mapping Changes</div>
            <div *ngIf="reviewData.mappingChanges.added.length > 2">
            <div *ngFor="let addedMap of reviewData.mappingChanges.added">
              <span class="added">+ Added </span
              ><span class="filechange">{{ addedMap.program }}</span> for
              {{ addedMap.file_name }}
            </div></div>
            <div *ngIf="reviewData.mappingChanges.removed.length > 2">
            <div *ngFor="let removedMap of reviewData.mappingChanges.removed">
              <span class="removed">- Removed </span
              ><span class="filechange">{{ removedMap.program }}</span> for
              {{ removedMap.file_name }}
            </div>
              </div>
            <div
               *ngIf="
              reviewData.mappingChanges.added.length < 2 &&
              reviewData.mappingChanges.removed.length < 2
            "
            >
              N/A
            </div>
          </div>
          <div
            class="data-3"
          >
            <div class="data-header">Info Changes</div>
            <div>
              <div *ngIf="reviewData.infoChanges.startDate.newDate">
                <span class="updated">Updated </span
                ><span class="updated-data">Start Date </span>value from <i>“{{
                  getDate(reviewData.infoChanges.startDate.oldDate, true)
                }}”</i> to <i>“{{ getDate(reviewData.infoChanges.startDate.newDate, true) }}”</i>
              </div>
              <div *ngIf="reviewData.infoChanges.endDate.newDate">
                <span class="updated">Updated </span
                ><span class="updated-data">End Date </span>value from <i>“{{
                getDate(reviewData.infoChanges.endDate.oldDate, true)
                }}”</i> to <i>“{{ getDate(reviewData.infoChanges.endDate.newDate, true) }}”</i>
              </div>
              <div *ngIf="reviewData.infoChanges.description.newDesc">
                <span class="updated">Updated </span
                ><span class="updated-data">Description </span>value from “{{
                  reviewData.infoChanges.description.oldDesc
                }}” to “{{ reviewData.infoChanges.description.newDesc }}”
              </div>
              <div *ngIf="reviewData.infoChanges.fileTitle.newTitle">
                <span class="updated">Updated </span
                ><span class="updated-data">File Title</span>value from “{{
                  reviewData.infoChanges.fileTitle.oldTitle
                }}” to “{{ reviewData.infoChanges.fileTitle.newTitle }}”
              </div>
            </div>
            <div
            *ngIf="
              !reviewData.infoChanges.startDate.newDate &&
              !reviewData.infoChanges.endDate.newDate &&
              !reviewData.infoChanges.description.newDesc &&
              !reviewData.infoChanges.fileTitle.newTitle
            "
          >N/A
          </div>
          </div>
        </div>
      </div>
      <div *ngIf="showRejectScreen">
        <div class="reject-wrapper">
          <div class="data-header">Reason for Rejection</div>
          <div>
            <textarea
              nz-input
              [(ngModel)]="reason"
              (keyup)="checkReason()"
              placeholder="Enter Reason for Rejection"
              [nzAutosize]="{ minRows: 8, maxRows: 8 }"
              [ngClass]="isValidReason ? 'text' : 'error'"
              (keydown)="disableEnter($event)"
            ></textarea>
            <div class="error-3" *ngIf="!isValidReason">
              The input value for this fields requires 2000 characters. Please enter valid input.
            </div>
          </div>
        </div>
      </div>
    </ng-container>
  </nz-modal>
</div>

<ng-template #footer>
  <nz-space *ngIf="!showRejectScreen">
    <div *nzSpaceItem class="cancel-button" (click)="onCancel()">Cancel</div>
    <div *nzSpaceItem>
      <button *ngIf="dataChangeStatus === 'Pending'" nz-button [nzType]="'default'" (click)="onReject()" class="default-button">
        <i nz-icon nzType="minus-circle" nzTheme="fill"></i> Reject Request
      </button>
    </div>
    <div *nzSpaceItem>
      <button *ngIf="dataChangeStatus === 'Pending'" nz-button [nzType]="'primary'" class="app-primary-button" (click)="approveClicked()">
        <i nz-icon nzType="check" nzTheme="outline"></i> Approve Request
      </button>
    </div>
  </nz-space>
  <nz-space *ngIf="showRejectScreen">
    <div *nzSpaceItem>
      <button nz-button [nzType]="'default'" (click)="onBack()">Back</button>
    </div>
    <div *nzSpaceItem>
      <button
        nz-button
        [nzType]="'primary'"
        [ngClass]="reason && isValidReason ? 'app-primary-button' : 'app-primary-button-disabled'"
        [disabled]="isdisabled()"
        (click)="rejectClicked()"
      >
        Reject
      </button>
    </div>
  </nz-space>
</ng-template>
