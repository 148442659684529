import { Component, EventEmitter, OnInit, Output, Input, OnDestroy } from '@angular/core';
import { Subscription } from 'rxjs';
import { HttpService } from '../../../shared/services/http.service';
import { DatePipe } from '@angular/common';
import { AppState } from '../../../shared/services/app-state';

@Component({
  selector: 'app-program-approval',
  templateUrl: './program-approval.component.html',
  styleUrls: ['./program-approval.component.scss'],
})
export class ProgramApprovalComponent implements OnInit, OnDestroy {
  @Output() exportPayload: EventEmitter<object> = new EventEmitter<object>();
  @Output() reviewed: EventEmitter<boolean> = new EventEmitter<boolean>();
  @Output() searchParamData: EventEmitter<object> = new EventEmitter<object>();
  @Output() exportUrl: EventEmitter<string> = new EventEmitter<string>();

  public loading = false;
  public programData = [];
  public showFilterStatus = false;
  public showFilterChannel = false;
  public showFilterClient = false;
  public pageIndex = 1;

  public sortOrder = 'descend';
  public sortDirection = ['ascend', 'descend'];
  public showReviewModal = false;

  public customProgramId;
  public search = '';
  public noDataMessage = 'No requests available.';

  public payload = {
    filters: {
      client: [],
      channel: [],
      status: [],
      date: 'descend',
    },
    search: '',
  };

  public status = [];

  public channel = [];
  public client = [];
  private appStateSubscription: Subscription;

  public programStatus;

  constructor(
    private httpService: HttpService,
    private datePipe: DatePipe,
    private appState: AppState,
  ) {
    this.appStateSubscription = AppState.appState.subscribe((val) => {
      if (val && !val.showDropdown) {
        this.showFilterClient = false;
        this.showFilterChannel = false;
        this.showFilterStatus = false;
      }
    });
  }

  ngOnInit(): void {
    this.loadData();
  }

  async loadData(): Promise<void> {
    this.loading = true;
    await this.httpService.postRequest('get_custom_programs', this.payload).toPromise().then((data) => {
    // await this.httpService.getRequest('get_custom_programs').toPromise().then((data) => {
      this.programData = data.data;
      this.status = data.status;
      this.client = data.client;
      this.channel = data.channel;
      this.searchParamData.emit(data.search_keywords);
      this.exportUrl.emit(data.export_url);
      this.loading = false;
    });
    this.reviewed.emit(true);
    this.exportPayload.emit(this.payload);
  }

  public generateMailData(mailRecipient, user, program, channel): void {
    const mailSubject = 'Your custom program request with ZS AffinityPortal is being looked into';
    const mailCC = 'AffinityTeam@zs.com';
    const mailBody = `Hi ${user},%0D%0AThe ZS Affinity Team is working on your request - ${program} under ${channel}.%0D%0APlease expect to hear from us soon.%0D%0A %0D%0AThank you,%0D%0AZS AffinityPortal Team`;

    window.location.href = `mailto:${mailRecipient}?subject=${mailSubject}&body=${mailBody}&cc=${mailCC}`;
  }
  public highlightFilterStatus(): boolean {
    return this.status.some((status) => status.value === true);
  }
  public highlightFilterChannel(): boolean {
    return this.channel.some((channel) => channel.value === true);
  }
  public highlightFilterClient(): boolean {
    return this.client.some((client) => client.value === true);
  }
  public resetStatus(): void {
    this.status.forEach((status) => {
      status.value = false;
    });
    this.filter(false);
  }

  public resetChannel(): void {
    this.channel.forEach((channel) => {
      channel.value = false;
    });
    this.filter(false);
  }
  public resetClient(): void {
    this.client.forEach((client) => {
      client.value = false;
    });
    this.filter(false);
  }

  public filter(dateModified): void {
    this.pageIndex = 1;
    this.showFilterClient = false;
    this.showFilterStatus = false;
    this.showFilterChannel = false;
    const clientTemp = [];
    const statusTemp = [];
    const channelTemp = [];
    this.client.forEach((c) => {
      if (c.value) {
        clientTemp.push(c.text);
      }
    });
    this.status.forEach((s) => {
      if (s.value) {
        statusTemp.push(s.text);
      }
    });
    this.channel.forEach((ch) => {
      if (ch.value) {
        channelTemp.push(ch.text);
      }
    });
    if (dateModified) {
      if (this.sortOrder === 'ascend') {
        this.sortOrder = 'descend';
      } else {
        this.sortOrder = 'ascend';
      }
    }
    this.payload = {
      filters: {
        status: statusTemp,
        client: clientTemp,
        channel: channelTemp,
        date: this.sortOrder,
      },
      search: this.search,
    };
    this.loadData();
  }

  public getDate(date): string {
    try {
      return this.datePipe.transform(new Date(date), 'MM/d/yy h:mm a');
    } catch (e) {
      return 'invalid date';
    }
  }
  public onReviewClose(event): void {
    this.showReviewModal = event;
  }
  public reviewClicked(data): void {
    this.showReviewModal = true;
    this.customProgramId = data.id;
    this.programStatus = data.status;
  }

  public openChange(event): void {
    this.appState.setGlobalState('showDropdown', event);
  }

  ngOnDestroy(): void {
    this.appStateSubscription.unsubscribe();
  }
}
